import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import WalletInfo from './WalletInfo';
import { getPortfolioInfo } from '../../../../actions/portfolio';
import {getAuthenticated, getCurrentWallet} from '../../../../selectors';

function mapStateToProps(state, props) {
    return {
        fiatCurrency: state.portfolio.fiatCurrency,
        wallet: getCurrentWallet(state, props.coin),
        isPortfolioLoaded: state.portfolio.isPortfolioLoaded,
        isAuthenticated: getAuthenticated(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPortfolioInfo,
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WalletInfo));
