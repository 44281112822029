import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import mail from '../../images/ic-mail.svg';
import blueMail from '../../images/ic-mail-big-orange.svg';
import refresh from '../../images/ic-refresh-orange-md.svg';
import { Component as AuthFooter } from '../../components/AuthFooter';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as GradientView } from '../../components/GradientView';

import { mobile } from '../../styles';


const MailIcon = styled.div`
  text-align: center;
  margin: 35px 0;

  ${mobile`
      margin-bottom: 20px;
  `}
`;

const FormWrapper = styled.form`
    padding: 60px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mobile`
        padding: 20px 30px 60px 30px;
    `};
`;

const FormTitle = styled.div`
	margin-bottom: 40px;
    margin-top: 40px;
`;

const CheckInboxMessage = styled.span`
	color: #262626;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-weight: 400;
	max-width: 350px;
    font-family: Inter, sans-serif;

	// span {
    //     margin-top: 40px;
    //     display: block;
    //     margin-bottom: 40px;
	// }
`;

const CheckInboxMessageEmail = styled(CheckInboxMessage)`
    font-weight: 700;
`;

const EmailActionsBlock = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
    margin-top: 30px;
`;

const ResendAction = styled.span`
color: #EF8632;
text-decoration: none;
display: flex;
align-items: center;
font-family: Inter, sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;

img {
    width: 14px;
    margin: 0 5px 0 0;
  }
`;

const EditEmailAction = styled(ResendAction)`
  color: #021747;
  text-decoration: underline;
  flex-direction: row;

  img {
    width: 14px;
    margin: 0 5px 0 0;
    color: #021747;
  }
`;

const EditEmailLink = styled.span`
  color: #021747;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  font-family: Inter, sans-serif;
  text-decoration: none;
`;


export default class VerifyEmailScreen extends React.Component {

    static propTypes = {
        registerEmail: PropTypes.func,
        email: PropTypes.string,

        history: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            resent: false,
        };
    }

    componentDidMount() {
        const { email } = this.props;

        if (!email || email === '') {
            this.props.history.push('/signup');
        }

        document.title = 'Verify Your Email (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });
    }

    onSubmit = async event => {
        event.preventDefault();
        const { email } = this.props;

        this.setState({
            loading: true,
        });

        const response = await this.props.registerEmail({
            email,
        });
        if (!response.error) {
            this.setState({
                loading: false,
                resent: true,
            });
        } else {
            //TODO: error message here?
        }
    };


    render() {

        const { email } = this.props;
        const { loading, resent } = this.state;

        return (
            <GradientView>
                <FormWrapper onSubmit={this.onSubmit}>
                    <FormTitle>
                        <MessageBold>
                            <FormattedMessage
                                id="auth.signin.checkEmail"
                                defaultMessage="Check your email"
                            />
                        </MessageBold>
                    </FormTitle>
                    <CheckInboxMessage>
                        <span>
                            <FormattedMessage
                                id="auth.signin.sentVerificationLinkToConfirmEmail"
                                defaultMessage="We’ve sent a verification link to confirm your {br} access to {email}"
                                values={{
                                    email: <CheckInboxMessageEmail>{email}</CheckInboxMessageEmail>,
                                    br: <br />
                                }}
                            />
                        </span>
                    </CheckInboxMessage>
                    <MailIcon>
                        <img src={mail} alt="" />
                    </MailIcon>
                    <EmailActionsBlock>
                        {!loading && !resent && (
                            <ResendAction href="#" onClick={this.onSubmit}>
                                <img src={refresh} />
                                <FormattedMessage
                                    id="auth.signin.resend"
                                    defaultMessage="Resend"
                                />
                            </ResendAction>
                        )}
                        {!loading && resent && (
                            <React.Fragment>
                                <FormattedMessage
                                    id="auth.signin.emailResent"
                                    defaultMessage="Email Resent"
                                />
                            </React.Fragment>
                        )}
                        <EditEmailAction>
                            <Link to="/signup">
                                <img src={blueMail} />
                                <EditEmailLink>
                                    <FormattedMessage
                                        id="auth.signin.editEmail"
                                        defaultMessage="Edit Email"
                                    />
                                </EditEmailLink>
                            </Link>
                        </EditEmailAction>
                    </EmailActionsBlock>
                </FormWrapper>
                <AuthFooter />
            </GradientView>
        );
    }
}
