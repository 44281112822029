import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import LoginScreen from './LoginScreen';
import { login } from '../../actions/auth';
import { clearErrors } from '../../actions/errors';

function mapStateToProps(state) {
    return {
        loginLoading: state.auth.loginLoading,
        isCredentialsError: state.errors && state.errors.type && state.errors.type === 'CREDENTIALS_ERROR',
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            login,
            clearErrors,
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginScreen));
