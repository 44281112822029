import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as Button } from '../../../../components/Button';
import { Component as Input } from '../../../../components/Input';
import { Container as InputTwoFa } from '../../../../components/InputTwoFa';

import { validateAnyAuthCode } from '../../../../utils';
import { mobile } from '../../../../styles';


const VALIDATION_MESSAGES = {
    address: (
        <FormattedMessage
            id="error.address_required"
            defaultMessage="Address must be set"
        />
    ),
    code: (
        <FormattedMessage
            id="error.code_required"
            defaultMessage="2FA Code must be set"
        />
    ),
};


const Container = styled.div`
  padding: 20px;
`;

const FormLine = styled.div`
  flex: 0 1 100%;
  margin-top: 20px;
  flex-wrap: wrap;
    flex-direction: column;
    display: flex;
    flex-flow: column;
    margin-bottom: 1em;

  label {
	color: #9B9B9B;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 5px;
  }
`;

const WithdrawButton = styled(Button)`
  width: 250px;

  ${mobile`
    width: 100%;
  `}
`;

const WithdrawalAddress = styled(Input)`
  padding: 10px;
  border-radius: 3px;
  width: 500px;
  border: 1px solid #ddd;

  ${mobile`
    width: 100%;
  `}
`;

const WithdrawalAddressTag = styled(Input)`
  padding: 10px;
  border-radius: 3px;
  width: 200px;
  border: 1px solid #ddd;
`;


export default class PortfolioPermWithdrawal extends React.Component {

    static propTypes = {
        coin: PropTypes.string,
        getPortfolioInfo: PropTypes.func,
        isAuthenticated: PropTypes.bool,
        setPermWithdraw: PropTypes.func,
        wallet: PropTypes.object,
        refreshPortfolioInfo: PropTypes.bool,
        user: PropTypes.object,
    };

    state = {
        errors: [],
        address: '',
        tag: '',
        label: '',
        code: '',
    };

    componentDidUpdate(prevProps, prevState) {
        const { isAuthenticated } = this.props;
        if (this.props.refreshPortfolioInfo !== prevProps.refreshPortfolioInfo && this.props.refreshPortfolioInfo === true) {
            if (isAuthenticated) {
                this.props.getPortfolioInfo();
            }
        }
        if (prevState.address === '' && this.state.address === '' && this.props.wallet && this.props.wallet.prmAddress && this.props.wallet.prmAddress !== '') {
            this.setState({
                address: this.props.wallet.prmAddress,
                tag: this.props.wallet.prmTag,
                label: this.props.wallet.prmLabel,
            });
        }
    }

    getError = field => {
        const error = this.state.errors.find(e => e.field === field);
        return error ? error.message : null;
    };

    validateInput = () => {
        const { user } = this.props;
        const requiredFields = [
            'address',
            user && !user.securedMethods.includes('NONE') && user.securedActions.includes('WITHDRAW') ? 'code' : undefined,
        ];

        const validations = [
            {
                field: 'code',
                validate: code => {
                    return validateAnyAuthCode(code);
                },
                message: (
                    <FormattedMessage
                        id="error.invalid_code"
                        defaultMessage="Invalid code"
                    />
                )
            },
        ];

        const missedFields = [];
        const invalidFields = [];

        requiredFields.filter(f => !!f).map(field => {
            if(!this.state[field]) {
                missedFields.push({
                    field,
                    message: VALIDATION_MESSAGES[field]
                });
            }
        });

        validations.map(({ field, validate, message }) => {
            if(!validate(this.state[field])) {
                invalidFields.push({
                    field,
                    message
                });
            }
        });

        return invalidFields.concat(missedFields);
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSubmit = () => {
        const { code, address, tag, label } = this.state;
        const { coin } = this.props;

        const errors = this.validateInput();

        if (errors.some(e => !!e)) {
            this.setState({
                errors
            });
        } else {
            this.setState({
                errors: []
            });

            this.props.setPermWithdraw({
                code: code,
                address: address,
                tag: tag,
                label: label,
                asset: coin
            });
        }
    };


    render() {

        const { coin, user, wallet } = this.props;
        const { address, tag, label, code } = this.state;

        const disabled = (address == wallet.prmAddress && tag == wallet.prmTag && label == wallet.prmLabel) || !address || !validateAnyAuthCode(code);


        return (
            <Container>
                <React.Fragment>
                    {(user && (user.kycTier == 'TIER3' || user.kycTier == 'CORPORATE')) ? (
                        <FormattedMessage
                            id="deposit.permAddressRequiredTier3"
                            defaultMessage="We maintain a record of your permanent withdrawal address in case we ever need to return your funds and we are unable to reach you."
                        />
                    ) : (
                        <FormattedMessage
                            id="deposit.permAddressRequired"
                            defaultMessage="Regulations in Canada do not allow us to keep your deposits for you long term. Before we allow you to deposit we need to know your permanent withdrawal address in case we need to automatically process a withdrawal.  Note that TIER 3 accounts are exempt of this requirement, so if you wish, you may apply for TIER 3 status."
                        />
                    )}
                    <br/>
                    <br/>
                    <FormattedMessage
                        id="deposit.setPermAddress"
                        defaultMessage="Should the need ever arise, the address you submit may be used to process automated withdrawals. Please make sure this is an address that you control and that will be valid long term."
                    />
                    <br/>
                    <br/>
                    <FormattedMessage
                        id="deposit.setPermAddressComeBack"
                        defaultMessage="You may come back here and update this address at any time."
                    />

                    {(user && (user.locked || user.withdrawalsDisabled)) ? (
                        <FormattedMessage
                            id="porfolio.withdrawalLockedBlockingDeposits"
                            defaultMessage="Sorry, this asset is currently locked."
                        />
                    ) : (
                        <React.Fragment>
                            <FormLine>
                                <FormattedMessage
                                    id="portfolio.permWithdrawalAddress"
                                    defaultMessage="Permanent Withdrawal Address"
                                    tagName="label"
                                />
                                <WithdrawalAddress
                                    type="text"
                                    name="address"
                                    value={(address == '') ? wallet.prmAddress : address}
                                    onChange={this.onChange}
                                    error={this.getError('address')}
                                />
                            </FormLine>
                            { coin && coin === 'XRP' && (
                                <FormLine>
                                    <FormattedMessage
                                        id="portfolio.withdrawalTag"
                                        defaultMessage="Address Tag or Payment Id"
                                        tagName="label"
                                    />
                                    <WithdrawalAddressTag
                                        type="text"
                                        name="tag"
                                        value={(tag === '') ? wallet.prmTag : tag}
                                        onChange={this.onChange}
                                        error={this.getError('tag')}
                                    />
                                </FormLine>
                            )}
                            <FormLine>
                                <FormattedMessage
                                    id="portfolio.permWithdrawalAddressComment"
                                    defaultMessage="Address Comment (for your own reference)"
                                    tagName="label"
                                />
                                <WithdrawalAddress
                                    type="text"
                                    name="label"
                                    value={(label === '') ? wallet.prmLabel : label}
                                    onChange={this.onChange}
                                    error={this.getError('label')}
                                />
                            </FormLine>

                            {user && !user.securedMethods.includes('NONE') && user.securedActions.includes('WITHDRAW') && (
                                <FormLine>
                                    <InputTwoFa
                                        name="code"
                                        value={code}
                                        onChange={this.onChange}
                                        disabled={false}
                                        component='WITHDRAW'
                                    />
                                </FormLine>
                            )}
                            <FormLine>
                                <WithdrawButton
                                    disabled={disabled}
                                    onClick={this.onSubmit}
                                >
                                    <FormattedMessage
                                        id="portfolio.withdraw"
                                        defaultMessage="Set {asset} Withdrawal Address"
                                        values={{ asset: coin, }}
                                    />
                                </WithdrawButton>
                            </FormLine>
                        </React.Fragment>

                    )}

                </React.Fragment>
            </Container>
        );
    }
}
