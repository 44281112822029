
import { getDeviceInfo, guid } from '../../utils';


/*
 * INTERNAL REQUESTS
 */

export const clearApiKeys = () => ({
    type: 'CLEAR_API_KEYS',
});

export const verificationUrlLoaded = () => ({
    type: 'VERIFICATION_URL_LOADED',
});

/*
 * REST REQUESTS
 */

export function subscribe(email, jurisdiction) {
    return {
        api: {
            type: 'REQUEST_EMAIL_SUBSCRIBE',
            method: 'POST',
            body: JSON.stringify({
                type: 'SUBSCRIBE',
                data: {
                    email: email,
                    jurisdiction: jurisdiction,
                    acceptedTerms: true
                },
                uid: guid(),
            }),
            types: [
                'EMAIL_SUBSCRIBE_START',
                'EMAIL_SUBSCRIBE_SUCCESS',
                'EMAIL_SUBSCRIBE_FAILURE'
            ]
        }
    };
}

export function unsubscribe(email) {
    return {
        api: {
            type: 'REQUEST_EMAIL_UNSUBSCRIBE',
            method: 'POST',
            body: JSON.stringify({
                type: 'UNSUBSCRIBE',
                data: {
                    email: email,
                },
                uid: guid(),
            }),
            types: [
                'EMAIL_UNSUBSCRIBE_START',
                'EMAIL_UNSUBSCRIBE_SUCCESS',
                'EMAIL_UNSUBSCRIBE_FAILURE'
            ]
        }
    };
}

/*
 * WEBSOCKET REQUESTS
 */

export const getUploadedFileList = () => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'GET_UPLOADED_FILE_LIST',
                data: {
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const getApiKeys = () => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'GET_API_KEYS',
                data: {
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const submitCreateApiKey = (allowTrade, allowWithdrawal, code) => ({
    ws: {
        type: 'CREATE_API_KEY',
        data: {
            allowTrade,
            allowWithdrawal,
            code,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const submitDisableApiKey = (id) => ({
    ws: {
        type: 'DISABLE_API_KEY',
        data: {
            id,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const submitDeleteApiKey = (id) => ({
    ws: {
        type: 'DELETE_API_KEY',
        data: {
            id,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const getVrfyInfo = () => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'GET_VRFY_INFO',
                data: {
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const getProofOfReserves = () => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'GET_PROOF_RESERVES',
                data: {
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const setProofOfReserves = (personalString, includeBalances) => ({
    ws: {
        type: 'SET_PROOF_RESERVES',
        data: {
            personalString: personalString,
            includeBalances: includeBalances,
        },
        uid: guid(),
        requireAuthorization: true
    }
});


export const submitUpdateVrfyInfo = (firstName, lastName, email, year, month, day, mrz1, mrz2, taxIdNumber, occupation, street, unitNumber, city, region, postal, country) => {
    // requests with device info must be async
    return async dispatch => {
        return dispatch({
            type: 'UPDATE_VRFY_INFO_DEVICE_INFO',
            ws: {
                type: 'UPDATE_VRFY_INFO',
                data: {
                    firstName,
                    lastName,
                    email,
                    year,
                    month,
                    day,
                    mrz1,
                    mrz2,
                    taxIdNumber,
                    occupation,
                    street,
                    unitNumber,
                    city,
                    region,
                    postal,
                    country,
                    deviceInfo: await getDeviceInfo()
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const submitReferredBy = (referredBy) => ({
    ws: {
        type: 'REQUEST_ADD_REFBY',
        data: {
            referredBy,
        },
        uid: guid(),
        requireAuthorization: true
    }
});
