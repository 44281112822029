import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import config from 'config';

import { Component as Input } from '../../components/Input';
import { Component as Button } from '../../components/Button';
import { Component as AuthFooter } from '../../components/AuthFooter';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as GradientView } from '../../components/GradientView';

import { validateGauthCode, validateSmsCode, validateYubikeyCode } from '../../utils';
import {
    AuthInputGroup,
    FormContainer,
    FormWrapper,
} from '../../commonStyles/AuthStyles';

import yubikeyIcon from '../../images/ic-yubi.svg';
import gauthIcon from '../../images/ic-gauth.svg';
import smsIcon from '../../images/sms.png';
import warningIcon from '../../images/ic-warning.svg';
// import smsRefreshIcon from '../../images/sms_refresh.png';
// import help from '../../images/ic-help-blue.svg';

const isSMSEnabled = config.get('features.sms');


const Warning = styled.img`
  padding-right: 5px;
`;

const FormTitle = styled.h1`
	font-size: 24px;
	line-height: 36px;
	text-align: center;
	font-weight: 500;
	margin-bottom: 25px;

	p {
	  display: block;
	  font-size: 16px;
	  margin: 0;
	}
`;

const InputGroupView = styled(AuthInputGroup)`
  width: 350px;
  margin-bottom: 0;
`;


const AuthIcon = styled.img`
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 2px;
`;

const SigninButton = styled(Button)`
  display: block;
  width: 350px;
  margin: 35px 0px;
  border-radius: 10px;
  text-transform: capitalize;
`;

const CodeInput = styled(Input)`
    font-size: 18px;
    height: 60px;
    font-weight: 300;
`;

// const HowToUse = styled(Link)`
//   display: flex;
//   align-items: center;
//   font-size: 12px;
//   margin-top: 30px;
//   color: #ef8632;
//   text-decoration: none;

//   img {
//     margin: 0;
//     margin-right: 10px;
//   }
// `;

export default class Login2FAScreen extends React.Component {

    static propTypes = {
        login2fa: PropTypes.func,
        token: PropTypes.string,
        history: PropTypes.object,
        location: PropTypes.object,
        isCredentialsError: PropTypes.bool,
    };

    state = {
        yubikeyCode: '',
        gAuthCode: '',
        smsCode: '',
        submittedCode: '',
        error: false,
        loading: false,
    };

    componentDidMount() {
        document.title = 'Two Factor Authentication (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    onSubmit = async event => {
        event.preventDefault();
        const delay = ms => new Promise(res => this.timer = setTimeout(res, ms));

        const { token } = this.props;
        const { yubikeyCode, gAuthCode, smsCode } = this.state;

        let code = yubikeyCode;
        let submittedCode = 'yubikeyCode';
        if (gAuthCode.length > yubikeyCode.length) {
            code = gAuthCode;
            submittedCode = 'gAuthCode';
        }
        if (smsCode.length > gAuthCode.length) {
            code = smsCode;
            submittedCode = 'smsCode';
        }

        this.setState({
            submittedCode: submittedCode,
            loading: true,
            error: false
        });

        this.props.login2fa(code, token);

        await delay(3000);

        this.setState({
            loading: false,
            yubikeyCode: '',
            gAuthCode: '',
            smsCode: '',
        });
    };

    onChangeSubmit = async code => {
        const { token } = this.props;

        const delay = ms => new Promise(res => this.timer = setTimeout(res, ms));

        this.setState({
            loading: true,
            error: false
        });

        this.props.login2fa(code, token);

        await delay(3000);

        this.setState({
            loading: false,
            yubikeyCode: '',
            gAuthCode: '',
            smsCode: '',
        });
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
        if ((event.target.name === 'gAuthCode' || event.target.name === 'smsCode') && event.target.value.length >= 6) {
            this.setState({
                submittedCode: event.target.name,
            });
            this.onChangeSubmit(event.target.value);
        }
    };


    render() {
        const { token, isCredentialsError } = this.props;
        const { submittedCode, yubikeyCode, gAuthCode, smsCode, error, loading } = this.state;

        // const disabled = loading || !this.validate(yubikeyCode, gAuthCode, smsCode);

        const disabled = loading || (!validateSmsCode(smsCode) && !validateYubikeyCode(yubikeyCode) && !validateGauthCode(gAuthCode));

        if (!token) {
            this.props.history.push('/login');
        }

        return (
            <FormContainer>
                <GradientView borderRadius={'0 0 15px 15px'}>
                    <FormWrapper onSubmit={this.onSubmit}>
                        <FormTitle>
                            <MessageBold>
                                <FormattedMessage
                                    id="auth.signin.title"
                                    defaultMessage="Two-Factor Authentication"
                                />
                            </MessageBold>
                        </FormTitle>
                        <InputGroupView>
                            <label>
                                <AuthIcon src={'/' + yubikeyIcon} />
                                <FormattedMessage
                                    id="auth.signin.subtitle_yubikey"
                                    defaultMessage="Yubikey Code"
                                />
                            </label>
                            <Input autoFocus
                                name="yubikeyCode"
                                placeholder="Enter full yubikey code"
                                value={yubikeyCode}
                                onChange={this.onChange}
                                error={(submittedCode === 'yubikeyCode' && (error || isCredentialsError)) ? (
                                    <React.Fragment>
                                        <Warning src={'/' + warningIcon}/>
                                        <FormattedMessage
                                            id="auth.signin.incorrectCode"
                                            defaultMessage="Incorrect code, please try again"
                                        />
                                    </React.Fragment>
                                ) : ''}
                                disabled={loading}
                            />
                        </InputGroupView>
                        <InputGroupView>
                            <label>
                                <AuthIcon src={'/' + gauthIcon} />
                                <FormattedMessage
                                    id="auth.signin.subtitle_google"
                                    defaultMessage="Google Authenticator Code"
                                />
                            </label>
                            <CodeInput autoFocus
                                name="gAuthCode"
                                placeholder="Enter 6 Digit code"
                                value={gAuthCode}
                                onChange={this.onChange}
                                error={(submittedCode === 'gAuthCode' && (error || isCredentialsError)) ? (
                                    <React.Fragment>
                                        <Warning src={'/' + warningIcon}/>
                                        <FormattedMessage
                                            id="auth.signin.incorrectCode"
                                            defaultMessage="Incorrect code, please try again"
                                        />
                                    </React.Fragment>
                                ) : ''}
                                disabled={loading}
                            />
                        </InputGroupView>
                        {isSMSEnabled && (
                            <InputGroupView>
                                <label>
                                    <AuthIcon src={'/' + smsIcon} />
                                    <FormattedMessage
                                        id="auth.signin.subtitle_sms"
                                        defaultMessage="SMS Code"
                                    />
                                </label>
                                <CodeInput
                                    name="smsCode"
                                    placeholder="✕✕✕ ✕✕✕"
                                    value={smsCode}
                                    onChange={this.onChange}
                                    error={(submittedCode === 'smsCode' && (error || isCredentialsError)) ? (
                                        <React.Fragment>
                                            <Warning src={'/' + warningIcon}/>
                                            <FormattedMessage
                                                id="auth.signin.incorrectCode"
                                                defaultMessage="Incorrect code, please try again"
                                            />
                                        </React.Fragment>
                                    ) : ''}
                                    disabled={loading}
                                />
                            </InputGroupView>
                        )}
                        <SigninButton disabled={disabled} bsStyle={'orange'}>
                            <FormattedMessage
                                id="auth.signin.continue"
                                defaultMessage="Continue"
                            />
                        </SigninButton>
                        {/* <HowToUse to='/'>
                            <img src={help} alt="" />
                            How to use Google Authenticator
                        </HowToUse> */}
                    </FormWrapper>
                </GradientView>
                <AuthFooter type={'WHITE'} />
            </FormContainer>
        );
    }
}
