import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from './App';
import { withCookies } from 'react-cookie';
import {getAuthenticated} from '../../selectors';

function mapStateToProps(state) {
    return {
        isAuthenticated: getAuthenticated(state),
        maintenanceMode: state.currency.maintenanceMode,
        loginLoading: state.auth.loginLoading,
        isConnected: state.ws.state && state.ws.state === 'CONNECTED',
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(App));
