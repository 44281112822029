import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dateFns from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { Component as CurrencyImage } from '../../../../components/CurrencyImage';

import { displayFormat } from '../../../../utils';
import { mobile } from '../../../../styles';

const tableRowStyle = {
    overflow: 'hidden',
};

const tableRowClickableStyle = {
    overflow: 'hidden',
    cursor: 'pointer',
};

const Container = styled.div`
  padding: 5px 0 0 0;
  margin: 0 20px 35px 20px;
  border: 2px solid #DDD;
  border-radius: 15px;
  background-color: #FAFAFA;

  ${mobile`
    margin: 0 10px 35px 10px;
  `}
`;

const Table = styled.div`
  width: 100%;
  display: table;
  overflow: hidden;
  padding:3px 0;
  margin-left: auto;
  margin-right: auto;
  // background-color: ${props => props.bgColor || '#FFF'};
`;

const NarrowTable = styled(Table)`
  width: 98%;
`;

const CellHeader = styled.div`
  border-width: 0;
  border-bottom: 1px solid #97979760;
  font-size: 10px;
  padding: 0;
  margin: 0 0 8px 0;
  color: #ACB5BC;
  font-weight: 600;
  letter-spacing: 0.71px;
  line-height: 20px;
  text-transform: capitalize;
  width: 100%;

  span {
      margin-left: 8px;
      margin-right: 8px;
  }
`;

const TableBody = styled.div`
  display: table-row-group;
`;

const TableRow = styled.div`
  display: table-row;

  ${mobile`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => props.flexDirection || 'row'};
    justify-content: space-between;
  `}
`;

const TableCell = styled.div`
  display: table-cell;
  border-width: 0;
  color: #232323;
  font-size: 13px;
  letter-spacing: 0.84px;
  padding: 0 0 10px 0;
  vertical-align: top;
  text-align: center;

  ${mobile`
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding: 0 0 6px 0;
  `}
`;

const TableCellNoWrap = styled(TableCell)`
  white-space: nowrap;
`;

const TableCellMobile = styled.div`
  display: none;
  ${mobile`
    display: flex;
    margin: 5px;
  `}
`;

const CurrencyIcon = styled(CurrencyImage)`
  height: 30px;
  width: 30px;
  margin-right: 8px;
`;

const CurrencyGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;


export default class WalletStatusTable extends React.Component {

    static propTypes = {
        assetArray: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            showFunnelRows: false,
        };
    }

    toggleFunnelsDisplayed = () => {
        const { showFunnelRows } = this.state;

        this.setState({
            showFunnelRows: !showFunnelRows,
        });
    };

    render() {

        const { assetArray } = this.props;
        const { showFunnelRows } = this.state;

        let rowCount = 0;

        const funnelRowCount = this.props.assetArray.addresses.filter(address => address.type === 'funnel').length;

        let funnelBalanceSum = 0;
        this.props.assetArray.addresses.filter(address => address.type === 'funnel').map(address => funnelBalanceSum = funnelBalanceSum + address.balance);

        let funnelUSDValue = 0;
        this.props.assetArray.addresses.filter(address => address.type === 'funnel').map(address => funnelUSDValue = funnelUSDValue + address.value);

        const summaryHead = [
            {key :'asset', title: (
                <FormattedMessage
                    id="walletStatus.asset"
                    defaultMessage="Asset"
                />
            )},
            {key :'deposits24h', title: (
                <FormattedMessage
                    id="walletStatus.deposits24h"
                    defaultMessage="24h Deposits"
                />
            )},
            {key :'totalDeposits', title: (
                <FormattedMessage
                    id="walletStatus.totalDeposits"
                    defaultMessage="Total Deposits"
                />
            )},
            {key :'withdrawals24h', title: (
                <FormattedMessage
                    id="walletStatus.withdrawals24h"
                    defaultMessage="24h Withdrawals"
                />
            )},
            {key :'totalWithdrawals', title: (
                <FormattedMessage
                    id="walletStatus.totalWithdrawals"
                    defaultMessage="Total Withdrawals"
                />
            )},
            {key :'avgWithdrawalFee', title: (
                <FormattedMessage
                    id="walletStatus.avgWithdrawalFee"
                    defaultMessage="Avg Withdrawal Fee"
                />
            )},
        ];

        const walletHead = [
            {key :'walletType', title: (
                <b><FormattedMessage
                    id="walletStatus.wallet"
                    defaultMessage="Wallet"
                /></b>
            )},
            {key :'address', title: (
                <FormattedMessage
                    id="walletStatus.address"
                    defaultMessage="Address"
                />
            )},
            {key :'balance', title: (
                <FormattedMessage
                    id="walletStatus.balance"
                    defaultMessage="Balance"
                />
            )},
            {key :'value', title: (
                <FormattedMessage
                    id="walletStatus.value"
                    defaultMessage="Value (USD)"
                />
            )},
            {key :'lastCheck', title: (
                <FormattedMessage
                    id="walletStatus.lastCheck"
                    defaultMessage="Updated"
                />
            )},
        ];

        const walletSummary = [
            {key :'blockchainBalance', title: (
                <FormattedMessage
                    id="walletStatus.blockchainBalance"
                    defaultMessage="Blockchain Wallet Balance"
                />
            )},
            {key :'platformBalance', title: (
                <FormattedMessage
                    id="walletStatus.platformBalance"
                    defaultMessage="Exchange Users Balances"
                />
            )},
            {key :'platformSurplus', title: (
                <FormattedMessage
                    id="walletStatus.exchangeSurplus"
                    defaultMessage="Exchange Surplus"
                />
            )},
        ];

        console.log(showFunnelRows);

        return (
            <React.Fragment>
                {assetArray.addresses ? (
                    <Container>
                        <Table bgColor='#FAFAFA'>
                            <TableBody>
                                <TableRow style={{ ...tableRowStyle }} >
                                    <TableCell>
                                        <CellHeader>{summaryHead[0].title}</CellHeader>
                                        <CurrencyGroup>
                                            <CurrencyIcon currency={assetArray.ticker} />
                                            <b>{assetArray.ticker}</b>
                                        </CurrencyGroup>
                                    </TableCell>
                                    <TableCell>
                                        <CellHeader>{summaryHead[1].title}</CellHeader>
                                        {assetArray.processedDepositCount24h}
                                    </TableCell>
                                    <TableCell>
                                        <CellHeader>{summaryHead[2].title}</CellHeader>
                                        {displayFormat(assetArray.processedDepositTotal24h, 8)}
                                    </TableCell>
                                    <TableCell>
                                        <CellHeader>{summaryHead[3].title}</CellHeader>
                                        {assetArray.processedWithdrawalCount24h}
                                    </TableCell>
                                    <TableCell>
                                        <CellHeader>{summaryHead[4].title}</CellHeader>
                                        {displayFormat(assetArray.processedWithdrawalTotal24h, 8)}
                                    </TableCell>
                                    <TableCell>
                                        <CellHeader>{summaryHead[5].title}</CellHeader>
                                        {displayFormat(assetArray.txFee, 8)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <NarrowTable>
                            <TableBody>
                                {this.props.assetArray.addresses.filter(address => address.type !== 'funnel').map(address =>
                                    (
                                        <React.Fragment key={rowCount++}>
                                            <TableRow flexDirection='column' style={{ ...tableRowStyle }}>
                                                <TableCellMobile />
                                                <TableCell>
                                                    <CellHeader>{walletHead[0].title}</CellHeader>
                                                    <b>{address.type}{address.network ? ' (' + address.network + ')' : ''}</b>
                                                </TableCell>
                                                <TableCell>
                                                    <CellHeader>{walletHead[1].title}</CellHeader>
                                                    {address.address}
                                                </TableCell>
                                                <TableCellNoWrap>
                                                    <CellHeader>{walletHead[2].title}</CellHeader>
                                                    {displayFormat(address.balance, 8)}
                                                </TableCellNoWrap>
                                                <TableCellNoWrap>
                                                    <CellHeader>{walletHead[3].title}</CellHeader>
                                                    {displayFormat(address.value, 2)}
                                                </TableCellNoWrap>
                                                <TableCellNoWrap>
                                                    <CellHeader>{walletHead[4].title}</CellHeader>
                                                    {dateFns.format(new Date(address.lastCheck*1000), 'MM/DD/YY hh:mm')}
                                                </TableCellNoWrap>
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                )}
                                {(funnelRowCount > 0) && (
                                    <React.Fragment key={rowCount++}>
                                        <TableRow flexDirection='column' style={{ ...tableRowClickableStyle }} onClick={() => {this.toggleFunnelsDisplayed();}}>
                                            <TableCellMobile />
                                            <TableCell>
                                                <CellHeader>{walletHead[0].title}</CellHeader>
                                                <b>deposit ({funnelRowCount}) {showFunnelRows ? '\u25BE' : '\u25B8'}</b>
                                            </TableCell>
                                            <TableCell>
                                                <CellHeader>&nbsp;</CellHeader>
                                            </TableCell>
                                            <TableCellNoWrap>
                                                <CellHeader>&nbsp;{!showFunnelRows && walletHead[2].title}&nbsp;</CellHeader>
                                                {!showFunnelRows && displayFormat(funnelBalanceSum, 8)}
                                            </TableCellNoWrap>
                                            <TableCellNoWrap>
                                                <CellHeader>&nbsp;{!showFunnelRows && walletHead[3].title}&nbsp;</CellHeader>
                                                {!showFunnelRows && displayFormat(funnelUSDValue, 2)}
                                            </TableCellNoWrap>
                                            <TableCellNoWrap>
                                                <CellHeader>&nbsp;</CellHeader>
                                            </TableCellNoWrap>
                                        </TableRow>
                                        {showFunnelRows && (
                                            this.props.assetArray.addresses.filter(address => address.type === 'funnel').map(address =>
                                                (
                                                    <React.Fragment key={rowCount++}>
                                                        <TableRow flexDirection='column' style={{ ...tableRowStyle }}>
                                                            <TableCellMobile />
                                                            <TableCell />
                                                            <TableCell>
                                                                <CellHeader>{walletHead[1].title}</CellHeader>
                                                                {address.address}
                                                            </TableCell>
                                                            <TableCellNoWrap>
                                                                <CellHeader>{walletHead[2].title}</CellHeader>
                                                                {displayFormat(address.balance, 8)}
                                                            </TableCellNoWrap>
                                                            <TableCellNoWrap>
                                                                <CellHeader>{walletHead[3].title}</CellHeader>
                                                                {displayFormat(address.value, 2)}
                                                            </TableCellNoWrap>
                                                            <TableCellNoWrap>
                                                                <CellHeader>{walletHead[4].title}</CellHeader>
                                                                {dateFns.format(new Date(address.lastCheck*1000), 'MM/DD/YY hh:mm')}
                                                            </TableCellNoWrap>
                                                        </TableRow>
                                                    </React.Fragment>
                                                )
                                            )
                                        )}
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </NarrowTable>
                        <Table bgColor='#FAFAFA' style={{ textAlign: 'center' }}>
                            <TableBody>
                                <TableRow style={{ ...tableRowStyle }} >
                                    <TableCell>
                                        <CellHeader>{walletSummary[0].title}</CellHeader>
                                        {displayFormat(assetArray.walletBalance, 8)}
                                    </TableCell>
                                    <TableCell>
                                        <CellHeader>{walletSummary[1].title}</CellHeader>
                                        {displayFormat(assetArray.platformBalance, 8)}
                                    </TableCell>
                                    <TableCell>
                                        <CellHeader>{walletSummary[2].title}</CellHeader>
                                        <b>{displayFormat((assetArray.walletBalance - assetArray.platformBalance), 8)}</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Container>
                ) : null }
            </React.Fragment>
        );
    }

}
