import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import ContentLoader from 'react-content-loader';

import { Component as CurrencyImage } from '../CurrencyImage';

import { mobile } from  '../../styles';
import { displayFormat } from  '../../utils';


const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
  height: 60px;
  border: 2px solid #CFCFCF;
  border-radius: 5px;
  box-sizing: border-box;

  ${mobile`
    // width: 100%;
    justify-content: space-around;
    padding-left: 12px;
    padding-right: 12px;
  `}
`;

const CurrencyIcon = styled(CurrencyImage)`
  width: 20px;
  height: 20px;
  margin-right: 7px;
  vertical-align: middle;
  padding-bottom: 4px;

  ${mobile`
    margin-right: 4px;
  `}
`;

const CurrencyImageWrapper = styled.div`
  	color: #4A4A4A;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    display: inline-block;

    ${mobile`
        max-width: 35px;
    `};
`;

const CurrencyCode = styled.div`
    top: 0;
    font-weight: 300;
    display: inline-block;
    margin-left: 5px;
    // line-height: 60px;
    // height: 60px;
    vertical-align: bottom;
    margin-top: auto;
    margin-bottom: auto;

    ${mobile`
      line-height: 15px;
    `}
`;

const CurrencyCodeMobileOnly = styled.div`
    display: none;

    ${mobile`
      display: block;
      text-align: center;
    `}
`;

const CurrencyCodeNoMobile = styled(CurrencyCode)`
    ${mobile`
      display: none;
    `}
`;

const Arrow = styled.div`
  height: 60px;
  font-size: 24px;
  line-height: 50px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;

  ${mobile`
    margin-left: 7px;
    margin-right: 7px;
  `}
`;

const ArrowAccurate = styled.div`
  height: 60px;
  line-height: 60px;
  margin-left: 15px;
  margin-right: 15px;

  ${mobile`
    margin-left: 7px;
    margin-right: 7px;
  `}
`;

const CoinLoader = props => (
    <ContentLoader
        height={50}
        width={118}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="10" rx="0" ry="0" width="118" height="30" />
    </ContentLoader>
);

const StyledLoader = styled(CoinLoader)`
  width: 118px;
`;

export default class CurrencyStaticPair extends React.Component {

    static propTypes = {
        currencyFrom: PropTypes.string,
        currencyTo: PropTypes.string,
        valueFrom: PropTypes.number,
        valueTo: PropTypes.number,
        accurate: PropTypes.bool,
    };

    // old arrow:  &#8594;

    render() {

        const { currencyFrom, currencyTo, valueFrom, valueTo, accurate } = this.props;

        return (
            <Wrapper>
                { currencyTo && currencyFrom ? (
                    <React.Fragment>
                        <CurrencyImageWrapper>
                            <CurrencyIcon currency={currencyFrom} />
                        </CurrencyImageWrapper>
                        <CurrencyCode>
                            {displayFormat(valueFrom, 6)}
                            <CurrencyCodeMobileOnly>
                                {currencyFrom}
                            </CurrencyCodeMobileOnly>
                        </CurrencyCode>
                        <CurrencyCodeNoMobile>
                            {currencyFrom}
                        </CurrencyCodeNoMobile>
                    </React.Fragment>
                ) : (
                    <StyledLoader/>
                ) }

                { accurate ? (
                    <ArrowAccurate>
                        &#8594;
                    </ArrowAccurate>
                ) : (
                    <Arrow>
                        &#10613;
                    </Arrow>
                )}
                { currencyTo && currencyFrom ? (
                    <React.Fragment>
                        <CurrencyImageWrapper>
                            <CurrencyIcon currency={currencyTo} />
                        </CurrencyImageWrapper>
                        <CurrencyCode>
                            {displayFormat(valueTo, 6)}
                            <CurrencyCodeMobileOnly>
                                {currencyTo}
                            </CurrencyCodeMobileOnly>
                        </CurrencyCode>
                        <CurrencyCodeNoMobile>
                            {currencyTo}
                        </CurrencyCodeNoMobile>
                    </React.Fragment>
                ) : (
                    <StyledLoader/>
                ) }
            </Wrapper>
        );
    }
}
