import styled from 'styled-components';
import {mobile} from '../styles';

export const Title = styled.h1`
	color: #EF8632;
	margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // align-items: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
`;

export const Label = styled.span`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #262626;
`;

export const Value = styled.div`
    display: flex;
    margin-top: 2px;
    margin-bottom: 14px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #868686;
    flex-direction: column;
`;

export const BoldOrangeValue = styled(Value)`
  color: #ef8632;
  font-weight: 800;
`;

export const ItemNotice = styled.div`
  outline: 2px solid #000;

  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  
  text-align: center;
  color: #000;
  background-color: #FFF;

  border-radius: 5px;
  padding: 15px;
  
  margin: 25px auto 25px 0px;

  width: 400px;

  overflow-wrap: normal;
  word-wrap: normal;
  word-break: normal;
  
  ${mobile`
    margin-left: auto;
    width: 100%;
    max-width: 300px;
  `}
`;

export const ItemFailed = styled(ItemNotice)`
    outline: 2px solid #F11010;
    color: #F11010;
    background-color: #FFDED8;
`;

export const ItemIncomplete = styled(ItemNotice)`
    outline: 2px solid #EF8632;
    color: #EF8632;
    background-color: #FFF7E9;
`;

export const ItemPending = styled(ItemNotice)`
    outline: 2px solid #006CEB;
    color: #006CEB;
    background-color: #E5EFFA;
`;

export const ItemComplete = styled(ItemNotice)`
    outline: 2px solid #00B64B;
    color: #00B64B;
    background-color: #E8F8ED;
`;

export const ItemText = styled.div`
    position: relative;
    margin: 12px 0px;
    // margin: auto;
    // text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #4A4A4A;

  p {
    display: inline;
    margin-bottom: 0;
    font-weight: normal !important;
  }
`;

export const ItemTextTitle = styled.div`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
`;

export const GrayBubble = styled.div`
  text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
  padding: 16px 20px;
  border-radius: 5px;
  background-color: #f1f1f1;
  color: #575757;
`;
