import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { LoadingDots } from '../../components/LoadingDots';
import { Component as StandardPage } from '../../components/StandardPage';
import { Container as QuickTrade } from './components/QuickTrade';
import { Container as Subscription } from './components/Subscription';


/* recent trades
import { Container as LatestTransactions } from '../../components/LatestTransactions';
import { FormattedMessage } from 'react-intl';
import { mobile } from '../../styles';
*/

const QuickTradeScreenContainer = styled.div`
  display: flex;
  //flex-flow: row wrap;
  justify-content: flex-end;
`;

const LoadingText = styled(LoadingDots)`
    font-size: 40px;
    font-weight: 700;
    height: 80vh;
    width: 100%;
    background-color: #021747;
    color: #eee;
    border-radius: 5px;
    text-shadow: 0px 2px 3px rgba(255,255,255,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FullScreenText = styled.div`
    font-size: 40px;
    font-weight: 700;
    height: 80vh;
    width: 100%;
    background-color: #fff;
    color: #0005;
    text-shadow: 0px 2px 3px rgba(255,255,255,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
`;

class PairsLoadingMessage extends React.PureComponent {
    render() {
        return (
            <LoadingText>
                <FormattedMessage
                    id="general.loading-pairs"
                    defaultMessage="Loading trade pairs"
                />
            </LoadingText>
        );
    }
}

class PairsNotAvailableMessage extends React.PureComponent {
    render() {
        return <FullScreenText>
            <FormattedMessage
                id="quicktrade.no-pairs-available"
                defaultMessage="No pairs currently available."
            />
        </FullScreenText>;
    }
}

/* recent trades
const LeftPane = styled.div`
  flex: 1 auto;
  border-right: 1px solid #ACB5BC60;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
`;

const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 410px;
  box-sizing: border-box;
  padding: 2em;
  position: relative;

  ${mobile`
    display: none;
  `}
`;

const TableWrapper = styled.div`
  position: absolute;
  width: calc(100% - 4em);
  left: 2em;
  top: 30px;
`;

const TransactionsTitle = styled.div`
	color: #000000;
	font-size: 18px;
	font-weight: 300;
	line-height: 27px;
  margin-bottom: 0.5em;
`;
*/

QuickTradeScreen.propTypes = {
    isUnsupportedCountry: PropTypes.bool,
    setReferredBy: PropTypes.func,
    jurisdiction: PropTypes.string,
    cookies: PropTypes.object,
    referredBy: PropTypes.string,
    enabledPairs: PropTypes.array,
    ws: PropTypes.object,
};

export default function QuickTradeScreen({ isUnsupportedCountry, jurisdiction, setReferredBy, cookies, referredBy, enabledPairs, ws }) {

    // TODO: use ws.state to present a decent loading message here...
    console.log(ws.state);

    const referrer = cookies.get('referrer');
    if ((!referrer || referrer === '') && (referredBy && referredBy.match(/^[0-9a-zA-Z]+$/))) {
        // referrer argument detected and cookie has not been set yet
        // console.log('set referrer cookie: ' + referredBy);
        cookies.set('referrer', referredBy, { path: '/', maxAge: 7776000 });  // 90 days
    } else {
        if (referrer && referredBy !== referrer) setReferredBy(referrer);
    }

    let content = <PairsLoadingMessage />;

    if (isUnsupportedCountry) {
        content = <QuickTradeScreenContainer><Subscription jurisdiction={jurisdiction}/></QuickTradeScreenContainer>;
    } else if (enabledPairs && enabledPairs.length > 0) {
        content = <QuickTradeScreenContainer><QuickTrade referrer={referrer}/></QuickTradeScreenContainer>;
    } else if (enabledPairs) {
        content = <PairsNotAvailableMessage />;
    }

    return (
        <StandardPage narrow>
            {content}
        </StandardPage>
    );

    /* recent trades
    return (
        <StandardPage>
            <QuickTradeScreenContainer>
                <LeftPane>
                    { isUnsupportedCountry ?
                        <Subscription country={country}/> :
                        <Trade ref={ref} />
                    }
                </LeftPane>

                <RightPane>
                    <TableWrapper>
                        <TransactionsTitle>
                            <FormattedMessage
                                id="navigation.transactions.title"
                                defaultMessage="Latest Trades"
                            />
                        </TransactionsTitle>
                        <LatestTransactions/>
                    </TableWrapper>
                </RightPane>
            </QuickTradeScreenContainer>
        </StandardPage>
    );
    */
}
