import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WithdrawalConfirmedScreen from './WithdrawalConfirmedScreen';
import { confirmAndProcessWithdrawal } from '../../actions/portfolio';

function mapStateToProps(state) {
    return {
        notifications: state.notifications,
        confirmationUuid: state.portfolio.confirmationUuid,
        confirmed: state.portfolio.confirmed,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            confirmAndProcessWithdrawal
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithdrawalConfirmedScreen);
