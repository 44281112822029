import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function BlogScreen() {

    return (
        <div>
            <FormattedMessage
                id="navigation.blog"
                defaultMessage="Blog"
            />

        </div>
    );

}
