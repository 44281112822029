import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Component as RouteButton } from '../../components/RouteButton';
import { Container as Footer } from '../../components/Footer';
import { Container as Header } from '../../components/Header';
import {
    HeroSlogan,
    HeroSection,
    HeroTagLine,
    HeroContent,
    HeroContainer,
    Container
} from '../../commonStyles/MarketingPageStyles';

import logoOnDark from '../../images/BlocpalX-ondark.svg';
//import nick02 from '../../images/nick_02.png';
import nick01 from '../../images/nick_01.webp';
import orangeback from '../../images/orangeback.png';
import logoOnLight from '../../images/BlocpalX-logoonlight.svg';
import arrow_icon from '../../images/arrow_icon.svg';
import stop from '../../images/stop.svg';
import blueback from '../../images/blueback.png';


const MobileBreak = styled.br`
     @media (min-width: 400px) {
       display: none;
     }
`;

const FeesSection = styled.section`
    max-width: 1440px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
`;

const FeeBigCont = styled.div`
    display: flex;
    align-items: center;
    margin: 40px 0px;
    @media (max-width: 1200px) {
       flex-direction: column;
       width: fit-content;
       text-align: center;
    }
`;

const FeeSlogan = styled.div`
    font-size: 38px;
    font-weight: 500;
    color: #021747;
    height: fit-content;
    margin: 0;
    @media (max-width: 600px) {
       font-size: 32px;
       margin-bottom: 20px;
    }
`;

const FeeCont = styled.div`
    display: flex;
    width: 100%;
    justify-content:space-around;
    @media (max-width: 600px) {
       flex-direction: column;
       width: fit-content;
       text-align: center;
    }
`;

const FeeItem = styled.div`
    font-size: 46px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 400px) {
       margin: 35px;
    }
`;

const FeeNumber = styled.div`
    font-size: 32px;
    font-weight: 400;
    margin: 0;
`;

const ButtonTeaser = styled.div`
    padding: 16px;
    border-radius: 14px;
    background: rgba(25, 47, 100, 0.79);
    backdrop-filter: blur(6px);
    width: 700px;
    display: flex;
    align-items: center;
   
     @media (max-width: 820px) {
       flex-direction: column;
       width: min-content;
     }
     @media (max-width: 500px) {
       width: auto;
     }
`;

const TeaserText = styled.p`
    padding:0;
    margin: 10px;
    color: #FFF;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    height: min-content;
    @media (max-width: 820px) {
       margin: 10px 0px 0px 0px;
       font-size: 16px;
     }
    @media (max-width: 500px) {
        margin: 10px 0px 0px 0px;
        font-size: 12px;
        text-align: center;
     }
`;


const LogoContainer = styled.div`
    width: 325px;
    @media (max-width: 600px) {
        width: 200px;
     }
`;

const ArrowIconContainer = styled.div`
    width: 66px;
    margin: auto;
    @media (max-width: 400px) {
        width: 38px;
     }
`;

const LogoContainerBottom = styled.div`
    width: 420px;
    @media (max-width: 500px) {
        width: 330px;
     }
    @media (max-width: 400px) {
        width: 230px;
     }
`;

const HeroImageSection = styled.section`
    background-image: url(${nick01});
    min-height:600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 400px) {
        min-height:330px;
    }
    @media (max-width: 450px) {
        min-height:400px;
    }
    @media (min-width: 799px) {
        display: none;
    }
`;

const WhySection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 40px 20px;
`;

const PledgeSection = styled.section`
    display: flex;
    min-height: 43rem;
    justify-content: center;
    flex-direction: column;
`;

const ProofSection = styled.section`
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1440px;
`;

const FinalSection = styled.section`
    display: flex;
    margin: 90px auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1440px;
    
    @media (max-width: 500px) {
        
        margin:37px auto;
     }
`;

const FinalSlogan = styled.h2`
    color: #021747;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    margin: 10px 20px;
    @media (max-width: 500px) {
        font-size: 30px;
        line-height: 36px;
     }
    @media (max-width: 400px) {
        font-size: 22px;
        line-height: 24px;
     }
`;

const WhoWeAre = styled.section`
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1440px;
    background-image: url(${blueback});
    background-repeat: no-repeat;
    background-position: left;
    padding:80px 0;
`;

const WhoLeader = styled.h2`
    color: #021747;
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    padding-left: 5.75rem;
    
    @media (max-width: 1000px) {
       padding: 0 1rem;
       text-align: center;
       margin:1rem 0;
     }
     @media (max-width: 400px) {
       font-size: 26px;
       line-height: 30px;
       text-align: left;
     }
`;

const Leader = styled.h3`
    color: #021747;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 400px) {
       font-size: 18px;
       line-height: normal;
     }
`;

const LeaderTag = styled.h4`
    color: #EF8632;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media (max-width: 400px) {
       font-size: 15px;
       line-height: normal;
       
     }
`;

const LeaderBullet = styled.p`
    color: #262626;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin:12px 0;
    @media (max-width: 400px) {
       font-size: 12px;
       line-height: normal;
     }
`;

const LeaderGroup = styled.div`
     max-width: 450px;
     padding-left: 50px;
     padding-right: 50px;
     @media (max-width: 1000px) {
       padding: 0 1rem;
       max-width: 600px;
     }
`;

const LeaderBlock = styled.div`
    display: flex;
    margin-left: 7.5rem;
    @media (max-width: 1280px) {
       margin-left: 0%;
     }
     @media (max-width: 1000px) {
       flex-direction: column;
       justify-content: center;
       align-items: center;
     }
`;

const ProofBlock = styled.div`
    display:flex;
    flex-direction: row;
    padding: 0 20px;
    @media (max-width: 1000px) {
        flex-direction: column;
        
     }
`;

const ProofText = styled.p`
    color: #262626;
    text-align: center;
    max-width: 70.938rem;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    padding-top: 40px;
    padding-bottom: 20px;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    @media (max-width: 800px) {
      font-size: 20px;
    }
    @media (max-width: 400px) {
      font-size: 15px;
      line-height: 26px;
      padding-top: 20px;
      margin-left: 1rem;
      margin-right: 1rem;
    }
`;

const ProofAccent = styled.b`
    font-size: 36px;
    @media (max-width: 800px) {
      font-size: 26px;
    }
    @media (max-width: 400px) {
      font-size: 20px;
    }
`;

const ProofBullet = styled.div`
    color: #262626;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding: 0 5rem;
    @media (max-width: 1280px) {
        padding: 1rem 1rem;
        max-width: 35rem;
     }
     @media (max-width: 400px) {
       font-size: 16px;
       line-height: normal;
     }
`;

const ProofP = styled.p`
    margin: 17px 0;
    
    @media (max-width: 1280px) {
        margin: 11px 0;
    }
`;

const ProofChallenge = styled.h3`
    color: #EF8632;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    max-width: 61.563rem;
    margin-top: 64px;
    padding: 0 20px;
    @media (max-width: 400px) {
      font-size: 16px;
      line-height: 24px;
      margin:10px;
    }
`;

const PledgeContainer = styled.div`
    border-radius: 0.938rem;
    background: #021747;
    max-width: 81.25rem;
    margin: auto;
    height: 100%;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${stop});
    @media (max-width: 1200px) {
      margin: 0 1rem;
    }
`;

const PledgeSlogan = styled.h2`
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 7.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 7.5rem;
    margin: 2.813rem;
    @media (max-width: 900px) {
      font-size:3.375rem;
      line-height: 3.375rem;
    }
    @media (max-width: 400px) {
        margin: 1rem;
    }
`;

const PledgeBullet = styled.h3`
    color: #FFF;
    text-align: center;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.813rem;
    margin: 2.813rem;
    max-width: 20.375rem;
    @media (max-width: 900px) {
      font-size:2rem;
     
    }
    @media (max-width: 800px) {
        margin: 2rem;
      
    }
    @media (max-width: 500px) {
        font-size:1.5rem;
        margin: 1rem;
      
    }
    @media (max-width: 400px) {
        margin: 0.75rem;
        font-size:1.25rem;
    }
`;

const WhyContainer = styled(Container)`
    flex-direction: column;
    margin: auto;
`;

const WhyBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
`;

const OrangeBack = styled.div`
    background-image: url(${orangeback});
    max-width: 1440px;
    background-repeat: no-repeat;
    background-position-x: right;
    margin: auto;
    background-position-y: 18.75rem;
`;

const Notice = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const StatementFirst = styled.h2`
    color: #EF8632;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    max-width: 53.25rem;
    margin: auto;
    padding-bottom: 70px;
    @media (max-width: 600px) {
        font-size: 24px;
        padding-bottom: 30px;
    }
    @media (max-width: 400px) {
        font-size: 20px;
        padding: 0;
        margin: 0;
        line-height: normal;
    }
`;

function WhyBPX() {
    const Slogan = styled.h3`
        color: #021747;
        font-family: 'Montserrat', sans-serif;
        font-size: 52px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (max-width: 600px) {
            font-size: 32px;
            line-height: 24px;
        }
        @media (max-width: 450px) {
            font-size: 26px;
            line-height: 24px;
        }
    `;

    const Block = styled.div`
        display: flex;
        flex-direction: column;
        max-width: 32.375rem;       
    `;

    const TagLine = styled.p`
        color: #262626;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (max-width: 600px) {
            font-size: 24px;
            line-height:normal;
        }
        @media (max-width: 400px) {
            font-size: 18px;
            line-height: 24px;
        }
    `;

    return (
        <Block>
            <Slogan>
                Why Bloc<span style={{ color: '#ef8632' }}>PalX</span>?
            </Slogan>
            <TagLine>
                Modern exchanges are overloaded with features. We have returned to the drawing board to build an exchange that just handles basics correctly.
            </TagLine>
        </Block>
    );
}

function TalkPoints({ slogan, tagline }) {

    const Block = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 35.625rem;
    margin: 3.125rem;
    
    @media (max-width: 800px) {
        margin: 20px 0px;
        }
    `;

    const Slogan = styled.h3`
    color: #021747;
    font-family: Inter, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    @media (max-width: 400px) {
            padding: 3px 0;
            font-size: 16px;
            line-height: normal;
        }
    `;

    const TagLine = styled.p`
    color: #262626;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    @media (max-width: 400px) {
            font-size: 15px;
            line-height: normal;
        }
    `;

    return (
        <Block>
            <Slogan>
                {slogan}
            </Slogan>
            <TagLine>
                {tagline}
            </TagLine>
        </Block>
    );
}

TalkPoints.propTypes = {
    slogan: PropTypes.string,
    tagline: PropTypes.string,
};


export default class LandingPage extends React.Component {

    static propTypes = {
        location: PropTypes.object,
    };

    componentDidMount() {
        document.title = 'Welcome to BlocPalX';
        window.gtag('event', 'page_view', {
            page_path: this.props.location.pathname + this.props.location.search + this.props.location.hash,
            page_search: this.props.location.search,
            page_hash: this.props.location.hash,
        });
    }

    render() {
        //console.log('LOC:', this.props.location);

        const talkpoints = [
            {
                slogan: 'IT Knowledge & Tech',
                tagline: 'Proof-of-reserves is only half of the conversation. We take on a full equation with proof-of-solvency utilizing zero knowledge. All our crypto infrastructure is bare-metal and managed by us, with no cloud or third-party exposure. Simply put, nobody can plug into our server and steal the private keys.'
            },
            {
                slogan: 'Moral Standing',
                tagline: 'We treat clients’ funds as a liability, not as an asset. We incur costs to keep your funds secure and take them into our custody solely to provide you with a service. We prioritize the duty of the client’s funds protection over the responsibilities towards shareholders, partnerships, and own business opportunities.'
            }
        ];

        return (
            <div style={{ display: 'block', width: '100%' }}>

                <div style={{ maxWidth: '1440px', margin: 'auto' }}>
                    <Header />
                </div>
                <HeroSection>
                    <HeroContainer>
                        <HeroContent>
                            <LogoContainer><img src={'/' + logoOnDark} style={{ width: '100%', height: 'auto' }}  alt="BlocPal Logo"/></LogoContainer>
                            <HeroSlogan>
                                Ease of Use of CeX & Radical Transparency of DeX
                            </HeroSlogan>
                            <HeroTagLine>
                                Don&apos;t trust. Verify. The first centralized exchange that doesn&apos;t ask for your trust but allows you to participate in proving its solvency. Trade crypto with minimum custody risk.
                            </HeroTagLine>
                            <div style={{ marginTop: '36px', marginBottom: '30px' }}>
                                <ButtonTeaser>
                                    <RouteButton
                                        to="/signup"
                                        title="Get Started"
                                        bsStyle="orange"
                                        fontSize="24px"
                                        fontWeight="600"
                                        borderRadius="10px"
                                        width="316px"
                                        height="52px"
                                    >
                                        <FormattedMessage
                                            id="auth.signup.getStarted"
                                            defaultMessage="Get Started"
                                        />
                                    </RouteButton>
                                    <TeaserText>
                                        The first 1000 users will get a zero-fee trading account for a year.
                                    </TeaserText>
                                </ButtonTeaser>
                            </div>
                            <Notice>
                                *Service is now available in limited regions
                            </Notice>
                        </HeroContent>
                    </HeroContainer>
                </HeroSection>
                <HeroImageSection>

                </HeroImageSection>
                <OrangeBack>
                    <WhySection>
                        <WhyContainer>
                            <WhyBlock >
                                <WhyBPX />
                                <div>
                                    {talkpoints.map((point) => <TalkPoints key={point.slogan} slogan={point.slogan} tagline={point.tagline} />)}
                                </div>
                            </WhyBlock>
                            <StatementFirst>
                                We are creating an exchange where you are encouraged to withdraw.
                            </StatementFirst>
                        </WhyContainer>
                    </WhySection>
                    <PledgeSection>
                        <PledgeContainer>
                            <PledgeSlogan>
                                We Will Never
                            </PledgeSlogan>
                            <div style={{ display: 'flex', margin: 'auto', flexWrap: 'wrap', justifyContent: 'center' }}>
                                <PledgeBullet>
                                    Reinvest your funds
                                </PledgeBullet>
                                <PledgeBullet>
                                    Run a fractional reserve
                                </PledgeBullet>
                                <PledgeBullet>
                                    Hide fees in fine print
                                </PledgeBullet>

                                <PledgeBullet>
                                    List assets of issuers we have no confidence in
                                </PledgeBullet>
                                <PledgeBullet>
                                    Convert your coins behind the scenes
                                </PledgeBullet>
                            </div>
                        </PledgeContainer>
                    </PledgeSection>
                </OrangeBack>
                <ProofSection>
                    <ProofText>
                        We maintain a <ProofAccent>100% reserve of your funds</ProofAccent> deposited with us. If you see that you have <b>0.25 btc</b> and <b>0.36 eth</b> in your wallet, this means we have exactly these amounts in our wallets, in those exact currencies. Not notional value, nor the ability to process your withdrawal. These exact coins. You don’t have to trust our word on it. You can prove it yourself:
                    </ProofText>
                    <ProofBlock >
                        <ProofBullet>
                            <ArrowIconContainer><img src={'/' + arrow_icon} style={{ width: '100%', height: 'auto' }} /></ArrowIconContainer>
                            <ProofP>All the funds in our custody are held in public hot, warm, and cold wallets. We periodically move small, predetermined amounts to prove our ownership of them. Those wallets are our reserves.</ProofP>
                        </ProofBullet>

                        <ProofBullet>
                            <ArrowIconContainer><img src={'/' + arrow_icon} style={{ width: '100%', height: 'auto' }} /></ArrowIconContainer>
                            <ProofP>We publish reports containing anonymized data on all user account holdings every quarter. Users can cryptographically verify and attest that their balances are accurately represented in the report. Anyone can add up those balances to determine our liabilities.</ProofP>
                        </ProofBullet>
                        <ProofBullet>
                            <ArrowIconContainer><img src={'/' + arrow_icon} style={{ width: '100%', height: 'auto' }} /></ArrowIconContainer>
                            <ProofP>By comparing reserves and liabilities, the community can confirm that our liabilities  are fully backed by our reserves, and we do not operate as a fractional exchange.</ProofP>
                        </ProofBullet>
                    </ProofBlock>
                    <ProofChallenge>
                        If a single user finds out that we haven&apos;t included their particular balance in the report, they can call us out on that, proving to the community that we are not 100% backed.
                    </ProofChallenge>
                    <div style={{ margin: '40px ' }}>
                        <RouteButton
                            to="/proofOfReserves"
                            title="Proof of Reserves"
                            bsStyle="orange"
                            fontSize="24px"
                            fontWeight="600"
                            borderRadius="10px"
                            width="316px"
                            height="52px"
                        >
                            <FormattedMessage
                                id="proofOfReserves.learnMore"
                                defaultMessage="Learn More"
                            />
                        </RouteButton>
                    </div>
                </ProofSection>
                <FeesSection>
                    <FeeBigCont >
                        <FeeSlogan>
                            Also, we use a maker/taker low-fee trading schedule.
                        </FeeSlogan>
                        <FeeCont>
                            <FeeItem>
                                Maker
                                <FeeNumber>
                                    0%
                                </FeeNumber>
                            </FeeItem>
                            <FeeItem>
                                Taker
                                <FeeNumber>
                                    0.30%
                                </FeeNumber>
                            </FeeItem>
                        </FeeCont>
                    </FeeBigCont>
                    <div style={{ margin: '0px 20px' }}>
                        <StatementFirst>
                            Makers trade for free, takers pay a fee.
                        </StatementFirst>
                    </div>

                </FeesSection>
                <WhoWeAre>
                    <WhoLeader>
                        Who We Are. <MobileBreak /> The Leadership.
                    </WhoLeader>
                    <LeaderBlock >
                        <LeaderGroup >
                            <Leader>
                                Ethan Burnside
                            </Leader>
                            <LeaderTag>
                                Lifetime Technologist & Blockchain Expert
                            </LeaderTag>
                            <LeaderBullet>3 exchanges under the belt </LeaderBullet>
                            <LeaderBullet>11 years of experience in building exchanges and crypto applications</LeaderBullet>
                            <LeaderBullet>$250m returned to clients after graceful exchange closure</LeaderBullet>
                            <LeaderBullet>20+ years of  experience in infrastructure security and  systems with millions of daily active users</LeaderBullet>

                        </LeaderGroup>
                        <LeaderGroup >
                            <Leader>
                                Nick Mellios
                            </Leader>
                            <LeaderTag>Entrepreneur and Visionary</LeaderTag>
                            <LeaderBullet>Led growth and financing of successful tech startups, processing over $40 Billion in transactions to date</LeaderBullet>
                            <LeaderBullet>Developed and launched enterprise systems in fintech, payments, digital rights management, software encryption and online games</LeaderBullet>
                            <LeaderBullet>15+ years of experience in obtaining and managing regulatory licensing and compliance with global authorities</LeaderBullet>
                        </LeaderGroup>
                    </LeaderBlock>
                </WhoWeAre>

                <FinalSection>
                    <FinalSlogan>
                        Back To The Basics.<MobileBreak /> Doing Everything Right.
                    </FinalSlogan>
                    <LogoContainerBottom><img src={'/' + logoOnLight} style={{ width: '100%', height: 'auto' }} /></LogoContainerBottom>
                </FinalSection>
                <div style={{ maxWidth: '1440px', margin: 'auto' }}>
                    <Footer />
                </div>
            </div >

        );


    }
}
