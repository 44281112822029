import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mobile } from '../../styles';


const colorMap = {
    white: {
        backgroundColor: '#FFFFFF',
        color: '#ef8632',
    },
    red: {
        backgroundColor: '#FF0000',
        color: '#FFFFFF',
    },
    green:  {
        background: 'linear-gradient(45.11deg, #56C200 0%, #7FD916 100%)',
        color: '#FFFFFF',
    },
    link: {
        color: '#FFFFFF',
    },
    menu: {
        backgroundColor: '#FFFFFF55',
        color: '#021747',
    },
    blue:  {
        // background: 'linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%)',
        backgroundColor: 'rgba(2, 23, 71, 1)',
        color: '#FFFFFF',
    },
    orange:  {
        // background: 'linear-gradient(90deg, rgba(239, 134, 50, 1) 0%, rgba(239, 134, 50, 0.7) 100%)',
        backgroundColor: 'rgba(239, 134, 50, 1)',
        color: '#FFFFFF',
    },
};

const ButtonWrapper = styled.span`
    > a {
      display: inline-block;
      text-align: center;
      background: ${props => props.buttonTheme.background};
      background-color: ${props => props.backgroundColor ? props.backgroundColor : props.buttonTheme.backgroundColor};
      color: ${props => props.color ? props.color : props.buttonTheme.color};
      text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
      padding-left: ${props => props.width ? '' : '18px'};
      padding-right: ${props => props.width ? '' : '18px'};
      padding-top: ${props => props.height ? '' : '8px'};
      padding-bottom: ${props => props.height ? '' : '8px'};
      border-radius: ${props => props.borderRadius};
      text-decoration: ${props => props.buttonTheme.textDecoration || 'none'};
      font-size: ${props => props.fontSize};
      font-weight: ${props => props.fontWeight};
      width: ${props => props.width};
      line-height: ${props => props.height};
      // line-height: 38px;  ... no problems with the header pulling this out but maybe issues elsewhere?
      letter-spacing: 0.6px;
      border: ${props => props.borderWidth} solid ${props => props.borderColor ? props.borderColor : (props.backgroundColor ? props.backgroundColor : props.buttonTheme.backgroundColor)};
      white-space: nowrap;
      &:hover {
        filter: brightness(1.05);
      }
      ${mobile`
          padding-left: ${props => props.width ? '' : '10px'};
          padding-right: ${props => props.width ? '' : '10px'};
          padding-top: ${props => props.height ? '' : '8px'};
          padding-bottom: ${props => props.height ? '' : '8px'};
      `}
    }
`;

const RouteButton = (props) => {
    const buttonTheme = colorMap[props.bsStyle || 'blue'];
    return (
        <ButtonWrapper 
            buttonTheme={buttonTheme} 
            borderRadius={props.borderRadius}
            borderWidth={props.borderWidth}
            borderColor={props.borderColor}
            backgroundColor={props.backgroundColor}
            color={props.color}
            fontSize={props.fontSize}
            fontWeight={props.fontWeight}
            width={props.width}
            height={props.height}
            {...props}>
            <Link to={props.to} title={props.title}>{props.children}</Link>
        </ButtonWrapper>
    );
};

RouteButton.propTypes = {
    to: PropTypes.string,
    title: PropTypes.string,
    // make it work with FormattedMessage without complaining
    // children: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array
    ]),
    name: PropTypes.string,
    value: PropTypes.string,
    bsStyle: PropTypes.oneOf(['green', 'orange', 'white', 'link', 'menu', 'blue']),
    type: PropTypes.oneOf([null, 'Link']),
    borderRadius:  PropTypes.string,
    borderColor: PropTypes.string,
    borderWidth: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

RouteButton.defaultProps = {
    name: null,
    value: null,
    bsStyle: 'blue',
    type: null,
    borderRadius: '7px',
    borderColor: null,
    borderWidth: '2px',
    color: null,
    fontSize: '12px',
    fontWeight: '800',
    width: null,
    height: null,
};

export default RouteButton;
