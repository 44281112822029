import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dateFns from 'date-fns';
import DropdownMenu from 'react-dd-menu';


const Wrapper = styled.div`
  background-color: #FFFFFF;
  padding-bottom: 10px;
`;

const Table = styled.div`
  //padding: 40px 40px 0;
  display: table;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-bottom: 1px solid #eee;
`;

const Header = styled.div`
  display: table-row;

  >div {
      padding: 10px 20px;
    }
`;

const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  font-size: 12px;

  // width: 22%;
`;

const LastCell = styled(Cell)`
  // width: 34%;
  text-align: right;

  span {
    color: #ef8632;
    cursor: pointer;
  }
`;

const HeaderRow = styled(Header)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

    color: #ACB5BC;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.71px;
    line-height: 20px;

    >div {
      padding: 10px 20px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;
    }
`;

const TableRow = styled.div`
  display: table-row;

  &:nth-child(2n) {
    background-color: #02174708;
  }

  >div {
    padding: 10px 20px;
  }
`;

const DateCell = styled(LastCell)`
	color: #4A4A4A;
	font-size: 12px;
	line-height: 21px;
`;

const TypeCell = styled(Cell)`
    text-transform: capitalize;
`;

const Pagination = styled.div`
  padding: 10px 20px;
`;

const PagingPuttons = styled.div`
	height: 56.39px;
	width: 105.2px;
	border: 2px solid #ef8632;
	border-radius: 5px;
	display: inline-flex;
	align-items: stretch;

	span {
	  width: 50%;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  color: #ef8632;
	  cursor: pointer;

	  &:first-child {
	    border-right: 1px solid #ef8632;
	  }
	}
 `;

const PageInput = styled.input`
	height: 49px;
	width: 57.97px;
	border: 1px solid #CFCFCF;
	border-radius: 3px;
	background-color: #FFFFFF;
	margin-left: 30px;
	margin-right: 10px;
	text-align: center;
	font-size: 15px;
`;

const OperationTypeCell = styled(Cell)`
  display: block;
  // width: 105px;
`;

const TitleCell = styled(Cell)`
	color: #9B9B9B;
	font-size: 24px;
	line-height: 36px;
`;

const StyledMenu = styled(DropdownMenu)`
  display: inline-block;
  margin-left: 5px;
  position: relative;

  >span {
    cursor: pointer;
    color: #ef8632;
    text-transform: capitalize;
  }

  .dd-menu-items {
    position: absolute;
    width: 170px;
    background-color:#fff;
    box-shadow: 0 0 2px #ddd;

    ul {
      margin: 0;
      padding: 0;

      span {
        display: block;
        text-transform: capitalize;
        cursor: pointer;
        padding: 3px 15px;

        &:hover {
          background-color:#eee;
        }
      }
    }
  }
`;

const DownloadCsvSpan = styled.div`
    color: #ef8632;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 0 15px;
    float: right;
    margin-right: 15px;
    margin-top: 5px;
`;

const TYPES = [
    'All',
    'BUY',
    'SELL',
];


export default class MyTradeHistory extends React.Component {

    static propTypes = {
        pair: PropTypes.string,

        baseCoinConfigName: PropTypes.string,
        quoteCoinConfigName: PropTypes.string,
        tradeHistory: PropTypes.object,
        tradeHistoryInitialized: PropTypes.bool,

        getTradeHistory: PropTypes.func,

        history: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            pair: null,
            tradeHistoryRequested: false,
            isMenuOpen: false,
            operationType: 'All',
            selectedPage: 1,
        };
    }

    componentDidMount() {
        // const { tradeHistoryInitialized } = this.props;
        const { tradeHistoryRequested } = this.state;

        // if (!tradeHistoryInitialized && !tradeHistoryRequested) {
        if (!tradeHistoryRequested) {
            this.props.getTradeHistory({
                pair: this.props.pair,
                side: this.state.operationType,
            });
            this.setState({
                tradeHistoryRequested: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.tradeHistory !== prevProps.tradeHistory) {
            this.setState({
                selectedPage: this.props.tradeHistory.number+1,
            });
        }
    }

    handleChange = event => {
        if (event.target.name === 'page') {
            this.setState({
                selectedPage: event.target.value,
            });
        }
    };

    updatePage = (page) => {
        const { tradeHistory } = this.props;

        if (page > (tradeHistory.totalPages - 1)) {
            page = tradeHistory.totalPages - 1;
        }
        if (page >= 0) {
            this.props.getTradeHistory({
                pair: this.props.pair,
                side: this.state.operationType,
                page
            });
        }
    };

    toggleOperationsMenu = () => {
        this.setState(state => ({
            isMenuOpen: !state.isMenuOpen
        }));
    };

    selectType = (type) => {
        this.setState({
            operationType: type,
            isMenuOpen: false,
        });
        this.props.getTradeHistory({
            pair: this.props.pair,
            side: type,
        });
    };

    closeMenu = () => {
        this.setState({ isMenuOpen: false });
    };


    render() {

        const { tradeHistory, baseCoinConfigName, quoteCoinConfigName, history } = this.props;
        const { operationType, isMenuOpen, selectedPage } = this.state;

        const menuOptions = {
            isOpen: isMenuOpen,
            close: this.closeMenu,
            toggle: <span onClick={this.toggleOperationsMenu}>{operationType.replace(/_/g , ' ').toLocaleLowerCase()}</span>,
            align: 'left'
        };

        return tradeHistory ? (
            <Wrapper>
                <DownloadCsvSpan onClick={() => { history.push('/account/profile/downloads'); }}>
                    &#11123; Download As CSV
                </DownloadCsvSpan>
                <Table>
                    <Header>
                        <TitleCell/>
                        <OperationTypeCell>
                            <React.Fragment>
                                Side:
                                <StyledMenu {...menuOptions}>
                                    {TYPES.filter(type => type !== operationType).map((type, i) => (
                                        <span key={i} onClick={() => this.selectType(type)}>
                                            {type.replace(/_/g , ' ').toLocaleLowerCase()}
                                        </span>
                                    ))}
                                </StyledMenu>
                            </React.Fragment>
                        </OperationTypeCell>
                        <Cell/>
                        <Cell/>
                        <Cell/>
                        <LastCell>
                            Time: <span>All</span>
                        </LastCell>
                    </Header>

                    <HeaderRow>
                        <Cell>
                            Trade
                        </Cell>
                        <Cell>
                            Side
                        </Cell>
                        <Cell>
                            Qty
                        </Cell>
                        <Cell>
                            Price
                        </Cell>
                        <Cell>
                            Fee
                        </Cell>
                        <LastCell>Time</LastCell>
                    </HeaderRow>
                    {tradeHistory && tradeHistory.content && tradeHistory.content.map((trade, i) => (
                        <TableRow key={i}>
                            <Cell>
                                # {trade.id ? trade.id : ''}
                            </Cell>
                            <TypeCell>
                                {trade.isBuyer ? 'BUY' : 'SELL'}
                                {/* item.type.replace('_', ' ').toLowerCase() */}
                            </TypeCell>
                            <Cell>
                                {trade.qty ? <span>{trade.qty} {baseCoinConfigName}</span> : ''}
                            </Cell>
                            <Cell>
                                {trade.price ? <span>{trade.price} {quoteCoinConfigName}</span> : ''}
                            </Cell>
                            <Cell>
                                {trade.commission ? <span>{trade.commission} {trade.commissionAsset}</span> : ''}
                            </Cell>
                            <DateCell>
                                {dateFns.format(new Date(trade.time), 'YYYY/MM/DD HH:mm:ss')}
                            </DateCell>
                        </TableRow>
                    ))}
                </Table>


                {tradeHistory.totalPages > 1 ? (
                    <Pagination>
                        <PagingPuttons>
                            <span onClick={() => !tradeHistory.first && this.updatePage(tradeHistory.number - 1)}>&#60;</span>
                            <span onClick={() => !tradeHistory.last && this.updatePage(tradeHistory.number + 1)}>&#62;</span>
                        </PagingPuttons>

                        <PageInput
                            name="page"
                            value={selectedPage}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    this.updatePage(Number(selectedPage)-1);
                                }
                            }}
                            onChange={this.handleChange}
                        />
                        <span>
                            of {tradeHistory.totalPages}
                        </span>
                    </Pagination>
                ) : null}

            </Wrapper>

        ) : null;
    }
}
