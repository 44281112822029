import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import QuickTradePane from './QuickTradePane';
import { changePair, stopOneWayPairFeed, submitQuickTrade } from '../../../../actions/transactions';
import { getCurrencyFromOptions, getCurrencyToOptions, getCurrencyToConfig, getCurrencyFromConfig, getCurrencyRateFromCurrentPair, getCurrencyRateUpdatedTime } from '../../../../selectors';

function mapStateToProps(state) {
    return {
        notifications: state.notifications,
        currencyFrom: state.currency.currentPair.currencyFrom,
        currencyTo: state.currency.currentPair.currencyTo,
        coinConfig: state.currency.coinsConfig,
        rate: getCurrencyRateFromCurrentPair(state),
        rateUpdated: getCurrencyRateUpdatedTime(state),
        currencyFromOptions: getCurrencyFromOptions(state),
        currencyToOptions: getCurrencyToOptions(state),
        currencyFromConfig: getCurrencyFromConfig(state),
        currencyToConfig: getCurrencyToConfig(state),
        tradeLocked: state.currency.tradeLocked,
        maintenanceMode: state.currency.maintenanceMode,
        referredBy: state.referrals.referredBy,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changePair,
            stopOneWayPairFeed,
            submitQuickTrade
        },
        dispatch
    );
}

export default injectIntl(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickTradePane)));
