import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mobile } from '../../styles';

import logo from '../../images/BlocpalX-logoonlight.svg';


const AppLogo = styled(Link)`
  text-align: center;
  
  img {
    width: 241px;
    vertical-align: middle;
  }

  ${mobile`
    img {
        // width: 170px;
    }
  `}
`;

export default class Logo extends React.Component {

    static propTypes = {
        children: PropTypes.any,
    };

    render() {
        return (
            <AppLogo to="/" title="BlocPalX Logo">
                <img src={'/' + logo} alt="BlocPalX Logo" />
            </AppLogo>
        );
    }

}
