import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import dateFns from 'date-fns';
import { NotificationManager } from 'react-notifications';
import config from 'config';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Component as Button } from '../../../../components/Button';
import { Component as QRCode } from '../../../../components/QRCode';
import { Component as InputGroup } from '../../../../components/InputGroup';
import { Component as RadioGroup } from '../../../../components/RadioGroup';
import { Component as Input } from '../../../../components/Input';
import { Component as Checkbox } from '../../../../components/Checkbox';
import { Component as SmallButton } from '../../../../components/SmallButton';
import { Container as InputTwoFa } from '../../../../components/InputTwoFa';

import { validateAnyAuthCode, validateGauthCode, validateSmsCode, validateYubikeyCode } from '../../../../utils';
import { Title } from '../../../../commonStyles/AccountProfileStyles';

import { mobile } from '../../../../styles';
import refreshIcon from '../../../../images/ic-refresh-dark-md.svg';
import warningIcon from '../../../../images/ic-warning.svg';
import copyIcon from '../../../../images/ic-copy.svg';

const isSMSEnabled = config.get('features.sms');

// this module is over 800k so we dynamically load it
const ReactPasswordStrength = lazy(() => import('react-password-strength'));


const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const SecuredActionsTitle = styled.div`
   margin-top: 50px;
   color: #9B9B9B;
   font-size: 24px;
   line-height: 36px;
`;

const Warning = styled.img`
  padding-right: 5px;
`;

const ErrorMessage = styled.span`
  color: #D0021B;
  left: 0;
  // width: 100%;
  font-size: 14px;
  white-space: nowrap;
`;

const CopyToClipboardSpan = styled.span`
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 !important;
`;

const FormWrapper = styled.div`
    // background-color: #fff;
    // border-radius: 10px;
    // box-shadow: 0 0 26px -14px rgba(11,32,78,0.85);
    // padding: 30px 100px 50px 100px;
    // box-sizing: border-box;
    width: 300px;
    margin-top: 10px;
    // margin-left: auto;
    // margin-right: auto;

    ${mobile`
      margin-left: auto;
      margin-right: auto;
      // padding: 30px 30px 50px 30px;
    `};
`;

const FormTitle = styled.h1`
    font-size: 24px;
    line-height: 36px;
    // text-align: center;
    font-weight: 500;
    margin-bottom: 30px;

    p {
      margin: 10px 0 0 0;
      display: block;
      font-size: 14px;
    }
`;

const AuthRow = styled.div`
  margin-left: 0;
  margin-top: 35px;
  font-weight: 500;
`;

const LeftInputTwoFa = styled.div`
  display: inline-block;
  width: 150px;
  margin-right: 5px;
  margin-top: 15px;
  vertical-align: top;
`;

const RightButtonTwoFa = styled(Button)`
  // display: block;
  // width: 47%;
  width: 140px;
  margin-left: 5px;
  margin-top: 32px;
  vertical-align: top;
  white-space: nowrap;
`;

const HalfButton = styled(Button)`
  // display: block;
  // width: 47%;
  width: 145px;
  margin-right: 5px;
  margin-top: 35px;
  vertical-align: top;
  white-space: nowrap;
`;

const WideButton = styled(Button)`
  display: block;
  width: 300px;
  margin-top: 35px;
`;

const StyledButton = styled(Button)`
  margin-left: 15px;
  margin-right: 15px;
`;

const AuthQR = styled(QRCode)`
`;

const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  
  span {
	// color: #4A4A4A;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	max-width: 250px;
	margin-top: 30px;
  }

  p {
    text-transform: uppercase;
    font-weight: bold;
    margin: 30px 0 0 0;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
`;

const YubiInput = styled(Input)`
  width: 270px;
  // justify-content: center;
  // text-align: center;
`;

const CodeInput = styled(Input)`
  width: 250px;
  justify-content: center;
  // text-align: center;
  font-size: 32px;
  height: 85px;
  font-weight: 300;
  letter-spacing: 11px;

  ::placeholder {
      text-align: center;
      font-size: 20px;
  }
`;

const HowToUse = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-top: 70px;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;

  ${mobile`
    text-align: center;
  `};
  a {
    text-align: center;
    width: 100%;
    background: #EDEEF2;
    color: #021747;
    padding: 30px 40px;
    text-decoration: none;

    ${mobile`
      width: 300px;
      padding: 25px;
    `};
  }
  img {
    margin: 0;
    margin-right: 10px;
  }
`;

const Table = styled.div`
  padding-bottom: 20px;
  display: table;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-bottom: 1px solid #eee;
`;

const Header = styled.div`
  display: table-row;

  >div {
      padding: 10px 20px;
    }
  // No idea what this does... if you know please reach out to Ethan }History
`;

const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  font-size: 12px;
`;

const CellNoMobile = styled(Cell)`
  ${mobile`
    display: none;
  `};
`;

const LastCell = styled(Cell)`
  // width: 34%;
  text-align: center;

  span {
    // color: #ef8632;
    cursor: pointer;
  }
`;

const HeaderRow = styled(Header)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

    color: #ACB5BC;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.71px;
    line-height: 20px;

    >div {
      padding: 10px 20px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;

      ${mobile`
        padding: 3px;
      `};
    }
`;

const TableRow = styled.div`
  display: table-row;

  &:nth-child(2n) {
    background-color: #02174708;
  }

  >div {
    padding: 10px 20px;

    ${mobile`
      padding: 3px;
    `};
  }
`;

const TitleCell = styled(Cell)`
	color: #9B9B9B;
	font-size: 24px;
	line-height: 36px;
`;

const TableBody = styled.div`
  display: table-row-group;
`;

const StyledCheckbox = styled.div`
  margin: 20px;
`;

const sideBySideStyle = {
    display: 'flex',
    alignItems: 'center'
};

const CommentInputGroup = styled(InputGroup)`
  width: 300px;
  margin-bottom: 25px;
  position: relative;
`;

const TwoFaTitle = styled.div`
  margin-top: 40px;
`;

const AuthInputGroup = styled(InputGroup)`
  width: 300px;
  margin-bottom: 25px;
  position: relative;
  
  a {
    position: absolute;
    top: 0;
    right: 0;
	color: #ef8632;
	font-size: 14px;
	line-height: 21px;
  }
`;


export default class ProfileTwoFaConfiguration extends React.Component {

    static propTypes = {
        updatePassword: PropTypes.func,
        clear2faAuths: PropTypes.func,
        get2faAuths: PropTypes.func,
        requestNewGauth: PropTypes.func,
        clearRequestSmsSend: PropTypes.func,
        requestSendSmsCode: PropTypes.func,
        requestAdd2fa: PropTypes.func,
        requestUpdate2faActions: PropTypes.func,
        requestDelete2fa: PropTypes.func,
        clearErrors: PropTypes.func,
        auth: PropTypes.object,
        securedActions: PropTypes.array,
        securedMethods: PropTypes.array,
        isNewCredentialsError: PropTypes.bool,

        match: PropTypes.object,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state={
            confirmPassword: '',
            password: '',
            pwError: '',
            pwIsValid: false,
            generalError: false,
            type: 'NONE',
            comment: '',
            phoneNumber: '',
            newCode: '',
            oldCode: '',
            error: null,
            qrLoadError: 'Loading...',
            loading: false,
            loginAction: null,
            editAccountAction: null,
            tradeAction: null,
            withdrawAction: null,
            apiWithdrawAction: null,
            deleteId: null,
            deleteString: '',
            gAuthKeyCopied: false,
        };
    }

    componentDidMount() {
        this.props.clearErrors();
        const { auth, match } = this.props;

        if (!auth.userAuths) {
            this.props.get2faAuths();
        }

        if (match && match.params) {
            if (match.params.label) {
                this.setState({
                    comment: decodeURIComponent(match.params.label),
                });
            }

            if (match.params.screen && match.params.screen === 'newGAuth') {
                this.props.requestNewGauth(decodeURIComponent(match.params.label));
            }

            if (match.params.screen && match.params.screen === 'newSms') {
                // TODO: figure out how to track the phoneNumber through a reload
                // this.props.requestSendSmsCode(phoneNumber);
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.refreshAuthsTable !== prevProps.auth.refreshAuthsTable && this.props.auth.refreshAuthsTable === true) {
            this.setState({
                type: 'NONE',
                comment: '',
                phoneNumber: '',
                newCode: '',
                oldCode: '',
                loginAction: null,
                editAccountAction: null,
                tradeAction: null,
                withdrawAction: null,
                apiWithdrawAction: null,
                deleteId: null,
                deleteString: '',
            });
            this.props.clear2faAuths();
            this.props.get2faAuths();
            this.props.clearErrors();
            this.props.history.push('/account/profile/config2fa');
        }

        const { match } = this.props;
        const { comment } = this.state;
        if (this.props.auth.isSmsSendSuccess !== prevProps.auth.isSmsSendSuccess && match.params.screen === 'newTwoFactor' && this.props.auth.isSmsSendSuccess === true) {
            this.props.clearRequestSmsSend();
            this.props.history.push('/account/profile/config2fa/newSms/' + encodeURIComponent(encodeURIComponent(comment.replace(/\/|\\|\.|%/g, ''))));
        }
    }

    submitRefreshAuths = () => {
        this.props.clear2faAuths();
        this.props.get2faAuths();
    }

    changeCallback = state => {
        this.setState({
            password: state.password,
            pwIsValid: state.isValid,
        });
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onTypeChange = (type) => {
        this.setState({
            type: type.target.value
        });
    };

    onChooseMethod = () => {
        const { type, comment, phoneNumber } = this.state;
        if (type === 'SMS') {
            this.props.clearErrors();
            this.props.clearRequestSmsSend();
            this.props.requestSendSmsCode(phoneNumber);
        } else if (type === 'GOOGLE') {
            this.props.requestNewGauth(comment);
            this.props.history.push('/account/profile/config2fa/newGAuth/' + encodeURIComponent(encodeURIComponent(comment.replace(/\/|\\|\.|%/g, ''))));
        } else if (type === 'YUBIKEY') {
            this.props.history.push('/account/profile/config2fa/newYubikey/' + encodeURIComponent(encodeURIComponent(comment.replace(/\/|\\|\.|%/g, ''))));
        }
    };

    onCreateNew = () => {
        this.props.history.push('/account/profile/config2fa/newTwoFactor');
    }

    onResetPassForm = () => {
        this.props.history.push('/account/profile/config2fa/resetPassword');
    }

    onCancelAction = () => {
        this.props.clearRequestSmsSend();
        this.setState({
            type: 'NONE',
            comment: '',
            phoneNumber: '',
            newCode: '',
            oldCode: '',
            loginAction: null,
            editAccountAction: null,
            tradeAction: null,
            withdrawAction: null,
            apiWithdrawAction: null,
            deleteId: null,
            deleteString: '',
        });
        this.props.history.push('/account/profile/config2fa');
    }

    displayDeleteAuth = (id, description) => {
        this.setState({
            deleteId: id,
            deleteString: description,
        });
        // this.props.history.replace('/account/profile/config2fa/removeTwoFactor');
    }

    submitDeleteAuth = () => {
        const { deleteId, oldCode } = this.state;
        this.props.requestDelete2fa(deleteId, oldCode);
    }

    onContinue = () => {
        const { auth, match } = this.props;
        const { comment, phoneNumber, newCode, oldCode } = this.state;

        let type;
        if (match && match.params) {
            if (match.params.screen && match.params.screen === 'newYubikey') {
                type='YUBIKEY';
            }
            if (match.params.screen && match.params.screen === 'newGAuth') {
                type='GOOGLE';
            }
            if (match.params.screen && match.params.screen === 'newSms') {
                type='SMS';
            }
        }

        this.props.requestAdd2fa(type, auth.secret, comment, newCode, oldCode, phoneNumber, null);
    }

    onUpdateSecuredActions = () => {
        const { securedActions } = this.props;
        const { oldCode, loginAction, editAccountAction, tradeAction, withdrawAction, apiWithdrawAction, } = this.state;

        const loginAct = (loginAction == null) ? securedActions.includes('SIGN_IN') : loginAction;
        const editAccountAct = (editAccountAction == null) ? securedActions.includes('EDIT_ACCOUNT') : editAccountAction;
        const tradeAct = (tradeAction == null) ? securedActions.includes('TRADE') : tradeAction;
        const withdrawAct = (withdrawAction == null) ? securedActions.includes('WITHDRAW') : withdrawAction;
        const apiWithdrawAct = (apiWithdrawAction == null) ? securedActions.includes('API_WITHDRAW') : apiWithdrawAction;

        const actions = [];
        if (loginAct) actions.push('SIGN_IN');
        if (editAccountAct) actions.push('EDIT_ACCOUNT');
        if (tradeAct) actions.push('TRADE');
        if (withdrawAct) actions.push('WITHDRAW');
        if (apiWithdrawAct) actions.push('API_WITHDRAW');

        this.props.requestUpdate2faActions(actions, oldCode);
    }

    onResetPassword = async event => {
        event.preventDefault();
        const { password, confirmPassword, oldCode } = this.state;
        const token = sessionStorage.getItem('token');
        // const { auth } = this.props;

        if (password.length >= 8 && password === confirmPassword) {
            const response = await this.props.updatePassword({
                password: password,
                token: token,
                code: oldCode,
            });

            if (response.error || response.payload.status.code === 112401 || response.payload.status.code === 112400) {
                NotificationManager.error(response.payload.status.message, undefined, 15000);
                this.setState({
                    pwError: response.payload.status.message,
                    loading: false
                });
            } else {
                NotificationManager.success('Password Reset', 'Password Change Successful');
                this.onCancelAction();
            }
        } else if (password !== confirmPassword) {
            this.setState({
                pwError: 'Passwords must match',
                loading: false
            });
        } else {
            this.setState({
                pwError: 'Password must be greater than 8 characters',
                loading: false
            });
        }
    };

    onCopyGAuthKey() {
        this.setState({
            gAuthKeyCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                gAuthKeyCopied: false,
            });
        }, 1500);
    }

    validatePass = (pwIsValid, password, confirmPassword) => {
        return (pwIsValid && password === confirmPassword);
    };


    render() {
        const { securedMethods, securedActions, auth, isNewCredentialsError, match } = this.props;
        const { pwError, pwIsValid, password, confirmPassword } = this.state;
        const { gAuthKeyCopied, newCode, oldCode, qrLoadError, error, loading, type, comment, phoneNumber, deleteId, deleteString } = this.state;
        const { loginAction, editAccountAction, tradeAction, withdrawAction, apiWithdrawAction, } = this.state;

        //
        // TODO: Move all this to componentDidUpdate() ...
        //
        let passwordError = pwError;
        let passwordConfirmError = '';

        if (confirmPassword && confirmPassword.length > 0 && password !== confirmPassword) {
            passwordConfirmError = 'Passwords must match';
        }

        const resetPassDisabled = !password ||
            !confirmPassword ||
            !this.validatePass(pwIsValid, password, confirmPassword) ||
            (!securedMethods.includes('NONE') && !validateAnyAuthCode(oldCode));

        const selectTypeDisabled = type === 'NONE' || comment === '' || (type === 'SMS' && phoneNumber === '');

        const continue2faDisabled = loading || (!securedMethods.includes('NONE') && !validateAnyAuthCode(oldCode));

        const continueSmsDisabled = continue2faDisabled || !newCode || !validateSmsCode(newCode);
        const continueYubikeyDisabled = continue2faDisabled || !newCode || !validateYubikeyCode(newCode);
        const continueGauthDisabled = continue2faDisabled || !newCode || !validateGauthCode(newCode);

        const continueChangeActionsDisabled = loading || (!securedMethods.includes('NONE') && !validateAnyAuthCode(oldCode));
        const formSecuredActionsChanged =   (loginAction != null && loginAction !== securedActions.includes('SIGN_IN')) ||
                                            (editAccountAction != null && editAccountAction !== securedActions.includes('EDIT_ACCOUNT')) ||
                                            (tradeAction != null && tradeAction !== securedActions.includes('TRADE')) ||
                                            (withdrawAction != null && withdrawAction !== securedActions.includes('WITHDRAW')) ||
                                            (apiWithdrawAction != null && apiWithdrawAction !== securedActions.includes('API_WITHDRAW'));

        let displayScreen = (match && match.params && match.params.screen) ? match.params.screen : '';
        if (deleteId && deleteId !== '') {
            displayScreen = 'removeTwoFactor';
        }

        // console.log(auth.qr);
        // console.log(auth.secret);
        // console.log('comment', comment);
        // console.log('type', type);
        // console.log('delete Id/String', deleteId, deleteString);

        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="profile.2fa.title"
                        defaultMessage="Account Authentication"
                    />
                </Title>

                {displayScreen === '' ? (
                    <React.Fragment>
                        {(!securedMethods.includes('NONE')) && (
                            <React.Fragment>
                                <p>
                                    <FormattedMessage
                                        id="profile.auth.passwordResetText"
                                        defaultMessage="Reset your BlocPalX password"
                                    />
                                </p>

                                <StyledButton onClick={() => {this.onResetPassForm();}} title="Reset Password" bsStyle='orange'>
                                    <FormattedMessage
                                        id="profile.auth.resetPass"
                                        defaultMessage="Reset Password"
                                    />
                                </StyledButton>

                            </React.Fragment>
                        )}

                        <TwoFaTitle>
                            <FormattedMessage
                                id="profile.2fa.subtitle_2fa_required"
                                defaultMessage="BlocPalX requires 2FA for your safety"
                            />
                        </TwoFaTitle>

                        <Table>
                            <Header>
                                <TitleCell>
                                    <FormattedMessage
                                        id="profile.2fa.table_title"
                                        defaultMessage="2FA Methods"
                                    />
                                </TitleCell>
                                <Cell/>
                                <CellNoMobile/>
                                <LastCell>
                                    <a onClick={() => {this.submitRefreshAuths();}} title="Refresh"><img src={'/' + refreshIcon} alt="Refresh" title="Refresh" width="20" height="20"/></a>
                                </LastCell>
                            </Header>
                            <HeaderRow>
                                <Cell>
                                    <FormattedMessage
                                        id="profile.2fa.table_method_title"
                                        defaultMessage="Method"
                                    />
                                </Cell>
                                <Cell>
                                    <FormattedMessage
                                        id="profile.2fa.table_device_label_title"
                                        defaultMessage="Label"
                                    />
                                </Cell>
                                <CellNoMobile>
                                    <FormattedMessage
                                        id="profile.2fa.table_date_created_title"
                                        defaultMessage="Date Created"
                                    />
                                </CellNoMobile>
                                <LastCell/>
                            </HeaderRow>
                            <TableBody>
                                {(auth.userAuths && auth.userAuths.length > 0) ? auth.userAuths.map((key) =>
                                    <TableRow key={key.id}>
                                        <Cell style={sideBySideStyle}>
                                            {(key.twoFaType === 'SMS') ? 'SMS: ' + key.phoneNumber : key.twoFaType.replace('GOOGLE', 'Google Authenticator').replace('YUBIKEY', 'Yubikey')}
                                        </Cell>
                                        <Cell>
                                            {key.comment}
                                        </Cell>
                                        <CellNoMobile>
                                            {dateFns.format(new Date(key.createdDate), 'MM/DD/YY hh:mm')}
                                        </CellNoMobile>
                                        <LastCell>
                                            {(auth.userAuths.length > 1) && (
                                                <SmallButton bsStyle='red' onClick={() => {this.displayDeleteAuth(key.id, (key.twoFaType === 'SMS') ? 'SMS: ' + key.phoneNumber : key.twoFaType.replace('GOOGLE', 'Google Authenticator').replace('YUBIKEY', 'Yubikey') + ': ' + key.comment);}} title="Delete">
                                                    <FormattedMessage
                                                        id="profile.delete"
                                                        defaultMessage="Delete"
                                                    />
                                                </SmallButton>
                                            )}
                                        </LastCell>
                                    </TableRow>
                                ) : (
                                    <TableRow key='0'>
                                        <Cell>
                                            <FormattedMessage
                                                id="profile.no2faMethods"
                                                defaultMessage="No 2FA Methods"
                                            />
                                        </Cell>
                                        <Cell/>
                                        <Cell/>
                                        <CellNoMobile/>
                                        <LastCell/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <StyledButton onClick={() => {this.onCreateNew();}} title="Create New Method" bsStyle='blue'>
                            <FormattedMessage
                                id="profile.2fa.createNew"
                                defaultMessage="Create New Method"
                            />
                        </StyledButton>


                        <SecuredActionsTitle>
                            <FormattedMessage
                                id="profile.2fa.securedActions"
                                defaultMessage="Actions Secured By Two Factor Auth"
                            />
                        </SecuredActionsTitle>

                        <StyledCheckbox>
                            <Checkbox
                                disabled={true}
                                label={(
                                    <FormattedMessage
                                        id="profile.2fa.securedActions.signIn"
                                        defaultMessage="Login (Required)"
                                    />
                                )}
                                checked={(loginAction == null) ? securedActions.includes('SIGN_IN') : loginAction}
                                onChange={() => this.setState({ loginAction: (loginAction == null) ? !securedActions.includes('SIGN_IN') : !loginAction })}
                            />
                        </StyledCheckbox>

                        <StyledCheckbox>
                            <Checkbox
                                disabled={true}
                                label={(
                                    <FormattedMessage
                                        id="profile.2fa.securedActions.withdraw"
                                        defaultMessage="Withdrawal (Required)"
                                    />
                                )}
                                checked={(withdrawAction == null) ? securedActions.includes('WITHDRAW') : withdrawAction}
                                onChange={() => this.setState({ withdrawAction: (withdrawAction == null) ? !securedActions.includes('WITHDRAW') : !withdrawAction })}
                            />
                        </StyledCheckbox>

                        <StyledCheckbox>
                            <Checkbox
                                label={(
                                    <FormattedMessage
                                        id="profile.2fa.securedActions.editAccount"
                                        defaultMessage="Profile Changes"
                                    />
                                )}
                                checked={(editAccountAction == null) ? securedActions.includes('EDIT_ACCOUNT') : editAccountAction}
                                onChange={() => this.setState({ editAccountAction: (editAccountAction == null) ? !securedActions.includes('EDIT_ACCOUNT') : !editAccountAction })}
                            />
                        </StyledCheckbox>

                        <StyledCheckbox>
                            <Checkbox
                                label={(
                                    <FormattedMessage
                                        id="profile.2fa.securedActions.trade"
                                        defaultMessage="Order Placement / Trading"
                                    />
                                )}
                                checked={(tradeAction == null) ? securedActions.includes('TRADE') : tradeAction}
                                onChange={() => this.setState({ tradeAction: (tradeAction == null) ? !securedActions.includes('TRADE') : !tradeAction })}
                            />
                        </StyledCheckbox>

                        <StyledCheckbox>
                            <Checkbox
                                label={(
                                    <FormattedMessage
                                        id="profile.2fa.securedActions.apiWithdraw"
                                        defaultMessage="API Withdrawal"
                                    />
                                )}
                                checked={(apiWithdrawAction == null) ? securedActions.includes('API_WITHDRAW') : apiWithdrawAction}
                                onChange={() => this.setState({ apiWithdrawAction: (apiWithdrawAction == null) ? !securedActions.includes('API_WITHDRAW') : !apiWithdrawAction })}
                            />
                        </StyledCheckbox>

                        {formSecuredActionsChanged && (
                            <React.Fragment>
                                {(!securedMethods.includes('NONE')) ? (
                                    <React.Fragment>
                                        <AuthRow>
                                            <FormattedMessage
                                                id="profile.2fa.verify2FA"
                                                defaultMessage="Verify Changes With Your 2FA Code"
                                                tagName="b"
                                            />
                                            <br/>
                                            <LeftInputTwoFa>
                                                <InputTwoFa
                                                    name="oldCode"
                                                    value={oldCode}
                                                    onChange={this.onChange}
                                                    error={error && 'Incorrect code'}
                                                    disabled={loading}
                                                    component='EDIT_ACCOUNT'
                                                />
                                            </LeftInputTwoFa>
                                            <RightButtonTwoFa disabled={continueChangeActionsDisabled} onClick={this.onUpdateSecuredActions}>
                                                <FormattedMessage
                                                    id="profile.update"
                                                    defaultMessage="Update"
                                                />
                                            </RightButtonTwoFa>
                                        </AuthRow>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <WideButton disabled={continueChangeActionsDisabled} onClick={this.onUpdateSecuredActions}>
                                            <FormattedMessage
                                                id="profile.update"
                                                defaultMessage="Update"
                                            />
                                        </WideButton>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}

                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { displayScreen === 'resetPassword' && (
                            <Suspense fallback={
                                <React.Fragment>
                                    <br/>
                                    <div>Loading...</div>
                                </React.Fragment>}>
                                <FormWrapper>
                                    <FormTitle>
                                        <FormattedMessage
                                            id="auth.signin.title"
                                            defaultMessage="Password Reset"
                                        />
                                        <p>
                                            <FormattedMessage
                                                id="profile.auth.text"
                                                defaultMessage="Please set your new password"
                                            />
                                        </p>
                                    </FormTitle>

                                    <AuthInputGroup>
                                        <label>New Password</label>
                                        <ReactPasswordStrength
                                            namespaceClassName={'BPPasswordStrength'}
                                            minLength={8}
                                            minScore={3}
                                            scoreWords={['Too Weak', 'Too Weak', 'Too Weak', 'Strong', 'Great!']}
                                            tooShortWord={'Too Short'}
                                            changeCallback={this.changeCallback}
                                            inputProps={{
                                                name: 'password',
                                                type: 'password',
                                                placeholder: 'Enter New Password',
                                                value: password,
                                                onChange: this.onChange,
                                                error: passwordError,
                                            }}
                                        />
                                    </AuthInputGroup>

                                    <AuthInputGroup>
                                        <label>Confirm Password</label>
                                        <Input
                                            name="confirmPassword"
                                            type="password"
                                            value={confirmPassword}
                                            onChange={this.onChange}
                                            error={passwordConfirmError}
                                        />
                                    </AuthInputGroup>

                                    <AuthRow>
                                        <LeftInputTwoFa>
                                            <InputTwoFa
                                                name="oldCode"
                                                value={oldCode}
                                                onChange={this.onChange}
                                                error={error && 'Incorrect code'}
                                                disabled={loading}
                                                component='EDIT_ACCOUNT'
                                            />
                                        </LeftInputTwoFa>
                                        <RightButtonTwoFa bsStyle='orange' disabled={resetPassDisabled} onClick={this.onResetPassword}>
                                            <FormattedMessage
                                                id="profile.continue"
                                                defaultMessage="Continue"
                                            />
                                        </RightButtonTwoFa>
                                    </AuthRow>
                                    <WideButton bsStyle='blue' onClick={this.onCancelAction}>
                                        <FormattedMessage
                                            id="profile.cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </WideButton>
                                </FormWrapper>
                            </Suspense>
                        )}

                        { displayScreen === 'newTwoFactor' && (
                            <React.Fragment>
                                <FormWrapper>
                                    <FormTitle>
                                        <FormattedMessage
                                            id="profile.2fa.create_new_title"
                                            defaultMessage="New Two-Factor Method"
                                        />
                                    </FormTitle>
                                    <FormattedMessage
                                        id="profile.2fa.subtitle_createLabel"
                                        defaultMessage="Create a label for your device"
                                        tagName="p"
                                    />
                                    <CommentInputGroup>
                                        <Input autoFocus
                                            name="comment"
                                            placeholder={(type === '' || type === 'NONE') ? 'My Device Name' : (type === 'YUBIKEY') ? 'My Key #123' : (type === 'GOOGLE') ? 'My Apple iPhone X' : 'My Work Number'}
                                            value={comment}
                                            onChange={this.onChange}
                                            maxLength="25"
                                        />
                                    </CommentInputGroup>
                                    <FormattedMessage
                                        id="profile.2fa.subtitle_select"
                                        defaultMessage="Please select one"
                                        tagName="p"
                                    />
                                    <RadioGroup>
                                        <input type="radio" id="yubikey" name="number" value="YUBIKEY" required onChange={this.onTypeChange} checked={this.state.type === 'YUBIKEY'} />
                                        <label htmlFor="yubikey">
                                            <FormattedMessage
                                                id="profile.2fa.radio.yubikey"
                                                defaultMessage="Yubikey (Great!)"
                                            />
                                        </label>
                                        <br/>
                                        <input type="radio" id="authenticator" name="number" value="GOOGLE" required onChange={this.onTypeChange} checked={this.state.type === 'GOOGLE'} />
                                        <label htmlFor="authenticator">
                                            <FormattedMessage
                                                id="profile.2fa.radio.authenticator"
                                                defaultMessage="Google Authenticator (Good)"
                                            />
                                        </label>
                                        {isSMSEnabled && (
                                            <React.Fragment>
                                                <br/>
                                                <input type="radio" id="sms" name="number" value="SMS" required onChange={this.onTypeChange} checked={this.state.type === 'SMS'} />
                                                <label htmlFor="sms">
                                                    <FormattedMessage
                                                        id="profile.2fa.radio.sms"
                                                        defaultMessage="Phone SMS (Not Recommended)"
                                                    />
                                                </label>
                                            </React.Fragment>
                                        )}
                                    </RadioGroup>

                                    {type && type === 'SMS' && (
                                        <CommentInputGroup>
                                            <label>Phone Number (Including +Country Code)</label>
                                            <Input autoFocus
                                                name="phoneNumber"
                                                placeholder="+1-555-555-1234"
                                                value={phoneNumber}
                                                onChange={this.onChange}
                                                maxLength="20"
                                            />
                                        </CommentInputGroup>
                                    )}

                                    <HalfButton bsStyle='blue' onClick={this.onCancelAction}>
                                        <FormattedMessage
                                            id="profile.cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </HalfButton>
                                    <HalfButton bsStyle='orange' disabled={selectTypeDisabled} onClick={this.onChooseMethod}>
                                        <FormattedMessage
                                            id="profile.continue"
                                            defaultMessage="Continue"
                                        />
                                    </HalfButton>
                                </FormWrapper>
                                <HowToUse>
                                    <a target='_blank' href='https://support.google.com/accounts/answer/1066447?hl=en' rel='noopener noreferrer'>
                                        <FormattedMessage
                                            id="profile.howToInstallGoogleAuth"
                                            defaultMessage="How To Install Google Authenticator ➜"
                                        />
                                    </a>
                                </HowToUse>
                            </React.Fragment>
                        )}

                        { displayScreen === 'newYubikey' && (
                            <FormWrapper>
                                <React.Fragment>
                                    <FormTitle>
                                        <FormattedMessage
                                            id="profile.2fa.yubikey.title"
                                            defaultMessage="Add Yubikey Auth"
                                        />
                                        <FormattedMessage
                                            id="profile.2fa.yubikey.subtitle"
                                            defaultMessage="Enter Your Yubikey And Push The Button"
                                            tagName="p"
                                        />
                                    </FormTitle>

                                    <YubiInput autoFocus
                                        name="newCode"
                                        placeholder="✕✕✕✕✕✕✕✕✕✕✕✕✕✕✕..."
                                        value={newCode}
                                        onChange={this.onChange}
                                        error={error && 'Incorrect code'}
                                        disabled={loading}
                                    />

                                    {(!securedMethods.includes('NONE')) ? (
                                        <React.Fragment>
                                            <AuthRow>
                                                <FormattedMessage
                                                    id="profile.2fa.existing2FA"
                                                    defaultMessage="Verify With Your Existing 2FA Code"
                                                />
                                                <LeftInputTwoFa>
                                                    <InputTwoFa
                                                        name="oldCode"
                                                        value={oldCode}
                                                        onChange={this.onChange}
                                                        error={error && 'Incorrect code'}
                                                        disabled={loading}
                                                        component='EDIT_ACCOUNT'
                                                    />
                                                </LeftInputTwoFa>
                                                <RightButtonTwoFa bsStyle='orange' disabled={continueYubikeyDisabled} onClick={this.onContinue}>
                                                    <FormattedMessage
                                                        id="profile.continue"
                                                        defaultMessage="Continue"
                                                    />
                                                </RightButtonTwoFa>
                                            </AuthRow>
                                            <WideButton bsStyle='blue' onClick={this.onCancelAction}>
                                                <FormattedMessage
                                                    id="profile.cancel"
                                                    defaultMessage="Cancel"
                                                />
                                            </WideButton>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <HalfButton bsStyle='blue' onClick={this.onCancelAction}>
                                                <FormattedMessage
                                                    id="profile.cancel"
                                                    defaultMessage="Cancel"
                                                />
                                            </HalfButton>
                                            <HalfButton bsStyle='orange' disabled={continueYubikeyDisabled} onClick={this.onContinue}>
                                                <FormattedMessage
                                                    id="profile.continue"
                                                    defaultMessage="Continue"
                                                />
                                            </HalfButton>
                                        </React.Fragment>
                                    )}

                                </React.Fragment>
                            </FormWrapper>
                        )}

                        { displayScreen === 'newGAuth' && (
                            <React.Fragment>
                                <FormWrapper>
                                    <FormTitle>
                                        <FormattedMessage
                                            id="profile.2fa.google.subtitle"
                                            defaultMessage="Add Google Auth"
                                        />
                                    </FormTitle>

                                    {auth.qr !== '' ? (
                                        <React.Fragment>
                                            <QRWrapper>
                                                <AuthQR value={auth.qr} size={230}/>

                                                <span>
                                                    Use Google Authenticator to scan the QR Code above, or manually enter the code below
                                                </span>

                                                <p>
                                                    {auth.secret}
                                                </p>
                                                <CopyToClipboardSpan>
                                                    {gAuthKeyCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                        <CopyToClipboard onCopy={()=>this.onCopyGAuthKey()} text={auth.secret}>
                                                            <span>
                                                                <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                                &nbsp;Copy To Clipboard
                                                            </span>
                                                        </CopyToClipboard>
                                                    )}
                                                </CopyToClipboardSpan>

                                                <span>
                                                    Enter the 6-digit code generated by the app on your new device
                                                </span>

                                                <CodeInput autoFocus
                                                    name="newCode"
                                                    placeholder="✕✕✕ ✕✕✕"
                                                    value={newCode}
                                                    onChange={this.onChange}
                                                    error={error && 'Incorrect code'}
                                                    disabled={loading}
                                                />
                                                {isNewCredentialsError && (
                                                    <React.Fragment>
                                                        <br/>
                                                        <ErrorMessage>
                                                            <Warning src={'/' + warningIcon}/>
                                                            <FormattedMessage
                                                                id="profile.2fa.newCredentialsError"
                                                                defaultMessage="Incorrect Code"
                                                            />
                                                        </ErrorMessage>
                                                    </React.Fragment>
                                                )}
                                            </QRWrapper>

                                            {(!securedMethods.includes('NONE')) ? (
                                                <React.Fragment>
                                                    <AuthRow>
                                                        <FormattedMessage
                                                            id="profile.2fa.existing2FA"
                                                            defaultMessage="Verify With Your Existing 2FA Code"
                                                        />
                                                        <LeftInputTwoFa>
                                                            <InputTwoFa
                                                                name="oldCode"
                                                                value={oldCode}
                                                                onChange={this.onChange}
                                                                error={error && 'Incorrect code'}
                                                                disabled={loading}
                                                                component='EDIT_ACCOUNT'
                                                            />
                                                        </LeftInputTwoFa>
                                                        <RightButtonTwoFa bsStyle='orange' disabled={continueGauthDisabled} onClick={this.onContinue}>
                                                            <FormattedMessage
                                                                id="profile.continue"
                                                                defaultMessage="Continue"
                                                            />
                                                        </RightButtonTwoFa>
                                                    </AuthRow>
                                                    <WideButton bsStyle='blue' onClick={this.onCancelAction}>
                                                        <FormattedMessage
                                                            id="profile.cancel"
                                                            defaultMessage="Cancel"
                                                        />
                                                    </WideButton>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <HalfButton bsStyle='blue' onClick={this.onCancelAction}>
                                                        <FormattedMessage
                                                            id="profile.cancel"
                                                            defaultMessage="Cancel"
                                                        />
                                                    </HalfButton>
                                                    <HalfButton bsStyle='orange' disabled={continueGauthDisabled} onClick={this.onContinue}>
                                                        <FormattedMessage
                                                            id="profile.continue"
                                                            defaultMessage="Continue"
                                                        />
                                                    </HalfButton>
                                                </React.Fragment>
                                            )}

                                        </React.Fragment>
                                    ) : (
                                        <FormTitle>
                                            {qrLoadError}
                                        </FormTitle>
                                    )}
                                </FormWrapper>
                                <HowToUse>
                                    <a target='_blank' href='https://support.google.com/accounts/answer/1066447?hl=en' rel='noopener noreferrer'>
                                        <FormattedMessage
                                            id="profile.howToInstallGoogleAuth"
                                            defaultMessage="How To Install Google Authenticator ➜"
                                        />
                                    </a>
                                </HowToUse>
                            </React.Fragment>
                        )}

                        { displayScreen === 'newSms' && (
                            <FormWrapper>
                                <React.Fragment>
                                    <FormTitle>
                                        <FormattedMessage
                                            id="profile.2fa.sms.title"
                                            defaultMessage="Add SMS Auth"
                                        />
                                        <p>
                                            <FormattedMessage
                                                id="profile.2fa.sms.subtitle"
                                                defaultMessage="Enter The Code In Your SMS Message"
                                            />
                                        </p>
                                    </FormTitle>

                                    <CodeInput autoFocus
                                        name="newCode"
                                        placeholder="✕✕✕ ✕✕✕"
                                        value={newCode}
                                        onChange={this.onChange}
                                        error={error && 'Incorrect code'}
                                        disabled={loading}
                                    />

                                    {(!securedMethods.includes('NONE')) ? (
                                        <React.Fragment>
                                            <AuthRow>
                                                <FormattedMessage
                                                    id="profile.2fa.existing2FA"
                                                    defaultMessage="Verify With Your Existing 2FA Code"
                                                />
                                                <LeftInputTwoFa>
                                                    <InputTwoFa
                                                        name="oldCode"
                                                        value={oldCode}
                                                        onChange={this.onChange}
                                                        error={error && 'Incorrect code'}
                                                        disabled={loading}
                                                        component='EDIT_ACCOUNT'
                                                    />
                                                </LeftInputTwoFa>
                                                <RightButtonTwoFa bsStyle='orange' disabled={continueSmsDisabled} onClick={this.onContinue}>
                                                    <FormattedMessage
                                                        id="profile.continue"
                                                        defaultMessage="Continue"
                                                    />
                                                </RightButtonTwoFa>
                                            </AuthRow>
                                            <WideButton bsStyle='blue' onClick={this.onCancelAction}>
                                                <FormattedMessage
                                                    id="profile.cancel"
                                                    defaultMessage="Cancel"
                                                />
                                            </WideButton>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <HalfButton bsStyle='blue' onClick={this.onCancelAction}>
                                                <FormattedMessage
                                                    id="profile.cancel"
                                                    defaultMessage="Cancel"
                                                />
                                            </HalfButton>
                                            <HalfButton bsStyle='orange' disabled={continueSmsDisabled} onClick={this.onContinue}>
                                                <FormattedMessage
                                                    id="profile.continue"
                                                    defaultMessage="Continue"
                                                />
                                            </HalfButton>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </FormWrapper>
                        )}

                        { displayScreen === 'removeTwoFactor' && (
                            <FormWrapper>
                                <React.Fragment>
                                    <FormTitle>
                                        <FormattedMessage
                                            id="profile.2fa.delete.title"
                                            defaultMessage="Delete Auth Method"
                                        />
                                        <p>
                                            <FormattedMessage
                                                id="profile.2fa.delete.subtitle"
                                                defaultMessage="Remove {methodType}"
                                                values={{
                                                    methodType: deleteString,
                                                }}
                                            />
                                        </p>
                                    </FormTitle>

                                    {(!securedMethods.includes('NONE')) ? (
                                        <React.Fragment>
                                            <AuthRow>
                                                <FormattedMessage
                                                    id="profile.2fa.existing2FA"
                                                    defaultMessage="Verify With Your Existing 2FA Code"
                                                />
                                                <LeftInputTwoFa>
                                                    <InputTwoFa
                                                        name="oldCode"
                                                        value={oldCode}
                                                        onChange={this.onChange}
                                                        error={error && 'Incorrect code'}
                                                        disabled={loading}
                                                        component='EDIT_ACCOUNT'
                                                    />
                                                </LeftInputTwoFa>
                                                <RightButtonTwoFa bsStyle='orange' disabled={continue2faDisabled} onClick={this.submitDeleteAuth}>
                                                    <FormattedMessage
                                                        id="profile.continue"
                                                        defaultMessage="Continue"
                                                    />
                                                </RightButtonTwoFa>
                                            </AuthRow>
                                            <WideButton bsStyle='blue' onClick={this.onCancelAction}>
                                                <FormattedMessage
                                                    id="profile.cancel"
                                                    defaultMessage="Cancel"
                                                />
                                            </WideButton>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <HalfButton bsStyle='blue' onClick={this.onCancelAction}>
                                                <FormattedMessage
                                                    id="profile.cancel"
                                                    defaultMessage="Cancel"
                                                />
                                            </HalfButton>
                                            <HalfButton bsStyle='orange' disabled={continue2faDisabled} onClick={this.submitDeleteAuth}>
                                                <FormattedMessage
                                                    id="profile.continue"
                                                    defaultMessage="Continue"
                                                />
                                            </HalfButton>
                                        </React.Fragment>
                                    )}

                                </React.Fragment>
                            </FormWrapper>
                        )}

                    </React.Fragment>
                )}

            </Container>
        );
    }
}
