import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MarketScreen from './MarketScreen';
import {getAuthenticated, getBaseCoinConfig, getQuoteCoinConfig} from '../../selectors';
import { getPortfolioInfo } from '../../actions/portfolio';

function mapStateToProps(state) {
    return {
        baseCoinConfigName: getBaseCoinConfig(state) && getBaseCoinConfig(state).name,
        quoteCoinConfigName: getQuoteCoinConfig(state) && getQuoteCoinConfig(state).name,
        baseCoinConfigLabel: getBaseCoinConfig(state) && getBaseCoinConfig(state).label,
        quoteCoinConfigLabel: getQuoteCoinConfig(state) && getQuoteCoinConfig(state).label,
        isPairsLoaded: state.market.activePairs && state.market.activePairs.length > 0,
        isAuthenticated: getAuthenticated(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPortfolioInfo,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketScreen);
