import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as Input } from '../../components/Input';
import { Component as Button } from '../../components/Button';
import { Component as GradientView } from '../../components/GradientView';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as AuthFooter } from '../../components/AuthFooter';
import {
    LinkButton,
    AuthInputGroup,
    FormWrapper, FormContainer
} from '../../commonStyles/AuthStyles';

import { mobile } from '../../styles';

import mail from '../../images/ic-mail.svg';

const BoldText = styled(MessageBold)`
    margin-bottom: 60px;
`;

const MailIcon = styled.div`
  text-align: center;
  margin-bottom: 35px;

  ${mobile`
      margin-bottom: 20px;
  `}
`;

const SentpasswordToEmailText = styled.div`
    white-space: nowrap;
    color: #262626;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 30px;
`;

const SentWrapper = styled.div`
    padding: 60px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mobile`
        padding: 20px 30px 60px 30px;
        border-radius: 5px;
    `};
`;

const FormWrapperView = styled(FormWrapper)`
  padding: 40px 110px 20px;
  text-align: center;
  font-size: 14px;
  
  label {
    text-align: left;
  }
`;

const FormTitle = styled.div`
	margin-bottom: 20px;
`;

const SubTitle = styled.div`
`;

const InputGroupView = styled(AuthInputGroup)`
  width: 350px;
  margin-top: 35px;
  margin-bottom: 25px;
`;

const SigninButton = styled(Button)`
  display: block;
  width: 350px;
  border-radius: 10px;
  text-transform: capitalize;

  ${mobile`
    width: 100%;
  `};
`;

const LinkBlock = styled.div`
  margin: 40px 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #262626;
    margin-bottom: 30px;
    }
`;

const CreateAccountLink = styled(LinkButton)`
    border: 1px solid #333333;
    width: 185px;
    color: #262626;
    background-color: #FFFFFF;
    padding: 16px 20px;
    text-transform: capitalize;
`;

const DidNotGetEmail = styled.div`
  max-width: 390px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
`;

const DidNotReceiveEmail = styled.div`
`;

const TryAnotherEmailLink = styled.span`
  text-decoration: underline; 
  font-weight: 700;
  cursor: pointer;
`;

export default class ForgotPassword extends React.Component {

    static propTypes = {
        resetPassword: PropTypes.func,
        history: PropTypes.object
    };

    state = {
        email: '',
        rememberMe: false,
        error: true,
        sent: false,
    };

    componentDidMount() {
        document.title = 'Reset Password (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });
    }

    validate = () => {

    };

    onSubmit = async event => {
        event.preventDefault();
        const { email } = this.state;
        const response = await this.props.resetPassword({
            email,
        });
        if (!response.error) {
            this.setState({
                sent: true,
            });
        } else {
            this.setState({
                error: true,
            });
        }
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onTryAnotherEmailClick = () => {
        this.setState({
            ...this.state,
            sent: false,
        });
    }

    renderEmailSentContent() {
        return (
            <SentWrapper>
                <FormTitle>
                    <BoldText>
                        <FormattedMessage
                            id="auth.signin.checkYourEmail"
                            defaultMessage="Check your email"
                        />
                    </BoldText>
                    <SentpasswordToEmailText>
                        <FormattedMessage
                            id="auth.signup.sentPasswordRecoveryToMail"
                            defaultMessage="We’ve sent password recovery instructions."
                        />
                    </SentpasswordToEmailText>

                </FormTitle>
                <MailIcon>
                    <img src={mail} alt="" />
                </MailIcon>
                <DidNotGetEmail>
                    <DidNotReceiveEmail>
                        <FormattedMessage
                            id="auth.signin.didNotGetEmailCheckSpam"
                            defaultMessage="Did not receive the email? Check your spam {br} filter or {tryAnotherEmail}"
                            values={{
                                br: <br />,
                                tryAnotherEmail: (
                                    <TryAnotherEmailLink onClick={this.onTryAnotherEmailClick}>
                                        <FormattedMessage
                                            id="auth.signin.tryAnotherEmail"
                                            defaultMessage="try another email address"
                                        />
                                    </TryAnotherEmailLink>
                                ),
                            }}
                        />
                    </DidNotReceiveEmail>
                </DidNotGetEmail>
            </SentWrapper>
        );
    }

    renderForgotPasswordContent() {
        const { email } = this.state;
        return (
            <React.Fragment>
                <FormWrapperView onSubmit={this.onSubmit}>
                    <FormTitle>
                        <BoldText>
                            <FormattedMessage
                                id="auth.signin.forgot_pw_title"
                                defaultMessage="Forgot Password"
                            />
                        </BoldText>
                    </FormTitle>
                    <SubTitle>
                        <FormattedMessage
                            id="auth.signup.weWillEmailLinkToResetPasswd"
                            defaultMessage="We’ll email you a link to reset your password"
                        />
                    </SubTitle>
                    <InputGroupView>
                        <label>Email</label>
                        <Input
                            name="email"
                            placeholder="Enter Your Email"
                            value={email}
                            onChange={this.onChange}
                        />
                    </InputGroupView>
                    <InputGroupView>
                        <SigninButton
                            bsStyle='orange'
                            disabled={(!(email && email !== ''))}
                        >
                            <FormattedMessage
                                id="auth.reset_password.cta"
                                defaultMessage="Continue"
                            />
                        </SigninButton>
                    </InputGroupView>
                </FormWrapperView>

                <LinkBlock>
                    <label>
                        <FormattedMessage
                            id="auth.signup.ifYouKnowPasswdContinueLogin"
                            defaultMessage="If you remember your password continue to login"
                        />
                    </label>
                    <CreateAccountLink to="/login">
                        <FormattedMessage
                            id="auth.signin.signup_link"
                            defaultMessage="Log in"
                        />
                    </CreateAccountLink>
                </LinkBlock>
            </React.Fragment>
        );
    }

    render() {
        const { sent } = this.state;
        return (
            <FormContainer>
                <GradientView borderRadius={'0 0 15px 15px'}>
                    {sent ?
                        this.renderEmailSentContent() :
                        this.renderForgotPasswordContent()}
                </GradientView>
                <AuthFooter type={'WHITE'}/>
            </FormContainer>
        );
    }
}
