import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import PortfolioDeposits from './PortfolioDeposits';
import {selectDepositAddresses, isDepositAllowed, getCoinNetworks, getNetworkOptions} from '../../../../selectors';
import { clearDepositAddresses, getDepositAddresses, requestDepositAddress } from '../../../../actions/portfolio';

function mapStateToProps(state, { coin }) {
    return {
        networkOptions: getNetworkOptions(state, coin),
        networkConfigs: getCoinNetworks(state, coin),
        addresses: selectDepositAddresses(state, coin),
        isDepositAllowed: isDepositAllowed(state, coin),
        refreshDepositAddresses: state.portfolio.refreshDepositAddresses,

        userKycTier: (state.user && state.user.kycTier) ? state.user.kycTier : 'NONE',
        userJurisdictionLockWhitelisted: state.user.jurisdictionLockWhitelisted,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearDepositAddresses,
            requestDepositAddress,
            getDepositAddresses,
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioDeposits));
