import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TradeOrderPlacementForm from './TradeOrderPlacementForm';
import { submitNewOrder, setLastPriceInput } from '../../../../actions/market';
import {getAuthenticated, getCurrentPrice, getTradePair} from '../../../../selectors';

function mapStateToProps(state) {
    return {
        tradePair: getTradePair(state),

        userSecuredMethods: (state.user && state.user.securedMethods) ? state.user.securedMethods : ['NONE'],
        userSecuredActions: (state.user && state.user.securedActions) ? state.user.securedActions : [],
        userKycTier: (state.user && state.user.kycTier) ? state.user.kycTier : 'NONE',
        userLocked: state.user.locked,
        userJurisdictionLockWhitelisted: state.user.jurisdictionLockWhitelisted,
        userFeeExempt: state.user.feeExempt,

        wallets: state.portfolio.wallets,
        lastBuyInput: state.market.lastBuyInput,
        lastSellInput: state.market.lastSellInput,
        buyOrders: state.market.buyOrders,
        sellOrders: state.market.sellOrders,
        buyPriceField: state.market.buyPriceField,
        sellPriceField: state.market.sellPriceField,
        currentPrice: getCurrentPrice(state),
        isAuthenticated: getAuthenticated(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitNewOrder,
            setLastPriceInput,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradeOrderPlacementForm);
