import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

import ProfileDownloadRecords from './ProfileDownloadRecords';

import { getAssetOptions } from '../../../../selectors';


function mapStateToProps(state) {
    return {
        assetOptions: getAssetOptions(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDownloadRecords));
