import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {DisplayFiat} from '../../utils';


const Container = styled.div`
  margin: 20px;
`;

const Title = styled.h1`
	color: ${props => props.theme.colors.primary6};
	font-size: 24px;
	line-height: 20px;
	font-weight: 400;
    margin-top: 30px;
`;

const AssetInfoBlock = styled.div`
    display: inline-block;
    margin-bottom: 40px;
    p {
        margin-bottom: -1em;
        margin-top: 0em;
    }
`;


export default class AssetInformation extends React.Component {

    static propTypes = {
        coinConfig: PropTypes.array,
        coinConfigExtended: PropTypes.array,
        coin: PropTypes.string,
    };

    componentDidMount() {
        //
    }

    render() {
        const { coinConfig, coinConfigExtended } = this.props;
        const description = coinConfigExtended ? coinConfigExtended.description : 'Loading...';

        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="portfolio.asset_statistics_title"
                        defaultMessage="{assetLabel} statistics"
                        values={{
                            assetLabel: coinConfig ? coinConfig.label : 'Asset',
                        }}
                    />
                </Title>
                {coinConfig && (
                    <React.Fragment>
                        <p>
                            <b>
                                <FormattedMessage
                                    id="portfolio.assetPriceTitle"
                                    defaultMessage="Current Price: "
                                />
                            </b>
                            <DisplayFiat currency='USD' value={coinConfig.coinPrice}/>
                        </p>
                        <p>
                            <b>
                                <FormattedMessage
                                    id="portfolio.assetChainFeeTitle"
                                    defaultMessage="Blockchain Transaction Fee: "
                                />
                            </b>
                            {coinConfig.networks.find(networkConfig => networkConfig.name === coinConfig.defaultNetwork).txFee} {coinConfig.defaultNetwork}
                            {(coinConfig.name !== coinConfig.defaultNetwork) && (
                                <FormattedMessage
                                    id="portfolio.asset_fees_native"
                                    defaultMessage=" (approx {feeAmount} {feeAsset})"
                                    values={{
                                        feeAmount: coinConfig.networks.find(networkConfig => networkConfig.name === coinConfig.defaultNetwork).networkFee,
                                        feeAsset: coinConfig.name
                                    }}
                                />
                            )}
                        </p>
                    </React.Fragment>
                )}
                <Title>
                    <FormattedMessage
                        id="portfolio.asset_fees_title"
                        defaultMessage="{assetLabel} exchange fees"
                        values={{
                            assetLabel: coinConfig ? coinConfig.label : 'Asset',
                        }}
                    />
                </Title>
                {coinConfig && (
                    <React.Fragment>
                        <p><b>Maker Trade Fee:</b> {(coinConfig.makerFee === 0) ? 'Free' : coinConfig.makerFee + '%'}</p>
                        <p><b>Taker Trade Fee:</b> {(coinConfig.takerFee === 0) ? 'Free' : coinConfig.takerFee + '%'}</p>
                    </React.Fragment>
                )}
                <Title>
                    <FormattedMessage
                        id="portfolio.asset_summary_title"
                        defaultMessage="{assetLabel} summary"
                        values={{
                            assetLabel: coinConfig ? coinConfig.label : 'Asset',
                        }}
                    />
                </Title>
                <AssetInfoBlock dangerouslySetInnerHTML={{__html: description }} />
            </Container>
        );
    }
}
