import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Component as CurrencyImage } from '../CurrencyImage';

import { mobile } from '../../styles';

import warningIcon from '../../images/ic-warning.svg';


const CurrencyInputWrapper = styled.div`
`;

const InputWrapper = styled.div`
  display: inline-flex;
  // position: relative;
  // width: 240px;
  // max-width: 400px;
  align-items: center;
  border: 1px solid #CFCFCF;
  border-radius: 5px;
  justify-content: center;

  ${mobile`
    max-width: 100%;
    width: 100%;
  `}
`;

const CurrencyIcon = styled(CurrencyImage)`
  height: 25px;
  width: 25px;
  display: block;
  padding: 5px 15px 5px 15px;
`;

const InputElement = styled.input`
  	background-color: #FFFFFF;
    outline: 0;
  	color: #4A4A4A;
  	font-size: 14px;
  	font-weight: 600;
  	letter-spacing: 0.2px;
  	line-height: 13px;
    text-align: center;
    margin: 1px;
    //margin-left: auto;
    //margin-right: auto;
    max-width: 140px;
    box-sizing: border-box;
    max-height: 40px;
  	border: 1px solid #CFCFCF;
  	border-radius: 5px;

    padding: 0 1em;

    ::placeholder {
      color: #9B9B9B;
      opacity: 0.9;
      font-weight: 400;
    }

    :disabled {
      background-color: #ddd;
      cursor: not-allowed;
    }
`;

const Warning = styled.img`
  // padding-left: 10px;
  padding-right: 5px;
  // position: absolute;
  // top: 14px;
  // right: 10px;
`;

const ErrorMessage = styled.span`
  color: #D0021B;
  left: 0;
  // width: 100%;
  font-size: 14px;
  white-space: nowrap;
`;

const CurrencyCode = styled.div`
  // position: absolute;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  min-width: 45px;
  text-align: left;
  padding: 0px 15px;
  
  // right: 20px;
  // top: 0;
`;

export default class CurrencyInput extends React.Component {

    static propTypes = {
        currency: PropTypes.string,
        className: PropTypes.string,
        error: PropTypes.string,
        iconAlign: PropTypes.string,
        textOverride: PropTypes.string,
    };

    static defaultProps = {
        type: 'text'
    };

    render() {

        const { currency, className, iconAlign, textOverride, error } = this.props;

        return (
            <CurrencyInputWrapper>
                {(iconAlign && iconAlign === 'right') ? (
                    <InputWrapper className={className}>
                        <CurrencyCode>
                            {textOverride ? textOverride : currency}
                        </CurrencyCode>

                        <InputElement {...this.props} />

                        <CurrencyIcon currency={currency} />
                    </InputWrapper>
                ) : (
                    <InputWrapper className={className}>
                        <CurrencyIcon currency={currency} />

                        <InputElement {...this.props} />

                        <CurrencyCode>
                            {textOverride ? textOverride : currency}
                        </CurrencyCode>
                    </InputWrapper>
                )}
                {error && (
                    <React.Fragment>
                        <br/>
                        <ErrorMessage>
                            <Warning src={'/' + warningIcon}/>
                            {error}
                        </ErrorMessage>
                    </React.Fragment>
                )}
            </CurrencyInputWrapper>
        );
    }
}
