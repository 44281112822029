import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const ProgressBarWrapper = styled.div`
  border-radius: 5px 5px 0px 0px;
  background-color: #f0f0f0;
  height: 16px;
  position: relative;
`;

const Bar = styled.div`
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  left: 0;
  top: 0;
  width: ${props => props.progress}%;
  // background: linear-gradient(90deg, #0FA4FF 0%, #1FDBFF 100%);
  background: linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%);
  height: 100%;
  transition: 0.3s;
`;

export default class QuickTradeProgressBar extends React.Component {

    static propTypes = {
        transaction: PropTypes.object
    };

    state = {
        progress: 0,
        timerOn: false,
    };

    componentWillUnmount() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        const { transaction } = props;

        const status = transaction.status.type;

        if (status === 'TRADE_IN_PROGRESS') {
            this.startTimer(5, 15);
            return;
        }

        if (status === 'TRADE_DONE' && transaction.confCount <= 1) {
            this.startTimer(20, 25);
            return;
        }

        if ((status === 'TRADE_DONE' && transaction.confCount > 1) || status === 'WITHDRAW_REQUESTED' || status === 'WITHDRAW_FAILED' || status === 'REFUND_REQUESTED' || status === 'REFUND_FAILED') {
            const progress = Math.min(90, (25 + Math.min(transaction.confCount, transaction.targetConfCount)/(transaction.targetConfCount) * 65));
            this.startTimer(35, progress);
            return;
        }

        if (status === 'WITHDRAW_FINISHED' || status === 'TRADE_CANCELED' || status === 'QUICKTRADE_FINISHED') {
            this.setState({
                progress: 100,
            });
            // this.startTimer(98, 100);
            return;
        }
    }

    startTimer(progressStart, targetProgress) {
        const start = this.state.progress < progressStart ? progressStart : this.state.progress;
        this.setState({
            timerOn: true,
            progress: start,
        });
        this.timerInterval = setInterval(() => {
            const newProgress = this.state.progress + 1;
            if (newProgress <= targetProgress) {
                this.setState({
                    progress: newProgress
                });
            } else {
                this.setState({ timerOn: false });
                clearInterval(this.timerInterval);
            }
        }, 500);
    }


    render() {
        const { progress } = this.state;

        return (
            <ProgressBarWrapper>
                <Bar progress={progress}/>
            </ProgressBarWrapper>
        );
    }
}
