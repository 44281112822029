import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobile } from '../../styles';
import { Component as StandardPage } from '../../components/StandardPage';

import linkedInLogo from '../../images/linkedin34px.png';
import davePhoto from '../../images/dave_bw.png';
import paulPhoto from '../../images/paul_bw.png';
import sylviaPhoto from '../../images/sylvia-linkedin.png';
import darrellPhoto from '../../images/darrell_bw.png';
import ethanPhoto from '../../images/ethan_bw.png';
import rickPhoto from '../../images/rick_bw.png';

// don't delete these - they are here so that they get included in builds
// and are used in every email sent from the platform
import emailLogoBloc from '../../images/emailLogoBloc.png';
import emailLogoPal from '../../images/emailLogoPal.png';



const Title = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin-top: 1em;
  margin-bottom: 0.5em;
  margin-left: 1em;

  width: 300px;

  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;

`;

const SquareLogoDiv = styled.div`
  text-align: center;
  display: block;

  margin-left: auto;
  margin-right: auto;

  ${mobile`
    display: none;
  `}
`;

const SquareLogo = styled.img`
  ${mobile`
    // width: 250px;
    // height: 250px;
  `}
`;

const TeamRowDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TeamMemberDiv = styled.div`
  width: 350px;
  padding: 10px;
  margin: 10px;

  ${mobile`
    width: 300px;
    margin: 5px;
  `}
`;

const TeamPicDiv = styled.div`
  text-align: center;
`;

const TeamPic = styled.img`
  margin: 10px;
  height: 250px;
  border-radius: 3px;
`;

class AboutUs extends React.Component {

    componentDidMount() {
        document.title = 'About Us (BlocPalX)';
    }

    render() {
        return (
            <React.Fragment>
                <TeamRowDiv>
                    <TeamMemberDiv>
                        <TeamPicDiv>
                            <TeamPic src={'/' + davePhoto}/>
                        </TeamPicDiv>
                        <a href="https://www.linkedin.com/in/dave-coombs-87a15215/" rel="noopener noreferrer" target="_blank"><img src={linkedInLogo} align="left" style={{margin: '5px'}} /> David Coombs</a>
                        &nbsp;(Co-Founder, Chairman) – David has been in the real-estate development business and various other business ventures for
                        over 20 years. He is currently a principle and General partner of two development projects in Kelowna B.C. Prospect at Black Mountain
                        and Cadence at The Lakes. These projects combined have over 400 residential lots. He is also the Chairman of Assured Software,
                        a privately held software company that provides solutions for the Insurance Industry and Restoration Contractors for property claims.
                        Prior to being the Chairman, he was the President and CEO of Assured for 3 years. David was the driving force in organizing and
                        securing the financing for Assured.
                        David was a director and then Chairman of The Thompson Okanagan Tourism Association (TOTA) from 1999 to 2005. The board of TOTA
                        is responsible for funding tourism activities in the Thompson Okanagan region in partnership with the BC Provincial Government.
                    </TeamMemberDiv>
                    <TeamMemberDiv>
                        <TeamPicDiv>
                            <TeamPic src={'/' + paulPhoto}/>
                        </TeamPicDiv>
                        <a href="https://www.linkedin.com/in/paul-winckers-cpa-260649a/" rel="noopener noreferrer" target="_blank"><img src={linkedInLogo} align="left" style={{margin: '5px'}} /> Paul Winckers</a>
                        &nbsp;(Chief Financial Officer) – Paul has 20+ years experience in CEO/CFO roles for software and services companies. Paul worked at Cenadex as their Controller
                        where he oversaw all financial reporting, budgeting, cash management, business planning, etc.  After Cenadex Paul moved on to Assured Software where he
                        worked as their Finance &amp; Services Manager leading the Services &amp; Finance groups and directing their Kelowna office sales.  Paul then founded Datz Solutions and
                        worked as the CEO developing software that simplifies Real Estate sales processing.
                    </TeamMemberDiv>
                    <TeamMemberDiv>
                        <TeamPicDiv>
                            <TeamPic src={'/' + sylviaPhoto}/>
                        </TeamPicDiv>
                        <a href="https://www.linkedin.com/in/sylvia-lee-a8b6271b/" rel="noopener noreferrer" target="_blank"><img src={linkedInLogo} align="left" style={{margin: '5px'}} /> Sylvia Lee, B.A., LLB.</a>
                        &nbsp;(Global Compliance) – At McCarthy Tetrault LLP, Sylvia worked in the areas of corporate, commercial,
                        franchise &amp; securities law. She was involved in the acquisition of various resort operations and the creation of an $800M trust
                        in conjunction with a public offering on behalf of a major North American resort corporation. Later as Head In-House Counsel &amp;
                        Director of Legal Services for Keg Restaurants Ltd., Sylvia managed regulatory compliance, franchise law, and corporate law teams
                        overseeing 25 corporate entities across 10 jurisdictions and 2 countries.
                    </TeamMemberDiv>
                    <TeamMemberDiv>
                        <TeamPicDiv>
                            <TeamPic src={'/' + darrellPhoto}/>
                        </TeamPicDiv>
                        <a href="https://www.linkedin.com/in/darrellsnow/" rel="noopener noreferrer" target="_blank"><img src={linkedInLogo} align="left" style={{margin: '5px'}} /> Darrell Snow</a>
                        &nbsp;(CTO) – In the last few years Darrell has focused on work with a number of early stage startups in highly dynamic
                        and emerging industries (genetics, cannabis, cloud infrastructure management). He most recently helped Halifax based Athletigen
                        create and launch their product platform which is now generating revenue. Darrell has a long background of creating technology
                        solutions for clients in a wide variety of industries including manufacturing, banking, insurance, retail, airline,
                        telecommunications, gaming, and other spaces. Previously he has served as the VP of Client Services at Professional Access,
                        as CTO at Ascentium and at Infosys, as SVP of Technology at Blast Radius, as Software Engineering Development Manager for
                        Microsoft, Director of Technology at Razorfish and as a Technical Architect at Starbucks.
                    </TeamMemberDiv>
                    <TeamMemberDiv>
                        <TeamPicDiv>
                            <TeamPic src={'/' + ethanPhoto}/>
                        </TeamPicDiv>
                        <a href="https://www.linkedin.com/in/ethanburnside/" rel="noopener noreferrer" target="_blank"><img src={linkedInLogo} align="left" style={{margin: '5px'}} /> Ethan Burnside</a>
                        &nbsp;(Lead Blockchain Developer, Chief Architect) – Ethan founded his first company, Kattare Internet Services as a sophomore in college at
                        Oregon State University in 1997.  Kattare specialized in website design and hosting and grew rapidly through the internet boom years.
                        After promoting his close friend Nick Hayward to CEO, Ethan moved on to help launch Bingo Blitz as VP, Live Technology at Buffalo
                        Studios, a Santa Monica start-up later acquired by Playtika.  During his tenure at Buffalo Studios &amp; Playtika Ethan worked on Caesars
                        Total Rewards Social, Bingo Blitz, Bingo Rush, Bingo Rush 2, Faro Blitz, &amp; Penguinauts games.  It was while he was at Buffalo Studios
                        in 2012 that Ethan also launched BTC Trading Corp, arguably the most secure, most trustworthy, and most advanced Cryptocurrency exchange available at the time.
                    </TeamMemberDiv>
                    <TeamMemberDiv>
                        <TeamPicDiv>
                            <TeamPic src={'/' + rickPhoto}/>
                        </TeamPicDiv>
                        <a href="https://www.linkedin.com/in/rick-lambrick-3a123131/" rel="noopener noreferrer" target="_blank"><img src={linkedInLogo} align="left" style={{margin: '5px'}} /> Rick Lambrick</a>
                        &nbsp;(Director &amp; Marketing Advisor) – Rick has over 25 years of strategic business development, market development,
                        corporate operations, and strategic investment experience. He has held several senior management and leadership roles focused on driving
                        revenue growth, business valuation, and market innovation. Rick has a history of guiding strategic business development with the goal
                        of increasing revenue and corporate valuation.
                    </TeamMemberDiv>
                </TeamRowDiv>
            </React.Fragment>
        );
    }
}

export default function AboutScreen() {

    return (
        <StandardPage>
            <Title>
                <FormattedMessage
                    id="navigation.aboutUsTitle"
                    defaultMessage="The BlocPalX Team"
                />
            </Title>
            <AboutUs/>
            <SquareLogoDiv>
                <SquareLogo src={'/' + emailLogoBloc}/>
                <SquareLogo src={'/' + emailLogoPal}/>
            </SquareLogoDiv>
        </StandardPage>
    );

}
