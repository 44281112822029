import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const colorMap = {
    red: {
        // background: 'linear-gradient(90deg, #FF0000 0%, #AA0000 100%)',
        background: '#e84e2c',
        color: '#FFFFFF'
    },
    orange: {
        // background: 'linear-gradient(90deg, rgba(239, 134, 50, 1) 0%, rgba(239, 134, 50, 0.5) 100%)',
        background: 'rgba(239, 134, 50, 1)',
        color: '#FFFFFF'
    },
    blue: {
        // background: 'linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%)',
        background: 'rgba(2, 23, 71, 1)',
        color: '#FFFFFF'
    },
    green: {
        // background: 'linear-gradient(45.11deg, #56C200 0%, #7FD916 100%)',
        background: '#56C200',
        color: '#FFFFFF'
    },
};

const ButtonWrapper = styled.button`
    background: ${props => props.buttonTheme.background};
    color: ${props => props.buttonTheme.color};
    text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
    padding: 12px 20px;
    border-radius: 5px;
    text-decoration: ${props => props.buttonTheme.textDecoration || 'none'};
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    cursor: pointer;

    :disabled {
      // background: #BBBBBB;
      opacity: 0.5;
      cursor: not-allowed;
    }
`;

// TODO Rework styles
const Button = props => {
    const buttonTheme = colorMap[props.bsStyle || 'blue'];
    return (
        <ButtonWrapper buttonTheme={buttonTheme} {...props} />
    );
};

Button.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    bsStyle: PropTypes.oneOf(['red', 'orange', 'blue', 'green']),
};

Button.defaultProps = {
    bsStyle: 'blue',
};

export default Button;
