import { createSelector } from 'reselect';

const getWalletProofs = state => state.user.walletProofs;


export const getCurrentWalletProof = createSelector(
    getWalletProofs,
    (state, coin) => coin,
    (walletProofs, coin) => {
        // console.log(coin, walletProofs);
        return (walletProofs && walletProofs.sort(function(a, b) { return a.ts < b.ts; }).length > 0) ? walletProofs.sort(function(a, b) { return a.ts < b.ts; })
            .find(walletProof => walletProof.ps.includes(' ' + coin)) || undefined : undefined;
    }
);
