import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QuickTradeScreen from './QuickTradeScreen';
import { setReferredBy } from '../../actions/referrals';

function mapStateToProps(state, ownProps) {
    return {
        isUnsupportedCountry: state.errors.type === 'UNSUPPORTED_COUNTRY',
        enabledPairs: state.currency.enabledPairs,
        jurisdiction: state.errors.message,
        cookies: ownProps.cookies,
        referredBy: state.referrals.referredBy,
        ws: state.ws,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setReferredBy: setReferredBy
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickTradeScreen);
