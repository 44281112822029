import React from 'react';
import { default as ReactSelect } from 'react-select';


export default class Select extends React.Component {
    render() {
        return (
            <ReactSelect
                {...this.props}
                styles={{
                    indicatorSeparator: () => ({display:'none'}),
                    control: (baseStyles) => ({
                        ...baseStyles,
                        height: '44px',
                    }),
                }}/>
        );
    }
}