import * as EmailValidator from 'email-validator';

export function validateGenericRegexp(input, regexString) {
    const regex = new RegExp(regexString);
    return input && regexString && input.match(regex);
}

// used for Select input fields with nested options
export function findSelected(obj, findValue) {
    for (let i = 0, len = Object.keys(obj).length; i < len; i++) {
        if (typeof obj[i] == 'object') {
            let found = obj[i].options.find(({value}) => value === findValue);
            if (found) {
                // If the object was found in the recursive call, bubble it up.
                return found;
            }
        }
    }
    return [];
}

export function validatePostal(postal) {
    // (?i)^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$
    return postal && postal.match(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i);
}

export function validateBirthdate(year, month, day) {
    if ((year || month || day) && (!year || !month || !day)) {
        return false;
    }

    const todayYear = new Date().getFullYear();
    const todayMonth = new Date().getMonth();
    const todayDay = new Date().getDate();

    let age = todayYear - year;
    let m = todayMonth - (month - 1);
    if (m < 0 || (m === 0 && todayDay < day)) {
        age--;
    }

    return age >= 18 && age <=110;
}

export function validateEmail(email) {
    return EmailValidator.validate(email);
}