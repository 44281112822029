import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetNews from './AssetNews';
import {getCoinNews} from '../../selectors';

function mapStateToProps(state, props) {
    return {
        newsConfig: getCoinNews(state, props.coin),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetNews);
