import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

// import mail from '../../images/ic-mail-big.svg';
import { Component as AuthFooter } from '../../components/AuthFooter';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as GradientView } from '../../components/GradientView';
import {
    FormContainer,
    FormWrapper,
} from '../../commonStyles/AuthStyles';

import { mobile } from '../../styles';
import mail from '../../images/ic-mail.svg';

const FormWrapperView = styled(FormWrapper)`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

const FormTitle = styled.div`
	font-size: 24px;
	line-height: 36px;
	text-align: center;
	font-weight: 500;
	margin-bottom: 40px;
	margin-top: 40px;
`;

const CheckInboxMessage = styled.div`
    color: #262626;
    font-size: 22px;
	font-weight: 300;
	text-align: center;
	max-width: 300px;

	span {
        color: #262626;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-top: 40px;
        display: block;
        margin-bottom: 40px;
	}
`;

const DidNotGetEmail = styled.div`
    max-width: 390px;
    border-top: 1px solid #ddd;
    padding-top: 35px;
    color: #262626;
    text-align: center;

    p {
      margin-bottom: 25px;
    }

    a {
      display: block;
      text-align: center;
      color: #262626;
      text-decoration: none;
    }

    ${mobile`
        max-width: 340px;
    `};
`;

const MailIcon = styled.div`
  text-align: center;
  margin-bottom: 35px;

  ${mobile`
      margin-bottom: 20px;
  `}
`;


export default class DeviceConfirmScreen extends React.Component {

    static propTypes = {
        history: PropTypes.object,
    };

    validate = () => {

    };

    componentDidMount() {
        document.title = 'Device Confirmation (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });

        this.timer = setTimeout(() => {
            this.props.history.push('/login');
        }, 180000);  // three minutes
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {

        return (
            <FormContainer>
                <GradientView borderRadius={'0 0 15px 15px'}>
                    <FormWrapperView>
                        <FormTitle>
                            <MessageBold>
                                <FormattedMessage
                                    id="auth.signin.title"
                                    defaultMessage="You're signing in from a new device"
                                />

                            </MessageBold>
                        </FormTitle>
                        <MailIcon>
                            <img src={mail} alt="" />
                        </MailIcon>
                        <CheckInboxMessage>
                            Please check your email inbox for a confirmation link
                            <span>
                                To protect your account we require additional confirmation before you can access your account from a new device or IP address.
                            </span>
                        </CheckInboxMessage>
                        <DidNotGetEmail>
                            <p>
                                Didn’t get the email?
                            </p>

                            Sometimes it may take a few minutes,
                            or it could be in your Spam / Junk folder.
                        </DidNotGetEmail>
                    </FormWrapperView>
                </GradientView>
                <AuthFooter type={'WHITE'} />
            </FormContainer>
        );
    }
}
