import { createSelector } from 'reselect';
import { sigDigits, format } from '../utils';

export const getStatus = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.status.type
);

export const getRate = createSelector(
    state => state.transactions.history,
    state => state.transactions.transactionState,
    (history, transaction) => {
        if (transaction.depositAddr != null && transaction.depositAddr.asset != null && transaction.targetAddr != null && transaction.targetAddr.asset != null) {
            // const { basePair, depositAddr, targetAddr } = transaction;
            // const pair = `${depositAddr.asset}-${targetAddr.asset}`;
            // console.log('**********');
            // console.log('basePair:');
            // console.log(basePair);
            // console.log('pair:');
            // console.log(pair);
            // console.log('**********');
            //return transaction.rate === 0 ?
            //    (history[0] ? history[0].rate : null) :
            //    basePair === pair ? sigDigits(transaction.rate, 6) : sigDigits(1/transaction.rate, 6);
            return transaction.rate === 0 ?
                (history[0] ? history[0].rate : null) :
                sigDigits((transaction.targetAmount/transaction.depositAmount), 6);
        } else {
            return transaction.rate === 0 ?
                (history[0] ? history[0].rate : null) : null;
        }
    }
);

export const getRateUpdatedTime = createSelector(
    state => state.transactions.history,
    state => state.transactions.transactionState,
    (history, transaction) => {
        return transaction.rate === 0 ?
            (history[0] ? history[0].date : null) :
            transaction.status.ts;
    }
);

export const getRefundAddress = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.refundAddr.address
);

export const getRefundAmount = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.refundAmount ? transaction.refundAmount : 0
);

export const getRefundTxId = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.refundTxId ? transaction.refundTxId : 'Pending'
);

export const getRefundTs = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.refundTxDate ? transaction.refundTxDate : 0
);

export const getDepositAddress = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.depositAddr ? transaction.depositAddr.address : ''
);

export const getProceedAddress = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.targetAddr.address
);

export const getWithdrawalAddress = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.targetAddr.address ? transaction.targetAddr.address : 'Pending'
);

export const getWithdrawalAmount = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.withdrawalAmount ? transaction.withdrawalAmount : 0
);

export const getWithdrawalTxId = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.withdrawalTxId ? transaction.withdrawalTxId : 'Pending'
);

export const getWithdrawalTs = createSelector(
    state => state.transactions.transactionState,
    transaction => transaction.withdrawalTxDate ? transaction.withdrawalTxDate : 0
);

export const getTransactionCurrencyFromConfig = createSelector(
    state => state.transactions.transactionState,
    state => state.currency.coinsConfig,
    (transaction, coinsConfig) => {
        const currency = transaction.refundAddr.asset;
        const label = coinsConfig && coinsConfig.length > 0 ? coinsConfig.find(coinConfig => coinConfig.name === currency).label : '';
        return {
            ...coinsConfig.find(coinConfig => coinConfig.name === currency),
            label: label
        };
    },
);

export const getTransactionCurrencyToConfig = createSelector(
    state => state.transactions.transactionState,
    state => state.currency.coinsConfig,
    (transaction, coinsConfig) => {
        const currency = transaction.targetAddr.asset;
        const label = coinsConfig && coinsConfig.length > 0 ? coinsConfig.find(coinConfig => coinConfig.name === currency).label : '';
        return {
            ...coinsConfig.find(coinConfig => coinConfig.name === currency),
            label: label
        };
    },
);

export const getTransactionNetworkFromConfig = createSelector(
    state => state.transactions.transactionState,
    state => state.currency.coinsConfig,
    (transaction, coinsConfig) => {
        const currency = transaction.refundAddr.asset;
        const network = transaction.refundAddr.network;

        const coinConfig = coinsConfig.find(coinConfig => coinConfig.name === currency);
        if (coinConfig && coinConfig.networks.length > 0) {
            return coinConfig.networks.find(networkConfig => networkConfig.name === network);
        }
        return undefined;
    },
);

export const getTransactionNetworkToConfig = createSelector(
    state => state.transactions.transactionState,
    state => state.currency.coinsConfig,
    (transaction, coinsConfig) => {
        const currency = transaction.targetAddr.asset;
        const network = transaction.targetAddr.network;

        const coinConfig = coinsConfig.find(coinConfig => coinConfig.name === currency);
        if (coinConfig && coinConfig.networks.length > 0) {
            return coinConfig.networks.find(networkConfig => networkConfig.name === network);
        }
        return undefined;
    },
);

export const getDepositMin = createSelector(
    state => state.transactions.transactionState,
    state => state.currency.coinsConfig,
    (transaction, coinsConfig) => {
        const coin = transaction.refundAddr.asset;
        const network = transaction.refundAddr.network;
        const coinConfig = coinsConfig.find(coinConfig => coinConfig.name === coin);
        const networkConfig = coinConfig.networks.find(networkConfig => networkConfig.name === network);
        const coinPrice = coinConfig ? coinConfig.coinPrice || 100 : 100;
        const txFee = networkConfig.networkFee;

        return (Number(txFee) > 0 && (txFee * coinPrice * 5) > 5) ? sigDigits((txFee * 5), 6) : sigDigits((5 / coinPrice), 6);
    }
);

export const getDepositMax = createSelector(
    state => state.currency.locationConfig,
    state => state.transactions.transactionState,
    state => state.currency.coinsConfig,
    (locationConfig, transaction, coinsConfig) => {
        const coin = transaction.refundAddr.asset;
        const coinConfig = coinsConfig.find(coinConfig => coinConfig.name === coin);
        const coinPrice = coinConfig ? coinConfig.coinPrice || 0 : 0;
        if (coinPrice <= 0) return 0;

        const dvAmount = locationConfig ? locationConfig.dvAmount : 0;
        const dvSpend = transaction.dvSpend;
        const spendableValue = dvAmount - dvSpend;

        if (spendableValue < 15) return 0;

        return sigDigits(spendableValue / coinPrice, 6);
    }
);

export const getDayValue = createSelector(
    state => state.currency.locationConfig,
    state => state.transactions.transactionState,
    (locationConfig, transaction) => {
        const dvAmount = locationConfig ? locationConfig.dvAmount : 0;
        const dvSpend = transaction.dvSpend;
        return format(dvAmount - dvSpend, 2);
    },
);

export const getDayLimit = createSelector(
    state => state.currency.locationConfig,
    (locationConfig) => {
        const dvAmount = locationConfig ? locationConfig.dvAmount : 0;
        return format(dvAmount, 2);
    },
);


// withdrawal fees, not trade fees
export const getWithdrawalFeeEstimate = createSelector(
    state => state.transactions.transactionState,
    state => state.currency.coinsConfig,
    (transaction, coinsConfig) => {
        const status = transaction.status.type;
        const coin = transaction.targetAddr.asset;
        const network = transaction.targetAddr.network;
        const coinConfig = coinsConfig.find(coinConfig => coinConfig.name === coin);
        const networkConfig = coinConfig.networks.find(networkConfig => networkConfig.name === network);
        const feeEstimate = networkConfig && !(status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'QUICKTRADE_FINISHED') ? networkConfig.networkFee : 0;

        return sigDigits(feeEstimate, 6);
    }
);
