import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import Subscription from './Subscription';
import {subscribe} from '../../../../actions/user';

function mapStateToProps() {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            subscribe
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Subscription));
