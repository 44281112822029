import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { mobile } from '../../styles';
import BlocPalToolTip from '../BlocPalToolTip/BlocPalToolTip';


const Container = styled.div`
  margin: 20px;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.primary4};
  display: flex;
  align-items: center;
  justify-content: left;	
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
`;

const NewsItem = styled.div`
  padding-bottom: 25px;
  // border-bottom: 1px solid #eee;  
  ${mobile`
    text-align: center;
  `}
`;

const NewsItemDate = styled.div`
  color: #000000;
  font-size: 10px;
`;

const NewsItemTitleLink = styled.div`
  padding-top: 10px;
  // margin-right: 20px;

  overflow-wrap: break-word;
  word-wrap: break-word;
  // word-break: break-all;
  

  color: ${props => props.theme.colors.primary1};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 5px;
  ${mobile`
    text-align: center;
  `}
  
  a {
    color: ${props => props.theme.colors.primary1};
    text-decoration: none;
  }
`;

const NewsItemDomainLink = styled.div`
  // padding-left: 10px;
  // color: #bb0000;
  // text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  // white-space: nowrap;
  overflow: hidden;
  line-height: 20px;
  margin-bottom: 5px;
  color: #0000AA;
  font-size: 16px;

  a {
    color: ${props => props.theme.colors.primary2};
  }

  a:visited {
    color: ${props => props.theme.colors.primary2};
  }

  a:hover {
    text-decoration: none;
    opacity: 0.9;
  }
`;


export default class AssetNews extends React.Component {

    static propTypes = {
        newsConfig: PropTypes.object,
        coin: PropTypes.string,
    };

    componentDidMount() {
        //
    }

    render() {

        const { newsConfig } = this.props;

        // eslint-disable-next-line no-useless-escape
        const urlRegexp = new RegExp(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);

        return newsConfig ? (
            <Container>
                <Title>
                    <FormattedMessage
                        id="portfolio.asset_news_title"
                        defaultMessage="Recent {coinName} articles"
                        values={{
                            coinName: newsConfig.name
                        }}
                    />
                </Title>
                {newsConfig.news.map((newsItem, i) => (
                    <NewsItem key={i}>
                        <NewsItemTitleLink>
                            <a href={newsItem.link} rel="noopener noreferrer" target="_blank" title="Article URL">{newsItem.title}</a>
                        </NewsItemTitleLink>
                        <NewsItemDomainLink>
                            <a href={newsItem.link} rel="noopener noreferrer" target="_blank" title="Article URL">{urlRegexp.exec(newsItem.link)[1]}</a>
                            <BlocPalToolTip tooltipId={'portfolio.asset_news_tooltip-' + i}>
                                <FormattedMessage
                                    id="portfolio.asset_news_tooltip"
                                    defaultMessage="Full URL: {fullUrl}"
                                    values={{
                                        fullUrl: newsItem.link,
                                    }}
                                />
                            </BlocPalToolTip>
                        </NewsItemDomainLink>
                        <NewsItemDate>
                            <FormattedMessage
                                id="portfolio.newsItemDate"
                                defaultMessage="Published: {pubDate}"
                                values={{
                                    pubDate: newsItem.pubDate,
                                }}
                            />
                        </NewsItemDate>
                    </NewsItem>
                ))}
            </Container>
        ) : (
            <p>Loading...</p>
        );
    }
}
