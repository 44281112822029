import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DropdownMenu from 'react-dd-menu';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Component as RouteButton } from '../../components/RouteButton';
import { Component as RouteMenu } from '../../components/RouteMenu';
import { MenuItem as MenuItem } from '../../components/RouteMenu';
import { Container as AccountNotices } from '../../components/AccountNotices';

import config from 'config';
import { mobile } from '../../styles';

import logo from '../../images/BlocpalX-logoonlight.svg';

const isAuthEnabled = config.get('features.authorization');

const Container = styled.div`
  color: #fff;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  
  margin: 0.4em 0 0.5em 0;
  padding: 0 0.5em 0 0.5em;
  
  ${mobile`
    margin-top: 8px;
    margin-bottom: 5px;
    padding: 0;
  `}
`;

const DesktopRouteMenu = styled.div`
    ul {
        flex-wrap: nowrap;
    }

  ${mobile`
    display: none;
  `}
`;

const LogoWrapper = styled.div`
  display: inline-block;
  margin-left: 0.5em;
  margin-right: 1em;
  margin-bottom: 8px;
  // position: relative;

  ${mobile`
    display:none;

    // width: 90%;
    // max-width: 400px;
    // margin: 5px auto 15px auto;
    // text-align: center;
  `}
`;

const Logo = styled(Link)`
  text-align: center;
  
  img {
    vertical-align: middle;
    width: 220px;
  }
`;

const MenuWrapper = styled.div`
  display: inline-flex;
  flex: 1;
  transition: 0.3s;
  margin: auto 2px;
  
  ${mobile`
    //height: ${props => props.open ? '45px' : '0'};
    //padding-top: ${props => props.open ? '20px' : '0'};
    flex: 100%;
  `}
`;

const SubLink = styled(Link)`
  padding-left: 30px !important;
  font-size: 14px;
`;

const LinkDesktopOnly = styled.div`
  display: block;
  
  ${mobile`
    display: none;
  `}
`;

const LinkMobileOnly = styled.div`
  display: none;
  
  ${mobile`
    display: block;
  `}
`;

const UserOptions = styled.span`
  list-style: none;
  margin: 5px 10px 0px auto;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  flex-wrap: nowrap;

  > li {
    display: inline-block;
    margin-left: 0.5em;
    white-space: nowrap;
  }

  ${mobile`
    margin: auto 10px auto auto;
  `}
`;

const Burger = styled.div`
  width: 30px;
  height: 25px;
  position: relative;
  z-index: 12;

  // margin-top: 15px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;

  // right: 20px;
  // top: -30px;
  // margin-left: auto;

  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;

  ${mobile`
    display: block;
  `};

  span {
      display: block;
      position: absolute;
      height: 5px;
      width: 100%;
      background: #ef8632;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      :nth-child(1) {
        // top: ${props => props.open ? '6px' : 0};
        ${props => props.open ? 'transform: rotate(135deg)' : ''}
      }

      :nth-child(2) {
        top: 10px;
        ${props => props.open ? `
            opacity: 0;
            left: -60px;
        ` : ''}
      }

      :nth-child(3) {
        top: ${props => props.open ? '10px' : '20px'};
        ${props => props.open ? 'transform: rotate(-135deg)' : ''}
      }
  }
`;

const StyledBurgerMenu = styled(DropdownMenu)`
    position: relative;
    margin-top: 5px;
    margin-right: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    width: 200px;
  
    display: none;
    ${mobile`
        display: block;
    `};

    .dd-items-left {
      padding: 0;
    }

    span.menuText {
        margin-left: 8px;
    }

    ul {
        margin-top: 0px;
        margin-bottom: 5px;
        border-bottom: 0px;
    }

    > span {
      color: #fff;
      cursor: pointer;
      white-space: nowrap;
    }

    > div {
      position: absolute;
      top: 38px;
      left: -30px;
      // left: -40px;
      background-color: #fff;
      border-radius: 7px;
      outline: 2px solid #d9d9d9;
      // box-shadow: 0 0 26px -14px rgba(11,32,78,0.85);
      z-index: 12;
       
      ${mobile`
          top: 38px;
          left: -30px;
      `};

      div {
        p {
          padding: 10px;
          margin: 0px;
          color: #858585;
          width: 120px;
          border-bottom: 1px solid #888;
        }

        span, a {
          padding: 5px 15px 5px 15px;
          color: #021747;
          font-weight: 600;
          cursor: pointer;
          text-decoration: none;
          display: block;
        }

        a:hover {
          background-color: #eee;
        }
      }
    }
`;

const UserName = styled.li`
  display: inline-block;
  border: 2px solid ${props => props.theme.colors.primary8};
  border-radius: 7px;
  background-color: #FFFFFF;
  padding: 8px 18px;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.6px;
  white-space: nowrap;
  color: #ef8632;
  margin-top: 2px;
  cursor: pointer;
`;

const StyledMenu = styled(DropdownMenu)`
  position: relative;
  // margin-top: 12px;

  margin-top: auto;
  margin-bottom: auto;
  // margin-right: 5px;

  .dd-items-left {
    padding: 0;
  }

  ul {
    width: 200px;
    margin-top: 0px;
    margin-bottom: 5px;
    border-bottom: 0px;
  }

  > span {
    // color: #ef8632;
    cursor: pointer;
    white-space: nowrap;
  }

  > div {
    position: absolute;
    top: 30px;
    right: 10px;
    background-color: #fff;
    border-radius: 8px;
    outline: 2px solid #d9d9d9;
    // box-shadow: 0 0 26px -14px rgba(11,32,78,0.85);
    z-index: 12;

    ${mobile`
        top: 25px;
        right: 10px;
    `};

    div {
      p {
        padding: 10px;
        margin: 0px;
        color: #858585;
        width: 150px;
        // font-size: 16px;
        border-bottom: 1px solid #888;
      }

      span, a {
        padding: 5px 10px 5px 15px;
        color: #021747;
        // font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
        display: block;
      }

      a:hover {
        background-color: #eee;
      }
    }
  }
`;

const unauthorizedUserMenuItems = [
    new MenuItem('market', 'Market', '/market'),
    new MenuItem('projects', 'Assets', '/projects'),
    // new MenuItem('quicktrade', 'QuickTrade', '/quicktrade'),
];

const authorizedUserMenuItems = [
    // new MenuItem('account', 'Account', '/account/profile'),
    new MenuItem('market', 'Market', '/market'),
    new MenuItem('wallet', 'Wallet', '/account/portfolio'),
];

export default class Header extends React.Component {

    static propTypes = {
        userNickName: PropTypes.string,
        isAuthenticated: PropTypes.bool,
        maintenanceMode: PropTypes.bool,

        logout: PropTypes.func,

        match: PropTypes.object,
    };

    state = {
        isBurgerOpen: false,
        isMenuOpen: false,
    };

    toggle = () => {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        });
    };

    toggleBurger = () => {
        this.setState({
            isBurgerOpen: !this.state.isBurgerOpen
        });
    };

    close = () => {
        this.setState({ isMenuOpen: false });
    };

    closeBurger = () => {
        this.setState({ isBurgerOpen: false });
    };

    onLogout = (e) => {
        e.preventDefault();

        // console.log('onLogout');
        this.props.logout();
    };

    render() {
        const { userNickName, isAuthenticated, maintenanceMode, match } = this.props;
        const { open, isBurgerOpen, isMenuOpen } = this.state;

        const menuItems = (isAuthenticated) ? authorizedUserMenuItems : unauthorizedUserMenuItems;

        const menuOptions = {
            isOpen: isMenuOpen,
            close: this.close,
            toggle: '',
            align: 'left'
        };

        const burgerOptions = {
            isOpen: isBurgerOpen,
            close: this.closeBurger,
            toggle: <span className='menuText' onClick={this.toggleBurger}>Menu</span>,
            align: 'left'
        };

        return !maintenanceMode && (
            <React.Fragment>
                <Container>
                    <LogoWrapper>
                        <Logo to="/" title="BlocPalX Logo">
                            <img src={'/' + logo} />
                        </Logo>
                    </LogoWrapper>
                    {isAuthEnabled && (
                        <React.Fragment>
                            <MenuWrapper open={open}>
                                <DesktopRouteMenu>
                                    <RouteMenu items={menuItems} />
                                </DesktopRouteMenu>
                                <Burger
                                    open={open}
                                    onClick={this.toggleBurger}
                                >
                                    <span />
                                    <span />
                                    <span />
                                </Burger>
                                {isBurgerOpen && (
                                    <StyledBurgerMenu {...burgerOptions}>
                                        <div>
                                            <div>
                                                <Link to='/' onClick={this.closeBurger}>
                                                    <FormattedMessage
                                                        id="navigation.home"
                                                        defaultMessage="Home"
                                                    />
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to='/market' onClick={this.closeBurger}>
                                                    <FormattedMessage
                                                        id="navigation.market"
                                                        defaultMessage="Market"
                                                    />
                                                </Link>
                                            </div>
                                            {isAuthenticated ? (
                                                <React.Fragment>
                                                    <div>
                                                        <Link to='/account/portfolio' onClick={this.closeBurger}>
                                                            <FormattedMessage
                                                                id="navigation.portoflio"
                                                                defaultMessage="Wallet"
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to='/account/profile' onClick={this.closeBurger}>
                                                            <FormattedMessage
                                                                id="navigation.profile"
                                                                defaultMessage="Account"
                                                            />
                                                        </Link>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div>
                                                        <Link to='/projects' onClick={this.closeBurger}>
                                                            <FormattedMessage
                                                                id="navigation.projects"
                                                                defaultMessage="Assets"
                                                            />
                                                        </Link>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <React.Fragment>
                                                <div>
                                                    <Link to='/walletStatus' onClick={this.closeBurger}>
                                                        <FormattedMessage
                                                            id="navigation.proofOfReserves"
                                                            defaultMessage="Proof Of Reserves"
                                                        />
                                                    </Link>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    </StyledBurgerMenu>
                                )}

                                <UserOptions>
                                    {!isAuthenticated && <li>
                                        <RouteButton
                                            to="/login"
                                            title="Login"
                                            bsStyle="menu"
                                            borderColor="#021747"
                                        >
                                            <FormattedMessage
                                                id="auth.signin_title"
                                                defaultMessage="Log In"
                                            />
                                        </RouteButton>
                                    </li>}
                                    {!isAuthenticated && <li>
                                        <RouteButton
                                            to="/signup"
                                            title="Sign Up"
                                            bsStyle="orange"
                                        >
                                            <FormattedMessage
                                                id="auth.signup.title"
                                                defaultMessage="Sign Up"
                                            />
                                        </RouteButton>
                                    </li>}
                                    {isAuthenticated && (
                                        <UserName onClick={this.toggle}>{userNickName} {'\u25BE'}</UserName>
                                    )}
                                </UserOptions>
                                {isAuthenticated && (
                                    <React.Fragment>
                                        {isMenuOpen && (
                                            <StyledMenu {...menuOptions}>
                                                <div>
                                                    <div>
                                                        <Link to='/account/portfolio'>
                                                            <FormattedMessage
                                                                id="navigation.wallet"
                                                                defaultMessage="Wallet"
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to='/market/openOrders'>
                                                            <FormattedMessage
                                                                id="navigation.openOrders"
                                                                defaultMessage="Open Orders"
                                                            />
                                                        </Link>
                                                    </div>
                                                    <LinkDesktopOnly>
                                                        <Link to='/account/profile/summary' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.account"
                                                                defaultMessage="Account"
                                                            />
                                                        </Link>
                                                    </LinkDesktopOnly>
                                                    <LinkMobileOnly>
                                                        <Link to='/account/profile' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.account"
                                                                defaultMessage="Account"
                                                            />
                                                        </Link>
                                                        <SubLink to='/account/profile/summary' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.accountSummary"
                                                                defaultMessage="Summary"
                                                            />
                                                        </SubLink>
                                                    </LinkMobileOnly>
                                                    <div>
                                                        <SubLink to='/account/profile/notifications' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.accountNotifications"
                                                                defaultMessage="Notifications"
                                                            />
                                                        </SubLink>
                                                    </div>
                                                    <div>
                                                        <SubLink to='/account/profile/verification' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.accountVerification"
                                                                defaultMessage="Identity Verification"
                                                            />
                                                        </SubLink>
                                                    </div>
                                                    <div>
                                                        <SubLink to='/account/profile/config2fa' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.accountSecurity"
                                                                defaultMessage="Security"
                                                            />
                                                        </SubLink>
                                                    </div>
                                                    <div>
                                                        <SubLink to='/account/profile/proofOfReserves' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.proofOfReserves"
                                                                defaultMessage="Proof Of Reserves"
                                                            />
                                                        </SubLink>
                                                    </div>
                                                    <div>
                                                        <SubLink to='/account/profile/downloads' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.accountReports"
                                                                defaultMessage="Statements & Reports"
                                                            />
                                                        </SubLink>
                                                    </div>
                                                    <div>
                                                        <SubLink to='/account/profile/apikeys' onClick={this.close}>
                                                            <FormattedMessage
                                                                id="navigation.accountApiKeys"
                                                                defaultMessage="API Keys"
                                                            />
                                                        </SubLink>
                                                    </div>
                                                    <div>
                                                        <Link to='#' onClick={(e) => this.onLogout(e)}>
                                                            <FormattedMessage
                                                                id="auth.sign_out"
                                                                defaultMessage="Sign Out"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </StyledMenu>
                                        )}
                                    </React.Fragment>
                                )}
                            </MenuWrapper>
                        </React.Fragment>
                    )}
                </Container>
                {/*<Notifications/>*/}
                {(match.url !== '/' && match.url !== '/proofOfReserves') && (
                    <AccountNotices />
                )}
            </React.Fragment>
        );
    }
}
