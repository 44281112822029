import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { validateEmail } from '../../utils';

import { Component as GradientView } from '../../components/GradientView';
import { Component as Input } from '../../components/Input';
import { Component as Checkbox } from '../../components/Checkbox';
import { Component as Button } from '../../components/Button';
import { Component as AuthFooter } from '../../components/AuthFooter';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as Message, FontWeight } from '../../components/Message';
import { Component as Select } from '../../components/Select';
import {
    LinkButton,
    FormContainer,
    FormWrapper,
    AuthInputGroup,
    LabelP
} from '../../commonStyles/AuthStyles';


const InputGroupView = styled(AuthInputGroup)`
`;

const SignUpButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 35px;
  border-radius: 10px;
  text-transform: capitalize;
`;

const LinkBlock = styled.div`
  background-color: #021747;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`;

const SignInButton = styled(LinkButton)`
    width: 185px;
    padding: 16px 20px;
`;

export default class SignUpScreen extends React.Component {

    static propTypes = {
        countries: PropTypes.array,
        regions: PropTypes.array,

        registerEmail: PropTypes.func,
        updateAuthInfo: PropTypes.func,
        requestCountriesList: PropTypes.func,
        requestRegionsList: PropTypes.func,
        regionServiceStatus: PropTypes.func,

        history: PropTypes.object,
        match: PropTypes.object,
    };

    state = {
        email: '',
        emailDisabled: false,
        emailSubscribe: '',
        error: false,
        errorMessage: '',
        loading: false,
        // can these be detected from the browser and preset somehow?
        // or could we add a geoIp lookup to the REST API to preset these to a reasonable default?
        selectedCountry: '',
        selectedRegion: '',
        jurisdictionRetailLocked: true,
        jurisdictionAccreditedLocked: true,
        accredited: false,
        allowSubscribe: false,
        emailSubscribeValid: false
    };


    componentDidMount() {
        document.title = 'Sign Up (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });

        if (queryString.parse(this.props.history.location.search).email && validateEmail(queryString.parse(this.props.history.location.search).email)) {
            this.setState({
                email: queryString.parse(this.props.history.location.search).email.toLowerCase(),
                emailDisabled: true,
            });
        }

        this.loadInitialRegion();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedCountry !== prevState.selectedCountry) {
            this.loadRegions(this.state.selectedCountry.value);
        }
    }

    loadInitialRegion = async () => {
        this.setState({
            loading: true,
            error: false,
            errorMessage: '',
        });

        await this.loadCountries();

        const response = await this.props.regionServiceStatus();

        if (response.error) {
            this.setState({
                loading: false,
                error: true,
                errorMessage: response.payload.status.message,
            });
        } else {
            if (response && response.payload && response.payload.r) {
                // console.log('regionServiceResponse', response.payload);

                const regionParts = response.payload.r.split('_');

                const selectedCountry = this.props.countries.find(country => country.value === regionParts[0]);
                const selectedRegion = this.props.regions.find(region => region.value === response.payload.r);
                const jurisdictionRetailLocked = (response.payload.tl !== undefined) ? response.payload.tl : true;
                const jurisdictionAccreditedLocked = (response.payload.t3l !== undefined) ? response.payload.t3l : true;

                this.setState({
                    loading: false,
                    jurisdictionRetailLocked: jurisdictionRetailLocked,
                    jurisdictionAccreditedLocked: jurisdictionAccreditedLocked,
                    selectedCountry: (selectedCountry) ? selectedCountry : '',
                    selectedRegion: (selectedRegion) ? selectedRegion : '',
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        }
    };

    loadCountries = async () => {
        this.setState({
            loading: true,
            error: false,
            errorMessage: '',
        });

        const response = await this.props.requestCountriesList();

        if (response.error) {
            this.setState({
                loading: false,
                error: true,
                errorMessage: response.payload.status.message,
            });
        } else {
            // reducer will populate state
            // nothing further to do?

            this.setState({
                loading: false
            });
        }
    };

    loadRegions = async (providedCountry) => {
        this.setState({
            loading: true,
            error: false,
            errorMessage: '',
        });

        const response = await this.props.requestRegionsList(providedCountry);

        if (response.error) {
            this.setState({
                loading: false,
                error: true,
                errorMessage: response.payload.status.message,
            });
        } else {
            // reducer will populate state
            // nothing further to do?

            this.setState({
                loading: false
            });
        }
    };

    onJurisdictionSubmit = async event => {
        event.preventDefault();

        const { selectedRegion } = this.state;

        this.setState({
            loading: true,
            error: false,
            errorMessage: '',
        });

        const response = await this.props.regionServiceStatus(selectedRegion.value);

        if (response.error) {
            this.setState({
                loading: false,
                error: true,
                errorMessage: response.payload.status.message,
            });
        } else {
            if (response && response.payload && response.payload.tl !== undefined) {
                // console.log('regionServiceResponse', response.payload);

                const jurisdictionRetailLocked = response.payload.tl;
                const jurisdictionAccreditedLocked = (response.payload.t3l !== undefined) ? response.payload.t3l : true;

                this.setState({
                    loading: false,
                    jurisdictionRetailLocked: jurisdictionRetailLocked,
                    jurisdictionAccreditedLocked: jurisdictionAccreditedLocked,
                });

                if (!jurisdictionRetailLocked || (!jurisdictionAccreditedLocked && this.state.accredited === true)) {
                    this.props.history.push('/signup/regionLive');
                } else if (this.state.accredited === true) {
                    this.props.history.push('/signup/regionPendingAccredited');
                } else {
                    this.props.history.push('/signup/regionPending');
                }
            }
        }
    }

    onSubmit = async event => {
        event.preventDefault();
        const { email } = this.state;

        this.setState({
            loading: true,
            error: false,
            errorMessage: '',
        });

        if (validateEmail(email)) {
            const response = await this.props.registerEmail({ email });

            this.setState({
                loading: false
            });

            if (response.error ||
                (response.payload && response.payload.status && response.payload.status.code === 112405)) {

                this.setState({
                    loading: false,
                    error: true,
                    errorMessage: response.payload.status.message,
                });
            } else {
                this.props.updateAuthInfo({ email });
                this.props.history.push('/verify-email');
                window.gtag('event', 'sign_up');
            }
        } else {
            this.setState({
                error: true,
                loading: false
            });
        }
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onChangeEmailSubscribe = event => {
        this.setState({
            emailSubscribe: event.target.value,
            emailSubscribeValid: validateEmail(event.target.value)
        });
    };

    onCountrySelect = (event) => {
        this.setState({
            selectedCountry: event,
            selectedRegion: null
        });
    }

    onRegionSelect = (event) => {
        this.setState({
            selectedRegion: event
        });
    }

    render() {

        const { countries, regions, match } = this.props;
        const {
            selectedCountry,
            selectedRegion,
            jurisdictionRetailLocked,
            email,
            emailDisabled,
            rememberMe,
            allowSubscribe,
            error,
            errorMessage,
            loading,
            accredited,
            emailSubscribe,
            emailSubscribeValid
        } = this.state;

        // console.log(regions);
        // console.log('Selected country:', selectedCountry);
        // console.log('Selected region:', selectedRegion);
        // console.log('Accredited:', accredited);

        return (
            <FormContainer>
                <GradientView borderRadius={'0 0 15px 15px'}>
                    {match.params.step === 'waitlistSuccess' ?
                        <FormWrapper>
                            <MessageBold>
                                <FormattedMessage
                                    id="auth.signup.success"
                                    defaultMessage="Success!"
                                />
                            </MessageBold>
                            <Message fontWeight={FontWeight.LIGHT}>
                                <FormattedMessage
                                    id="auth.signup.addedtowait"
                                    defaultMessage="Added you to the waitlist."
                                />
                            </Message>
                        </FormWrapper>
                        : null}
                    {match.params.step === undefined ?
                        <FormWrapper onSubmit={this.onJurisdictionSubmit}>
                            <MessageBold>
                                <FormattedMessage
                                    id="auth.signup.welcome"
                                    defaultMessage="Welcome!"
                                />
                            </MessageBold>
                            <Message fontWeight={FontWeight.LIGHT}>
                                <FormattedMessage
                                    id="auth.signup.wherereside"
                                    defaultMessage="Where do you reside?"
                                />
                            </Message>
                            <InputGroupView>
                                <label>Country</label>
                                <Select
                                    onChange={this.onCountrySelect}
                                    value={(selectedCountry && selectedCountry.value !== 'QQ') ? selectedCountry : undefined}
                                    options={countries}
                                />
                            </InputGroupView>

                            <InputGroupView>
                                <label>Region</label>
                                <Select
                                    onChange={this.onRegionSelect}
                                    value={(selectedRegion && !selectedRegion.value.endsWith('_QQ')) ? selectedRegion : undefined}
                                    options={regions}
                                />
                            </InputGroupView>
                            <Checkbox
                                label={
                                    <FormattedMessage
                                        id="auth.signup.accredited"
                                        defaultMessage="I am an accredited investor"

                                    />
                                }
                                checked={accredited}
                                onChange={() => this.setState({ accredited: !accredited })}
                            />
                            <InputGroupView>
                                <SignUpButton
                                    disabled={!(selectedCountry && selectedRegion)}
                                    bsStyle={'orange'}
                                >
                                    <FormattedMessage
                                        id="auth.signup.next"
                                        defaultMessage="Next"
                                    />
                                </SignUpButton>
                            </InputGroupView>
                        </FormWrapper> : null}
                    {match.params.step && match.params.step.startsWith('regionPending') ?
                        <FormWrapper
                            onSubmit={() => {
                                window.gtag('event', 'subscribe_waitlist', { accredited: match.params.step === 'regionPendingAccredited' ? 'true' : 'false', country: selectedCountry.value, region: selectedRegion.value });
                            }}
                            method='post'
                            action='https://blocpal.us18.list-manage.com/subscribe/post?u=8886b3355946d475d0ac90081&amp;id=743a8b659f&amp;f_id=009b2be7f0'
                        >
                            {match.params.step === 'regionPendingAccredited' ? (
                                <input type='hidden' name='ACCRED' value='true'/>
                            ) : (
                                <input type='hidden' name='ACCRED' value='false'/>
                            )}
                            <MessageBold>
                                <FormattedMessage
                                    id="auth.signup.notlive"
                                    defaultMessage="Sorry! We are not yet live there."
                                />
                            </MessageBold>
                            <Message fontWeight={FontWeight.LIGHT}>
                                <FormattedMessage
                                    id="auth.signup.waitlistemail"
                                    defaultMessage="Let's add you to our waiting list."
                                />
                            </Message>
                            <InputGroupView style={{ display: 'none' }}>
                                <label>Country</label>
                                <Select
                                    name="COUNTRY"
                                    onChange={this.onCountrySelect}
                                    value={(selectedCountry && selectedCountry.value !== 'QQ') ? selectedCountry : undefined}
                                    options={countries}
                                />
                            </InputGroupView>

                            <InputGroupView style={{ display: 'none' }}>
                                <label>Region</label>
                                <Select
                                    name="REGION"
                                    onChange={this.onRegionSelect}
                                    value={(selectedRegion && !selectedRegion.value.endsWith('_QQ')) ? selectedRegion : undefined}
                                    options={regions}
                                />
                            </InputGroupView>
                            <InputGroupView>
                                <label>Email</label>
                                <Input
                                    name="EMAIL"
                                    placeholder="Enter Your Email"
                                    value={emailSubscribe}
                                    onChange={this.onChangeEmailSubscribe}
                                    error={error ? errorMessage ? 'Error: ' + errorMessage : 'Please provide a valid address.' : ''}
                                    disabled={loading}
                                />
                                <p>
                                    <FormattedMessage
                                        id="auth.signup.weadd"
                                        defaultMessage="We will inform you when we go live in your country."
                                    />
                                </p>

                            </InputGroupView>
                            <Checkbox
                                label={
                                    <FormattedMessage
                                        id="auth.signup.mailcheckbox"
                                        defaultMessage="I agree to be added to the waiting list."

                                    />
                                }
                                checked={allowSubscribe}
                                onChange={() => this.setState({ allowSubscribe: !allowSubscribe })}
                            />

                            <InputGroupView>
                                <SignUpButton
                                    disabled={!allowSubscribe || !emailSubscribe || loading || !emailSubscribeValid}
                                    bsStyle={'orange'}
                                >
                                    <FormattedMessage
                                        id="auth.signup.subscribe"
                                        defaultMessage="Subscribe"
                                    />
                                </SignUpButton>
                            </InputGroupView>
                        </FormWrapper> : null}
                    {match.params.step === 'regionLive' ?
                        <FormWrapper onSubmit={this.onSubmit}>
                            <MessageBold >
                                <FormattedMessage
                                    id="auth.signup.welcome"
                                    defaultMessage="Welcome!"
                                />
                            </MessageBold>
                            {!emailDisabled && (
                                <Message fontWeight={FontWeight.LIGHT}>
                                    <FormattedMessage
                                        id="auth.signup.whatemail"
                                        defaultMessage="What's your email?"
                                    />
                                </Message>
                            )}
                            <InputGroupView>
                                <label>Email</label>
                                <Input
                                    name="email"
                                    placeholder="Enter Your Email"
                                    value={email}
                                    onChange={this.onChange}
                                    error={error ? errorMessage ? 'Error: ' + errorMessage : 'Please provide a valid address' : ''}
                                    disabled={loading || emailDisabled}
                                />
                                <p>
                                    <FormattedMessage
                                        id="auth.signup.hint"
                                        defaultMessage="We’ll send a verification link to the email address provided"
                                    />
                                </p>
                            </InputGroupView>
                            <Checkbox
                                label={
                                    <FormattedMessage
                                        id="auth.signup.checkbox"
                                        defaultMessage="I agree to the {termsLink} and {privacyLink}"
                                        values={{
                                            termsLink: (
                                                <a href="/terms" target="_blank">Terms of Service</a>
                                            ),
                                            privacyLink: (
                                                <a href="/privacy" target="_blank">Privacy Policy</a>
                                            )
                                        }}
                                    />
                                }
                                checked={rememberMe}
                                onChange={() => this.setState({ rememberMe: !rememberMe })}
                            />

                            <InputGroupView>
                                <SignUpButton
                                    disabled={!rememberMe || !email || loading}
                                    bsStyle={'orange'}
                                >
                                    <FormattedMessage
                                        id="auth.signup.cta"
                                        defaultMessage="Sign up"
                                    />
                                </SignUpButton>
                            </InputGroupView>

                            { !emailDisabled && jurisdictionRetailLocked && (
                                <InputGroupView>
                                    <p>
                                        <FormattedMessage
                                            id="auth.signup.accreditedOnly"
                                            defaultMessage="Note: Only accredited investors are currently allowed to trade in this region. You will be asked to demonstrate your accredited investor status before you are permitted to trade."
                                        />
                                    </p>
                                </InputGroupView>
                            )}
                        </FormWrapper> : null}
                </GradientView>
                <LinkBlock>
                    <LabelP>
                        <FormattedMessage
                            id="auth.signin.already_account"
                            defaultMessage="Already have an account?"
                        />
                    </LabelP>

                    <SignInButton to="/login">
                        <FormattedMessage
                            id="auth.signin.signin_link"
                            defaultMessage="Sign In"
                        />
                    </SignInButton>
                </LinkBlock>
                <AuthFooter type={'WHITE'} />
            </FormContainer>
        );
    }
}
