import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputTwoFa from './InputTwoFa';
import { clearRequestSmsSend, requestSendSmsCode } from '../../actions/auth';

function mapStateToProps(state) {
    return {
        securedMethods: state.user.securedMethods,
        securedActions: state.user.securedActions,
        isCredentialsError: state.errors.type === 'INVALID_2FA_CODE',
        isSmsSendSuccess: state.auth.isSmsSendSuccess,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearRequestSmsSend,
            requestSendSmsCode,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputTwoFa);
