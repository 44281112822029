import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegistrationSetPasswordScreen from './RegistrationSetPasswordScreen';
import { updatePassword, registerPassword } from '../../actions/auth';

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePassword,
            registerPassword,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationSetPasswordScreen);
