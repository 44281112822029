import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TradeTabGroup from './TradeTabGroup';
import {getAuthenticated, getBaseCoinConfig, getQuoteCoinConfig, getTradePair} from '../../../../selectors';

function mapStateToProps(state) {
    return {
        baseCoinConfigTakerFee: getBaseCoinConfig(state) && getBaseCoinConfig(state).takerFee,
        quoteCoinConfigTakerFee: getQuoteCoinConfig(state) && getQuoteCoinConfig(state).takerFee,
        isAuthenticated: getAuthenticated(state),
        tradePairStatus: getTradePair(state) && getTradePair(state).status,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradeTabGroup);
