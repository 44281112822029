import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProjectsSidebar from './ProjectsSidebar';
import {getAssetOptionsWithPrice} from '../../../../selectors';


function mapStateToProps(state) {
    return {
        coinsConfig: getAssetOptionsWithPrice(state),
        fiatCurrency: state.portfolio.fiatCurrency,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsSidebar));
