import {getDeviceInfo} from '../../utils';


/*
 * INTERNAL REQUESTS
 */

/*
 * REST REQUESTS
 */

/*
 * WEBSOCKET REQUESTS
 */

export function changePair({currencyFrom, currencyTo}) {
    return (dispatch, getState) => {
        if (!currencyFrom || !currencyTo) {
            return;
        }

        const currencyState = getState().currency;

        const { defaultPair, currentPair, enabledPairs } = currencyState;

        let newPair = `${currencyFrom}-${currencyTo}`;

        if (currencyTo === currencyFrom) {
            if (currencyFrom !== currentPair.currencyFrom) {
                let availableToPairs = enabledPairs
                    .filter(pair => pair.currencyFrom === currencyFrom || pair.currencyTo === currencyFrom)
                    .map(pair => pair.currencyTo === currencyFrom ? pair.currencyFrom : pair.currencyTo)
                    .concat([currencyFrom]);

                let newCurrencyTo = currencyFrom === defaultPair.currencyTo ?
                    defaultPair.currencyFrom :
                    defaultPair.currencyTo;

                newCurrencyTo = availableToPairs.includes(newCurrencyTo) ?
                    newCurrencyTo :
                    availableToPairs[0];

                newPair = `${currencyFrom}-${newCurrencyTo}`;
            }
            if (currencyTo !== currentPair.currencyTo) {

                let availableFromPairs = enabledPairs
                    .filter(pair => pair.currencyTo === currencyTo || pair.currencyFrom === currencyTo)
                    .map(pair => pair.currencyFrom === currencyTo ? pair.currencyTo : pair.currencyFrom)
                    .concat([currencyTo]);


                let newCurrencyFrom = currencyTo === defaultPair.currencyFrom ?
                    defaultPair.currencyTo :
                    defaultPair.currencyFrom;

                newCurrencyFrom = availableFromPairs.includes(newCurrencyFrom) ?
                    newCurrencyFrom :
                    availableFromPairs[0];

                newPair = `${newCurrencyFrom}-${currencyTo}`;
            }
        }

        dispatch({
            ws: {
                type: 'REQUEST_CHANGE_PAIR',
                data: {
                    newPair
                }
            }
        });
    };

}

export const stopOneWayPairFeed = () => ({
    ws: {
        type: 'STOP_ONE_WAY_PAIR_FEED',
        data: {
            pair: 'any',
        },
    }
});

export function submitQuickTrade({currencyFrom, currencyTo, proceedAddress, proceedTag, refundAddress, refundTag, name, email, referredBy}) {
    // requests with device info must be async
    return async (dispatch, getState) => {

        const currencyState = getState().currency;
        const { coinsConfig, enabledPairs } = currencyState;

        const isCurrencyFromHasTag = coinsConfig
            .find(currency => currency.name === currencyFrom)
            .hasOwnProperty('addressTagName');

        const isCurrencyToHasTag = coinsConfig
            .find(currency => currency.name === currencyTo)
            .hasOwnProperty('addressTagName');

        const pair = enabledPairs.find(pair => pair.currencyFrom === currencyFrom && pair.currencyTo === currencyTo);

        const RefundAddress = {
            asset: currencyFrom,
            address: refundAddress,
            tag: isCurrencyFromHasTag ? refundTag : undefined
        };

        const ProceedAddress = {
            asset: currencyTo,
            address: proceedAddress,
            tag: isCurrencyToHasTag ? proceedTag : undefined
        };

        const data = {
            refundAddr: RefundAddress,
            targetAddr: ProceedAddress,
            rate: pair.rate.toString(),
            email,
            name,
            referredBy,
            deviceInfo: await getDeviceInfo()
        };

        dispatch({
            type: 'SUBMIT_QUICKTRADE_DEVICE_INFO',
            ws: {
                type: 'SUBMIT_QUICKTRADE',
                data
            }
        });

    };
}

export function subscribeOnTransactionUpdates(transactionId) {
    // requests with device info must be async
    return async dispatch => {
        dispatch({
            type: 'REQUEST_SUBSCRIBE_QT_DEVICE_INFO',
            ws: {
                type: 'REQUEST_SUBSCRIBE_QT',
                data: {
                    txId: transactionId,
                    deviceInfo: await getDeviceInfo()
                }
            }
        });
    };
}
