import React from 'react';
import PropTypes from 'prop-types';
import { Component as Tooltip } from '../Tooltip';
import styled from 'styled-components';

import infoIcon from '../../images/ic-help.svg';

const InfoIconImg = styled.img`
  margin-left: 5px;
  margin-right: 5px;
  z-index: 2;
`;


export default class InfoIcon extends React.Component {

    static propTypes = {
        content: PropTypes.any
    };

    render() {

        return (
            <Tooltip {...this.props}>
                <InfoIconImg src={'/' + infoIcon}/>
            </Tooltip>
        );

    }

}
