//
// sourced from https://www.npmjs.com/package/react-progress-button
//
// updated for webpack and react ^16.3.0 by EB
//

import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import styled from 'styled-components';

export const STATE = {
    LOADING: 'loading',
    DISABLED: 'disabled',
    SUCCESS: 'success',
    ERROR: 'error',
    NOTHING: ''
};

const ProgressButtonStyle = styled.div`
    .pb-container {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    .pb-container .pb-button {
        // background: linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%);
        background: rgba(2, 23, 71, 1);
        color: #FFFFFF;
        border: 0;
        outline: 0;
        border-radius: 5px;
        cursor: pointer;
        padding: 16px 20px;
        text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
        text-decoration: none;
        text-align: center;
        height: 54px;
        width: 100%;
        -webkit-tap-highlight-color: transparent;
        outline: none;
        transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s;
    }
    .pb-container .pb-button span {
        display: inherit;
        transition: opacity 0.3s 0.1s;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 1.05px;
        text-transform: capitalize;
    }
    .pb-container .pb-button svg {
        height: 30px;
        // width: 100%;
        position: absolute;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }
    .pb-container .pb-button svg path {
        opacity: 0;
        fill: none;
    }
    .pb-container .pb-button svg.pb-progress-circle {
        animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710);
    }
    .pb-container .pb-button svg.pb-progress-circle path {
        stroke: currentColor;
        stroke-width: 5;
    }
    .pb-container .pb-button svg.pb-checkmark path,
    .pb-container .pb-button svg.pb-cross path {
        stroke: #fff;
        stroke-linecap: round;
        stroke-width: 4;
    }
    .pb-container.disabled .pb-button {
        cursor: not-allowed;
        background: #E1E6EB;
    }
    .pb-container.loading .pb-button {
        width: 100%;
        cursor: wait;
        background-color: transparent;
        padding: 0;
    }
    .pb-container.loading .pb-button span {
        transition: all 0.15s;
        opacity: 0;
        display: none;
    }
    .pb-container.loading .pb-button .pb-progress-circle > path {
        transition: opacity 0.15s 0.3s;
        opacity: 1;
    }
    .pb-container.success .pb-button {
        border-color: #A0D468;
        background-color: #A0D468;
    }
    .pb-container.success .pb-button span {
        transition: all 0.15s;
        opacity: 0;
        display: none;
    }
    .pb-container.success .pb-button .pb-checkmark > path {
        opacity: 1;
    }
    .pb-container.error .pb-button {
        border-color: #ED5565;
        background-color: #ED5565;
    }
    .pb-container.error .pb-button span {
        transition: all 0.15s;
        opacity: 0;
        display: none;
    }
    .pb-container.error .pb-button .pb-cross > path {
        opacity: 1;
    }
    @keyframes spin {
        from {
            transform: translate(-50%, -50%) rotate(0deg);
            transform-origin: center center;
        }
        to {
            transform: translate(-50%, -50%) rotate(360deg);
            transform-origin: center center;
        }
    }
`;

const ProgressButton = createReactClass({
    propTypes: {
        children: PropTypes.node.isRequired,
        classNamespace: PropTypes.string,
        className: PropTypes.string,
        controlled: PropTypes.bool,
        durationError: PropTypes.number,
        durationSuccess: PropTypes.number,
        form: PropTypes.string,
        onClick: PropTypes.func,
        onError: PropTypes.func,
        onSuccess: PropTypes.func,
        state: PropTypes.oneOf(Object.keys(STATE).map(k => STATE[k])),
        type: PropTypes.string,
        shouldAllowClickOnLoading: PropTypes.bool
    },

    getDefaultProps () {
        return {
            classNamespace: 'pb-',
            controlled: false,
            durationError: 1200,
            durationSuccess: 500,
            onClick () {},
            onError () {},
            onSuccess () {},
            shouldAllowClickOnLoading: false
        };
    },

    getInitialState () {
        return {
            currentState: this.props.state || STATE.NOTHING
        };
    },

    componentDidUpdate (prevProps) {
        if (prevProps.state !== this.props.state) {
            switch (this.props.state) {
            case STATE.SUCCESS:
                this.success();
                return;
            case STATE.ERROR:
                this.error();
                return;
            case STATE.LOADING:
                this.loading();
                return;
            case STATE.DISABLED:
                this.disable();
                return;
            case STATE.NOTHING:
                this.notLoading();
                return;
            default:
                return;
            }
        }
    },

    componentWillUnmount () {
        clearTimeout(this._timeout);
    },

    render () {
        const {
            className,
            classNamespace,
            children,
            type,
            form,
            durationError, // eslint-disable-line no-unused-vars
            durationSuccess, // eslint-disable-line no-unused-vars
            onClick, // eslint-disable-line no-unused-vars
            onError, // eslint-disable-line no-unused-vars
            onSuccess, // eslint-disable-line no-unused-vars
            state, // eslint-disable-line no-unused-vars
            shouldAllowClickOnLoading, // eslint-disable-line no-unused-vars
            controlled, // eslint-disable-line no-unused-vars
            ...containerProps
        } = this.props;

        containerProps.className = classNamespace + 'container ' + this.state.currentState + ' ' + className;
        containerProps.onClick = this.handleClick;
        return (
            <ProgressButtonStyle>
                <div {...containerProps}>
                    <button disabled={state === STATE.DISABLED} type={type} form={form} className={classNamespace + 'button'}>
                        <span>{children}</span>
                        <svg className={classNamespace + 'progress-circle'} viewBox='0 0 41 41'>
                            <path d='M38,20.5 C38,30.1685093 30.1685093,38 20.5,38' />
                        </svg>
                        <svg className={classNamespace + 'checkmark'} viewBox='0 0 70 70'>
                            <path d='m31.5,46.5l15.3,-23.2' />
                            <path d='m31.5,46.5l-8.5,-7.1' />
                        </svg>
                        <svg className={classNamespace + 'cross'} viewBox='0 0 70 70'>
                            <path d='m35,35l-9.3,-9.3' />
                            <path d='m35,35l9.3,9.3' />
                            <path d='m35,35l-9.3,9.3' />
                            <path d='m35,35l9.3,-9.3' />
                        </svg>
                    </button>
                </div>
            </ProgressButtonStyle>
        );
    },

    handleClick (e) {
        if (this.props.controlled) {
            this.props.onClick(e);
            return true;
        }

        if ((this.props.shouldAllowClickOnLoading ||
                this.state.currentState !== STATE.LOADING) &&
                this.state.currentState !== STATE.DISABLED
        ) {
            this.loading();
            const ret = this.props.onClick(e);
            this.handlePromise(ret);
        } else {
            e.preventDefault();
        }
    },

    handlePromise (promise) {
        if (promise && promise.then && promise.catch) {
            promise
                .then(() => {
                    this.success();
                })
                .catch((err) => {
                    this.error(null, err);
                });
        }
    },

    loading () {
        this.setState({currentState: STATE.LOADING});
    },

    notLoading () {
        this.setState({currentState: STATE.NOTHING});
    },

    enable () {
        this.setState({currentState: STATE.NOTHING});
    },

    disable () {
        this.setState({currentState: STATE.DISABLED});
    },

    success (callback, dontRemove) {
        this.setState({currentState: STATE.SUCCESS});
        this._timeout = setTimeout(() => {
            if (!dontRemove) { this.setState({currentState: STATE.NOTHING}); }
            callback = callback || this.props.onSuccess;
            if (typeof callback === 'function') { callback(); }
        }, this.props.durationSuccess);
    },

    error (callback, err) {
        this.setState({currentState: STATE.ERROR});
        this._timeout = setTimeout(() => {
            this.setState({currentState: STATE.NOTHING});
            callback = callback || this.props.onError;
            if (typeof callback === 'function') { callback(err); }
        }, this.props.durationError);
    }
});

export default ProgressButton;
