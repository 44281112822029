import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TooltipWrapper } from 'react-lightweight-tooltip';

const styles = {
    content: {
        color: '#EF8632',
        backgroundColor: 'transparent',
        padding: 0,
        fontSize: '14px',
        fontWeight: '400',
    },
    tooltip: {
        borderRadius: '5px',
        padding: '10px',
        minWidth: '300px',
        backgroundColor: '#FFF',
        textAlign: 'left',
        border: 'solid #EF8632 2px',
    },
    arrow: {
        color: '#EF8632',
        backgroundColor: '#EF8632',
    }
};


export default class Tooltip extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
            PropTypes.object,
        ]),
    };

    render() {

        const { content, children, ...props } = this.props;

        return (
            <TooltipWrapper
                content={content}
                styles={styles}
                {...props}
            >
                {children}
            </TooltipWrapper>
        );

    }

}
