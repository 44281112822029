import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import notifications from './notifications';
import transactions from './transactions';
import portfolio from './portfolio';
import referrals from './referrals';
import opendata from './opendata';
import currency from './currency';
import errors from './errors';
import market from './market';
import auth from './auth';
import user from './user';
import ws from './ws';

export default history => combineReducers({
    notifications,
    transactions,
    portfolio,
    referrals,
    opendata,
    currency,
    errors,
    market,
    auth,
    user,
    ws,
    router: connectRouter(history),
});
