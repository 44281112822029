import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import PortfolioAddressBook from './PortfolioAddressBook';
import {getCoinNetworks, getNetworkOptions, selectWithdrawalAddresses} from '../../../../selectors';
import {
    setWithdrawAddress,
    deleteWithdrawAddress,
    clearWithdrawalAddresses,
    getWithdrawalAddresses
} from '../../../../actions/portfolio';

function mapStateToProps(state, { coin }) {
    return {
        networkOptions: getNetworkOptions(state, coin),
        networkConfigs: getCoinNetworks(state, coin),
        addresses: selectWithdrawalAddresses(state, coin),
        refreshWithdrawalAddresses: state.portfolio.refreshWithdrawalAddresses,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setWithdrawAddress,
            deleteWithdrawAddress,
            clearWithdrawalAddresses,
            getWithdrawalAddresses,
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioAddressBook));
