import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function FaqScreen() {

    return (
        <div>
            <FormattedMessage
                id="navigation.faq"
                defaultMessage="FAQ"
            />

        </div>
    );

}
