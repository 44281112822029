import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TradeOrders from './TradeOrders';
import { getTradePair, getCurrentPrice, getOrderBookBuyEntries, getOrderBookSellEntries } from '../../../../selectors';
import { submitCancelOrders, setPriceField, clearOrderBookFeed, startOrderBookFeed, stopOrderBookFeed } from '../../../../actions/market';

function mapStateToProps(state, props) {
    return {
        buyOrders: getOrderBookBuyEntries(state),
        sellOrders: getOrderBookSellEntries(state),
        currentPrice: getCurrentPrice(state),
        tradePair: getTradePair(state, props),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitCancelOrders,
            setPriceField,
            clearOrderBookFeed,
            startOrderBookFeed,
            stopOrderBookFeed,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradeOrders);
