import { push } from 'connected-react-router';
import { clearNotifications } from '../actions/notifications';
import { clearErrors } from '../actions/errors';
import { loginLoading } from '../actions/auth';

// import { login } from '../actions/auth';
// import config from 'config';
// const isAuthEnabled = config.get('features.authorization');

const switchcase = cases => defaultCase => key =>
    cases.hasOwnProperty(key) ? cases[key] : defaultCase;

const executeIfFunction = f =>
    f instanceof Function ? f() : f;

const switchcaseF = cases => defaultCase => key =>
    executeIfFunction(switchcase(cases)(defaultCase)(key));


export const authMiddleware = store => next => action => {

    return switchcaseF({
        'SUCCESS_LOGIN': () => {
            const state = store.getState();
            const url = state.router.location.pathname;

            sessionStorage.setItem('token', action.payload.token);
            store.dispatch(clearErrors());

            // this gives us a default landing page at login
            if (!url.includes('account') && !url.includes('quicktrade') && !url.includes('market')) {
                store.dispatch(push('/account/portfolio'));
            }
        },
        'LOGOUT_REQUESTED': () => {
            const state = store.getState();
            if (state.auth.isAuthenticated) {
                // wait 2s for server response, then force client logout...
                setTimeout(() => {
                    const state = store.getState();
                    if (state.auth.isAuthenticated) {
                        store.dispatch(push('/signedout'));
                    }
                }, 2000);
            }
        },
        'SESSION_IP_MISMATCH': () => {
            store.dispatch(loginLoading(false));
            sessionStorage.removeItem('token');
            store.dispatch(push('/ipchanged'));
        },
        'SUCCESS_LOGOUT': () => {
            const state = store.getState();
            if (state.auth.isAuthenticated) {
                store.dispatch(push('/signedout'));
            } else {
                store.dispatch(loginLoading(false));
                // sessionStorage.removeItem('token');
            }
        },
        'LOGIN_NOT_FINISHED': () => {
            const state = store.getState();
            const url = state.router.location.pathname;

            if (url !== '/login-2fa') {
                store.dispatch(push('/login-2fa'));
            }
        },
        'TRANSACTION_INITIATED': () => {
            store.dispatch(push('/confirm'));
            store.dispatch(clearNotifications());
        },
        'DEVICE_NOTIFICATION': () => {
            const state = store.getState();
            const url = state.router.location.pathname;

            if (url === '/device-confirm') {
                store.dispatch(push('/login'));
            }
        },
        'UNAUTHORIZED_DEVICE': () => {
            sessionStorage.removeItem('token');
            store.dispatch(push('/device-confirm'));
        },
        '@@router/LOCATION_CHANGE': () => {
            /* I believe this was here to prevent logging in under a different account
                   within the same websocket connection but now the backend resets the
                   session if you log in again so it is now backend safe to take a new
                   login attempt...

            const { pathname } = action.payload.location;

            if (pathname.includes('/login')) {
                const token = sessionStorage.getItem('token');
                if (token && isAuthEnabled) {
                    store.dispatch(loginLoading(true));
                    store.dispatch(login({ token }));
                } else {
                    store.dispatch(loginLoading(false));
                }
            }
            */
        },
    })(next(action))(action.type);

};
