import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Component as StandardPage } from '../../components/StandardPage';
import { Component as WalletStatusTable } from './components/WalletStatusTable';

import { mobile } from '../../styles';


const Title = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin: 0.5em 20px;

  ${mobile`
    display: none;
  `}
`;

const Description = styled.div`
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 20px 30px 20px;
`;

const InternalLink = styled(Link)`
  color: #ef8632;
  text-decoration: none;
`;


export default class WalletStatusScreen extends React.Component {

    static propTypes = {
        getWalletStatusInfo: PropTypes.func,

        walletStatus: PropTypes.array,

        location: PropTypes.object,
    };

    componentDidMount() {
        this.props.getWalletStatusInfo();
        document.title = 'Wallet (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.location.pathname + this.props.location.search + this.props.location.hash,
            page_search: this.props.location.search,
            page_hash: this.props.location.hash,
        });
    }

    render() {

        const { walletStatus } = this.props;

        return (
            <StandardPage>
                <Title>
                    <FormattedMessage
                        id="navigation.walletStatusTitle"
                        defaultMessage="Radical Transparency: Our Current Wallet Status"
                    />
                </Title>
                <Description>
                    <FormattedMessage
                        id="navigation.proofOfReservesDescription"
                        defaultMessage="For every asset we hold, you can view our real-time wallet tracking. This page is a key component of the BlocPalX Proof of Reserves system, which is explained in detail here: {githubLink}"
                        values={{
                            githubLink: <InternalLink to='/proofOfReserves'><FormattedMessage
                                id="navigation.proofOfReserves"
                                defaultMessage="Proof of Reserves"/></InternalLink>,
                        }}
                    />
                </Description>
                { walletStatus ? walletStatus.filter(assetArray => assetArray.platformBalance > 0).map(assetArray =>
                    <WalletStatusTable key={assetArray.ticker} assetArray={assetArray}/>
                ) : null}
            </StandardPage>
        );
    }
}
