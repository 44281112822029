
/*
 * INTERNAL REQUESTS
 */

export const dismissNotification = id => ({
    type: 'DISMISS_NOTIFICATION',
    payload: id
});

export const addNotification = payload => ({
    type: 'ADD_NOTIFICATION',
    payload,
});

export const clearNotifications = () => ({
    type: 'CLEAR_NOTIFICATION',
});

/*
 * REST REQUESTS
 */

/*
 * WEBSOCKET REQUESTS
 */
