import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import SignUpScreen from './SignUpScreen';
import {regionServiceStatus, registerEmail, updateAuthInfo} from '../../actions/auth';
import {requestCountriesList, requestRegionsList} from '../../actions/opendata';
import {getSelectableCountries, getSelectableRegions} from '../../selectors';

function mapStateToProps(state) {
    return {
        countries: getSelectableCountries(state),
        regions: getSelectableRegions(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            registerEmail,
            updateAuthInfo,
            requestCountriesList,
            requestRegionsList,
            regionServiceStatus,
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpScreen));
