import { handleActions } from 'redux-actions';
import { NotificationManager } from 'react-notifications';

import { guid } from '../../utils';


const defaultState = [];


export default handleActions(
    {
        ['ADD_NOTIFICATION']: (state, action) => {
            // console.log('ADD_NOTIFICATION');
            // console.log(action);
            if (action.payload.text === 'User session ended') {
                // suppress message from server...
                return state;
            } else {
                if (action.payload.type && action.payload.type === 'info') {
                    NotificationManager.info(action.payload.text);
                } else {
                    const errorMessage = action.payload.text ? action.payload.text : 'An Unknown Error Has Occurred';
                    NotificationManager.error(errorMessage, undefined, 15000);
                }
                return [
                    ...state.filter(n => n.message !== action.payload.message),
                    {
                        ...action.payload,
                        id: guid()
                    }
                ];
            }
        },
        ['DISMISS_NOTIFICATION']: (state, action) => state.filter(notification => notification.id !== action.payload),
        ['CLEAR_NOTIFICATION']: () => [],
        ['JSON_PARSE_ERROR']: (state, action) => {
            NotificationManager.error(action.payload.message, 'Error');
            return state;
        },
    },
    defaultState
);
