import React from 'react';
import styled from 'styled-components';
import nick01 from '../images/nick_01.webp';
import orangeArrow from '../images/orangeArrow.svg';




export function RightArrow() {
    const Img = styled.img`
        transform: translate(0%, -50%);
        @media (max-width: 1100px) {
            transform:translate(0%, -50%) scale(0.8);
        }    
        @media (max-width: 800px) {
            transform:translate(0%, -50%) scale(0.8) rotate(90deg);
        }    
    `;

    const Div = styled.div`
        position: absolute;
        top: 50%;
        left: -20px;
        @media (max-width: 800px) {
            top:0;
            left: 50%;
        }    
    `;

    return (
        <Div >
            <Img src={'/' + orangeArrow} />
        </Div>
    );
}

export function BottomArrow() {
    const Img = styled.img`
        transform:translate(-50%, 0%) rotate(90deg);
        @media (max-width: 1100px) {
            transform:translate(-50%, 0%) scale(0.8) rotate(90deg);
     }    
    `;
    return (
        <Img src={'/' + orangeArrow} />
    );
}

export function LeftArrow() {
    const Div = styled.div`
        position: absolute;
        right: -20px;
        top:50%;
       
        @media (max-width: 800px) {
            top:0;
            left:50%;
           
        }    
    `;

    const Img = styled.img`
        transform:translate(0%, -50%) rotate(0deg);
        @media (max-width: 1100px) {
            transform:translate(0%, -50%) scale(0.8);
     }    
        @media (max-width: 800px) {
            transform:translate(0%, -50%) rotate(90deg) scale(0.8);
           
        }    
    `;
    return (
        <Div >
            <Img src={'/' + orangeArrow} />
        </Div>
    );
}

export const HeroSlogan = styled.h1`
    color: #FFF;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    font-family: 'Montserrat', sans-serif;
    max-width: 48.813rem;
    margin: 0px;
    margin-bottom: 26px;
    @media (max-width: 600px) {
        font-size: 32px;
        line-height:  45px;
     }
    @media (max-width: 400px) {
        font-size: 24px;
        line-height:  32px;
        margin:10px 0;
     }
  
`;

export const HeroSection = styled.section`
    width: 100%;
    background-color: #021747;
    display: flex;
    justify-content: center;
    
`;

export const Container = styled.div`
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
`;


export const HeroTagLine = styled.h2`
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    max-width: 709px;
    margin: 0px;
    @media (max-width: 600px) {
        font-size: 20px;
        line-height:  32px;
     }
    @media (max-width: 400px) {
        font-size: 14px;
        line-height:  20px;
     }
   
`;

export const HeroContent = styled.div`
    padding: 100px 0px;
    margin-left: 6.75rem;
    @media (max-width: 1280px) {
        margin-left: 3rem;
        max-width: 600px;
     }
     @media (max-width: 600px) {
        margin: 40px 20px;
        
        padding: 0
     }
`;

export const HeroContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    
    background-image:linear-gradient(to right,#021747, #021747 45%,transparent 60%), url(${nick01});
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    @media (max-width: 1280px) {
        background-position-x: right, 25rem ;
    }
     @media (max-width: 990px) {
        background-position-x: right, 15rem;
        background-size: cover;
        
     }
     @media (max-width: 800px) {
       background: none;
        
     }
`;