import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { mobile } from '../../../../styles';


const Container = styled.div`
  padding: 40px 40px 10px 40px;

  ${mobile`
    padding: 10px 10px 5px 10px;
  `};
`;

const WalletActions = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 10px;
  }

  ${mobile`
    // flex-wrap: wrap;
    margin: 10px 0 10px 0;
    flex-direction: row;
    align-items: center;
  `};

`;

const WalletActionsDesktop = styled(WalletActions)`
  ${mobile`
    display: none;
  `};
`;

const WalletActionsMobile = styled(WalletActions)`
  display: none;
  ${mobile`
    display: flex;
  `};
`;

const BlueButton = styled(Link)`
    width: 150px;
    margin-right: 16px;
    // background: linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%);
    background: rgba(2, 23, 71, 1);
    color: #FFFFFF;
    text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
    padding: 16px 20px;
    border-radius: 5px;
    -webkit-text-decoration: none;
    text-decoration: none;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    -webkit-letter-spacing: 1.05px;
    -moz-letter-spacing: 1.05px;
    -ms-letter-spacing: 1.05px;
    letter-spacing: 1.05px;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;

    ${mobile`
      margin: 5px 5px 5px 5px;
      padding: 5px;
      width: 30%;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      // width: 100%;
    `};
`;


export default class ProjectPage extends React.Component {

    static propTypes = {
        fiatCurrency: PropTypes.string,
        ticker: PropTypes.string,
        coinConfig: PropTypes.object,
        history: PropTypes.object,
    };

    static defaultProps = {
        wallet: null
    };

    render() {

        const { coinConfig } = this.props;

        // console.log(coinConfig);

        return coinConfig && coinConfig.name ? (
            <Container>
                <WalletActionsMobile>
                    {(coinConfig) && (
                        <React.Fragment>
                            <BlueButton to={`/projects/${coinConfig.name}/about`}>
                                <FormattedMessage
                                    id="project.about"
                                    defaultMessage="About"
                                />
                            </BlueButton>
                            <BlueButton to={`/projects/${coinConfig.name}/news`}>
                                <FormattedMessage
                                    id="project.news"
                                    defaultMessage="News"
                                />
                            </BlueButton>
                        </React.Fragment>
                    )}
                </WalletActionsMobile>
                <WalletActionsDesktop>
                    {(coinConfig) && (
                        <React.Fragment>
                            <BlueButton to={`/projects/${coinConfig.name}/about`}>
                                <FormattedMessage
                                    id="project.about"
                                    defaultMessage="About"
                                />
                            </BlueButton>
                            <BlueButton to={`/projects/${coinConfig.name}/news`}>
                                <FormattedMessage
                                    id="project.news"
                                    defaultMessage="News"
                                />
                            </BlueButton>
                        </React.Fragment>
                    )}
                </WalletActionsDesktop>
                <ul>
                    {Object.entries(coinConfig).map(([key,value]) => (
                        <li key={key}>{key} : {value.toString()}</li>
                    ))}
                </ul>
            </Container>
        ) : null;
    }
}
