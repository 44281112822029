import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PortfolioPermWithdrawal from './PortfolioPermWithdrawal';
import {getAuthenticated, getCurrentWallet} from '../../../../selectors';
import { getPortfolioInfo, setPermWithdraw } from '../../../../actions/portfolio';

function mapStateToProps(state, props) {
    return {
        wallet: getCurrentWallet(state, props.coin),
        refreshPortfolioInfo: state.portfolio.refreshPortfolioInfo,
        user: state.user,
        isAuthenticated: getAuthenticated(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPortfolioInfo,
            setPermWithdraw,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioPermWithdrawal);
