import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Link } from 'react-router-dom';

import { Component as CurrencyImage } from '../../../../components/CurrencyImage';
import BlocPalToolTip from '../../../../components/BlocPalToolTip/BlocPalToolTip';

import {DisplayFiat, displayFormat} from '../../../../utils';
import { mobile } from '../../../../styles';

import {
    Table,
    TableSortHeader,
    HeaderRowNoMobile,
    TableRow,
    HeaderCellLeft,
    HeaderCellLeftNoMobile,
    CellLeft,
    CellLeftNoMobile,
    LastCellNoMobile,
    SortButton,
    LastCellNoDesktop, CellNoMobile,
} from '../../../../commonStyles/TableStyles.js';


const Wrapper = styled.div`
  flex: 1 auto;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  position: relative;

  padding: 10px;

  ${mobile`
    padding: 5px 0px 0px 0px;
  `};
`;

const StyledPair = styled.div`
  // width: 100px;
  display: flex;
  // border: 2px solid #eee;
  // border-radius: 5px;
  // border-bottom: 1px solid #eee;
  // position: relative;
  padding: 10px 10px 10px 5px;
  // padding: 15px 0px 15px 0px;
  // white-space: pre-wrap;
  // padding: 20px 20px 20px 20px;
  text-decoration: none;
  // margin: 10px 0px;
  vertical-align: middle;

  color: #232323;
  justify-content: space-between;

  &.active {
    background-color: #ef863214;
    border-color: #ef863214;
  }

  >span {
    // display: flex;
    // vertical-align: middle;
    // align-items: center;
    // height: 24px;
    margin-right: auto;
    img {
      margin-right: 10px;
    }
  }

  ${mobile`
    padding: 10px 5px 10px 5px;
    // width: 160px;
  `};
`;

const Bold = styled.span`
    font-weight: bold;

    ${mobile`
      text-align: center;
    `};
`;

const SmallerPrice = styled.span`
    font-size: 12px;
`;

const CurrencyIcon = styled(CurrencyImage)`
  margin: auto 15px auto 4px;

  width: 40px;
  height: 40px;

  ${mobile`
      margin: auto 10px auto 0px;
      width: 30px;
      height: 30px;
  `};
`;

const ClickSpan = styled.span`
  color: ${props => props.theme.colors.primary2};
  cursor: pointer;
`;

const SideHeader = styled.div`
  padding: 0 16px;
  // float: right;
  // margin-left: auto;

  ${mobile`
    padding: 5px 0 5px 5px;
  `};

  >span {
	color: #9B9B9B;
	font-size: 14px;
	line-height: 21px;
  }

  >p {
    margin: 0;
	color: #4A4A4A;
	font-size: 36px;
	line-height: 36px;
  }
`;

const SideHeaderSmall = styled.span`
    font-size: 24px;
`;


const PublicCode = styled.div`
    float: right;
    margin-top: 4px;
    color: #9B9B9B;
	font-size: 14px;
	line-height: 21px;

  ${mobile`
  
    display: none;
  `};
`;

/*
import DropdownMenu from 'react-dd-menu';
const StyledMenu = styled(DropdownMenu)`
  display: inline-block;
  margin-left: 5px;
  position: relative;

  >span {
    cursor: pointer;
    color: #ef8632;
    text-transform: capitalize;
  }

  .dd-menu-items {
    position: absolute;
    width: 170px;
    background-color:#fff;
    box-shadow: 0 0 2px #ddd;

    ul {
      margin: 0;
      padding: 0;

      span {
        display: block;
        text-transform: capitalize;
        cursor: pointer;
        padding: 3px 15px;

        &:hover {
          background-color:#eee;
        }
      }
    }
  }
`;
*/

const StyledLinkButton = styled(Link)`
    width: 150px;
    margin-right: 5px;
    // background: linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%);
    padding: 12px 16px;
    border-radius: 5px;
    text-decoration: none;
    border: 0;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    letter-spacing: 1.05px;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
`;

const BlueButton = styled(StyledLinkButton)`
  background: rgba(2, 23, 71, 1);
  color: #FFFFFF;
`;

const WhiteButton = styled(StyledLinkButton)`
  outline: 2px solid rgba(2, 23, 71, 1);
  outline-offset: -2px;
  
  color: rgba(2, 23, 71, 1);
  background: #FFFFFF;
`;


export default class PortfolioAssetPage extends React.Component {

    static propTypes = {
        coinsConfig: PropTypes.array,
        wallets: PropTypes.arrayOf(PropTypes.shape({
            coin: PropTypes.string,
            avail: PropTypes.number,
            fiat: PropTypes.number,
            lock: PropTypes.number
        })),
        approxTotal: PropTypes.string,
        fiatCurrency: PropTypes.string,
        usdToFiatRate: PropTypes.number,
        userPublicCode: PropTypes.string,

        history: PropTypes.object,
        intl: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            sortDirection: 'DESC',
            sortColumn: 'VALUE',
            isPortfolioRequested: false,
            wallets: [],
        };
    }

    componentDidMount() {
        this.sortWallets();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.wallets !== prevProps.wallets ||
            this.state.sortDirection !== prevState.sortDirection ||
            this.state.sortColumn !== prevState.sortColumn) {

            this.sortWallets();
        }
    }

    sortWallets() {
        const { wallets } = this.props;
        const { sortDirection, sortColumn } = this.state;

        if (sortColumn === 'NAME') {
            this.setState({
                wallets: [...wallets].sort(
                    (prev, next) => sortDirection === 'ASC' ?
                        prev.coin > next.coin :
                        next.coin > prev.coin
                ),
            });
        } else {
            this.setState({
                wallets: [...wallets].sort(
                    (prev, next) => sortDirection === 'ASC' ?
                        Number(prev.fiat) - Number(next.fiat) :
                        Number(next.fiat) - Number(prev.fiat)
                ),
            });
        }
    }

    onChangeSortDirection = (column) => {
        this.setState({
            sortColumn: column,
            sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        });
    };


    render() {
        const { intl, coinsConfig, userPublicCode, approxTotal, history, fiatCurrency, usdToFiatRate } = this.props;
        const { wallets, sortDirection, sortColumn } = this.state;

        return (
            <Wrapper>
                <SideHeader>
                    <SideHeader>
                        <p>
                            {getSymbolFromCurrency(fiatCurrency)}{intl.formatNumber(approxTotal, {minimumFractionDigits: 2, maximumFractionDigits: 2})} <SideHeaderSmall>{fiatCurrency}</SideHeaderSmall>
                        </p>
                        <FormattedMessage
                            id="portfolio.approxAccountValue"
                            defaultMessage="Approximate account value"
                        />
                    </SideHeader>
                    <PublicCode>
                        <FormattedMessage
                            id="portfolio.publicCode"
                            defaultMessage="Public Account Code: {publicCode}"
                            values={{
                                publicCode: userPublicCode,
                            }}
                        />
                        <BlocPalToolTip tooltipId='portfolio.publicCode_tooltip'>
                            <FormattedMessage
                                id="portfolio.publicCode_tooltip"
                                defaultMessage="Used for our proof-of-reserves system."
                            />
                        </BlocPalToolTip>
                    </PublicCode>
                </SideHeader>

                <Table>
                    <TableSortHeader>
                        <CellLeft>
                            <SortButton>
                                <FormattedMessage
                                    id="portfolio.sort"
                                    defaultMessage="Sort: {actionLabel}"
                                    values={{
                                        actionLabel: (
                                            <FormattedMessage
                                                id="portfolio.sortName"
                                                defaultMessage="Name"
                                            >
                                                {message => <ClickSpan onClick={() => { this.onChangeSortDirection('NAME'); }}>{message} {sortColumn === 'NAME' && (sortDirection === 'ASC' ? '↑' : '↓')}</ClickSpan>}
                                            </FormattedMessage>
                                        )
                                    }}
                                />
                            </SortButton>
                        </CellLeft>
                        <CellLeftNoMobile>
                            <SortButton>
                                <FormattedMessage
                                    id="portfolio.sort"
                                    defaultMessage="Sort: {actionLabel}"
                                    values={{
                                        actionLabel: (
                                            <FormattedMessage
                                                id="portfolio.sortValue"
                                                defaultMessage="Value"
                                            >
                                                {message => <ClickSpan onClick={() => { this.onChangeSortDirection('VALUE'); }}>{message} {sortColumn === 'VALUE' && (sortDirection === 'ASC' ? '↑' : '↓')}</ClickSpan>}
                                            </FormattedMessage>
                                        )
                                    }}
                                />
                            </SortButton>
                        </CellLeftNoMobile>
                        <LastCellNoDesktop>
                            <SortButton>
                                <FormattedMessage
                                    id="portfolio.sort"
                                    defaultMessage="Sort: {actionLabel}"
                                    values={{
                                        actionLabel: (
                                            <FormattedMessage
                                                id="portfolio.sortValue"
                                                defaultMessage="Value"
                                            >
                                                {message => <ClickSpan onClick={() => { this.onChangeSortDirection('VALUE'); }}>{message} {sortColumn === 'VALUE' && (sortDirection === 'ASC' ? '↑' : '↓')}</ClickSpan>}
                                            </FormattedMessage>
                                        )
                                    }}
                                />
                            </SortButton>
                        </LastCellNoDesktop>
                        <CellLeftNoMobile/>
                        <CellLeftNoMobile/>
                        <CellLeftNoMobile/>
                    </TableSortHeader>

                    <HeaderRowNoMobile>
                        <HeaderCellLeft>
                            <FormattedMessage
                                id="portfolio.activeAsset"
                                defaultMessage="Active Assets"
                            />
                        </HeaderCellLeft>
                        <HeaderCellLeft>
                            <FormattedMessage
                                id="portfolio.balance"
                                defaultMessage="Balance"
                            />
                        </HeaderCellLeft>
                        <HeaderCellLeft>
                            <FormattedMessage
                                id="portfolio.value"
                                defaultMessage="Value"
                            />
                        </HeaderCellLeft>
                        <HeaderCellLeftNoMobile>
                            <FormattedMessage
                                id="portfolio.maker"
                                defaultMessage="Maker Fee"
                            />
                        </HeaderCellLeftNoMobile>
                        <HeaderCellLeftNoMobile>
                            <FormattedMessage
                                id="portfolio.taker"
                                defaultMessage="Taker Fee"
                            />
                        </HeaderCellLeftNoMobile>
                        <HeaderCellLeftNoMobile>
                            <FormattedMessage
                                id="portfolio.currentPrice"
                                defaultMessage="Current Price"
                            />
                        </HeaderCellLeftNoMobile>
                        <HeaderCellLeftNoMobile/>
                    </HeaderRowNoMobile>

                    {wallets.map((wallet, i) => (
                        <React.Fragment key={'active-' + wallet.coin}>
                            {(coinsConfig && coinsConfig.length > 0 && coinsConfig.find(config => config.name && config.name === wallet.coin)) && (
                                <TableRow key={i}>

                                    <CellLeft onClick={() => { history.push('/account/portfolio/' + wallet.coin); }}>
                                        <StyledPair
                                            key={i}
                                            to={`/account/portfolio/${wallet.coin}`}
                                            exact
                                        >
                                            <CurrencyIcon currency={wallet.coin} />
                                            <span>
                                                <Bold>
                                                    {wallet.coin}<br/>
                                                </Bold>
                                                {coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).label}
                                            </span>
                                        </StyledPair>
                                    </CellLeft>

                                    <CellLeft onClick={() => { history.push('/account/portfolio/' + wallet.coin); }}>
                                        <Bold>
                                            {displayFormat(wallet.total, 8)}
                                        </Bold>
                                    </CellLeft>

                                    <CellLeft onClick={() => { history.push('/account/portfolio/' + wallet.coin); }}>
                                        {fiatCurrency !== 'USD' ? (
                                            <React.Fragment>
                                                <DisplayFiat currency={fiatCurrency} value={coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice * (usdToFiatRate !== 0 ? usdToFiatRate : 0) * (+wallet.total)}/>
                                                <br/>
                                                <SmallerPrice>
                                                    <DisplayFiat currency='USD' value={coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice * (+wallet.total)}/>
                                                </SmallerPrice>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <DisplayFiat currency={fiatCurrency} value={coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice * (+wallet.total)}/>
                                            </React.Fragment>
                                        )}
                                    </CellLeft>

                                    <CellLeftNoMobile onClick={() => { history.push('/account/portfolio/' + wallet.coin); }}>
                                        {(Number(coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).makerFee * 100) > 0) ? (coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).makerFee * 100) + '%' : (
                                            <Bold>
                                                Free
                                            </Bold>
                                        )}
                                    </CellLeftNoMobile>
                                    <CellLeftNoMobile onClick={() => { history.push('/account/portfolio/' + wallet.coin); }}>
                                        {(coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).takerFee) ? (coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).takerFee * 100) + '%' : '- -'}
                                    </CellLeftNoMobile>

                                    <CellLeftNoMobile onClick={() => { history.push('/account/portfolio/' + wallet.coin); }}>
                                        {fiatCurrency !== 'USD' ? (
                                            <React.Fragment>
                                                <DisplayFiat currency={fiatCurrency} value={coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice * (usdToFiatRate !== 0 ? usdToFiatRate : 0)}/>
                                                <br/>
                                                <SmallerPrice>
                                                    <DisplayFiat currency='USD' value={coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice}/>
                                                </SmallerPrice>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <DisplayFiat currency={fiatCurrency} value={coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice}/>
                                            </React.Fragment>
                                        )}
                                    </CellLeftNoMobile>

                                    <LastCellNoMobile>
                                        <BlueButton to={`/account/portfolio/${wallet.coin}/deposit`}>
                                            <FormattedMessage
                                                id="wallet.deposit"
                                                defaultMessage="Deposit"
                                            />
                                        </BlueButton>
                                        <BlueButton to={`/account/portfolio/${wallet.coin}/withdraw`}>
                                            <FormattedMessage
                                                id="wallet.withdraw"
                                                defaultMessage="Withdraw"
                                            />
                                        </BlueButton>
                                        <WhiteButton to={`/account/portfolio/${wallet.coin}`}>
                                            <FormattedMessage
                                                id="wallet.more"
                                                defaultMessage="..."
                                            />
                                        </WhiteButton>
                                    </LastCellNoMobile>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}


                    <br/>
                    <HeaderRowNoMobile>
                        <HeaderCellLeft>
                            <FormattedMessage
                                id="portfolio.inactiveAsset"
                                defaultMessage="Inactive Assets"
                            />
                        </HeaderCellLeft>
                        <HeaderCellLeft>
                            <FormattedMessage
                                id="portfolio.balance"
                                defaultMessage="Balance"
                            />
                        </HeaderCellLeft>
                        <HeaderCellLeft>
                            <FormattedMessage
                                id="portfolio.value"
                                defaultMessage="Value"
                            />
                        </HeaderCellLeft>
                        <HeaderCellLeftNoMobile>
                            <FormattedMessage
                                id="portfolio.maker"
                                defaultMessage="Maker Fee"
                            />
                        </HeaderCellLeftNoMobile>
                        <HeaderCellLeftNoMobile>
                            <FormattedMessage
                                id="portfolio.taker"
                                defaultMessage="Taker Fee"
                            />
                        </HeaderCellLeftNoMobile>
                        <LastCellNoMobile>
                            <FormattedMessage
                                id="portfolio.currentPrice"
                                defaultMessage="Current Price"
                            />
                        </LastCellNoMobile>
                    </HeaderRowNoMobile>


                    {wallets.map((wallet, i) => (
                        <React.Fragment key={'inactive-' + wallet.coin}>
                            {(!coinsConfig.find(coinConfig => coinConfig.name === wallet.coin)) && (
                                <TableRow key={i} className='inactivePair' onClick={() => { history.push('/account/portfolio/' + wallet.coin); }}>

                                    <CellLeft>
                                        <StyledPair
                                            key={i}
                                            to={`/account/portfolio/${wallet.coin}`}
                                            exact
                                        >
                                            <CurrencyIcon currency={wallet.coin} />
                                            <span>
                                                <Bold>
                                                    {wallet.coin}<br/>
                                                </Bold>
                                                (inactive)
                                            </span>
                                        </StyledPair>
                                    </CellLeft>

                                    <CellLeft>
                                        <Bold>
                                            {displayFormat(wallet.total, 8)}
                                        </Bold>
                                    </CellLeft>

                                    <CellLeft>
                                        - -
                                    </CellLeft>

                                    <CellNoMobile>
                                        - -
                                    </CellNoMobile>

                                    <CellNoMobile>
                                        - -
                                    </CellNoMobile>

                                    <CellNoMobile>
                                        - -
                                    </CellNoMobile>

                                    <LastCellNoMobile>
                                        - -
                                    </LastCellNoMobile>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}

                </Table>
            </Wrapper>
        );
    }
}
