import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as RouteButton } from '../RouteButton';
import { ColWrapper, RowWrapper } from '../Layout';
import { mobile } from '../../styles';
import config from 'config';

import mailIcon from '../../images/ic-mail-big.svg';
import loadIcon from '../../images/ic-refresh-md.svg';


const isAuthEnabled = config.get('features.authorization');

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;
`;

const ConfirmationBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

const Title = styled.p`
	color: #4A4A4A;
	font-size: 24px;
	font-weight: 300;
	line-height: 36px;
	text-align: center;
	margin: 0;
	margin-top: 15px;
	margin-bottom: 30px;
`;

const MailIcon = styled.img`
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`;

const StartTradeButton = styled(RouteButton).attrs({
    bsStyle: 'blue'
})`

    border-radius: 5px;
    text-align: center;
    display: block;

    a {
        border-radius: 5px;
        display: inline-flex;
        padding: 18px 35px;
        width: 320px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }
`;


const Line = styled.hr`
  margin: 50px -55px;
  border: 1px solid #979797;
  border-top: 0;

  ${mobile`
    border: 0;
  `}
`;

const RegistrationAd = styled(RowWrapper)`
    display: flex;
    flex-direction: row;
    padding-left: 40px;
    // margin-right: -20px;

    ${mobile`
        margin: 0;
        padding: 0;
    `}
`;

const Description = styled(ColWrapper)`
  	color: #727072;
  	font-size: 14px;
    line-height: 20px;
    opacity: 0.9;
`;

const SignUpDescription = styled(Description)`
  	opacity: 0.6;
    width: 250px;
  	color: #4A4A4A;
  	font-size: 12px;
  	line-height: 15px;
`;


export default class ConfirmPanel extends React.Component {

    render() {


        return (
            <Container>
                <ConfirmationBody>
                    <MailIcon src={mailIcon}/>
                    <Title>
                        <FormattedMessage
                            id="oct.check_your_inbox"
                            defaultMessage="An email confirmation has been sent. Please check your email to confirm and continue your trade."
                        />
                    </Title>
                    <StartTradeButton to="/quicktrade">
                        <img src={'/' + loadIcon} alt=""/>
                        <FormattedMessage
                            id="oct.new_quicktrade"
                            defaultMessage="Start another trade"
                        />
                    </StartTradeButton>
                    <Line/>
                    { isAuthEnabled && (
                        <RegistrationAd>
                            <RouteButton to="/signup" title="Sign Up">
                                <FormattedMessage
                                    id="auth.signup.title"
                                    defaultMessage="Sign Up"
                                />
                            </RouteButton>
                            <SignUpDescription>
                                <FormattedMessage
                                    id="oct.auth_disclaimer"
                                    defaultMessage="Trade instantly, track all your trades, and get better exchange rates."
                                />
                            </SignUpDescription>
                        </RegistrationAd>
                    ) }

                </ConfirmationBody>
            </Container>
        );

    }

}
