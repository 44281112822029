import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Title } from '../../../../commonStyles/AccountProfileStyles';
import {Component as Checkbox} from '../../../../components/Checkbox';

const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const NotificationsTitle = styled.div`
   margin-top: 50px;
   color: #9B9B9B;
   font-size: 24px;
   line-height: 36px;
`;

const StyledCheckbox = styled.div`
  margin: 20px;
`;


export default class ProfileNotificationSettings extends React.Component {

    static propTypes = {
        extendSession: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            dummyValue: null,
        };
    }

    getLocalStorageItem = (key) => {
        key = sessionStorage.getItem('loginEmail') + '_' + key;
        return localStorage.getItem(key);
    }

    onToggleLocalStorageItem = (key) => {
        key = sessionStorage.getItem('loginEmail') + '_' + key;

        if (localStorage.getItem(key) !== 'disabled') {
            localStorage.setItem(key, 'disabled');
        } else {
            localStorage.setItem(key, '');
        }

        this.props.extendSession();

        // without this, we don't re-render
        this.setState({ dummyValue: key });
    }

    render() {
        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="profile.title_account_notifications"
                        defaultMessage="Account Notifications"
                    />
                </Title>

                <NotificationsTitle>
                    <FormattedMessage
                        id="profile.notifications.webClientTitle"
                        defaultMessage="Web Client Notifications"
                    />
                </NotificationsTitle>

                <StyledCheckbox>
                    <Checkbox
                        disabled={false}
                        label={(
                            <FormattedMessage
                                id="profile.notifications.newOrderNotifications"
                                defaultMessage="New Order Notifications"
                            />
                        )}
                        checked={(this.getLocalStorageItem('newOrderNotifications') !== 'disabled')}
                        onChange={() => { this.onToggleLocalStorageItem('newOrderNotifications'); }}
                    />
                </StyledCheckbox>

                <StyledCheckbox>
                    <Checkbox
                        disabled={false}
                        label={(
                            <FormattedMessage
                                id="profile.notifications.expiredOrderNotifications"
                                defaultMessage="Expired Order Notifications"
                            />
                        )}
                        checked={(this.getLocalStorageItem('expiredOrderNotifications') !== 'disabled')}
                        onChange={() => { this.onToggleLocalStorageItem('expiredOrderNotifications'); }}
                    />
                </StyledCheckbox>

                <StyledCheckbox>
                    <Checkbox
                        disabled={false}
                        label={(
                            <FormattedMessage
                                id="profile.notifications.cancelledOrderNotifications"
                                defaultMessage="Cancelled Order Notifications"
                            />
                        )}
                        checked={(this.getLocalStorageItem('cancelledOrderNotifications') !== 'disabled')}
                        onChange={() => { this.onToggleLocalStorageItem('cancelledOrderNotifications'); }}
                    />
                </StyledCheckbox>

                <StyledCheckbox>
                    <Checkbox
                        disabled={false}
                        label={(
                            <FormattedMessage
                                id="profile.notifications.rejectedOrderNotifications"
                                defaultMessage="Rejected Order Notifications"
                            />
                        )}
                        checked={(this.getLocalStorageItem('rejectedOrderNotifications') !== 'disabled')}
                        onChange={() => { this.onToggleLocalStorageItem('rejectedOrderNotifications'); }}
                    />
                </StyledCheckbox>


                <StyledCheckbox>
                    <Checkbox
                        disabled={false}
                        label={(
                            <FormattedMessage
                                id="profile.notifications.tradeNotifications"
                                defaultMessage="Trade Notifications"
                            />
                        )}
                        checked={(this.getLocalStorageItem('tradeNotifications') !== 'disabled')}
                        onChange={() => { this.onToggleLocalStorageItem('tradeNotifications'); }}
                    />
                </StyledCheckbox>

                <FormattedMessage
                    id="profile.notifications.webClientDescription"
                    defaultMessage="These settings affect the pop-up notifications that appear on the right in your browser. Changes you make here are stored in your browser, not on the server."
                />

            </Container>
        );
    }
}
