import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import dateFns from 'date-fns';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { ColWrapper, RowWrapper } from '../../../../components/Layout';
import { Component as CurrencyStaticPair } from '../../../../components/CurrencyStaticPair';
import { Component as Spinner } from '../../../../components/Spinner';

import { mobile } from '../../../../styles';
import { sigDigits } from '../../../../utils';

import refreshIcon from '../../../../images/ic-refresh-dark-md.svg';
import copyIcon from '../../../../images/ic-copy.svg';


const BlockWrapper = styled.div`
  padding: 0 48px;
  border-bottom: 1px solid rgba(172,181,188,0.25);

  ${mobile`
    padding: 0 24px;
  `};
`;

const HeaderWrapper = styled(BlockWrapper)`
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;

  ${mobile`
    border: none;
  `};
`;

const CopyToClipboardSpan = styled.span`
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    margin-top: 0;
    margin: 0 0 0 5px;
`;

/*
const AddressWrapper = styled(BlockWrapper)`
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;

  ${mobile`
    flex-direction: column;
  `};
`;
*/

const AddressWrapper = styled(BlockWrapper)`
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  border-top: 1px solid rgba(172,181,188,0.25);

  ${mobile`
    flex-direction: column;
  `};
`;

const TransactionStatus = styled.p`
  color: #4A4A4A;
  font-size: 24px;	font-weight: 600;
  line-height: 36px;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

const ValuesWrapper = styled(BlockWrapper)`
  padding-top: 25px;
  padding-bottom: 25px;
`;

const TransactionID = styled.p`
	color: #4A4A4A;
	font-size: 14px;
	line-height: 21px;
	margin: 0;
`;

const LabeledItem = styled.div`
  label {
    color: #9B9B9B;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  p {
    color: #4A4A4A;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin: 0;
    overflow-wrap: break-word;
    line-height: 16px;
  }

  //span {
  //  color: #727072;
  //  font-size: 12px;
  //  line-height: 16px;
  //  margin-top: 20px;
  //}
`;

const AddressLabel = styled(LabeledItem)`
  width: 300px;
  margin-right: 25px;

  display: flex;
  flex-direction: column;

  label {
    display: inline-block;
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }

  ${mobile`
    p {
        font-size: 14px;
        margin-bottom: 10px;
    }
    padding-top: 10px;
    padding-bottom: 10px;
  `}
`;

const DepositAddressLabel = styled(LabeledItem)`
  display: flex;
  flex-direction: column;
  word-break: break-all;
  // width: 100%;

  ${mobile`
    // width: 100%;
    p {
        font-size: 14px;
        margin-bottom: 10px;
    }
  `};

  label {
    display: inline-block;
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }
`;

const DepositAddressDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const Equivalent = styled.div`
  display: inline-block;

  ${mobile`
    display: none;
  `};

  label {
    color: #9B9B9B;
    font-size: 14px;
    text-transform: initial!important;
    margin-bottom: 0!important;
  }

  a {
    color: #ef8632;
    font-size: 12px;
    line-height: 19px;
  }
`;

const Rate = styled.div`
  label {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #4A4A4A
  }

  p {
    margin: 0;
    font-weight: 500;
    span {
      color: #D3D3D3;
      font-size: 12px;
      line-height: 21px;
    }
  }
`;

const FreezValuesWrapper = styled(ValuesWrapper)`
  label {
	color: #9B9B9B;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.86px;
	line-height: 20px;
	text-transform: uppercase;
	margin-bottom: 15px;
    display: inline-block;
  }

  ${mobile`
    padding-top: 0;

    > label {
        display: none;
    }
  `}
`;

const Deprate = styled(Rate)`
  display: inline-block;
  vertical-align: top;
  margin-top: -8px;
  margin-left: 30px;

  ${mobile`
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  `};

  label {
    text-transform: initial;
    margin-bottom: 0!important;
  }

  span {
    color: #D3D3D3;
    font-size: 12px;
    line-height: 21px;
  }
`;

const DepRow = styled(RowWrapper)`
  justify-content: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;

  ${mobile`
    flex-direction: column;
    margin-top: 0;

    > div {
        width: 100%;
        margin-bottom: 15px;
    }
  `};

  p {
    margin: 0;
  }

  span {
	color: #9B9B9B;
	font-size: 12px;
	line-height: 14px;
	width: 200px;
  }
`;

const Col = styled(ColWrapper)`
  width: 20%;

  p {
    font-size: 14px;
    color: #4A4A4A;

    &:last-child {
    font-weight: bold;
    }
  }
`;

const DCol = styled(ColWrapper)`
  width: 40%;
  flex-flow: row;
    align-items: center;

    img {
      margin-right: 8px;
    }
`;

const Status = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 14px;
  color: #4A4A4A;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  ${mobile`
    width: calc(100% - 80px);
    text-align: left;
    top: 43px;
    font-size: 16px;
    height: 70px;
    right: 0;
    left: 50px;
    align-items: flex-start;

    > div {
        align-self: flex-end;
    }

    span {
        display: none;
    }
  `};
`;

const MobileStatus = styled.div`
  display: none;
  color: #4A4A4A;
  font-size: 16px;
  margin-top: 30px;

  ${mobile`
    display: block;
  `}
`;

const MobileCol = styled(Col)`
  ${mobile`
    display: none;
  `}
`;


export default class TransactionIncomingDepositStep extends React.Component {

    static propTypes = {
        status: PropTypes.string,
        currencyFromConfig: PropTypes.object,
        currencyToConfig: PropTypes.object,
        proceedAddress: PropTypes.string,
        refundAddress: PropTypes.string,
        transaction: PropTypes.object,
        transactionId: PropTypes.string,
        rate: PropTypes.number,
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
        dayValue: PropTypes.number,
        dayLimit: PropTypes.number,
        feeEstimate: PropTypes.number,
        partialDepthPair: PropTypes.string,
        partialDepth: PropTypes.object,
    };

    state = {
        currentStep: 1
    };

    state = {
        depositTxIdCopied: false,
    };

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    onCopyDepositTxId() {
        this.setState({
            depositTxIdCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                depositTxIdCopied: false,
            });
        }, 1500);
    }

    render() {

        const {
            status,
            proceedAddress,
            refundAddress,
            currencyToConfig,
            currencyFromConfig,
            transaction,
            transactionId,
            rate,
            minValue,
            maxValue,
            dayValue,
            dayLimit,
            feeEstimate,
            partialDepth,
            partialDepthPair,
        } = this.props;

        const pairString = transaction.refundAddr.asset + '-' + transaction.targetAddr.asset;

        var calculatedRate = rate;
        var calculatedMin = minValue;
        var calculatedMax = maxValue;

        if (partialDepth != null && partialDepthPair == pairString) {
            var executedTrades = {};
            var tradeRemainingAmount = Number(transaction.depositAmount);
            var tradeAmount;

            Object.keys(partialDepth).forEach(function(orderPrice) {
                var orderAmount = Number(partialDepth[orderPrice]);
                tradeAmount = tradeRemainingAmount;
                if (tradeAmount > 0) {
                    if (tradeAmount > orderAmount) {
                        tradeAmount = Number(orderAmount);
                    }
                    executedTrades[orderPrice] = tradeAmount;
                    tradeRemainingAmount = tradeRemainingAmount - tradeAmount;
                }
                calculatedMin = calculatedMin + orderAmount;
                calculatedMax = calculatedMax + orderAmount;
            });
            if (calculatedMin > minValue) calculatedMin = minValue;
            if (calculatedMax > maxValue) calculatedMax = maxValue;

            var num = 0;
            var denom = 0;
            Object.keys(executedTrades).forEach(function(executedPrice) {
                var executedAmount = Number(executedTrades[executedPrice]);
                num = num + (Number(executedPrice) * executedAmount);
                denom = denom + Number(executedAmount);
            });
            if (denom > 0) {
                calculatedRate = sigDigits((num / denom) * 0.994, 6);
            }
        }

        var calculatedProceeds = sigDigits((transaction.depositAmount * calculatedRate)-feeEstimate, 6);


        return status === 'LOADING_TRANSACTION' || status === 'TRADE_IN_PROGRESS' || (status === 'DEPOSIT_IN_PROGRESS' && transaction.depositAmount) || (status === 'DEPOSIT_CREDITED' && transaction.depositAmount) ? (
            <div>
                <HeaderWrapper>
                    <TransactionID>
                        <FormattedMessage
                            id="oct.trade_id"
                            defaultMessage="Trade ID: {id}"
                            values={{
                                id: transactionId
                            }}
                        />
                    </TransactionID>
                    <TransactionStatus>
                        {status === 'LOADING_TRANSACTION' ? 'Loading ...' : null}
                        {status === 'TRADE_IN_PROGRESS' ? 'Trade in progress' : null}
                        {status === 'DEPOSIT_IN_PROGRESS' && transaction.confCount === 0 ?
                            'Incoming deposit detected' :
                            null
                        }
                        {status === 'DEPOSIT_IN_PROGRESS' && transaction.confCount > 0 ?
                            'Deposit confirmations' :
                            null
                        }
                        {status === 'DEPOSIT_CREDITED' ?
                            'Deposit credited' :
                            null
                        }
                    </TransactionStatus>
                    <Status>
                        <Spinner />
                        <span>
                            {status === 'LOADING_TRANSACTION' ? 'Loading the transaction...' : null}
                            {status === 'TRADE_IN_PROGRESS' ? 'Trade in progress...' : null}
                            {status === 'DEPOSIT_IN_PROGRESS' && transaction.confCount === 0 ?
                                'Waiting for one blockchain confirmation...' :
                                null
                            }
                            {status === 'DEPOSIT_IN_PROGRESS' && transaction.confCount > 0 ?
                                'Blockchain confirmation received...' :
                                null
                            }
                            {status === 'DEPOSIT_CREDITED' ?
                                'Deposit credited, trade pending...' :
                                null
                            }
                        </span>
                    </Status>
                    <MobileStatus>
                        {status === 'LOADING_TRANSACTION' ? 'Loading the transaction...' : null}
                        {status === 'TRADE_IN_PROGRESS' ? 'Trade in progress...' : null}
                        {status === 'DEPOSIT_IN_PROGRESS' && transaction.confCount === 0 ?
                            'Waiting for one confirmation...' :
                            null
                        }
                        {status === 'DEPOSIT_IN_PROGRESS' && transaction.confCount > 0 ?
                            'Confirmation received...' :
                            null
                        }
                        {status === 'DEPOSIT_CREDITED' ?
                            'Deposit credited, trade pending...' :
                            null
                        }
                    </MobileStatus>
                </HeaderWrapper>

                <FreezValuesWrapper>
                    <CurrencyStaticPair
                        currencyFrom={currencyFromConfig.name}
                        currencyTo={currencyToConfig.name}
                        valueFrom={transaction.depositAmount}
                        valueTo={calculatedProceeds}
                    />
                    <Deprate>
                        <FormattedMessage
                            id="oct.exchange_rate"
                            defaultMessage="Exchange Rate"
                            tagName="label"
                        />
                        <p>
                            1 {currencyFromConfig.name} ≈ {calculatedRate} {currencyToConfig.name}
                        </p>
                        <span>As of {dateFns.format(new Date(transaction.startTs), 'HH:mm a')}</span>
                    </Deprate>
                    <DepRow>
                        <MobileCol>
                            <FormattedMessage
                                id="oct.min_trade"
                                defaultMessage="Min trade"
                                tagName="p"
                            />
                            <p>
                                {calculatedMin} {currencyFromConfig.name}
                            </p>
                        </MobileCol>
                        <MobileCol>
                            <FormattedMessage
                                id="oct.max_trade"
                                defaultMessage="Max trade"
                                tagName="p"
                            />
                            <p>
                                {calculatedMax} {currencyFromConfig.name}
                            </p>
                        </MobileCol>
                        {transaction.refundAmount > 0 ? (
                            <Col>
                                <FormattedMessage
                                    id="oct.refund"
                                    defaultMessage="Refund"
                                    tagName="p"
                                />
                                <p>
                                    {transaction.refundAmount} {currencyFromConfig.name}
                                </p>
                            </Col>
                        ) : null}

                        <DCol>
                            <img src={'/' + refreshIcon} alt=""/>
                            <FormattedMessage
                                id="oct.refund_description"
                                defaultMessage="Deposits in excess of the maximum will be sent back to your refund address."
                                tagName="span"
                            />
                        </DCol>
                    </DepRow>
                    <Equivalent>
                        <FormattedMessage
                            id="oct.equivalent"
                            defaultMessage="You have ${spendable} of your ${limit} trade limit remaining today."
                            values={{
                                spendable: dayValue,
                                limit: dayLimit
                            }}
                            tagName="label"
                        /> <br/>
                        <FormattedHTMLMessage
                            id="oct.canada_trade_regulation"
                            defaultMessage="<a href=https://www.fintrac-canafe.gc.ca/guidance-directives/client-clientele/client/msb-eng target=_blank>Canadian Regulations</a>"
                        />
                    </Equivalent>
                </FreezValuesWrapper>

                <AddressWrapper>
                    { transaction.depositAddr.address && (
                        <DepositAddressLabel>
                            <DepositAddressDiv>
                                <FormattedMessage
                                    id="oct.deposit_txid"
                                    defaultMessage="Your {currency} Deposit TxID"
                                    tagName="label"
                                    values={{
                                        currency: currencyFromConfig.label
                                    }}
                                />
                                <CopyToClipboardSpan>
                                    {this.state.depositTxIdCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                        <CopyToClipboard onCopy={()=>this.onCopyDepositTxId()} text={transaction.depositTxId}>
                                            <span>
                                                <img src={'/' + copyIcon} alt="Copy to TxID clipboard"/>
                                                &nbsp;Copy TxID to clipboard
                                            </span>
                                        </CopyToClipboard>
                                    )}
                                </CopyToClipboardSpan>
                            </DepositAddressDiv>
                            <p>
                                {transaction.depositTxId}
                            </p>
                            <FormattedMessage
                                id="oct.deposit_currency"
                                defaultMessage="Your {currency} Deposit Address"
                                tagName="label"
                                values={{
                                    currency: currencyFromConfig.label
                                }}
                            />
                            <p>
                                {transaction.depositAddr.address}
                            </p>
                            {currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.depositAddr.network).addressTagName && transaction.depositAddr.tag ? (
                                <React.Fragment>
                                    <FormattedMessage
                                        id="oct.deposit_tag_label"
                                        defaultMessage="Your {label} Deposit {name}"
                                        tagName="label"
                                        values={{
                                            label: currencyFromConfig.label,
                                            name: currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.depositAddr.network).addressTagName.replace(' (Optional)', ''),
                                        }}
                                    />
                                    <p>
                                        {transaction.depositAddr.tag}
                                    </p>
                                </React.Fragment>
                            ) : null}
                        </DepositAddressLabel>
                    ) }
                </AddressWrapper>

                <AddressWrapper>
                    { refundAddress && (
                        <AddressLabel>
                            <FormattedMessage
                                id="oct.refund_currency"
                                defaultMessage="Your {currency} Refund Address"
                                tagName="label"
                                values={{
                                    currency: currencyFromConfig.label
                                }}
                            />
                            <p>
                                {refundAddress}
                            </p>
                            {currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.refundAddr.network).addressTagName && transaction.refundAddr.tag ? (
                                <React.Fragment>
                                    <FormattedMessage
                                        id="oct.refund_tag_label"
                                        defaultMessage="Your {label} Refund {name}"
                                        tagName="label"
                                        values={{
                                            label: currencyFromConfig.label,
                                            name: currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.refundAddr.network).addressTagName.replace(' (Optional)', ''),
                                        }}
                                    />
                                    <p>
                                        {transaction.refundAddr.tag}
                                    </p>
                                </React.Fragment>
                            ) : null}
                        </AddressLabel>
                    ) }

                    { proceedAddress && (
                        <AddressLabel>
                            <FormattedMessage
                                id="oct.proceed_currency"
                                defaultMessage="Your {currency} Proceeds Address"
                                tagName="label"
                                values={{
                                    currency: currencyToConfig.label
                                }}
                            />
                            <p>
                                {proceedAddress}
                            </p>
                            {currencyToConfig.networks.find(networkConfig => networkConfig.name === transaction.targetAddr.network).addressTagName && transaction.targetAddr.tag ? (
                                <React.Fragment>
                                    <FormattedMessage
                                        id="oct.proceeds_tag_label"
                                        defaultMessage="Your {label} Proceeds {name}"
                                        tagName="label"
                                        values={{
                                            label: currencyToConfig.label,
                                            name: currencyToConfig.networks.find(networkConfig => networkConfig.name === transaction.targetAddr.network).addressTagName.replace(' (Optional)', ''),
                                        }}
                                    />
                                    <p>
                                        {transaction.targetAddr.tag}
                                    </p>
                                </React.Fragment>
                            ) : null}
                        </AddressLabel>
                    ) }
                </AddressWrapper>

            </div>
        ) : null;
    }
}
