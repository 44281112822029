import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PortfolioAssetPage from './PortfolioAssetPage';
import {getApproxTotal} from '../../../../selectors';


function mapStateToProps(state) {
    return {
        coinsConfig: state.currency.coinsConfig,
        wallets: state.portfolio.wallets,
        approxTotal: getApproxTotal(state),
        fiatCurrency: state.portfolio.fiatCurrency,
        usdToFiatRate: state.portfolio.usdToFiatRate,
        userPublicCode: state.user.publicCode,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default withRouter(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioAssetPage)));
