import styled, { keyframes } from 'styled-components';

const dots = keyframes`
    0%, 20% {
      color: white;
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;
    }
    40% {
      color: #727272;
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;
    }
    60% {
      text-shadow:
        .25em 0 0 #727272,
        .5em 0 0 white;
    }
    80%, 100% {
      text-shadow:
        .25em 0 0 #727272,
        .5em 0 0 #727272;
    }
`;

export const LoadingDots = styled.div`
  &:after {
    content: ' .';
    animation: ${dots} 1s steps(5, end) infinite;
  }
`;
