import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Slider, Handles, Tracks } from 'react-compound-slider';

import { sigDigits } from '../../utils';
import { mobile } from '../../styles';

const sliderStyle = {
    position: 'relative',
    width: '100%',
    height: 50,
};

const railStyle = {
    position: 'absolute',
    width: '100%',
    height: 5,
    marginTop: 35,
    borderRadius: 5,
    backgroundColor: '#E0E4EE',
};

const Dot = styled.div`
    left: ${props => props.percent}%;
    position: absolute;
    // margin-left: -7px;
    //margin-top: 32px;
    // width: 12px;
    // height: 12px;
    margin-left: -11px;
    margin-top: 28px;
    width: 20px;
    height: 20px;
    z-index: 2;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: #ef8632;
    box-shadow: 1px 1px 2px #9e9e9e;

    div {
      // width: 6px;
      // height: 6px;
      // top: 3px;
      // left: 3px;
      width: 10px;
      height: 10px;
      top: 5px;
      left: 5px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
    }

    ${mobile`
        margin-left: -16px;
        margin-top: 23px;
        width: 30px;
        height: 30px;
        div {
          width: 14px;
          height: 14px;
          top: 8px;
          left: 8px;
        }
    `}
`;

const Container = styled.div`
`;

const Values = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Value = styled.div`
  span {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #9B9B9B;
  }

  p {
    margin: 0;
    text-align: center;
  }
`;

const SmallP = styled.p`
  font-size: 12px;
  text-align: center;
`;

export function Handle({ // your handle component
    handle: { id, percent }, // you get an id, the value and the percentage to place it.
    getHandleProps,
}) {
    return (
        <Dot percent={percent} {...getHandleProps(id)}>
            <div/>
        </Dot>
    );
}

Handle.propTypes = {
    handle: PropTypes.any,
    getHandleProps: PropTypes.func
};


function Track({ source, target, getTrackProps }) { // your own track component
    return (
        <div
            style={{
                position: 'absolute',
                height: 5,
                zIndex: 1,
                marginTop: 35,
                backgroundColor: '#ef8632',
                borderRadius: 5,
                cursor: 'pointer',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    );
}

Track.propTypes = {
    source: PropTypes.any,
    target: PropTypes.any,
    getTrackProps: PropTypes.any,
};


export default class SliderComponent extends React.Component {

    static propTypes = {
        onChange: PropTypes.func,
        onUpdate: PropTypes.func,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
        usdRate: PropTypes.number,
        value: PropTypes.number,
        currency: PropTypes.string,
    };

    render() {

        const {onChange, currency, value, onUpdate, minValue, maxValue, usdRate } = this.props;

        const fraction = 1000000;
        const steps = 1000;

        const roundedMin = Math.round(minValue * fraction) / fraction;
        const roundedMax = Math.round(maxValue * fraction) / fraction;
        const step = Math.round((roundedMax - roundedMin)/steps  * fraction)/ fraction;
        const calculatedMax = roundedMin  + (step * steps);

        return (
            <Container>
                <Slider
                    rootStyle={sliderStyle}
                    domain={[roundedMin, calculatedMax]}
                    step={step}
                    mode={2}
                    values={[value]}
                    onUpdate={onChange}
                    onChange={onUpdate}
                >
                    <div style={railStyle} />
                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle
                                        key={handle.id}
                                        handle={handle}
                                        getHandleProps={getHandleProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Handles>
                    <Tracks right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div className="slider-tracks">
                                {tracks.map(({ id, source, target }) => (
                                    <Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>
                </Slider>
                <Values>
                    <Value>
                        <span>
                            Minimum trade
                        </span>
                        <p>
                            {sigDigits(+minValue, 6)} {currency}
                        </p>
                        <SmallP>
                            (${Number(minValue*usdRate).toFixed(2)} USD)
                        </SmallP>
                    </Value>
                    <Value>
                        <span>
                            Maximum trade
                        </span>
                        <p>
                            {sigDigits(+maxValue, 6)} {currency}
                        </p>
                        <SmallP>
                            (${Number(maxValue*usdRate).toFixed(2)} USD)
                        </SmallP>
                    </Value>
                </Values>
            </Container>
        );
    }

}
