import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QuickTrade from './QuickTrade';

function mapStateToProps(state) {
    return {
        trade: state.market,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickTrade);
