import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as GradientView } from '../../components/GradientView';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as AuthFooter } from '../../components/AuthFooter';
import {
    LinkButton,
    FormContainer,
    FormWrapper,
} from '../../commonStyles/AuthStyles';

import { mobile } from '../../styles';
import greenCheckImage from '../../images/ic-green-tick.svg';
import PropTypes from 'prop-types';


const GreenCheckImage = styled.div`
  text-align: center;
  margin-bottom: 35px;

  ${mobile`
      margin-bottom: 20px;
  `}
`;

const FormTitle = styled.h1`
	font-size: 24px;
	line-height: 36px;
	text-align: center;
	font-weight: 500;
	margin-bottom: 40px;
`;

const LinkBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`;

const ContinueLink = styled(LinkButton)`
    border: 1px solid #EF8632;
    width: 220px;
    color: #FFFFFF;
    background-color: #EF8632;
    padding: 16px 20px;
`;

const Explanation = styled.div`
  max-width: 390px;
  padding-top: 35px;
  text-align: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #262626;

  p {
    margin-bottom: 25px;
  }
`;


export default class NewDeviceScreen extends React.Component {

    static propTypes = {
        history: PropTypes.object,
    };

    componentDidMount() {
        document.title = 'New Device (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });

        this.timer = setTimeout(() => {
            this.props.history.push('/login');
        }, 300000); // five minutes
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        return (
            <FormContainer>
                <GradientView borderRadius={'0 0 15px 15px'}>
                    <FormWrapper>
                        <GreenCheckImage>
                            <img src={'/' + greenCheckImage} alt="" />
                        </GreenCheckImage>
                        <FormTitle>
                            <MessageBold>
                                <FormattedMessage
                                    id="auth.deviceapproved.title"
                                    defaultMessage="New device registered"
                                />
                            </MessageBold>
                        </FormTitle>
                        <Explanation>
                            <p>
                                <FormattedMessage
                                    id="auth.deviceapproved.deviceIsReady"
                                    defaultMessage="This device is ready to be used from this IP address"
                                />
                            </p>

                            <FormattedMessage
                                id="auth.deviceapproved.explanation"
                                defaultMessage="For your safety, we verify devices any time location or browser changes are detected."
                            />
                        </Explanation>

                        <LinkBlock>
                            <ContinueLink to="/login">
                                <FormattedMessage
                                    id="auth.deviceapproved.continueLogin"
                                    defaultMessage="Continue to login"
                                />
                            </ContinueLink>
                        </LinkBlock>
                    </FormWrapper>
                </GradientView>
                <AuthFooter type={'WHITE'} />
            </FormContainer>
        );
    }
}
