import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import logoImage from '../../../../images/ic-warning.svg';

import { mobile } from '../../../../styles';


const Title = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin-top: 1em;
  // margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  // width: 600px;

  display: block;
  flex-wrap: wrap;
  flex: 0 1 auto;

`;

const SquareLogoDiv = styled.div`
  text-align: center;
  display: block;

  margin-left: auto;
  margin-right: auto;
`;

const SquareLogo = styled.img`
  width: 300px;
  height: 300px;

  ${mobile`
    width: 200px;
    height: 200px;
  `}
`;

export default class NotFound extends React.Component {
    render() {
        return (
            <SquareLogoDiv>
                <SquareLogo src={'/' + logoImage}/>
                <Title>
                    <FormattedMessage
                        id="navigation.notFoundTitle"
                        defaultMessage="Whatever it was, it is gone now..."
                    />
                    <br/>
                    <FormattedMessage
                        id="navigation.fourOhFourTitle"
                        defaultMessage="(404: Not Found)"
                    />
                </Title>
                <FormattedMessage
                    id="thanksForUsing"
                    defaultMessage="Thank you for choosing BlocPalX!"
                    tagName="h3"
                />
            </SquareLogoDiv>
        );
    }
}
