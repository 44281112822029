import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QRCodeComponent from 'qrcode.react';


const Wrapper = styled.div`
  
`;


/**
 * Simple wrapper for qrcode.react lib
 * We may need to change implementation but could keep interface
 */
export default class QRCode extends React.Component {

    static propTypes = {
        /**
         * String value, that is showed on the QR code
         */
        value: PropTypes.string.isRequired
    };

    render() {

        const { value, ...props } = this.props;

        return (
            <Wrapper {...props}>
                <QRCodeComponent {...props} value={value} />
            </Wrapper>
        );

    }

}