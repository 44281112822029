import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ProjectsList from './ProjectsList';


function mapStateToProps(state) {
    return {
        coinsConfig: state.currency.coinsConfig,
        fiatCurrency: state.portfolio.fiatCurrency,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsList));
