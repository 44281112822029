import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {DisplayFiat, displayFormat} from '../../../../utils';
import { mobile } from '../../../../styles';
import { Component as CurrencyImage } from '../../../../components/CurrencyImage';
import { Table,
    FilterHeader,
    HeaderRowNoMobile,
    TableRowMono,
    Cell,
    CellLeft,
    CellRight,
    CellRightNoMobile,
    CellNoDesktop,
    CellNoMobile,
    LastCell,
    SortButtonCell } from '../../../../commonStyles/TableStyles.js';

const Wrapper = styled.div`
  flex: 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  position: relative;
  padding: 10px;
  background-color: #fff;
  ${mobile`
    padding: 5px 0px 0px 0px;
  `};
  font-style: normal;
`;

const StyledPair = styled.div`
  // width: 100%;
  display: flex;
  // border: 2px solid #eee;
  // border-radius: 5px;
  // border-bottom: 1px solid #eee;
  // position: relative;
  padding: 10px;
  // padding: 15px 0px 15px 0px;
  // white-space: pre-wrap;
  // padding: 20px 20px 20px 20px;
  font-family: Inter, sans-serif;
  text-decoration: none;
  margin: 10px 0px;

  color: #232323;
  justify-content: space-between;
  align-items: center;
  
  &.active {
    background-color: #ef863214;
    border-color: #ef863214;
  }

  >span {
    // display: flex;
    // vertical-align: middle;
    // align-items: center;
    // height: 24px;
    // margin-top: 13px;
    img {
      margin-right: 10px;
    }
  }

  ${mobile`
    padding: 10px 5px 10px 5px;
    width: 160px;
  `};
`;

const Bold = styled.span`
    font-weight: bold;
    ${mobile`
      text-align: center;
    `};
`;

const Tiny = styled.div`
    margin-right: 25px;
    font-size: 12px;

    ${mobile`
      margin-right: 0;
      text-align: center;
    `};
`;

const CurrencyIcon = styled(CurrencyImage)`
  margin-left: 4px;
  margin-right: 4px;
  width: 30px;
  height: 30px;

  ${mobile`
      margin-left: 3px;
      margin-right: 3px;
      width: 25px;
      height: 25px;
  `};
`;

export const CellLeftNoPadding = styled(CellLeft)`
  padding-left: 0px;

  ${mobile`
    text-align: center;
  `};
`;

const IntervalCell = styled.div`
  display: table-cell;  
  text-align: center;  
  vertical-align: middle;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  
  ${mobile`
    max-width: 280px;
    margin-right: 1px;
  `};
`;

const ClickSpan = styled.span`
  color: ${props => props.theme.colors.primary2};
  cursor: pointer;
`;

const GreenSpan = styled.span`
  color: #00B200;
`;

const RedSpan = styled.span`
  color: #F00;
`;

const IntervalButton = styled.div`
    color: ${props => props.selected ? '#FFFFFF' : '#EF8632'};
    background: ${props => props.selected ? '#EF8632' : '#F2F2F2'} ;
    cursor: pointer;
    border-radius: 5px;
    vertical-align: middle;
    padding: 4px 10px;
    border: 1px solid ${props => props.selected ? '#EF8632' : '#D3D3D3'};
    margin: 2px;
    // display: flex;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;    
`;


/*
import DropdownMenu from 'react-dd-menu';
const StyledMenu = styled(DropdownMenu)`
  display: inline-block;
  margin-left: 5px;
  position: relative;

  >span {
    cursor: pointer;
    color: #ef8632;
    text-transform: capitalize;
  }

  .dd-menu-items {
    position: absolute;
    width: 170px;
    background-color:#fff;
    box-shadow: 0 0 2px #ddd;

    ul {
      margin: 0;
      padding: 0;

      span {
        display: block;
        text-transform: capitalize;
        cursor: pointer;
        padding: 3px 15px;

        &:hover {
          background-color:#eee;
        }
      }
    }
  }
`;
*/

const INTERVALS = [
    '24 Hrs',
    '7 Days',
    '30 Days',
];


export default class MarketPage extends React.Component {

    static propTypes = {
        fiatCurrency: PropTypes.string,
        usdToFiatRate: PropTypes.number,
        pairs: PropTypes.array,
        startTickerFeed: PropTypes.func,
        stopTickerFeed: PropTypes.func,
        onChangeSortDirection: PropTypes.func,
        sortDirection: PropTypes.string,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false,
            selectedInterval: '24 Hrs',
        };
    }

    componentDidMount() {
        this.props.startTickerFeed({
            pair: 'ALL',
        });
    }

    componentWillUnmount() {
        this.props.stopTickerFeed();
    }

    openMenu = () => {
        this.setState(state => ({
            isMenuOpen: !state.isMenuOpen
        }));
    };

    selectInterval = (interval) => {
        this.setState({
            selectedInterval: interval,
            isMenuOpen: false,
        });
    };

    closeMenu = () => {
        this.setState({
            isMenuOpen: false
        });
    };

    renderSortView = () => {
        return (
            <SortButtonCell>
                <FormattedMessage
                    id="market.sort"
                    defaultMessage="Sort: {actionLabel}"
                    values={{
                        actionLabel: (
                            <FormattedMessage
                                id="market.sortAlpha"
                                defaultMessage="Alpha"
                            >
                                {message => <ClickSpan onClick={this.props.onChangeSortDirection}>{message} {this.props.sortDirection === 'ASC' ? '↑' : '↓'}</ClickSpan>}
                            </FormattedMessage>
                        )
                    }}
                />
            </SortButtonCell>
        );
    };

    renderPeriodFilter = () => {
        return (
            <IntervalCell>
                <FormattedMessage
                    id="market.reportingPeriod"
                    defaultMessage="Period: "
                />
                {
                    INTERVALS.map((interval, i) => <IntervalButton
                        key={i}
                        selected={interval === this.state.selectedInterval}
                        onClick={() => this.selectInterval(interval)}>{interval}</IntervalButton>
                    )
                }
            </IntervalCell>
        );
    };

    renderActiveRowsHeader = () => {
        return (
            <HeaderRowNoMobile>
                <CellLeft>
                    <FormattedMessage
                        id="market.activePair"
                        defaultMessage="Active Pairs"
                    />
                </CellLeft>
                <CellRight>
                    <FormattedMessage
                        id="market.last"
                        defaultMessage="Last"
                    />
                </CellRight>
                <Cell />
                <Cell>
                    <GreenSpan>&#9650; </GreenSpan>
                    <FormattedMessage
                        id="market.high"
                        defaultMessage="High"
                    />
                </Cell>
                <Cell>
                    <FormattedMessage
                        id="market.volume"
                        defaultMessage="Tot Vol"
                    />
                </Cell>
                <Cell>
                    <FormattedMessage
                        id="market.avg"
                        defaultMessage="Avg Price"
                    />
                </Cell>
                <Cell>
                    <FormattedMessage
                        id="market.low"
                        defaultMessage="Low"
                    />
                    <RedSpan> &#9660;</RedSpan>
                </Cell>
                <LastCell>
                    <FormattedMessage
                        id="market.change"
                        defaultMessage="Change"
                    />
                </LastCell>
            </HeaderRowNoMobile>
        );
    };

    renderInActiveRowsHeader = () => {
        return (
            <HeaderRowNoMobile>
                <CellLeft>
                    <FormattedMessage
                        id="market.inactivePair"
                        defaultMessage="Inactive Pairs"
                    />
                </CellLeft>
                <CellRight>
                    <FormattedMessage
                        id="market.last"
                        defaultMessage="Last"
                    />
                </CellRight>
                <Cell />
                <Cell>
                    <GreenSpan>&#9650; </GreenSpan>
                    <FormattedMessage
                        id="market.high"
                        defaultMessage="High"
                    />
                </Cell>
                <Cell>
                    <FormattedMessage
                        id="market.volume"
                        defaultMessage="Tot Vol"
                    />
                </Cell>
                <Cell>
                    <FormattedMessage
                        id="market.avg"
                        defaultMessage="Avg Price"
                    />
                </Cell>
                <Cell>
                    <FormattedMessage
                        id="market.low"
                        defaultMessage="Low"
                    />
                    <RedSpan> &#9660;</RedSpan>
                </Cell>
                <LastCell>
                    <FormattedMessage
                        id="market.change"
                        defaultMessage="Change"
                    />
                </LastCell>
            </HeaderRowNoMobile>
        );
    };

    renderPriceValues = (pair, isTrading) => {

        const placeholder = isTrading ? '--' : 'N/A';

        let highKey = '';
        let volKey = '';
        let avgPriceKey = '';
        let lowKey = '';
        let changePerKey = '';

        if(this.state.selectedInterval === '24 Hrs'){
            highKey = 'h';
            volKey = 'v';
            avgPriceKey = 'w';
            lowKey = 'l';
            changePerKey = 'P';
        }
        else if(this.state.selectedInterval === '7 Days'){
            highKey = 'h7';
            volKey = 'v7';
            avgPriceKey = 'w7';
            lowKey = 'l7';
            changePerKey = 'P7';
        }
        else if(this.state.selectedInterval === '30 Days'){
            highKey = 'h30';
            volKey = 'v30';
            avgPriceKey = 'w30';
            lowKey = 'l30';
            changePerKey = 'P30';
        }

        return (
            <React.Fragment>
                <CellNoMobile>
                    {pair.data && displayFormat(pair.data[highKey], pair.data.d)}
                </CellNoMobile>
                <CellNoMobile>
                    {pair.data && displayFormat(pair.data[volKey], 2)}
                </CellNoMobile>
                <CellNoMobile>
                    {pair.data && displayFormat(pair.data[avgPriceKey], pair.data.d)}
                </CellNoMobile>
                <CellNoMobile>
                    {pair.data && displayFormat(pair.data[lowKey], pair.data.d)}
                </CellNoMobile>
                <LastCell>
                    <div>
                        {pair.data && (
                            Number(pair.data[changePerKey]) > 0 ? (
                                <GreenSpan>&#9650; </GreenSpan>
                            ) : (
                                Number(pair.data[changePerKey]) < 0 && (
                                    <RedSpan>&#9660; </RedSpan>
                                )
                            )
                        )}
                        {(pair.data) ? pair.data[changePerKey].replace('-', '') + '%' : placeholder}
                    </div>
                </LastCell>
            </React.Fragment>
        );

    };

    renderPairInfo = (index, pair) => {
        return (
            <CellLeft>
                <StyledPair
                    key={index}
                    to={`/market/${pair.pair}`}
                    exact
                >
                    <CurrencyIcon currency={pair.base} />
                    <Bold>
                        {pair.base}
                    </Bold>
                    <Bold>/</Bold>
                    <Bold>
                        {pair.quote}
                    </Bold>
                    <CurrencyIcon currency={pair.quote} />
                </StyledPair>
            </CellLeft>
        );
    }

    renderPairRates = (pair, isTrading) => {
        const { fiatCurrency, usdToFiatRate } = this.props;

        const placeholder = isTrading ? '--' : 'N/A';

        if (pair.data && Number(pair.data.Q) > 0) {
            return (
                <React.Fragment>
                    <CellRightNoMobile>
                        <Bold>
                            {pair.data && pair.data.Q} @
                        </Bold>
                        {(fiatCurrency !== 'USD') && (
                            <Tiny>
                                {pair.data && (
                                    <DisplayFiat currency={fiatCurrency} value={Number(pair.data.us) * usdToFiatRate}/>
                                )}
                            </Tiny>
                        )}
                        <Tiny>
                            {pair.data && (
                                <DisplayFiat currency='USD' value={Number(pair.data.us)}/>
                            )}
                        </Tiny>
                    </CellRightNoMobile>
                    <CellLeftNoPadding>
                        <Bold>
                            {pair.data && displayFormat(pair.data.c, pair.data.d)}
                        </Bold>
                        {(fiatCurrency !== 'USD') && (
                            <Tiny>
                                {pair.data && (
                                    <DisplayFiat currency={fiatCurrency} value={(Number(pair.data.us) * usdToFiatRate) / Number(pair.data.Q)}/>
                                )}
                            </Tiny>
                        )}
                        <Tiny>
                            {(pair.data && !pair.quote.includes('USD')) ? (
                                <DisplayFiat currency='USD' value={Number(pair.data.us) / Number(pair.data.Q)}/>
                            ) : (
                                <DisplayFiat currency='USD' value={Number(pair.data.c)}/>
                            )}
                        </Tiny>
                    </CellLeftNoPadding>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <CellNoDesktop>
                    {placeholder}
                </CellNoDesktop>
                <CellRightNoMobile>
                    {placeholder}
                </CellRightNoMobile>
                <CellNoMobile />
            </React.Fragment>
        );
    }

    render() {

        const { sortDirection, pairs, history} = this.props;        

        const sortedNonTrading = (sortDirection === 'DESC') ?
            [...pairs].filter((pair) => pair.status !== 'TRADING').sort((a, b) => (a.base > b.base) ? 1 : (a.base === b.base) ? ((a.quote > b.quote) ? 1 : -1) : -1) :
            [...pairs].filter((pair) => pair.status !== 'TRADING').sort((a, b) => (a.base < b.base) ? 1 : (a.base === b.base) ? ((a.quote < b.quote) ? 1 : -1) : -1);

        const sortedTrading = (sortDirection === 'DESC') ?
            [...pairs].filter((pair) => pair.status === 'TRADING').sort((a, b) => (a.base > b.base) ? 1 : (a.base === b.base) ? ((a.quote > b.quote) ? 1 : -1) : -1).concat(sortedNonTrading) :
            [...pairs].filter((pair) => pair.status === 'TRADING').sort((a, b) => (a.base < b.base) ? 1 : (a.base === b.base) ? ((a.quote < b.quote) ? 1 : -1) : -1).concat(sortedNonTrading);

        let activePairIndex = 9999;
        let inactivePairIndex = 9999;

        for (let j = (sortedTrading.length - 1); j >= 0; j--) {
            // console.log(sortedTrading[j].status);

            if (sortedTrading[j].status === 'TRADING') {
                activePairIndex = j;
            } else {
                inactivePairIndex = j;
            }
        }

        return (
            <Wrapper>
                <FilterHeader>
                    {this.renderSortView()}
                    {this.renderPeriodFilter()}
                </FilterHeader>
                <Table>
                    {sortedTrading.map((pair, i) => (
                        <React.Fragment key={'frag_' + i}>
                            {activePairIndex === i && this.renderActiveRowsHeader()}
                            {inactivePairIndex === i && this.renderInActiveRowsHeader()}
                            <TableRowMono key={i} className={(pair.status !== 'TRADING') && 'inactivePair'} onClick={() => { history.push('/market/' + pair.pair); }}>
                                {this.renderPairInfo(i, pair)}
                                {this.renderPairRates(pair, pair.status === 'TRADING')}
                                {this.renderPriceValues(pair, pair.status === 'TRADING')}
                            </TableRowMono>
                        </React.Fragment>
                    ))}
                </Table>
            </Wrapper>
        );
    }

}
