import UAParser from 'ua-parser-js';
import Fingerprint2 from '@fingerprintjs/fingerprintjs';
import config from 'config';

const ua = UAParser(window.navigator.userAgent);

const getLocale = () => {
    if (window.navigator.languages) {
        return window.navigator.languages[0];
    } else {
        return window.navigator.userLanguage || window.navigator.language;
    }
};

export const getDeviceInfo = async () => {
    const options = {
        excludes: {
            plugins: true,
            localStorage: true,
            adBlock: true,
            screenResolution: true,
            availableScreenResolution: true,
            enumerateDevices: true,
            pixelRatio: true,
            doNotTrack: true
        },
        preprocessor: (key, value) => {
            if (key === 'userAgent') {
                const parser = new UAParser(value);
                // return customized user agent (without browser version)
                return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`;
            }
            return value;
        }
    };

    const components = await Fingerprint2.getPromise(options);
    const values = components.map(component => component.value);
    const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);

    return {
        accessToken: null, // token for sending push/browser notifications
        appVersion: config.get('features.clientVersion'), // should be defined in client build but for now is fixed in default.js
        deviceModel: ua.device.model || window.navigator.platform,
        deviceOS: ua.os.name,
        deviceUID: fingerprint,
        deviceVersion: String(ua.os.version),
        browserType: ua.browser.name,
        browserVersion: String(ua.browser.version),
        locale: getLocale(),
    };
};
