import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import ContentLoader from 'react-content-loader';
import { Route } from 'react-router-dom';

import { Component as CurrencyImage } from '../../components/CurrencyImage';
import { Component as StandardPage } from '../../components/StandardPage';
import { Component as SmallButton } from '../../components/SmallButton';

import { Container as AssetNews } from '../../components/AssetNews';

import { Container as TradeTabGroup } from './components/TradeTabGroup';
import { Container as TradeOrders } from './components/TradeOrders';
import { Container as TradeMyOrders } from './components/TradeMyOrders';
import { Container as TradeTicker } from './components/TradeTicker';
import { Container as TradePriceChart } from './components/TradePriceChart';
import { Container as TradePairsList } from './components/TradePairsList';
import { Container as MarketPage } from './components/MarketPage';
import { mobile } from '../../styles';


const MainLoader = props => (
    <ContentLoader
        height={600}
        width={800}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="50" y="80" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="160" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="240" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="320" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="400" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="480" rx="0" ry="0" width="700" height="60" />
    </ContentLoader>
);

const PairTitle = styled.div`
  color: #4A4A4A;
  font-size: 15px;
  font-weight: 600;
  // line-height: 36px;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  // margin-bottom: 10px;
  align-items: flex-start;
  flex-wrap: nowrap;
  
  a {
    color: #00174A;
    text-decoration:none;
  }

  ${mobile`
    justify-content: space-evenly;
  `};
`;
const PageTitle = styled.div`
	color: #4A4A4A;
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
    margin: 10px 0px 10px 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

	a {
	  color: #ef8632;
	  text-decoration:none;
	}

    span {
      text-align: center;
	}

    ${mobile`
      // justify-content: center;
      // margin-left: auto;
      // margin-right: auto;
      // margin-bottom: 5px;
    `};
`;


const StyledPair = styled.div`
  display: flex;
  // border: 2px solid #eee;
  // border-bottom: 0px;
  // border-radius: 5px 5px 0px 0px;
  padding: 5px;
  text-decoration: none;
  // margin-top: -1px;
  // margin: 5px 0px;
  vertical-align: middle;
  
  // background-color: #FAFAFA;
  color: #232323;
  justify-content: space-between;
  align-items: center;

  &.active {
    background-color: #ef863214;
    border-color: #ef863214;
  }

  >span {
    img {
      margin-right: 10px;
    }
  }
`;

const Sidebar = styled.div`
  border-right: 1px solid #eee;
  flex: 0 0 175px;
  padding: 15px;
  box-sizing: border-box;

  ${mobile`
    display: none;
  `};
`;

const Content = styled.div`
  flex: 1 1;
  // flex: 1 1 738px;
  margin-bottom: 5px;
`;

const Header = styled.div`
  // padding: 20px 20px 0;
`;

const StyledSmallButtonLeft = styled(SmallButton)`
  border-radius: 0px 5px 0px 0px;
  margin-top: auto;
  margin-bottom: auto;
`;

const StyledSmallButtonRight = styled(SmallButton)`
  border-radius: 0px 5px 0px 0px;
  margin-top: auto;
  margin-bottom: auto;
`;

const CurrencyIconNews = styled(CurrencyImage)`
  margin: 2px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`;

const CurrencyIcon = styled(CurrencyImage)`
  margin: 5px;
  width: 30px;
  height: 30px;
`;

const BackLink = styled.div`
  color: ${props => props.theme.colors.primary2};
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0px 20px 15px;

  :before {
    content: ' ← ';
    color: #000;
  }

  :hover {
    cursor: pointer;
  }
`;

// const CurrencyIconRight = styled(CurrencyImage)`
//   margin: 20px 10px 10px 10px;
//   width: 200px;
//   height: 200px;
//   float: right;
//   top: -20px;
//   right: -10px;
//   position: relative;
//   margin-left: auto;
//   opacity: 0.8;
// `;

const CurrencyIconRight = styled(CurrencyImage)`
  // hide it for now - Kurtis doesn't like it
  display: none;
  margin: 20px 10px 10px auto;
  width: 200px;
  height: 200px;
  float: right;
  // top: -20px;
  // right: -10px;
  // position: relative;
  opacity: 0.8;

  ${mobile`
    display: none;
  `};
`;


export default class MarketScreen extends React.Component {

    static propTypes = {
        baseCoinConfigName: PropTypes.string,
        quoteCoinConfigName: PropTypes.string,
        baseCoinConfigLabel: PropTypes.string,
        quoteCoinConfigLabel: PropTypes.string,
        isPairsLoaded: PropTypes.bool,
        isAuthenticated: PropTypes.bool,

        getPortfolioInfo: PropTypes.func,

        match: PropTypes.object,
        history: PropTypes.object,
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 'TRADE',
            sortDirection: 'DESC',
            previousPair: '',
        };
    }

    componentDidMount() {
        // console.log('**MarketScreen MOUNT');
        const { match, baseCoinConfigName, quoteCoinConfigName } = this.props;

        if (match.params.pair === 'openOrders') {
            document.title = 'Open Orders (BlocPalX)';
        } else if (baseCoinConfigName) {
            document.title = baseCoinConfigName + '-' + quoteCoinConfigName + ' (BlocPalX)';
        } else {
            document.title = 'Market (BlocPalX)';
            window.gtag('event', 'page_view', {
                page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
                page_search: this.props.history.location.search,
                page_hash: this.props.history.location.hash,
            });
        }

        const { isAuthenticated } = this.props;
        if (isAuthenticated) {
            this.props.getPortfolioInfo();
        }
    }

    componentWillUnmount() {
        // console.log('**MarketScreen UNMOUNT');
        document.title = 'BlocPalX';
    }

    componentDidUpdate() {
        const { isAuthenticated } = this.props;
        if (isAuthenticated) {
            this.props.getPortfolioInfo();
        }
    }

    onChangeSortDirection = () => {
        this.setState({
            sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        });
    };

    openAssetBasePage = () => {
        const { match } = this.props;
        this.props.history.push('/market/' + match.params.pair + '/aboutBase');
    };

    openAssetQuotePage = () => {
        const { match } = this.props;
        this.props.history.push('/market/' + match.params.pair + '/aboutQuote');
    };

    renderSideBar = () => {
        const { match } = this.props;
        return (
            <Sidebar>
                <TradePairsList
                    onChangeSortDirection={this.onChangeSortDirection}
                    sortDirection={this.state.sortDirection}
                    activePair={(match.params.pair === 'openOrders') ? '' : match.params.pair}
                    openOrders={match.params.pair === 'openOrders'}
                />
            </Sidebar>
        );
    }

    renderPairNewsBar = () => {
        const { baseCoinConfigName, quoteCoinConfigName, match } = this.props;
        return (
            <React.Fragment>
                <Header>
                    <PairTitle>
                        <React.Fragment>
                            <StyledSmallButtonLeft onClick={() => { this.openAssetBasePage(); }} title={baseCoinConfigName + ' Asset News'} bsStyle='white'>
                                <FormattedMessage
                                    id="trade.assetNewsRight"
                                    defaultMessage="{asset} NEWS"
                                    values={{
                                        asset: <CurrencyIconNews currency={baseCoinConfigName} />,
                                    }}
                                />
                            </StyledSmallButtonLeft>

                            <StyledPair>
                                <CurrencyIcon currency={baseCoinConfigName} />
                                <FormattedMessage
                                    id="trade.pageTitle"
                                    defaultMessage="{pair}"
                                    values={{
                                        pair: match.params.pair.replace('-', ' / '),
                                    }}
                                />
                                <CurrencyIcon currency={quoteCoinConfigName} />
                            </StyledPair>

                            <StyledSmallButtonRight onClick={() => { this.openAssetQuotePage(); }} title={quoteCoinConfigName + ' Asset News'} bsStyle='white'>
                                <FormattedMessage
                                    id="trade.assetNewsLeft"
                                    defaultMessage="NEWS {asset}"
                                    values={{ asset: <CurrencyIconNews currency={quoteCoinConfigName} />, }}
                                />
                            </StyledSmallButtonRight>
                        </React.Fragment>
                    </PairTitle>
                </Header>
            </React.Fragment>
        );
    }

    renderPairContentView = () => {
        const { match } = this.props;
        return (
            <React.Fragment>
                {this.renderPairNewsBar()}
                <TradeTicker pair={match.params.pair} />
                <TradePriceChart pair={match.params.pair} />
                <TradeTabGroup pair={match.params.pair} />
                <TradeOrders pair={match.params.pair} />
            </React.Fragment>
        );
    }

    render() {

        const { baseCoinConfigName, quoteCoinConfigName, baseCoinConfigLabel, quoteCoinConfigLabel, isPairsLoaded, isAuthenticated, match, history } = this.props;

        return (
            <StandardPage row>
                {(match.params.pair && match.params.pair !== '') && this.renderSideBar()}
                {isPairsLoaded ? (
                    (match.params.pair && match.params.pair === 'openOrders') ? (
                        (isAuthenticated) && (
                            <TradeMyOrders />
                        )
                    ) : (
                        <Content>
                            <Route path={`${this.props.match.url}/`} exact component={() => (
                                <React.Fragment>
                                    {(match.params.pair && match.params.pair !== '') ? this.renderPairContentView() : (
                                        <MarketPage
                                            onChangeSortDirection={this.onChangeSortDirection}
                                            sortDirection={this.state.sortDirection}
                                        />
                                    )}
                                </React.Fragment>
                            )} />

                            <Route path={`${this.props.match.url}/aboutBase`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={baseCoinConfigName} />
                                        <BackLink onClick={() => { history.push('/market/' + match.params.pair); }}>
                                            {baseCoinConfigName ? match.params.pair + ' Trading' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={baseCoinConfigName} />
                                            <FormattedMessage
                                                id="wallet.news"
                                                defaultMessage="{name}{ticker} Asset News"
                                                values={{
                                                    name: baseCoinConfigLabel ? baseCoinConfigLabel + ' ' : '',
                                                    ticker: baseCoinConfigName ? '(' + baseCoinConfigName + ')' : baseCoinConfigName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <AssetNews coin={baseCoinConfigName} />
                                </React.Fragment>
                            )} />

                            <Route path={`${this.props.match.url}/aboutQuote`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={quoteCoinConfigName} />
                                        <BackLink onClick={() => { history.push('/market/' + match.params.pair); }}>
                                            {quoteCoinConfigName ? match.params.pair + ' Trading' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={quoteCoinConfigName} />
                                            <FormattedMessage
                                                id="wallet.news"
                                                defaultMessage="{name}{ticker} Asset News"
                                                values={{
                                                    name: quoteCoinConfigLabel ? quoteCoinConfigLabel + ' ' : '',
                                                    ticker: quoteCoinConfigName ? '(' + quoteCoinConfigName + ')' : quoteCoinConfigName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <AssetNews coin={quoteCoinConfigName} />
                                </React.Fragment>
                            )} />

                        </Content>
                    )
                ) : (
                    <MainLoader />
                )}

            </StandardPage>
        );
    }

}
