import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from './Footer';

function mapStateToProps(state) {
    return {
        quickTradeId: state.transactions.transactionId,
        currencyFrom: state.transactions.currentPair.currencyFrom,
        currencyTo: state.transactions.currentPair.currencyTo,
        referralCode: state.referrals.referralCode
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);

