import { injectIntl } from 'react-intl';
import getSymbolFromCurrency from 'currency-symbol-map';

export function validatePriceInput(price, decimals) {
    if (decimals > 0) {
        const regex = new RegExp(`^\\d{1,8}\\.\\d{0,${decimals}}$`);
        return (price === '') || (price && (price.match(regex) || price.match(/^\d{0,8}$/)));
    }
    return (price === '') || (price && price.match(/^\d{0,8}$/));
}

export function validatePercentInput(price, decimals) {
    if (decimals > 0) {
        const regex = new RegExp(`^\\d{1,2}\\.\\d{0,${decimals}}$`);
        return (price === '') || (price && (price.match(regex) || price.match(/^\d{1,2}$/)));
    }
    return (price === '') || (price && price.match(/^\d{1,2}$/));
}

export function format(value, precision) {
    let divider = Math.pow(10, precision+1);
    return +(Math.floor(value * divider) / divider).toFixed(precision);
}

export function formatFloor(value, precision) {
    let divider = Math.pow(10, precision);
    return +(Math.floor(value * divider) / divider).toFixed(precision);
}

export function formatCeil(value, precision) {
    let divider = Math.pow(10, precision);
    return +(Math.ceil(value * divider) / divider).toFixed(precision);
}

export function displayFormat(value, precision) {
    if (value === undefined) value = 0;
    let divider = Math.pow(10, precision+1);
    return (Math.floor(value * divider) / divider).toFixed(precision);
}

export const DisplayFiat = injectIntl((props) => {
    const { currency, value, intl } = props;
    return getSymbolFromCurrency(currency) + intl.formatNumber(value, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' ' + currency;
});

export function countDecimals(value) {
    if ((value % 1) !== 0) {
        if (value.toString().includes('e')) {
            return convertScientificToString(value).split('.')[1].length;
        } else {
            return value.toString().split('.')[1].length;
        }
    }
    return 0;
}

export function convertScientificToString(n) {
    var sign = +n < 0 ? '-' : '', toStr = n.toString();
    if (!/e/i.test(toStr)) {
        return n;
    }
    var [lead,decimal,pow] = n.toString()
        .replace(/^-/,'')
        .replace(/^([0-9]+)(e.*)/,'$1.$2')
        .split(/e|\./);
    return +pow < 0
        ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
        : sign + lead + (+pow >= decimal.length ? (decimal + '0'.repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+'.'+decimal.slice(+pow)));
}

export function sigDigits(value, digits) {
    value = Number(value);
    if (typeof value !== 'undefined' && !isNaN(value)) {
        if (value.toPrecision(digits).includes('e')) {
            if (countDecimals(parseFloat(value.toPrecision(digits))) > 8) {
                return Number(format(parseFloat(value.toPrecision(digits)), 8));
            } else {
                return Number(parseFloat(value.toPrecision(digits)));
            }
        } else {
            if (countDecimals(value.toPrecision(digits)) > 8) {
                return Number(format(value.toPrecision(digits), 8));
            } else {
                return Number(value.toPrecision(digits));
            }
        }
    } else {
        return 0;
    }
}

export function generateQRString(addressObj, networkConfig, amount) {
    let address = '';
    let tag = '';
    let currency = '';
    if (addressObj) {
        address = addressObj.address;
        tag = addressObj.tag;
        currency = addressObj.asset;
    }

    let qrString = address;
    if (currency === 'XRP') {
        qrString = address + ':' + tag;
    }

    if (networkConfig) {
        if (networkConfig.qrFormat !== null) {
            qrString = networkConfig.qrFormat.replace('{ADDRESS}', address);
            if (tag && !isNaN(tag)) {
                qrString = qrString.replace('{TAG}', tag);
            } else {
                qrString = qrString.replace('{TAG}', '');
            }
            if (networkConfig.contractAddress && !isNaN(networkConfig.contractAddress)) {
                qrString = qrString.replace('{CONTRACT}', networkConfig.contractAddress);
            } else {
                qrString = qrString.replace('{CONTRACT}', '');
            }
            if (amount && !isNaN(amount) && amount > 0) {
                // calculate amount_long and amount_decimal
                qrString = qrString.replace('{AMOUNT_LONG}', Math.round(amount * Math.pow(10, networkConfig.decimals)).toString());
                qrString = qrString.replace('{AMOUNT_DECIMAL}', amount);
            } else {
                qrString = qrString.replace('{AMOUNT_LONG}', '');
                qrString = qrString.replace('{AMOUNT_DECIMAL}', '');
            }
        }
    }
    return qrString;
}

export function normalizePrice(side, price) {
    var decimals = calculateScaleUsingPrice(price);
    // console.log('price: ' + price);
    // console.log('decimals: ' + decimals);
    // console.log('formatFloor: ' + formatFloor(price, decimals));
    // console.log('formatCeil: ' + formatCeil(price, decimals));

    if (side === 'BUY') {
        return formatFloor(price, decimals);
    }
    return formatCeil(price, decimals);
}

// this MUST match the JAVA version in Parity::OrderBook
export function calculateScaleUsingPrice(price) {
    var decimals = 8;
    if (Number(price) >= 100000) decimals = 0;
    else if (Number(price) >= 10000) decimals = 2;
    else if (Number(price) >= 1000) decimals = 2;
    else if (Number(price) >= 100) decimals = 2;
    else if (Number(price) >= 10) decimals = 3;
    else if (Number(price) >= 1) decimals = 4;
    else if (Number(price) >= .1) decimals = 5;
    else if (Number(price) >= .01) decimals = 6;
    else if (Number(price) >= .001) decimals = 7;
    else if (Number(price) >= .0001) decimals = 8;
    return decimals;
}
