import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import lockedIcon from '../../images/ic-locked-select.png';

const LockedSelectIconImg = styled.img`
  margin-left: 3px;
  margin-right: 13px;
  vertical-align: middle;
  z-index: 2;
`;

const Label = styled.label`
  line-height: 24px;
  margin-top: 15px;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;

  + label {
    position: relative;
    cursor: pointer;
    padding: 0;

    &:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      background: white;
      border: 1px solid #cccccc;
      border-radius: 2px;
    }
  }
  
  &:checked + ${Label}:after {
    content: '';
    position: absolute;
    left: 8px;
    top: 10px;
    background: #021747;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 #021747,
      4px 0 0 #021747,
      4px -2px 0 #021747,
      4px -4px 0 #021747,
      4px -6px 0 #021747,
      4px -8px 0 #021747;
    transform: rotate(45deg);
  }
`;

const InputHidden = styled.div`
    display: none;
`;


export default class Checkbox extends React.Component {

    static propTypes = {
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
            PropTypes.object,
        ]),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        type: 'text'
    };

    render() {

        const { checked, disabled, onChange, label, ...props } = this.props;
        const id = Math.random();

        return disabled ? (
            <React.Fragment>
                <InputHidden id={id} disabled={disabled} checked={checked} onChange={onChange} {...props} type="checkbox"/>
                <LockedSelectIconImg src={'/' + lockedIcon}/>
                <Label htmlFor={id}>{label}</Label>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Input id={id} disabled={disabled} checked={checked} onChange={onChange} {...props} type="checkbox"/>
                <Label htmlFor={id}>{label}</Label>
            </React.Fragment>
        );
    }
}
