import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfileNotificationSettings from './ProfileNotificationSettings';
import {extendSession} from '../../../../actions/auth';

function mapStateToProps() {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            extendSession,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileNotificationSettings);
