import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TradePriceChart from './TradePriceChart';
import { clearCandlestickFeed, stopCandlestickFeed, startCandlestickFeed } from '../../../../actions/market';

function mapStateToProps(state) {
    return {
        candlesticksTv: state.market.candlesticksTv,
        volumeBarsTv: state.market.volumeBarsTv,
        lastCandlestickPrice: state.market.lastCandlestickPrice,
        candlestickInterval: state.market.candlestickInterval,
        lastCandlestick: state.market.lastCandlestick,
        lastVolumeBar: state.market.lastVolumeBar,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearCandlestickFeed,
            stopCandlestickFeed,
            startCandlestickFeed,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradePriceChart);
