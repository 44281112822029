import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { Line } from 'rc-progress';
import axios from 'axios';
import config from 'config';
import { Link } from 'react-router-dom';
import dateFns from 'date-fns';
import countryList from 'react-select-country-list';

import { Component as Button } from '../../../../components/Button';
import { Component as Input } from '../../../../components/Input';
import { Component as Select } from '../../../../components/Select';
import { Component as InputGroup } from '../../../../components/InputGroup';
import CountryRegionData from '../../../../../node_modules/country-region-data/data.json';
import { mobile } from '../../../../styles';
import BlocPalToolTip from '../../../../components/BlocPalToolTip/BlocPalToolTip';

import { findSelected, validateBirthdate } from '../../../../utils';
import { Title,
    Label,
    Value,
    BoldOrangeValue,
    ItemIncomplete,
    ItemFailed,
    ItemPending,
    ItemComplete,
    ItemText,
    ItemTextTitle
} from '../../../../commonStyles/AccountProfileStyles';

import cloudUploadIcon from '../../../../images/ic-cloud-upload.png';
import greenCheckImage from '../../../../images/ic-green-tick.svg';
import selfiePhoto from '../../../../images/selfiePhoto.png';
import {StyledSelect} from '../../../../commonStyles/FormStyles';
// import verificationPhotoOne from '../../../../images/verificationPhotoOne.png';
// import verificationPhotoTwo from '../../../../images/verificationPhotoTwo.png';


const GATEWAY = config.get('api.domain');
const outgoingEvents = config.get('api.outgoingEvents');


const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const SubTitle = styled.h2`
	color: #4A4A4A;
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;
	// margin-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-right: auto;
    text-align: left;

	a {
	  color: #ef8632;
	  text-decoration: none;
	}
`;

const GreenCheckImage = styled.span`
  img {
    text-align: center;
    margin-right: 10px;
    width: 15px;
    height: 15px;

    ${mobile`
      margin-right: 5px;
    `}
  }
`;

const ErrorMessage = styled.div`
  color: #ef8632;
`;

const ApplyTitle = styled.div`
    // text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 35px 0 15px 0;
`;

/*
const SquareImageDiv = styled.div`
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;

  ${mobile`
    // display: none;
  `}
`;

const SquareImage = styled.img`
  border:1px solid #92b0b3;
  width: 290px;

  ${mobile`
    width: 145px;
  `}
`;

const InfoBlock = styled.div`
    // display: flex;
    margin-bottom: 20px;
    padding: 0px 10px 10px 10px;
`;
*/

const Files = styled.div`
  position: relative;
  margin-top: 20px;
  width: 400px;

  ${mobile`
      width: 350px;
  `}

  label {
    position: absolute;
    white-space: nowrap;
    bottom: 15px;
    margin-left: 50px;
    
    ${mobile`
      display: none;
    `}
  }

  input {
    cursor: pointer;

    background-color: #F9F9F9;
    background-image: url(${'/' + cloudUploadIcon});
    background-size: 60px;
    background-repeat: no-repeat;
    border-radius: 5px;
    background-position: 50% 0%;
    
    // outline: 2px dashed #111111;
    // outline-offset: -10px;
    // -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    // transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 80px 0px;
    // padding: 120px 0px 85px 35%;
    // text-align: center !important;
    // margin: 0;
    width: 400px !important;

    ${mobile`
      padding: 70px 0 10px 0;
      width: 300px !important;
    `}

    ::file-selector-button {
      margin-left: 80px;
      margin-right: 10px;
      padding: 2px 25px;
      font-weight: bold;
      font-size: 16px;
      color: #021747;
      border-style: solid;
      border-width: 2px;
      border-color: #021747;
      background-color: white;
      border-radius: 7px;

      ${mobile`
        margin-left: 30px;
      `}
    }
  }

  input:focus{
      // outline: 2px dashed #92b0b3;
      border:2px solid black;
      // outline-offset: -10px;
      // transition: outline-offset .15s ease-in-out, background-color .15s linear;
      // -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  }

  //input:after {
  //  pointer-events: none;
  //  position: absolute;
  //  top: 60px;
  //  left: 0;
  //  width: 50px;
  //  right: 0;
  //  height: 56px;
  //  content: "";
  //  background-image: url(${'/' + cloudUploadIcon});
  //  display: block;
  //  margin: 0 auto;
  //  background-size: 100%;
  //  background-repeat: no-repeat;

    ${mobile`
      width: 300px;
      // display: none;
    `}
  }

  label:before {
    position: absolute;
    bottom: -5px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " (or Drag File Here) ";
    display: block;
    margin: 0 auto;
    color: #000;
    font-weight: 600;
    text-align: center;

    ${mobile`
      display: none;
    `}
`;

const ProgressDiv = styled.div`
  width: 400px;
  // display: flex;
  // flex: 0 0 auto;
  // flex-flow: row wrap;
  align-items: center;
  margin-bottom: 0;

  ${mobile`
      width: 300px;
  `}
`;

const UploadButton = styled(Button).attrs({
    bsStyle: 'orange'
})`
    text-align: center;
    display: block;
    width: 400px;
    margin-top: 15px;
    margin-bottom: 30px;

    a {
        display: inline-flex;
        padding: 18px 35px;
        width: 235px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }

  ${mobile`
      width: 300px;
  `}

`;

const SubmitTier1Button = styled(Button).attrs({bsStyle: 'orange'})`
    text-align: center;
    display: block;
    width: 350px;
    margin: 30px auto 30px 0px;

    a {
        // display: inline-flex;
        padding: 18px 35px;
        width: 235px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: bold;
        // align-items: center;
        // justify-content: space-between;
    }
`;

const ProceedVerificationButton = styled(Button).attrs({
    bsStyle: 'orange'
})`
    text-align: center;
    display: block;
    width: 350px;
    margin-top: 20px;
    margin-bottom: 30px;
    // margin-left: auto;
    // margin-right: auto;

    a {
        display: inline-flex;
        padding: 18px 35px;
        width: 235px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }
`;

const FlexRow = styled.div`
    // background-color: #BBBBBB;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // padding: 5px;
    justify-content: space-around;

    ${mobile`
      // broke the initial form layout!
      // flex-wrap: wrap;
    `}
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    // justify-content: center;
    align-items: center;
    width: 100%;
`;

const FlexItem = styled.div`
    // background-color: #AAAAAA;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
`;

const ItemTextGray = styled(ItemText)`
    color: ${props => props.theme.colors.primary6};
  
    b {
      color: #000;
    }
`;

/*
const ItemPending = styled(ItemNotice)`
    outline: 2px solid #00B64B;
    color: #00B64B;
    background-color: #E8F8ED;
`;

const ItemTextWhiteBox = styled.div`
    background-color: #ffffff;
    padding: 15px;
    width: 400px;
    margin-bottom: 20px;
    // margin-left: auto;
    // margin-right: auto;
  
    ${mobile`
      width: 100%;
      max-width: 300px;
    `}
`;
*/

const ItemTextGreyBox = styled.div`
    background-color: #F2F2F2;
    padding: 15px;
    width: 400px;
    margin-bottom: 20px;
    // margin-left: auto;
    // margin-right: auto;
    
    ${mobile`
      width: 100%;
      max-width: 350px;
    `}
`;

const ItemTextBold = styled.div`
    font-size: 14px;
    font-weight: bold;
`;

const ItemLinkBoldOrange = styled(ItemTextBold)`
  color: #ef8632;

  a {
    color: #ef8632;
  }
`;

const NarrowInputGroup = styled(InputGroup)`
    width: 25%;
`;

const FiftyPercentInputGroup = styled(InputGroup)`
  width: 50%;
  margin-right: auto;
`;

const WideInputGroup = styled(InputGroup)`
    width: 75%;
`;

const LeftInputGroup = styled(InputGroup)`
  width: 100%;
  margin-right: 150px;
`;

// mirrored in txq-ui:src/screens/ProfileScreen/components/ProfileAccountVerification/ProfileAccountVerification.js
// mirrored in txq-admin-ui:src/components/Users/Users.js
// mirrored in dwe-www:includes/fx.inc.php
// make sure any changes here get reflected everywhere ...
// keep it json friendly so we can use it in php, no apostrophes or extra trailing commas
// TODO: this should come from DWE list_kyc_occupations via frontend cache/endpoint
const occupationOptions = [
    {
        label: 'Agriculture, Maintenance, Repair, and Skilled Crafts',
        options: [
            { value: '1-1', label: 'Construction and Extraction (eg., Construction Laborer, Electrician)' },
            { value: '1-2', label: 'Farming, Fishing, and Forestry' },
            { value: '1-3', label: 'Installation, Maintenance, and Repair' },
            { value: '1-4', label: 'Production Line Occupation' },
            { value: '1-99', label: 'Other: Agriculture, Maintenance, Repair, and Skilled Crafts' }
        ]
    },
    {
        label: 'Architecture and Engineering',
        options: [
            { value: '2-1', label: 'Architect, Surveyor, or Cartographer' },
            { value: '2-2', label: 'Engineer' },
            { value: '2-99', label: 'Other: Architecture and Engineering' }
        ]
    },
    {
        label: 'Business, Executive, Management, and Financial',
        options: [
            { value: '3-1', label: 'Chief Executive' },
            { value: '3-2', label: 'General and Operations Manager' },
            { value: '3-3', label: 'Advertising, Marketing, Promotions, Public Relations, and Sales Manager' },
            { value: '3-4', label: 'Operations Specialties Manager (eg., IT or HR Manager)' },
            { value: '3-5', label: 'Construction Manager' },
            { value: '3-6', label: 'Engineering Manager' },
            { value: '3-7', label: 'Accountant, Auditor' },
            { value: '3-8', label: 'Investment Banker, Analyst' },
            { value: '3-9', label: 'Business Operations or Financial Specialist' },
            { value: '3-10', label: 'Business Owner' },
            { value: '3-11', label: 'Financial Clerk' },
            { value: '3-12', label: 'Financial Advisor, Planner' },
            { value: '3-99', label: 'Other: Business, Executive, Management, Financial' }
        ]
    },
    {
        label: 'Education, Training, and Library',
        options: [
            { value: '4-1', label: 'At Home Teacher or Instructor' },
            { value: '4-2', label: 'Librarian' },
            { value: '4-3', label: 'Occupational Onsite Teacher or Instructor' },
            { value: '4-4', label: 'Postsecondary Teacher or Professor' },
            { value: '4-5', label: 'Primary, Secondary, or Special Education School Teacher' },
            { value: '4-99', label: 'Other: Education, Training, and Library' }
        ]
    },
    {
        label: 'Healthcare Practitioners and Technical Occupations',
        options: [
            { value: '5-1', label: 'Chiropractor' },
            { value: '5-2', label: 'Dentist' },
            { value: '5-3', label: 'Dietitian or Nutritionist' },
            { value: '5-4', label: 'Emergency Responder' },
            { value: '5-5', label: 'Optometrist' },
            { value: '5-6', label: 'Pharmacist' },
            { value: '5-7', label: 'Physician' },
            { value: '5-8', label: 'Physician Assistant' },
            { value: '5-9', label: 'Podiatrist' },
            { value: '5-10', label: 'Registered Nurse' },
            { value: '5-11', label: 'Therapist' },
            { value: '5-12', label: 'Veterinarian' },
            { value: '5-13', label: 'Health Technologist or Technician' },
            { value: '5-99', label: 'Other: Healthcare Practioners' }
        ]
    },
    {
        label: 'Healthcare Support Occupations',
        options: [
            { value: '6-1', label: 'Nursing, Psychiatric, or Home Health Aide' },
            { value: '6-2', label: 'Occupational and Physical Therapist Assistant or Aide' },
            { value: '6-99', label: 'Other: Healthcare Support' }
        ]
    },
    {
        label: 'Legal and Police',
        options: [
            { value: '7-1', label: 'Court Officer, Clerk, or Reporter' },
            { value: '7-2', label: 'Corrections Officer' },
            { value: '7-3', label: 'Detective' },
            { value: '7-4', label: 'Judge' },
            { value: '7-5', label: 'Lawyer' },
            { value: '7-6', label: 'Legal Assistant' },
            { value: '7-7', label: 'Police Officer' },
            { value: '7-8', label: 'Probation Officer' },
            { value: '7-99', label: 'Other: Legal and Police' }
        ]
    },
    {
        label: 'Office and Administrative Support',
        options: [
            { value: '8-1', label: 'Supervisor of Administrative Support Workers' },
            { value: '8-2', label: 'Secretary or Administrative Assistant' },
            { value: '8-3', label: 'Material Recording, Scheduling, and Dispatching Worker' },
            { value: '8-99', label: 'Other: Office and Administrative Support' }
        ]
    },
    {
        label: 'Professional Services',
        options: [
            { value: '9-1', label: 'Private Investigator' },
            { value: '9-2', label: 'Protective Service, Firefighter, or Body Guard' },
            { value: '9-3', label: 'Chef or Head Cook' },
            { value: '9-4', label: 'Cook or Food Preparation Worker' },
            { value: '9-5', label: 'Food and Beverage Serving Worker (eg., Bartender, Waiter, Waitress)' },
            { value: '9-6', label: 'Building and Grounds Cleaning and Maintenance' },
            { value: '9-7', label: 'Personal Care and Service (eg., Hairdresser, Flight Attendant, Concierge)' },
            { value: '9-8', label: 'Sales Supervisor, Retail Sales' },
            { value: '9-9', label: 'Retail Sales Worker' },
            { value: '9-10', label: 'Insurance Agent' },
            { value: '9-11', label: 'Sales Representative' },
            { value: '9-12', label: 'Real Estate Agent' },
            { value: '9-99', label: 'Other: Services Occupation' }
        ]
    },
    {
        label: 'Transportation',
        options: [
            { value: '10-1', label: 'Aircraft Pilot or Flight Engineer' },
            { value: '10-2', label: 'Aircraft Ground Crew (eg., Baggage Handler or Air Traffic Control)' },
            { value: '10-3', label: 'Motor Vehicle Operator (eg., Ambulance, Bus, Taxi, or Truck Driver)' },
            { value: '10-4', label: 'Transportation Support Role (eg., Ticket or Travel Agent)' },
            { value: '10-99', label: 'Other: Transportation' }
        ]
    },
    {
        label: 'Other Professional Occupations',
        options: [
            { value: '11-1', label: 'Arts, Design, Entertainment, Sports, and Media' },
            { value: '11-2', label: 'Computer Specialist, Mathematical Science' },
            { value: '11-3', label: 'Counselor, Social Worker, or Other Community and Social Service Specialist' },
            { value: '11-4', label: 'Life Scientist (eg., Animal, Food, Soil, or Biological Scientist, Zoologist)' },
            { value: '11-5', label: 'Physical Scientist (eg., Astronomer, Physicist, Chemist, Hydrologist)' },
            { value: '11-6', label: 'Religious Worker (eg., Clergy, Director of Religious Activities or Education)' },
            { value: '11-7', label: 'Social Scientist and Related Worker' },
            { value: '11-99', label: 'Other: Professional Occupation' }
        ]
    },
    {
        label: 'Other Occupations',
        options: [
            { value: '99-1', label: 'No Occupation' },
            { value: '99-2', label: 'Homemaker' },
            { value: '99-3', label: 'Military, Enlisted' },
            { value: '99-4', label: 'Military, Officer' },
            { value: '99-99', label: 'Other Occupation' }
        ]
    }
];

const tier1FailedFileTypeOptions = [
    {
        label: 'Proof of Identity',
        options: [
            { value: 'PASSPORT', label: 'Passport' },
            { value: 'DRIVER_LICENSE', label: 'Driver\'s License' },
            { value: 'FEDERAL_IDENTITY', label: 'Federal Government Id' },
            { value: 'STATE_IDENTITY', label: 'State/Province Id' },
        ]
    },
    {
        label: 'Proof of Residence',
        options: [
            { value: 'UTILITY_BILL', label: 'Utility Bill' },
            { value: 'FEDERAL_OTHER', label: 'Federal Government Document' },
            { value: 'STATE_OTHER', label: 'State/Province Document' },
        ]
    },
    {
        label: 'Verification Photos',
        options: [
            { value: 'VERIFICATION_PHOTO_1', label: 'Selfie: BlocPalX + Date + Occupation' },
            // { value: 'VERIFICATION_PHOTO_2', label: 'Photo Two: TAX ID + OCCUPATION' },
        ]
    },
];

const tier2FileTypeOptions = [
    {
        label: 'Proof of Residence',
        options: [
            { value: 'UTILITY_BILL', label: 'Utility Bill' },
            { value: 'FEDERAL_OTHER', label: 'Federal Government Document' },
            { value: 'STATE_OTHER', label: 'State/Province Document' },
        ]
    },
];

const tier3FileTypeOptions = [
    {
        label: 'Accredited Investor Confirmation Form',
        options: [
            { value: 'FORM_45_106F9', label: 'BlocPal Form 45-106F9' },
        ]
    },
];

const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
];


export default class ProfileAccountVerification extends React.Component {

    static propTypes = {
        verificationUrlLoaded: PropTypes.func,
        getUploadedFileList: PropTypes.func,
        getVrfyInfo: PropTypes.func,
        submitUpdateVrfyInfo: PropTypes.func,
        user: PropTypes.object,
        verificationStatus: PropTypes.string,
        verificationAutoStatus: PropTypes.string,
        verificationUrl: PropTypes.string,

        intl: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);
        const { user } = props;

        this.countryOptions = countryList().getData();

        this.state = {
            countryOptions: this.countryOptions,
            selectedFileType: null,
            selectedFile: null,
            loaded: 0,
            error: '',
            birthdayError: '',
            fileInputKey: Date.now(),
            uploadResponse: null,
            vendorManualReview: null,
            vendorReviewResponse: null,
            vendorCheckStateLoop: false,
            firstName: (user.verificationData && user.verificationData.firstName != null) ? user.verificationData.firstName : null,
            lastName: (user.verificationData && user.verificationData.lastName != null) ? user.verificationData.lastName : null,
            year: (user.verificationData && user.verificationData.year != null) ? user.verificationData.year.toString() : null,
            month: (user.verificationData && user.verificationData.month != null) ? user.verificationData.month.toString() : null,
            day: (user.verificationData && user.verificationData.day != null) ? user.verificationData.day.toString() : null,
            mrz1: null,
            mrz2: null,
            taxIdNumber: (user.verificationData && user.verificationData.taxIdNumber != null) ? user.verificationData.taxIdNumber : null,
            occupation: (user.verificationData && user.verificationData.occupation != null) ? user.verificationData.occupation : null,
            street: (user.verificationData && user.verificationData.street != null) ? user.verificationData.street : null,
            unitNumber: (user.verificationData && user.verificationData.unitNumber != null) ? user.verificationData.unitNumber : null,
            city: (user.verificationData && user.verificationData.city != null) ? user.verificationData.city : null,
            region: (user.verificationData && user.verificationData.region != null) ? user.verificationData.region : null,
            postal: (user.verificationData && user.verificationData.postal != null) ? user.verificationData.postal : null,
            country: (user.verificationData && user.verificationData.country != null) ? user.verificationData.country : null,
        };
    }

    componentWillUnmount() {
        if (this.vendorStatusTimer) {
            clearTimeout(this.vendorStatusTimer);
        }
    }

    componentDidMount() {
        const { user, verificationAutoStatus, verificationUrl } = this.props;
        const { vendorCheckStateLoop } = this.state;

        if (!this.props.user.uploadedFiles) {
            this.props.getUploadedFileList();
        }
        if (!this.props.user.verificationData) {
            this.props.getVrfyInfo();
        }

        if (!vendorCheckStateLoop && user.kycTier === 'NONE' && verificationUrl && verificationUrl !== '') {
            if (verificationAutoStatus !== 'COMPLETED-FAIL' &&
                verificationAutoStatus !== 'COMPLETED-PASS') {
                // checking for manual review state
                this.onUpdateKycVendorStatus();
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { user, verificationAutoStatus, verificationUrl } = this.props;
        const { vendorCheckStateLoop } = this.state;

        if (!user.verificationData ||
            (user.refreshVerifyInfo !== prevProps.user.refreshVerifyInfo &&
                user.refreshVerifyInfo === true)) {

            this.props.getVrfyInfo();
        }

        if (!vendorCheckStateLoop && user.kycTier === 'NONE' && verificationUrl && verificationUrl !== '') {
            if (verificationAutoStatus !== 'COMPLETED-FAIL' &&
                verificationAutoStatus !== 'COMPLETED-PASS') {
                // checking for manual review state
                this.onUpdateKycVendorStatus();
            }
        }
    }

    validateMimeType = (event) => {
        //getting file object
        let files = event.target.files;
        // list allow mime type
        const types = ['image/tiff', 'image/bmp', 'application/pdf', 'image/svg+xml', 'image/png', 'image/jpeg', 'image/gif'];
        // loop access array
        for (let x = 0; x < files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                this.setState({
                    // error: files[x].type + ' is not a supported file type',
                    error: 'That is not a supported file type',
                });
                return false;
            }
        }
        return true;
    }

    validateFileSize = (event) => {
        let files = event.target.files;
        let size = 10240000;
        for (let x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                this.setState({
                    error: files[x].name + ' is too large. 10 MB maximum',
                });
                return false;
            }
        }
        return true;
    }

    onChange = event => {
        const { intl } = this.props;
        const { year, month, day } = this.state;

        let birthdayError = '';
        if (!validateBirthdate(event.target.name === 'year' ? event.target.value : year, month, event.target.name === 'day' ? event.target.value : day)) {
            birthdayError = intl.formatMessage({
                id: 'profile.validation.validBirthdayError',
                defaultMessage: 'Must be 18 or older'
            });
        }

        this.setState({
            [event.target.name]: event.target.value,
            birthdayError,
        });
    };

    onChangeOccupation = occupation => {
        this.setState({
            occupation: occupation.value,
        });
    };

    onChangeMonth = month => {
        const { intl } = this.props;
        const { year, day } = this.state;

        let birthdayError = '';
        if (!validateBirthdate(year, month.value, day)) {
            birthdayError = intl.formatMessage({
                id: 'profile.validation.validBirthdayError',
                defaultMessage: 'Must be 18 or older'
            });
        }

        this.setState({
            month: month.value,
            birthdayError,
        });

    };

    onChangeCountry = country => {
        this.setState({
            country: country.value,
            region: null,
        });
    };

    onChangeRegion = region => {
        this.setState({
            region: region.value,
        });
    };

    onFileChangeHandler = event => {
        let files = event.target.files;
        if (this.validateMimeType(event) && this.validateFileSize(event)) {
            // if return true allow to setState
            this.setState({
                selectedFile: files,
                loaded: 0,
                error: '',
            });
        }
    }

    setFileType(selectedFileType) {
        this.setState(
            { selectedFileType },
        );
    }

    onSelectFileType = selectedFileType => {
        this.setState(
            { selectedFileType },
        );
    };

    onProceedToVerification = () => {
        const { user, verificationUrl } = this.props;

        if (!user.verificationUrlLoaded) {
            // sets global user.verificationUrlLoaded = true
            this.props.verificationUrlLoaded();
        }

        // this pops the verification up in a new window/tab
        window.open(verificationUrl, '_blank');
    }

    onSubmitVrfyInfo = () => {
        const { user } = this.props;
        const { firstName, lastName, year, month, day, mrz1, mrz2, taxIdNumber, occupation, street, unitNumber, city, region, postal, country } = this.state;

        this.props.submitUpdateVrfyInfo(firstName, lastName, user.email, year, month, day, mrz1, mrz2, taxIdNumber, occupation, street, unitNumber, city, region, postal, country);
    }

    onSubmitFileUpload = () => {
        const token = sessionStorage.getItem('token');
        const { selectedFileType } = this.state;

        const data = new FormData();

        data.set('fileType', selectedFileType.value);
        data.set('token', token);

        for (let x = 0; x < this.state.selectedFile.length; x++) {
            data.append('file', this.state.selectedFile[x]);
        }

        axios.post(GATEWAY + '/' + outgoingEvents['REQUEST_FILE_UPLOAD'], data, {
            onUploadProgress: progressEvent => {
                this.setState({
                    loaded: ((progressEvent.loaded * 100) / progressEvent.total),
                });
            },
        }).then(res => { // then print response status
            // console.log(res);
            NotificationManager.success('Upload Successful');
            this.setState({
                uploadResponse: res,
                selectedFileType: null,
                selectedFile: null,
                loaded: 0,
                error: '',
                fileInputKey: Date.now(),
            });
            this.props.getUploadedFileList();
        }).catch(err => { // then print response status
            // console.log(err.response);
            this.setState({
                uploadResponse: err,
            });
            if (err.response && err.response.data && err.response.data.message) {
                NotificationManager.error(err.response.data.message, 'Upload Failed');
            } else if (err.response && err.response.statusText) {
                NotificationManager.error(err.response.statusText, 'Upload Failed');
            } else {
                NotificationManager.error(err.response.status, 'Upload Failed');
            }
        });
    }

    onUpdateKycVendorStatus = () => {
        const { user, verificationUrl } = this.props;

        this.setState({
            vendorCheckStateLoop: true,
        });

        if (user.verificationData && verificationUrl) {
            axios.get(verificationUrl + '&eval=true').then(res => {
                this.setState({
                    vendorReviewResponse: res,
                    vendorManualReview: false,
                });
                this.vendorStatusTimer = setTimeout(() => {
                    this.onUpdateKycVendorStatus();
                }, 11000);
            }).catch(err => {
                // console.log('err.response:', err.response);
                this.setState({
                    vendorReviewResponse: err,
                    vendorManualReview: true,
                });

                //  check for actual error ... do not surface notification
                //  when JSON errorCode == 'kyc_complete'
                if (err.response && err.response.data && err.response.data.message) {
                    if (err.response.data.errorCode && Array.isArray(err.response.data.errorCode) && err.response.data.errorCode.includes('kyc_complete')) {
                        clearTimeout(this.vendorStatusTimer);
                    } else {
                        NotificationManager.error(err.response.data.message, 'KYC Lookup Failed');
                    }
                } else if (err.response && err.response.statusText) {
                    NotificationManager.error(err.response.statusText, 'KYC Lookup Failed');
                } else if (err.response && err.response.status) {
                    NotificationManager.error(err.response.status, 'KYC Lookup Failed');
                } else {
                    NotificationManager.error('Network Error', 'KYC Lookup Failed');
                }
            });
        }
    }

    listRegions() {
        let response = '';
        CountryRegionData.forEach((i) => {
            // console.log(i);
            let count = 0;
            response = response + i.regions.map(({ name, shortCode }) => {
                count++;
                return '\n' + i.countryShortCode + '_' + ((shortCode) ? shortCode.replace('-', '') : 'QQ' + count) + '(Country.' + i.countryShortCode + ', "' + name + '")';
            });
            response = response + ',\n' + i.countryShortCode + '_QQ(Country.' + i.countryShortCode + ', "Unknown"),\n';
        });
        return response;
    }

    getRegions(country) {
        if (!country) {
            return [];
        }
        const region = CountryRegionData.find(({ countryShortCode }) => countryShortCode === country);

        if (!region || region.length === 0) {
            console.error('Error. Unknown country passed: ' + country + '. If you\'re passing a country shortcode, be sure to include countryValueType="short" on the RegionDropdown');
            return [];
        }

        let count = 0;
        return region.regions.map(({ name, shortCode }) => {
            count++;
            return { label: name, value: ((shortCode) ? shortCode.replace('-', '') : 'QQ' + count) };
        });
    }

    renderTierBenefits() {
        return (
            <React.Fragment>
                <ItemTextGray>
                    <b>Tier 1 </b>
                    <FormattedMessage
                        id="profile.validation.tier1description"
                        defaultMessage="Status enables withdrawals up to $1,000 USD total value per day"
                    />
                </ItemTextGray>
                <ItemTextGray>
                    <b>Tier 2 </b>
                    <FormattedMessage
                        id="profile.validation.tier2description"
                        defaultMessage="Status enables withdrawals up to $7,000 USD total value per day"
                    />
                </ItemTextGray>
                <ItemTextGray>
                    <b>Tier 3 </b>
                    <FormattedMessage
                        id="profile.validation.tier3description"
                        defaultMessage="Status enables trading of securities assets and is reserved for accredited investors"
                    />
                </ItemTextGray>
            </React.Fragment>
        );
    }

    renderJurisdictionMessage() {
        return (
            <React.Fragment>
                <ItemText>
                    <FormattedMessage
                        id="profile.validation.blockedJurisdictions"
                        defaultMessage="Please note that we are only serving customers in select jurisdictions at this time."
                    />
                </ItemText>

                <ItemText>
                    <FormattedHTMLMessage
                        id="profile.validation.corporateVerification"
                        defaultMessage="For corporate verifications and onboarding, please contact us via email: <u>info@blocpal.com</u>"
                    />
                </ItemText>
            </React.Fragment>
        );
    }


    render() {

        const { user, verificationStatus, verificationAutoStatus, verificationUrl, match, intl, history } = this.props;
        const { vendorManualReview, vendorReviewResponse, countryOptions, selectedFile, birthdayError, error, selectedFileType, loaded } = this.state;
        const { firstName, lastName, year, month, day, taxIdNumber, occupation, street, unitNumber, city, region, postal, country } = this.state;

        const regionOptions = this.getRegions((country) ? country : (user.verificationData && user.verificationData.country) ? user.verificationData.country : '');

        const formDisabled = !selectedFileType || (error !== '') || !selectedFile;

        let tier1SubmitReady = false;
        if (user.kycTier === 'NONE') {
            if (firstName != null &&
                lastName != null &&
                year != null && year.match(/^[0-9]{4}/) &&
                month != null && month.match(/^[0-9]{1,2}/) &&
                day != null && day.match(/^[0-9]{1,2}/) && day <= 31 &&
                validateBirthdate(year, month, day) &&
                occupation != null &&
                street != null &&
                city != null &&
                region != null &&
                postal != null &&
                country != null &&
                (country !== 'US' || (taxIdNumber != null && country === 'US'))) {

                tier1SubmitReady = true;
            }
        }

        let tier1VerificationFailed = false;
        if ((match && match.params.screen === 'tierOneManualVerification')
            || (user.kycTier === 'NONE'
                && user.verificationData
                && verificationAutoStatus
                && verificationAutoStatus === 'COMPLETED-FAIL')) {
            tier1VerificationFailed = true;
        }

        let tier1ReviewDenied = false;
        if (user.kycTier === 'NONE' && user.verificationData && user.verificationData.reviewed && !user.verificationData.verified) {
            tier1ReviewDenied = true;
        }

        let tier1VerificationReady = false;
        let tier1ReviewReady = false;
        if (user.kycTier === 'NONE') {
            if (user.verificationData &&
                user.verificationData.clientIpSet != null && user.verificationData.clientIpSet === true &&
                user.verificationData.firstName != null &&
                user.verificationData.lastName != null &&
                user.verificationData.year != null &&
                user.verificationData.month != null &&
                user.verificationData.day != null &&
                (user.verificationData.country !== 'US' || user.verificationData.taxIdNumber != null) &&
                user.verificationData.occupation != null &&
                user.verificationData.street != null &&
                user.verificationData.city != null &&
                user.verificationData.region != null &&
                user.verificationData.postal != null &&
                user.verificationData.country != null) {

                if (user.verificationData.verificationProvider != null &&
                    user.verificationData.verificationProvider === 'VOUCHED' &&
                    verificationUrl != null) {

                    if (verificationStatus === 'REVIEW' ||
                        verificationAutoStatus === 'COMPLETED-FAIL' ||
                        verificationAutoStatus === 'COMPLETED-PENDING' ||
                        tier1VerificationFailed ||
                        tier1ReviewDenied) {
                        tier1ReviewReady = true;
                    } else {
                        // console.log('verificationStatus', verificationStatus, 'verificationAutoStatus', verificationAutoStatus, 'tier1VerificationFailed', tier1VerificationFailed, 'tier1ReviewDenied', tier1ReviewDenied);
                        tier1VerificationReady = true;
                        // uncommenting this will cause the popup to load when the page loads, without clicking the button
                        // if (!user.verificationUrlLoaded) this.onProceedToVerification();
                    }
                }
            }
        }

        /*
         * Sync with:
         *    DWE method Validations::getIdentityDocumentTypesArray()
         *    DWE method Validations::getResidenceDocumentTypesArray()
         *    UI const ProfileAccountVerification::tier2FileTypeOptions
         *    ADMIN-UI method User::renderMissingTier2KYC()
         *    PLATFORM enum VerificationFileType
         */
        let tier2ReviewReady = false;
        let proofOfResidence = false;
        let govtPhotoId = false;
        let verificationPhoto1 = false;
        let verificationPhoto2 = true;
        if (user.kycTier === 'TIER1' || (user.kycTier === 'NONE' && (tier1ReviewDenied || tier1VerificationFailed))) {
            tier2ReviewReady = true;

            if (user.uploadedFiles && user.uploadedFiles.find(file => (file.reviewStatus === 'NEW_SUBMISSION' || file.reviewStatus === 'REVIEWED_APPROVED')
                && (file.fileType === 'UTILITY_BILL' || file.fileType === 'FEDERAL_OTHER' || file.fileType === 'STATE_OTHER'))) {
                proofOfResidence = true;
            }
            if (user.uploadedFiles && user.uploadedFiles.find(file => (file.reviewStatus === 'NEW_SUBMISSION' || file.reviewStatus === 'REVIEWED_APPROVED')
                && (file.fileType === 'PASSPORT' || file.fileType === 'FEDERAL_IDENTITY' || file.fileType === 'DRIVER_LICENSE' || file.fileType === 'STATE_IDENTITY'))) {
                govtPhotoId = true;
            }
            if (user.uploadedFiles && user.uploadedFiles.find(file => (file.reviewStatus === 'NEW_SUBMISSION' || file.reviewStatus === 'REVIEWED_APPROVED') && file.fileType === 'VERIFICATION_PHOTO_1')) {
                verificationPhoto1 = true;
            }
            if (user.uploadedFiles && user.uploadedFiles.find(file => (file.reviewStatus === 'NEW_SUBMISSION' || file.reviewStatus === 'REVIEWED_APPROVED') && file.fileType === 'VERIFICATION_PHOTO_2')) {
                verificationPhoto2 = true;
            }

            if (tier1ReviewDenied || tier1VerificationFailed) {
                if (!proofOfResidence || !govtPhotoId || !verificationPhoto1 || !verificationPhoto2) {
                    tier2ReviewReady = false;
                }
            } else {
                if (!proofOfResidence) {
                    tier2ReviewReady = false;
                }
            }
        }

        let tier3ReviewReady = false;
        if (user.kycTier === 'TIER2') {
            tier3ReviewReady = true;
            let signedInvestorForm = false;

            if (user.uploadedFiles && user.uploadedFiles.find(file => (file.reviewStatus === 'NEW_SUBMISSION' || file.reviewStatus === 'REVIEWED_APPROVED') && (file.fileType === 'FORM_45_106F9'))) {
                signedInvestorForm = true;
            }

            if (!signedInvestorForm) {
                tier3ReviewReady = false;
            }
        }

        let displayScreen = 'none';
        if (!match || (match && match.params && !match.params.screen) || (match && match.params && match.params.screen === 'tierOneManualVerification')) {
            // console.log('state', user.kycTier, vendorReviewResponse, vendorManualReview, verificationAutoStatus, tier1VerificationReady, tier1ReviewReady, tier1VerificationFailed, tier2ReviewReady, tier1ReviewDenied);
            if (user.kycTier === 'NONE') {
                if (tier1VerificationFailed && tier2ReviewReady) {
                    displayScreen = 'tierOneReviewReady';
                } else if (tier1VerificationFailed) {
                    displayScreen = 'tierOneVerificationFailed';
                } else if (tier1ReviewDenied) {
                    displayScreen = 'tierOneReviewDenied';
                    //if ((tier1VerificationReady || tier1ReviewReady) && !(user.uploadedFiles && user.uploadedFiles.length > 3)) {
                    //    notice = 'tierOneReviewDenied';
                    //    displayScreen = 'tierTwoFullApplication';
                    //}
                } else {
                    // console.log('tier1VerificationReady', tier1VerificationReady, 'tier1ReviewReady', tier1ReviewReady, 'vendorReviewResponse', vendorReviewResponse, 'vendorManualReview', vendorManualReview);
                    if (tier1VerificationReady) {
                        displayScreen = 'loadingScreen';
                        if (vendorReviewResponse) {
                            if (vendorManualReview) {
                                displayScreen = 'tierOneAutoReviewReady';
                            } else {
                                displayScreen = 'tierOneIdVerification';
                            }
                        }
                    } else if (tier1ReviewReady) {
                        displayScreen = 'tierOneAutoReviewReady';
                    } else {
                        displayScreen = 'tierOneApplication';
                    }
                }
            } else if (user.kycTier === 'TIER1') {
                if (tier2ReviewReady) {
                    displayScreen = 'tierTwoReviewReady';
                } else {
                    displayScreen = 'tierTwoApplication';
                }
            } else if (user.kycTier === 'TIER2') {
                if (tier3ReviewReady) {
                    displayScreen = 'tierThreeReviewReady';
                } else {
                    displayScreen = 'tierThreeApplication';
                }
            } else if (user.kycTier === 'TIER3') {
                displayScreen = 'tierThreeApproved';
            } else if (user.kycTier === 'CORPORATE') {
                displayScreen = 'tierCorporateApproved';
            }
        } else {
            displayScreen = match.params.screen;
        }

        // console.log('displayScreen', displayScreen);

        let notice = 'none';
        // if (displayScreen === 'tierOneManualVerification') {
        //    notice = 'tierOneVerificationFailed';
        //    displayScreen = 'tierTwoFullApplication';
        if (displayScreen === 'tierOneVerificationFailed') {
            notice = 'tierOneVerificationFailed';
            displayScreen = 'tierTwoFullApplication';
        } else if (displayScreen === 'tierOneAutoReviewReady') {
            notice = 'tierOneInReview';
            displayScreen = 'none';
        } else if (displayScreen === 'tierOneReviewReady') {
            notice = 'tierOneInReview';
            displayScreen = 'tierTwoFullApplication';
        } else if (displayScreen === 'tierOneReviewDenied') {
            notice = 'tierOneReviewDenied';
            displayScreen = 'tierTwoFullApplication';
        } else if (displayScreen === 'tierTwoReviewReady') {
            notice = 'tierTwoReviewReady';
            displayScreen = 'tierTwoResidenceApplication';
        } else if (displayScreen === 'tierTwoApplication') {
            notice = 'tierTwoApplicationStatus';
            displayScreen = 'tierTwoResidenceApplication';
        } else if (displayScreen === 'tierThreeReviewReady') {
            notice = 'tierThreeReviewReady';
            displayScreen = 'tierThreeApplication';
        } else if (displayScreen === 'tierThreeApplication') {
            notice = 'tierThreeApplicationStatus';
            displayScreen = 'tierThreeApplication';
        }

        if (displayScreen === 'tierThreeApplication' && tier3FileTypeOptions[0].options.length === 1 && selectedFileType === null) {
            this.setFileType(tier3FileTypeOptions[0].options[0]);
        }

        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="profile.title_account_verification"
                        defaultMessage="Account Verification"
                    />
                </Title>

                <Label>
                    Current Account Tier
                    {user.kycTier === 'NONE' && (
                        <Value>
                            None<br />
                        </Value>
                    )}
                    {user.kycTier === 'TIER1' && (
                        <BoldOrangeValue>
                            Tier 1<br />
                        </BoldOrangeValue>
                    )}
                    {user.kycTier === 'TIER2' && (
                        <BoldOrangeValue>
                            Tier 2<br />
                        </BoldOrangeValue>
                    )}
                    {user.kycTier === 'TIER3' && (
                        <BoldOrangeValue>
                            Tier 3<br />
                        </BoldOrangeValue>
                    )}
                    {user.kycTier === 'CORPORATE' && (
                        <BoldOrangeValue>
                            Corporate<br />
                        </BoldOrangeValue>
                    )}
                </Label>

                {(displayScreen === 'loadingScreen') && (
                    <React.Fragment>
                        <FormattedMessage
                            id="profile.loading"
                            defaultMessage="Loading..."
                        />
                    </React.Fragment>
                )}

                {(notice === 'tierOneVerificationFailed' || notice === 'tierOneReviewDenied') && (
                    (govtPhotoId || proofOfResidence || verificationPhoto1) ? (
                        <ItemIncomplete>
                            <ItemText>
                                <ItemTextTitle>
                                    <FormattedMessage
                                        id="profile.validation.tier2ApplicationIncomplete"
                                        defaultMessage="Manual Verification Application Incomplete"
                                    />
                                </ItemTextTitle>
                                <ItemText>
                                    Please continue your application and upload the following missing documents
                                </ItemText>
                                {(!govtPhotoId) && (
                                    <ItemTextBold>Proof of Identity</ItemTextBold>
                                )}
                                {(!proofOfResidence) && (
                                    <ItemTextBold>Proof of Residence</ItemTextBold>
                                )}
                                {(!verificationPhoto1) && (
                                    <ItemTextBold>Selfie with Signature</ItemTextBold>
                                )}
                            </ItemText>
                        </ItemIncomplete>
                    ) : (
                        <React.Fragment>
                            {(notice === 'tierOneReviewDenied') && (
                                <ItemFailed>
                                    <ItemText>
                                        <ItemTextTitle>
                                            <FormattedMessage
                                                id="profile.validation.tier1DeniedTitle"
                                                defaultMessage="Tier 1 Application Failed"
                                            />
                                        </ItemTextTitle>
                                        <ItemText>
                                            {(user && user.verificationData && user.verificationData.reviewedComment) ? user.verificationData.reviewedComment : 'It looks like the automated verification failed.'}
                                            <br/>
                                            Please complete the manual verification process below
                                        </ItemText>
                                    </ItemText>
                                </ItemFailed>
                            )}
                            {(notice === 'tierOneVerificationFailed') && (
                                <ItemFailed>
                                    <ItemText>
                                        <ItemTextTitle>
                                            <FormattedMessage
                                                id="profile.validation.tier1AutoVerifyFailedTitle"
                                                defaultMessage="Manual Identity Verification"
                                            />
                                        </ItemTextTitle>
                                        <ItemTextBold>
                                            If you wish to verify, manual verification may be requested
                                        </ItemTextBold>
                                    </ItemText>
                                </ItemFailed>
                            )}
                        </React.Fragment>
                    )
                )}

                {(displayScreen === 'tierOneIdVerification') && (
                    <React.Fragment>
                        <ItemTextGreyBox>
                            <ItemTextTitle>
                                <FormattedMessage
                                    id="profile.validation.tier1VerificationTitle"
                                    defaultMessage="Complete Your Tier 1 Application - ID Required"
                                />
                            </ItemTextTitle>
                            <ItemText>
                                <FormattedMessage
                                    id="profile.validation.tier1VerificationText"
                                    defaultMessage="Thanks for creating your profile - Next you'll need to provide a photo of a valid government ID and snap a selfie to verify your identity. Our automated verification is the fastest way to apply."
                                    itemTag="p"
                                />
                            </ItemText>
                        </ItemTextGreyBox>
                        <ProceedVerificationButton onClick={this.onProceedToVerification} disabled={!tier1VerificationReady}>
                            <FormattedMessage
                                id="profile.verification.continueToAutomatedVerification"
                                defaultMessage="Continue To Automated Verification"
                            />
                        </ProceedVerificationButton>

                        <ItemTextGreyBox>
                            <ItemTextTitle>
                                <FormattedMessage
                                    id="profile.validation.tier1ManualVerificationTitle"
                                    defaultMessage="Already Tried Automated Verification?"
                                />
                            </ItemTextTitle>
                            <ItemText>
                                <FormattedMessage
                                    id="profile.validation.tier1ManualVerificationText"
                                    defaultMessage="Automated AI verification doesn't work for all ID's. If you've tried the automated verification and it's not working for you, you can attempt a manual verification."
                                    itemTag="p"
                                />
                            </ItemText>
                        </ItemTextGreyBox>

                        <ProceedVerificationButton onClick={() => { history.push('/account/profile/verification/tierOneManualVerification'); }}>
                            <FormattedMessage
                                id="profile.verification.continueToManualVerification"
                                defaultMessage="Continue To Manual Verification"
                            />
                        </ProceedVerificationButton>
                    </React.Fragment>
                )}

                {(notice === 'tierOneInReview') && (
                    <ItemPending>
                        <ItemText>
                            <ItemTextTitle>
                                <FormattedMessage
                                    id="profile.validation.tier1PendingReviewTitle"
                                    defaultMessage="Application Pending Review"
                                />
                            </ItemTextTitle>
                            <ItemTextBold>
                                <FormattedMessage
                                    id="profile.validation.tier1PendingText"
                                    defaultMessage="All required information has been submitted. We will now review your application."
                                />
                            </ItemTextBold>
                        </ItemText>
                    </ItemPending>
                )}

                {(displayScreen === 'tierOneApplication' && user.verificationData != null) && (
                    <React.Fragment>
                        <ApplyTitle>
                            <FormattedMessage
                                id="profile.validation.tier1ApplyTitle"
                                defaultMessage="Apply For Tier 1 Status"
                            />
                        </ApplyTitle>

                        <FlexRow>
                            <FlexColumn>
                                <SubTitle>
                                    <FormattedMessage
                                        id="profile.title_identity"
                                        defaultMessage="Your Identity"
                                    />
                                    <BlocPalToolTip tooltipId='profile.whyIdentify_tooltip'>
                                        <FormattedMessage
                                            id="profile.whyIdentify_tooltip"
                                            defaultMessage="Why are we asking for this info? We comply with government requirements to verify your identity and we only collect necessary information"
                                        />
                                    </BlocPalToolTip>
                                </SubTitle>

                                <FlexRow>
                                    <InputGroup>
                                        <FormattedMessage
                                            id="profile.validation.firstNameTitle"
                                            defaultMessage="First Name"
                                            tagName="label"
                                        />
                                        <Input
                                            name="firstName"
                                            placeholder={
                                                intl.formatMessage({
                                                    id: 'profile.validation.firstNameExample',
                                                    defaultMessage: 'Robert'
                                                })
                                            }
                                            type="text"
                                            value={(firstName != null) ? firstName : (user.verificationData && user.verificationData.firstName) ? user.verificationData.firstName : ''}
                                            onChange={this.onChange}
                                            disabled={tier1VerificationReady}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <FormattedMessage
                                            id="profile.validation.lastNameTitle"
                                            defaultMessage="Last Name"
                                            tagName="label"
                                        />
                                        <Input
                                            name="lastName"
                                            placeholder={
                                                intl.formatMessage({
                                                    id: 'profile.validation.lastNameExample',
                                                    defaultMessage: 'Smith'
                                                })
                                            }
                                            type="text"
                                            value={(lastName != null) ? lastName : (user.verificationData && user.verificationData.lastName) ? user.verificationData.lastName : ''}
                                            onChange={this.onChange}
                                            disabled={tier1VerificationReady}
                                        />
                                    </InputGroup>
                                </FlexRow>

                                <FlexRow>
                                    <FiftyPercentInputGroup>
                                        <FormattedMessage
                                            id="profile.validation.monthTitle"
                                            defaultMessage="Birthday: Month"
                                            tagName="label"
                                        />
                                        <Select
                                            placeholder={
                                                intl.formatMessage({
                                                    id: 'profile.validation.monthExample',
                                                    defaultMessage: 'Month'
                                                })
                                            }
                                            value={monthOptions.find(({ value }) => value === ((month) ? month : (user.verificationData && user.verificationData.month) ? user.verificationData.month : ''))}
                                            onChange={this.onChangeMonth}
                                            options={monthOptions}
                                            isDisabled={tier1VerificationReady}
                                        />
                                    </FiftyPercentInputGroup>
                                    <NarrowInputGroup>
                                        <FormattedMessage
                                            id="profile.validation.dayTitle"
                                            defaultMessage="Day"
                                            tagName="label"
                                        />
                                        <Input
                                            name="day"
                                            placeholder={
                                                intl.formatMessage({
                                                    id: 'profile.validation.dayExample',
                                                    defaultMessage: 'DD'
                                                })
                                            }
                                            type="text"
                                            value={(day != null) ? day : (user.verificationData && user.verificationData.day) ? user.verificationData.day : ''}
                                            onChange={this.onChange}
                                            disabled={tier1VerificationReady}
                                        />
                                    </NarrowInputGroup>
                                    <NarrowInputGroup>
                                        <FormattedMessage
                                            id="profile.validation.yearTitle"
                                            defaultMessage="Year"
                                            tagName="label"
                                        />
                                        <Input
                                            name="year"
                                            placeholder={
                                                intl.formatMessage({
                                                    id: 'profile.validation.yearExample',
                                                    defaultMessage: 'YYYY'
                                                })
                                            }
                                            type="text"
                                            value={(year != null) ? year : (user.verificationData && user.verificationData.year) ? user.verificationData.year : ''}
                                            onChange={this.onChange}
                                            disabled={tier1VerificationReady}
                                        />
                                    </NarrowInputGroup>
                                </FlexRow>

                                {(birthdayError !== '') && (
                                    <ErrorMessage>{birthdayError}</ErrorMessage>
                                )}

                                <FlexRow>
                                    <InputGroup>
                                        <FormattedMessage
                                            id="profile.validation.occupationTitle"
                                            defaultMessage="Occupation"
                                            tagName="label"
                                        />
                                        <StyledSelect
                                            placeholder={
                                                intl.formatMessage({
                                                    id: 'profile.validation.occupationExample',
                                                    defaultMessage: 'Type or Select Your Occupation'
                                                })
                                            }
                                            value={findSelected(occupationOptions, (occupation) ? occupation : (user.verificationData && user.verificationData.occupation) ? user.verificationData.occupation : '')}
                                            onChange={this.onChangeOccupation}
                                            options={occupationOptions}
                                            isDisabled={tier1VerificationReady}
                                        />
                                    </InputGroup>
                                </FlexRow>

                                <SubTitle>
                                    <FormattedMessage
                                        id="profile.title_residence"
                                        defaultMessage="Your Residence"
                                    />
                                </SubTitle>

                                <FlexRow>
                                    <InputGroup>
                                        <FormattedMessage
                                            id="profile.validation.countryTitle"
                                            defaultMessage="Country{tooltip}"
                                            tagName="label"
                                            values={{
                                                tooltip: (
                                                    <BlocPalToolTip tooltipId='profile.countryLimitations_tooltip'>
                                                        <FormattedMessage
                                                            id="profile.countryLimitations_tooltip"
                                                            defaultMessage="We are currently unable to serve customers from the countries of Iran or North Korea, or the US states of Alabama, Alaska, Connecticut, Georgia, Hawaii, New Mexico, North Carolina, New York, Vermont, or Washington"
                                                        />
                                                    </BlocPalToolTip>
                                                )
                                            }}

                                        />

                                        <StyledSelect
                                            placeholder={
                                                intl.formatMessage({
                                                    id: 'profile.validation.countryExample',
                                                    defaultMessage: 'Type or Select Your Country'
                                                })
                                            }
                                            value={countryOptions.find(({ value }) => value === ((country) ? country : (user.verificationData && user.verificationData.country) ? user.verificationData.country : ''))}
                                            onChange={this.onChangeCountry}
                                            options={countryOptions}
                                            isDisabled={tier1VerificationReady}
                                        />
                                    </InputGroup>
                                </FlexRow>

                                {(country || (user.verificationData && user.verificationData.country)) && (
                                    <React.Fragment>
                                        <FlexRow>
                                            <InputGroup>
                                                <FormattedMessage
                                                    id="profile.validation.regionTitle"
                                                    defaultMessage="State/Province/Region"
                                                    tagName="label"
                                                />
                                                <StyledSelect
                                                    placeholder={
                                                        intl.formatMessage({
                                                            id: 'profile.validation.regionExample',
                                                            defaultMessage: 'Type or Select Your Region'
                                                        })
                                                    }
                                                    value={regionOptions.find(({ value }) => value === ((region) ? region : (user.verificationData && user.verificationData.region) ? user.verificationData.region : ''))}
                                                    onChange={this.onChangeRegion}
                                                    options={regionOptions}
                                                    isDisabled={tier1VerificationReady}
                                                />
                                            </InputGroup>
                                        </FlexRow>

                                        {(region || (user.verificationData && user.verificationData.region)) && (
                                            <React.Fragment>
                                                <FlexRow>
                                                    <WideInputGroup>
                                                        <FormattedMessage
                                                            id="profile.validation.streetTitle"
                                                            defaultMessage="Street Address"
                                                            tagName="label"
                                                        />
                                                        <Input
                                                            name="street"
                                                            placeholder={
                                                                intl.formatMessage({
                                                                    id: 'profile.validation.streetExample',
                                                                    defaultMessage: '1100 W Maple Ave'
                                                                })
                                                            }
                                                            type="text"
                                                            value={(street != null) ? street : (user.verificationData && user.verificationData.street) ? user.verificationData.street : ''}
                                                            onChange={this.onChange}
                                                            disabled={tier1VerificationReady}
                                                        />
                                                    </WideInputGroup>
                                                    <NarrowInputGroup>
                                                        <FormattedMessage
                                                            id="profile.validation.unitNumberTitle"
                                                            defaultMessage="Apt/Unit"
                                                            tagName="label"
                                                        />
                                                        <Input
                                                            name="unitNumber"
                                                            placeholder=''
                                                            type="text"
                                                            value={(unitNumber != null) ? unitNumber : (user.verificationData && user.verificationData.unitNumber) ? user.verificationData.unitNumber : ''}
                                                            onChange={this.onChange}
                                                            disabled={tier1VerificationReady}
                                                        />
                                                    </NarrowInputGroup>
                                                </FlexRow>

                                                <FlexRow>
                                                    <WideInputGroup>
                                                        <FormattedMessage
                                                            id="profile.validation.cityTitle"
                                                            defaultMessage="City"
                                                            tagName="label"
                                                        />
                                                        <Input
                                                            name="city"
                                                            placeholder={
                                                                intl.formatMessage({
                                                                    id: 'profile.validation.cityExample',
                                                                    defaultMessage: 'Atlantis'
                                                                })
                                                            }
                                                            type="text"
                                                            value={(city != null) ? city : (user.verificationData && user.verificationData.city) ? user.verificationData.city : ''}
                                                            onChange={this.onChange}
                                                            disabled={tier1VerificationReady}
                                                        />
                                                    </WideInputGroup>

                                                    <NarrowInputGroup>
                                                        <label>
                                                            {(country === 'US') ?
                                                                intl.formatMessage({
                                                                    id: 'profile.validation.zipTitle',
                                                                    defaultMessage: 'Zip Code'
                                                                }) :
                                                                intl.formatMessage({
                                                                    id: 'profile.validation.postalTitle',
                                                                    defaultMessage: 'Postal Code'
                                                                })
                                                            }
                                                        </label>
                                                        <Input
                                                            name="postal"
                                                            placeholder={(country === 'US') ?
                                                                intl.formatMessage({
                                                                    id: 'profile.validation.zipExample',
                                                                    defaultMessage: '98765'
                                                                }) :
                                                                intl.formatMessage({
                                                                    id: 'profile.validation.postalExample',
                                                                    defaultMessage: 'H0H 0H0'
                                                                })
                                                            }
                                                            type="text"
                                                            value={(postal != null) ? postal : (user.verificationData && user.verificationData.postal) ? user.verificationData.postal : ''}
                                                            onChange={this.onChange}
                                                            disabled={tier1VerificationReady}
                                                        />
                                                    </NarrowInputGroup>
                                                </FlexRow>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}

                                {(occupation || (user.verificationData && user.verificationData.occupation)) && ((country && country === 'US') || ((user.verificationData && user.verificationData.country && user.verificationData.country === 'US'))) && (
                                    <React.Fragment>
                                        <SubTitle>
                                            <FormattedMessage
                                                id="profile.title_tax_id"
                                                defaultMessage="Your Tax Id"
                                            />
                                        </SubTitle>
                                        <FlexRow>
                                            <LeftInputGroup>
                                                <FormattedMessage
                                                    id="profile.validation.socialSecurityTitle"
                                                    defaultMessage="Social Security Number{tooltip}"
                                                    tagName="label"
                                                    values={{
                                                        tooltip: (
                                                            <BlocPalToolTip tooltipId='profile.dataEncryption_tooltip'>
                                                                <FormattedMessage
                                                                    id="profile.dataEncryption_tooltip"
                                                                    defaultMessage="Just like opening a bank account or brokerage account - regulators require we collect this. Your sensitive data is always stored on our servers using encryption"
                                                                />
                                                            </BlocPalToolTip>
                                                        )
                                                    }}
                                                />
                                                <FormattedMessage
                                                    id="profile.validation.requiredForUS"
                                                    defaultMessage="(required for US residents)"
                                                    tagName="label"
                                                />
                                                <Input
                                                    name="taxIdNumber"
                                                    placeholder={
                                                        intl.formatMessage({
                                                            id: 'profile.validation.taxIdNumberExample',
                                                            defaultMessage: '000-00-0000'
                                                        })
                                                    }
                                                    type="text"
                                                    value={(taxIdNumber != null) ? taxIdNumber : (user.verificationData && user.verificationData.taxIdNumber) ? user.verificationData.taxIdNumber : ''}
                                                    onChange={this.onChange}
                                                    disabled={tier1VerificationReady}
                                                />
                                            </LeftInputGroup>
                                        </FlexRow>
                                    </React.Fragment>
                                )}

                                <SubmitTier1Button onClick={this.onSubmitVrfyInfo} disabled={!tier1SubmitReady}>
                                    <FormattedMessage
                                        id="profile.verification.submitTier1"
                                        defaultMessage="Send & Continue"
                                    />
                                </SubmitTier1Button>
                            </FlexColumn>
                            <FlexItem/>
                        </FlexRow>
                    </React.Fragment>
                )}

                {(notice === 'tierTwoReviewReady') && (
                    <ItemPending>
                        <ItemTextTitle>
                            <FormattedMessage
                                id="profile.validation.tier2PendingTitle"
                                defaultMessage="Tier 2 Application Pending Review"
                            />
                        </ItemTextTitle>
                        <ItemTextBold>
                            <FormattedMessage
                                id="profile.validation.tier2PendingText"
                                defaultMessage="All required information has been submitted. We will now review your application."
                            />
                        </ItemTextBold>
                    </ItemPending>
                )}

                {(displayScreen === 'tierTwoFullApplication' || displayScreen === 'tierTwoResidenceApplication') && (
                    (user.uploadedFiles == null) ? (
                        <FormattedMessage
                            id="profile.loading"
                            defaultMessage="Loading..."
                        />
                    ) : (
                        <React.Fragment>
                            {(user.uploadedFiles && user.uploadedFiles.map(file => file.reviewStatus === 'REVIEWED_DENIED' && !user.uploadedFiles.find(checkFile => (checkFile.reviewStatus === 'NEW_SUBMISSION' || checkFile.reviewStatus === 'REVIEWED_APPROVED') && checkFile.fileType === file.fileType) && (
                                (file.fileType === 'PASSPORT' || file.fileType === 'FEDERAL_IDENTITY' || file.fileType === 'DRIVER_LICENSE' || file.fileType === 'STATE_IDENTITY'
                                    || file.fileType === 'UTILITY_BILL' || file.fileType === 'FEDERAL_OTHER' || file.fileType === 'STATE_OTHER'
                                    || file.fileType === 'VERIFICATION_PHOTO_1' || file.fileType === 'VERIFICATION_PHOTO_2') && (
                                    <FlexRow key={file.id}>
                                        <ItemFailed>
                                            <ItemText>
                                                {(file.fileType === 'PASSPORT' || file.fileType === 'FEDERAL_IDENTITY' || file.fileType === 'DRIVER_LICENSE' || file.fileType === 'STATE_IDENTITY') && (
                                                    <ItemTextTitle>Proof of Identity: {file.fileType.replaceAll('_', ' ')}</ItemTextTitle>
                                                )}
                                                {(file.fileType === 'UTILITY_BILL' || file.fileType === 'FEDERAL_OTHER' || file.fileType === 'STATE_OTHER') && (
                                                    <ItemTextTitle>Proof of Residence: {file.fileType.replaceAll('_', ' ')}</ItemTextTitle>
                                                )}
                                                {(file.fileType === 'VERIFICATION_PHOTO_1' || file.fileType === 'VERIFICATION_PHOTO_2') && (
                                                    <ItemTextTitle>Verification Photos: {file.fileType.replaceAll('_', ' ')}</ItemTextTitle>
                                                )}
                                                <ItemText>Reviewed: {dateFns.format(new Date(file.reviewTs), 'YYYY-MM-DD HH:mm')}</ItemText>
                                                <br />
                                                <ItemTextBold>Issue: {file.reviewComment}</ItemTextBold>
                                                <br />
                                                <ItemText>Please address the issue identified and resubmit your document</ItemText>
                                            </ItemText>
                                        </ItemFailed>
                                    </FlexRow>
                                )
                            )))}
                            {(displayScreen === 'tierTwoResidenceApplication') ? (
                                <React.Fragment>
                                    {this.renderTierBenefits()}

                                    <ApplyTitle>
                                        <FormattedMessage
                                            id="profile.validation.tier2ApplyTitle"
                                            defaultMessage="Apply for Tier 2 Status"
                                        />
                                        <BlocPalToolTip tooltipId='profile.whyTier2_tooltip'>
                                            <FormattedMessage
                                                id="profile.whyTier2_tooltip"
                                                defaultMessage="Verification is similar to opening a bank account - to comply with all government regulations we are required to collect all of the same information a bank would collect"
                                            />
                                        </BlocPalToolTip>
                                    </ApplyTitle>
                                    <ItemTextBold>
                                        <FormattedMessage
                                            id="profile.validation.tier2ApplySubTitleResidence"
                                            defaultMessage="Proof of Residence is required to achieve Tier 2 Status"
                                        />
                                    </ItemTextBold>
                                    <ItemText>
                                        <FormattedMessage
                                            id="profile.validation.tier2ApplyDescriptionResidence"
                                            defaultMessage="Accepted documents include utility bill OR tax document OR government document and must show your name, address, and a date of no more than 90 days ago. Please ensure uploads are high quality and easily legible"
                                        />
                                    </ItemText>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <ApplyTitle>
                                        <FormattedMessage
                                            id="profile.validation.tier2ManualApplyTitle"
                                            defaultMessage="Manual Verification Application"
                                        />
                                        <BlocPalToolTip tooltipId='profile.whyTier2_tooltip'>
                                            <FormattedMessage
                                                id="profile.whyTier2_tooltip"
                                                defaultMessage="Verification is similar to opening a bank account - to comply with all government regulations we are required to collect all of the same information a bank would collect"
                                            />
                                        </BlocPalToolTip>
                                    </ApplyTitle>

                                    <ItemTextBold>
                                        <FormattedMessage
                                            id="profile.validation.tier2ManualInstructions"
                                            defaultMessage="Please upload the following 3 documents to complete the manual verification process"
                                        />
                                    </ItemTextBold>

                                    <ItemText>
                                        {(govtPhotoId) && (<GreenCheckImage><img src={'/' + greenCheckImage} alt="" /></GreenCheckImage>)}
                                        1. &nbsp;
                                        <FormattedMessage
                                            id="profile.validation.tier2IdentityTitle"
                                            defaultMessage="Proof of Identity: "
                                            tagName="b"
                                        />
                                        &nbsp;
                                        <FormattedMessage
                                            id="profile.validation.tier2IdentityDescription"
                                            defaultMessage="Driver's license OR passport OR other government photo ID. Must be current - not expired"
                                        />
                                    </ItemText>
                                    <ItemText>
                                        {(proofOfResidence) && (<GreenCheckImage><img src={'/' + greenCheckImage} alt="" /></GreenCheckImage>)}
                                        2. &nbsp;
                                        <FormattedMessage
                                            id="profile.validation.tier2ResidenceTitle"
                                            defaultMessage="Proof of Residence: "
                                            tagName="b"
                                        />
                                        &nbsp;
                                        <FormattedMessage
                                            id="profile.validation.tier2ResidenceDescription"
                                            defaultMessage="Utility bill OR tax document OR government document and must show your name, address, and a date of no more than 90 days ago"
                                        />
                                    </ItemText>
                                    <ItemText>
                                        {(verificationPhoto1) && (<GreenCheckImage><img src={'/' + greenCheckImage} alt="" /></GreenCheckImage>)}
                                        3. &nbsp;
                                        <FormattedMessage
                                            id="profile.validation.tier2SelfieTitle"
                                            defaultMessage="Selfie + Sign: "
                                            tagName="b"
                                        />
                                        &nbsp;
                                        <FormattedMessage
                                            id="profile.validation.tier2SelfieDescription"
                                            defaultMessage="Upload a selfie of you holding a piece of paper with 'BlocPalX' written across the top, today's date, and your current occupation written on it. Please ensure your face and your piece of paper are fully visible."
                                        />
                                        <BlocPalToolTip tooltipId='profile.selfiePhoto_tooltip'>
                                            <img src={'/' + selfiePhoto} alt="" height="250" width="250"/>
                                        </BlocPalToolTip>
                                    </ItemText>

                                    <ItemText>
                                        <FormattedMessage
                                            id="profile.validation.tier2ApplyQuality"
                                            defaultMessage="Please ensure all uploads are high quality and easily legible"
                                        />
                                    </ItemText>
                                </React.Fragment>
                            )}

                            <br/>
                            <StyledSelect
                                value={selectedFileType}
                                placeholder={
                                    intl.formatMessage({
                                        id: 'profile.validation.select_file_type',
                                        defaultMessage: 'Select the Document Type to Upload'
                                    })
                                }
                                onChange={this.onSelectFileType}
                                options={(displayScreen === 'tierTwoFullApplication') ? tier1FailedFileTypeOptions : tier2FileTypeOptions}
                                isDisabled={false}
                            />
                            <Files>
                                <label><FormattedMessage
                                    id="profile.verification.uploadFileTypes"
                                    defaultMessage="BMP, GIF, JPEG, PDF, PNG, SVG, or TIFF"
                                /></label>
                                <input type="file" key={this.state.fileInputKey || ''} name="file" id="file" className="form-control" onChange={this.onFileChangeHandler} />
                            </Files>
                            {error && error !== '' && (<ErrorMessage>{error}</ErrorMessage>)}
                            <UploadButton onClick={this.onSubmitFileUpload} disabled={formDisabled}>
                                <FormattedMessage
                                    id="profile.verification.submit_upload"
                                    defaultMessage="Upload File"
                                />
                            </UploadButton>
                            <ProgressDiv>
                                {loaded > 0 && (
                                    <Line strokeWidth="4" trailWidth="4" strokeColor="#021747" percent={loaded} />
                                )}
                            </ProgressDiv>

                            {this.renderJurisdictionMessage()}
                        </React.Fragment>
                    )
                )}

                {(notice === 'tierThreeReviewReady') && (
                    <ItemPending>
                        <ItemTextTitle>
                            <FormattedMessage
                                id="profile.validation.tier3PendingTitle"
                                defaultMessage="Tier 3 Application Pending Review"
                            />
                        </ItemTextTitle>
                        <ItemTextBold>
                            <FormattedMessage
                                id="profile.validation.tier3PendingText"
                                defaultMessage="All required information has been submitted. We will now review your application."
                            />
                        </ItemTextBold>
                    </ItemPending>
                )}

                {(notice === 'tierThreeApplicationStatus') && (
                    <React.Fragment>
                        {(user.uploadedFiles && user.uploadedFiles.map(file => file.reviewStatus === 'REVIEWED_DENIED' && !user.uploadedFiles.find(checkFile => (checkFile.reviewStatus === 'NEW_SUBMISSION' || checkFile.reviewStatus === 'REVIEWED_APPROVED') && checkFile.fileType === file.fileType) && (
                            (file.fileType === 'FORM_45_106F9') && (
                                <FlexRow key={file.id}>
                                    <ItemFailed>
                                        <ItemText>
                                            <ItemTextTitle>Accredited Investor Confirmation Form: {file.fileType.replaceAll('_', ' ')}</ItemTextTitle>
                                            <ItemText>Reviewed: {dateFns.format(new Date(file.reviewTs), 'YYYY-MM-DD HH:mm')}</ItemText>
                                            <br />
                                            <ItemTextBold>Issue: {file.reviewComment}</ItemTextBold>
                                            <br />
                                            <ItemText>Please address the issue identified and resubmit your document</ItemText>
                                        </ItemText>
                                    </ItemFailed>
                                </FlexRow>
                            )
                        )))}
                    </React.Fragment>
                )}

                {(displayScreen === 'tierThreeApplication') && (
                    <React.Fragment>
                        {(user.uploadedFiles == null) ? (
                            <FormattedMessage
                                id="profile.loading"
                                defaultMessage="Loading..."
                            />
                        ) : (
                            <React.Fragment>
                                {this.renderTierBenefits()}

                                <ApplyTitle>
                                    <FormattedMessage
                                        id="profile.validation.tier3ApplyTitle"
                                        defaultMessage="Apply For Tier 3 Status"
                                    />
                                    <BlocPalToolTip tooltipId='profile.whyTier3_tooltip'>
                                        <FormattedMessage
                                            id="profile.whyTier3_tooltip"
                                            defaultMessage="You must meet at least one of the following criteria to be able to qualify for Tier 3 Accredited Investor status."
                                        />
                                        <ul>
                                            <li>Your net income before taxes
                                                was more than $200,000 in each of the 2 most recent calendar years,
                                                and you expect it to be more than $200,000 in the current calendar
                                                year. (You can find your net income before taxes on your personal
                                                income tax return.)</li>
                                            <li>Your net income before taxes
                                                combined with your spouse’s was more than $300,000 in each of the 2
                                                most recent calendar years, and you expect your combined net income
                                                before taxes to be more than $300,000 in the current calendar
                                                year.</li>
                                            <li>Either alone or with your
                                                spouse, you own more than $1 million in cash and securities, after
                                                subtracting any debt related to the cash and
                                                securities.</li>
                                            <li>Either alone or with your spouse, you have net assets worth more than $5
                                                million. (Your net assets are your total assets, including real estate, minus your
                                                total debt.)</li>
                                        </ul>
                                    </BlocPalToolTip>
                                </ApplyTitle>

                                <ItemTextBold>
                                    <FormattedMessage
                                        id="profile.validation.tier3ApplySubTitle"
                                        defaultMessage="Accredited Investor Form is required to achieve Tier 3 status"
                                    />
                                </ItemTextBold>
                                <ItemText>
                                    <FormattedMessage
                                        id="profile.validation.tier3ApplyDescription"
                                        defaultMessage="Please ensure you meet the accredited investor criteria, then download and complete the linked PDF form. You will need to scan and upload the document in high quality."
                                    />
                                </ItemText>

                                <ItemLinkBoldOrange>
                                    <Link to="/pdf/BlocPal-45106F9.pdf" target="_blank" download>FORM BlocPal - 45-106F9.pdf</Link>
                                </ItemLinkBoldOrange>

                                <br/>
                                <StyledSelect
                                    value={selectedFileType}
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'profile.validation.select_file_type',
                                            defaultMessage: 'Select the Document Type to Upload'
                                        })
                                    }
                                    onChange={this.onSelectFileType}
                                    options={tier3FileTypeOptions}
                                    isDisabled={false}
                                />
                                <Files>
                                    <label><FormattedMessage
                                        id="profile.verification.uploadFileTypes"
                                        defaultMessage="BMP, GIF, JPEG, PDF, PNG, SVG, or TIFF"
                                    /></label>
                                    <input type="file" key={this.state.fileInputKey || ''} name="file" id="file" className="form-control" onChange={this.onFileChangeHandler} />
                                </Files>
                                {error && error !== '' && (<ErrorMessage>{error}</ErrorMessage>)}
                                <UploadButton onClick={this.onSubmitFileUpload} disabled={formDisabled}>
                                    <FormattedMessage
                                        id="profile.verification.submit_upload"
                                        defaultMessage="Upload File"
                                    />
                                </UploadButton>
                                <ProgressDiv>
                                    {loaded > 0 && (
                                        <Line strokeWidth="4" trailWidth="4" strokeColor="#021747" percent={loaded} />
                                    )}
                                </ProgressDiv>

                                {this.renderJurisdictionMessage()}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {(displayScreen === 'tierThreeApproved') && (
                    <React.Fragment>
                        {this.renderTierBenefits()}
                        <ItemComplete>
                            <ItemTextTitle>
                                <FormattedMessage
                                    id="profile.validation.tier3ApprovedTitle"
                                    defaultMessage="Tier 3 Application Approved"
                                />
                            </ItemTextTitle>
                            <ItemTextBold>
                                <FormattedMessage
                                    id="profile.validation.tier3ApprovedText"
                                    defaultMessage="You can now trade all assets available on BlocPalX"
                                />
                            </ItemTextBold>
                        </ItemComplete>
                        {this.renderJurisdictionMessage()}
                    </React.Fragment>
                )}

                {(displayScreen === 'tierCorporateApproved') && (
                    <React.Fragment>
                        {this.renderTierBenefits()}
                        <ItemComplete>
                            <ItemTextTitle>
                                <FormattedMessage
                                    id="profile.validation.corporateApprovedTitle"
                                    defaultMessage="Corporate Application Approved"
                                />
                            </ItemTextTitle>
                            <ItemTextBold>
                                <FormattedMessage
                                    id="profile.validation.corporateApprovedText"
                                    defaultMessage="You can now trade all assets available on BlocPalX"
                                />
                            </ItemTextBold>
                        </ItemComplete>
                        {this.renderJurisdictionMessage()}
                    </React.Fragment>
                )}

            </Container>
        );
    }
}
