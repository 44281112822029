
const theme = {
    fontFamily: {
        Inter: 'Inter',
    },
    fontSize: {

    },
    fontStyle: {
        normal: 'normal'
    },    
    colors: {
        primary1: '#021747',
        primary2: '#EF8632',
        primary3: '#262626',
        primary4: '#000000',
        primary5: '#FFFFFF',
        primary6: '#848484',
        primary7: '#f0f0f0',
        primary8: '#B1B1B1',
        primary9: '#bd362f',
        primary10: '#4A4A4A',
        primary11: '#38B258',
    }
};

export default theme;