import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TradePairsList from './TradePairsList';
import {getActivePairs, getAuthenticated} from '../../../../selectors';
// import { getSortedPairs } from '../../../../selectors';


function mapStateToProps(state) {
    return {
        // sortedPairs: getSortedPairs(state, ownProps),
        activeOrderIds: (state.market && state.market.activeOrderIds && state.market.activeOrderIds.length > 0) ? state.market.activeOrderIds.length : 0,
        pairs: getActivePairs(state),
        isAuthenticated: getAuthenticated(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradePairsList);
