
/*
 * INTERNAL REQUESTS
 */

export const setReferredBy = (referredBy) => ({
    type: 'SET_REFERRED_BY',
    referredBy : referredBy,
});

/*
 * REST REQUESTS
 */

/*
 * WEBSOCKET REQUESTS
 */
