import { createSelector } from 'reselect';

const getWallets = state => state.portfolio.wallets;
const getFiatCurrency = state => state.portfolio.fiatCurrency;
const getUsdToFiatRate = state => state.portfolio.usdToFiatRate;
const getCoinsConfig = state => state.currency.coinsConfig;

/*
export const getApproxTotal = createSelector(
    getWallets,
    wallets => wallets.reduce((sum, wallet) => (+wallet.fiat) + sum, 0)
);
*/

export const isDepositAllowed = createSelector(
    getWallets,
    (state, coin) => coin,
    (wallets, coin) => {
        return wallets ? wallets.find(w => w.coin === coin).rad || false : false;
    }
);

export const isWithdrawalAllowed = createSelector(
    getWallets,
    (state, coin) => coin,
    (wallets, coin) => {
        return wallets ? wallets.find(w => w.coin === coin).raw || false : false;
    }
);

export const getApproxTotal = createSelector(
    getWallets,
    getFiatCurrency,
    getUsdToFiatRate,
    getCoinsConfig,
    (wallets, fiatCurrency, usdToFiatRate, coinsConfig) => {
        let totalValue = 0;
        wallets.map((wallet) => {
            if (coinsConfig && coinsConfig.length > 0 && coinsConfig.find(config => config.name && config.name === wallet.coin)) {
                if (fiatCurrency === 'USD') {
                    totalValue += coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice * (+wallet.total);
                } else {
                    totalValue += coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice * (usdToFiatRate !== 0 ? usdToFiatRate : 0) * (+wallet.total);
                }
            }
        });
        return Number(totalValue).toFixed(2);
    }
);

/* was used in AssetPage and PortfolioCurrenciesList
export const getSortedWallets = createSelector(
    getWallets,
    (state, props) => props.sortColumn,
    (state, props) => props.sortDirection,
    (wallets, sortColumn, sortDirection) => {
        if (sortColumn == 'NAME') {
            return wallets.sort(
                (prev, next) => sortDirection == 'ASC' ?
                    prev.coin > next.coin :
                    next.coin > prev.coin
            );
        }
        // sortColumn == 'VALUE'
        return wallets.sort(
            (prev, next) => sortDirection == 'ASC' ?
                Number(prev.fiat) - Number(next.fiat) :
                Number(next.fiat) - Number(prev.fiat)
        );
    }
);
*/

export const getCurrentWallet = createSelector(
    getWallets,
    (state, coin) => coin,
    (wallets, coin) => {
        return wallets ? wallets.find(w => w.coin === coin) || {} : {};
    }
);

export const selectDepositAddresses = createSelector(
    state => state.portfolio.depositAddresses,
    (state, coin) => coin,
    (depositAddresses, coin) => depositAddresses ? depositAddresses.filter(address => address.asset === coin) : []
);

export const selectWithdrawalAddresses = createSelector(
    state => state.portfolio.withdrawalAddresses,
    (state, coin) => coin,
    (withdrawalAddresses, coin) => withdrawalAddresses ? withdrawalAddresses.filter(address => address.asset === coin) : []
);
