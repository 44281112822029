import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const colorMap = {
    yellow:  {
        // background: 'linear-gradient(90deg, #c2c42d 0%, #e8eb34 100%)',
        background: '#c2c42d',
        color: '#000000'
    },
    red:  {
        // background: 'linear-gradient(90deg, #AA0000 0%, #FF0000 100%)',
        background: '#e84e2c',
        color: '#FFFFFF'
    },
    blue:  {
        // background: 'linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%)',
        background: 'rgba(2, 23, 71, 1)',
        color: '#FFFFFF',
    },
    ltgray:  {
        // background: 'linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%)',
        background: '#FAFAFA',
        color: '#000000',
    },
    white:  {
        // background: 'linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%)',
        background: '#FFFFFF',
        color: '#000000',
    },
    orange:  {
        // background: 'linear-gradient(90deg, rgba(239, 134, 50, 1) 0%, rgba(239, 134, 50, 0.7) 100%)',
        background: 'rgba(239, 134, 50, 1)',
        color: '#FFFFFF',
    },
    green:  {
        // background: 'linear-gradient(45.11deg, #56C200 0%, #7FD916 100%)',
        background: '#56C200',
        color: '#FFFFFF'
    },
};

const ButtonWrapper = styled.button`
    background: ${props => props.buttonTheme.background};
    color: ${props => props.buttonTheme.color};
    text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
    padding: 8px 8px;
    border-radius: 3px;
    text-decoration: ${props => props.buttonTheme.textDecoration || 'none'};
    border: 0;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 8px;
    cursor: pointer;

    :disabled {
      // background: #BBBBBB;
      opacity: 0.5;
      cursor: not-allowed;
    }
`;

// TODO Rework styles
export default class SmallButton extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.any,
        bsStyle: PropTypes.oneOf(['yellow', 'red', 'blue', 'orange', 'green', 'white', 'ltgray']),
    };

    static defaultProps = {
        bsStyle: 'blue',
    };

    render() {
        const buttonTheme = colorMap[this.props.bsStyle || 'blue'];
        return (
            <ButtonWrapper buttonTheme={buttonTheme} {...this.props} />
        );
    }

}
