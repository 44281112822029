import React from 'react';
import PropTypes from 'prop-types';
import {Link, Route, Switch} from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Component as CurrencyImage } from '../../components/CurrencyImage';
import { Component as StandardPage } from '../../components/StandardPage';
import { Component as NotFound } from '../NotFoundScreen/components/NotFound';

import { Container as AssetInformation } from '../../components/AssetInformation';
import { Container as AssetNews } from '../../components/AssetNews';
import { Container as ProjectPage } from './components/ProjectPage';
import { Container as ProjectsList } from './components/ProjectsList';
import { Container as ProjectsSidebar } from './components/ProjectsSidebar';

import { mobile } from '../../styles';
import {BackLink} from '../../commonStyles/GeneralStyles';


const SidebarLoader = props => (
    <ContentLoader
        height={800}
        width={280}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="16" y="0" rx="0" ry="0" width="75" height="21" />
        <rect x="16" y="24" rx="0" ry="0" width="230" height="45" />
        {/*<rect x="16" y="75" rx="0" ry="0" width="84" height="19" />*/}
        {/*<rect x="170" y="75" rx="0" ry="0" width="105" height="19" />*/}
        <rect x="0" y="105" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="191" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="277" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="363" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="449" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="535" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="621" rx="0" ry="0" width="280" height="83" />
    </ContentLoader>
);

const MainLoader = props => (
    <ContentLoader
        height={800}
        width={800}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="50" y="80" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="160" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="240" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="320" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="400" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="480" rx="0" ry="0" width="700" height="60" />
    </ContentLoader>
);

const Sidebar = styled.div`
  border-right: 1px solid #eee;
  flex: 0 0 290px;
  padding: 15px;
  box-sizing: border-box;

  ${mobile`
    display: none;
  `};
`;

const Content = styled.div`
  flex: 1 1;
  // flex: 1 1 738px;
`;

const Header = styled.div`
  padding: 10px 20px 0;
`;

/*
const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;
*/


const PageTitle = styled.div`
	color: #4A4A4A;
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
	margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

	a {
	  color: #ef8632;
	  text-decoration:none;
	}

    span {
      text-align: center;
	}

    ${mobile`
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5px;
    `};
`;

const CurrencyIcon = styled(CurrencyImage)`
  margin: 10px 5px 10px 0;
  width: 30px;
  height: 30px;
`;

const CurrencyIconRight = styled(CurrencyImage)`
  margin: 20px 10px 10px auto;
  width: 200px;
  height: 200px;
  float: right;
  top: -20px;
  right: -10px;
  position: relative;
  opacity: 0.8;

  ${mobile`
    display: none;
  `};
`;

const WalletActions = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: start;

  img {
    margin-left: 10px;
  }

  ${mobile`
    // flex-wrap: wrap;
    margin: 10px 0 10px 0;
    flex-direction: row;
    align-items: center;
  `};

`;

const BlueButton = styled(Link)`
    width: 150px;
    margin-right: 16px;
    // background: linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%);
    background: rgba(2, 23, 71, 1);
    color: #FFFFFF;
    text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
    padding: 16px 20px;
    border-radius: 5px;
    text-decoration: none;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 1.05px;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;

    ${mobile`
      margin: 5px 5px 5px 5px;
      padding: 5px;
      width: 30%;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      // width: 100%;
    `};
`;

export default class ProjectsScreen extends React.Component {

    static propTypes = {
        coinsConfig: PropTypes.array,
        match: PropTypes.object,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });
        const { match } = this.props;

        if (!match.params.ticker || match.params.ticker === '') {
            document.title = 'Assets (BlocPalX)';
        } else {
            document.title = match.params.ticker + ' Asset (BlocPalX)';
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match !== prevProps.match) {
            const { match } = this.props;
            if (!match.params.ticker || match.params.ticker === '') {
                document.title = 'Assets (BlocPalX)';
            } else {
                document.title = match.params.ticker + ' Asset (BlocPalX)';
            }
        }
    }

    render() {
        const { coinsConfig, match, history } = this.props;
        const coinConfig = coinsConfig.find(coinConfig => coinConfig.name === match.params.ticker);

        return (
            <StandardPage row>
                {(match.params.ticker && match.params.ticker !== '') && (
                    <Sidebar>
                        {(coinsConfig && coinsConfig.length > 0) ? (
                            <ProjectsSidebar
                                activeAsset={match.params.ticker}
                            />
                        ) : (
                            <SidebarLoader/>
                        )}
                    </Sidebar>
                )}

                {(coinsConfig && coinsConfig.length > 0) ? (
                    <Content>
                        <Switch>
                            <Route path={`${this.props.match.url}/`} exact component={() => (
                                <React.Fragment>
                                    {(match.params.ticker && match.params.ticker !== '') ? (
                                        <React.Fragment>
                                            <Header>
                                                <CurrencyIconRight currency={match.params.ticker} />
                                                <BackLink onClick={() => { history.push('/projects'); }}>
                                                    Assets List
                                                </BackLink>
                                                <PageTitle>
                                                    <CurrencyIcon currency={match.params.ticker} />
                                                    <FormattedMessage
                                                        id="project.title"
                                                        defaultMessage="{name}{ticker}"
                                                        values={{
                                                            name: coinConfig ? coinConfig.label + ' ' : '',
                                                            ticker: coinConfig ? '(' + match.params.ticker + ')' : match.params.ticker
                                                        }}
                                                    />
                                                </PageTitle>
                                                <AssetInformation coin={match.params.ticker}/>
                                                {coinConfig && coinConfig.name && (
                                                    <WalletActions>
                                                        <BlueButton to={`/projects/${coinConfig.name}/news`}>
                                                            <FormattedMessage
                                                                id="project.newsButton"
                                                                defaultMessage="{name} News"
                                                                values={{
                                                                    name: coinConfig.label,
                                                                }}
                                                            />
                                                        </BlueButton>
                                                    </WalletActions>
                                                )}
                                            </Header>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <ProjectsList/>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}/>

                            <Route path={`${this.props.match.url}/about`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={match.params.ticker} />
                                        <BackLink onClick={() => { history.push('/projects/' + match.params.ticker); }}>
                                            {coinConfig ? 'Asset: ' + coinConfig.label + ' (' + coinConfig.name + ')' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={match.params.ticker} />
                                            <FormattedMessage
                                                id="projects.about"
                                                defaultMessage="{name}{ticker} Asset Information"
                                                values={{
                                                    name: coinConfig ? coinConfig.label+' ' : '',
                                                    ticker: coinConfig ? '('+match.params.ticker+')' : match.params.ticker
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <ProjectPage ticker={match.params.ticker} coinConfig={coinConfig}/>
                                </React.Fragment>
                            )}/>

                            <Route path={`${this.props.match.url}/news`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={match.params.ticker} />
                                        <BackLink onClick={() => { history.push('/projects/' + match.params.ticker); }}>
                                            {coinConfig ? 'Asset: ' + coinConfig.label + ' (' + coinConfig.name + ')' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={match.params.ticker} />
                                            <FormattedMessage
                                                id="project.newsHeader"
                                                defaultMessage="{name}{ticker} Asset News"
                                                values={{
                                                    name: coinConfig ? coinConfig.label+' ' : '',
                                                    ticker: coinConfig ? '('+match.params.ticker+')' : match.params.ticker
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <AssetNews coin={match.params.ticker}/>
                                </React.Fragment>
                            )}/>

                            <Route component={NotFound} />
                        </Switch>
                    </Content>
                ) : (
                    <MainLoader/>
                )}

            </StandardPage>
        );
    }

}
