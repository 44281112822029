import { handleActions } from 'redux-actions';

const defaultState = {
    state: 'NOT_CONNECTED',
    retries: 0
};

export default handleActions(
    {
        ['SET_WS_STATE']: (state, action) => {
            return {
                ...state,
                state: action.payload,
                retries: 0
            };
        },
        ['WS_CONNECTED']: (state) => {
            return {
                ...state,
                state: 'CONNECTED',
                retries: 0
            };
        },
        ['WS_RECONNECTION']: (state) => {
            return {
                ...state,
                state: 'RECONNECTING',
                retries: state.retries + 1
            };
        },
        ['WS_CLOSED']: (state) => {
            return {
                ...state,
                state: 'CLOSED',
                retries: state.retries + 1
            };
        },
    },
    defaultState
);
