import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as CurrencyInput } from '../../../../components/CurrencyInput';
import { FlexRowWithMobileWrap } from '../../../../components/Layout';
import { Component as Button } from '../../../../components/Button';
import { Component as InputGroup } from '../../../../components/InputGroup';
import { Container as InputTwoFa } from '../../../../components/InputTwoFa';
import { displayFormat, validateAnyAuthCode } from '../../../../utils';

import { mobile } from '../../../../styles';
import {Link} from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../../../../images/ic-copy.svg';
import { NotificationManager } from 'react-notifications';
import getSymbolFromCurrency from 'currency-symbol-map';
import {ItemIncomplete, ItemText, ItemTextTitle} from '../../../../commonStyles/AccountProfileStyles';
import {StyledSelect} from '../../../../commonStyles/FormStyles';


const VALIDATION_MESSAGES = {
    amount: (
        <FormattedMessage
            id="error.amount_required"
            defaultMessage="Amount must be set"
        />
    ),
    address: (
        <FormattedMessage
            id="error.address_required"
            defaultMessage="Address must be set"
        />
    ),
    code: (
        <FormattedMessage
            id="error.code_required"
            defaultMessage="2FA Code must be set"
        />
    ),
};

const Container = styled.div`
  margin-top: 20px;
  padding: 0px 0px 10px 40px;
  display: flex;
  flex-wrap: wrap;

  width: 500px;

  ${mobile`
    width: 350px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 10px 20px;
  `};
`;

const BlueButton = styled(Link)`
    float: right;
    width: 310px;
    background: rgba(2, 23, 71, 1);
    color: #FFFFFF;
    text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
    text-decoration: none;

    margin: 0;
    padding: 12px 0px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 1.05px;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;

    ${mobile`
      width: 350px;
    `};
`;

const FormItem = styled.div`
  margin-top: 10px;
  // margin-left: 30px;
  margin-bottom: 10px;
  white-space: nowrap;
  flex-wrap: wrap;

  > label {
    color: #333333;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0.2em;
  }

  p {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
  }

  span {
	color: #9B9B9B;
	font-size: 14px;
	line-height: 21px;
  }

  ${mobile`
    text-align: center;
    margin-left: 0px;
  `};
`;

const BalanceItem = styled(FormItem)`
  margin-top: 20px;
  margin-left: 30px;
  margin-bottom: 15px;
  white-space: nowrap;
  flex-wrap: wrap;

  ${mobile`
    text-align: center;
    margin-left: 0px;
  `};
`;

const AmountInput = styled(CurrencyInput)`
  width: 305px;
  height: 56px;
  justify-content: space-between;
  white-space: nowrap;

  input {
    height: 56px;
  }

  div {
    line-height: 56px;
  }
`;

const WithdrawalInputGroup = styled(InputGroup)`
  margin-top: 15px;
  overflow: visible;
  flex-wrap: wrap;

  /*label {
	color: #9B9B9B;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
  }
  
   */
`;

const WithdrawalResultGroup = styled(InputGroup)`
  margin-top: 0;

  /*
  label {
	color: #9B9B9B;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
  }
*/
  ${mobile`
    width: 100%;
    text-align: center;
  `};
`;

const AuthRow = styled.div`
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const LeftInputTwoFa = styled.div`
  display: inline-block;
  width: 150px;
  margin-right: 5px;
  margin-top: 15px;
  vertical-align: top;
`;

const RightButtonTwoFa = styled(Button)`
  // display: block;
  // width: 47%;
  width: 150px;
  margin-left: 5px;
  margin-top: 32px;
  vertical-align: top;
`;

const WideButton = styled(Button)`
  display: block;
  width: 310px;
  margin-top: 35px;
`;

const CopyToClipboardSpan = styled.span`
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 0 5px;
`;

const InfoBlock = styled.div`
  padding: 0px 5px 0px 5px;
  margin-bottom: 10px;

  ${mobile`
    margin: 0;
    text-align: center;
    justify-content: center;
  `}

  p {
    font-size: 14px;
  }

  h3 {
    color: #bd1a1a;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
    margin-bottom: 15px;

    ${mobile`
        text-align: center;
    `}
  }
`;

const BalanceAmount = styled.div`
    margin-top: 10px;
`;

const RegionLockedContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  width: 350px;

  color: #ef8632;
  background-color: #FAFAFA;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #CFCFCF;
  border-radius: 5px;
`;

const BreakWord = styled.div`
  width: 650px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  ${mobile`
    width: 320px;
  `};
`;


export default class PortfolioWithdrawals extends React.Component {

    static propTypes = {
        coin: PropTypes.string,

        fiatCurrency: PropTypes.string,
        usdToFiatRate: PropTypes.number,
        coinConfig: PropTypes.object,
        addresses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            asset: PropTypes.string,
            network: PropTypes.string,
            address: PropTypes.string,
            tag: PropTypes.string,
            label: PropTypes.string,
            used: PropTypes.bool,
        })),
        networkConfigs: PropTypes.object,
        wallet: PropTypes.object,
        isWithdrawalAllowed: PropTypes.bool,

        userSecuredMethods: PropTypes.array,
        userSecuredActions: PropTypes.array,
        userKycTier: PropTypes.string,
        userLocked: PropTypes.bool,
        userJurisdictionLockWhitelisted: PropTypes.bool,
        userWithdrawalsDisabled: PropTypes.bool,
        withdrawalRequestState: PropTypes.string,

        clearWithdrawRequest: PropTypes.func,
        requestWithdraw: PropTypes.func,
        withdrawRequestSent: PropTypes.func,
        clearWithdrawalAddresses: PropTypes.func,
        getWithdrawalAddresses: PropTypes.func,
        reloadWalletActivity: PropTypes.func,

        intl: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object,
    };

    state = {
        errors: [],
        amount: 0,
        asset: '',
        network: '',
        address: '',
        tag: '',
        label: '',
        code: '',
        selectedAddress: undefined,
    };

    componentDidMount() {
        if (this.props.coin) {
            this.props.clearWithdrawalAddresses();
            this.props.getWithdrawalAddresses(this.props.coin);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.withdrawalRequestState && this.props.withdrawalRequestState !== prevProps.withdrawalRequestState) {
            if (this.props.withdrawalRequestState === 'success') {
                this.setState({
                    amount: 0,
                    asset: '',
                    network: '',
                    address: '',
                    tag: '',
                    label: '',
                    code: '',
                    selectedAddress: undefined,
                });
                this.props.clearWithdrawRequest();
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        // don't want to redraw for every exchange config update since we're just using these for explorer links
        if (this.props.networkConfigs !== nextProps.networkConfigs) {
            return false;
        }
        if (this.props.coinConfig !== nextProps.coinConfig) {
            return false;
        }
        return true;
    }

    getError = field => {
        const error = this.state.errors.find(e => e.field === field);
        return error ? error.message : null;
    };

    setAmountField = (amount) => {
        this.setState({
            amount: String(amount),
        });
    };

    onSelectWithdrawalAddress = (key) => {
        const { addresses, wallet, coin } = this.props;
        if (key.value === 0) {
            // permanent withdrawal address is always zero
            this.setState({
                selectedAddress: key.value,
                asset: coin,
                network: wallet.prmNetwork,
                address: wallet.prmAddress,
                tag: wallet.prmTag,
                label: wallet.prmLabel,
            });
        } else if (key.value === addresses.length + 1) {
            // create new address is always last
            this.props.history.push('/account/portfolio/' + coin + '/addressBook');
        } else {
            this.setState({
                selectedAddress: key.value,
                asset: addresses[key.value-1].asset,
                network: addresses[key.value-1].network,
                address: addresses[key.value-1].address,
                tag: addresses[key.value-1].tag ? addresses[key.value-1].tag : '',
                label: addresses[key.value-1].label ? addresses[key.value-1].label : '',
            });
        }
    };

    validateInput = () => {
        const { userSecuredMethods, userSecuredActions, networkConfigs } = this.props;
        const { network } = this.state;

        const requiredFields = [
            'amount',
            'address',
            !userSecuredMethods.includes('NONE') && userSecuredActions.includes('WITHDRAW') ? 'code' : undefined,
        ];

        const txFee = networkConfigs[network] && networkConfigs[network].networkFee && networkConfigs[network].networkFee > 0 ? Number(networkConfigs[network].networkFee) : 0;

        const validations = [
            {
                field: 'amount',
                validate: amount => {
                    return amount && amount.match(new RegExp(/^\d*(\.\d+)?$/));
                },
                message: (
                    <FormattedMessage
                        id="error.invalid_amount"
                        defaultMessage="Amount: Numbers and decimal only, no commas"
                    />
                )
            },
            {
                field: 'amount',
                validate: amount => {
                    return Number(amount)-txFee > 0;
                },
                message: (
                    <FormattedMessage
                        id="error.insufficient_amount"
                        defaultMessage="Amount: must be greater than fee"
                    />
                )
            },
            {
                field: 'code',
                validate: code => {
                    return validateAnyAuthCode(code);
                },
                message: (
                    <FormattedMessage
                        id="error.invalid_code"
                        defaultMessage="Invalid code"
                    />
                )
            },
        ];

        const missedFields = [];
        const invalidFields = [];

        requiredFields.filter(f => !!f).map(field => {
            if(!this.state[field]) {
                missedFields.push({
                    field,
                    message: VALIDATION_MESSAGES[field]
                });
            }
        });

        validations.map(({ field, validate, message }) => {
            if(!validate(this.state[field])) {
                invalidFields.push({
                    field,
                    message
                });
            }
        });

        return invalidFields.concat(missedFields);
    };

    onCopy = (text) => {
        NotificationManager.success(text, 'Copy To Clipboard');
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSubmit = () => {
        const { asset, network, amount, code, address, tag, label } = this.state;

        const errors = this.validateInput();

        if (errors.some(e => !!e)) {
            this.setState({
                errors
            });
        } else {
            this.setState({
                errors: []
            });

            this.props.requestWithdraw({
                code,
                amount,
                address,
                tag,
                label,
                asset,
                network
            });
            this.props.withdrawRequestSent();

            this.timer = setTimeout(() => {
                this.props.reloadWalletActivity();
            }, 2000);
        }
    };

    render() {
        const { userSecuredMethods, userSecuredActions, userKycTier, userJurisdictionLockWhitelisted, userLocked, userWithdrawalsDisabled } = this.props;

        const { wallet, coin, fiatCurrency, usdToFiatRate, coinConfig, networkConfigs, addresses, intl, isWithdrawalAllowed } = this.props;
        const { network, amount, address, tag, code, selectedAddress } = this.state;

        const disabled = !amount || !(amount > 0) || !address || !validateAnyAuthCode(code);

        // networkConfigs[network].networkFee = native txFee converted to this asset
        const txFee = networkConfigs[network] && networkConfigs[network].networkFee && networkConfigs[network].networkFee > 0 ? Number(networkConfigs[network].networkFee) : 0;
        const addressLabel = networkConfigs[network] ? networkConfigs[network].addressName : 'Address';
        const tagLabel = networkConfigs[network] ? networkConfigs[network].addressTagName.replace(' (Optional)', '') : 'Tag';

        // withdrawalAddressOptions.find(({value}) => value === selectedAddress)

        const permWithdrawalAddressText = intl.formatMessage({
            id: 'portfolio.permanentWithdrawalAddress',
            defaultMessage: 'Permanent Withdrawal Address'
        });

        let withdrawalAddressOptions = [];
        wallet.prmAddress && withdrawalAddressOptions.push({
            value: 0,
            label: wallet.prmAddress ? ((selectedAddress === 0) ? permWithdrawalAddressText : ((permWithdrawalAddressText + '\n' + wallet.prmAddress) + (wallet.prmTag ? ' : ' + wallet.prmTag : ''))) : '',
        });
        if (addresses && addresses.length > 0) {
            withdrawalAddressOptions = withdrawalAddressOptions.concat(addresses.map((address, i) => ({
                value: i+1,
                label: (selectedAddress === (i+1)) ? (address.label ? address.label : 'No Label (' + (i+1) + ')') : (address.label ? address.label + '\n' : 'No Label (' + (i+1) + ')\n') + address.address + (address.tag ? ' : ' + address.tag : '')
            })));
        }
        withdrawalAddressOptions.push({
            value: withdrawalAddressOptions.length+1,
            label: intl.formatMessage({
                id: 'portfolio.createNewWithdrawalAddress',
                defaultMessage: 'Create New Withdrawal Address'
            }),
        });

        // console.log(wallet.prmAddress, selectedAddress, withdrawalAddressOptions);

        return (
            <Container>
                {(userKycTier === 'NONE' || (!isWithdrawalAllowed && !userJurisdictionLockWhitelisted)) ? (
                    (userKycTier === 'NONE') ? (
                        <RegionLockedContainer>
                            <FormattedMessage
                                id="portfoilo.kycRequiredToWithdraw"
                                defaultMessage="Please Complete Your Account Setup"
                            />
                        </RegionLockedContainer>
                    ) : (
                        <RegionLockedContainer>
                            <FormattedMessage
                                id="portfoilo.regionWithdrawalsNotAllowed"
                                defaultMessage="Withdrawals of {asset} are not yet enabled in your region"
                                values={{
                                    asset: coin,
                                }}
                            />
                        </RegionLockedContainer>
                    )
                ) : (
                    <React.Fragment>
                        {(userLocked || userWithdrawalsDisabled) && (
                            <FormattedMessage
                                id="porfolio.withdrawalLocked"
                                defaultMessage="Withdrawals on this asset are currently locked"
                            />
                        )}

                        {((txFee * coinConfig.coinPrice) > 1) && (
                            <InfoBlock>
                                <FormattedMessage
                                    id="portfolio.high_dest_fee_notice"
                                    defaultMessage="Warning: The {currency} blockchain is currently congested, causing a high TxFee. (approx ${txFeeUsd} USD)"
                                    tagName="h3"
                                    values={{
                                        currency: (network && network !== '') ? network : coin,
                                        txFeeUsd: Number(txFee * coinConfig.coinPrice).toFixed(2),
                                    }}
                                />
                                <FormattedMessage
                                    id="portfolio.fees_out_of_our_control"
                                    defaultMessage="To keep fees as low as possible BlocPal automatically adjusts withdrawal fees to reflect an average of recent network transactions. Unfortunately blockchain fees are out of our control."
                                    tagName="p"
                                />
                            </InfoBlock>
                        )}

                        {address && address !== '' && (
                            (networkConfigs[network] && networkConfigs[network].withdrawalWarning && networkConfigs[network].withdrawalWarning !== '') && (
                                <ItemIncomplete>
                                    <ItemTextTitle>
                                        <FormattedMessage
                                            id="profile.withdrawalWarningLabel"
                                            defaultMessage="{network} Network Warning"
                                            values={{
                                                network: networkConfigs[network].displayName + ' (' + network + ')',
                                            }}
                                        />
                                    </ItemTextTitle>
                                    <ItemText dangerouslySetInnerHTML={{__html: networkConfigs[network].withdrawalWarning }} />
                                </ItemIncomplete>
                            )
                        )}

                        {((addresses && addresses.length > 0) || wallet.prmAddress) && (
                            <InputGroup>
                                <FormattedMessage
                                    id="portfolio.selectWithdrawalAddressLabel"
                                    defaultMessage="Withdrawal Destination"
                                    tagName="label"
                                />
                                <StyledSelect
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'portfolio.selectWithdrawalAddress',
                                            defaultMessage: 'Select Address'
                                        })
                                    }
                                    value={withdrawalAddressOptions.find(({value}) => value === selectedAddress)}
                                    onChange={this.onSelectWithdrawalAddress}
                                    options={withdrawalAddressOptions}
                                    isDisabled={false}
                                />
                            </InputGroup>
                        )}

                        {address && address !== '' && (
                            networkConfigs[network] ? (
                                <React.Fragment>
                                    <FormItem>
                                        <FormattedMessage
                                            id="portfolio.withdrawNetworkLabel"
                                            defaultMessage="Blockchain or Network"
                                            tagName="label"
                                        />
                                        <br/>

                                        <BreakWord>
                                            {networkConfigs[network].displayName + ' (' + network + ')'}
                                        </BreakWord>
                                    </FormItem>

                                    <FormItem>
                                        <FormattedMessage
                                            id="portfolio.withdrawAddressLabel"
                                            defaultMessage="{addressLabel}"
                                            tagName="label"
                                            values={{
                                                addressLabel: addressLabel,
                                            }}
                                        />
                                        <CopyToClipboardSpan  onClick={() => {this.onCopy(address);}}>
                                            <CopyToClipboard text={address}>
                                                <span>
                                                    <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                    &nbsp;Copy to clipboard
                                                </span>
                                            </CopyToClipboard>
                                        </CopyToClipboardSpan>
                                        <br/>

                                        <BreakWord>
                                            {address}
                                        </BreakWord>
                                    </FormItem>

                                    {tag && tag !== '' && (
                                        <FormItem>
                                            <FormattedMessage
                                                id="portfolio.withdrawAddressTagLabel"
                                                defaultMessage="{tagLabel}"
                                                tagName="label"
                                                values={{
                                                    tagLabel: tagLabel,
                                                }}
                                            />
                                            <CopyToClipboardSpan  onClick={() => {this.onCopy(tag);}}>
                                                <CopyToClipboard text={tag}>
                                                    <span>
                                                        <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                        &nbsp;Copy to clipboard
                                                    </span>
                                                </CopyToClipboard>
                                            </CopyToClipboardSpan>
                                            <br/>

                                            <BreakWord>
                                                {tag}
                                            </BreakWord>
                                        </FormItem>
                                    )}

                                    <FlexRowWithMobileWrap>
                                        <WithdrawalInputGroup>
                                            <FormattedMessage
                                                id="portfolio.withdrawalAmount"
                                                defaultMessage="Withdrawal Amount"
                                                tagName="label"
                                            />
                                            <AmountInput
                                                name="amount"
                                                value={amount}
                                                currency={coin}
                                                onChange={this.onChange}
                                                error={this.getError('amount')}
                                            />
                                        </WithdrawalInputGroup>
                                        <BalanceItem>
                                            <FormattedMessage
                                                id="portfolio.available"
                                                defaultMessage="Available"
                                                tagName="label"
                                            />
                                            <BalanceAmount>
                                                <p onClick={() => {this.setAmountField(wallet.avail);}}>
                                                    {wallet.avail}
                                                </p>
                                                <span>
                                                    Fee: {txFee} {coin}
                                                </span>
                                            </BalanceAmount>
                                        </BalanceItem>
                                    </FlexRowWithMobileWrap>
                                    <WithdrawalResultGroup>
                                        {(amount && Number(amount) > 0 && (Number(amount)-txFee) > 0) ? (
                                            <React.Fragment>
                                                <FormattedMessage
                                                    id="porfolio.withdrawalReceiveAmount"
                                                    defaultMessage="You will receive approximately"
                                                    tagName="label"
                                                />
                                                {displayFormat(amount-txFee, 8)} {coin} {(fiatCurrency !== 'USD') ?
                                                    '(' +
                                                    intl.formatMessage({id: 'portfolio.selectWithdrawalAmountValue', defaultMessage: 'valued at '}) +
                                                    getSymbolFromCurrency(fiatCurrency) +
                                                    displayFormat((coinConfig.coinPrice * (usdToFiatRate !== 0 ? usdToFiatRate : 0) * (amount-txFee)), 2) +
                                                    ' ' + fiatCurrency +
                                                    ' / $' + displayFormat((coinConfig.coinPrice * (amount-txFee)), 2) + ' USD)'
                                                    :
                                                    '($' + displayFormat((coinConfig.coinPrice * (amount-txFee)), 2) + ' ' + fiatCurrency + ')'}
                                            </React.Fragment>
                                        ) : null }
                                    </WithdrawalResultGroup>

                                    {!userSecuredMethods.includes('NONE') && userSecuredActions.includes('WITHDRAW') ? (
                                        <AuthRow>
                                            <LeftInputTwoFa>
                                                <InputTwoFa
                                                    name="code"
                                                    value={code}
                                                    onChange={this.onChange}
                                                    disabled={false}
                                                    component='WITHDRAW'
                                                />
                                            </LeftInputTwoFa>
                                            <RightButtonTwoFa
                                                disabled={disabled}
                                                onClick={this.onSubmit}
                                            >
                                                <FormattedMessage
                                                    id="portfolio.withdraw"
                                                    defaultMessage="Withdraw"
                                                />
                                            </RightButtonTwoFa>
                                        </AuthRow>
                                    ) : (
                                        <AuthRow>
                                            <WideButton
                                                disabled={disabled}
                                                onClick={this.onSubmit}
                                            >
                                                <FormattedMessage
                                                    id="portfolio.withdraw"
                                                    defaultMessage="Withdraw"
                                                />
                                            </WideButton>
                                        </AuthRow>
                                    )}
                                </React.Fragment>
                            ) : (
                                <FormattedMessage
                                    id="porfolio.withdrawalNetworkUnavailable"
                                    defaultMessage="Withdrawals on the {network} network are currently unavailable"
                                    values={{
                                        network
                                    }}
                                />
                            )
                        )}

                        <InputGroup>
                            <BlueButton to={`/account/portfolio/${coin}/addressBook`}>
                                <FormattedMessage
                                    id="wallet.addressBookLink"
                                    defaultMessage="Manage Addresses &#128464;"
                                />
                            </BlueButton>
                        </InputGroup>
                    </React.Fragment>
                )}
            </Container>
        );
    }
}
