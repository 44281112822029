import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WithdrawalConfirmPanel from './WithdrawalConfirmPanel';
import { getWithdrawalFeeEstimate } from '../../selectors';

function mapStateToProps(state) {
    return {
        feeEstimate: getWithdrawalFeeEstimate(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithdrawalConfirmPanel);



