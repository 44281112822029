import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { mobile } from '../../../../styles';

import { Container as QuickTradePane } from '../QuickTradePane';

import quicktradeLogoBlack from '../../../../images/quicktrade-logo-black.png';


const TradeWrapper = styled.div`
  padding-top: 1.5em;
  flex: 1 auto;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  position: relative;
`;

const TradingHeader = styled.div`
    flex-flow: row;
`;

const TradingLogo = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 0.5em;
  padding-left: 1.3em;
  padding-right: 0.5em;
  float: left;

  ${mobile`
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  `}
`;

const QuickTradeLogo = styled.img`
  width: 300px;

  ${mobile`
    width: 280px;
  `}
`;

const TradingTitle = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 1.8em;
  float: right;

  ${mobile`
    display: none;
  `}
`;

const TradingSubTitle = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  line-height: 18px;
  margin-bottom: 0.5em;
  padding-left: 1.8em;
  padding-right: 1.8em;

  ${mobile`
    display: none;
  `}
`;

const SpinnerWrapper = styled.div`
    flex: 1 auto;
    z-index: 999;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #CCCCCCAA;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
`;

const Spinner = styled.div`
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
`;

const Message = styled.div`
    color: #3498db;
    position: absolute;
`;

const MessageBox = styled.div`
    flex: 1 auto;
    margin: 2em 4em;

    display: flex;
    flex-direction: column;
`;

const SpinnerOverlay = () => {
    return (
        <SpinnerWrapper>
            <Spinner />
            <Message>
                <FormattedMessage
                    id="navigation.trade.loading"
                    defaultMessage="Loading"
                />
            </Message>
        </SpinnerWrapper>
    );
};

export default class QuickTrade extends React.Component {
    static propTypes = {
        trade: PropTypes.shape({
            requestSubmitted: PropTypes.bool,
            submittingRequest: PropTypes.bool,
            validation: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string,
                value: PropTypes.string,
                message: PropTypes.string,
            })),
        }).isRequired,
    };

    componentDidMount() {
        document.title = 'QuickTrade (BlocPalX)';
    }

    render() {
        if(this.props.trade.requestSubmitted) {
            return (
                <TradeWrapper>
                    <MessageBox>
                        <span>Your trade request has been submitted.</span>
                        <span>Check your email to confirm the trade.</span>
                    </MessageBox>
                </TradeWrapper>
            );
        } else {
            return this.renderTradeForm();
        }
    }

    renderTradeForm() {
        return (
            <TradeWrapper>
                {this.props.trade.submittingRequest && <SpinnerOverlay />}
                <TradingHeader>
                    <TradingLogo>
                        <QuickTradeLogo src={'/' + quicktradeLogoBlack}/>
                    </TradingLogo>
                    <TradingTitle>
                        <FormattedMessage
                            id="navigation.trade.title"
                            defaultMessage="Choose A Trade Pair"
                        />
                    </TradingTitle>
                </TradingHeader>
                <TradingHeader>
                    <TradingSubTitle>
                        <FormattedMessage
                            id="navigation.trade.subtitle"
                            defaultMessage="Instant Withdrawal - No Account Required"
                        />
                    </TradingSubTitle>
                </TradingHeader>
                <QuickTradePane
                    validation={this.props.trade.validation}
                />
            </TradeWrapper>
        );
    }
}
