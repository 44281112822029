import { handleActions } from 'redux-actions';
import queryString from 'query-string';

// const queryString = require('query-string');
const referredBy = queryString.parse(location.search).ref && queryString.parse(location.search).ref.match(/^[0-9a-zA-Z]+$/) ? queryString.parse(location.search).ref : null;

const defaultState = {
    referredBy: referredBy,
    referralCode: null,
};

export default handleActions(
    {
        ['SET_REFERRED_BY']: (state, action) => {
            return {
                ...state,
                referredBy: action.referredBy,
            };
        },
        ['SUCCESS_LOGIN']: (state = defaultState, action) => {
            return {
                ...state,
                referralCode: action.payload.referralCode,
                referredBy: action.payload.referredBy,
            };
        },
        ['UPDATE_TRANSACTION_STATE']: (state, action) => {
            if (action.payload && action.payload.referralCode) {
                return {
                    ...state,
                    referralCode: action.payload.referralCode
                };
            } else {
                return state;
            }
        },

    },
    defaultState
);
