import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { Component as CurrencyImage } from '../CurrencyImage';


const SingleValueWrapper = styled(components.SingleValue)`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  align-items: center;
`;

const OptionWrapper = styled(components.Option)`
  display: flex!important;
  align-items: center;

  img {
    margin-right: 8px;
  }
`;

const ControlWrapper = styled(components.Control)`
  border-color: #CFCFCF;
  box-shadow: none!important;
  background-color: #fff!important;

  &:hover {
    border-color: #CFCFCF!important;
  }
`;

const SelectContainerWrapper = styled(components.ValueContainer)`
  height: 50px;
`;


const CurrencyName = styled.span`
  font-weight: 700;
  color: #232323;
  font-size: 16px;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
`;

const CurrencyIcon = styled(CurrencyImage)`
  width: 30px;
  height: 30px;
`;


const SingleValue = ({ data, ...props }) => (
    <SingleValueWrapper {...props}>
        <CurrencyName>
            {data.value && <CurrencyIcon currency={data.value} />}
            {data.label}
        </CurrencyName>
        <span>{data.value}</span>
    </SingleValueWrapper>
);

SingleValue.propTypes = {
    data: PropTypes.object
};

const ValueContainer = ({ children, ...props }) => (
    <SelectContainerWrapper {...props}>
        {children}
    </SelectContainerWrapper>
);

ValueContainer.propTypes = {
    data: PropTypes.object,
    children: PropTypes.any
};

//const Control = (props) => (
//    <ControlWrapper {...props} />
//);

//const Option =({label, options, ...props}) => {
//    const option = options.find(o => o.label === label);
//    return (
//        <OptionWrapper {...props}>
//            <CurrencyIcon currency={option.value} />
//            <span>
//                {option.label}
//            </span>
//        </OptionWrapper>
//    );
//};


const Option = forwardRef(({label, options, ...props}, ref) => {
    Option.displayName = 'Option';
    const option = options.find(o => o.label === label);
    return (
        <OptionWrapper ref={ref} className="Option" {...props}>
            <CurrencyIcon currency={option.value} />
            <span>
                {option.label}
            </span>
        </OptionWrapper>
    );
});

Option.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array
};

/* change zIndex
const selectStyles = {
    container: (base, state) => ({
        ...base,
        opacity: state.isDisabled ? '.5' : '1',
        backgroundColor: 'transparent',
        zIndex: '90'
    })
};
*/

export default class CurrencySelector extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        onSelect: PropTypes.func,
        options: PropTypes.array
    };

    render() {
        const { value, onSelect } = this.props;

        const v = this.props.options.find(o => o.value === value);

        // change zIndex... <Select styles={selectStyles}

        return (
            <Select
                value={v || this.props.options[0]}
                onChange={onSelect}
                options={this.props.options}
                components={{
                    SingleValue,
                    ValueContainer,
                    ControlWrapper,
                    Option,
                    IndicatorSeparator: () => null
                }}
            />
        );
    }
}
