import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;

  input {
    position: absolute;
    margin: 8px 0 0 16px;
    display: none;
  }

  input + label {
    // position: relative;
    padding: 0 0 0 50px;
    line-height: 2.0em;

    :before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 40px; /* x*5 */
        height: 24px; /* x*3 */
        border-radius: 16px; /* x*2 */
        background: #fff;
        border: 1px solid #d9d9d9;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    :after {
        content: "";
        position: absolute;
        display: block;
        left: 0px;
        top: 0px;
        width: 24px; /* x*3 */
        height: 24px; /* x*3 */
        border-radius: 16px; /* x*2 */
        background: #fff;
        border: 1px solid #d9d9d9;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
  }

  input:checked + label:after {
    margin-left: 16px;
  }

  input:checked + label:before {
    background: rgba(2, 23, 71, 0.5);
  }
`;


export default class Switcher extends React.Component {

    static propTypes = {
        label: PropTypes.string
    };

    render() {

        const uniq = Math.random();

        return (
            <Wrapper>
                <input type='checkbox' id={`switcher_${uniq}`} {...this.props}/>
                <label htmlFor={`switcher_${uniq}`}>{this.props.label}</label>
            </Wrapper>
        );
    }

}
