import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {mobile} from '../../styles';
import {FormattedMessage} from 'react-intl';
import logo from '../../images/BlocpalX-logoonlight.svg';


const Title = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin: 1em auto 30px auto;
  text-align: center;

  display: block;
  flex-wrap: wrap;
  flex: 0 1 auto;
`;

const SquareLogoDiv = styled.div`
  text-align: center;
  display: block;
  margin: 30px auto;
`;

const SquareLogo = styled.img`
  width: 400px;
  ${mobile`
    width: 250px;
  `}
`;


export default class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.any,
    }

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        // console.log('ErrorBoundary caught error ' + error);
        return { error: error };
    }

    componentDidCatch(error, errorInfo) {
        // console.log(error, errorInfo.componentStack);

        // TODO: implement remote server-side logging
        // logErrorToMyService(error, errorInfo);

        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render() {
        if (this.state.error) {
            return (
                <SquareLogoDiv>
                    <SquareLogo src={'/' + logo} />
                    <Title>
                        <FormattedMessage
                            id="navigation.unexpectedError"
                            defaultMessage="Our apologies, an unexpected error has occurred"
                        />
                    </Title>
                </SquareLogoDiv>
            );
        }

        return this.props.children;
    }
}
