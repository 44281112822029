import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dateFns from 'date-fns';
import sha256 from 'crypto-js/sha256';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Component as Button } from '../../../../components/Button';
import { Component as InputGroup } from '../../../../components/InputGroup';
import { Component as RadioGroup } from '../../../../components/RadioGroup';
import { Component as Input } from '../../../../components/Input';
import { Component as Checkbox } from '../../../../components/Checkbox';
import { Title } from '../../../../commonStyles/AccountProfileStyles';

import { obfuscateEmail } from '../../../../utils';
import { mobile } from '../../../../styles';

import copyIcon from '../../../../images/ic-copy.svg';


const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  ${mobile`
    padding-left: 10px;
    padding-right: 10px;
  `};
`;

const SubTitle = styled.div`
   margin-top: 30px;
   color: #9B9B9B;
   font-size: 24px;
   line-height: 36px;
`;

const CopyToClipboardSpan = styled.span`
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 0 5px;
`;

const SampleProofSentence = styled.div`
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
  
  p {
    font-weight: bold;
  }
  
  i {
    font-size: 15px;
  }

  ${mobile`
      width: 100%;
      max-width: 320px;
  `};
`;

const WideButton = styled(Button)`
  display: block;
  width: 250px;
  margin-bottom: 40px;

  ${mobile`
      width: 100%;
      max-width: 320px;
  `};
`;

const Table = styled.div`
  padding-bottom: 20px;
  display: table;
  margin: 20px auto;
  width: 95%;
  border-bottom: 1px solid #eee;
`;

const Header = styled.div`
  display: table-row;

  >div {
    padding: 10px 20px;
  }
  // what does this do? If you know please reach out to Ethan    }History
`;

const Cell = styled.div`
  display: table-cell;
  width: 50%;
  // white-space: nowrap;
  overflow-x: visible;
  font-size: 12px;
`;

const CellNoMobile = styled(Cell)`
  ${mobile`
    display: none;
  `};
`;

const LastCell = styled(Cell)`
  // width: 34%;
  text-align: center;

  span {
    // color: #ef8632;
    cursor: pointer;
  }
`;

const HeaderRow = styled(Header)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

    color: #ACB5BC;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.71px;
    line-height: 20px;

    >div {
      padding: 10px 20px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;

      ${mobile`
        padding: 3px;
      `};
    }
`;

const TableRow = styled.div`
  display: table-row;

  &:nth-child(2n) {
    background-color: #02174708;
  }

  >div {
    padding: 10px 20px;

    ${mobile`
      padding: 3px;
    `};
  }
`;

const TableBody = styled.div`
  display: table-row-group;
`;

const StyledCheckbox = styled.div`
  margin: 20px;
  max-width: 350px;

  ${mobile`
      width: 100%;
      max-width: 320px;
  `};
`;

const sideBySideStyle = {
    display: 'flex',
    alignItems: 'center'
};

const CustomInputGroup = styled(InputGroup)`
  width: 100%;
  max-width: 350px;
  margin-bottom: 30px;
  position: relative;

  ${mobile`
      width: 100%;
      max-width: 320px;
  `};
`;

const SmallText = styled.div`
  font-size: 12px;

  ${mobile`
      width: 100%;
      max-width: 320px;
  `};
`;

const FormCounter = styled.div`
  text-align: right;
  font-size: 12px;
`;

const FormCounterRed = styled(FormCounter)`
  color: ${props => props.theme.colors.primary9};
`;

export default class ProfilePoRConfiguration extends React.Component {

    static propTypes = {
        getProofOfReserves: PropTypes.func,
        setProofOfReserves: PropTypes.func,
        clearErrors: PropTypes.func,
        walletProofs: PropTypes.array,
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state={
            personalString: '',
            stringType: '',
            includeBalances: false,
            proofSentence: '',
            loading: true,
            defaultCustomString: 'Going To The Moon With BlocPalX',
            proofSentenceCopied: false,
        };
    }

    componentDidMount() {
        const { user } = this.props;
        this.props.clearErrors();

        if (user && user.personalString) {
            this.loadSettings();
        }

        if (!this.props.user.balancesString || this.props.user.refreshProofOfReservesInfo === true) {
            this.props.getProofOfReserves();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { user } = this.props;
        const { personalString, stringType, includeBalances } = this.state;

        if (this.props.user.refreshProofOfReservesInfo !== prevProps.user.refreshProofOfReservesInfo && this.props.user.refreshProofOfReservesInfo === true) {
            this.props.getProofOfReserves();
        }

        if (user && user.personalString !== prevProps.user.personalString) {
            this.loadSettings();
        }

        if (personalString !== prevState.personalString ||
            stringType !== prevState.stringType ||
            includeBalances !== prevState.includeBalances) {
            this.buildProofSentence();
        }
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value.replace(/\s\s+/g, ' ').replace(/[^A-Za-z0-9\s]/g, '')
        });
    };

    onStringTypeChange = (stringType) => {
        this.setState({
            stringType: stringType.target.value,
        });
    };

    onUpdateProofOfReserves = () => {
        const { personalString, includeBalances } = this.state;

        this.setState({
            loading: true,
        });

        this.props.setProofOfReserves(personalString, includeBalances);
    }

    loadSettings = () => {
        const { stringType } = this.state;
        const { user } = this.props;

        let thisStringType;
        let thisPersonalString;
        let thisIncludeBalances;
        if (stringType === '' && user && user.personalString) {
            if (user.personalString.includes('@*.*') || user.personalString.includes('***')) {
                thisStringType = 'OBFUSCATED';
            } else if (user.personalString.includes('@')) {
                thisStringType = 'EMAIL';
            } else {
                thisStringType = 'CUSTOM';
            }
            thisPersonalString = user.personalString;
            thisIncludeBalances = (user.includeBalances === true);

            this.setState({
                stringType: thisStringType,
                personalString: thisPersonalString,
                includeBalances: thisIncludeBalances,
                loading: false,
            });
        }
    }

    buildProofSentence = () => {
        /*
         * It is VERY IMPORTANT that the logic and structure of the string generated here be consistent with
         * the logic and structure of the string generated in txq-platform::UserWalletProof.java
         *
         * The platform version should be considered the reference implementation.
         */
        const { personalString, defaultCustomString, stringType, includeBalances } = this.state;
        const { user } = this.props;

        // console.log('BUILD PROOF SENTENCE');
        // console.log(stringType);
        // console.log(personalString);

        let date = new Date();
        let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);

        let sentencePersonalString;
        if (stringType === 'OBFUSCATED') {
            sentencePersonalString = obfuscateEmail(user.email);
        } else if (stringType === 'EMAIL') {
            sentencePersonalString = user.email;
        } else {
            if (personalString.includes('@')) {
                sentencePersonalString = defaultCustomString;
            } else {
                sentencePersonalString = personalString;
            }
        }

        let proofSentence = 'The BlocPalX client identified as [' + sentencePersonalString +
            '] and Account Public Code [' + user.publicCode +
            '] was included in the [' + month + ' ' + year + '] audit';

        if (includeBalances) {
            proofSentence += ' and had balances of [' + user.balancesString + '] on deposit at the time';
        }

        proofSentence += '.';

        this.setState({
            personalString: sentencePersonalString,
            proofSentence: proofSentence,
        });
    }

    onCopyProofSentence() {
        this.setState({
            proofSentenceCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                proofSentenceCopied: false,
            });
        }, 1500);
    }

    render() {
        const { personalString, stringType, includeBalances, proofSentence, defaultCustomString, proofSentenceCopied } = this.state;
        const { user } = this.props;

        const saveDisabled = (user && !(personalString === defaultCustomString || personalString === '') && (personalString !== user.personalString || (includeBalances === true) !== (user.includeBalances === true))) ? false : true;
        const proofSentenceHash = sha256(proofSentence).toString();

        // console.log(proofSentenceHash);

        // console.log(stringType);
        // console.log(personalString);
        // console.log(includeBalances);
        // console.log(user.includeBalances);
        // console.log(saveDisabled);

        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="profile.proofOfReserves.title"
                        defaultMessage="Proof Of Reserves"
                    />
                </Title>

                <SubTitle>
                    <FormattedMessage
                        id="profile.proofOfReserves.previousProofsTitle"
                        defaultMessage="Your Available Proofs"
                    />
                </SubTitle>

                <Table>
                    <HeaderRow>
                        <Cell>
                            Proof Id
                        </Cell>
                        <Cell>
                            Date Created
                        </Cell>
                    </HeaderRow>
                    <TableBody>
                        {(user && user.walletProofs && user.walletProofs.length > 0) ? user.walletProofs.map((proof) =>
                            <React.Fragment key={proof.pid.replace(' ', '_')}>
                                <TableRow key={'pid_' + proof.pid.replace(' ', '_')}>
                                    <Cell style={sideBySideStyle}>
                                        {proof.pid}
                                    </Cell>
                                    <Cell>
                                        {dateFns.format(new Date(proof.ts), 'MM/DD/YY hh:mm')}
                                    </Cell>
                                </TableRow>
                                <TableRow key={'ps_' + proof.pid.replace(' ', '_')}>
                                    <Cell>
                                        <b>Proof Sentence</b>
                                        <CopyToClipboardSpan>
                                            {proofSentenceCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                <CopyToClipboard onCopy={()=>this.onCopyProofSentence()} text={proof.ps}>
                                                    <span>
                                                        <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                        &nbsp;Copy to clipboard
                                                    </span>
                                                </CopyToClipboard>
                                            )}
                                        </CopyToClipboardSpan>
                                        <br/>
                                        {proof.ps}
                                    </Cell>
                                    <Cell>
                                        <b>Balances</b>
                                        <br/>
                                        {(proof.bal && proof.bal !== 'none' && proof.bal.length > 0) && proof.bal.map((bal, i) =>
                                            <React.Fragment key={i}>
                                                <div>{bal.balance} {bal.asset}</div>
                                            </React.Fragment>
                                        )}
                                    </Cell>
                                </TableRow>
                            </React.Fragment>
                        ) : (
                            <TableRow key='0'>
                                <Cell>
                                    <FormattedMessage
                                        id="profile.proofOfReserves.noResults"
                                        defaultMessage="No proofs available."
                                    />
                                </Cell>
                                <Cell/>
                                <Cell/>
                                <CellNoMobile/>
                                <LastCell/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                {!(user && user.walletProofs && user.walletProofs.length > 0) && (
                    <SmallText>
                        <FormattedMessage
                            id="profile.proofOfReserves.noResultsExplanation"
                            defaultMessage="You must have a balance on BlocPalX at the time we run the audit to be included in the Proof of Reserves report."
                        />
                    </SmallText>
                )}

                <SubTitle>
                    <FormattedMessage
                        id="profile.proofOfReserves.optionsTitle"
                        defaultMessage="Proof Sentence Settings"
                    />
                </SubTitle>

                <StyledCheckbox>
                    <Checkbox
                        label={(
                            <FormattedMessage
                                id="profile.proofOfReserves.includeBalances"
                                defaultMessage="Include Balances (Recommended *)"
                            />
                        )}
                        checked={includeBalances}
                        onChange={() => this.setState({ includeBalances: (includeBalances === false) })}
                    />
                </StyledCheckbox>

                <RadioGroup>
                    <input type="radio" id="obfuscated" name="stringType" value="OBFUSCATED" required onChange={this.onStringTypeChange} checked={this.state.stringType === 'OBFUSCATED'} />
                    <label htmlFor="obfuscated">
                        <FormattedMessage
                            id="profile.por.radio.obfuscated"
                            defaultMessage="Obfuscated Email (Good)"
                        />
                    </label>
                    <br/>
                    <input type="radio" id="fullemail" name="stringType" value="EMAIL" required onChange={this.onStringTypeChange} checked={this.state.stringType === 'EMAIL'} />
                    <label htmlFor="fullemail">
                        <FormattedMessage
                            id="profile.por.radio.fullemail"
                            defaultMessage="Full Email (Better)"
                        />
                    </label>
                    <br/>
                    <input type="radio" id="custom" name="stringType" value="CUSTOM" required onChange={this.onStringTypeChange} checked={this.state.stringType === 'CUSTOM'} />
                    <label htmlFor="custom">
                        <FormattedMessage
                            id="profile.radio.custom"
                            defaultMessage="Custom Phrase (Best)"
                        />
                    </label>
                </RadioGroup>

                {stringType && stringType === 'CUSTOM' && (
                    <CustomInputGroup>
                        <label>
                            <FormattedMessage
                                id="profile.por.setCustomPhraseLabel"
                                defaultMessage="Set Your Custom Phrase"
                            />
                        </label>
                        <Input
                            name="personalString"
                            placeholder={defaultCustomString}
                            value={personalString}
                            onChange={this.onChange}
                            maxLength="35"
                        />
                        {(personalString.length > 32) ? (
                            <FormCounterRed>
                                <FormattedMessage
                                    id="profile.por.setCustomPhraseLabelCount"
                                    defaultMessage=" [{count}/35]"
                                    values={{ count: personalString.length }}
                                />
                            </FormCounterRed>
                        ) : (
                            <FormCounter>
                                <FormattedMessage
                                    id="profile.por.setCustomPhraseLabelCount"
                                    defaultMessage=" [{count}/35]"
                                    values={{ count: personalString.length }}
                                />
                            </FormCounter>
                        )}
                    </CustomInputGroup>
                )}

                <WideButton bsStyle='blue' disabled={saveDisabled} onClick={this.onUpdateProofOfReserves}>
                    <FormattedMessage
                        id="profile.saveChanges"
                        defaultMessage="Save Changes"
                    />
                </WideButton>

                {(proofSentence !== '') && (
                    <SampleProofSentence>
                        <p>
                            <FormattedMessage
                                id="profile.proofOfReserves.sampleProofSentenceTitle"
                                defaultMessage="Sample Proof of Reserves Sentence"
                            />
                        </p>
                        <i>{proofSentence}</i>

                        <p>
                            <FormattedMessage
                                id="profile.proofOfReserves.yourProofHashTitle"
                                defaultMessage="Proof of Reserves Sentence Hash"
                            />
                        </p>
                        <i>{proofSentenceHash}</i>
                    </SampleProofSentence>
                )}

                <SmallText>
                    <FormattedMessage
                        id="profile.proofOfReserves.includeBalancesNote"
                        defaultMessage="* Removing the balances from your Proof of Reserves Sentence allows you to reuse your Proof Sentence without having to log in and look it up.
                        Your balance data is always included in the Proof of Reserves audit, this only affects the Proof Sentence structure."
                    />
                </SmallText>
                <SmallText>
                    <FormattedMessage
                        id="profile.proofOfReserves.fullEmailWarning"
                        defaultMessage="** Full email is better for the proof, however you should be careful what third parties you share your proof sentence with, as they could extract your email and collect it."
                    />
                </SmallText>

            </Container>
        );
    }
}
