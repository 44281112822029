import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

import ProfileAccountVerification from './ProfileAccountVerification';

import { verificationUrlLoaded, getUploadedFileList, getVrfyInfo, submitUpdateVrfyInfo } from '../../../../actions/user';


function mapStateToProps(state) {
    return {
        user: state.user,
        verificationStatus: (state.user && state.user.verificationData && state.user.verificationData.verificationStatus) ? state.user.verificationData.verificationStatus : undefined,
        verificationAutoStatus: (state.user && state.user.verificationData && state.user.verificationData.verificationAutoStatus) ? state.user.verificationData.verificationAutoStatus : undefined,
        verificationUrl: (state.user && state.user.verificationData && state.user.verificationData.verificationUrl) ? state.user.verificationData.verificationUrl : undefined,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            verificationUrlLoaded,
            getUploadedFileList,
            getVrfyInfo,
            submitUpdateVrfyInfo,
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileAccountVerification));
