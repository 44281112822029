import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WalletProofOfReserves from './WalletProofOfReserves';
import { getCurrentWalletProof } from '../../../../selectors';

function mapStateToProps(state, props) {
    return {
        walletProof: getCurrentWalletProof(state, props.coin),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WalletProofOfReserves);
