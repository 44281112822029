import { handleActions } from 'redux-actions';
import { NotificationManager } from 'react-notifications';

const defaultState = {
    email: null,
    mobile: null,
    qr: '',
    secret: '',
    loginLoadingName: '',
    loginLoading: true,
    isAuthenticated: false,
    userAuths: null,
    refreshAuthsTable: false,
    isSmsSendSuccess: false,
    expiresInMillis: 900000,
};

export default handleActions(
    {
        ['UNAUTHORIZED_DEVICE']: (state = defaultState) => {
            // handled in authMiddleware
            // if (action.payload && action.payload.message === 'unauthorized device' ) {
            //     location.replace('/device-confirm');
            // }
            return {
                ...state,
                isAuthenticated: false,
            };
        },
        ['CLEAR_AUTH_DATA']: (state) => {
            sessionStorage.removeItem('token');
            return {
                ...defaultState,
                loginLoading: state.loginLoading,
            };
        },
        ['LOGIN_LOADING']: (state, action) => {
            return {
                ...state,
                loginLoading: action.payload,
            };
        },
        ['SUCCESS_LOGIN']: (state) => {
            // these are handled in authMiddleware but could likely all be moved here?
            // sessionStorage.setItem('token', action.payload.token);
            // location.replace('/account/portfolio');

            // save the successful login email for next time
            if (localStorage.getItem('loginRememberMe') === '1' && sessionStorage.getItem('loginEmail')) {
                localStorage.setItem('loginEmail', sessionStorage.getItem('loginEmail'));
            } else {
                localStorage.removeItem('loginEmail');
            }

            return {
                ...state,
                isAuthenticated: true,
                loginLoading: false,
            };
        },
        // WEBSOCKET
        ['WARN_SESSION_EXPIRE']: (state, action) => {
            // console.log('WARN_SESSION_EXPIRE', (action.payload.expiresInMillis / 1000));
            return {
                ...state,
                ...action.payload
            };
        },
        ['UPDATE_INFO']: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        // REST
        ['ADD_PASSWORD_SUCCESS']: (state, action) => {
            // console.log(action);
            if (action && action.payload && action.payload.data && action.payload.data.token) {
                // console.log('New Token: ' + action.payload.token);
                sessionStorage.setItem('token', action.payload.data.token);

                // triggers a browser reload
                window.location.replace('/account/profile/config2fa/newTwoFactor');

                return {
                    ...state,
                    isAuthenticated: true,
                    loginLoading: false,
                };
            } else {
                // triggers a browser reload
                window.location.replace('/login');
                return {
                    ...state,
                };
            }
        },
        // WEBSOCKET
        ['LOGIN_NOT_FINISHED']: (state, action) => {
            // save the successful login email for next time
            if (localStorage.getItem('loginRememberMe') === '1' && sessionStorage.getItem('loginEmail')) {
                localStorage.setItem('loginEmail', sessionStorage.getItem('loginEmail'));
            } else {
                localStorage.removeItem('loginEmail');
            }

            return {
                ...state,
                loginLoadingName: (action.payload.email && action.payload.email !== '') ? action.payload.email : action.payload.phoneNumber,
                token: action.payload.token,
            };
        },
        // WEBSOCKET
        ['SESSION_IP_MISMATCH']: (state) => {
            NotificationManager.error('Session IP Has Changed');
            // store.dispatch(push('/signedout'));

            // TODO: log out user ... send to user page explaining why we logout on IP changes ...

            return {
                ...state,
            };
        },
        // INTERNAL
        ['CLEAR_2FA_AUTHS']: (state) => {
            return {
                ...state,
                userAuths: null,
            };
        },
        // WEBSOCKET
        ['GET_2FA_AUTHS_SUCCESS']: (state, action) => {
            return {
                ...state,
                userAuths: action.payload.userAuths,
                refreshAuthsTable: false,
            };
        },
        // WEBSOCKET
        ['DEVICE_NOTIFICATION']: (state) => {
            NotificationManager.success('New Device Authorized');
            return state;
        },
        // WEBSOCKET
        ['UPDATE_2FA_ACTIONS_SUCCESS']: (state) => {
            NotificationManager.success('Changes Saved');
            return {
                ...state,
                refreshAuthsTable: true,
            };
        },
        // WEBSOCKET
        ['NEW_GAUTH_SUCCESS']: (state, action) => {
            // suppressed per BLOC-1506
            // NotificationManager.success('New Secret Generated');
            return {
                ...state,
                ...action.payload,
                refreshAuthsTable: false,
            };
        },
        // WEBSOCKET
        ['ADD_2FA_SUCCESS']: (state) => {
            // TODO: instead of plain text we should send phrase keys to the notification manager, then in
            //   the notification manager process internationalizations using the phrase keys
            //   eg: NotificationManager.success('notification.authMethodConfirmed');
            NotificationManager.success('Auth Method Confirmed');
            return {
                ...state,
                qr: '',
                secret: '',
                refreshAuthsTable: true,
            };
        },
        // WEBSOCKET
        ['DELETE_2FA_SUCCESS']: (state) => {
            NotificationManager.success('Auth Method Deleted');
            return {
                ...state,
                refreshAuthsTable: true,
            };
        },
        // INTERNAL
        ['CLEAR_REQUEST_SMS_SEND']: (state) => {
            return {
                ...state,
                isSmsSendSuccess: false,
            };
        },
        // WEBSOCKET
        ['SEND_SMS_CODE_SUCCESS']: (state) => {
            NotificationManager.success('SMS Sent!');
            return {
                ...state,
                isSmsSendSuccess: true,
            };
        },
    },
    defaultState
);
