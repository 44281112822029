import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dateFns from 'date-fns';


import { mobile } from '../../../../styles';


const Wrapper = styled.div`
  background-color: #FFFFFF;
  padding-bottom: 5px;
  width: 95%;
`;

const Table = styled.div`
  //padding: 40px 40px 0;
  display: table;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-bottom: 1px solid #eee;
`;

const Header = styled.div`
  display: table-row;

  >div {
      padding: 10px 20px;
    }
`;

const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  font-size: 12px;

  // width: 22%;
`;

const NoMobileCell = styled(Cell)`
  ${mobile`
    display: none;
  `};
`;

const LastCell = styled(Cell)`
  // width: 34%;
  text-align: right;

  span {
    color: #ef8632;
    cursor: pointer;
  }

  ${mobile`
    display: none;
  `};

`;

const HeaderRow = styled(Header)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

    color: #ACB5BC;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.71px;
    line-height: 20px;

    >div {
      padding: 10px 20px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;

      ${mobile`
        padding: 10px;
      `};
    }
`;

const TableRow = styled.div`
  display: table-row;

  &:nth-child(2n) {
    background-color: #02174708;
  }

  >div {
    padding: 10px 20px;

    ${mobile`
      padding: 10px;
    `};
  }
`;

const DateCell = styled(LastCell)`
	color: #4A4A4A;
	font-size: 12px;
	line-height: 21px;

    ${mobile`
      display: none;
    `};
`;

const TypeCell = styled(Cell)`
    text-transform: capitalize;
`;

const GreenSpan = styled.span`
  color: #00B200;
`;

const RedSpan = styled.span`
  color: #F00;
`;


export default class TradeHistory extends React.Component {

    static propTypes = {
        pair: PropTypes.string,

        baseCoinConfigName: PropTypes.string,
        quoteCoinConfigName: PropTypes.string,
        recentTrades: PropTypes.array,

        clearAggTradeFeed: PropTypes.func,
        stopAggTradeFeed: PropTypes.func,
        startAggTradeFeed: PropTypes.func,
    };

    static defaultProps = {
        pair: null,
    };


    componentDidMount() {
        const { pair } = this.props;
        this.props.startAggTradeFeed({
            pair: pair,
        });
    }

    componentWillUnmount() {
        this.props.clearAggTradeFeed();
        this.props.stopAggTradeFeed();
    }


    render() {
        const { recentTrades, baseCoinConfigName, quoteCoinConfigName } = this.props;

        return (
            <Wrapper>
                <Table>
                    <HeaderRow>
                        <NoMobileCell>
                            Trade
                        </NoMobileCell>
                        <Cell>
                            Side
                        </Cell>
                        <Cell>
                            Qty
                        </Cell>
                        <Cell>
                            Price
                        </Cell>
                        <LastCell>Time</LastCell>
                    </HeaderRow>
                    {recentTrades && recentTrades.length > 0 && recentTrades.map((trade, i) => (
                        <TableRow key={i}>
                            <NoMobileCell>
                                # {trade.aggTradeId ? trade.aggTradeId : ''}
                            </NoMobileCell>
                            <TypeCell>
                                {trade.buyerMaker ? 'SELL' : 'BUY'}
                            </TypeCell>
                            <Cell>
                                {trade.quantity ? <span>{trade.quantity} {baseCoinConfigName}</span> : ''}
                            </Cell>
                            <Cell>
                                {(trade.positiveMove) ? (
                                    <GreenSpan> &#9650; </GreenSpan>
                                ) : (
                                    <RedSpan> &#9660; </RedSpan>
                                )}
                                {trade.price ? <span>{trade.price} {quoteCoinConfigName}</span> : ''}
                            </Cell>
                            <DateCell>
                                {dateFns.format(new Date(trade.timestamp), 'YYYY/MM/DD HH:mm:ss')}
                            </DateCell>
                        </TableRow>
                    ))}
                </Table>

            </Wrapper>
        );
    }
}
