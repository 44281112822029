import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as CurrencySelector } from '../../../../components/CurrencySelector';
import { Component as Button } from '../../../../components/Button';
import { Component as InputGroup } from '../../../../components/InputGroup';
import { Component as Select } from '../../../../components/Select';
import { findSelected } from '../../../../utils';
import { Title } from '../../../../commonStyles/AccountProfileStyles';

import config from 'config';

const GATEWAY = config.get('api.domain');
const outgoingEvents = config.get('api.outgoingEvents');


const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const UserItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-size: 12px;
`;

const Bold = styled.span`
    font-weight: bold;
    font-size: 18px;
`;

const FlexRow = styled.div`
    // background-color: #BBBBBB;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // padding: 5px;
    // justify-content: space-around;
`;

const StyledInputGroup = styled(InputGroup)`
    width: 350px;
`;

const SubmitButton = styled(Button).attrs({ bsStyle: 'blue' })`
    text-align: center;
    display: block;
    width: 350px;
    margin-top: 20px;
    margin-bottom: 30px;

    a {
        display: inline-flex;
        padding: 18px 35px;
        width: 235px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }
`;

const formatOptions = [
    { value: 'transactions', label: 'Transactions CSV' },
    { value: 'bitcointax', label: 'Bitcoin Tax CSV' },
];

const months = [ '', 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];


export default class ProfileDownloadRecords extends React.Component {

    static propTypes = {
        intl: PropTypes.object,
        assetOptions: PropTypes.array,
    };

    componentDidMount() {
        //
    }

    constructor() {
        super();
        const currentYear = (new Date()).getFullYear();
        const currentMonth = (new Date()).getMonth() + 1;
        this.state = {
            currentYear: currentYear,
            currentMonth: currentMonth,
            selectedInterval: currentYear + '-' + currentMonth,
            selectedFormat: 'transactions',
            selectedAsset: '',
        };
    }

    onChangeFormat = format => {
        this.setState({
            selectedFormat: format.value,
        });
    };

    onChangeInterval = interval => {
        this.setState({
            selectedInterval: interval.value,
        });
    };

    onChangeAsset = asset => {
        this.setState({
            selectedAsset: asset.value,
        });
    };

    getIntervalOptions(firstYear) {
        const { currentYear, currentMonth } = this.state;

        const intervalOptions = [];
        for (let year = currentYear; year >= firstYear; year--) {
            let yearOptions = [];
            let quarterlyOptions = [];
            let monthlyOptions = [];
            if (year === currentYear) {
                yearOptions.push({ value: year, label: year + ' (Year To Date)' });

                if (currentMonth > 9) quarterlyOptions.push({ value: year + '-' + 'Q4', label: year + ' (Q4' + (currentMonth <= 12 ? ' - Quarter To Date' : '') + ')' });
                if (currentMonth > 6) quarterlyOptions.push({ value: year + '-' + 'Q3', label: year + ' (Q3' + (currentMonth <= 9 ? ' - Quarter To Date' : '') + ')' });
                if (currentMonth > 3) quarterlyOptions.push({ value: year + '-' + 'Q2', label: year + ' (Q2' + (currentMonth <= 6 ? ' - Quarter To Date' : '') + ')' });
                quarterlyOptions.push({ value: year + '-' + 'Q1', label: year + ' (Q1' + (currentMonth <= 3 ? ' - Quarter To Date' : '') + ')' });

                for (let month = currentMonth; month >= 1; month--) {
                    monthlyOptions.push({ value: year + '-' + month, label: year + ' (' + months[month] + (month === currentMonth ? ' - Month To Date' : '') + ')' });
                }
            } else {
                yearOptions = [];
                yearOptions.push({ value: year, label: year + ' (Full Year)' });

                quarterlyOptions = [];
                quarterlyOptions.push({ value: year + '-' + 'Q4', label: year + ' (Q4)' });
                quarterlyOptions.push({ value: year + '-' + 'Q3', label: year + ' (Q3)' });
                quarterlyOptions.push({ value: year + '-' + 'Q2', label: year + ' (Q2)' });
                quarterlyOptions.push({ value: year + '-' + 'Q1', label: year + ' (Q1)' });

                monthlyOptions = [];
                for (let month = 12; month >= 1; month--) {
                    monthlyOptions.push({ value: year + '-' + month, label: year + ' (' + months[month] + ')' });
                }
            }
            intervalOptions.push({ options: yearOptions, label: year });
            if (quarterlyOptions.length > 0) intervalOptions.push({ options: quarterlyOptions, label: year + ' Quarterly' });
            intervalOptions.push({ options: monthlyOptions, label: year + ' Monthly' });
        }
        return intervalOptions;
    }

    render() {

        const { intl, assetOptions } = this.props;
        const { selectedInterval, selectedFormat, selectedAsset } = this.state;

        const token = sessionStorage.getItem('token');

        let downloadUrl = '';
        if (selectedFormat === 'transactions') {
            downloadUrl = GATEWAY + '/' +  outgoingEvents['REQUEST_CSV_TRANSACTIONS'];
        } else if (selectedFormat === 'bitcointax') {
            downloadUrl = GATEWAY + '/' +  outgoingEvents['REQUEST_CSV_BITCOINTAX'];
        }

        if (assetOptions.some(e => e.label === 'ALL')) {
            /* already contains "ALL" */
        } else {
            assetOptions.splice(0, 0, { value: '', label: 'ALL' });
        }

        const intervalOptions = this.getIntervalOptions(2018);

        const loading=false;

        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="profile.title_statements_reports"
                        defaultMessage="Statements & Reports"
                    />
                </Title>
                <br/>

                <UserItem>
                    <span>
                        <Bold>
                            <FormattedMessage
                                id="profile.title_download_transaction_report"
                                defaultMessage="Download Transaction Report"
                            />
                        </Bold>
                    </span>
                </UserItem>

                <FlexRow>
                    <StyledInputGroup>
                        <FormattedMessage
                            id="profile.download.assetTitle"
                            defaultMessage="Asset(s)"
                            tagName="label"
                        />
                        <CurrencySelector
                            placeholder={
                                intl.formatMessage({
                                    id: 'profile.download.assetMessage',
                                    defaultMessage: 'Select asset...'
                                })
                            }
                            value={selectedAsset}
                            onSelect={this.onChangeAsset}
                            options={assetOptions}
                            isDisabled={loading}
                        />
                    </StyledInputGroup>
                </FlexRow>

                <FlexRow>
                    <StyledInputGroup>
                        <FormattedMessage
                            id="profile.download.intervalTitle"
                            defaultMessage="Report Interval"
                            tagName="label"
                        />
                        <Select
                            placeholder={
                                intl.formatMessage({
                                    id: 'profile.download.intervalMessage',
                                    defaultMessage: 'Select interval...'
                                })
                            }
                            value={findSelected(intervalOptions, selectedInterval)}
                            onChange={this.onChangeInterval}
                            options={intervalOptions}
                            isDisabled={loading}
                        />
                    </StyledInputGroup>
                </FlexRow>

                <FlexRow>
                    <StyledInputGroup>
                        <FormattedMessage
                            id="profile.download.formatTitle"
                            defaultMessage="File Format"
                            tagName="label"
                        />
                        <Select
                            placeholder={
                                intl.formatMessage({
                                    id: 'profile.download.formatMessage',
                                    defaultMessage: 'Select format...'
                                })
                            }
                            value={formatOptions.find(({value}) => value === selectedFormat )}
                            onChange={this.onChangeFormat}
                            options={formatOptions}
                            isDisabled={loading}
                        />
                    </StyledInputGroup>
                </FlexRow>

                <FlexRow>
                    <form method="POST" action={downloadUrl} target="_blank">
                        <input type="hidden" name="token" value={token} />
                        <input type="hidden" name="interval" value={selectedInterval} />
                        <input type="hidden" name="asset" value={(selectedAsset === '') ? 'ALL' : selectedAsset} />
                        <SubmitButton bsStyle='orange' type="submit" disabled={loading}>
                            <FormattedMessage
                                id="profile.download.downloadButton"
                                defaultMessage="Download"
                            />
                        </SubmitButton>
                    </form>
                </FlexRow>

            </Container>
        );
    }
}
