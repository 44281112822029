import { handleActions } from 'redux-actions';
import queryString from 'query-string';

// const queryString = require('query-string');
const defaultSourceCurrency = queryString.parse(location.search).src ? queryString.parse(location.search).src.toUpperCase() : null;
const defaultDestinationCurrency = queryString.parse(location.search).dest ? queryString.parse(location.search).dest.toUpperCase() : null;

const defaultState = {
    enabledPairs: null,
    coinsConfig: [],
    coinsConfigExtended: [],
    coinsNews: [],

    // the following are used FOR QUICKTRADES - NOT FULL ACCOUNT TRADING
    //
    // currentPair
    // defaultPair
    // partialDepth
    // partialDepthPair

    defaultPair: {
        currencyFrom: null,
        currencyTo: null,
        rate: 0
    },
    currentPair: {
        currencyFrom: defaultSourceCurrency,
        currencyTo: defaultDestinationCurrency,
        rate: 0
    },

    // lastChanged: null
};


const parsePair = (stringPair) => {
    const splitedPair = stringPair.split(/[-_]/g);
    return {
        currencyFrom: splitedPair[0],
        currencyTo: splitedPair[1],
        rate: Number(splitedPair[2])
    };
};

const parseEnginePair = (stringPair) => {
    const splitPair = stringPair.split(/[-_]/g);
    return {
        base: splitPair[0],
        quote: splitPair[1],
        pair: splitPair[0] + '-' + splitPair[1],
        symbol: splitPair[0] + splitPair[1],
        status: splitPair[2],
    };
};

const parseChangePair = (enabledPairs, stringPair) => {
    const splitedPair = stringPair.split(/[-_]/g);
    const rate = enabledPairs ? enabledPairs.find(enabledPair => enabledPair.currencyFrom === splitedPair[0] && enabledPair.currencyTo === splitedPair[1]) ? enabledPairs.find(enabledPair => enabledPair.currencyFrom == splitedPair[0] && enabledPair.currencyTo == splitedPair[1]).rate : 0 : 0;
    return {
        currencyFrom: splitedPair[0],
        currencyTo: splitedPair[1],
        rate: rate
    };
};

const parseCurrentPair = (enabledPairs, coinsConfig, stringPair, from, to) => {
    // console.log(enabledPairs);
    // console.log(coinsConfig);
    // console.log(stringPair);
    // console.log(from);
    // console.log(to);

    if (!enabledPairs || enabledPairs.length === 0) {
        return {
            currencyFrom: undefined,
            currencyTo: undefined,
            rate: 0
        };
    }

    const splitPair = stringPair.split(/[-_]/g);

    from = from && coinsConfig.find(coinConfig => coinConfig.name === from) ? from : splitPair[0];
    to = to && coinsConfig.find(coinConfig => coinConfig.name === to) && to != from ? to : from == splitPair[0] ? splitPair[1] : splitPair[0];

    if (!(enabledPairs && enabledPairs.find(enabledPair => enabledPair.currencyFrom === from && enabledPair.currencyTo === to))) {
        from = enabledPairs[0].currencyFrom;
        to = enabledPairs[0].currencyTo;
    }

    const rate = enabledPairs ? enabledPairs.find(enabledPair => enabledPair.currencyFrom == from && enabledPair.currencyTo == to).rate : 0;
    return {
        currencyFrom: from,
        currencyTo: to,
        rate: rate
    };
};

/*
const getLastChanged = (payload, currentPair) => {
    if(payload.currencyFrom !== currentPair.currencyFrom) {
        return 'FROM';
    }
    if(payload.currencyTo !== currentPair.currencyTo) {
        return 'TO';
    }
    return null;
};
*/

export default handleActions(
    {
        ['EXCHANGE_CONFIG']: (state, action) => {
            const config = action.payload;

            let coinsNews = [];
            if (config.coinsConfig != null) {
                config.coinsConfig.forEach(function (coinConfig) {
                    // trigger on description or news
                    if (coinConfig.news) {
                        const newCoinNews = {
                            name: coinConfig.name,
                            news: coinConfig.news,
                        };
                        coinsNews.push(newCoinNews);
                        delete coinConfig.news;
                    }
                });
            }

            let coinsConfigExtended = [];
            if (config.coinsConfig != null) {
                config.coinsConfig.forEach(function (coinConfig) {
                    // trigger on description or news
                    if (coinConfig.description != null && coinConfig.description.length !== 0) {
                        const newExtendedCoinConfig = {
                            name: coinConfig.name,
                            description: coinConfig.description,
                        };
                        coinsConfigExtended.push(newExtendedCoinConfig);
                        delete coinConfig.description;
                    }
                });
            }

            if (config.defaultPair != null) {
                return {
                    ...state,
                    enabledPairs: config.enabledPairs.map(parsePair),
                    enginePairs: config.enginePairs.map(parseEnginePair),
                    coinsConfig: config.coinsConfig,
                    coinsConfigExtended: (coinsConfigExtended.length > 0) ? coinsConfigExtended : state.coinsConfigExtended,
                    coinsNews: (coinsNews.length > 0) ? coinsNews : state.coinsNews,
                    defaultPair: parsePair(config.defaultPair),
                    currentPair: parseCurrentPair(config.enabledPairs.map(parsePair), config.coinsConfig, config.defaultPair, defaultSourceCurrency, defaultDestinationCurrency),
                    locationConfig: config.locationConfig,
                    maintenanceMode: config.maintenanceMode,
                    tradeLocked: config.tradeLocked
                };
            } else if (config.maintenanceMode != null) {
                return {
                    ...state,
                    maintenanceMode: config.maintenanceMode
                };
            } else if (config.tradeLocked != null) {
                return {
                    ...state,
                    tradeLocked: config.tradeLocked
                };
            }
        },
        ['CHANGE_PAIR_SUCCESS']: (state, action) => {
            const pair = parseChangePair(state.enabledPairs, action.newPair);

            return {
                ...state,
                currentPair: pair,
                // lastChanged: getLastChanged(pair, state.currentPair),
            };
        },
        ['ONE_WAY_PARTIAL_DEPTH']: (state, action) => {
            const pair = parseChangePair(state.enabledPairs, action.payload.pair);
            const depthPair = action.payload.pair;
            const partialDepth = action.payload.partialDepth;

            if (partialDepth != null) {
                return {
                    ...state,
                    currentPair: pair,
                    partialDepthPair: depthPair,
                    partialDepth: partialDepth
                };
            } else {
                return {
                    ...state
                };
            }
        }
    },
    defaultState
);
