import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { mobile } from '../../styles';

import { Container as Header } from '../../components/Header';
import { Container as Footer } from '../../components/Footer';


const Grid = styled.div`
  height: 100%;
  display: flex;
  // flex-flow: row wrap;
  // flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.narrow ? '760px' : '1100px'};

  // > * {
  //   flex: 1 100%;
  // }

  ${mobile`
    max-width: 760px;
  `}
`;

const ContentPane = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  // box-shadow: 0 0 26px -14px rgba(11,32,78,0.85);
  // covers up tooltips... overflow: hidden;

  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;

  padding: 0px;

  display: flex;
  flex-direction: ${props => props.row ? 'row' : 'column'};

  > * {
    flex: 1;
  }
`;


export default class StandardPage extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        narrow: PropTypes.bool,
        row: PropTypes.bool,
    };

    render() {
        return (
            <Grid narrow={this.props.narrow}>
                <Header />
                <ContentPane row={this.props.row}>
                    {this.props.children}
                </ContentPane>
                <Footer />
            </Grid>
        );
    }

}
