import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionScreen from './TransactionScreen';
import { subscribeOnTransactionUpdates, changePair, stopOneWayPairFeed } from '../../actions/transactions';

function mapStateToProps(state) {
    return {
        transaction: state.transactions.transactionState,
        transactionId: state.transactions.transactionId,
        isConnected: state.ws.state === 'CONNECTED',
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            subscribeOnTransactionUpdates,
            changePair,
            stopOneWayPairFeed,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionScreen);
