import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    HeroSlogan,
    HeroSection,
    HeroTagLine,
    HeroContent,
    RightArrow,
    BottomArrow,
    LeftArrow
} from '../../commonStyles/MarketingPageStyles';
import { Link } from 'react-router-dom';

import heroImg from '../../images/cryptoX2.png';
import walletStatus from '../../images/walletStatus.png';
import orangeback from '../../images/orangeback.png';
import reportScreenshot from '../../images/report_screenshot.png';
import profileScreenshot from '../../images/profile_screenshot.png';
import balancesReportScreeshot from '../../images/balances_report_screenshot.png';
import proofingToolScreenshot from '../../images/proofingToolScreenshot.png';

import { Container as Footer } from '../../components/Footer';
import { Container as Header } from '../../components/Header';


const InternalLink = styled(Link)`
  color: #ef8632;
  text-decoration: none;
`;

const ExternalLink = styled.a`
  color: #ef8632;
  text-decoration: none;

  a:visited {
    color: #ef8632;
  }
`;

export const Section = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const ProofSentHashBox = styled.div`
    position: relative;
    margin: 20px 100px 50px 100px;
    @media (max-width: 1280px) {
      width: 95%;
      overflow: auto;
      margin: auto;
    }
`;

const OrangeTitle = styled.h2`
    color: #EF8632;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 40px;
    font-style: normal;
    max-width: 1100px;
    font-weight: 700;
    line-height: 48px; /* 120% */
    @media (max-width: 1100px) {
        font-size: 28px;
        margin: 20px 50px;
        }
    @media (max-width: 800px) {
        font-size: 24px;
        margin: 20px
    }
`;

const Container = styled.div`
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
`;

const HowContainer = styled(Container)`
    flex-direction: column;
    margin: auto;
`;

const ShadowBoxMargin = styled.div`
    margin: 45px;
    @media (max-width: 1100px) {
        margin: 25px;
        }
`;

export const HerProofCont = styled.div`
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #021747;
    background-blend-mode: lighten;
    background-image: url(${heroImg});
    background-repeat: no-repeat;
    background-position-x: 33.75rem;
    background-position-y: center;
    @media (max-width: 1280px) {
        background-position-x: 25rem ;
    }
     @media (max-width: 990px) {
        background-position-x: 20rem;
     }
     @media (max-width: 800px) {
       background: none;
     }
`;

const HowHeader = styled.h2`
    color: #021747;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin: 120px 40px 80px 40px;
    font-size: 60px;
    line-height: 42px; 
    @media (max-width: 1024px) {
        font-size: 40px;
        }
    @media (max-width: 800px) {
        margin:40px
        }
`;

const LargeParagraph = styled.p`
    color: #262626;
    font-family: Inter, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; 
    max-width: 520px;
    margin: 10px 20px;
    @media (max-width: 1200px) {
        font-size: 24px;
        max-width: 400px;
        }
    @media (max-width: 800px) {
        max-width: 90%;
        margin: 20px;
        }
`;

const Shadow = styled.div`
    border-radius: 10px;
    background: #FFF;
    box-shadow: -17px -29px 40px 0px rgba(2, 23, 71, 0.10);
    position: relative;
`;

const ShadowBox = styled(Shadow)`
    width: 650px;
    flex-shrink: 2;
    margin:0px 20px;
    @media (max-width: 1100px) {
        width: 400px;
        
        }
    @media (max-width: 800px) {
        width:95%;
        
        }
`;

const ShadowHashBox = styled(Shadow)`
  margin-bottom: 30px;
     @media (max-width: 1280px) {
        width: 95%;
        
        }
`;

const FlexBox = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 50px 0px; 
    
    @media (max-width: 800px) {
        flex-direction: column;
        margin: 20px 0px; 
        }
`;

const FlexBoxRev = styled(FlexBox)`
    @media (max-width: 800px) {
        flex-direction: column-reverse;
        }

`;

const ProofSentHeader = styled.p`
    color: #262626;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ProofSentence = styled.p`
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
    margin: 25px 0px 25px 0px;
`;

const SubLine = styled.p`
    color: #262626; 
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    @media (max-width: 1100px) {
        font-size: 14px;
        }
`;

const WeHashSent = styled.h3`
    color: #262626;
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 150% */
    max-width: 1080px;
    @media (max-width: 1100px) {
       width: auto;
       margin: 50px;
       font-size: 28px;
        }
    @media (max-width: 800px) {
       width: auto;
       margin: 20px;
       font-size: 24px;
    }
    
`;

const HashedString = styled.p`
    color: #000;
    width: max-content;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 1100px) {
        font-size: 16px;
        }
    @media (max-width: 900px) {
        font-size: 14px;
        }
`;

const OrangeBack = styled.img`
    position: absolute;
    z-index: -1;
    transform: scale(-1) translate(335px, 369px);
    @media (max-width: 800px) {
        transform: scale(-0.8) translate(390px, 503px);
        }

`;

BackgroundBox.propTypes = {
    bgColor: PropTypes.string,
    subText: PropTypes.string,
    children: PropTypes.children,
    flexShrink: PropTypes.string,
};


function BackgroundBox({ bgColor, subText, children, flexShrink }) {

    const P = styled.p`
        padding:10px;
        
        font-size: 16px;
        
        @media (max-width: 1100px) {
          font-size: 14px;
        }
    `;

    const BoxSml = styled.div`
        text-align: center;
        position: absolute;
        bottom:0;
        left: 0;
        width: 100%;
        transform: translate(0px, 90%);
    `;

    return (
        <div style={{ position: 'relative', flexShrink: flexShrink, height: '128px', backgroundColor: bgColor, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <P >
                {children}
            </P>
            <BoxSml>
                <SubLine>
                    {subText}
                </SubLine>
            </BoxSml>
        </div >
    );
}

export default class proofOfReserves extends React.Component {

    static propTypes = {
        location: PropTypes.object,
    };

    componentDidMount() {
        document.title = 'Proof of reserves explained';
        window.gtag('event', 'page_view', {
            page_path: this.props.location.pathname + this.props.location.search + this.props.location.hash,
            page_search: this.props.location.search,
            page_hash: this.props.location.hash,
        });
    }

    render() {

        return (
            <div style={{ display: 'block', width: '100%' }}>
                <div style={{ maxWidth: '1440px', margin: 'auto' }}>
                    <Header />
                </div>
                <HeroSection>
                    <HerProofCont>
                        <HeroContent>
                            <HeroSlogan>
                                Proof of Reserves
                            </HeroSlogan>
                            <HeroTagLine>
                                We&apos;ve created a system which allows us to prove to the world that our reserves are 100% backed. For maximum confidence it relies on community participation.
                            </HeroTagLine>
                        </HeroContent>
                    </HerProofCont>
                </HeroSection>

                <Section>

                    <HowContainer>
                        <OrangeBack src={'/' + orangeback}  />

                        <HowHeader>
                            How Does It Work?
                        </HowHeader>
                        <FlexBox>
                            <LargeParagraph>
                                Wallets holding our reserves are public and trackable in real time on our <InternalLink to='/walletStatus'>Wallet Status</InternalLink> page.
                            </LargeParagraph>
                            <ShadowBox>
                                <ShadowBoxMargin>
                                    <InternalLink to='/walletStatus'><img src={'/' + walletStatus} style={{ width: '100%' }} /></InternalLink>
                                </ShadowBoxMargin>
                                <RightArrow />
                            </ShadowBox>
                        </FlexBox>
                        <FlexBox>
                            <LargeParagraph  >
                                Each user has a unique Proof Sentence consisting of their obfuscated email (or custom phrase), unique identifier, and balances per coin.
                            </LargeParagraph>
                            <ShadowBox>

                                <ShadowBoxMargin>
                                    <ProofSentHeader>
                                        The Proof Sentence is visible to the user only
                                    </ProofSentHeader>
                                    <ProofSentence>
                                        The BlocPal client identified as <span style={{ color: '#D2CC35' }}>[v***@gmail.com]</span> and Account Public Code <span style={{ color: '#4FBF68' }}>[DTX6QV9YGR6ZTKQD]</span> was included in the [27 June 2023] audit and had balances of <span style={{ color: '#F12929' }}>[0.04863839 ETH:325.86717980 USDC:384.99643942 EURT20:202.07618272 USDT20]</span> on deposit at the time.
                                    </ProofSentence>
                                    <SubLine>
                                        * Not a real Proof Sentence, for reference only. Log into your account to customize your personal Proof Sentence.
                                    </SubLine>
                                </ShadowBoxMargin>
                                <RightArrow />

                            </ShadowBox>
                        </FlexBox>
                        <WeHashSent>
                            Every quarter we <span style={{ color: '#2715F4' }}>hash each user&apos;s Proof Sentence</span>, snapshot the <span style={{ color: '#1BC000' }}>user&apos;s balances</span>, add them in clear text, and publish the results line by line in a Proof Report file, where every user is represented by one line.
                        </WeHashSent>

                        <ShadowHashBox>
                            <div style={{ position: 'absolute', top: '-20px', left: '50%' }}><BottomArrow /></div>
                            <ProofSentHashBox>
                                <HashedString>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <SubLine>
                                            Proof Sentence hash
                                        </SubLine>
                                        <SubLine>
                                            User balances
                                        </SubLine>
                                        <SubLine>
                                            Marketing code
                                        </SubLine>
                                    </div>
                                    <span style={{ color: '#2715F4' }}>f3aba4...be0b13</span>,<span style={{ color: '#1BC000' }}>0.04863839 ETH:325.86717980 USDC:384.99643942 EURT20:202.07618272 USDT20</span>,<span style={{ color: '#FA1414' }}>HDcUfT</span>
                                </HashedString>
                            </ProofSentHashBox>
                        </ShadowHashBox>

                        <FlexBoxRev>
                            <ShadowBox>
                                <ShadowBoxMargin>
                                    <img src={'/' + profileScreenshot} style={{ width: '100%' }} />
                                </ShadowBoxMargin>
                                <LeftArrow />
                            </ShadowBox>
                            <LargeParagraph>
                                For each Proof Report, we save a snapshot of the Proof Sentence in the user’s profile.
                            </LargeParagraph>
                        </FlexBoxRev>

                        <FlexBox>
                            <LargeParagraph  >
                                You can download the <ExternalLink href='https://github.com/Blocpal-Inc/proof-of-reserves/blob/master/audit_files/' target='_blank'>Proof Report</ExternalLink>, and sum everyone&apos;s balances and compare them to our reserves to confirm that we are 100% backed.
                            </LargeParagraph>
                            <ShadowBox>
                                <ShadowBoxMargin>
                                    <ExternalLink href='https://github.com/Blocpal-Inc/proof-of-reserves/blob/master/audit_files/' target='_blank'><img src={'/' + balancesReportScreeshot} style={{ width: '100%' }} /></ExternalLink>
                                </ShadowBoxMargin>
                                <RightArrow />
                            </ShadowBox>
                        </FlexBox>

                        <FlexBoxRev>
                            <ShadowBox>
                                <ShadowBoxMargin>
                                    <img src={'/' + reportScreenshot} style={{ width: '100%' }} />
                                </ShadowBoxMargin>
                                <LeftArrow />
                            </ShadowBox>
                            <LargeParagraph>
                                The report is cryptographically sealed from tampering by computing a Merkle tree with each line as a leaf and publishing the root on GitHub.
                            </LargeParagraph>
                        </FlexBoxRev>

                        <WeHashSent>
                            You can also find your entry in the Proof Report by searching for your Proof Sentence hash. If the balances are equal, your coins have been represented in the report correctly.
                        </WeHashSent>
                        <ShadowHashBox>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '30px', height: '200px', overflowX: 'scroll', overflowY: 'hidden' }}>

                                <BackgroundBox bgColor='#02174708' subText='Snapshot of Proof Sentence balances' flexShrink='0'>
                                    <strong>Balances</strong><br />
                                    0.04863839 ETH<br />
                                    325.8671798 USDC<br />
                                    384.99643942 EURT20<br />
                                    202.07618272 USDT20
                                </BackgroundBox>

                                <p style={{ fontSize: '40px' }}>=</p>

                                <BackgroundBox bgColor='#FFE76A' subText='Your Proof Sentence hash'>
                                    f3aba4...be0b13
                                </BackgroundBox>

                                <BackgroundBox bgColor='#9AFF6A' subText='Your Reported balances' flexShrink='0'>
                                    0.04863839 ETH:325.86717980 USDC:384.99643942 EURT20:202.07618272 USDT20
                                </BackgroundBox>
                            </div>
                        </ShadowHashBox>

                        <OrangeTitle>
                            The more people complete this procedure, the more confident the community can be that everyone’s balances are represented in the report accurately.
                        </OrangeTitle>

                        <FlexBox>
                            <LargeParagraph  >
                                We provide an open-source <ExternalLink href="https://blocpal-inc.github.io/proof-of-reserves/" target='_blank'>Verification Tool</ExternalLink> which makes it easy to participate automatically!
                            </LargeParagraph>
                            <ShadowBox>
                                <ShadowBoxMargin>
                                    <ExternalLink href='"https://blocpal-inc.github.io/proof-of-reserves/' target='_blank'><img src={'/' + proofingToolScreenshot} style={{ width: '100%' }} /></ExternalLink>
                                </ShadowBoxMargin>
                                <RightArrow />
                            </ShadowBox>
                        </FlexBox>
                        <OrangeTitle>
                            Even if you don’t trust the code, you can easily replicate it yourself using publicly available third-party tools.
                        </OrangeTitle>
                        <WeHashSent>
                            For a full technical dive into the BlocPal Proof Of Reserves protocol visit our <ExternalLink href='https://github.com/Blocpal-Inc/proof-of-reserves' target='_blank'>GitHub Repository</ExternalLink>.
                        </WeHashSent>
                    </HowContainer>
                </Section >
                <div style={{ maxWidth: '1440px', margin: 'auto' }}>
                    <Footer />
                </div>
            </div >
        );

    }
}
