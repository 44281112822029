import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles';
import { Component as StandardPage } from '../../components/StandardPage';
import PropTypes from 'prop-types';


const Content = styled.div`
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  // line-height: 18px;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: 20px;
  margin-right: 20px;
  // padding-left: 2em;
  // padding-right: 2em;
  // width: 95%;

  display: flex;
  flex-wrap: wrap;

  ${mobile`
      margin-left: 10px;
      margin-right: 10px;
  `}
`;


class PrivacyText extends React.Component {


    render() {
        return (
            <Content>
                <div>
                    <header>
                        <h1>Canadian Privacy Policy</h1>
                    </header>
                    <div>
                        <h2>Our Commitment</h2>
                        <p>BlocPal is committed to the protection of personal information (PI) in its possession and adheres to prescribed and
                            best practices to ensure appropriate methods are followed in the collection, use, security and access to information
                            collected from customers and potential customers. To that end, it is the responsibility of every employee (including
                            contract and part time employees) to comply with this program and with Personal Information Protection and Electronic
                            Documents Act (PIPEDA), legislation related to PIPEDA and applicable provincial and/or territorial<a href="#fn1" className="footnote-ref" id="fnref1"><sup>1</sup></a>
                            privacy laws as required.</p>
                        <p>Our procedures for implementing this policy are described in separate documents.</p>
                        <p>There are also separate training materials for staff, which include quizzes designed to test the effectiveness of
                            training.</p>
                        <p>In addition, we assure our customers that we uphold their privacy with the utmost respect. Below is our Privacy
                            Policy to our customers:</p>
                        <p>At BlocPal, we respect your privacy and take pride in protecting your personal information. The below demonstrates
                            our commitment.</p>
                        <h3>Consent</h3>
                        <p>Unless permitted or required by law, we will not collect use or share personal information without first obtaining
                            your consent. We will obtain consent when we want to use personal information for a new purpose or for a purpose other
                            than that which was stated at the time of collection.</p>
                        <p>You may withdraw your consent for certain purposes. For example, if you choose not to receive information related to
                            our products services or promotions.</p>
                        <h3>Personal Information</h3>
                        <p>Personal Information is information that identifies you as an individual. This information may include but is not
                            limited to your name, address, phone number, tax id number, bank account number, blockchain wallet address, credit
                            card information, photograph, occupation, certain financial information, and your e-mail address. Personal Information
                            does not include business contact information used for the purpose of communicating or facilitating communication with
                            you, as an individual, in relation to your employment, business or profession.</p>
                        <h3>Collection</h3>
                        <p>We collect your personal information in a variety of ways such as during the course of initiating a trade, account
                            signup, changing of your account profile information, account verification, deposits and withdrawals, servicing of
                            your account and other interactions with our services. We may collect Personal Information from you directly or from
                            third parties as required or permitted by law. Additionally, we may obtain personal information for promotions or
                            events you have signed up for.</p>
                        <h3>Purpose</h3>
                        <p>We identify the purpose for which personal information will be used at or before the time the information is
                            collected. We collect personal information for the following purposes:</p>
                        <ul>
                            <li><p>Provision of services</p></li>
                            <li><p>Targeted marketing</p></li>
                            <li><p>Fulfill regulatory requirements</p>
                            </li></ul>
                        <h3>How We Share</h3>
                        <p>We may share personal information for the purposes described at the time we obtain consent. This may include sharing
                            of your personal information with third parties’ vendors and government agencies as needed in order to provide service
                            to you.</p>
                        <h3>How We Protect</h3>
                        <p>We strive to maintain appropriate physical and technical safeguards to ensure no loss, misuse or unauthorized access
                            or modification of your personal information on file. Records are kept electronically on servers in our British
                            Columbia offices, on servers in Canada managed on our behalf by third parties, and on servers managed by our
                            third-party vendors and partners.</p>
                        <h3>Complaints </h3>
                        <p>We have established a privacy office and have appointed a senior officer as our privacy officer to ensure that
                            everyone at BlocPal complies with our principles for protecting personal information. If you have any questions,
                            concerns or complaints contact BlocPal at <a href="mailto:info@blocpal.com"><span className="underline">info@blocpal.com</span></a>.
                        </p>
                        <h1>Regulatory Background</h1>
                        <br />
                        <h2>Privacy and Related Legislation</h2>
                        <p>The Personal Information Protection and Electronic Documents Act (PIPEDA) sets out the requirements that BlocPal must
                            use to collect, use or disclose personal information in the course of its commercial activities, including information
                            about employees<a href="#fn2" className="footnote-ref" id="fnref2"><sup>2</sup></a>. In addition, some provinces have
                            privacy laws and have additional requirements that must be followed.</p>
                        <p>Additional legislation, that has privacy related components, that set out other requirements that BlocPal must comply
                            with are the “National Do Not Call List” (DNCL), Telemarketing Rules, and Canada’s Anti-Spam legislation (CASL). The
                            requirements in these pieces of legislation guide the way we can market to potential customers and how we communicate
                            with our existing customers.</p>
                        <h2>The Regulators</h2>
                        <p>The Office of the Privacy Commissioner of Canada (OPC), also referred to as the Privacy commissioner, oversees
                            compliance with PIPEDA. Their mission is to protect and promote the privacy rights of individuals which is done
                            by:</p>
                        <ul>
                            <li><p>Investigating complaints;</p></li>
                            <li><p>Conducting audits and pursuing court action under federal privacy law;</p></li>
                            <li><p>Publicly reporting on the personal information handling practices of public and private sector
                                organizations;</p></li>
                            <li><p>Supporting, undertaking and publishing research into privacy issues; and</p></li>
                            <li><p>Promoting public awareness and understanding of privacy issues.</p></li>
                        </ul>
                        <p>The OPC is also tasked with investigating violations related to CASL, in particular,</p>
                        <ul>
                            <li><p>The harvesting of electronic addresses, in which email list are compiled through the use of computer programs
                                to automatically mine the Internet for email addresses; and,</p></li>
                            <li><p>The collection of personal information through illicit access to other computer systems (i.e. through
                                spyware).</p></li>
                        </ul>
                        <p>Each province and territory in Canada has a commissioner or ombudsman responsible for overseeing provincial and
                            territorial privacy legislation. In most cases, these individuals or bodies defer to the OPC.</p>
                        <p>The Canadian Radio-television and Telecommunications Commission (CRTC) oversees compliance with the National Do Not
                            Call List and Telemarketing Rules and CASL. They are responsible for investigating the sending of unsolicited
                            commercial electronic messages, unsolicited telecommunications and the installation of software without consent.</p>
                        <h2>Regulator Audits </h2>
                        <p>The OPC conducts audits to ensure companies are sufficiently managing personal information in its possession. These
                            audits may look at the physical and security controls in place, what policies and procedures exist, and how the
                            organization manages privacy issues. Through these reviews, the OPC can identify areas for improvement which may
                            result in public disclosure or compliance agreements.</p>
                        <p>The OPC has the power to receive, initiate, investigate and attempt to resolve complaints about any aspect of
                            BlocPal’ s compliance with PIPEDA provisions.&nbsp;</p>
                        <p>The CRTC has the power to receive, initiate, investigate and attempt to resolve complaints about any aspect of
                            BlocPal’s compliance with CASL and Do Not Call List and Telemarketing provisions under which they can leave decisions,
                            notices and orders in respect to non-compliance.</p>
                        <h1>Roles and Responsibilities</h1>
                        <p>Everyone has a responsibility to ensure that our privacy compliance program runs smoothly.</p>
                        <ul>
                            <li><p><strong>Board of Directors &amp; Senior Management</strong>:</p>
                                <ul>
                                    <li><p>Ensure occurrences of non-compliance with this policy, associated procedures and applicable governing
                                        legislation are appropriately addressed;</p></li>
                                    <li><p>Approve the Privacy Officer’s appointment;</p></li>
                                    <li><p>Approve a CASL point person; and</p></li>
                                    <li><p>Reviewing and approving amendments to this policy, as required but at least annually.</p></li>
                                </ul>
                            </li>
                            <li><p><strong>Privacy Officer</strong>:</p>
                                <ul>
                                    <li><p>Developing and maintaining this policy, which includes regularly reviewing and updating and maintaining a
                                        record of all updates;</p></li>
                                    <li><p>Ensuring effective procedures and controls are in place in order to meet privacy obligations under law;</p>
                                    </li>
                                    <li><p>Ensuring that all employees and other relevant parties received appropriate privacy and privacy related
                                        training;</p></li>
                                    <li><p>Reporting the Senior Management and the Board of Directors (if applicable) on the status of the privacy
                                        program, including any issues of non-compliance with governing legislation;</p></li>
                                    <li><p>Maintaining records in accordance with privacy legislation;</p></li>
                                    <li><p>Corresponding with the OPC and other regulators as required;</p></li>
                                    <li><p>Conduct Privacy Impact Assessments as required; and</p></li>
                                    <li><p>Developing breach and incident management response protocols, initiating them when necessary.</p></li>
                                </ul>
                            </li>
                            <li><p><strong>All Employees</strong>:</p>
                                <ul>
                                    <li><p>Complying with the requirements set out in the Privacy program;</p></li>
                                    <li><p>Reporting privacy concerns, including potential breaches and any new initiatives that have privacy
                                        considerations, to the Privacy Officer; and</p></li>
                                    <li><p>Complete privacy and privacy related (i.e. CASL) training as required.</p></li>
                                </ul>
                            </li>
                        </ul>
                        <h1>Privacy Compliance Program Requirements </h1>
                        <p>As an organization subject to PIPEDA BlocPal is required to comply with the 10 privacy principles.&nbsp; BlocPal is
                            responsible for the protection of personal information and the safeguarding of it, extending to third parties BlocPal
                            may deal with.&nbsp; BlocPal is required to obtain an individual’s consent when collecting, using or disclosing the
                            individual’s personal information and the purpose for which we are collecting, using or disclosing must be disclosed
                            when obtaining that consent.</p>
                        <h2>Principle 1 – Accountability</h2>
                        <p>BlocPal is responsible for the personal information it holds and must designate a Privacy Officer that is accountable
                            for compliance with the following principles.</p>
                        <p>Under the “Accountability” principle BlocPal must:</p>
                        <ul>
                            <li><p>Designate a Privacy Officer to be accountable for BlocPal’s compliance with the principles set out below;</p>
                            </li>
                            <li><p>Make the identity of the Privacy Officer known;</p></li>
                            <li><p>Protect all personal information in BlocPal ‘s possession, including information that has been transferred to a
                                third party;</p></li>
                            <li><p>Use contractual or other means to ensure protection of personal information which has been passed to a third
                                party;</p></li>
                            <li><p>Develop and implement procedures to uphold the 10 principles;</p></li>
                            <li><p>Establish procedures for receiving and responding to complaints and inquiries – including information access
                                request; and</p></li>
                            <li><p>Train staff and ensure dissemination of BlocPal’s procedures related to privacy.</p></li>
                        </ul>
                        <h2>Principle 2 – Identifying Purposes</h2>
                        <p>The purposes for which personal information is collected must be identified at or before the time it is
                            collected.</p>
                        <p>Under the “Identifying Purposes” principle BlocPal must:</p>
                        <ul>
                            <li><p>Identify why personal information is being collected at the time of or before collection;</p></li>
                            <li><p>Disclose the purposes for collecting personal information; and</p></li>
                            <li><p>Notify customers, before using personal information for any purpose not identified at the time of
                                collection.</p></li>
                        </ul>
                        <h2>Principle 3 – Consent </h2>
                        <p>The consent of an individual is required for the collection, use or disclosure of personal information.</p>
                        <p>Under the “Consent” principle, BlocPal must:</p>
                        <ul>
                            <li><p>Obtain the individual’s consent for any collection, use, or disclosure of personal information ensuring that
                                the individual understands;</p></li>
                            <li><p>Advise of the purposes for which personal information will be used for or disclosed ensuring the individual can
                                reasonably understand what their personal information will be used for;</p></li>
                            <li><p>Obtain consent before using personal information for other purpose not identified at the time of
                                collection;</p></li>
                            <li><p>Never require an individual to consent, as a condition of supplying a product or service, beyond what is
                                necessary to fulfill on the product or service;</p></li>
                            <li><p>Consider the reasonable expectations of the individual in obtaining consent;</p></li>
                            <li><p>Never obtain consent through deception;</p></li>
                            <li><p>Consider the sensitivity of the personal information when determining how you will obtain consent; and</p></li>
                            <li><p>Allow the individual to withdraw consent at any time and inform of any consequences of withdrawing consent.</p></li>
                        </ul>
                        <h2>Principle 4 – Limiting Collection </h2>
                        <p>The collection of personal information is limited to that which is necessary for the purposes identified by
                            BlocPal.</p>
                        <p>Under the “Limiting Collection” principle BlocPal must:</p>
                        <ul>
                            <li><p>Limit its collection of personal information (type and amount) to what is necessary for identified
                                purposes;</p></li>
                            <li><p>Never collect personal information indiscriminately;</p></li>
                            <li><p>Collect personal information without misleading; and</p></li>
                            <li><p>Document the types of information we will collect.</p></li>
                        </ul>
                        <h2>Principle 5 – limiting use, Disclosure, And Retention </h2>
                        <p>Personal information shall not be used or disclosed for purposes other than those for which it was collected, except
                            with the consent of the individual or as required under law. Personal information shall be retained only as long as
                            necessary.</p>
                        <p>Under the “Limiting Use, Disclosure, and Retention” principle BlocPal must:</p>
                        <ul>
                            <li><p>Never use or disclose personal information for purposes other than those for which it was collected, except
                                with the consent of the individual or as required by law;</p></li>
                            <li><p>Retain personal information only as long as necessary;</p></li>
                            <li><p>Develop procedures regarding the disposal of personal information;</p></li>
                            <li><p>Assess risks of destroying personal information on or off-site;</p></li>
                            <li><p>Destroy, erase, or anonymize personal information that is no longer needed;</p></li>
                            <li><p>Develop and implement procedures regarding the retention of personal information; and</p></li>
                            <li><p>Include minimum and maximum retention periods in the procedures.</p></li>
                        </ul>
                        <h2>Principle 6 – Accuracy </h2>
                        <p>Personal information should be accurate and up-to-date for the purposes for which it is to be used.</p>
                        <p>Under the “Accuracy” principle, principle BlocPal must:</p>
                        <ul>
                            <li><p>Ensure that personal information is as accurate and up-to-date;</p></li>
                            <li><p>Update personal information if necessary to fulfill the purposes for which the information was collected;
                                and</p></li>
                            <li><p>Ensure that information is sufficiently accurate and up-to-date to ensure fair decisions about individuals.</p></li>
                        </ul>
                        <h2>Principle 7 – Safeguards</h2>
                        <p>Personal information must be safeguarded appropriately based on the sensitivity of the information.</p>
                        <p>Under the “Safeguards” principle, BlocPal must:</p>
                        <ul>
                            <li><p>Protect personal information by security safeguards appropriate to the sensitivity of the information;</p></li>
                            <li><p>Institute security safeguards that will protect personal information against loss, theft, unauthorized access,
                                copying, modification, etc.;</p></li>
                            <li><p>Protect personal information regardless of the format in which it is held;</p></li>
                            <li><p>Ensure staff is aware of the importance of maintaining the confidentiality of personal information;</p></li>
                            <li><p>Use care to prevent unauthorized access when destroying or disposing of personal information; and</p></li>
                            <li><p>Include physical controls such as locked filing cabinets and restricted access to offices use of passwords and
                                encryption.</p>
                            </li>
                        </ul>
                        <h2>Principle 8 – Openness</h2>
                        <p>BlocPal must make available to individuals specific information about its policies and practices relating to the
                            management of personal information.</p>
                        <p>Under the “Openness” principle, BlocPal must:</p>
                        <ul>
                            <li><p>Be open about practices relating to the management of personal information;</p></li>
                            <li><p>Make specific information regarding the management of personal information readily to your clients and
                                customers;</p></li>
                            <li><p>Include the name or title, and address, of the person who is accountable for the Privacy program and where
                                complaints or inquiries can be sent; and</p></li>
                            <li><p>Describe how to gain access to personal information it holds.</p></li></ul>
                        <h2>Principle 9 – Individual Access </h2>
                        <p>Upon written request, BlocPal must inform individuals of the existence, use, and disclosure of their personal
                            information and must give access to that information. Individuals can challenge the accuracy and completeness of the
                            information.</p>
                        <p>Under the “Individual Access” principle BlocPal must:</p>
                        <ul>
                            <li><p>On written request, inform individuals of the existence, use, and disclosure of their personal information, and
                                give access to that information, except as specified in Section 9 of PIPEDA such as in doing so would likely reveal
                                personal information about a third party;</p></li>
                            <li><p>Allow individuals to challenge the accuracy of their personal information;</p></li>
                            <li><p>Upon receiving a request in writing inform individuals (via an acknowledgment letter) if we hold personal
                                information about them;</p></li>
                            <li><p>Allow access to the information, providing details of how BlocPal has used or will use it including if the
                                information has been passed to third parties;</p></li>
                            <li><p>Respond to the access request within 30 days after receiving the request. Note the acknowledgment letter does
                                not constitute a response;</p></li>
                            <li><p>Where more than 30 days is required, we must send the individual a notice within 30 days, advising of the new
                                time limit and the reasons for the extension, and inform them they have the right to complain to the OPC;</p></li>
                            <li><p>Provide requested information in a format that is easy to understandable;</p></li>
                            <li><p>Respond to access request with no cost to the individual;</p></li>
                            <li><p>If you refuse an access request, inform the individual in writing, along with the reasons and inform them they
                                have the right to complain to the OPC;</p></li>
                            <li><p>Keep records for access request for as long as necessary;</p></li>
                            <li><p>When an individual proves there is inaccuracy of personal information, we must amend the information as
                                required; and</p></li>
                            <li><p>When a challenge is not resolved, a record of the details of the unresolved challenge should be kept.</p></li></ul>
                        <h2>Principle 10 – Challenging Compliance</h2>
                        <p>An individual is able to challenge compliance with the above principles to BlocPal’s Privacy Officer.</p>
                        <p>Under the “Challenging Compliance” principle, BlocPal must:</p>
                        <ul>
                            <li><p>BlocPal must have in place procedures for receiving and responding to complaints regarding the handling of
                                personal information;</p></li>
                            <li><p>Establish complaint procedures that are easily accessible and easy to use;</p></li>
                            <li><p>Inform complainants that there are complaint procedures;</p></li>
                            <li><p>Investigate all complaints; and</p></li>
                            <li><p>Take appropriate action if a complaint is found to be justified, such as correcting the issue and amending
                                procedures as necessary.</p></li>
                        </ul>
                        <h1>Privacy Impact Assessments <a href="#fn3" className="footnote-ref" id="fnref3"><sup>3</sup></a></h1>
                        <p>A Privacy Impact Assessments (PIA) is a tool that helps ensure privacy is a core consideration when a new project or
                            change in BlocPal’s business model is being planned and implemented. PIAs are meant to describe and document what
                            personal information will be involved and what privacy controls are needed to be in place to ensure compliance.</p>
                        <p>The 10 privacy principles, described above, guide how a PIA is conducted. BlocPal must look at the design
                            effectiveness, implementation, and operating effectiveness of privacy controls/processes.</p>
                        <p>A sample PIA form is included as an appendix to this document. This form has been developed based on OPC guidance<a href="#fn4" className="footnote-ref" id="fnref4"><sup>4</sup></a>, however, the Privacy Officer may make modifications to
                            the form on an as-needed basis.</p>
                        <p>The PIA form should be completed in conjunction with the Privacy Officer. Any staff managing initiatives involving
                            personal information must notify the Privacy Officer that a PIA is required, and work with the Privacy Officer to
                            complete the PIA. In order to avoid delays, sufficient time must be allotted in project plans to allow for the
                            completion of a PIA, as well as any risk mitigation implications deemed necessary. These must be completed prior to
                            the “go live” date of new projects and/or processes.</p>
                        <p>Staff that are uncertain as to whether or not a project or process change under their management has a privacy or PI
                            related component that requires the completion of a PIA should contact the Privacy Officer.</p>
                        <p>The Privacy Officer will maintain electronic copies of all PIAs.</p>
                        <h1>Privacy Breach Response Plan </h1>
                        <p>In the event BlocPal suspects or has a privacy breach, which is the loss of, unauthorized access to, or disclosure
                            of, personal information under our control and if it is reasonable to believe the breach creates a real risk of
                            significant harm, the breach must be reported to the Privacy Commissioner. An organization that knowingly fails to
                            report or maintain records of a breach can be found guilty of an offence punishable by fine of up to CAD 100,000.</p>
                        <p>The following are the steps we must take when we believe there is a privacy breach.</p>
                        <ul>
                            <li>
                                <blockquote>
                                    <p>Determine if there is a “real risk of significant harm” (RROSH);</p>
                                </blockquote>
                            </li>
                            <li>
                                <blockquote>
                                    <p>Report the breach;</p>
                                </blockquote>
                            </li>
                            <li>
                                <blockquote>
                                    <p>Notification; and</p>
                                </blockquote>
                            </li>
                            <li>
                                <blockquote>
                                    <p>Record keeping.</p>
                                </blockquote>
                            </li>
                        </ul>
                        <h2>Determine if there is a “Real Risk of Significant Harm”</h2>
                        <p>To determine if the breach poses a RROSH to any individual whose personal information was involved in the breach we
                            must conduct a risk assessment. The following factors are some of the considerations that we must include in assessing
                            the risks taken from OPC guidance<a href="#fn5" className="footnote-ref" id="fnref5"><sup>5</sup></a>:</p>
                        <ul>
                            <li><p>Sensitivity of the personal information involved i.e. How sensitive is the information? Was the personal
                                information adequately encrypted, anonymized or otherwise not easily accessible?</p></li>
                            <li><p>Probability of Misuse. i.e. What happened and how likely is it that someone would be harmed by the breach? Who
                                actually accessed or could have accessed the personal information? How long has the personal information been
                                exposed?</p></li>
                            <li><p>The cause and extent of the breach i.e. Is there a risk of ongoing breaches or further exposure of the
                                information – is it a systemic issue? Has the personal information been recovered?</p></li>
                            <li><p>The individuals affected by the breach i.e. How many individuals’ personal information was involved? Who is
                                affected by the breach: employees, customers, etc.?</p></li></ul>
                        <h2>Reporting the Breach</h2>
                        <p>We must notify the privacy commissioner, of any breach of security safeguards involving personal information under
                            its control if it is reasonable to believe the breach creates a real risk of significant harm as determined in the
                            step above. The report should contain the following:</p>
                        <ul>
                            <li><p>A description of the circumstances of the breach and, if known, the cause;</p></li>
                            <li><p>The day or the period in which the breach occurred;</p></li>
                            <li><p>A description of the personal information that was involved in the breach;</p></li>
                            <li><p>an estimate of the number of individuals impacted – where the breach creates a real risk of significant
                                harm;</p></li>
                            <li><p>The steps that the organization has taken to reduce the risk of harm to the impacted individuals;</p></li>
                            <li><p>The steps that the organization has taken or will take to notify impacted individuals; and</p></li>
                            <li><p>The name and contact information of a person who can answer, on behalf of the organization, the Privacy
                                Commissioner’s questions about the breach.</p></li>
                        </ul>
                        <p>The OPC has published a reporting form the should be used for reporting breaches which can be accessed here: <a href="https://www.priv.gc.ca/media/4844/pipeda_pb_form_e.pdf"><span className="underline">https://www.priv.gc.ca/media/4844/pipeda_pb_form_e.pdf</span></a>
                        </p>
                        <p>If the breach involves individuals or organizations in the province of Alberta, the breach must be reported to their
                            provincial privacy commissioner in addition to the OPC. A separate form must be used for this and can be accessed
                            here: <a href="https://www.oipc.ab.ca/action-items/how-to-report-a-privacy-breach.aspx"><span className="underline">https://www.oipc.ab.ca/action-items/how-to-report-a-privacy-breach.aspx</span></a>
                        </p>
                        <p>While not mandatory, as a best practice, if the breach involves individuals or organizations in the province of
                            Quebec, BlocPal will report the breach to the Commission d’accès à l’information du québec (CAI) in addition to the
                            OPC. Contact to the CAI is found here: <a href="http://www.cai.gouv.qc.ca/a-propos/nous-joindre/"><span className="underline">http://www.cai.gouv.qc.ca/a-propos/nous-joindre/</span></a></p>
                        <h2>Notification </h2>
                        <p>We must notify affected individuals if it is determined that there is a RROSH. How the notification will take place
                            depends on serval factors such as if contact information of the impacted individuals is known, cost, and if the method
                            chosen to deliver such a notification will cause further harm.</p>
                        <p>Issuing notification that contains:</p>
                        <ul>
                            <li><p>A description of the circumstances of the breach;</p></li>
                            <li><p>The day or period during which the breach occurred;</p></li>
                            <li><p>A description of the personal information that was involved in the breach;</p></li>
                            <li><p>the steps that the organization has taken to reduce the risk of harm to the impacted individuals;</p></li>
                            <li><p>The steps that the impacted individuals could take to reduce the risk of harm resulting from the breach;</p>
                            </li>
                            <li><p>A toll-free number or email address that the impacted individuals can use to obtain further information about
                                the breach; and</p></li>
                            <li><p>Information about the organization’s internal complaint process and about the individual’s right, under PIPEDA
                                and that they can make a complaint with the privacy commissioner.</p></li>
                        </ul>
                        <p>We must also notify other organizations or government institution if we believe they may be able to reduce the risk
                            of harm to the impacted individuals. (i.e. law enforcement agencies). If this is the case, consent of individuals is
                            not required for such disclosures.</p>
                        <h2>Record Keeping</h2>
                        <p>Organizations are required to keep and maintain records of all breaches of security safeguards, including those that
                            do not meet the harm threshold for reporting and notification. These records must be provided to the privacy
                            commissioner upon request. Records must be maintained for 24 months from the day that the organization determined that
                            the breach occurred, and that they contain sufficient information to enable the privacy commissioner to verify
                            compliance with the breach reporting obligations.</p>
                        <h1>Privacy Management Program</h1>
                        <p>A comprehensive privacy management program provides an effective way for BlocPal to foster a strong privacy culture
                            throughout the organization and ensure compliance with legislation. While we have discussed many aspects of what is
                            needed in such a program in the sections above, the following are the fundamental elements BlocPal ensures are in
                            place and operate effectively.</p>
                        <ul>
                            <li><p>Senior management support in order to foster a privacy respectful culture.</p></li>
                            <li><p>The Privacy Officer is involved in business decision-making process. The Privacy Officer monitors compliance
                                and ensures program controls are assessed regularly.</p></li>
                            <li><p>The Privacy Office supports the ability of staff to monitor compliance and fosters a culture of privacy.</p>
                            </li>
                            <li><p>Reporting mechanisms are in place and are reflected in our program controls.</p></li>
                            <li><p>We can identify the personal information we have in our possession, what that information is used for, and the
                                sensitivity of the personal information.</p></li>
                            <li><p>We have procedures in place related to the following:</p>
                                <ul>
                                    <li><p>Collection, use and disclosure of personal information, which include requirements for consent and
                                        notification</p></li>
                                    <li><p>Access to and correction of personal information</p></li>
                                    <li><p>Retention and disposal of personal information</p></li>
                                    <li><p>Responsible use of information and information technology, including physical and technological security
                                        controls</p></li>
                                </ul>
                            </li>
                            <li><p>We have ways to challenging compliance in place:</p>
                                <ul>
                                    <li><p>Risk assessment tools</p></li>
                                    <li><p>Training</p></li>
                                    <li><p>Breach and incident management response protocols</p></li>
                                    <li><p>Service Provider management</p></li>
                                    <li><p>External communication</p></li>
                                </ul>
                            </li>
                        </ul>
                        <h1>CASL Compliance Program </h1>
                        <p>CASL is Canada’s anti-spam law that applies to all electronic messages (i.e. email, texts) and governs how BlocPal
                            communicates related to its commercial activity. BlocPal is responsible for ensuring all commercial electronic
                            messages (CEMs) comply with the requirements of CASL. BlocPal is required to obtain an individual’s express consent
                            before sending messages, manage unsubscribe request and ensure CEMs clearly identify who is sending the message. In
                            addition, the CRTC has outline CASL compliance program requirements that BlocPal must adhere to.</p>
                        <h2>Senior management involvement</h2>
                        <p>BlocPal must identify a point person who is responsible and accountable for compliance with the Rules and/or CASL.
                            BlocPal has chosen their Compliance &amp; Privacy Officer, Sylvia Lee, as this person.</p>
                        <h2>Risk assessment</h2>
                        <p>The Privacy Officer is to conduct a risk assessment to determine which business activities are at risk for possible
                            violations under CASL. Where there is a risk, procedures are put into place to mitigate.</p>
                        <h2> Additional Compliance </h2>
                        <p>In addition to this Policy. BlocPal may also implement the following</p>
                        <ul>
                            <li><p>Procedures for compliance with CASL;</p></li>
                            <li><p>CASL Training;</p></li>
                            <li><p>An auditing and monitoring program;</p></li>
                            <li><p>Procedures for dealing with third to ensure that they comply with CASL;</p></li>
                            <li><p>Record keeping requirements especially with respect to consent; and</p></li>
                            <li><p>A means to allow employees to provide feedback to the Privacy Officer in relation to CASL.</p></li>
                        </ul>
                        <h2>Record keeping</h2>
                        <p>BlocPal must ensure records related to obtaining consent and managing unsubscribe are kept. Records may be kept in
                            hard copy or electronic form and may help in investigating and responding to consumer complaints or establish a due
                            diligence defense in the event of complaints to the Commission against the business.</p>
                        <h2>Training program</h2>
                        <p>BlocPal should implement CASL training. Training should contain what is required under legislation and the penalties
                            for not meeting those requirements. For training to be effective, links should be made to BlocPal’s procedures and
                            include examples that staff may face in their daily activities.</p>
                        <p>The training should be adapted and re-administered when there are changes to the business or changes in
                            regulation.</p>
                        <h2>Auditing and monitoring</h2>
                        <p>BlocPal must have in place auditing and monitoring mechanisms to help prevent and detect CASL compliance issues.
                            BlocPal must ensure that audits are conducted regularly (with or without external help).</p>
                        <p>The results of all such monitoring activities should be recorded, maintained, and communicated to senior management.
                            Any finding or recommendations should be addressed in a timely fashion.</p>
                        <h2> Complaints</h2>
                        <p>BlocPal must have in place a complaint process to allow customers to submit complaints. BlocPal should respond to and
                            resolve complaints timely. This process is in addition to processes for withdrawing of consent.</p>
                        <h2>Corrective action</h2>
                        <p>BlocPal should consider taking corrective actions, or providing refresher training, as appropriate, to address
                            non-compliance with CASL requirements. A record of the contravention and the actions taken should be kept.</p>
                        <h2>Requirements for CEM</h2>
                        <p>In order to ensure any commercial electronic messages, such as emails promoting a BlocPal’s services that we send, we
                            must comply with the following requirements:</p>
                        <ul>
                            <li><p>Obtaining consent;</p></li>
                            <li><p>Including required information; and</p></li>
                            <li><p>Including an appropriate unsubscribe mechanism.</p></li>
                        </ul>
                        <p>CEMs can include messages that have another primary purpose (for example a Christmas card) but also include messaging
                            promoting a product or service.</p>
                        <h4>Obtaining Consent</h4>
                        <p>To send a CEM, BlocPal needs express consent, either orally or in writing. Written consent can be electronic. Consent
                            must be obtained prior to sending any communication, excluding ones that are exempt under regulations. Express consent
                            cannot be obtained via a CEM, unless you already have implied consent. BlocPal should keep record of what type of
                            information or content they agreed to receive (i.e. e-newsletters, sales info, product promotions, etc.)</p>
                        <h4>Information to be included in CEMs</h4>
                        <p>Each CEM must identify the sender of the message and, if applicable, the person(s) on whose behalf the message is
                            sent. BlocPal must ensure that each CEM contains the full mailing address of the person sending the message or, if
                            different, the mailing address of the person on whose behalf the message is sent. Additionally, a URL, email address,
                            or phone number, where the sender can be contacted should be included. This information must be valid for at least 60
                            days after the message has been sent. This information should also to be included in a request for consent. The
                            subject line of the CEM must accurately describe the contents of the message.</p>
                        <h4>Unsubscribe mechanism for CEMs</h4>
                        <p>Each CEM must contain an unsubscribe mechanism. BlocPal must ensure that each CEM contains an unsubscribe mechanism
                            that is clearly and prominently located and easy for the consumer to use. BlocPal must honour all unsubscribe requests
                            within 10 days following receipt of the request.</p>
                        <h2 id> Requirements Related to Installation of Computer
                            Programs</h2><a href="#fn6" className="footnote-ref" id="fnref6"><sup>6</sup></a>
                        <p>BlocPal is required to obtain consent before the installation of a computer program, including installation of
                            digital platforms, that will do one or more of the following:</p>
                        <ul>
                            <li><p>collect personal information stored on the computer;</p></li>
                            <li><p>interfere with the user’s control of the computer system; and</p></li>
                            <li><p>change or interfere with settings, preferences, or commands already installed or stored on the computer system
                                without the knowledge of the owner or an authorized user of the computer system.</p></li>
                        </ul>
                        <p>The consent must obtain an acknowledgement from the person from whom consent is being sought that he or she
                            understands and agrees that the program performs the specified functions. Consent may be obtained via an empty toggle
                            box, separate from a license agreement and other requests for consent.</p>
                        <h1>National Do Not Call and Telemarketing Rules </h1>
                        <p>National Do Not Call List and Telemarketing Rules set out requirements that telemarketers and organizations who hire
                            telemarketers must follow. BlocPal must comply with the following requirements before initiating any
                            telecommunication.</p>
                        <h2>National Do Not Call <a href="#fn7" className="footnote-ref" id="fnref7"><sup>7</sup></a></h2>
                        <ul>
                            <li><p>BlocPal is not allowed a telephone number if it is registered on the National DNCL for more than 31 days,
                                unless we have prior express consent.</p></li>
                            <li><p>BlocPal or any other third party we hire to telemarket, must subscribe to the National DNCL and pay any
                                applicable subscription fee. The list must not be used for any purpose other than compliance and must not be sold,
                                rented, leased, published or disclosed to any person outside of the organization.</p></li>
                            <li><p>BlocPal must maintain an internal do not call list;</p></li>
                            <li><p>BlocPal must state the purpose of the telemarketing call at the beginning of the call; and,</p></li>
                            <li><p>BlocPal must identify on whose behalf the call is being made.</p>
                            </li>
                        </ul>
                        <h2>Telemarketing Rules</h2>
                        <p>In addition to the above there are addition rules that BlocPal must comply with</p>
                        <ul>
                            <li><p>Calling hours are restricted Monday to Friday between 9:00 am and 9:30 pm and weekends between 10:00 am and
                                6:00 pm. Restrictions hours refer to the time zone of the customer receiving the telephone calls.</p></li>
                            <li><p>Telemarketers must display the number from where the call in being originated or an alternate number where the
                                call originator can be reached.</p></li>
                            <li><p>For any telephone numbers provided to an individual the number must be answered by a live operator or a
                                voicemail system. The voicemail must inform consumers that their call will be returned within three business
                                days.</p></li>
                            <li><p>Sequential dialing is prohibited.</p></li>
                            <li><p>Internal do not call lists are to be maintained. Request to be removed from calling list must be fulfilled
                                within fourteen (14) days<a href="#fn8" className="footnote-ref" id="fnref8"><sup>8</sup></a> from the date of the c
                                request and remain active for three years.</p></li>
                            <li><p>A telemarketer making a telemarketing call must process a do not call request from a consumer at the time of
                                the call. The consumer shall not be asked to call elsewhere to make their request</p></li>
                            <li><p>A telemarketer using a predictive dialing device to initiate a call shall not exceed, in any calendar month, a
                                five percent abandonment rate. The telemarketer and/or its client shall maintain records of actual abandonment
                                rate.</p></li>
                            <li><p>At the beginning of a call a telemarketer must identify the name or fictitious name of the individual placing
                                the call, identify the name of the telemarketer whether calling on its own behalf or on behalf of a client of the
                                telemarketer, and provide the purpose of the call.</p></li>
                            <li><p>Upon request, a telemarketer must provide a local or toll-free number allowing the customer access to speak to
                                an employee or other representative, provide the name and e-mail or postal mailing address of an employee or other
                                representative, to whom the consumer can write the customer access to an employee or other representative of the
                                telemarketer, and where applicable, the client of the telemarketer, for the purpose of asking questions or making a
                                do not call request the name and e-mail or postal mailing address of an employee or other representative of the
                                telemarketer, or client where applicable, to whom the consumer can write.</p></li>
                        </ul>
                        <section className="footnotes">
                            <hr />
                            <ol>
                                <li id="fn1"><p>It is BlocPal’s intent to operate in all Canadian provinces and territories.<a href="#fnref1" className="footnote-back">↩</a></p></li>
                                <li id="fn2"><p>https://www.priv.gc.ca/en/privacy-topics/privacy-at-work/<a href="#fnref2" className="footnote-back">↩</a></p></li>
                                <li id="fn3"><p>In Canadian legislation and related guidance, PIAs may also be described as self-assessments.
                                    BlocPal has chosen to use the term PIA as it more clearly reflects the specific purpose of the exercise (to gauge
                                    privacy related impact and mitigate applicable privacy risk).<a href="#fnref3" className="footnote-back">↩</a></p>
                                </li>
                                <li id="fn4"><p>https://www.priv.gc.ca/en/privacy-topics/privacy-impact-assessments/<a href="#fnref4" className="footnote-back">↩</a></p></li>
                                <li id="fn5"><p>
                                    https://www.priv.gc.ca/en/privacy-topics/privacy-breaches/respond-to-a-privacy-breach-at-your-business/gd_pb_201810/#_Part_6<a href="#fnref5" className="footnote-back">↩</a></p></li>
                                <li id="fn6"><p>BlocPal does not currently offer any computer programs. This section has been included for education
                                    purpose only or in the event BlocPal’s business model changes.<a href="#fnref6" className="footnote-back">↩</a></p>
                                </li>
                                <li id="fn7"><p>BlocPal does not tele-market to non-customers. This section has been included for educational
                                    purposes only.<a href="#fnref7" className="footnote-back">↩</a></p></li>
                                <li id="fn8"><p>While the requirement is 14 days in this instance, BlocPal’s processes are designed to ensure that a
                                    timeframe of 10 days or less is applied in all cases.<a href="#fnref8" className="footnote-back">↩</a></p></li>
                            </ol>
                        </section>
                    </div>
                </div>
            </Content>
        );
    }
}


export default class PrivacyScreen extends React.Component {

    static propTypes = {
        location: PropTypes.object,
    };

    componentDidMount() {
        document.title = 'Privacy Policy (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.location.pathname + this.props.location.search + this.props.location.hash,
            page_search: this.props.location.search,
            page_hash: this.props.location.hash,
        });
    }

    render() {
        return (
            <StandardPage>
                <PrivacyText />
            </StandardPage>
        );

    }

}
