import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VerifyEmailScreen from './VerifyEmailScreen';
import { registerEmail } from '../../actions/auth';

function mapStateToProps(state) {
    return {
        email: state.auth.email
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            registerEmail
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyEmailScreen);
