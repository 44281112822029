import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import PortfolioScreen from './PortfolioScreen';
import { getPortfolioInfo } from '../../actions/portfolio';
import {getCoinConfig, getApproxTotal, getAuthenticated} from '../../selectors';

function mapStateToProps(state, { match: { url, params: {coin} } }) {
    return {
        thisUrl: url,
        coinLabel: (coin && getCoinConfig(state, coin)) ? getCoinConfig(state, coin).label : undefined,
        coinName: (coin && getCoinConfig(state, coin)) ? getCoinConfig(state, coin).name : coin,
        isFiatCurrency: (coin && getCoinConfig(state, coin)) ? getCoinConfig(state, coin).fiatCurrency : undefined,
        isWallets: state.portfolio.wallets.length !== 0,
        approxTotal: getApproxTotal(state),
        fiatCurrency: (state.portfolio.fiatCurrency) ? state.portfolio.fiatCurrency : 'USD',
        isPortfolioLoaded: state.portfolio.isPortfolioLoaded,
        isAuthenticated: getAuthenticated(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPortfolioInfo,
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioScreen));
