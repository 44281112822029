import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Component as Button } from '../../../../components/Button';
import { Component as Input } from '../../../../components/Input';
import { Component as InputGroup } from '../../../../components/InputGroup';
import { Component as Checkbox } from '../../../../components/Checkbox';

import { mobile } from '../../../../styles';

import globeIcon from '../../../../images/ic-globe.svg';


const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: calc(100vh - 80px);
  min-height: 700px;
`;

const CountryLine = styled.p`
  text-align: center;
  margin: 0;
  color: #4A4A4A;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 7px;
  }
`;

const ConfirmationBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 310px;
  padding: 15px;
`;

const Title = styled.p`
	color: #4A4A4A;
	font-size: 24px;
	font-weight: 300;
	line-height: 36px;
	text-align: center;
	margin: 0;
	margin-top: 15px;
`;

const SubscribeButton = styled(Button).attrs({
    bsStyle: 'blue'
})`
    text-align: center;
    display: block;

    a {
        display: inline-flex;
        padding: 18px 35px;
        width: 235px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;

    }
`;

const Line = styled.hr`
  margin: 30px -55px;
  border: 1px solid #979797;
  border-top: 0;
  opacity: 0.14;

  ${mobile`
    border: 0;
  `}
`;

const SubscriptionInputGroup = styled(InputGroup)`
  label {
    color: #4A4A4A;
  }
`;


export default class Subscription extends React.Component {

    static propTypes = {
        intl: PropTypes.object,
        jurisdiction: PropTypes.string,
        subscribe: PropTypes.func
    };

    state = {
        email: '',
        checked: false
    };

    onSubmit = () => {
        this.props.subscribe(this.state.email, this.props.jurisdiction);
    };

    render() {

        const { intl, jurisdiction } = this.props;
        const { email, checked } = this.state;

        return (
            <Container>
                <ConfirmationBody>
                    <CountryLine>
                        <img src={globeIcon} alt=""/>
                        {jurisdiction}
                    </CountryLine>
                    <Title>
                        <FormattedMessage
                            id="email_subscribe.invalid_region"
                            defaultMessage="Sorry, we aren't able to perform services in this region yet"
                        />
                    </Title>
                    <Line/>
                    <SubscriptionInputGroup>
                        <FormattedMessage
                            id="email_subscribe.be_the_first"
                            defaultMessage="Be the first to hear when we open!"
                        />
                        <Input
                            placeholder={
                                intl.formatMessage({
                                    id: 'email_subscribe.your_email',
                                    defaultMessage: 'Your email address'
                                })
                            }
                            onChange={e => this.setState({ email: e.target.value })}
                            value={email}
                        />
                        <Checkbox
                            label={
                                <FormattedMessage
                                    id="email_subscribe.send_me_updates"
                                    defaultMessage="It’s OK to send me updates"
                                />
                            }
                            checked={checked}
                            onChange={() => this.setState({ checked: !checked })}
                        />
                    </SubscriptionInputGroup>
                    <SubscribeButton onClick={this.onSubmit}>
                        <FormattedMessage
                            id="email_subscribe.subscribe"
                            defaultMessage="Subscribe to updates"
                        />
                    </SubscribeButton>
                </ConfirmationBody>
            </Container>
        );
    }
}
