import {createSelector} from 'reselect';

const getIsAuthenticated = state => state.auth.isAuthenticated;

export const getAuthenticated = createSelector(
    getIsAuthenticated,
    (isAuthenticated) => {
        return isAuthenticated;
    }
);
