import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionIncomingDepositStep from './TransactionIncomingDepositStep';
import {
    getWithdrawalFeeEstimate,
    getStatus,
    getTransactionCurrencyFromConfig,
    getTransactionCurrencyToConfig,
    getDepositMin,
    getDepositMax,
    getRefundAddress,
    getProceedAddress,
    getDayValue,
    getDayLimit,
    getCurrencyRateFromCurrentPair
} from '../../../../selectors';

function mapStateToProps(state) {
    return {
        feeEstimate: getWithdrawalFeeEstimate(state),
        status: getStatus(state),
        currencyFromConfig: getTransactionCurrencyFromConfig(state),
        currencyToConfig: getTransactionCurrencyToConfig(state),
        proceedAddress: getProceedAddress(state),
        refundAddress: getRefundAddress(state),
        rate: getCurrencyRateFromCurrentPair(state),
        // this is from the transaction... which hasn't happened yet... rate: getRate(state),
        minValue: getDepositMin(state),
        maxValue: getDepositMax(state),
        dayValue: getDayValue(state),
        dayLimit: getDayLimit(state),
        transaction: state.transactions.transactionState,
        transactionId: state.transactions.transactionId,
        partialDepthPair: state.currency.partialDepthPair,
        partialDepth: state.currency.partialDepth,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionIncomingDepositStep);
