import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as InputGroup } from '../../components/InputGroup';
import { Component as Input } from '../../components/Input';
import { Component as Button } from '../../components/Button';
import { Component as GradientView } from '../../components/GradientView';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as AuthFooter } from '../../components/AuthFooter';

import alertCircle from '../../images/ic-alert-circle.svg';

import { mobile } from '../../styles';

// this module is over 800k so we dynamically load it
const ReactPasswordStrength = lazy(() => import('react-password-strength'));

const BoldText = styled(MessageBold)`
    margin-bottom: 60px;
`;

const AlertCircle = styled.img`
  height: 15px;
  width: 15px;
  vertical-align: middle;
`;

const FormWrapper = styled.form`
    padding: 37px 110px 60px 110px;
    max-width: 520px;

    ${mobile`
        padding: 20px 30px 60px 30px;
    `};
`;

const PasswordHintWrapper = styled.div`
  display: flex;    
  margin-top: 30px;
  img {
    margin-right: 10px;
  }

  p {
    font-family: Inter,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #868686;
  }  
`;

const FormTitle = styled.div`
	margin-bottom: 40px;

	p {
        font-family: Inter,sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #868686;
    }
`;

const AuthInputGroup = styled(InputGroup)`
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  ${mobile`
      width: 100%;
  `};

  a {
    position: absolute;
    top: 0;
    right: 0;
	color: #ef8632;
	font-size: 14px;
	line-height: 21px;
  }

  label {
    white-space: nowrap;
    color: #262626;
    font-family: Inter,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    }
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 50px;
  border-radius: 10px;
  text-transform: capitalize;
`;


export default class RegistrationSetPasswordScreen extends React.Component {

    static propTypes = {
        updatePassword: PropTypes.func,
        registerPassword: PropTypes.func,
        history: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            confirmPassword: '',
            password: '',
            error: '',
            loading: false,
            isValid: false,
        };
    }

    componentDidMount() {
        document.title = 'Set Password (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });
    }

    onSubmit = async event => {
        event.preventDefault();
        const { password, confirmPassword } = this.state;

        if (password.length >= 8 && password === confirmPassword) {
            if (this.props.history.location.pathname === '/performReset') {
                const token = location.search.substr(11);
                const code = '';
                const response = await this.props.updatePassword({
                    password,
                    token,
                    code,
                });

                if (response.error || response.payload.status.code === 112401 || response.payload.status.code === 112400) {
                    this.setState({
                        error: response.payload.status.message,
                        loading: false
                    });
                } else {
                    this.props.history.push('/login');
                }
            } else {
                const registerSessionId = location.search.substr(1);

                const response = await this.props.registerPassword({
                    registerSessionId,
                    password
                });

                if (response.error || response.payload.status.code === 112403 || response.payload.status.code === 112401 || response.payload.status.code === 112400) {
                    this.setState({
                        error: response.payload.status.message,
                        loading: false
                    });
                }
            }

        } else if (password !== confirmPassword) {
            this.setState({
                error: 'Passwords must match',
                loading: false
            });
        } else {
            this.setState({
                error: 'Password must be greater than 8 characters',
                loading: false
            });
        }
    };

    changeCallback = state => {
        this.setState({
            password: state.password,
            isValid: state.isValid,
        });
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    validate = (isValid, password, confirmPassword) => {
        return (isValid && password === confirmPassword);
    };

    render() {

        const { error, confirmPassword, password, isValid } = this.state;

        let passwordError = error;
        let passwordConfirmError = '';

        if (confirmPassword && confirmPassword.length > 0 && password !== confirmPassword) {
            passwordConfirmError = 'Passwords must match';
        }

        const disabled = !password ||
            !confirmPassword ||
            !this.validate(isValid, password, confirmPassword);

        const buttonText = this.props.history.location.pathname === '/performReset' ? (
            <FormattedMessage
                id="auth.setPassword.saveResetPass"
                defaultMessage="Save and sign in"
            />
        ) : (
            <FormattedMessage
                id="auth.setPassword.createAccount"
                defaultMessage="Create account"
            />
        );

        return (
            <GradientView>
                <Suspense fallback={
                    <React.Fragment>
                        <br/>
                        <div>Loading...</div>
                    </React.Fragment>}>
                    <FormWrapper onSubmit={this.onSubmit}>
                        <FormTitle>
                            <BoldText>
                                <FormattedMessage
                                    id="auth.setPassword.createPassword"
                                    defaultMessage="Create Your Password"
                                />
                            </BoldText>
                            <PasswordHintWrapper>
                                <AlertCircle src={'/' + alertCircle}/>
                                <FormattedMessage
                                    id="auth.setPassword.createPasswordHint"
                                    defaultMessage="Password should be a minimum of 8 characters & include uppercase, numeric and special characters."
                                />
                            </PasswordHintWrapper>
                        </FormTitle>
                        <AuthInputGroup>
                            <label>Enter Password</label>
                            <ReactPasswordStrength
                                namespaceClassName={'BPPasswordStrength'}
                                minLength={8}
                                minScore={3}
                                scoreWords={['Too Weak', 'Too Weak', 'Too Weak', 'Strong', 'Great!']}
                                tooShortWord={'Too Short'}
                                changeCallback={this.changeCallback}
                                inputProps={{
                                    name: 'password',
                                    type: 'password',
                                    placeholder: 'Enter Password',
                                    value: password,
                                    onChange: this.onChange,
                                    error: passwordError,
                                }}
                            />
                        </AuthInputGroup>

                        <AuthInputGroup>
                            <label>Confirm Password</label>
                            <Input
                                name="confirmPassword"
                                type="password"
                                placeholder="Enter Confirm Password"
                                value={confirmPassword}
                                onChange={this.onChange}
                                error={passwordConfirmError}
                            />

                            <StyledButton
                                disabled={disabled}
                                onClick={this.onSubmit}
                                bsStyle='orange'
                                type="submit">
                                {buttonText}
                            </StyledButton>
                        </AuthInputGroup>

                    </FormWrapper>
                </Suspense>
                <AuthFooter />
            </GradientView>
        );
    }
}
