import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import infoIcon from '../../images/ic-help-orange.svg';

const InfoIconImg = styled.img`
  margin: 0px 5px;
  z-index: 2;
`;

const Container = styled.span`
  vertical-align: center;
  display: inline-block;
`;

export const BlocPalTooltip = styled(ReactTooltip).attrs({
    className: 'custom-tooltip',
})`
  &.custom-tooltip {
    opacity: 1 !important;
    background-color: white;
    color: #EF8632;
    font-weight: 500;
    font-size: 14px;
    width: 300px;
    border-radius: 5px;
    border-width: 2px;
    box-shadow: 0 0 30px #ccc;
  }
`;


export default class BlocPalToolTip extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        tooltipId: PropTypes.string,
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
            PropTypes.object,
        ]),
    };

    render() {

        const { tooltipId, children, ...props } = this.props;

        return (
            <Container>
                <InfoIconImg data-tip data-for={tooltipId} src={'/' + infoIcon}/>
                <BlocPalTooltip id={tooltipId} className='custom-tooltip' border borderColor='#EF8632' arrowColor='#EF8632' {...props}>
                    {children}
                </BlocPalTooltip>
            </Container>
        );

    }

}
