import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import getSymbolFromCurrency from 'currency-symbol-map';
import { Component as CurrencyImage } from '../../../../components/CurrencyImage';


const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  >span {
	color: #9B9B9B;
	font-size: 12px;
	letter-spacing: 0.43px;
	line-height: 19px;
	text-transform: uppercase;
  }

  p {
    color: #9B9B9B;
	font-size: 14px;
	letter-spacing: 0.43px;
	line-height: 19px;

	span {
	  color: #ef8632;
	  cursor: pointer;
	  user-select: none;
	}
  }
`;

const ListWrapper = styled.div`

`;

const Option = styled(NavLink)`
  width: 280px;
  display: flex;
  border-top: 1px solid #eee;
  position: relative;
  padding: 10px 0px 10px 15px;
  // white-space: pre-wrap;
  // padding: 20px 20px 20px 20px;
  text-decoration: none;
  margin-bottom: 1px;

  color: #232323;
  justify-content: space-between;
  align-items: center;

  &.active {
    background-color: #ef863214;
    border-color: #ef863214;
  }

  &:hover {
    background-color: #ef863214;
  }

  &.inactivePair {
    background-color: rgba(2, 23, 71, 0.05);
  }

  &.inactivePair:hover {
    background-color: rgba(2, 23, 71, 0.10);
  }

  >span {
    // display: flex;
    // align-items: center;
    // height: 24px;
    // margin-top: 13px;
    img {
      margin-right: 10px;
    }
  }
`;

const BoldCoin = styled.span`
    font-weight: bold;
`;

const CoinLabel = styled.div`
  display: flex;
  align-items: center;
`;

const CoinLabelText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  white-space: nowrap;
`;

const CoinValues = styled.div`
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;

  >span {
    width: 30px;
  }

  p {
    margin: 0 10px 0 0;

    span {
      display: block;
      text-align: right;

      &:first-child {
        font-weight: bold;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:last-child {
        color: #9B9B9B;
        font-size: 14px;
      }
    }
  }
`;

const CurrencyIcon = styled(CurrencyImage)`
  margin-right: 15px;
  width: 35px;
  height: 35px;
`;

export default class ProjectsSidebar extends React.Component {

    static propTypes = {
        coinsConfig: PropTypes.array,
        fiatCurrency: PropTypes.string,
        activeAsset: PropTypes.string,

        intl: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            usdToFiatRate: 1, //TODO: figure out how to get this from the server without being logged in
            sortDirection: 'DESC',
            sortColumn: 'VALUE',
            wallets: [],
        };
    }

    componentDidMount() {
        this.sortWallets();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.coinsConfig !== prevProps.coinsConfig ||
            this.state.sortDirection !== prevState.sortDirection ||
            this.state.sortColumn !== prevState.sortColumn) {

            this.sortWallets();
        }
    }

    sortWallets() {
        const { coinsConfig } = this.props;
        const { sortDirection, sortColumn } = this.state;

        if (sortColumn === 'NAME') {
            this.setState({
                wallets: [...coinsConfig].sort(
                    (prev, next) => sortDirection === 'ASC' ?
                        prev.name > next.name :
                        next.name > prev.name
                ),
            });
        } else {
            this.setState({
                wallets: [...coinsConfig].sort(
                    (prev, next) => sortDirection === 'ASC' ?
                        Number(prev.coinPrice) - Number(next.coinPrice) :
                        Number(next.coinPrice) - Number(prev.coinPrice)
                ),
            });
        }
    }

    onChangeSortDirection = (column) => {
        this.setState({
            sortColumn: column,
            sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        });
    };

    render() {

        const { intl, fiatCurrency, activeAsset } = this.props;
        const { wallets, sortDirection, sortColumn, usdToFiatRate } = this.state;

        return (
            <ListWrapper>
                <ListHeader>
                    <FormattedMessage
                        id="projects.sort"
                        defaultMessage="Sort: {actionLabel}"
                        tagName="p"
                        values={{
                            actionLabel: (
                                <FormattedMessage
                                    id="projects.sortName"
                                    defaultMessage="Ticker"
                                >
                                    {message => <span onClick={() => { this.onChangeSortDirection('NAME'); }}>{message} {sortColumn === 'NAME' && (sortDirection === 'ASC' ? '↑' : '↓')}</span>}
                                </FormattedMessage>
                            )
                        }}
                    />

                    <FormattedMessage
                        id="projects.sort"
                        defaultMessage="Sort: {actionLabel}"
                        tagName="p"
                        values={{
                            actionLabel: (
                                <FormattedMessage
                                    id="projects.sortValue"
                                    defaultMessage="Value"
                                >
                                    {message => <span onClick={() => { this.onChangeSortDirection('VALUE'); }}>{message} {sortColumn === 'VALUE' && (sortDirection === 'ASC' ? '↑' : '↓')}</span>}
                                </FormattedMessage>
                            )
                        }}
                    />
                </ListHeader>
                {wallets && wallets.map((coinConfig, index) => (
                    <Option
                        key={index}
                        to={`/projects/${coinConfig.name}`}
                        exact
                        isActive={() => activeAsset === coinConfig.name}
                    >
                        <CoinLabel>
                            <CurrencyIcon currency={coinConfig.name} />
                            <CoinLabelText>
                                <BoldCoin>
                                    {coinConfig.name}<br/>
                                </BoldCoin>
                                {coinConfig.label}
                            </CoinLabelText>
                        </CoinLabel>
                        <CoinValues>
                            <p>
                                {fiatCurrency === 'USD' ? (
                                    <span>
                                        ${intl.formatNumber(coinConfig.coinPrice, {minimumFractionDigits: 2, maximumFractionDigits: 2})}<br/>{fiatCurrency}
                                    </span>
                                ) : (
                                    <span>
                                        {getSymbolFromCurrency(fiatCurrency)}{intl.formatNumber(coinConfig.coinPrice * (usdToFiatRate !== 0 ? usdToFiatRate : 0), {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                        <br/>
                                        {fiatCurrency}
                                    </span>
                                )}
                            </p>
                        </CoinValues>
                    </Option>
                ))}
            </ListWrapper>
        );
    }
}
