import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfileScreen from './ProfileScreen';

function mapStateToProps(state) {
    return {
        userLoaded: (state.user) ? true : false,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileScreen);
