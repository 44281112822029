import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Component as Checkbox } from '../../components/Checkbox';
import { Component as Input } from '../../components/Input';
import { Component as Button } from '../../components/Button';
import { Component as AuthFooter } from '../../components/AuthFooter';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as GradientView } from '../../components/GradientView';
import { LoadingDots } from '../../components/LoadingDots';
import {
    AuthInputGroup,
    LinkButton,
    FormContainer,
    FormWrapper,
    LabelP
} from '../../commonStyles/AuthStyles';

import { mobile } from '../../styles';
import blocPalXLogoWhite from '../../images/BlocpalX-ondark.svg';

const FormTitle = styled.div`
    margin-top: 40px;
`;

const InputGroupView = styled(AuthInputGroup)`
  width: 300px;
  margin-bottom: 25px;
`;

const SigninButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 45px;
  border-radius: 10px;
`;

const LinkBlock = styled.div`
  background-color: #021747;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`;

const ForgotPasswordLink = styled.div`
  color: #EF8632;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-decoration: underline;
`;

const CreateAccountLink = styled(LinkButton)`
    width: 185px;
    padding: 16px 20px;
`;

const AppLogoWhite = styled.img`
    vertical-align: middle;
    margin: 0px 2px 8px 5px;
    width: 90px;
    // height: 18px;
    
    ${mobile`
    img {
        width: 60px;
        height: 12px;
    }
  `}
`;

const LoadingText = styled(LoadingDots)`
    font-size: 40px;
    font-weight: 700;
    background-color: #021747;
    color: #eee;
    text-shadow: 0px 2px 3px rgba(255,255,255,0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    box-shadow: 0 0 26px -14px rgba(11,32,78,0.85);
    padding: 60px 110px;
    box-sizing: border-box;
    width: 100%;
    margin: 0;

    ${mobile`
      margin: 0px;
    `}
`;


export default class LoginScreen extends React.Component {

    static propTypes = {
        loginLoading: PropTypes.bool,
        isCredentialsError: PropTypes.bool,

        login: PropTypes.func,
        clearErrors: PropTypes.func,

        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            login: '',
            pass: '',
            rememberMe: '0',
            // generalError: false,
            emailError: false,
            loading: false
        };
    }

    componentDidMount() {
        document.title = 'Sign In (BlocPalX)';

        //console.log('FIRED');
        
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });
        

        this.props.clearErrors();

        const rememberMe = localStorage.getItem('loginRememberMe');
        if (rememberMe === '1') {
            this.setState({
                rememberMe: rememberMe,
            });
            const loginEmail = localStorage.getItem('loginEmail');
            if (loginEmail) {
                this.setState({
                    login: loginEmail,
                });
            }
        }
    }

    onSubmit = async event => {
        event.preventDefault();

        const { login, pass } = this.state;

        this.setState({
            loading: true
        });

        await this.props.login({
            login, pass
        });
        window.gtag('event', 'login');

        // put it in sessionStorage first - then if they successfully authenticate
        // the auth reducer will store it in localStorage
        sessionStorage.setItem('loginEmail', login);
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            loading: false,
        });
    };

    setRememberMe = () => {
        const { rememberMe } = this.state;

        this.setState({
            rememberMe: (rememberMe === '1') ? '0' : '1',
        });

        if (rememberMe === '0') {
            localStorage.removeItem('loginEmail');
        }
        localStorage.setItem('loginRememberMe', (rememberMe === '1') ? '0' : '1');
    };

    render() {
        const { login, pass, loading, rememberMe } = this.state;
        const { loginLoading, isCredentialsError } = this.props;

        return loginLoading ? (
            <LoadingText>
                <FormattedMessage
                    id="general.loading"
                    defaultMessage="Loading"
                />
            </LoadingText>
        ) : (
            <FormContainer>
                <GradientView borderRadius={'0 0 15px 15px'}>
                    <FormTitle>
                        <MessageBold>
                            <FormattedMessage
                                id="auth.signin.welcomeBack"
                                defaultMessage="Welcome Back!"
                            />
                        </MessageBold>
                    </FormTitle>
                    <FormWrapper onSubmit={this.onSubmit}>
                        {/* {generalError && (
                            <ErrorMessage>
                                <img src={errorIcon} />
                                <FormattedMessage
                                    id="auth.signin.incorrect_data"
                                    defaultMessage="The email or password you entered is incorrect."
                                />
                                <FormattedMessage
                                    id="auth.signin.try_again"
                                    defaultMessage="Please try again."
                                />
                            </ErrorMessage>
                        )} */}
                        <InputGroupView>
                            <label>Email</label>
                            <Input
                                name="login"
                                placeholder="Enter Your Email"
                                value={login}
                                onChange={this.onChange}
                                error={isCredentialsError ? 'Incorrect email or password.\n' +
                                    'Please try again.' : ''}
                            />
                        </InputGroupView>
                        <InputGroupView>
                            <label>Password</label>
                            <Link to="/forgot-password">
                                <ForgotPasswordLink >
                                    <FormattedMessage
                                        id="auth.forgot_password.link"
                                        defaultMessage="Forgot password?"
                                    />
                                </ForgotPasswordLink>
                            </Link>
                            <Input
                                name="pass"
                                placeholder="Enter Password"
                                type="password"
                                value={pass}
                                onChange={this.onChange}
                            />
                        </InputGroupView>

                        <Checkbox
                            label={(
                                <FormattedMessage
                                    id="auth.remember_login_name"
                                    defaultMessage="Remember Me"
                                />
                            )}
                            checked={(rememberMe === '1')}
                            onChange={() => this.setRememberMe()}
                        />

                        <SigninButton
                            bsStyle={'blue'}
                            disabled={loading || !login || !pass}>
                            <FormattedMessage
                                id="auth.signin.cta"
                                defaultMessage="Sign in"
                            />
                        </SigninButton>
                    </FormWrapper>
                </GradientView>
                <LinkBlock>
                    <LabelP>
                        <FormattedMessage
                            id="auth.signin.signup_link_title"
                            defaultMessage="New to"
                        />
                        <AppLogoWhite
                            src={'/' + blocPalXLogoWhite}
                            alt="BlocPalX Logo"
                        />
                        <FormattedMessage
                            id="?"
                            defaultMessage="?"
                        />
                    </LabelP>

                    <CreateAccountLink to="/signup">
                        <FormattedMessage
                            id="auth.signin.signup_link"
                            defaultMessage="Create account"
                        />
                    </CreateAccountLink>
                </LinkBlock>
                <AuthFooter type={'WHITE'} />
            </FormContainer>
        );
    }
}
