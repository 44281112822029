module.exports = {
    api: {
        // kattare production
        // domain: 'https://frontend-api-prd.txquick.com',
        // AWS production
        domain: 'https://frontend.blocpal.com',
    },
    ws: {
        // kattare production
        // uri: 'wss://frontend-ws-prd.txquick.com',
        // AWS production
        uri: 'wss://frontend.blocpal.com',
    }
};
