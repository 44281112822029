import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QuickTradeProgressBar from './QuickTradeProgressBar';


function mapStateToProps(state) {
    return {
        transaction: state.transactions.transactionState
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickTradeProgressBar);
