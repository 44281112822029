import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled.div`
    background-color: transparent;
    color: #021747;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: ${props => props.fontSize ? props.fontSize : '22px'};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '500'};
    margin: 5px;
`;

export default class Message extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        fontSize: PropTypes.string,
        fontWeight: PropTypes.number,
    };

    render() {
        return (
            <Text>
                {this.props.children}
            </Text>
        );
    }
}

export const FontWeight = {
    'LIGHT': 400,
    'MEDIUM': 500,
};
