import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ForgotPassword from './ForgotPassword';
import { resetPassword } from '../../actions/auth';

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetPassword
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);
