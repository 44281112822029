import ReconnectingWebSocket from 'reconnecting-websocket';

import { login, loginLoading, subscribeDevice } from '../actions/auth';
import { getApiKeys } from '../actions/user';

import { confirmAndProcessWithdrawal } from '../actions/portfolio';
import { addNotification } from '../actions/notifications';

import { guid } from '../utils';
import config from 'config';


const wsUri = config.get('ws.uri');
const coreEvents = config.get('ws.coreEvents');
const errors = config.get('ws.errors');
const incomingEvents = config.get('ws.incomingEvents');
const outgoingEvents = config.get('ws.outgoingEvents');
const reconnectionCodes = config.get('ws.reconnectionCodes');
const wsCallbacks = config.get('ws.callbacks');
const isAuthEnabled = config.get('features.authorization');


function isErrorCode (code) {
    return ['4', '5'].includes(code.toString().substr(3)[0]);
}

/**
 * Redux middleware, that establish websocket connection and
 * handle all the ws events
 * Now we can listen for connection and all messages from echo-server
 * Also message can be sent to server using special redux action, like
 * ```js
 * const action = {
 *  ws: {
 *      type: 'MESSAGE_TYPE',
 *      payload: {...}
 *  }
 * };
 * ```
 */
export const wsMiddleware = () => {

    const options = {
        connectionTimeout: 4000,
        maxRetries: Infinity,
        maxReconnectionDelay: 10000,
        minReconnectionDelay: 1000 + Math.random() * 4000,
        reconnectionDelayGrowFactor: 1.3,
        // debug: true,
    };

    const websocket = new ReconnectingWebSocket(wsUri, [], options);

    let stack = [];
    let isAuthorized = false;
    let authRequests = [];
    const callbacks = { };

    let pingInterval = null;

    return store => next => {

        websocket.onopen = () => {

            const token = sessionStorage.getItem('token');
            const state = store.getState();

            clearInterval(pingInterval);
            pingInterval = setInterval(() => {
                websocket.send(JSON.stringify({ type: 'ping' }));
            }, 30000); //send ping every 30 sec

            if (token && isAuthEnabled) {
                store.dispatch(loginLoading(true));
                store.dispatch(login({ token }));
            } else {
                // commented temporarily so we don't double request per comment above
                // store.dispatch(subscribeDevice());
                if (state.auth.loginLoading) {
                    store.dispatch(loginLoading(false));
                }
            }

            // hack until we can figure out why login() isn't properly registering devices in the platform...
            store.dispatch(subscribeDevice());

            //if (!token && state.router.location.pathname.includes('/account/')) {
            //    store.dispatch(push('/'));
            //}

            // converted to componentDidMount
            //if (state.router.location.pathname === '/quicktrade/' && state.transactions.transactionId) {
            //    store.dispatch(subscribeOnTransactionUpdates(state.transactions.transactionId));
            //}

            if (state.router.location.pathname === '/withdrawalConfirmed/' && state.portfolio.confirmationUuid) {
                store.dispatch(confirmAndProcessWithdrawal(state.portfolio.confirmationUuid));
            }

            stack.map(message => websocket.send(JSON.stringify(message)));
            stack = [];

            next({
                type: coreEvents.CONNECTED
            });
        };

        /**
         * Reconnect user for particular cases
         * NORMAL = new CloseStatus(1000);
         * GOING_AWAY = new CloseStatus(1001);
         * PROTOCOL_ERROR = new CloseStatus(1002);
         * NOT_ACCEPTABLE = new CloseStatus(1003);
         * NO_STATUS_CODE = new CloseStatus(1005);
         * NO_CLOSE_FRAME = new CloseStatus(1006);
         * BAD_DATA = new CloseStatus(1007);
         * POLICY_VIOLATION = new CloseStatus(1008);
         * SERVER_ERROR = new CloseStatus(1011);
         * SERVICE_RESTARTED = new CloseStatus(1012);
         * SERVICE_OVERLOAD = new CloseStatus(1013);
         * @param event
         */
        websocket.onclose = (event) => {
            clearInterval(pingInterval);

            if (reconnectionCodes.includes(event.code)) {
                console.log('Disconnected with event code: ' + event.code + ' ...will try to reconnect');
                next({
                    type: coreEvents.RECONNECTION
                });
                // websocket auto-reconnects, do not need to explicitly call reconnect...
                // websocket.reconnect();
            } else {
                console.log('Disconnected with event code: ' + event.code + ' ...will not reconnect');
                next({
                    type: coreEvents.CLOSED,
                    payload: {
                        code: event.code
                    }
                });
                websocket.close(event.code, 'Will not reconnect');
            }
        };

        websocket.onmessage = event => {
            // log raw messages from the server?
            // console.log(event.data);

            const message = JSON.parse(event.data);
            const { e, type, data, uid, status, originUid, ...messageData } = message;

            // Prevent pong from logging into browser console
            if (type === 'pong') {
                return;
            }

            if (callbacks[originUid] && !isErrorCode(status.code)) {
                store.dispatch({
                    type: callbacks[originUid],
                });
                delete callbacks[originUid];
                return;
            }

            // TODO Replace with callbacks

            // set mappings in ../app-config/default.js
            const responseCode = status ? status.code.toString().slice(status.code.toString().length - 3) : '';

            let actionType = null;
            let typemsg = '';

            if (!type && e) {
                actionType = status ? errors[status.code] : incomingEvents[e];
                if (actionType === 'OK' || responseCode === '200' || responseCode === '201') actionType = incomingEvents[e];
                typemsg = e;
            } else {
                actionType = status ? errors[status.code] : incomingEvents[type];
                if (actionType === 'OK' || responseCode === '200' || responseCode === '201') actionType = incomingEvents[type];
                typemsg = type;
            }

            // console.log(type, e, status, actionType, typemsg);
            // console.log(message);

            actionType = actionType || 'UNKNOWN_MESSAGE';

            const action = {
                uid,
                type: actionType,
                typemsg: typemsg,
                status: status,
                payload: data ? data : status,
                ...messageData
            };

            // update api keys table after changes are made
            // TODO: somehow I don't think this belongs here... needs to move to a "reducer triggers props update triggers componentDidUpdate()" setup
            if (actionType === 'CREATE_API_KEY_SUCCESS' || actionType === 'DISABLE_API_KEY_SUCCESS' || actionType === 'DELETE_API_KEY_SUCCESS') {
                store.dispatch(getApiKeys());
            }

            if (actionType === 'SUCCESS_LOGOUT' || actionType === 'SESSION_IP_MISMATCH') {
                isAuthorized = false;
            }

            if (actionType === 'SUCCESS_LOGIN') {
                isAuthorized = true;
                authRequests.map(message => websocket.send(JSON.stringify(message)));
                authRequests = [];
            }

            if (status && isErrorCode(status.code) && !errors[status.code]) {
                // moved this directly into App.js
                // if (status.message && status.message.includes('currently under maintenance')) {
                //    store.dispatch(push('/maintenance'));
                // }
                if (status.code === 102450) { // SESSION_ENDED
                    // process as an action
                } else {
                    store.dispatch(addNotification({
                        text: status.message,
                        code: status.code,
                        type: 'error',
                    }));
                    return;
                }
            }

            next(action);
        };

        return async action => {

            if (!action.ws) {
                return next(action);
            }

            const { type, data, requireAuthorization } = action.ws;

            if (!action.type) {
                action.type = type;
            }

            const message = {
                type: outgoingEvents[type],
                data,
                uid: guid()
            };

            if (wsCallbacks[type]) {
                callbacks[message.uid] = wsCallbacks[type];
            }

            if(websocket.readyState !== 1 && !requireAuthorization) {
                stack = [message, ...stack];
            } else if (!isAuthorized && requireAuthorization) {
                authRequests.push(message);
            } else {
                websocket.send(JSON.stringify(message));
                //return next({
                //    type,
                //    payload: data
                //});
            }
        };
    };
};
