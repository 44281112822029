import React from 'react';

import { Component as StandardPage } from '../../components/StandardPage';
import { Component as NotFound } from './components/NotFound';


export default function NotFoundScreen() {
    return (
        <StandardPage>
            <NotFound/>
        </StandardPage>
    );
}
