import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as CurrencyImage } from '../../../../components/CurrencyImage';
import {DisplayFiat, displayFormat} from '../../../../utils';


const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  >span {
	color: #9B9B9B;
	font-size: 12px;
	letter-spacing: 0.43px;
	line-height: 19px;
	text-transform: uppercase;
  }

  p {
    color: #9B9B9B;
	font-size: 14px;
	letter-spacing: 0.43px;
	line-height: 19px;

	span {
	  color: #ef8632;
	  cursor: pointer;
	  user-select: none;
	}
  }
`;

const ListWrapper = styled.div`
`;

const Option = styled(NavLink)`
  width: 280px;
  display: flex;
  border-top: 1px solid #eee;
  position: relative;
  padding: 10px 0px 10px 15px;
  // white-space: pre-wrap;
  // padding: 20px 20px 20px 20px;
  text-decoration: none;
  margin-bottom: 1px;

  color: #232323;
  justify-content: space-between;
  align-items: center;

  &.active {
    background-color: #ef863214;
    border-color: #ef863214;
  }

  &:hover {
    background-color: #ef863214;
  }

  &.inactivePair {
    background-color: rgba(2, 23, 71, 0.05);
  }

  &.inactivePair:hover {
    background-color: rgba(2, 23, 71, 0.10);
  }

  >span {
    // display: flex;
    // align-items: center;
    // height: 24px;
    // margin-top: 13px;
    img {
      margin-right: 10px;
    }
  }
`;

const BoldCoin = styled.span`
    font-weight: bold;
`;

const CoinLabel = styled.div`
  display: flex;
  align-items: center;
`;

const CoinLabelText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  white-space: nowrap;
`;

const CoinValues = styled.div`
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;

  >span {
    width: 30px;
  }

  p {
    margin: 0 10px 0 0;

    span {
      display: block;
      text-align: right;

      &:first-child {
        font-weight: bold;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:first-child:hover {
        overflow: visible;
      }

      &:last-child {
        color: #9B9B9B;
        font-size: 14px;
      }
    }
  }
`;

const CurrencyIcon = styled(CurrencyImage)`
  margin-right: 15px;
  width: 35px;
  height: 35px;
`;

export default class PortfolioCurrenciesList extends React.Component {

    static propTypes = {
        coinsConfig: PropTypes.array,
        /*
        wallets: PropTypes.arrayOf(PropTypes.shape({
            coin: PropTypes.string,
            avail: PropTypes.number,
            fiat: PropTypes.number,
            lock: PropTypes.string
        })),
        */
        fiatCurrency: PropTypes.string,
        usdToFiatRate: PropTypes.number,
        wallets: PropTypes.array,
        activeCoin: PropTypes.string,

        intl: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            sortDirection: 'DESC',
            sortColumn: 'VALUE',
            isPortfolioRequested: false,
            wallets: [],
        };
    }

    componentDidMount() {
        this.sortWallets();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.wallets !== prevProps.wallets || this.state.sortDirection !== prevState.sortDirection || this.state.sortColumn !== prevState.sortColumn) {
            this.sortWallets();
        }
    }

    sortWallets() {
        const { wallets } = this.props;
        const { sortDirection, sortColumn } = this.state;

        if (sortColumn === 'NAME') {
            this.setState({
                wallets: [...wallets].sort(
                    (prev, next) => sortDirection === 'ASC' ?
                        prev.coin > next.coin :
                        next.coin > prev.coin
                ),
            });
        } else {
            this.setState({
                wallets: [...wallets].sort(
                    (prev, next) => sortDirection === 'ASC' ?
                        Number(prev.fiat) - Number(next.fiat) :
                        Number(next.fiat) - Number(prev.fiat)
                ),
            });
        }
    }

    onChangeSortDirection = (column) => {
        this.setState({
            sortColumn: column,
            sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        });
    };

    render() {

        const { coinsConfig, activeCoin, fiatCurrency, usdToFiatRate } = this.props;
        const { wallets, sortDirection, sortColumn } = this.state;

        return (
            <ListWrapper>
                <ListHeader>
                    <FormattedMessage
                        id="portfolio.sort"
                        defaultMessage="Sort: {actionLabel}"
                        tagName="p"
                        values={{
                            actionLabel: (
                                <FormattedMessage
                                    id="portfolio.sortName"
                                    defaultMessage="Ticker"
                                >
                                    {message => <span onClick={() => { this.onChangeSortDirection('NAME'); }}>{message} {sortColumn === 'NAME' && (sortDirection === 'ASC' ? '↑' : '↓')}</span>}
                                </FormattedMessage>
                            )
                        }}
                    />

                    <FormattedMessage
                        id="portfolio.sort"
                        defaultMessage="Sort: {actionLabel}"
                        tagName="p"
                        values={{
                            actionLabel: (
                                <FormattedMessage
                                    id="portfolio.sortValue"
                                    defaultMessage="Value"
                                >
                                    {message => <span onClick={() => { this.onChangeSortDirection('VALUE'); }}>{message} {sortColumn === 'VALUE' && (sortDirection === 'ASC' ? '↑' : '↓')}</span>}
                                </FormattedMessage>
                            )
                        }}
                    />
                </ListHeader>
                {wallets.map((wallet, index) => (
                    <React.Fragment key={'active-' + wallet.coin}>
                        {(coinsConfig && coinsConfig.length > 0 && coinsConfig.find(config => config.name && config.name === wallet.coin)) && (
                            <Option
                                key={index}
                                to={`/account/portfolio/${wallet.coin}`}
                                exact
                                isActive={() => activeCoin === wallet.coin}
                            >
                                <CoinLabel>
                                    <CurrencyIcon currency={wallet.coin} />
                                    <CoinLabelText>
                                        <BoldCoin>
                                            {wallet.coin}<br/>
                                        </BoldCoin>
                                        {coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).label}
                                    </CoinLabelText>
                                </CoinLabel>
                                <CoinValues>
                                    <p>
                                        <span>
                                            {displayFormat(wallet.total, 8)}
                                        </span>
                                        {fiatCurrency === 'USD' ? (
                                            <span>
                                                <DisplayFiat currency={fiatCurrency} value={coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice * (+wallet.total)}/>
                                            </span>
                                        ) : (
                                            <span>
                                                <DisplayFiat currency={fiatCurrency} value={coinsConfig.find(coinConfig => coinConfig.name === wallet.coin).coinPrice * (usdToFiatRate !== 0 ? usdToFiatRate : 0) * (+wallet.total)}/>
                                            </span>
                                        )}
                                    </p>
                                </CoinValues>
                            </Option>
                        )}
                    </React.Fragment>
                ))}
                <br/>
                {wallets.map((wallet, index) => (
                    <React.Fragment key={'inactive-' + wallet.coin}>
                        {(!coinsConfig.find(coinConfig => coinConfig.name === wallet.coin)) && (
                            <Option
                                className='inactivePair'
                                key={index}
                                to={`/account/portfolio/${wallet.coin}`}
                                exact
                                isActive={() => activeCoin === wallet.coin}
                            >
                                <CoinLabel>
                                    <CurrencyIcon currency={wallet.coin} />
                                    <CoinLabelText>
                                        <BoldCoin>
                                            {wallet.coin}<br/>
                                        </BoldCoin>
                                        (inactive)
                                    </CoinLabelText>
                                </CoinLabel>
                                <CoinValues>
                                    <p>
                                        <span>
                                            {displayFormat(wallet.total, 8)}
                                        </span>
                                        <span>
                                        </span>
                                    </p>
                                </CoinValues>
                            </Option>
                        )}
                    </React.Fragment>
                ))}
            </ListWrapper>
        );
    }
}
