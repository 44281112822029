import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { Component as QRCode } from '../../components/QRCode';
import { Component as Button } from '../Button';

import { mobile, smallMobile } from '../../styles';
import { generateQRString } from '../../utils';

import copyIcon from '../../images/ic-copy.svg';


const TagRequiredButton = styled(Button)`
  display: inline-block;
  text-align: center;
  width: 100%;

  ${mobile`
    display: flex;
    justify-content: center;

    a {
        width: 170px;
        text-align: center;
    }
  `}
`;

const AddressLine = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 10px;

  label {
    color: #9B9B9B;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  p {
    margin: 0;
    word-break: break-all;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;

    ${mobile`
        position: relative;
        width: 100%;
        text-align: center;
        display: block;
        font-size: 16px;
        padding: 14px;
        box-sizing: border-box;
        border: 2px solid #ef8632;
        border-radius: 5px;
        margin-top: 20px;
    `}
  }

  ${mobile`
    padding: 15px;
    text-align: center;
    justify-content: center;
    // display: none;
  `}
`;

const ResponsiveQR = styled(QRCode)`
  ${mobile`
    padding: 2px 15px 2px 15px;
    text-align: center;
    justify-content: center;
    // display: none;
  `}
`;

const SubmitWrapper = styled.div`
  padding: 0 48px;
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  justify-content: center;

  ${mobile`
    padding: 15px 40px 15px 40px;
  `};

  ${smallMobile`
    padding: 15px 20px 15px 20px;
  `};
`;

const SubmitBlock = styled.div`
  display: flex;

  ${mobile`
    flex-direction: column;
    justify-content: center;
  `};
`;

const SubmitInfoBlock = styled.div`
  margin-right: 40px;

  ${mobile`
    margin: 0;
    text-align: center;
    justify-content: center;
  `}

  h3 {
    color: #4A4A4A;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
    margin-bottom: 20px;

    ${mobile`
        text-align: center;
    `}
  }

  h4 {
    text-align: center;
    color: #D0021B;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
    margin-bottom: 20px;

    ${mobile`
        text-align: center;
    `}
  }

  img {
    margin-right: 3px;
    margin-bottom: -1px;
  }
`;

const QRBlock = styled.div`
  // display: inline-block;
  // margin-left: 30px;
  margin: 5px;
  font-size: 13px;
  text-align: center;

  a {
    color: #ef8632;
    // line-height: 16px;
  }
`;


export default class SubmitDepositInfo extends React.Component {

    static propTypes = {
        coinConfig: PropTypes.object,
        addressObj: PropTypes.object,
        tagLabel: PropTypes.string,
        minAmount: PropTypes.number,
        maxAmount: PropTypes.number,
        amount: PropTypes.number,
    };

    state = {
        viewDepositAddress: false,
        depositAddressCopied: false,
        depositTagCopied: false,
    };

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    onSubmitViewDepositAddress = () => this.setState({
        viewDepositAddress: true
    });

    onCopyDepositAddress() {
        this.setState({
            depositAddressCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                depositAddressCopied: false,
            });
        }, 1500);
    }

    onCopyDepositTag() {
        this.setState({
            depositTagCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                depositTagCopied: false,
            });
        }, 1500);
    }

    render() {

        const { addressObj, tagLabel, amount, minAmount, maxAmount, coinConfig } = this.props;
        const { viewDepositAddress } = this.state;
        const qrString = generateQRString(addressObj, coinConfig, amount);

        /*
        var address = '';
        var tag = '';
        var currency = '';
        if (addressObj) {
            address = addressObj.address;
            tag = addressObj.tag;
            currency = addressObj.asset;
        }
        */

        return (
            <SubmitWrapper>
                <SubmitBlock>
                    { maxAmount > 0 && addressObj && addressObj.address && (
                        !viewDepositAddress && addressObj.asset === 'XRP' && addressObj.tag ? (
                            <SubmitInfoBlock>
                                <FormattedMessage
                                    id="oct.tag_required_notice"
                                    defaultMessage="{name} required for {currency} deposits"
                                    tagName="h3"
                                    values={{
                                        currency: addressObj.asset,
                                        name: tagLabel.replace(' (Optional)', '')
                                    }}
                                />
                                <TagRequiredButton onClick={this.onSubmitViewDepositAddress}>
                                    <FormattedMessage
                                        id="oct.tag_required_button_ack"
                                        defaultMessage="I understand, show me the deposit address"
                                    />
                                </TagRequiredButton>
                            </SubmitInfoBlock>
                        ) : (
                            <React.Fragment>
                                <SubmitInfoBlock>
                                    <h3>
                                        Deposit between {minAmount} {addressObj.asset} and {maxAmount} {addressObj.asset} to start your QuickTrade
                                    </h3>
                                    <AddressLine>
                                        <label>
                                            Deposit Address
                                        </label>
                                        <p>
                                            {addressObj.address}
                                        </p>
                                        {this.state.depositAddressCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                            <CopyToClipboard onCopy={()=>this.onCopyDepositAddress() } text={addressObj.address}>
                                                <span>
                                                    <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                    Copy address to clipboard
                                                </span>
                                            </CopyToClipboard>
                                        )}
                                    </AddressLine>
                                    <AddressLine>
                                        {addressObj.asset === 'XRP' && addressObj.tag && (
                                            <React.Fragment>
                                                <label>
                                                    {tagLabel.replace(' (Optional)', '')}
                                                </label>
                                                <p>
                                                    {addressObj.tag}
                                                </p>
                                                {this.state.depositTagCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                    <CopyToClipboard onCopy={()=>this.onCopyDepositTag() } text={addressObj.tag}>
                                                        <span>
                                                            <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                            Copy tag to clipboard
                                                        </span>
                                                    </CopyToClipboard>
                                                )}
                                                <label style={{color: '#D0021B'}}>
                                                    Failure to include the {tagLabel.replace(' (Optional)', '')} will forfeit your deposit
                                                </label>
                                            </React.Fragment>
                                        )}
                                    </AddressLine>
                                </SubmitInfoBlock>
                                <QRBlock>
                                    {amount && amount > 0 && (
                                        <React.Fragment>
                                            <FormattedMessage
                                                id="oct.amount_currency"
                                                defaultMessage="{amount} {currency}"
                                                values={{
                                                    currency: addressObj.asset,
                                                    amount: amount,
                                                }}
                                            />
                                            <br/>
                                            <FormattedMessage
                                                id="oct.adjust_via_slider"
                                                defaultMessage="(adjust via slider)"
                                                values={{
                                                    currency: addressObj.asset,
                                                    amount: amount,
                                                }}
                                            />
                                        </React.Fragment>
                                    )}
                                    <a href={qrString}>
                                        <ResponsiveQR value={qrString}/>
                                    </a>
                                    <FormattedHTMLMessage
                                        id="oct.canada_trade_regulation"
                                        defaultMessage="<a href={qrString} target=_blank>Open In App</a>"
                                        values={{
                                            amount: amount,
                                            qrString: qrString,
                                        }}
                                    />
                                </QRBlock>
                            </React.Fragment>
                        )
                    )}
                </SubmitBlock>
            </SubmitWrapper>

        );  // end return

    } // end render

} // end component
