import React from 'react';
import styled from 'styled-components';
import mailIcon from '../../images/ic-mail-big.svg';
import { FormattedMessage } from 'react-intl';


const Container = styled.div`
  // width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;
`;

const ConfirmationBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 310px;
`;

const Title = styled.p`
	color: #4A4A4A;
	font-size: 24px;
	font-weight: 300;
	line-height: 36px;
	text-align: center;
	margin: 0;
	margin-top: 15px;
	margin-bottom: 30px;
`;

const MailIcon = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 150px;
`;


export default class WithdrawalConfirmPanel extends React.Component {

    render() {

        return (
            <Container>
                <ConfirmationBody>
                    <MailIcon src={'/' + mailIcon}/>
                    <Title>
                        <FormattedMessage
                            id="confirmWithdrawal.heading"
                            defaultMessage="Withdrawal request confirmed. Your request has been submitted for processing."
                        />
                    </Title>
                </ConfirmationBody>
            </Container>
        );

    }

}
