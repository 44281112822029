import {handleActions} from 'redux-actions';
import {NotificationManager} from 'react-notifications';
import {format, formatCeil, formatFloor} from '../../utils';


const defaultState = {
    wallets: [],
    coinsConfig: [],
    depositAddresses: [],
    refreshDepositAddresses: false,
    withdrawalAddresses: [],
    refreshWithdrawalAddresses: false,
    refreshPortfolioInfo: false,
    reloadWalletActivity: false,
    walletActivity: undefined,
    withdrawalRequestState: undefined,
    fiatCurrency: 'USD',
    usdToFiatRate: 1,
};

const sortWallets = (wallets) => {
    return [].concat(wallets).sort(function (a, b) {
        if (a.coin < b.coin) {
            return -1;
        }
        if (a.coin > b.coin) {
            return 1;
        }
        return 0;
    });
};


export default handleActions(
    {
        ['CLEAR_AUTH_DATA']: () => {
            return defaultState;
        },
        ['EXCHANGE_CONFIG']: (state, action) => {
            const config = action.payload;

            if (config.coinsConfig != null) {
                let newCoins = [];
                config.coinsConfig.forEach(function (coinConfig) {
                    const newCoinConfig = {
                        name: coinConfig.name,
                        coinPrice: coinConfig.coinPrice
                    };
                    newCoins.push(newCoinConfig);
                });

                return {
                    ...state,
                    coinsConfig: newCoins,
                };
            }
        },
        ['PORTFOLIO_INFO']: (state, action) => {
            return {
                ...state,
                refreshPortfolioInfo: false,
                fiatCurrency: action.payload.fiatCurrency,
                usdToFiatRate: action.payload.usdToFiatRate,
                wallets: action.payload.wallets ? sortWallets(action.payload.wallets.map(w => ({
                    ...w,
                    total: formatFloor((w.avail + w.lock), 8),
                    avail: formatFloor(w.avail, 8),
                    lock: formatCeil(w.lock, 8),
                }))) : state.wallets
            };
        },
        ['CLEAR_DEPOSIT_ADDRESSES']: (state) => {
            return {
                ...state,
                depositAddresses: [],
                refreshDepositAddresses: true,
            };
        },
        ['GET_DEPOSIT_ADDRESSES_SUCCESS']: (state, action) => {
            return {
                ...state,
                depositAddresses: state.depositAddresses && state.depositAddresses.length !== 0 && action.payload.addresses.length !== 0 &&
                    state.depositAddresses[0].asset === action.payload.addresses[0].asset && action.payload.addresses.length === 1 ?
                    action.payload.addresses.concat(state.depositAddresses) : action.payload.addresses,
                refreshDepositAddresses: false,
            };
        },
        ['CLEAR_WITHDRAWAL_ADDRESSES']: (state) => {
            return {
                ...state,
                withdrawalAddresses: [],
                refreshWithdrawalAddresses: true,
            };
        },
        ['GET_WITHDRAWAL_ADDRESSES_SUCCESS']: (state, action) => {
            return {
                ...state,
                withdrawalAddresses: action.payload.addresses,
                refreshWithdrawalAddresses: false,
            };
        },
        ['RELOAD_WALLET_ACTIVITY']: (state) => {
            return {
                ...state,
                reloadWalletActivity: true,
            };
        },
        ['CLEAR_WALLET_ACTIVITY']: (state) => {
            return {
                ...state,
                reloadWalletActivity: false,
                walletActivity: undefined,
            };
        },
        ['GET_WALLET_ACTIVITY_SUCCESS']: (state, action) => {
            if (!action.payload.items.content || action.payload.items.content.length === 0) {
                return state;
            } else {
                return {
                    ...state,
                    reloadWalletActivity: false,
                    walletActivity: action.payload.items
                };
            }
        },
        ['CONFIRM_WITHDRAW']: (state, action) => {
            return {
                ...state,
                confirmationUuid: action.payload.confirmationUuid,
                confirmed: action.payload.confirmed
            };
        },
        ['STREAM_BALANCE_UPDATE']: (state, action) => {
            let prunedWallets = state.wallets;
            let newWallets = [];
            for (const w of action.B) {
                const coinConfig = state.coinsConfig ? state.coinsConfig.find(coinConfig => (coinConfig.name === w.a)) || undefined : undefined;
                const wallet = state.wallets ? state.wallets.find(wallet => (wallet.coin === w.a)) || undefined : undefined;

                if (coinConfig && wallet) {
                    const rateUSD = coinConfig.coinPrice;
                    const rate = coinConfig.coinPrice * state.usdToFiatRate;

                    // console.log('rate', rate, 'rateUSD', rateUSD, coinConfig);

                    const newWallet = {
                        coin: w.a,
                        avail: formatFloor(w.f, 8),
                        fiat: +format(((+w.f) + (+w.l)) * rate, 2),
                        fiatUSD: +format(((+w.f) + (+w.l)) * rateUSD, 2),
                        lock: formatCeil(w.l, 8),
                        total: formatFloor(((+w.f) + (+w.l)), 8),
                        prmNetwork: wallet.prmNetwork,
                        prmAddress: wallet.prmAddress,
                        prmLabel: wallet.prmLabel,
                        prmTag: wallet.prmTag,
                        rad: wallet.rad,
                        rat: wallet.rat,
                        raw: wallet.raw
                    };

                    prunedWallets = prunedWallets.filter(wallet => wallet.coin !== newWallet.coin);
                    newWallets.push(newWallet);
                }
            }
            return {
                ...state,
                wallets: [ ...prunedWallets, ...newWallets],
            };
        },
        ['CLEAR_WITHDRAW_REQUEST']: (state) => {
            return {
                ...state,
                withdrawalRequestState: undefined,
            };
        },
        ['WITHDRAW_REQUEST_SENT']: (state) => {
            NotificationManager.info('Request Sent', 'Withdrawal Request Sent');
            return {
                ...state,
                withdrawalRequestState: 'sent',
            };
        },
        ['WITHDRAW_INITIATED']: (state) => {
            NotificationManager.success('Check your Email', 'Withdrawal Process Initiated');
            return {
                ...state,
                withdrawalRequestState: 'success',
            };
        },
        ['SET_WITHDRAW_ADDRESS_SUCCESS']: (state) => {
            NotificationManager.success('Withdrawal Address Set');
            return {
                ...state,
                refreshWithdrawalAddresses: true,
            };
        },
        ['DELETE_WITHDRAW_ADDRESS_SUCCESS']: (state) => {
            NotificationManager.success('Withdrawal Address Deleted');
            return {
                ...state,
                refreshWithdrawalAddresses: true,
            };
        },
        ['SET_PERM_WITHDRAW_SUCCESS']: (state) => {
            NotificationManager.success('Permanent Withdrawal Address Set');
            return {
                ...state,
                refreshPortfolioInfo: true,
            };
        },
    },
    defaultState
);
