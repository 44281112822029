import { css } from 'styled-components';

export function mobile(...args) {
    return css`
        @media (max-width: 768px) {
          ${css(...args)}
        }
    `;
}

export function smallMobile(...args) {
    return css`
        @media (max-width: 360px) {
          ${css(...args)}
        }
    `;
}
