import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from 'config';

import warningIcon from '../../images/ic-warning.svg';
import gauthIcon from '../../images/gauth.png';
import smsIcon from '../../images/sms.png';
import smsRefreshIcon from '../../images/sms_refresh.png';
import yubikeyIcon from '../../images/yubikey.png';

const isSMSEnabled = config.get('features.sms');


const InputWrapper = styled.div`
  width: 150px;
  display: inline-block;
  position: relative;
`;

const InputElement = styled.input`
  	height: 44px;
  	// border: 1px solid #CFCFCF;
  	// border-radius: 5px;
  	background-color: #FFFFFF;

  	color: #4A4A4A;
  	font-size: 14px;
  	font-weight: 600;
  	// letter-spacing: 0.2px;

    // padding: 0 1em;
    // width: 100%;
    box-sizing: border-box;

    :disabled {
      background-color: #ddd;
      cursor: not-allowed;
    }

    ::placeholder {
      color: #9B9B9B;
      opacity: 0.9;
      font-weight: 400;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
    }

    padding: 10px;
    border-radius: 3px;
    border: 1px solid #ddd;
    width: 150px;
    // font-size: 16px;
    // line-height: 18px;
    letter-spacing: 5px;
    // height: 50px;
`;

/*
const InputElementSmall = styled(InputElement)`
    width: 50px;
`;
*/

const AuthIcons = styled.div`
  display: inline-block;
  position: absolute;
  width: 150px;
  text-align: center;
  top: 9px;
  right: 0px;
`;

const AuthIcon = styled.img`
  width: 25px;
  height: 25px;
  // padding-left: 10px;
  padding-right: 5px;
  // position: absolute;
  // top: 14px;
  // right: 10px;
`;

const RefreshIcon = styled.img`
  width: 15px;
  height: 15px;
  padding-left: 10px;
  padding-right: 5px;
  // position: absolute;
  // top: 14px;
  // right: 10px;
`;

const Warning = styled.img`
  // padding-left: 10px;
  padding-right: 5px;
  // position: absolute;
  // top: 14px;
  // right: 10px;
`;

const ErrorMessage = styled.span`
  color: #D0021B;
  left: 0;
  // width: 100%;
  font-size: 14px;
  white-space: nowrap;
`;

const NoticeMessage = styled.span`
  color: #02D01B;
  left: 0;
  // width: 100%;
  font-size: 14px;
  white-space: nowrap;
`;

const Label = styled.div`
  padding-left: 5px;
  text-align: left;
  font-size: 14px;
`;


export default class InputTwoFa extends React.Component {

    static propTypes = {
        clearRequestSmsSend: PropTypes.func,
        requestSendSmsCode: PropTypes.func,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        component: PropTypes.string.isRequired,
        error: PropTypes.string,
        securedMethods: PropTypes.array,
        securedActions: PropTypes.array,
        isCredentialsError: PropTypes.bool,
        isSmsSendSuccess: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state={
            clicked: false,
            smsSentMessage: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.isSmsSendSuccess !== prevProps.isSmsSendSuccess && this.props.isSmsSendSuccess === true) {
            this.setState({
                smsSentMessage: 'SMS Sent!',
            });
        }
    }

    onClick = () => {
        const { clicked } = this.state;
        if (!clicked) {
            this.setState({
                clicked: true,
            });
        }
    };

    onSendSms = () => {
        this.setState({
            smsSentMessage: '',
        });
        this.props.clearRequestSmsSend();
        this.props.requestSendSmsCode();
    };

    static defaultProps = {
        type: 'text'
    };

    render() {

        const { error, component, securedMethods, isCredentialsError } = this.props;
        const { clicked, smsSentMessage } = this.state;

        const placeholder = '✕✕✕ ✕✕✕';
        const displayError = (isCredentialsError) ? 'Code Validation Failed' : error;

        return (
            <React.Fragment>
                <Label>
                    <FormattedMessage
                        id="trade.enter2FA"
                        defaultMessage="Two Factor Code"
                    />
                    {(isSMSEnabled && securedMethods.includes('SMS') && component !== 'TRADE' && !smsSentMessage) && (
                        <RefreshIcon src={'/' + smsRefreshIcon} alt="Send SMS Code" title="Send SMS Code" onClick={() => {this.onSendSms();}}/>
                    )}
                </Label>
                <InputWrapper onClick={() => {this.onClick();}}>
                    {clicked ? (
                        <InputElement autoFocus {...this.props} placeholder={placeholder} />
                    ) : (
                        <React.Fragment>
                            <InputElement onFocus={() => {this.onClick();}} {...this.props} placeholder="" />
                            <AuthIcons>
                                {securedMethods.includes('YUBIKEY') && (
                                    <AuthIcon src={'/' + yubikeyIcon}/>
                                )}
                                {securedMethods.includes('GOOGLE') && (
                                    <AuthIcon src={'/' + gauthIcon}/>
                                )}
                                {(isSMSEnabled && securedMethods.includes('SMS') && component !== 'TRADE') && (
                                    <AuthIcon src={'/' + smsIcon}/>
                                )}
                            </AuthIcons>
                        </React.Fragment>
                    )}
                    {displayError ? (
                        <React.Fragment>
                            <br/>
                            <ErrorMessage>
                                <Warning src={'/' + warningIcon}/>
                                {displayError}
                            </ErrorMessage>
                        </React.Fragment>
                    ) : smsSentMessage && (
                        <React.Fragment>
                            <br/>
                            <NoticeMessage>
                                {smsSentMessage}
                            </NoticeMessage>
                        </React.Fragment>
                    )}
                </InputWrapper>
            </React.Fragment>
        );
    }
}
