import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfilePoRConfiguration from './ProfilePoRConfiguration';

import { getProofOfReserves, setProofOfReserves } from '../../../../actions/user';
import { clearErrors } from '../../../../actions/errors';


function mapStateToProps(state) {
    return {
        user: state.user,
        walletProofs: state.portfolio.walletProofs,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getProofOfReserves,
            setProofOfReserves,
            clearErrors,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePoRConfiguration);
