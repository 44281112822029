import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TradeMyOrders from './TradeMyOrders';
import { submitCancelOrders } from '../../../../actions/market';
import {getBaseCoinConfig, getQuoteCoinConfig} from '../../../../selectors';

function mapStateToProps(state) {
    return {
        baseCoinConfigName: getBaseCoinConfig(state) && getBaseCoinConfig(state).name,
        quoteCoinConfigName: getQuoteCoinConfig(state) && getQuoteCoinConfig(state).name,
        orders: state.market.activeOrderHashes,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitCancelOrders,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradeMyOrders);
