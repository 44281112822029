import { getDeviceInfo, guid } from '../../utils';
import config from 'config';

const isSMSEnabled = config.get('features.sms');

/*
 * INTERNAL REQUESTS
 */

export const updateAuthInfo = info => ({
    type: 'UPDATE_INFO',
    payload: info
});

export const loginLoading = loginLoading => ({
    type: 'LOGIN_LOADING',
    payload: loginLoading
});

export const clearAuthData = () => ({
    type: 'CLEAR_AUTH_DATA',
});

export const clear2faAuths = () => ({
    type: 'CLEAR_2FA_AUTHS',
});

export const clearRequestSmsSend = () => ({
    type: 'CLEAR_REQUEST_SMS_SEND',
});


/*
 * REST REQUESTS
 */

export const registerEmail = ({ email }) => {
    return async dispatch => {
        return dispatch({
            api: {
                type: 'REQUEST_REGISTER_EMAIL',
                // endpoint: 'registration/email',
                method: 'POST',
                body: JSON.stringify({
                    type: 'register_email',
                    data: {
                        email,
                        acceptedTerms: true,
                        deviceInfo: await getDeviceInfo()
                    },
                    uid: guid()
                }),
                types: [
                    'ADD_EMAIL_START',
                    'ADD_EMAIL_SUCCESS',
                    'ADD_EMAIL_FAILURE'
                ]
            }
        });
    };
};

export const regionServiceStatus = (jurisdiction) => {
    if (jurisdiction) {
        return async dispatch => {
            return dispatch({
                api: {
                    type: 'REQUEST_REGION_SERVICE_STATUS',
                    params: {
                        jurisdiction: jurisdiction,
                    },
                    types: [
                        'REGION_SERVICE_STATUS_START',
                        'REGION_SERVICE_STATUS_SUCCESS',
                        'REGION_SERVICE_STATUS_FAILURE'
                    ]
                }
            });
        };
    } else {
        return async dispatch => {
            return dispatch({
                api: {
                    type: 'REQUEST_REGION_SERVICE_STATUS',
                    types: [
                        'REGION_SERVICE_STATUS_START',
                        'REGION_SERVICE_STATUS_SUCCESS',
                        'REGION_SERVICE_STATUS_FAILURE'
                    ]
                }
            });
        };
    }
};

export const registerPassword = ({ registerSessionId, password }) => {
    return async dispatch => {
        return dispatch({
            api: {
                type: 'REQUEST_REGISTER_PASSWORD',
                // endpoint: 'registration/pass',
                method: 'POST',
                body: JSON.stringify({
                    type: 'register_password',
                    data: {
                        registerSessionId,
                        password,
                        deviceInfo: await getDeviceInfo()
                    },
                    uid: guid()
                }),
                types: [
                    'ADD_PASSWORD_START',
                    'ADD_PASSWORD_SUCCESS',
                    'ADD_PASSWORD_FAILURE'
                ]
            }
        });
    };
};

export const resetPassword = ({ email }) => {
    return async dispatch => {
        return dispatch({
            api: {
                type: 'REQUEST_RESET_PASSWORD_LINK',
                // endpoint: 'registration/resetpass',
                method: 'POST',
                body: JSON.stringify({
                    type: 'resetpass',
                    data: {
                        email,
                        deviceInfo: await getDeviceInfo()
                    },
                    uid: guid()
                }),
                types: [
                    'RESET_PASSWORD_REQUEST',
                    'RESET_PASSWORD_SUCCESS',
                    'RESET_PASSWORD_FAILURE'
                ]
            }
        });
    };
};

// TODO: Transition to using WSS for this
export const updatePassword = ({ token, password, code }) => {
    return async dispatch => {
        return dispatch({
            api: {
                type: 'REQUEST_RESET_PASSWORD_SAVE',
                method: 'POST',
                body: JSON.stringify({
                    type: 'resetpassapply',
                    data: {
                        token,
                        password,
                        code,
                        deviceInfo: await getDeviceInfo()
                    },
                    uid: guid()
                }),
                types: [
                    'UPDATE_PASSWORD_REQUEST',
                    'UPDATE_PASSWORD_SUCCESS',
                    'UPDATE_PASSWORD_FAILURE'
                ]
            }
        });
    };
};


/*
 * WEBSOCKET REQUESTS
 */

export const subscribeDevice = () => {
    // requests with device info must be async
    return async (dispatch) => {
        return dispatch({
            ws: {
                type: 'REQUEST_SUBSCRIBE_DEVICE',
                data: {
                    deviceInfo: await getDeviceInfo(),
                },
                uid: guid()
            }
        });
    };
};

export const login = ({ login, pass, token }) => {
    // requests with device info must be async
    return async (dispatch) => {
        return dispatch({
            ws: {
                type: 'REQUEST_LOGIN',
                data: {
                    token,
                    login,
                    pass,
                    sms: isSMSEnabled,
                    deviceInfo: await getDeviceInfo(),
                },
                uid: guid()
            }
        });
    };
};

export const login2fa = (code, token) => {
    // requests with device info must be async
    return async dispatch => {
        return dispatch({
            ws: {
                type: 'LOGIN_2FA',
                data: {
                    token,
                    code,
                    deviceInfo: await getDeviceInfo(),
                },
                uid: guid()
            }
        });
    };
};

export const logout = () => {
    sessionStorage.removeItem('token');
    return dispatch => {
        dispatch({
            type: 'LOGOUT_REQUESTED',
        });
        dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
                type: 'info',
                text: 'Sign out requested'
            }
        });
        return dispatch({
            type: 'WS_REQUEST_LOGOUT',
            ws: {
                type: 'REQUEST_LOGOUT',
                data: {},
                uid: guid()
            }
        });
    };
};

export const extendSession = () => ({
    ws: {
        type: 'EXTEND_SESSION',
        data: {},
        uid: guid(),
        requireAuthorization: true
    }
});

export const get2faAuths = () => ({
    ws: {
        type: 'GET_2FA_AUTHS',
        data: {},
        uid: guid(),
        requireAuthorization: true
    }
});

export const requestNewGauth = (comment) => ({
    ws: {
        type: 'REQUEST_NEW_GAUTH',
        data: {
            comment,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const requestAdd2fa = (type, secret, comment, newCode, oldCode, phone, actions) => ({
    ws: {
        type: 'REQUEST_ADD_2FA',
        data: {
            twoFaType: type,
            secret,
            comment,
            newCode,
            code: oldCode,
            phone,
            actions,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const requestUpdate2faActions = (actions, code) => ({
    ws: {
        type: 'REQUEST_UPDATE_2FA_ACTIONS',
        data: {
            actions,
            code,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const requestDelete2fa = (id, code) => ({
    ws: {
        type: 'REQUEST_DELETE_2FA',
        data: {
            id,
            code,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const requestSendSmsCode = (phone) => ({
    ws: {
        type: 'REQUEST_SEND_SMS_CODE',
        data: {
            phone,
        },
        uid: guid(),
        requireAuthorization: true
    }
});
