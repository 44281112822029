import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as StandardPage } from '../../components/StandardPage';
import { Component as NotFound } from '../NotFoundScreen/components/NotFound';

import { Container as DepositingStep } from '../QuickTradeScreen/components/TransactionDepositingStep';
import { Container as IncomingDepositStep } from '../QuickTradeScreen/components/TransactionIncomingDepositStep';
import { Container as TransactionCompleteStep } from '../QuickTradeScreen/components/TransactionCompleteStep';

import { Container as QuickTradeProgressBar } from '../QuickTradeScreen/components/QuickTradeProgressBar';

import { LoadingDots } from '../../components/LoadingDots';

const Container = styled.div`
  border-radius: 5px;
  // overflow: hidden; breaks tooltips
`;

const FullScreenText = styled(LoadingDots)`
    font-size: 40px;
    font-weight: 700;
    height: 60vh;
    width: 100%;
    background-color: #fff;
    color: #0005;
    text-shadow: 0px 2px 3px rgba(255,255,255,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
`;

class TransactionLoadingMessage extends React.PureComponent {

    /* if you want to display the quicktrade id...
            {this.props.id ?
                (
                    <FormattedMessage
                        id="general.loading_transaction"
                        defaultMessage="Loading transaction {transactionId}"
                        values={{
                            transactionId: this.props.id
                        }}
                    />
                ) : (
                    <FormattedMessage
                        id="general.loading"
                        defaultMessage="Loading"
                    />
                )
            }
    */

    render() {
        return <FullScreenText>
            <FormattedMessage
                id="general.loading"
                defaultMessage="Loading"
            />
        </FullScreenText>;
    }
}

TransactionLoadingMessage.propTypes = {
    id: PropTypes.string,
};

class SomethingWentWrongMessage extends React.PureComponent {
    render() {
        return <FullScreenText>
            <FormattedMessage
                id="general.loading_transaction_error"
                defaultMessage="Failed to load {transactionId} for stage: {stage}"
                values={{
                    transactionId: this.props.id,
                    stage: this.props.stage
                }}
            />
        </FullScreenText>;
    }
}

SomethingWentWrongMessage.propTypes = {
    id: PropTypes.string,
    stage: PropTypes.string,
};

export default class TransactionScreen extends React.Component {

    static propTypes = {
        subscribeOnTransactionUpdates: PropTypes.func,
        changePair: PropTypes.func,
        stopOneWayPairFeed: PropTypes.func,
        transaction: PropTypes.object,
        transactionId: PropTypes.string,
        status: PropTypes.object,
        location: PropTypes.object,
        isConnected: PropTypes.bool,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        history: PropTypes.object,
    };

    state = {
        initialLoad: true,
        currentStep: 1,
        currencyFrom: '',
        currencyTo: ''
    };

    componentDidMount() {
        if (!this.props.transaction || !this.props.transaction.status || !this.props.transaction.status.type || this.props.transaction.status.type == 'LOADING_TRANSACTION') {
            if (this.props.match.params && this.props.match.params.id) {
                this.props.subscribeOnTransactionUpdates(this.props.match.params.id);
                document.title = 'QuickTrade #' + this.props.match.params.id + ' (BlocPalX)';
            } else {
                this.props.history.push('/');
            }
        }
        //TODO: I feel like we need to implement a changePair call here in case they navigate away and return...?
    }

    componentWillUnmount() {
        this.props.stopOneWayPairFeed();
    }

    UNSAFE_componentWillReceiveProps(props) {
        const newFrom = props.transaction.refundAddr.asset;
        const newTo = props.transaction.targetAddr.asset;

        // subscribe to quicktrade updates whenever we go from not connected to connected
        if (props.isConnected && !this.props.isConnected && this.props.transaction.status.type != 'LOADING_TRANSACTION') {
            this.props.subscribeOnTransactionUpdates(this.props.match.params.id);
        }

        if(this.state.currencyFrom !== newFrom || this.state.currencyTo !== newTo) {
            this.setState({
                currencyFrom: newFrom,
                currencyTo: newTo
            });

            this.props.changePair({
                currencyFrom: newFrom,
                currencyTo: newTo
            });
        }
    }


    render() {

        const { transactionId } = this.props;
        const { status: { type }, depositAmount } = this.props.transaction;

        let content = <TransactionLoadingMessage id={transactionId} />;

        // const { transaction, match } = this.props;
        // console.log('***TRANSACTION***');
        // console.log(type);
        // console.log(match);
        // console.log(transaction);

        if (type === 'LOADING_TRANSACTION') {
            // do nothing...
        } else if (type === 'NOT_FOUND') {
            content = <NotFound/>;
        } else if (type === 'DEPOSIT_ADDR_ASSIGNED' || type === 'DEPOSIT_ADDR_NOT_ASSIGNED') {
            content = <DepositingStep/>;
        } else if (type === 'TRADE_IN_PROGRESS'
            || (type === 'DEPOSIT_IN_PROGRESS' && depositAmount)
            || (type === 'DEPOSIT_CREDITED' && depositAmount)) {
            content = <IncomingDepositStep/>;
        } else if (type === 'TRADE_INITIATED'
            || type === 'TRADE_CANCELED'
            || type === 'TRADE_DONE'
            || type === 'WITHDRAW_REQUESTED'
            || type === 'WITHDRAW_FAILED'
            || type === 'WITHDRAW_FINISHED'
            || type === 'REFUND_REQUESTED'
            || type === 'REFUND_FAILED'
            || type === 'REFUND_FINISHED'
            || type === 'QUICKTRADE_FINISHED') {
            content = <TransactionCompleteStep/>;
        } else if ( type && type !== '') {
            content = <SomethingWentWrongMessage id={transactionId} stage={type} />;
        }

        return (
            <StandardPage narrow>
                <Container>
                    <QuickTradeProgressBar />
                    {content}
                </Container>
            </StandardPage>
        );
    }

}
