import { createSelector } from 'reselect';
import { sigDigits } from '../utils';

// the following are used FOR QUICKTRADES - NOT FULL ACCOUNT TRADING
//
// currentPair
// defaultPair
// partialDepth
// partialDepthPair

const getEnabledPairs = state => state.currency.enabledPairs;
export const getCoinsConfig = state => state.currency.coinsConfig;
export const getCoinsConfigExtended = state => state.currency.coinsConfigExtended;
const getCoinsNews = state => state.currency.coinsNews;
const getCurrentPair = state => state.currency.currentPair;
// const getLastChanged = state => state.currency.lastChanged;
const getPartialDepth = state => state.currency.partialDepth;
// const getRate = state => Number(state.currency.currentPair.rate);


/*
export const getCurrencyConfig = createSelector(
    getCoinsConfig,
    (state, props) => props.coin,
    (coinConfigs, coin) => {
        return coinConfigs ? coinConfigs.find(config => config.name === coin) || {} : {};
    }
);
*/

export const getCoinNews = createSelector(
    getCoinsNews,
    (state, coin) => coin,
    (coinsNews, coin) => {
        return coinsNews.find(config => config.name === coin);
    }
);

export const getCoinNetwork = createSelector(
    getCoinsConfig,
    (state, coin) => coin,
    (state, network) => network,
    (coinsConfig, coin, network) => {
        const coinConfig = coinsConfig.find(config => config.name === coin);
        const networks = coinConfig.networks;
        return networks.find(net => net.name === network);
    }
);

export const getCoinNetworks = createSelector(
    getCoinsConfig,
    (state, coin) => coin,
    (coinsConfig, coin) => {
        const coinConfig = coinsConfig.find(config => config.name === coin);
        if (coinConfig && coinConfig.networks) {
            let mapped = coinConfig.networks.map(item => ({ [item.name]: item }) );
            return Object.assign({}, ...mapped );
        }
        return undefined;
    }
);

export const getCoinConfig = createSelector(
    getCoinsConfig,
    (state, coin) => coin,
    (coinsConfig, coin) => {
        return coinsConfig.find(config => config.name === coin);
    }
);

export const getCoinConfigExtended = createSelector(
    getCoinsConfigExtended,
    (state, coin) => coin,
    (coinsConfigExtended, coin) => {
        return coinsConfigExtended.find(config => config.name === coin);
    }
);

export const getNetworkOptions = createSelector(
    getCoinNetworks,
    (networkConfigs) => {
        let networkOptions = [];
        if (networkConfigs) {
            networkOptions = networkOptions.concat(Object.keys(networkConfigs).map((networkName) => ({
                value: networkName,
                label: networkConfigs[networkName].displayName + ' (' + networkName + ')',
            })));
        }
        return networkOptions;
    }
);

export const getAssetOptions = createSelector(
    getCoinsConfig,
    (coinsConfig) => {
        return Array.from(new Set(coinsConfig)).map(coinConfig => ({
            value: coinConfig.name,
            label: coinConfig.label
        }));
    }
);

export const getAssetOptionsWithPrice = createSelector(
    getCoinsConfig,
    (coinsConfig) => {
        return Array.from(new Set(coinsConfig)).filter(coinConfig => coinConfig.trading).map(coinConfig => ({
            name: coinConfig.name,
            label: coinConfig.label,
            coinPrice: coinConfig.coinPrice,
        }));
    }
);

export const getCurrencyFromOptions = createSelector(
    getCoinsConfig,
    getEnabledPairs,
    (coinsConfig, enabledPairs) => {
        /*
        getCurrentPair
        const { currencyTo } = currentPair;
        const availablePairs = enabledPairs
            .filter(pair => pair.currencyTo === currencyTo || pair.currencyFrom === currencyTo)
            .map(pair => pair.currencyFrom === currencyTo ? pair.currencyTo : pair.currencyFrom);
            .concat([currencyTo]);
        */

        const availablePairs = enabledPairs.map(pair => pair.currencyFrom);

        return Array.from(new Set(availablePairs))
            .map(currency => ({
                value: currency,
                label: coinsConfig.find(coinConfig => coinConfig.name === currency).label
            })).sort(function(a,b) {
                return ((a.label < b.label) ? -1 : ((a.label > b.label) ? 1 : 0));
            });
    }
);

export const getCurrencyToOptions = createSelector(
    getCoinsConfig,
    getEnabledPairs,
    (coinsConfig, enabledPairs) => {
        /*
        getCurrentPair,
        const { currencyFrom } = currentPair;
        const availablePairs = enabledPairs
            .filter(pair => pair.currencyFrom === currencyFrom || pair.currencyTo === currencyFrom)
            .map(pair => pair.currencyTo === currencyFrom ? pair.currencyFrom : pair.currencyTo);
            .concat([currencyFrom]);
        */

        const availablePairs = enabledPairs.map(pair => pair.currencyTo);

        return Array.from(new Set(availablePairs))
            .map(currency => ({
                value: currency,
                label: coinsConfig.find(coinConfig => coinConfig.name === currency).label
            })).sort(function(a,b) {
                return ((a.label < b.label) ? -1 : ((a.label > b.label) ? 1 : 0));
            });
    }
);

export const getCurrencyFromValue = createSelector(
    getCurrencyFromOptions,
    state => state.currency.currentPair.currencyFrom,
    (options, currencyFrom) => {
        const isCurrencyInOptions = options.find(option => option.value === currencyFrom);

        return isCurrencyInOptions || !options[0] ? currencyFrom : options[0].value;
    }
);

export const getCurrencyToValue = createSelector(
    getCurrencyToOptions,
    state => state.currency.currentPair.currencyTo,
    (options, currencyTo) => {

        const isCurrencyInOptions = options.find(option => option.value === currencyTo);

        return isCurrencyInOptions || !options[0] ? currencyTo : options[0].value;
    }
);

export const getCurrencyFromConfig = createSelector(
    getCoinsConfig,
    getCurrentPair,
    (coinsConfigs, currentPair) => {
        const { currencyFrom } = currentPair;
        if (coinsConfigs && coinsConfigs.length > 0) {
            const coinConfig = coinsConfigs.find(coinConfig => coinConfig.name === currencyFrom);
            return {
                ...coinConfig,
                label: (coinConfig) ? coinConfig.label : ''
            } || {};
        }
        return {
            label: '',
        };
    }
);

export const getCurrencyToConfig = createSelector(
    getCoinsConfig,
    getCurrentPair,
    (coinsConfigs, currentPair) => {
        const { currencyTo } = currentPair;
        if (coinsConfigs && coinsConfigs.length > 0) {
            const coinConfig = coinsConfigs.find(coinConfig => coinConfig.name === currencyTo);
            return {
                ...coinConfig,
                label: (coinConfig) ? coinConfig.label : ''
            } || {};
        }
        return {
            label: '',
        };
    }
);

export const getCurrencyRateFromCurrentPair = createSelector(
    getPartialDepth,
    (partialDepth) => {
        if (partialDepth != null) {
            const key = Object.keys(partialDepth)[0];
            return sigDigits((key * 0.994), 5);
        } else {
            return 0;
        }
    }
);

export const getCurrencyRateUpdatedTime = createSelector(
    getCurrencyToConfig,
    getCurrencyFromConfig,
    (currencyTo, currencyFrom) => {
        return currencyFrom.lastPriceUpdateDate < currencyTo.lastPriceUpdateDate ?
            currencyFrom.lastPriceUpdateDate : currencyTo.lastPriceUpdateDate;
    }
);
