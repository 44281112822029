import styled from 'styled-components';
import {mobile} from '../styles';

export const Table = styled.div`
  display: table;
  width: 100%;

  border-collapse:collapse;
  border-spacing: 0;
  
  // width: 95%;
  // margin-left: auto;
  // margin-right: auto;

  margin-bottom: 20px;
  border-bottom: 1px solid #eee;

  //${mobile`
  //  width: 100%;
  //  display: flex;
  //  flex-flow: column;
  //  justify-content: center;
  //  flex-wrap: wrap;
  //  border-bottom: 0px;
  //`};
`;

export const Header = styled.div`
  display: table-row;
  
  >div {
   // padding: 10px 10px 15px 10px;
  }

  //${mobile`
  //  display: flex;
  //  flex-flow: row;
  //  flex-wrap: wrap;
  //  justify-content: space-between;
  //`};
`;

export const TableSortHeader = styled.div`
    display: table-row;

    margin-bottom: 20px;
    margin-top: 20px;    

  ${mobile`
  `};
`;

export const FilterHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;    

  ${mobile`
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
  `};
`;

export const HeaderRow = styled(Header)`
  // border-top: 1px solid #eee;
  // border-bottom: 1px solid #eee;
  // padding-top: 5px;
  // padding-bottom: 5px;

  color: #ACB5BC;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0.71px;
  line-height: 20px;

  >div {
    text-transform: capitalize;
    // padding: 5px 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
`;

export const HeaderRowNoMobile = styled(HeaderRow)`
  ${mobile`
    display: none;
    padding: 0;
  `};
`;

export const TableRow = styled.div`
  border-bottom: 1px solid #eee;
  display: table-row;
  cursor: pointer;

  &:nth-child(2n) {    
    background-color: rgba(2, 23, 71, 0.05);
  }

  &:hover {      
      background-color: rgba(2, 23, 71, 0.10);
  }
  
  &:nth-child(2n):hover {
      // background-color: rgba(239, 134, 50, 0.10);
  }

  &.inactivePair {      
      background-color: rgba(239, 134, 50, 0.05);
  }
  
  &.inactivePair:hover {      
      background-color: rgba(239, 134, 50, 0.10);
  }

  //${mobile`
  //  display: flex;
  //  flex-flow: row;
    // flex-wrap: wrap;
  //  justify-content: space-between;
  //`};
`;

export const TableRowMono = styled(TableRow)`
  font-family: 'Driod Sans Mono', 'Liberation Mono', monospace;
`;

export const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  text-align: center;
  // width: 25%;
  vertical-align: middle;

  padding: 10px 5px;
  
  ${mobile`
    padding: 8px 3px;
  //  display: flex;
  //  flex-flow: column;
  //  flex-wrap: wrap;
  //  justify-content: center;
  //  align-items: center;
  `};
`;

export const CellNoMobile = styled(Cell)`
  ${mobile`
    display: none;
    padding: 0;
  `};
`;

export const CellNoDesktop = styled(Cell)`
  display: none;
  ${mobile`
    display: table-cell;
  `};
`;

export const CellRight = styled(Cell)`
  text-align: right;
`;

export const CellRightNoMobile = styled(CellRight)`
  ${mobile`
    display: none;
    padding: 0;
  `};
`;

export const CellLeft = styled(Cell)`
  text-align: left;
`;

export const HeaderCellLeft = styled(CellLeft)`
  padding: 5px 5px;
`;

export const HeaderCellLeftNoMobile = styled(HeaderCellLeft)`
  ${mobile`
    display: none;
    padding: 0;
  `};
`;

export const CellLeftNoMobile = styled(CellLeft)`
  ${mobile`
    display: none;
    padding: 0;
  `};
`;

export const CellLeftNoDesktop = styled(CellLeft)`
  display: none;
  ${mobile`
    display: table-cell;
  `};
`;

export const LastCell = styled(Cell)`
  padding-right: 10px;
  text-align: right;
  ${mobile`
    padding-left: 0;
  `};
`;

export const LastCellNoMobile = styled(LastCell)`
  ${mobile`
    display: none;
    padding: 0;
  `};
`;

export const LastCellNoDesktop = styled(LastCell)`
  display: none;
  ${mobile`
    display: table-cell;
  `};
`;

/*
export const CapitalCell = styled(Cell)`
    text-transform: capitalize;
`;
*/

export const SortButton = styled.div`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  white-space: nowrap;
  padding: 4px 15px;  
  background: #F2F2F2;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
`;

export const SortButtonCell = styled.div`  
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  white-space: nowrap;
  padding: 4px 15px;  
  background: #F2F2F2;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  
  ${mobile`
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    margin-left: 3px;
  `};
`;
