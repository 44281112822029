import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import DeviceConfirmScreen from './DeviceConfirmScreen';

function mapStateToProps() {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceConfirmScreen));
