import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobile } from '../../styles';
import { Component as StandardPage } from '../../components/StandardPage';

const StandardPageColumn = styled.div`
  flex-direction: column;
`;

const Title = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding-left: 2em;
  padding-right: 2em;

  display: flex;
  flex-wrap: wrap;

  ${mobile`
  `}
`;

const Content = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding-left: 3em;
  padding-right: 2em;

  ${mobile`
  `}
`;

class HowItWorks extends React.Component {

    componentDidMount() {
        document.title = 'How QuickTrades Work (BlocPalX)';
    }

    render() {
        return (
            <React.Fragment>
                <h1>STEP 1: Choose A Trade Pair</h1>
                <ul>
                    <li>Select your <b>TRADE PAIR</b></li>
                    <li>Enter your <b>REFUND</b> and destination <b>PROCEEDS</b> addresses</li>
                    <li>Enter your <b>EMAIL</b> address</li>
                    <li>Enter your real <b>NAME</b> (see below for why this is important!)</li>
                </ul>
                <h1>STEP 2: Confirm Via Email</h1>
                <ul>
                    <li>Find the email from BlocPalX</li>
                    <li>Click the <b>CONFIRM TRADE</b> button in the email</li>
                </ul>
                <h1>STEP 3: Deposit</h1>
                <ul>
                    <li>Open your favorite <b>WALLET</b> software</li>
                    <li>Execute a deposit to the <b>DEPOSIT ADDRESS</b> provided</li>
                </ul>
                <h1>STEP 4: Instant Trade & Automatic Withdrawal</h1>
                <ul>
                    <li>After 1 blockchain confirmation your deposit will <b>TRADE</b></li>
                    <li>After full blockchain confirmation your trade funds will be sent to your <b>PROCEEDS</b> address</li>
                    <li>BlocPalX will send you an email summarizing your trade for your <b>RECORDS</b></li>
                </ul>

                <br/>
                <h1>COMMON QUESTIONS</h1>

                <h2>Why are your rates frequently so much better than other instant trade services?</h2>
                <p>
                    <b>BlocPalX does not use other exchanges for the actual trade execution</b> We do not have to pay deposit, trade, or withdrawal fees to other exchanges.
                    Instead we allow market makers to manage orders on our own order books using our Binance compatible full trade API.  <b>Our market makers compete for your business.</b>
                </p>
                <h2>Are you going to allow me to deposit, then keep my funds because I am outside your jurisdiction?</h2>
                <p>
                    <b>No!</b> Unlike our competitors we do all of our jurisdiction checks <b>BEFORE</b> we allow you to deposit!
                </p>
                <p>
                    Wherever you trade, please read the fine print.
                    We have seen Terms of Service that outright say they will <b>keep your funds</b>.
                </p>
                <h2>How long does a trade take?</h2>
                <p>
                    It varies slightly from blockchain to blockchain but in order to get you a rate as close as possible to your expectations we initiate the trade after we see a single
                    blockchain confirmation. Final withdrawal of your proceeds is processed after your deposit has
                    safely and completely confirmed on the blockchain. The number of required confirmations for withdrawal varies from blockchain to blockchain but we follow industry
                    standards in determining how many to require.
                </p>
                <p>
                    We update you in real time on the trade status page throughout the process.
                </p>
                <h2>Why do you need my name and email address?</h2>
                <p>
                    We believe that with a simple Name and Email address we will be able to significantly improve your experience with us.
                    Your privacy is of the utmost importance to us and we will not share your information with anyone we are not lawfully required to.
                </p>
                <ul>
                    <li>If anything goes wrong we need a way to proactively inform you.</li>
                    <li>The emails we send you can be used as records for your taxes at year end.</li>
                    <li>In the future you will be able to request trade account records. This can be important when it comes time to calculate taxes in certain jurisdictions.</li>
                    <li>In the future you will be able to request removal of the daily trade limit by registering an account with us.</li>
                    <li>Confirming each trade helps us prevent attacks on our platform</li>
                    <li><b>If for any reason we ever need to validate ownership of funds via Photo ID, we are required to match the name you provide during trading to the name on your Photo ID.</b> (This is why it is important to use your real name!)</li>
                </ul>
                <h2>What happens if I accidentally deposit to the same deposit address twice (or more)?</h2>
                <p>
                    The system will simply start a new trade using the same currencies and proceeds address as the first trade. As soon as we detect the second (or subsequent)
                    deposit, an email will be sent to you with a link where you can watch the trade.  Then when the trade is done, you will also receive a summary email.
                </p>
                <p>
                    This means that once you set up a trade pair, you can just deposit again and again to the same address instead of starting from scratch every time!
                </p>
                <h2>Do you have a referral program?</h2>
                <p>
                    Of course! No need to sign up. Your referral ID and link appears at the bottom of your finalized trade summary page. We will track your referrals and credit
                    you every month with a percentage of the trades we process.
                </p>
                <br/>
            </React.Fragment>
        );
    }
}

export default function HowItWorksScreen() {

    return (
        <StandardPage>
            <StandardPageColumn>
                <Title>
                    <FormattedMessage
                        id="navigation.howItWorksTitle"
                        defaultMessage="How An Instant Withdrawal QuickTrade Works"
                    />
                </Title>
                <Content>
                    <HowItWorks/>
                </Content>
            </StandardPageColumn>
        </StandardPage>
    );

}
