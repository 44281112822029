import { handleActions } from 'redux-actions';

const defaultState = [];

export default handleActions(
    {
        ['EXCHANGE_WALLET_SUCCESS']: (state, action) => {
            return {
                ...state,
                walletStatus: action.assets
            };
        },
        ['REQUEST_COUNTRIES_LIST_SUCCESS']: (state, action) => {
            return {
                ...state,
                countries: action.payload
            };
        },
        ['REQUEST_REGIONS_LIST_SUCCESS']: (state, action) => {
            return {
                ...state,
                regions: action.payload
            };
        },
    },
    defaultState
);
