import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import PortfolioCurrenciesList from './PortfolioCurrenciesList';


function mapStateToProps(state) {
    return {
        coinsConfig: state.currency.coinsConfig,
        fiatCurrency: state.portfolio.fiatCurrency,
        usdToFiatRate: state.portfolio.usdToFiatRate,
        wallets: state.portfolio.wallets,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioCurrenciesList));
