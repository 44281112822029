import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobile } from '../../styles';


const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const InputElement = styled.input`
  	height: 44px;
  	border: 1px solid ${props => props.error ? '#E84E2C' : '#BEBEBE'} ;
  	border-radius: 5px;
  	background-color: #FFFFFF;

  	color: #4A4A4A;
  	font-size: 14px;
  	font-weight: 600;
  	letter-spacing: 0.2px;

    padding: 0 1em;
    width: 100%;
    box-sizing: border-box;

    :disabled {
      color: #4A4A4A;
      background-color: #dddddd;
      cursor: not-allowed;
      ${mobile`
        -webkit-text-fill-color: #1A1A1A;
      `};
    }

    ::placeholder {
      color: #9B9B9B;
      opacity: 0.9;
      font-weight: 400;
    }
`;

const ErrorMessageContainer = styled.div`
  margin-top: 4px;  
  left: 0;
  white-space: nowrap;
  border-radius: 4px;
  display: block;
  background: rgba(232, 78, 44, .1);
  width: min-content;
`;

const ErrorMessage = styled.div`
  padding: 4px 8px;
  color: #E84E2C;
  font-size: 14px;
  opacity: 1.0;
`;

export default class Input extends React.Component {

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        error: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
    };

    static defaultProps = {
        type: 'text'
    };

    render() {

        const { error } = this.props;

        return (
            <InputWrapper>
                <InputElement {...this.props} />
                {error && (
                    <React.Fragment>
                        <br />
                        <ErrorMessageContainer>
                            <ErrorMessage>
                                {/* <Warning src={'/' + warningIcon}/> */}
                                {error}
                            </ErrorMessage>
                        </ErrorMessageContainer>
                    </React.Fragment>
                )}
            </InputWrapper>
        );
    }
}
