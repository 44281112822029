import React from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import PropTypes from 'prop-types';

const HEIGHT = 300;
const DIVID = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);

let chart;
let candlestickSeries;
let histogramSeries;

const PriceVolumeChart = ({legend, reset, interval, initVolumeBars, lastVolumeBar, initCandles, lastCandle, decimals}) => {
    const chartRef = React.useRef();
    const legendRef = React.useRef();

    // console.log('new candle' , lastCandle);
    // console.log(DIVID);
    // console.log(chartRef);
    // console.log(chartRef.current);
    // console.log(initCandles);
    // console.log(initVolumeBars);
    // console.log(lastCandle);
    // console.log(decimals);
    // console.log(lastVolumeBar);

    React.useEffect(() => {

        chart = createChart(chartRef.current, {
            width: chartRef.current.offsetWidth,
            height: HEIGHT,
            alignLabels: true,
            timeScale: {
                rightOffset: 0,
                barSpacing: 15,
                fixLeftEdge: false,
                fixRightEdge: true,
                lockVisibleTimeRangeOnResize: true,
                rightBarStaysOnScroll: true,
                borderVisible: false,
                borderColor: '#fff000',
                visible: true,
                timeVisible: true,
                secondsVisible: false,
            },
            handleScroll: {
                vertTouchDrag: false,
            },
            handleScale: {
                mouseWheel: false,
                axisPressedMouseMove: {
                    price: false,
                },
            },
            rightPriceScale: {
                entireTextOnly: true,
                scaleMargins: {
                    top: 0.3,
                    bottom: 0.25,
                },
                borderVisible: false,
            },
            watermark: {
                color: 'rgba(0, 0, 0, 0.7)',
                visible: true,
                text: 'BlocPalX',
                fontSize: 18,
                horzAlign: 'left',
                vertAlign: 'bottom',
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
        });

        candlestickSeries = chart.addCandlestickSeries({
            priceScaleId: 'right',
            upColor: '#33D778',
            downColor: '#F22626',
            borderVisible: false,
            wickVisible: true,
            borderColor: '#000000',
            wickColor: '#000000',
            borderUpColor: '#00AA00',
            borderDownColor: '#AA0000',
            wickUpColor: '#00AA00',
            wickDownColor: '#AA0000',
            priceFormat: {
                type: 'custom',
                minMove: '0.00000001',
                formatter: (price) => {
                    return parseFloat(price).toFixed(decimals);
                }
            },
        });

        histogramSeries = chart.addHistogramSeries({
            color: '#26a69a',
            /*
            priceFormat: {
                type: 'volume',
            },
            */
            priceScaleId: '',
            scaleMargins: {
                top: 0.8,
                bottom: 0,
            },
            priceFormat: {
                type: 'custom',
                minMove: '0.00000001',
                formatter: (value) => {
                    return parseFloat(value).toFixed(6);
                }
            },
        });

        return () => {
            chart.removeSeries(histogramSeries);
            chart.removeSeries(candlestickSeries);
            chart.remove();
            candlestickSeries = undefined;
            histogramSeries = undefined;
            chart = undefined;
        };
    }, []);

    React.useEffect(() => {
        candlestickSeries.setData(initCandles);
        histogramSeries.setData(initVolumeBars);
    }, [interval]);

    React.useEffect(() => {
        chart.timeScale().resetTimeScale();
    }, [reset]);

    React.useEffect(() => {
        if (!(lastCandle && Object.keys(lastCandle).length === 0 && lastCandle.constructor === Object)) {
            candlestickSeries.update(lastCandle);
        }
    }, [lastCandle]);

    React.useEffect(() => {
        if (!(lastVolumeBar && Object.keys(lastVolumeBar).length === 0 && lastVolumeBar.constructor === Object)) {
            histogramSeries.update(lastVolumeBar);
        }
    }, [lastVolumeBar]);

    React.useEffect(() => {
        const handler = () => {
            chart.resize(chartRef.current.offsetWidth, HEIGHT);
        };
        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    return (
        <div ref={chartRef} id={DIVID} style={{'position': 'relative', 'width': '100%'}}>
            <div
                ref={legendRef}
                style={{
                    position: 'absolute',
                    zIndex: 2,
                    color: '#333',
                    padding: 10,
                }}
            >
                {legend}
            </div>
        </div>
    );
};

PriceVolumeChart.propTypes = {
    legend: PropTypes.string,
    reset: PropTypes.number,
    interval: PropTypes.string,
    initCandles: PropTypes.array,
    lastCandle: PropTypes.object,
    initVolumeBars: PropTypes.array,
    lastVolumeBar: PropTypes.object,
    decimals: PropTypes.number,
};

export default PriceVolumeChart;
