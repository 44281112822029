import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProjectsScreen from './ProjectsScreen';

function mapStateToProps(state) {
    return {
        coinsConfig: state.currency.coinsConfig
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsScreen);
