
/*
 * INTERNAL REQUESTS
 */

/*
 * REST REQUESTS
 */

export const requestCountriesList = () => {
    return async dispatch => {
        return dispatch({
            api: {
                type: 'REQUEST_COUNTRIES_LIST',
                types: [
                    'REQUEST_COUNTRIES_LIST_START',
                    'REQUEST_COUNTRIES_LIST_SUCCESS',
                    'REQUEST_COUNTRIES_LIST_FAILURE'
                ]
            }
        });
    };
};

export const requestRegionsList = (country) => {
    return async dispatch => {
        return dispatch({
            api: {
                type: 'REQUEST_REGIONS_LIST',
                params: {
                    country: country,
                },
                types: [
                    'REQUEST_REGIONS_LIST_START',
                    'REQUEST_REGIONS_LIST_SUCCESS',
                    'REQUEST_REGIONS_LIST_FAILURE'
                ]
            }
        });
    };
};

/*
 * WEBSOCKET REQUESTS
 */

export const getWalletStatusInfo = () => ({
    ws: {
        type: 'GET_EXCHANGE_WALLET',
        data: {}
    }
});
