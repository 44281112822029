import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';

import bchLogo from '../../images/assetlogos/BCH.svg';
import bpxLogo from '../../images/assetlogos/BPX.svg';
import btcLogo from '../../images/assetlogos/BTC.svg';
import cadLogo from '../../images/assetlogos/CAD.svg';
import dashLogo from '../../images/assetlogos/DASH.svg';
import ethLogo from '../../images/assetlogos/ETH.svg';
import ltcLogo from '../../images/assetlogos/LTC.svg';
import miotaLogo from '../../images/assetlogos/MIOTA.svg';
import ppcLogo from '../../images/assetlogos/PPC.svg';
import sto55Logo from '../../images/assetlogos/STO55.svg';
import usdLogo from '../../images/assetlogos/USD.svg';
import usdcLogo from '../../images/assetlogos/USDC.svg';
import usdtLogo from '../../images/assetlogos/USDT.svg';
import xlmLogo from '../../images/assetlogos/XLM.svg';
import xmrLogo from '../../images/assetlogos/XMR.svg';
import xrpLogo from '../../images/assetlogos/XRP.svg';

const ICONS = {
    BCH: bchLogo,
    BPX: bpxLogo,
    BTC: btcLogo,
    CAD: cadLogo,
    DASH: dashLogo,
    ETH: ethLogo,
    EURT20: usdtLogo,
    LTC: ltcLogo,
    MIOTA: miotaLogo,
    PPC: ppcLogo,
    STO55: sto55Logo,
    USD: usdLogo,
    USDC: usdcLogo,
    USDT: usdtLogo,
    USDT20: usdtLogo,
    XLM: xlmLogo,
    XMR: xmrLogo,
    XRP: xrpLogo,
};

const CurrencyIcon = styled.img`
  width: 20px;
  height: 100%;
  top: 0;
`;

export default class CurrencyImage extends React.Component {

    static propTypes = {
        currency: PropTypes.string,
    };

    static defaultProps = {
        currency: 'text'
    };

    render() {

        const { currency, ...props } = this.props;
        const currencyIconPath = ICONS[currency];

        if (currencyIconPath) {
            return (
                <CurrencyIcon src={'/' + currencyIconPath} {...props} />
            );
        } else {
            return (
                <span />
            );
        }
    }
}
