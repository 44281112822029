import { connect } from 'react-redux';
import AccountNotices from './AccountNotices';

function mapStateToProps(state) {
    return {
        withdrawalsDisabled: state.user.withdrawalsDisabled,
        userLocked: state.user.locked,
        kycTier: state.user.kycTier,
        securedMethods: state.user.securedMethods,
        isAuthenticated: (state.auth && state.auth.isAuthenticated && state.user) ? true : false,
    };
}

export default connect(mapStateToProps)(AccountNotices);
