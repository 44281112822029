import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WalletStatusScreen from './WalletStatusScreen';
import { getWalletStatusInfo } from '../../actions/opendata';

function mapStateToProps(state) {
    return {
        walletStatus: state.opendata.walletStatus
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getWalletStatusInfo,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WalletStatusScreen);
