const getValue = require('get-value');
const merge = require('deepmerge');
const defaultConfig = require('./default');

const config = process.env.NODE_ENV === 'production' ?
    require('./production') :
    process.env.NODE_ENV === 'sandbox' ?
        require('./sandbox') :
        process.env.NODE_ENV === 'development' ?
            require('./development') :
            process.env.NODE_ENV === 'localdev' ?
                require('./localdev'):
                require('./local');

const mergedConfig = merge(defaultConfig, config);
// eslint-disable-next-line no-undef
// const overWrittenConfig = merge(mergedConfig, SETTINGS || {});

module.exports = {
    get(key) {
        return getValue(mergedConfig, key);
    }
};
