import { handleActions } from 'redux-actions';
import { NotificationManager } from 'react-notifications';

const defaultState = {};

export default handleActions(
    {
        /*
        ['ADD_NOTIFICATION']: (state, action) => {
            if (action.payload.text === 'abc123') {
                return {
                    type: 'ERROR',
                    message: action.payload.text,
                };
            } else {
                // suppress message from server...
                return state;
            }
        },
        */
        ['UNSUPPORTED_COUNTRY']: (state, action) => {
            return {
                type: 'UNSUPPORTED_COUNTRY',
                message: action.payload.message
            };
        },
        ['CREDENTIALS_ERROR']: (state, action) => {
            NotificationManager.error(action.payload.message);
            return {
                type: 'CREDENTIALS_ERROR',
                message: action.payload.message
            };
        },
        ['INVALID_2FA_CODE']: (state, action) => {
            NotificationManager.error(action.payload.message);
            return {
                type: 'INVALID_2FA_CODE',
                message: action.payload.message
            };
        },
        ['REQUEST_LOGIN']: () => {
            return defaultState;
        },
        ['CLEAR_ERRORS']: () => [],
    },
    defaultState
);
