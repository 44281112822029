
/*
 * INTERNAL REQUESTS
 */

export const clearErrors = () => ({
    type: 'CLEAR_ERRORS',
});

/*
 * REST REQUESTS
 */

/*
 * WEBSOCKET REQUESTS
 */
