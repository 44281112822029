import { handleActions } from 'redux-actions';


const parsePair = (stringPair) => {
    const splitedPair = stringPair.split(/[-_]/g);
    return {
        currencyFrom: splitedPair[0],
        currencyTo: splitedPair[1]
    };
};

/**
 * We're getting rate for base pair and need to invert it (and pair name) for reverted pair
 * First currency in pair is defined by alphabetical order
 * Example
 * BTC - ETH is base pair       rate will be rate itself
 * ETH - BTC is reverted pair   rate will be 1/rate
 */
const formatTrade = (trade, currentPair) => {
    const { currencyFrom, currencyTo } = currentPair;

    const rate = currencyFrom < currencyTo ? trade.rate : 1/trade.rate;

    const formattedRate = rate.toString().slice(0, 10);

    return {
        ...trade,
        pair: `${currencyFrom}-${currencyTo}`,
        rate: formattedRate
    };
};


const defaultState = {
    history: [],
    currentPair: {
        from: '',
        to: ''
    },
    transactionState: {
        'status': {
            type: 'LOADING_TRANSACTION'
        },
        'depositAddr': {
            'asset': null,
            'address': null,
            'tag': null,
            'isUsed': false,
            'used': false
        },
        'refundAddr': {
            'asset': null,
            'address': null,
            'tag': null,
            'isUsed': false,
            'used': false
        },
        'targetAddr': {
            'asset': null,
            'address': null,
            'tag': null,
            'isUsed': false,
            'used': false
        },
        'depositAmount': 2,
        'refundAmount': 2,
        'targetAmount': 0,
        'rate': 0,
        'confCount': 2,
        'targetConfCount': 8,
        'startTs': 0
    }
};

export default handleActions(
    {
        ['EXCHANGE_CONFIG']: (state, action) => {
            const config = action.payload;

            if (config.defaultPair != null) {
                return {
                    ...state,
                    currentPair: parsePair(config.defaultPair)
                };
            } else {
                return {
                    ...state
                };
            }
        },
        ['CHANGE_PAIR_SUCCESS']: (state, action) => {
            const pair = parsePair(action.newPair);

            return {
                ...state,
                currentPair: pair
            };
        },
        ['ADD_RECENT_TRANSACTION']: (state, action) => {
            const newTransaction = {...action.payload, id: action.uid};
            return {
                ...state,
                history: [formatTrade(newTransaction, state.currentPair), ...state.history].slice(0, 40)
            };
        },
        ['QUICKTRADE_STATE']: (state, action) => {
            if(action.payload && action.payload.status) {
                return {
                    ...state,
                    transactionId: action.payload.qtId,
                    transactionState: action.payload
                };
            } else {
                return state;
            }
        },
        ['QUICKTRADE_NOT_FOUND']: (state) => {
            return {
                ...state,
                transactionState: {
                    ...state.transactionState,
                    status: {
                        type: 'NOT_FOUND',
                    }
                }
            };
        },
    },
    defaultState
);
