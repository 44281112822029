import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from './Header';
import { logout } from '../../actions/auth';
import {getAuthenticated} from '../../selectors';
import { withRouter } from 'react-router-dom';

function mapStateToProps(state) {
    return {
        userNickName: (state.user) ? state.user.nickName : 'Profile',
        isAuthenticated: getAuthenticated(state),
        maintenanceMode: state.currency.maintenanceMode,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Header));
