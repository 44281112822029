import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileSidebar from './ProfileSidebar';

import { getUploadedFileList, getVrfyInfo, getApiKeys, getProofOfReserves } from '../../../../actions/user';
import { get2faAuths } from '../../../../actions/auth';

function mapStateToProps(state) {
    return {
        location: state.router.location.pathname,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            get2faAuths,
            getApiKeys,
            getUploadedFileList,
            getVrfyInfo,
            getProofOfReserves,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileSidebar);
