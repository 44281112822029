import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Component as CurrencyImage } from '../../components/CurrencyImage';
import { Component as StandardPage } from '../../components/StandardPage';
import { Component as NotFound } from '../NotFoundScreen/components/NotFound';

import { Container as AssetInformation } from '../../components/AssetInformation';
import { Container as AssetNews } from '../../components/AssetNews';
import { Container as PortfolioAssetPage } from './components/PortfolioAssetPage';
import { Container as PortfolioAddressBook } from './components/PortfolioAddressBook';
import { Container as PortfolioCurrenciesList } from './components/PortfolioCurrenciesList';
import { Container as PortfolioDeposits } from './components/PortfolioDeposits';
import { Container as PortfolioPermWithdrawal } from './components/PortfolioPermWithdrawal';
import { Container as PortfolioWithdrawals } from './components/PortfolioWithdrawals';
import { Container as WalletActivity } from './components/WalletActivity';
import { Container as WalletInfo } from './components/WalletInfo';
import { Container as WalletProofOfReserves } from './components/WalletProofOfReserves';

import { mobile } from '../../styles';
import {BackLink} from '../../commonStyles/GeneralStyles';
import getSymbolFromCurrency from 'currency-symbol-map';


const SidebarLoader = props => (
    <ContentLoader
        height={800}
        width={280}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="16" y="0" rx="0" ry="0" width="75" height="21" />
        <rect x="16" y="24" rx="0" ry="0" width="230" height="45" />
        {/*<rect x="16" y="75" rx="0" ry="0" width="84" height="19" />*/}
        {/*<rect x="170" y="75" rx="0" ry="0" width="105" height="19" />*/}
        <rect x="0" y="105" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="191" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="277" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="363" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="449" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="535" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="621" rx="0" ry="0" width="280" height="83" />
    </ContentLoader>
);

const MainLoader = props => (
    <ContentLoader
        height={800}
        width={800}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="50" y="80" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="160" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="240" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="320" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="400" rx="0" ry="0" width="700" height="60" />
        <rect x="50" y="480" rx="0" ry="0" width="700" height="60" />
    </ContentLoader>
);

const Sidebar = styled.div`
  border-right: 1px solid #eee;
  flex: 0 0 290px;
  padding: 15px;
  box-sizing: border-box;

  ${mobile`
    display: none;
  `};
`;

const SideHeader = styled.div`
  padding: 0 16px;

  >span {
	color: #9B9B9B;
	font-size: 14px;
	line-height: 21px;
  }

  >p {
    margin: 0;
	color: #4A4A4A;
	font-size: 36px;
	line-height: 36px;
  }
`;

const SideHeaderSmall = styled.span`
    font-size: 24px;
`;

const Content = styled.div`
  flex: 1 1;
  // flex: 1 1 738px;
`;

const Header = styled.div`
  padding: 15px 20px 0;
`;

/*
const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;
*/

const PageTitle = styled.div`
	color: #4A4A4A;
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
    margin-top: 5px;
	margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

	a {
	  color: #ef8632;
	  text-decoration:none;
	}

    span {
      text-align: center;
	}

    ${mobile`
      // justify-content: center;
      // margin-left: auto;
      // margin-right: auto;
      // margin-bottom: 5px;
    `};
`;

const CurrencyIcon = styled(CurrencyImage)`
  margin: 10px 5px 10px 0px;
  width: 50px;
  height: 50px;
  ${mobile`
    width: 40px;
    height: 40px;
  `};
`;

const CurrencyIconRight = styled(CurrencyImage)`
  // hide it for now - Kurtis doesn't like it
  display: none;
  margin: 20px 10px 10px 10px;
  width: 200px;
  height: 200px;
  float: right;
  top: -20px;
  right: -10px;
  position: relative;
  opacity: 0.8;

  ${mobile`
    display: none;
  `};
`;


export default class PortfolioScreen extends React.Component {

    static propTypes = {
        thisUrl: PropTypes.string,
        coinName: PropTypes.string,
        coinLabel: PropTypes.string,
        isFiatCurrency: PropTypes.bool,
        isWallets: PropTypes.bool,
        approxTotal: PropTypes.string,
        fiatCurrency: PropTypes.string,
        isPortfolioLoaded: PropTypes.bool,
        isAuthenticated: PropTypes.bool,

        getPortfolioInfo: PropTypes.func,

        history: PropTypes.object,
        intl: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isPortfolioRequested: false,
        };
    }

    componentDidMount() {
        const { coinName, isPortfolioLoaded, isAuthenticated } = this.props;
        const { isPortfolioRequested } = this.state;

        if (!coinName || coinName === '') {
            document.title = 'Wallet (BlocPalX)';
        } else {
            document.title = coinName + ' Wallet (BlocPalX)';
        }

        if (isAuthenticated && !isPortfolioRequested && !isPortfolioLoaded) {
            this.props.getPortfolioInfo();
            this.setState({
                isPortfolioRequested: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { isPortfolioLoaded, isAuthenticated } = this.props;
        const { isPortfolioRequested } = this.state;

        if (isAuthenticated && !isPortfolioRequested && !isPortfolioLoaded) {
            this.props.getPortfolioInfo();
            this.setState({
                isPortfolioRequested: true,
            });
        }

        if (this.props.coinName !== prevProps.coinName) {
            const { coinName } = this.props;
            if (!coinName || coinName === '') {
                document.title = 'Wallet (BlocPalX)';
            } else {
                document.title = coinName + ' Wallet (BlocPalX)';
            }
        }
    }

    render() {
        const {
            thisUrl,
            coinLabel,
            coinName,
            isFiatCurrency,
            approxTotal,
            fiatCurrency,
            isWallets,
            history,
            intl
        } = this.props;

        return (
            <StandardPage row>
                {(coinName && coinName !== '') && (
                    <Sidebar>
                        {(isWallets) ? (
                            <React.Fragment>
                                <SideHeader>
                                    <p>
                                        {getSymbolFromCurrency(fiatCurrency)}{intl.formatNumber(approxTotal, {minimumFractionDigits: 2, maximumFractionDigits: 2})} <SideHeaderSmall>{fiatCurrency}</SideHeaderSmall>
                                    </p>
                                    <FormattedMessage
                                        id="portfolio.approxAccountValue"
                                        defaultMessage="Approximate account value"
                                    />
                                </SideHeader>
                                <PortfolioCurrenciesList
                                    activeCoin={coinName}
                                />
                            </React.Fragment>
                        ) : (
                            <SidebarLoader/>
                        )}
                    </Sidebar>
                )}

                {(isWallets) ? (
                    <Content>
                        <Switch>
                            <Route path={`${thisUrl}/`} exact component={() => (
                                <React.Fragment>
                                    {(coinName && coinName !== '') ? (
                                        <React.Fragment>
                                            <Header>
                                                <CurrencyIconRight currency={coinName} />
                                                <BackLink onClick={() => { history.push('/account/portfolio'); }}>
                                                    Wallet
                                                </BackLink>
                                                <PageTitle>
                                                    <CurrencyIcon currency={coinName} />
                                                    <FormattedMessage
                                                        id="wallet.title"
                                                        defaultMessage="{label}{ticker}"
                                                        values={{
                                                            label: coinLabel ? coinLabel+' ' : '',
                                                            ticker: coinLabel ? '('+coinName+')' : coinName
                                                        }}
                                                    />
                                                </PageTitle>
                                                <WalletInfo coin={coinName} isFiatAsset={isFiatCurrency}/>
                                                <WalletActivity freezeType='All' coin={coinName}/>
                                            </Header>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <PortfolioAssetPage/>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}/>

                            <Route path={`${thisUrl}/deposit`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={coinName} />
                                        <BackLink onClick={() => { history.push('/account/portfolio/' + coinName); }}>
                                            {coinLabel ? 'Wallet: ' + coinLabel + ' (' + coinName + ')' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={coinName} />
                                            <FormattedMessage
                                                id="wallet.deposit"
                                                defaultMessage="Deposit {label}{ticker}"
                                                values={{
                                                    label: coinLabel ? coinLabel+' ' : '',
                                                    ticker: coinLabel ? '('+coinName+')' : coinName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <PortfolioDeposits coin={coinName}/>
                                    <WalletActivity freezeType='DEPOSIT' coin={coinName}/>
                                </React.Fragment>
                            )}/>

                            <Route path={`${thisUrl}/addressBook`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={coinName} />
                                        <BackLink onClick={() => { history.push('/account/portfolio/' + coinName + '/withdraw'); }}>
                                            {coinLabel ? 'Wallet: ' + coinLabel + ' (' + coinName + '): Withdraw' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={coinName} />
                                            <FormattedMessage
                                                id="wallet.addressBook"
                                                defaultMessage="Address Book {ticker}"
                                                values={{
                                                    label: coinLabel ? coinLabel+' ' : '',
                                                    ticker: coinLabel ? '('+coinName+')' : coinName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <PortfolioAddressBook coin={coinName} />
                                </React.Fragment>
                            )}/>

                            <Route path={`${thisUrl}/permwithdraw`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={coinName} />
                                        <BackLink onClick={() => { history.push('/account/portfolio/' + coinName); }}>
                                            {coinLabel ? 'Wallet: ' + coinLabel + ' (' + coinName + ')' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={coinName} />
                                            <FormattedMessage
                                                id="wallet.perm_withdraw"
                                                defaultMessage="Permanent Withdrawal Address {ticker}"
                                                values={{
                                                    label: coinLabel ? coinLabel+' ' : '',
                                                    ticker: coinLabel ? '('+coinName+')' : coinName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <PortfolioPermWithdrawal coin={coinName} />
                                </React.Fragment>
                            )}/>

                            <Route path={`${thisUrl}/withdraw`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={coinName} />
                                        <BackLink onClick={() => { history.push('/account/portfolio/' + coinName); }}>
                                            {coinLabel ? 'Wallet: ' + coinLabel + ' (' + coinName + ')' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={coinName} />
                                            <FormattedMessage
                                                id="wallet.withdraw"
                                                defaultMessage="Withdraw {label}{ticker}"
                                                values={{
                                                    label: coinLabel ? coinLabel+' ' : '',
                                                    ticker: coinLabel ? '('+coinName+')' : coinName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <PortfolioWithdrawals
                                        coin={coinName}
                                    />
                                    <WalletActivity freezeType='WITHDRAWAL' coin={coinName}/>
                                </React.Fragment>
                            )}/>

                            <Route path={`${thisUrl}/about`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={coinName} />
                                        <BackLink onClick={() => { history.push('/account/portfolio/' + coinName); }}>
                                            {coinLabel ? 'Wallet: ' + coinLabel + ' (' + coinName + ')' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={coinName} />
                                            <FormattedMessage
                                                id="wallet.about"
                                                defaultMessage="{label}{ticker} Asset Information"
                                                values={{
                                                    label: coinLabel ? coinLabel+' ' : '',
                                                    ticker: coinLabel ? '('+coinName+')' : coinName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <AssetInformation coin={coinName}/>
                                </React.Fragment>
                            )}/>

                            <Route path={`${thisUrl}/news`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={coinName} />
                                        <BackLink onClick={() => { history.push('/account/portfolio/' + coinName); }}>
                                            {coinLabel ? 'Wallet: ' + coinLabel + ' (' + coinName + ')' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={coinName} />
                                            <FormattedMessage
                                                id="wallet.news"
                                                defaultMessage="{label}{ticker} Asset News"
                                                values={{
                                                    label: coinLabel ? coinLabel+' ' : '',
                                                    ticker: coinLabel ? '('+coinName+')' : coinName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <AssetNews coin={coinName}/>
                                </React.Fragment>
                            )}/>

                            <Route path={`${thisUrl}/reserves`} exact component={() => (
                                <React.Fragment>
                                    <Header>
                                        <CurrencyIconRight currency={coinName} />
                                        <BackLink onClick={() => { history.push('/account/portfolio/' + coinName); }}>
                                            {coinLabel ? 'Wallet: ' + coinLabel + ' (' + coinName + ')' : 'Back'}
                                        </BackLink>
                                        <PageTitle>
                                            <CurrencyIcon currency={coinName} />
                                            <FormattedMessage
                                                id="wallet.reserves"
                                                defaultMessage="{label}{ticker} Proof Of Reserves"
                                                values={{
                                                    label: coinLabel ? coinLabel+' ' : '',
                                                    ticker: coinLabel ? '('+coinName+')' : coinName
                                                }}
                                            />
                                        </PageTitle>
                                    </Header>
                                    <WalletProofOfReserves coin={coinName}/>
                                </React.Fragment>
                            )}/>

                            <Route component={NotFound} />
                        </Switch>
                    </Content>
                ) : (
                    <MainLoader/>
                )}

            </StandardPage>
        );
    }

}
