import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import MarketPage from './MarketPage';

import { startTickerFeed, stopTickerFeed } from '../../../../actions/market';
import { getTradePairTickers } from '../../../../selectors';


function mapStateToProps(state) {
    return {
        fiatCurrency: state.portfolio.fiatCurrency,
        usdToFiatRate: state.portfolio.usdToFiatRate,
        pairs: getTradePairTickers(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            startTickerFeed,
            stopTickerFeed,
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketPage));
