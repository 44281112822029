import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import IpChangedScreen from './IpChangedScreen';
import {clearAuthData} from '../../actions/auth';

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearAuthData,
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IpChangedScreen));
