import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import config from 'config';
import { distanceInWords } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

import { Component as Input } from '../../../../components/Input';
import { Component as InputGroup } from '../../../../components/InputGroup';
import { Component as RouteButton } from '../../../../components/RouteButton';
import { Component as ProgressButton } from '../../../../components/ProgressButton';
import { Component as CurrencySelect } from '../../../../components/CurrencySelector';
import { ColWrapper, RowWrapper } from '../../../../components/Layout';

import { mobile } from '../../../../styles';

import swapIcon from '../../../../images/ic-exchange.svg';
import BlocPalToolTip from '../../../../components/BlocPalToolTip/BlocPalToolTip';


const isAuthEnabled = config.get('features.authorization');

const VALIDATION_MESSAGES = {
    refundAddress: (
        <FormattedMessage
            id="oct.refund_address_required"
            defaultMessage="Refund address cannot be blank"
        />
    ),
    proceedAddress: (
        <FormattedMessage
            id="oct.proceed_address_required"
            defaultMessage="Proceeds address cannot be blank"
        />
    ),
    refundTag: (
        <FormattedMessage
            id="oct.field_required"
            defaultMessage="Address tag cannot be blank"
        />
    ),
    proceedTag: (
        <FormattedMessage
            id="oct.field_required"
            defaultMessage="Address tag cannot be blank"
        />
    ),
    name: (
        <FormattedMessage
            id="oct.name_required"
            defaultMessage="Name cannot be blank"
        />
    ),
    email: (
        <FormattedMessage
            id="oct.email_required"
            defaultMessage="Email cannot be blank"
        />
    )
};

const JustifiedColWrapper = styled(ColWrapper)`
    justify-content: space-between;
    flex: 0 0 calc(50% - 25px);

    > * {
        margin-bottom: 1em;
    }
`;

const QuickTradePaneWrapper = styled(ColWrapper)`
    flex: 1 auto;

    justify-content: space-between;
`;

const InputGroupTag = styled(InputGroup)`
  margin-top: 0.25em;
  margin-bottom: 1.0em;
`;

const UpperPane = styled.div`
    margin-left: 2em;
    margin-right: 2em;

    display: flex;
    flex-flow: column;

    ${mobile`
        margin: 0;
    `};

    > * > * {
      flex: 1 auto;
    }
`;

const DownPane = styled(ColWrapper)`
    padding: 1em 2em;

    ${mobile`
        padding: 0;
    `}
`;

const DownPaneTitle = styled.h3`
  	color: #8A847E;
  	font-size: 12px;
  	font-weight: 600;
  	letter-spacing: 0.86px;
  	line-height: 20px;
    text-transform: uppercase;

    ${mobile`
        display: none;
    `}
`;

const ShortageWarning = styled.div`
    font-size: 12px;
    color: #ff0000;
`;

const UserInfoFields = styled(ColWrapper)`
    flex: 0 0 calc(50% - 25px);

    > :last-child {
      margin-top: 0.5em;
    }
`;

const Description = styled(ColWrapper)`
  	color: #727072;
  	font-size: 14px;
    line-height: 20px;
    opacity: 0.9;
`;

const TradeDescription = styled(Description)`
    margin-top: 35px;

    ${mobile`
        margin: 15px 0;
        text-align: center;
    `}
`;

const CheckboxDescription = styled(Description)`
    margin-bottom: 15px;

    input {
        height: 15px;
        width: 15px;
    }

    span {
        margin-right: 3px;
    }
`;

const NameDescription = styled(Description)`
    margin-top: 15px;

    ${mobile`
        margin: 15px 0;
        text-align: center;
    `}
`;

const SignUpDescription = styled(Description)`
  	opacity: 0.6;
  	color: #4A4A4A;
  	font-size: 12px;
  	line-height: 15px;

 	${mobile`
  	    text-align: center;
  	    margin-top: 10px;
  	    font-size: 14px;
  	`}
`;
const RegistrationAd = styled(Description)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > span {
      margin: 10px 10px 10px 0;
    }

    ${mobile`
        flex-direction: column;
        justify-content: center;
    `}
`;

const Rate = styled.span`
  position: absolute;
    right: 33px;
    top: 71px;
    font-size: 14px;
    text-align: right;

    span {
      color: #D3D3D3;
      font-size: 12px;
      line-height: 21px;
      margin-left: 5px;
      display: block;

      div {
        vertical-align: sub;
      }

      img {
        margin-left: 7px;
      }

      ${mobile`
        font-size: 16px;
        margin: 0;
      `}
  }
`;

const AddressEntry = styled.span`
    font-size: 14px;
    text-align: left;

    span {
      color: #D3D3D3;
      font-size: 12px;
      line-height: 21px;
      display: block;

      div {
        vertical-align: sub;
      }

      img {
        margin-left: 7px;
      }

      ${mobile`
        font-size: 16px;
        margin: 0;
      `}
  }
`;

const ResponsiveRow = styled(RowWrapper)`
  ${mobile`
    flex-flow: ${props => props.reverse ? 'column-reverse' : 'column'};
    ${props => props.border ? 'border-bottom: 1px solid #ddd;' : ''}
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 10px;
    padding-top: 10px;
  `}
`;

const SwapColumn = styled(ColWrapper)`
  flex: 0 0 50px;
  // margin: 0;
  align-self: self-start;
  padding-top: 50px;
  cursor: pointer;

  img {
      margin-left: auto;
      margin-right: auto;
      width: 30px;
      height: 33px;
  }

  ${mobile`
    flex: 0;
    margin: 0;
    align-self: flex-end;
    cursor: pointer;
    padding: 10px;
    background-color: #F4F7F8;
    margin-right: -32px;
    margin-bottom: -20px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

    img {
        transform: rotate(90deg)
    }
  `}
`;

const CurrencyColumn = styled(ColWrapper)`
  flex: 0 0 calc(50% - 25px);

  p {
    margin: 0;
    font-size: 12px;
    color: #4A4A4A;

    ${mobile`
        font-size: 14px;
    `}
  }
`;

const ResponsiveButton = styled(RouteButton)`
  ${mobile`
    display: flex;
    justify-content: center;

    a {
        width: 170px;
        text-align: center;
    }
  `}
`;


export default class QuickTradePane extends React.Component {

    static propTypes = {
        changePair: PropTypes.func,
        stopOneWayPairFeed: PropTypes.func,
        submitQuickTrade: PropTypes.func,
        currencyFrom: PropTypes.string,
        currencyTo: PropTypes.string,
        validation: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
            message: PropTypes.string,
        })),
        coinConfig: PropTypes.array,
        rate: PropTypes.number,
        rateUpdated: PropTypes.number,
        currencyFromOptions: PropTypes.array,
        currencyToOptions: PropTypes.array,
        currencyToConfig: PropTypes.object,
        currencyFromConfig: PropTypes.object,
        history: PropTypes.object,
        tradeLocked: PropTypes.bool,
        maintenanceMode: PropTypes.bool,
        intl: PropTypes.object,
        notifications: PropTypes.array,
        referredBy: PropTypes.string,
    };

    static defaultProps = {
        refundTagCheckbox: false,
        proceedTagCheckbox: false,
        onSubmit: undefined,
        validation: undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            currencyFrom: props.currencyFrom,
            currencyTo: props.currencyTo,
            refundAddress: null,
            proceedAddress: null,
            refundTag: null,
            proceedTag: null,
            email: null,
            name: null,
            errors: []
        };
    }

    componentDidMount() {
        const { currencyFrom, currencyTo } = this.props;

        this.props.changePair({
            currencyFrom: currencyFrom,
            currencyTo: currencyTo,
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.validation !== this.props.validation) {
            const newState = {};
            this.props.validation && this.props.validation.forEach(v => {
                newState[v.name] = v.value;
            });

            this.setState(newState);
        }
    }

    componentWillUnmount() {
        this.props.stopOneWayPairFeed();

        if (this.errorTimer) {
            clearTimeout(this.errorTimer);
        }
    }

    /**
     * TODO We may need to use some schema lib for validation
     */
    validateTrade = () => {
        const {
            currencyFromConfig,
            currencyToConfig,
        } = this.props;

        const {
            refundTagCheckbox,
            proceedTagCheckbox,
        } = this.state;

        const requiredFields = [
            'proceedAddress',
            'refundAddress',
            'email',
            'name',
            currencyFromConfig.networks.find(networkConfig => networkConfig.name === currencyFromConfig.defaultNetwork).addressTagName !== '' && !refundTagCheckbox ? 'refundTag' : undefined,
            currencyToConfig.networks.find(networkConfig => networkConfig.name === currencyToConfig.defaultNetwork).addressTagName !== '' && !proceedTagCheckbox ? 'proceedTag' : undefined,
        ];

        const validations = [
            {
                field: 'name',
                validate: name => {
                    return name && (name.trim().split(' ').length === 2 || name.trim().split(' ').length === 3) &&
                        /^[a-zA-Z\s]+$/g.test(name.trim());
                },
                message: (
                    <FormattedMessage
                        id="error.invalid_name"
                        defaultMessage="Invalid name (A-Z only - No accents or punctuation)"
                    />
                )
            }
        ];

        const missedFields = [];
        const invalidFields = [];

        requiredFields.filter(f => !!f).map(field => {
            if(!this.state[field]) {
                missedFields.push({
                    field,
                    message: VALIDATION_MESSAGES[field]
                });
            }
        });

        validations.map(({ field, validate, message }) => {
            if(!validate(this.state[field])) {
                invalidFields.push({
                    field,
                    message
                });
            }
        });

        return invalidFields.concat(missedFields);
    };

    getError = field => {
        const error = this.state.errors.find(e => e.field === field);
        return error ? error.message : null;
    };

    onChangeHandler = ({ target }) => {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    };

    onSwapCurrency = () => {
        const { currencyFrom, currencyTo } = this.props;

        this.props.changePair({
            currencyFrom: currencyTo,
            currencyTo: currencyFrom,
        });
    };

    onCurrencyFromChangeHandler = ({value}) => {
        this.props.changePair({
            ...this.props,
            currencyFrom: value,
        });
    };

    onCurrencyToChangeHandler = ({value}) => {
        this.props.changePair({
            ...this.props,
            currencyTo: value,
        });
    };

    onSubmit = () => {
        const {
            currencyFrom,
            currencyTo,
            referredBy,
            // history
        } = this.props;

        const {
            proceedAddress,
            refundAddress,
            proceedTag,
            refundTag,
            email,
            name
        } = this.state;

        const errors = this.validateTrade();

        this.setState({buttonState: 'loading'});

        if (errors.some(e => !!e)) {
            this.setState({
                errors
            });
            this.errorTimer = setTimeout(() => {
                this.setState({buttonState: 'error'});
            }, 100);
        } else {
            this.setState({
                errors: []
            });

            this.props.submitQuickTrade({
                proceedAddress,
                refundAddress,
                proceedTag,
                refundTag,
                email,
                name,
                currencyFrom,
                currencyTo,
                referredBy,
            });

            // TODO: would be better if the state could be set to 'success' or 'error' based on the submitQuickTrade result
            this.errorTimer = setTimeout(() => {
                this.setState({buttonState: ''});
            }, 10000);
        }
    };

    deriveValidationMap = () => {
        const validationMap = {};

        this.props.validation && this.props.validation.forEach(v => {
            validationMap[v.name] = v.message;
        });

        return validationMap;
    };

    render() {
        const validationMap = this.deriveValidationMap();
        const token = sessionStorage.getItem('token');

        const {
            currencyFromConfig,
            currencyToConfig,
            currencyFromOptions,
            currencyToOptions,
            currencyFrom,
            currencyTo,
            rate,
            rateUpdated,
            tradeLocked,
            maintenanceMode,
            intl,
            notifications,
        } = this.props;

        const {
            proceedAddress,
            refundAddress,
            proceedTag,
            refundTag,
            refundTagCheckbox,
            proceedTagCheckbox,
            email,
            name,
        } = this.state;

        //console.log('***********************');
        // console.log(refundTagCheckbox);
        // console.log(proceedTagCheckbox);
        // console.log(this.state);
        // console.log(currencyFrom);
        // console.log(currencyTo);
        // console.log(currencyToOptions);
        //console.log('***********************');

        // stop spinning the button if a notification comes in (typically as a response)
        let buttonState;
        if (notifications && notifications.length > 0) {
            buttonState = '';
        } else {
            buttonState = this.state.buttonState;
        }

        return (
            <QuickTradePaneWrapper>
                <UpperPane>
                    {!rate.isNaN && rate > 0 && (
                        <Rate>
                            <span>
                                {rateUpdated > 0 ? 'Rate updated ' + distanceInWords(new Date(), new Date(rateUpdated)) + ' ago' : 'Loading...' }
                                <BlocPalToolTip tooltipId='oct.rate_tooltip'>
                                    <FormattedMessage
                                        id="oct.rate_tooltip"
                                        defaultMessage="The exchange rate will adjust to the current rate as of the time the trade is executed on our systems."
                                    />
                                </BlocPalToolTip>
                            </span>
                            {'1 ' + currencyFrom + ' ≈ ' + rate + ' ' + currencyTo}
                        </Rate>
                    )}
                    <ResponsiveRow border>
                        <CurrencyColumn>
                            <InputGroup validationState={validationMap.currencyFrom ? 'error' : null}>
                                <FormattedMessage
                                    id="oct.i_have"
                                    defaultMessage="I have"
                                />
                                <CurrencySelect
                                    value={currencyFrom}
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'oct.select_currency',
                                            defaultMessage: 'Select Currency'
                                        })
                                    }
                                    onSelect={this.onCurrencyFromChangeHandler}
                                    options={currencyFromOptions}
                                    isDisabled={tradeLocked || maintenanceMode}
                                />
                            </InputGroup>
                            <InputGroup validationState={validationMap.refundAddr ? 'error' : null}>
                                <AddressEntry>
                                    <FormattedMessage
                                        id="oct.refund_address_label"
                                        defaultMessage="Your {label} Refund {name}"
                                        tagName="label"
                                        values={{
                                            label: currencyFromConfig.label,
                                            name: currencyFromConfig.networks.find(networkConfig => networkConfig.name === currencyFromConfig.defaultNetwork).addressName,
                                        }}
                                    />
                                    <BlocPalToolTip tooltipId='oct.refund_tooltip'>
                                        <FormattedMessage
                                            id="oct.refund_tooltip"
                                            defaultMessage="If you deposit too much, or the trade fails, this is the address we will return the funds to."
                                        />
                                    </BlocPalToolTip>
                                </AddressEntry>
                                <Input
                                    name="refundAddress"
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'oct.refund_address',
                                            defaultMessage: 'Refund address'
                                        })
                                    }
                                    value={ refundAddress || '' }
                                    onChange={this.onChangeHandler}
                                    error={this.getError('refundAddress')}
                                    disabled={tradeLocked || maintenanceMode}
                                />
                            </InputGroup>
                            {currencyFromConfig.networks.find(networkConfig => networkConfig.name === currencyFromConfig.defaultNetwork).addressTagName && (
                                <React.Fragment>
                                    { !this.state.refundTagCheckbox && (
                                        <InputGroupTag validationState={validationMap.refundTag ? 'error' : null}>
                                            <FormattedMessage
                                                id="oct.refund_tag_label"
                                                defaultMessage="Your {label} Refund {name}"
                                                tagName="label"
                                                values={{
                                                    label: currencyFromConfig.label,
                                                    name: currencyFromConfig.networks.find(networkConfig => networkConfig.name === currencyFromConfig.defaultNetwork).addressTagName,
                                                }}
                                            />
                                            <Input
                                                name="refundTag"
                                                placeholder={currencyFromConfig.networks.find(networkConfig => networkConfig.name === currencyFromConfig.defaultNetwork).addressTagName}
                                                value={ refundTag || '' }
                                                onChange={this.onChangeHandler}
                                                error={this.getError('refundTag')}
                                                disabled={tradeLocked || maintenanceMode}
                                            />
                                        </InputGroupTag>
                                    )}
                                    { (!this.state.refundTag || this.state.refundTag === '') && (
                                        <CheckboxDescription>
                                            <label style={{ color: refundTagCheckbox ? 'black' : '#D0021B' }}>
                                                <Checkbox
                                                    name="refundTagCheckbox"
                                                    checked={refundTagCheckbox}
                                                    onChange={this.onChangeHandler}
                                                    disabled={tradeLocked || maintenanceMode}
                                                />
                                                <FormattedMessage
                                                    id="oct.no_refund_tag_required"
                                                    defaultMessage="My refund address has no {name}"
                                                    tagName="label"
                                                    values={{
                                                        name: currencyFromConfig.networks.find(networkConfig => networkConfig.name === currencyFromConfig.defaultNetwork).addressTagName.replace(' (Optional)', '')
                                                    }}
                                                />
                                            </label>
                                        </CheckboxDescription>
                                    )}
                                </React.Fragment>
                            )}
                            {
                                <FormattedMessage
                                    id="oct.refund_address_hint"
                                    defaultMessage="If we are unable to execute a trade, or if there is any amount in excess of the daily limit, that amount will be sent to your refund address."
                                    tagName="p"
                                />
                            }
                        </CurrencyColumn>
                        <SwapColumn>
                            <img src={'/' + swapIcon} onClick={this.onSwapCurrency}/>
                        </SwapColumn>
                        <CurrencyColumn>
                            <InputGroup validationState={validationMap.currencyTo ? 'error' : null}>
                                <FormattedMessage
                                    id="oct.i_want"
                                    defaultMessage="I want"
                                />
                                <CurrencySelect
                                    value={currencyTo}
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'oct.select_currency',
                                            defaultMessage: 'Select Currency'
                                        })
                                    }
                                    onSelect={this.onCurrencyToChangeHandler}
                                    options={currencyToOptions}
                                    isDisabled={tradeLocked || maintenanceMode}
                                />
                            </InputGroup>
                            {!rate.isNaN && rate === 0 && (
                                <ShortageWarning>
                                    <FormattedMessage
                                        id="oct.low_funds_warning"
                                        defaultMessage="We are currently experiencing a {label} shortage. Processing may be delayed."
                                        tagName="label"
                                        values={{
                                            label: currencyToConfig.label,
                                        }}
                                    />
                                </ShortageWarning>
                            )}
                            <InputGroup validationState={validationMap.proceedAddr ? 'error' : null}>
                                <AddressEntry>
                                    <FormattedMessage
                                        id="oct.proceeds_address_label"
                                        defaultMessage="Your {label} Proceeds {name}"
                                        tagName="label"
                                        values={{
                                            label: currencyToConfig.label,
                                            name: currencyToConfig.networks.find(networkConfig => networkConfig.name === currencyToConfig.defaultNetwork).addressName,
                                        }}
                                    />
                                    <BlocPalToolTip tooltipId='oct.proceeds_tooltip'>
                                        <FormattedMessage
                                            id="oct.proceeds_tooltip"
                                            defaultMessage="When the trade finishes this is where we will send your funds."
                                        />
                                    </BlocPalToolTip>
                                </AddressEntry>
                                <Input
                                    name="proceedAddress"
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'oct.proceed_address',
                                            defaultMessage: 'Proceeds address'
                                        })
                                    }
                                    value={ proceedAddress || '' }
                                    onChange={this.onChangeHandler}
                                    error={this.getError('proceedAddress')}
                                    disabled={tradeLocked || maintenanceMode}
                                />
                            </InputGroup>
                            {currencyToConfig.networks.find(networkConfig => networkConfig.name === currencyToConfig.defaultNetwork).addressTagName && (
                                <React.Fragment>
                                    { !this.state.proceedTagCheckbox && (
                                        <InputGroupTag validationState={validationMap.proceedTag ? 'error' : null}>
                                            <FormattedMessage
                                                id="oct.proceeds_tag_label"
                                                defaultMessage="Your {label} Proceeds {name}"
                                                tagName="label"
                                                values={{
                                                    label: currencyToConfig.label,
                                                    name: currencyToConfig.networks.find(networkConfig => networkConfig.name === currencyToConfig.defaultNetwork).addressTagName,
                                                }}
                                            />
                                            <Input
                                                name="proceedTag"
                                                placeholder={currencyToConfig.networks.find(networkConfig => networkConfig.name === currencyToConfig.defaultNetwork).addressTagName}
                                                value={ proceedTag || '' }
                                                onChange={this.onChangeHandler}
                                                error={this.getError('proceedTag')}
                                                disabled={tradeLocked || maintenanceMode}
                                            />
                                        </InputGroupTag>
                                    )}
                                    { (!this.state.proceedTag || this.state.proceedTag === '') && (
                                        <CheckboxDescription>
                                            <label style={{ color: proceedTagCheckbox ? 'black' : '#D0021B' }}>
                                                <Checkbox
                                                    name="proceedTagCheckbox"
                                                    checked={proceedTagCheckbox}
                                                    onChange={this.onChangeHandler}
                                                    disabled={tradeLocked || maintenanceMode}
                                                />
                                                <FormattedMessage
                                                    id="oct.no_proceeds_tag_required"
                                                    defaultMessage="My proceeds address has no {name}"
                                                    tagName="label"
                                                    values={{
                                                        name: currencyToConfig.networks.find(networkConfig => networkConfig.name === currencyToConfig.defaultNetwork).addressTagName.replace(' (Optional)', '')
                                                    }}
                                                />
                                            </label>
                                        </CheckboxDescription>
                                    )}
                                </React.Fragment>
                            )}
                        </CurrencyColumn>
                    </ResponsiveRow>
                </UpperPane>
                <DownPane>
                    <DownPaneTitle>
                        <FormattedMessage
                            id="oct.confirmation_address"
                            defaultMessage="Confirmation Address"
                        />
                    </DownPaneTitle>
                    <ResponsiveRow>
                        <UserInfoFields>
                            <InputGroup validationState={validationMap.email ? 'error' : null}>
                                <AddressEntry>
                                    <FormattedMessage
                                        id="oct.confirmation_email"
                                        defaultMessage="Email for confirmation"
                                        tagName="label"
                                    />
                                </AddressEntry>
                                <Input
                                    name="email"
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'oct.email_example',
                                            defaultMessage: 'example@example.com'
                                        })
                                    }
                                    type="email"
                                    value={ email || '' }
                                    onChange={this.onChangeHandler}
                                    error={this.getError('email')}
                                    disabled={tradeLocked || maintenanceMode}
                                />
                            </InputGroup>
                            <InputGroup validationState={validationMap.name ? 'error' : null}>
                                <AddressEntry>
                                    <FormattedMessage
                                        id="oct.your_name"
                                        defaultMessage="Your name"
                                        tagName="label"
                                    />
                                    <BlocPalToolTip tooltipId='oct.name_tooltip'>
                                        <FormattedMessage
                                            id="oct.name_tooltip"
                                            defaultMessage="Some customer service issues can only be resolved with a full verifiable name"
                                        />
                                    </BlocPalToolTip>
                                </AddressEntry>
                                <Input
                                    name="name"
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'oct.name_example',
                                            defaultMessage: 'First & Last'
                                        })
                                    }
                                    value={ name || '' }
                                    onChange={this.onChangeHandler}
                                    error={this.getError('name')}
                                    disabled={tradeLocked || maintenanceMode}
                                />
                            </InputGroup>
                            <ProgressButton disabled={tradeLocked || maintenanceMode} onClick={this.onSubmit} state={buttonState}>Execute</ProgressButton>
                        </UserInfoFields>
                        <JustifiedColWrapper>
                            <TradeDescription>
                                <FormattedMessage
                                    id="oct.confirmation_email_description"
                                    defaultMessage="We will send you a confirmation email along with trade details and deposit address."
                                />
                            </TradeDescription>
                            <NameDescription>
                                <FormattedMessage
                                    id="oct.name_description"
                                    defaultMessage="We collect your name for Customer Service and regulatory reasons. Your name and email will be used solely for the purpose of transacting with us."
                                />
                            </NameDescription>
                            { isAuthEnabled && !token && (
                                <RegistrationAd>
                                    <ResponsiveButton bsStyle="orange" to="/signup" title="Sign Up" disabled={tradeLocked || maintenanceMode}>
                                        <FormattedMessage
                                            id="auth.signup.title"
                                            defaultMessage="Sign Up"
                                        />
                                    </ResponsiveButton>
                                    <SignUpDescription>
                                        <FormattedMessage
                                            id="oct.signup_description"
                                            defaultMessage="Full accounts can trade instantly, set alternate order types, track all your trades with trade reporting, and earn better exchange rates."
                                        />
                                    </SignUpDescription>
                                </RegistrationAd>
                            ) }
                        </JustifiedColWrapper>
                    </ResponsiveRow>
                </DownPane>
            </QuickTradePaneWrapper>
        );
    }
}
