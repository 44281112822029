import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import WalletActivity from './WalletActivity';
import { clearWalletActivity, getWalletActivity } from '../../../../actions/portfolio';
import {getCoinNetworks} from '../../../../selectors';

function mapStateToProps(state, { coin }) {
    return {
        fiatCurrency: (state.portfolio.fiatCurrency) ? state.portfolio.fiatCurrency : 'USD',
        usdToFiatRate: state.portfolio.usdToFiatRate,
        walletActivity: state.portfolio.walletActivity,
        networkConfigs: getCoinNetworks(state, coin),
        reloadWalletActivity: state.portfolio.reloadWalletActivity,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearWalletActivity,
            getWalletActivity
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WalletActivity));
