import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionDepositingStep from './TransactionDepositingStep';
import {
    getDepositAddress,
    getProceedAddress,
    getRefundAddress,
    getStatus,
    getDepositMax,
    getDepositMin,
    getDayValue,
    getDayLimit,
    getTransactionCurrencyFromConfig,
    getTransactionCurrencyToConfig,
    getCurrencyRateFromCurrentPair,
    getCurrencyRateUpdatedTime,
    getTransactionNetworkToConfig,
    getTransactionNetworkFromConfig,
} from '../../../../selectors';

function mapStateToProps(state) {
    return {
        transaction: state.transactions.transactionState,
        rate: getCurrencyRateFromCurrentPair(state),
        rateUpdated: getCurrencyRateUpdatedTime(state),
        status: getStatus(state),
        currencyFromConfig: getTransactionCurrencyFromConfig(state),
        currencyToConfig: getTransactionCurrencyToConfig(state),
        networkFromConfig: getTransactionNetworkFromConfig(state),
        networkToConfig: getTransactionNetworkToConfig(state),
        proceedAddress: getProceedAddress(state),
        depositAddress: getDepositAddress(state),
        transactionId: state.transactions.transactionId,
        maxValue: getDepositMax(state),
        minValue: getDepositMin(state),
        dayValue: getDayValue(state),
        dayLimit: getDayLimit(state),
        refundAddress: getRefundAddress(state),
        partialDepthPair: state.currency.partialDepthPair,
        partialDepth: state.currency.partialDepth,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionDepositingStep);
