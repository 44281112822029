import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles';
import { Component as StandardPage } from '../../components/StandardPage';
import PropTypes from 'prop-types';



const Content = styled.div`
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  // line-height: 18px;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;

  a {
    display: block;
  }

  ${mobile`
      margin-left: 10px;
      margin-right: 10px;
  `}
`;


class TermsText extends React.Component {



    render() {
        return (
            <Content>
                <header>
                    <h1>Terms of Service</h1>
                </header>{/* .entry-header */}

                <p>Last Updated: March 1, 2019</p>
                <p>IMPORTANT NOTE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION CLAUSE AND A CLASS ACTION WAIVER. THESE CLAUSES AND OTHER TERMS IN THIS AGREEMENT IMPACT YOUR RIGHTS RELATING TO DISPUTES. PLEASE READ IT CAREFULLY.</p>
                <p>This BlocPal User Agreement (this “<strong>Agreement</strong>”) contains the terms and conditions that apply to your access and use of <span className="underline">blocpal.com</span> and any mobile applications (collectively, the “<strong>Site</strong>”), owned or licensed, and operated by BlocPal International. (“<strong>BlocPal</strong>”, “<strong>we</strong>”, “<strong>our</strong>” or “<strong>us</strong>”), and your use of services available on the Site and to any other related services provided by BlocPal (the “<strong>Services</strong>”), including, without limitation, BlocPal’s Cryptocurrency to Cryptocurrency blockchain trading and exchange platform (the “<strong>Platform</strong>).</p>
                <ol type={1}>
                    <li><p>KEY TERMS</p>
                        <ol type={1}>
                            <li><p><strong>Acceptance of Terms upon Access or Use.</strong></p>
                                <p>BY ACCESSING OR USING THE SITE OR THE SERVICES, YOU ARE INDICATING YOUR ACCEPTANCE OF THE TERMS OF THIS AGREEMENT IN EFFECT WHEN YOU ACCESS OR USE THE SITE OR ANY SERVICES. YOUR ACCESS OF THE SITE AND/OR USE OF THE SERVICES ALSO INDICATES YOUR ACCEPTANCE OF BlocPal’S POLICIES IN EFFECT AT SUCH TIME, WHICH INCLUDE, WITHOUT LIMITATION, BlocPal’S PRIVACY POLICY (the “<strong>Privacy Policy</strong>”), MARKETPLACE CONDUCT RULES (“<strong>Marketplace</strong> <strong>Conduct</strong> <strong>Rules</strong>”), AND ALL OTHER CURRENT POLICIES OF BlocPal (collectively, the “<strong>Policies</strong>”).</p></li>
                            <li><p><strong>Understanding.</strong></p>
                                <p><strong>BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT AND HAVE READ AND UNDERSTAND ALL OF THE POLICIES</strong> AND <strong>HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.</strong></p></li>
                            <li><p><strong>No Access on Non-Acceptance.</strong></p>
                                <p>IF YOU DO NOT ACCEPT THIS AGREEMENT OR THE POLICIES, YOU MUST NOT ACCESS THE SITE OR USE THE SERVICES.</p></li>
                            <li><p><strong>Authority to Bind.</strong></p>
                                <p>IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERM “YOU” OR “USER” WILL REFER TO SUCH ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MUST NOT ACCESS OR USE THE SITE OR THE SERVICES.</p></li>
                            <li><p><strong>Clicking “I Agree”.</strong> By clicking “I AGREE,” during the account opening process, you agree and understand to be legally bound by the terms and conditions of this Agreement, as it may be amended from time to time and agree and understand that you have received the disclosures set forth herein. If you do not agree to be legally bound by the terms and conditions of this Agreement, do not click “I AGREE” and do not visit, access, or use the Site or the Services in any capacity or manner. Whether or not you click “I AGREE,” if you sign up for an account and use the Site or the Services in any capacity or manner, you agree, by virtue of any such action, to be legally bound by the terms and conditions of this Agreement (including any changes or amendments) and the BlocPal Policies in their entirety.</p></li>
                            <li><p><strong>Amendments and Continued Acceptance.</strong> This Agreement and the BlocPal Policies may be amended, changed, or updated by us at any time and without prior notice to you. It is your responsibility to visit the Site to access the most up-to-date version of this Agreement and the Policies to confirm that your copy and understanding of this Agreement is current and correct. Your access of the Site or non-termination or continued use of any Services after the effective date of any amendments, changes, or updates constitutes your acceptance of this Agreement and the Policies, as modified by such amendments, changes, or updates.</p></li>
                            <li><p><strong>Changes Effective Immediately.</strong> Any changes or modifications will be effective immediately upon the posting of the revised Agreement or Policies to the Site. These changes will apply at that instant to all then-current and subsequent uses of the Site. You waive any right you may have to receive specific notice of such changes or modifications.</p></li>
                            <li><p><strong>Notice of Changes.</strong> We may provide notice of changes by one or more of the following: (i) posting the revised Agreement to the Site and changing the “Last Revised” date set out at the top of the Agreement, (ii) emailing registered Users at their provided email addresses, or (iii) any other means as determined by us in our sole discretion. Using a particular form of notice in some instances does not obligate us to use the same form in other instances.</p></li>
                            <li><p><strong>Void where Prohibited by Law.</strong> The use of the Site and any Services is void where such use is prohibited by, would constitute a violation of, or would be subject to penalties under Applicable Laws, and shall not be the basis for the assertion or recognition of any interest, right, remedy, power, or privilege.</p></li>
                        </ol></li>
                    <li><p>ELIGIBILITY</p>
                        <ol type={1} start={10}>
                            <li><p><strong>Representation that User is Legally Eligible to enter into Agreement.</strong> By accessing or using the Site and/or the Services, you represent and warrant that you:</p>
                                <ol type="a">
                                    <li><p>have reached the legal age of majority in your jurisdiction of residence;</p></li>
                                    <li><p>are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations and warranties set forth in this Agreement;</p></li>
                                    <li><p>will abide by and comply with this Agreement and the BlocPal Policies; and</p></li>
                                    <li><p>have not previously been suspended or removed from the Site or from using the Services.</p>
                                        <p>It is your responsibility to ensure that you are legally eligible to enter into the Agreement under any Applicable Laws. If you accept the Agreement, you represent that you have the capacity to be bound by it.</p></li>
                                </ol></li>
                            <li><p><strong>Not a Resident of Restricted Jurisdiction.</strong> You also represent and warrant that you are not a resident of a Restricted Jurisdictions as defined in the list of “Restricted Jurisdictions” on www.BlocPal.com. The list of Restricted Jurisdictions is subject to change at any time. In the event a jurisdiction becomes a Restricted Jurisdiction, BlocPal will provide Users that it has identified as residing in that jurisdiction with notice via email, a notification on the Site, or other means as we deem necessary, regarding their access or restricted access to the Site and Services.</p></li>
                            <li><p><strong>Election to Not make Services or Site Available.</strong> Notwithstanding the foregoing, BlocPal may elect to not make the Services or Site, in whole or in part, available in every market, either in its sole discretion or due to legal or regulatory requirements, depending on the User’s residence, citizenship and/or location.</p></li>
                        </ol></li>
                    <li><p>RISK ACKNOWLEDGEMENT</p>
                        <ol type={1} start={13}>
                            <li><p><strong>Risk Representation and Warranties – Acceptance of Risk</strong>. You represent, warrant and covenant that you accept the following risks arising from your use of the Site and Services:</p>
                                <ol type="a" start={5}>
                                    <li><p>the risk of loss in trading cryptocurrencies and other digital assets may be substantial and you may experience significant losses over a short period of time; \</p></li>
                                    <li><p>there is no guarantee against losses from using the Site or the Services;</p></li>
                                    <li><p>the price and liquidity of cryptocurrencies and other digital assets is volatile and digital asset markets shift quickly in terms of price, liquidity, market depth, and trading dynamics;</p></li>
                                    <li><p>changes in applicable law may adversely affect the use, transfer, exchange and value of cryptocurrencies and other digital assets; and</p></li>
                                    <li><p>cryptocurrencies and other digital assets are not legal tender and are not backed by any government or physical commodity.</p></li>
                                </ol></li>
                            <li><p><strong>Acknowledgment of Risk.</strong> You acknowledge and agree that you access and use the Site and Services at your own risk and you are solely responsible for determining the suitability of the Services and Assets trading and investing for yourself.</p></li>
                            <li><p><strong>No Advice.</strong> BlocPal provides no advice or recommendations regarding cryptocurrencies and other digital assets or investment strategies.</p></li>
                        </ol></li>
                    <li><p>INTERPRETATION</p>
                        <ol type={1} start={16}>
                            <li><p><strong>Definitions</strong>. In this Agreement and all documents incorporated herein by reference, the following terms have the following meanings, unless the context indicates otherwise:</p></li>
                        </ol></li>
                </ol>
                <p>“<strong>AML</strong>” means anti-money laundering and includes all Applicable Laws prohibiting money laundering or any acts or attempted acts to conceal or disguise the identity or origin of, change the form of, move, transfer, or transport any illicit proceeds, property, or funds, whether fiat currency or Assets.</p>
                <p>“<strong>Applicable Laws</strong>” means the laws, regulations, and rules of any governmental or regulatory authority having jurisdiction or application to any or all of BlocPal, you, the Site, or the Services.</p>
                <p>“<strong>Business</strong> <strong>Day</strong>” means a day that is not a Saturday, Sunday, or a day when the BlocPal head office location is closed for a public holiday, a bank holiday, or by government directive.</p>
                <p>“<strong>Cryptocurrency</strong>” means a digital currency that uses cryptography to secure transactions, operating independently of a central authority.</p>
                <p>“<strong>CTF</strong>” means as counter-terrorist financing policy and includes all Applicable Laws prohibiting the financing of terrorist activity.</p>
                <p>“<strong>Asset or Assets</strong>” means any Cryptocurrency, virtual currency, digital currency, digital commodity or digital token accepted by BlocPal for trading using the Services, but specifically excludes securitized tokens and other securities.</p>
                <p>“<strong>User</strong>” means persons accessing the Site or using the Services, including you. If you represent a corporation or other entity, User refers to that underlying entity.</p>
                <p>“<strong>Written Notice</strong>” means notice given by us to you by email communication to the email address listed on your Account. Written Notice given by you to us must be sent to &lt;support@BlocPal.com&gt;. For all notices made by email from us to you, the date of transmission is deemed to be the date that BlocPal sent the e-mail. For all notices made by email from you to BlocPal, the date of receipt is deemed to be the date of acceptance of by BlocPal’s servers.</p>
                <ol type={1} start={17}>
                    <li><p><strong>Extended Meanings.</strong> Unless otherwise specified in this Agreement, words importing the singular include the plural and vice versa and words importing gender include all genders. The word “person” includes individuals, corporations, partnerships, trust, limited liability companies, associations, organizations, and any other form of entity.</p></li>
                    <li><p><strong>Headings.</strong> Section headings in this Agreement are for convenience only, and do not govern the meaning or interpretation of any provision of this Agreement.</p></li>
                </ol>
                <ol type={1} start={5}>
                    <li><p>USER REPRESENTATIONS AND WARRANTIES</p>
                        <ol type={1} start={19}>
                            <li><p><strong>User Representation and Warranties.</strong> You hereby represent, warrant and covenant to BlocPal that:</p>
                                <ol type="a" start={10}>
                                    <li><p>you are a sophisticated investor that has sufficient knowledge regarding Assets, and, in particular, those Assets you have elected to trade for using the Services;</p></li>
                                    <li><p>you understand the speculative and volatile nature of investing in the Assets;</p></li>
                                    <li><p>you are the sole legal and beneficial owner of all Assets you trade using the Services or you are validly authorized to carry out transactions using such Assets;</p></li>
                                    <li><p>the Assets traded by you using the Services are not the direct or indirect proceeds of any criminal or fraudulent activity; and</p></li>
                                    <li><p>you will not be using, nor intend to use, the Site for any illegal activity, including but not limited to illegal gambling, money laundering, fraud, blackmail, extortion, ransoming data, or the financing of terrorism or other violent activities.</p></li>
                                </ol></li>
                        </ol></li>
                    <li><p>COMPLIANCE WITH APPLICABLE LAWS</p></li>
                </ol>
                <p>Your access of the Site and use of the Services is subject to compliance with all Applicable Laws, including, but not limited to:</p>
                <ol type="a" start={15}>
                    <li><p>Money Service Business (“<strong>MSB</strong>”) regulations under the Financial Transactions and Reports Analysis Centre of Canada (“FINTRAC”), if and when applicable;</p></li>
                    <li><p>Provincial money transmission laws, if applicable;</p></li>
                    <li><p>Laws, regulations, and rules of relevant tax authorities;</p></li>
                    <li><p>Applicable regulations and guidance set forth by FINTRAC;</p></li>
                    <li><p>CTF/AML provisions and any other rules and regulations regarding AML or CTF; and</p></li>
                    <li><p>Issuances from the Office of Foreign Assets Control (“<strong>OFAC</strong>”).</p></li>
                </ol>
                <p>You agree and understand that your access of the Site and use of the Services in any capacity is conditional upon your compliance with all Applicable Laws. The above list is not exhaustive and there may be certain Applicable laws that only apply to you. By accessing the Site and using the Services, you agree to act in compliance with and be legally bound by Applicable Laws.</p>
                <ol type={1} start={7}>
                    <li><p>LISTED ASSETS <strong>(CRYPTOCURRENCIES, TOKENS, &amp; DIGITAL ASSETS)</strong></p></li>
                </ol>
                <p>The Listed Assets that are accepted by BlocPal for trading using the Services are listed in our Listed Assets page (the “<strong>Listed Assets</strong>”), which is frequently amended.&nbsp; We may, in our sole discretion, delist any Listed Asset or cease to provide any Services in respect of any Listed Asset, based on a number of factors, which may include, without limitation, changes in a given Listed Asset’s characteristics after originally listing the Asset.&nbsp; You agree and understand that we may delist or cease providing Services in respect of any Listed Asset with or without advance notice to you.</p>
                <ol type={1} start={8}>
                    <li><p><strong>ASSET</strong> NETWORKS <strong>(BLOCKCHAINS, CENTRALIZED NETWORKS, &amp; PEER TO PEER NETWORKS)</strong></p>
                        <ol type={1} start={20}>
                            <li><p><strong>Asset Networks.</strong> You agree and understand that we support only certain Assets and the online networks they operate on, such as a blockchain or peer-to-peer network (each, an “<strong>Asset Network</strong>”).&nbsp; We do not own or control the underlying software protocols of any Asset Networks, which govern the operation of the Assets. In general, the underlying protocols are open <strong>source</strong>, and anyone can use, copy, modify, and distribute them.</p></li>
                            <li><p><strong>No Responsibility for Asset Networks, Forks.</strong> By using the Services, you acknowledge and agree that:</p>
                                <ol type="a" start={21}>
                                    <li><p>we are not responsible for operation of the underlying software or protocols and Asset Networks that support the various Assets and we make no guarantee of functionality, security, or availability of such software and networks, and</p></li>
                                    <li><p>the underlying protocols are subject to sudden changes in operating rules (such <strong>as</strong> “<strong>Forks</strong>”), which may materially affect the value, function, and/or even the name of the Asset you store in your Account.</p></li>
                                </ol></li>
                            <li><p><strong>Event of a Fork.</strong> In the event of a Fork, you agree that we may temporarily suspend the Services with or without advance notice to you, and that we may, in our sole discretion:</p>
                                <ol type="a" start={23}>
                                    <li><p>configure or reconfigure systems, or</p></li>
                                    <li><p>decide not to support (or cease supporting) the Forked Asset Network entirely;</p>
                                        <p>provided, however, that you will have an opportunity to withdraw your Asset(s) on at least one of the still-existent underlying Asset Networks. You acknowledge and agree that we assume absolutely no responsibility whatsoever in respect of any underlying software protocols, whether Forked or otherwise.</p></li>
                                </ol></li>
                            <li><p><strong>Acknowledgment of Asset Network and Fork Risk. </strong></p>
                                <p>ASSET VALUES CAN FLUCTUATE SUBSTANTIALLY WHICH MAY RESULT IN A TOTAL LOSS OF THE VALUE OF ASSETS HELD BY US ON YOUR BEHALF. THE SUPPLY OF ASSETS AVAILABLE TO US TO PROVIDE TO YOU AS A RESULT OF A FORKED ASSET NETWORK AND OUR ABILITY TO DELIVER ASSETS <strong>RESULTING</strong> FROM A FORKED ASSET NETWORK MAY DEPEND ON THIRD PARTY PROVIDERS THAT ARE OUTSIDE OF OUR CONTROL. WE DO NOT OWN OR CONTROL ANY OF THE PROTOCOLS THAT ARE USED IN CONNECTION WITH ASSETS AND THEIR RELATED ASSET NETWORKS, INCLUDING THOSE RESULTING FROM A FORKED ASSET NETWORK. ACCORDINGLY, WE DISCLAIM ALL LIABILITY RELATING TO SUCH PROTOCOLS AND ANY CHANGE IN THE VALUE OF ANY ASSETS (WHETHER OF A FORKED ASSET NETWORK OR OTHERWISE), AND WE MAKE NO GUARANTEES REGARDING THE SECURITY, FUNCTIONALITY, OR AVAILABILITY OF SUCH PROTOCOLS OR ASSET NETWORKS. YOU ACCEPT ALL RISKS ASSOCIATED WITH THE USE OF THE SITE AND THE SERVICES TO CONDUCT TRANSACTIONS, INCLUDING, BUT NOT LIMITED TO, IN CONNECTION WITH THE FAILURE OF HARDWARE, SOFTWARE, AND INTERNET CONNECTIONS.</p></li>
                        </ol></li>
                    <li><p>YOUR PROFILE INFORMATION AND ACCOUNT</p>
                        <ol type={1} start={24}>
                            <li><p><strong>Ad Hoc Trades / QuickTrade.</strong> You may be able to execute an ad-hoc trade (“<strong>QuickTrade</strong>”) on the Site by providing to us your name and a valid email address. Other than ad-hoc trades made within the limits as indicated in “Additional Account Information” below, all other trades will require that you open an account.</p></li>
                            <li><p><strong>BlocPal Account.</strong> You must provide BlocPal with your name, address, date of birth, occupation and a valid email address to create a BlocPal account (“<strong>Account</strong>”). By creating an Account, you agree that BlocPal is providing you with one user identification reference that you will use to create a username and password (together, the “<strong>User ID</strong>”) to the extent, and only to the extent, necessary to access and use the Site and Services in accordance with the Agreement. You agree and understand that you are responsible for maintaining the confidentiality of your User ID. That User ID, together with any other user information you provide, including without limitation the information described in the “Additional <strong>Account</strong> Information” section below, will form your profile information (“<strong>Profile Information</strong>”) and allow you to access and use your Account. You agree to provide true, accurate, current and complete information about yourself, and you agree not to misrepresent your Profile Information. You represent and warrant to BlocPal that you have not misrepresented any Profile Information. You are responsible for any Profile Information that may be lost or unrecoverable through the use of the Site or Services.</p></li>
                            <li><p><strong>Account Non-Transferability, Unauthorized Use, Updates.</strong> Your right to access and use the Services is personal to you and is not transferable by you to any other person or entity. You agree not to disclose your User ID to any third party. You are solely responsible for all activities that occur under your Account. If you become aware of any unauthorized use of your Account or Profile Information, you are responsible for notifying BlocPal immediately. It is your responsibility to update or change any Account or Profile Information, as appropriate.</p></li>
                            <li><p><strong>Right to Limit Number of Accounts.</strong> We reserve the right to limit <strong>the</strong> number of Accounts that any User or such User’s affiliates can open or hold.</p></li>
                        </ol></li>
                    <li><p>SUBACCOUNTS, LINKED ACCOUNTS &amp; ASSET ACCOUNTS</p>
                        <ol type={1} start={28}>
                            <li><p><strong>Linked Accounts.</strong> Your Account may have other User Accounts that may be linked or otherwise associated with your Account as requested by you.&nbsp; By linking a User Account to your Account, you agree to take full responsibility for any action such linked Users may take with <strong>respect</strong> to your Account, and you hereby agree to indemnify and hold harmless BlocPal from any and all liability arising out of such use or misuse by any other User linked to your Account.</p></li>
                            <li><p><strong>Linking a Third Party Account.</strong> Your Account may have third party accounts that may be linked or otherwise associated with your Account as requested by you.&nbsp; By linking a <strong>third</strong> party to your account, you agree to take full responsibility for any action such third party may take with respect to your Account, and you hereby agree to indemnify and hold harmless BlocPal from any and all liability arising out of such use or misuse by any third party linked to your Account.</p></li>
                            <li><p><strong>Asset Accounts.</strong> Your Account may also have one or more Asset sub-accounts (each, an “<strong>Asset Account</strong>”) that reflects your Asset balance specific to that sub-<strong>account</strong>.</p></li>
                        </ol></li>
                    <li><p>ADDITIONAL ACCOUNT INFORMATION</p>
                        <ol type={1} start={31}>
                            <li><p><strong>Verification Required.</strong> Depending on the value of trades you wish to perform using the Services, you will be required to provide us with certain personal information <strong>and</strong> other identity verification materials at the time you sign up for an Account or utilize your Account to perform trades, as follows:</p>

                                <strong>Tier Name: </strong> None (QuickTrades)
                                <br />
                                <strong>Maximum Daily Volume (CAD): </strong> $1,000
                                <br />
                                <strong>Verification Required</strong>
                                <br />
                                <ul>
                                    <li><p>Full Legal Name</p></li>
                                    <li><p>Email Address</p></li>
                                </ul>
                                <br />

                                <strong>Tier Name: </strong> Tier 1
                                <br />
                                <strong>Maximum Daily Volume (CAD): </strong> $10,000
                                <br />
                                <strong>Verification Required</strong>
                                <br />
                                <p>Full Legal Name + Email Address + Tier 1 (Basic) KYC</p>
                                <ul>
                                    <li><p>Address (Verified by Trulioo)</p></li>
                                    <li><p>DOB (Verified by Trulioo)</p></li>
                                    <li><p>Occupation</p></li>
                                </ul>
                                <br />

                                <strong>Tier Name: </strong> Tier 2
                                <br />
                                <strong>Maximum Daily Volume (CAD): </strong> $50,000
                                <br />
                                <strong>Verification Required</strong>
                                <br />
                                <p>Tier 1 (Basic) + Tier 2 (Expanded) KYC</p>
                                <ul>
                                    <li><p>ID Document</p></li>
                                    <li><p>Proof of Residence or CC AVS</p></li>
                                </ul>
                                <br />

                                <strong>Tier Name: </strong> Tier 3
                                <br />
                                <strong>Maximum Daily Volume (CAD): </strong> Unlimited
                                <br />
                                <strong>Verification Required</strong>
                                <br />
                                <p>Tier 1 (Basic) + Tier 2 (Expanded) + Tier 3 (Sophisticated) KYC</p>
                                <ul>
                                    <li><p>Sophisticated Investor Declaration</p></li>
                                </ul>
                                <br />

                            </li>
                        </ol></li>
                </ol>

                <ol type={1} start={32}>
                    <li><p><strong>Collection of Personal Information.</strong> We collect this personal information in order to comply with “know your client” and anti-money laundering best practices and legal <strong>requirements</strong>. We may share this personal information with governmental authorities as required by Applicable Laws and Regulations.</p></li>
                    <li><p><strong>Background Check.</strong> We reserve the right to require you to provide additional information or undergo a background check prior to being authorized to use the <strong>Services</strong>, or at any point thereafter, in accordance with Applicable Laws.</p></li>
                </ol>
                <ol type={1} start={12}>
                    <li><p>ACCOUNT SECURITY</p>
                        <ol type={1} start={34}>
                            <li><p><strong>Two-Factor Authentication.</strong> We highly recommend using multi-factor authentication or two-factor authentication (“<strong>2FA</strong>”) to keep your Assets safe and secure. BlocPal also requires 2FA be enabled on your Account for you to collect BlocPal Monthly Rebates and may require you to <strong>enable</strong> 2FA on Your Account when Your Account reaches a certain value.&nbsp; Forms of 2FA in addition to your standard email and password credentials may include verification numbers or phrases delivered through short message service (“<strong>SMS</strong>”) or a specified and supported two-factor authentication application such as Google Authenticator or a Yubikey USB device. If you choose to install and use a 2FA application on a device, you do so at your own risk, understanding that the security of the device is paramount to the security of Your Account with BlocPal. We reserve the right in our sole discretion to prohibit access from or by any device on which the operating system has been or is suspected of having been modified or tampered with, compromised in any way, or shared with third parties. You agree that we may provide your 2FA data to a third-party service provider in order to help us authenticate you.</p></li>
                            <li><p><strong>Account Credentials.</strong> You agree that your Account credentials and other required forms of authentication, where applicable, have been chosen by you. You, therefore, agree to keep your Account credentials and other required forms of authentication, including your API keys, 2FA <strong>keys</strong> &amp; 2FA devices, confidential and separate from each other, as well as separate from any other information or documents relating to your Account. You also agree to be solely responsible for maintaining the security of your Account credentials and other required forms of authentication, including your API keys, 2FA keys, &amp; 2FA devices. Unfortunately, we cannot be responsible for any unauthorized use of your Account.</p></li>
                            <li><p><strong>Monitoring Your Account.</strong> You are <strong>responsible</strong> for monitoring your Account. If you notice any unauthorized or suspicious activity in your Account, please email&nbsp;<a href="mailto:support@BlocPal.com">support@BlocPal.com</a>&nbsp;and notify us immediately.</p></li>
                        </ol></li>
                    <li><p>COMMUNICATIONS</p>
                        <ol type={1} start={37}>
                            <li><p><strong>Communication Via E-mail.</strong> You agree and understand that all communication with you will be via email. We will use the email address on record for your Account <strong>as</strong> our primary means of communicating with you. To ensure that you receive all of our communications, you agree to keep your email address up-to-date and immediately notify us if there are any changes. Delivery of any communication to the email address on record for your Account is considered valid. If any email communication is returned as undeliverable, we reserve the right to block your access to the Site and Services until you provide and confirm a new, valid email address.</p></li>
                            <li><p><strong>Withdrawal or Non-Receipt of E-mails.</strong> You may withdraw your consent to receive emails by sending a withdrawal notice to BlocPal, understanding that BlocPal may suspend or terminate your ability to use the Site and Services. You understand and agree that if BlocPal sends you an <strong>electronic</strong> communication but you do not receive it because your email address on file is invalid or because our email is blocked by your service provider or intercepted by any filters or spam restrictions, or you are otherwise unable to receive electronic communications, BlocPal will be deemed to have provided the communication to you regardless.</p></li>
                        </ol></li>
                    <li><p>TRANSACTION HISTORY</p>
                        <ol type={1} start={39}>
                            <li><p><strong>Transaction History Composition.</strong> For Tier 1 or higher, you can view and download the transaction history for your Account (“<strong>Transaction History</strong>”) on the Site or <strong>via</strong> the API. Your Transaction History contains all of your trading activity, including pending transactions, deposits and withdrawals, as well as your Asset Account balances; however, please note that nothing in your Transaction History should be treated as a current valuation.</p></li>
                            <li><p><strong>Reasonable Efforts to Ensure Accuracy – Disclaimer.</strong> We make reasonable efforts to ensure that the information contained in the notices we send you and reports we make available to you in <strong>your</strong> Transaction History are accurate and reliable; however, errors may sometimes occur and such errors do not impact the actual means and results of a given transaction. Therefore, we highly recommended you keep your own log of your Transaction History as well.</p></li>
                            <li><p><strong>Previous Transaction History.</strong> If you have engaged in QuickTrade transactions using your e-mail address and wish to acquire your transaction history, you can sign up for a Tier 1 account under that same e-mail address. When you create your account and verify your e-mail, you should be able to access your previous transaction history in connection with that e-mail address.</p></li>
                        </ol></li>
                    <li><p>TRANSACTION REVIEW AND ACKNOWLEDGMENT</p>
                        <ol type={1} start={42}>
                            <li><p><strong>Responsibility for Review of Transaction History.</strong> It is important for you to understand that it is your sole responsibility to review your Transaction History and any notices. You also understand that for the purposes of review and acknowledgment, you agree to be deemed to have reviewed <strong>your</strong> Transaction History and all notices on at least a monthly basis. If for any reason you are unable to do so, or you do not receive our communications, it is your responsibility to email support@BlocPal.com and notify us immediately.</p></li>
                            <li><p><strong>Deemed Receipt.</strong> You understand that every communication sent to your email on record will be deemed to have been acknowledged as <strong>correct</strong>, approved, and confirmed by you unless we have received Written Notice to the contrary within three calendar days from the date the communication was sent.</p></li>
                            <li><p><strong>Changes or Adjustments to Asset Account.</strong> We retain the right to make changes or adjustments to your Asset Account to comply with any Applicable Laws, to ensure market <strong>integrity</strong>, or as we deem necessary or appropriate in our sole discretion. Please see the “Erroneous Orders” and “Erroneous Trades” sections for more information.</p></li>
                        </ol></li>
                    <li><p>ACCOUNT CLOSURE &amp; CANCELLATION BY USER</p>
                        <ol type={1} start={45}>
                            <li><p><strong>BlocPal Right to Cancel Account.</strong> You may cancel your Account at any time through the Website provided as part of the Services.&nbsp; This is the only way to cancel your Account.&nbsp; Email requests or phone requests to cancel your Account or the Services will not be accepted.&nbsp; You will remain liable for all fees and charges accrued on your Account up to the time of cancellation.</p></li>
                            <li><p><strong>Rights and Obligations on Account Closure or Cancellation. Closing</strong> or cancelling an Account will not affect any rights and obligations incurred prior to the date of account closure. You may be required to either cancel or complete all open orders and, in accordance with the provisions of this Agreement, provide transfer instructions of where to transfer any Assets remaining in your Account. You are responsible for <strong>any</strong> fees, costs, expenses, charges, or obligations (including, but not limited to, attorney and court fees or transfer costs of Assets) associated with the closing of your Account. In the event that the costs of closing of your Account exceed the value in your Account, you will be responsible for reimbursing us for the shortfall. You may not close any of your Accounts to avoid paying any fees otherwise due or to avoid any examination related to any AML requirements.</p></li>
                        </ol></li>
                    <li><p>ACCOUNT SUSPENSION</p>
                        <ol type={1} start={47}>
                            <li><p><strong>Suspension of Account.</strong> If BlocPal, in its sole discretion and without notice, considers that there is an immediate security or operational risk to the Site, the Services or any of its, your, or a third party’s systems, then we may immediately suspend your Account or your access or use of the Services. Such suspension of your Account or your access or use of the Services is not a breach of this Agreement and we have no liability to you for suspending your Account or the Services under this provision. You acknowledge that the preservation of security, confidentiality and data is paramount to BlocPal.</p></li>
                            <li><p><strong>Related Account.</strong> You further agree and understand that we have the right to immediately: (i) suspend and/or investigate your Account and all Accounts beneficially owned by you or any members of your household or for which you are a representative or authorized signatory and, in the case of entities, any affiliates (each, a “<strong>Related</strong> <strong>Account</strong>”), (ii) freeze/lock the funds and assets in all such Accounts, and (iii) suspend your access to the Site and Services, until a determination has been made, if we suspect, in our sole discretion, any such accounts to be in violation of:</p>
                                <ol type="a" start={25}>
                                    <li><p>any provision of this Agreement or any other BlocPal Policies or terms;</p></li>
                                    <li><p>any Applicable Laws;</p></li>
                                    <li><p>any CTF, AML or other regulatory compliance rules or regulations (collectively, the “CTF/AML Requirements”); or</p></li>
                                    <li><p>our &lt;Marketplace Conduct Rules&gt;</p></li>
                                </ol></li>
                        </ol></li>
                </ol>
                <p>at any time since the opening of such Account or Related Account (each, a “<strong>Conduct</strong> <strong>Violation</strong>”).</p>
                <ol type={1} start={49}>
                    <li><p><strong>Suspension, Investigation.</strong> You further agree and understand that we have the right to immediately: (i) suspend and/or investigate your Account and any Related Account, (ii) freeze/lock the funds and assets in all such Accounts, and (iii) suspend and/or investigate your access to the Site or the Services until a determination has been made, if:</p>
                        <ol type="a" start={29}>
                            <li><p>we are required to do so by a regulatory authority, court order, facially valid subpoena, or binding order of a government authority;</p></li>
                            <li><p>the Account or any Related Account is subject to any pending litigation, investigation, or governmental proceeding;</p></li>
                            <li><p>the Account has a negative balance for any reason;</p></li>
                            <li><p>the Account has a balance that needs to be reconciled for any reason;</p></li>
                            <li><p>the Account or the information given by you is not in compliance with CTF/AML Requirements;</p></li>
                            <li><p>we believe someone is attempting to gain unauthorized access to the Account;</p></li>
                            <li><p>we believe there is unusual activity in the Account; or</p></li>
                            <li><p>we believe you are using the Site, the Services, your UserID, your Profile Information, or other Account information in an unauthorized or inappropriate manner.</p></li>
                        </ol></li>
                    <li><p><strong>Termination and Other Actions.</strong> You agree and understand that if your Account or any Related Account is determined, in our sole discretion, to have committed a Conduct Violation, we have the right to terminate any such accounts and take any and all necessary and appropriate actions pursuant to this Agreement and/or Applicable Laws.</p></li>
                    <li><p><strong>Notification of Suspension.</strong> If your Account has been suspended, you will be notified when accessing the Site. We may, in our sole discretion, give Written Notice that your Account has been suspended and may, in our sole discretion, disclose the reasons for suspension, if permitted to do so by law.</p></li>
                    <li><p><strong>Debit for Damages etc.</strong> You agree and understand that if your Account or any Related Account is determined, in our sole discretion, to have committed a Conduct Violation, we have the right to debit from your Account or any Related Account:</p>
                        <ol type="a" start={37}>
                            <li><p>the value of any trading fee discounts or rebates that you or the holder of a Related Account has received in connection with this Agreement at any time after one year preceding the date on which we become aware of the existence of the Conduct Violation; and</p></li>
                            <li><p>any other damages suffered by us as a result of the Conduct Violation.</p></li>
                        </ol></li>
                </ol>
                <ol type={1} start={18}>
                    <li><p>ACCOUNT INVESTIGATIONS</p>
                        <ol type={1} start={53}>
                            <li><p><strong>Right to Investigate.</strong> You agree and understand that we have the right to immediately investigate your Account and any Related Account, if we suspect, in our sole discretion, that any such Account is associated with a Conduct Violation.</p></li>
                            <li><p><strong>Investigation in Certain Circumstances.</strong> You further agree and understand that we have the right to immediately investigate your Account and any Related Account, if:</p>
                                <ol type="a" start={39}>
                                    <li><p>we are required to do so by a regulatory authority, court order, facially valid subpoena, or binding order of a government authority; or</p></li>
                                    <li><p>the Account or any Related Account is subject to any pending litigation, investigation, or governmental proceeding.</p></li>
                                </ol></li>
                            <li><p><strong>Notice of Investigation.</strong> We may, in our sole discretion, give Written Notice that your Account is the subject of an investigation and may, in our sole discretion, share the general nature of the investigation, if permitted to do so by law.</p></li>
                            <li><p><strong>Violation of Marketplace Conduct Rules.</strong> If we suspect that your Account has committed a Conduct Violation that pertains to our Marketplace Conduct Rules, we will give Written Notice to you and share the general nature of the allegations as well as the specific provisions of our Marketplace Conduct Rules that appear to have been violated. You will have the right to submit, within seven calendar days from the date of notification, a written statement to our compliance department (“<strong>Compliance Department</strong>”) explaining why no disciplinary action should be taken.</p></li>
                            <li><p><strong>Requirement to Furnish Information.</strong> You agree and understand that upon our request and within the time frame designated by us, you and any related party are obligated to respond to any interrogatories in writing and to furnish any documentary materials and other information in connection with any investigation initiated pursuant to this Agreement. You agree and understand that neither you nor any related party shall impede or delay any investigation or proceeding conducted pursuant to this Agreement, nor refuse to comply with any request made pursuant to this Agreement.</p></li>
                            <li><p><strong>Determination after Investigation.</strong> Following the conclusion of an investigation, our Compliance Department will make, in its sole discretion, a determination based upon the weight of the evidence. If a suspected Conduct Violation pertains to our Marketplace Conduct Rules, our Compliance Department may consider a variety of factors in assessing whether your conduct violated our Marketplace Conduct Rules, including, but not limited to:</p>
                                <ol type="a" start={41}>
                                    <li><p>Whether your intent was to induce others to trade when they otherwise would not have;</p></li>
                                    <li><p>Whether your intent was to affect a price rather than to change your position;</p></li>
                                    <li><p>Whether your intent was to create misleading market conditions;</p></li>
                                    <li><p>Market conditions in the impacted market(s) and related markets;</p></li>
                                    <li><p>The effect on other market participants;</p></li>
                                    <li><p>Your historical pattern of activity;</p></li>
                                    <li><p>Your order entry and cancellation activity;</p></li>
                                    <li><p>The size of your order(s) relative to market conditions at the time your order(s) was placed;</p></li>
                                    <li><p>The size of your order(s) relative to your position and/or capitalization;</p></li>
                                    <li><p>The number of orders;</p></li>
                                    <li><p>Your ability to manage the risk associated with your order(s) if fully executed;</p></li>
                                    <li><p>The duration for which your order(s) was exposed to the market;</p></li>
                                    <li><p>The duration between, and frequency of, non-actionable messages;</p></li>
                                    <li><p>The queue position or priority of your order in the order book;</p></li>
                                    <li><p>The prices of preceding and succeeding bids, offers, and trades; and</p></li>
                                    <li><p>The change in the best offer price, best bid price, last sale price, or other price that results from the entry of your order.</p></li>
                                </ol></li>
                            <li><p><strong>Right to Terminate and Take Appropriate Actions.</strong> You agree and understand that if your Account or any Related Account is determined, in our sole discretion, to be have committed a Conduct Violation, we have the right to terminate any such accounts and take any and all necessary and appropriate actions pursuant to this Agreement and/or Applicable Laws.</p></li>
                        </ol></li>
                    <li><p>ACCOUNT TERMINATION</p>
                        <ol type={1} start={60}>
                            <li><p><strong>Right of BlocPal to Terminate, Modify, Suspend.</strong> We reserve the right at any time, and without cost, charge or liability, to terminate your User Account or this Agreement at our sole discretion for any reason, including, but not limited to, a failure to comply with the terms of this Agreement. We reserve the right to modify, suspend or discontinue the Site and/or Services, or any portion thereof, at any time and for any reason, without notice.</p></li>
                            <li><p><strong>Return of Assets.</strong> If your User Account is terminated and is no longer subject to an investigation, court order, or subpoena, we will return your Assets to you, less the value of any trading fee discounts, withdrawal fees, rebates, costs and/or damages that we are entitled to pursuant to this Agreement. You agree to provide us with an Asset address within seven calendar days upon receiving Written Notice of termination, so that we can return the remaining Assets to you.</p></li>
                        </ol></li>
                    <li><p>RIGHT OF OFFSET</p></li>
                </ol>
                <p>You agree and understand that we have the right to offset any and all debts owed to us through the offset of balances of your Account or any Related Account. We will withdraw sufficient Assets as calculated using BlocPal’s current exchange prices. In addition, you agree and understand that we may alternatively collect some or all of any such amounts by offsetting them against any amount owing to you from BlocPal, if any. If you disagree with any determination made or remedy exercised under this subsection, you may bring an arbitration action pursuant to the ‘Dispute Resolution’ section of this Agreement.</p>
                <ol type={1} start={21}>
                    <li><p>UNCLAIMED FUNDS</p>
                        <ol type={1} start={62}>
                            <li><p><strong>Unclaimed Property.</strong> If your Account remains closed or dormant for a long enough period of time, we may be required, upon the passage of applicable time periods, to report any remaining funds in your Account as unclaimed property in accordance with abandoned property and escheat laws. If this occurs, we will use reasonable efforts to give Written Notice. If you fail to respond to any such Written Notice within seven (7) Business Days or as otherwise required by law, we have the right to suspend or terminate your account and may be required to deliver any such funds to your jurisdiction as unclaimed property. We reserve the right to deduct an administrative fee resulting from such unclaimed funds, as permitted by Applicable Law.</p></li>
                            <li><p><strong>Dormant Account Fee.</strong> In the event no abandoned property laws apply, after five (5) years of dormancy, we will institute a monthly dormant account fee equal to 2.1% of the balance as denominated in the Asset or Assets on deposit as of the date that the dormant account fee is implemented. This monthly account fee will apply to the account until the balance is reduced to zero.</p></li>
                        </ol></li>
                    <li><p>LEGAL PROCESS</p></li>
                </ol>
                <p>You agree and understand that we, as well as our affiliates, service providers, their respective officers, directors, agents, joint venturers, employees and representatives (collectively, the “<strong>BlocPal Service Providers</strong>”), may comply with any writ of attachment, execution, garnishment, tax levy, restraining order, subpoena, warrant or other legal process, which any of us reasonably and in good faith believe to be valid. We and any BlocPal Service Provider may, but are not required to, notify you of such process by electronic communication. We and any BlocPal Service Provider may charge you for associated costs, in addition to any legal process fees. You agree to indemnify, defend, and hold all of us harmless from all actions, claims, liabilities, losses, costs, attorney’s fees on an attorney-client basis, or damages associated with our compliance with any process that any of us reasonably believe in good faith to be valid. You further agree that we and any BlocPal Service Provider may honor any legal process, regardless of the method or location of service.</p>
                <ol type={1} start={23}>
                    <li><p>PRIVACY POLICY</p></li>
                </ol>
                <p>User privacy is important to us. We only share User information with third parties as outlined in our &lt;Privacy Policy&gt; or as required to do so by law enforcement, court order, or in compliance with the CTF/AML Requirements or other legal and reporting obligations.</p>
                <ol type={1} start={24}>
                    <li><p>SERVICE MODIFICATIONS</p></li>
                </ol>
                <p>You agree and understand that we may modify part of or all of the Site or Services, including the Platform, without notice.</p>
                <ol type={1} start={25}>
                    <li><p>MARKET DATA</p>
                        <ol type={1} start={64}>
                            <li><p><strong>BlocPal Market Data.</strong> Our exchange matching engines (“<strong>Exchange</strong> <strong>Matching</strong> <strong>Engines</strong>”) generate data regarding bid prices, ask prices, trade prices, final auction prices, and trading volume (collectively, “<strong>BlocPal Market Data</strong>”). BlocPal Market Data are publicly available via our website at BlocPal.com and our application programming interface (“<strong>API</strong>”).</p></li>
                            <li><p><strong>More Information on Market Data.</strong> For more information on BlocPal Market Data, please see our Marketplace page at www.BlocPal.com.</p></li>
                        </ol></li>
                    <li><p>API AGREEMENT</p></li>
                </ol>
                <p>By accessing or using our API, you are agreeing to be bound by all of the terms and conditions containerd herein, including without limitation the Marketplace Conduct Rules.</p>
                <ol type={1} start={27}>
                    <li><p>ORDER TYPES</p></li>
                </ol>
                <p>BlocPal currently supports limit orders. There is no margin trading, options trading, or shorting offered on BlocPal at this time.</p>
                <ol type={1} start={28}>
                    <li><p>CLEARLY ERRONEOUS TRANSACTION POLICY</p>
                        <ol type={1} start={66}>
                            <li><p><strong>Clearly Erroneous Transaction.</strong> We consider a transaction to be clearly erroneous when its price is substantially inconsistent with the market price at the time of execution (each, a “<strong>Clearly Erroneous Transaction</strong>”). In making a determination, we take into account the circumstances at the time of the transaction, the preservation of the integrity of the market, and the maintenance of a fair and orderly marketplace. You are responsible for ensuring that the appropriate price and Order type is entered into the Platform. A simple assertion by you that a mistake was made in entering an Order, or that you failed to pay attention to or update an Order, will not be sufficient to establish it as a Clearly Erroneous Transaction.</p></li>
                            <li><p><strong>Determination.</strong> You agree and understand that if we determine a transaction to be a Clearly Erroneous Transaction, we may declare it null and void, in whole or in part, even if you and/or the other party do not agree to cancel or modify it.</p></li>
                            <li><p><strong>Considerations for Determination.</strong> In determining whether a transaction is a Clearly Erroneous Transaction, we will consider the following:</p>
                                <ol type="a" start={57}>
                                    <li><p>Suspicious trading activity;</p></li>
                                    <li><p>Violations of our Marketplace Conduct Rules;</p></li>
                                    <li><p>If there was an obvious error in any term, including, but not limited to, price, amount of Assets, or other unit of trading;</p></li>
                                    <li><p>If there was a disruption or malfunction in the operation of any trading system or component of our Platform, a BlocPal Service Provider, or an Asset Network; or</p></li>
                                    <li><p>If there were extraordinary market conditions or other circumstances in which the nullification or modification of transactions may be necessary for the maintenance of a fair and orderly market.</p></li>
                                </ol></li>
                            <li><p><strong>Timing of Determination and Notice.</strong> Absent extraordinary circumstances, our Market Data Committee will make a determination and take any action pursuant to this Clearly Erroneous Transaction Policy as soon as reasonably practicable. If you are a party to any action taken, we will notify you via Written Notice as soon as practicable.</p></li>
                        </ol></li>
                    <li><p>MARKETPLACE CONDUCT RULES</p>
                        <ol type={1} start={70}>
                            <li><p><strong>Marketplace Conduct Rules.</strong> In an effort to foster a fair and orderly market, we have put in place marketplace controls (“<strong>Marketplace</strong> <strong>Conduct Rules</strong>”). For information on BlocPal’s Marketplace Controls, please see our &lt; Marketplace Conduct Rules &gt;.</p></li>
                            <li><p><strong>Acceptance of Marketplace Conduct Rules.</strong> <span className="underline"> </span> Your acceptance of this Agreement is considered to be your acceptance of our Marketplace Conduct Rules. We reserve the right to change or modify our Marketplace Conduct Rules at any time. Any change to our Marketplace Conduct Rules will be posted for at least three calendar days before it takes effect and no change will be in effect for less than 30 calendar days. You agree that by logging into your account and/or authenticating to our API with your API key and using the Site or Services following any change to our Marketplace Conduct Rules, you are agreeing to accept our new Marketplace Conduct Rules.</p></li>
                        </ol></li>
                    <li><p>TRADE PROCESS</p>
                        <ol type={1} start={72}>
                            <li><p><strong>Trade Process.</strong> At the time you place an order to make a trade using the Services (an “<strong>Order</strong>”), you must confirm the following information:</p>
                                <ol type="a">
                                    <li><p>the amount of the Asset you wish to sell or purchase;</p></li>
                                    <li><p>any fees and other charges associated with your proposed trade; and</p></li>
                                    <li><p>the Asset Account(s) or blockchain address(es) that you wish to use to facilitate your proposed trade. Please note that deposits need to be in place before a trade can occur.</p></li>
                                </ol></li>
                            <li><p><strong>Responsibility for Orders.</strong> You agree to be responsible for all Orders and Order modifications placed on BlocPal. For QuickTrade, you acknowledge and agree that the exchange rate in effect for such trade is subject to change due to market conditions at the time the trade is executed.</p></li>
                            <li><p><strong>Acceptance or Rejection of Orders.</strong> BlocPal reserves the right to accept or reject any Orders in its sole discretion. Further, BlocPal may cancel, and/or reverse any Order or trade, in our sole discretion with or without your consent, if we determine:</p>
                                <ol type="a">
                                    <li><p>such Order or trade constitutes suspicious trading activity;</p></li>
                                </ol>
                                <ol type="a" start={4}>
                                    <li><p>such Order or trade is in violation of the terms and conditions of this Agreement;</p></li>
                                    <li><p>there was an obvious error in the terms of the Order or trade, including without limitation amount, type or pricing of the Asset;</p></li>
                                    <li><p>there was an error or disruption that affected the Site, Services, our other systems or any third-party systems utilized to facilitate Orders or trades; or</p></li>
                                    <li><p>there was any other circumstance, including without limitation extraordinary market conditions, which resulted in an abnormal or irregular Order or trade being accepted and/or processed.</p></li>
                                </ol></li>
                            <li><p><strong>No Guarantee.</strong> You agree and understand that we do not guarantee that any Order you place will be filled. Orders that are active before Downtime (as defined herein), may be lost or may be handled, once our Exchange resumes operations.</p></li>
                            <li><p><strong>No Liability for Damages.</strong> You further agree that in no event will we be liable to you for any damages, costs, or other liability in the event of any Downtime (as defined below), systems failure, error, interruption, delay, or other circumstance resulting in the inability to connect to our Exchange.</p></li>
                            <li><p><strong>Failure of Transaction.</strong> A transaction on the Site may fail for several reasons, including but not limited to change in prices or unanticipated technical difficulties. We make no representation or warrant that any transaction will be executed properly. BlocPal is under no circumstances liable for any loss or injury suffered by a failure of a transaction to complete properly or in a timely manner. Further, BlocPal is in no way responsible for notifying you of a transaction failure. You have full responsibility to determine and inquire into the failure of any transaction that you initiate.</p></li>
                            <li><p><strong>Notification of Unentitled Data.</strong> In the event that you receive any data, information, or software through our Services other than that which you are entitled to receive pursuant to this Agreement, you will immediately notify us and will not use, in any way whatsoever, such data, information, or software.</p></li>
                            <li><p><strong>Withdrawal Request.</strong> If you request a withdrawal of Assets and we cannot comply with it without closing some part of your open Orders, we will not comply with the request until you have cancelled sufficient Orders to allow you to make the withdrawal.</p></li>
                            <li><p><strong>No Change Except to Unfilled Orders.</strong> While BlocPal may in its discretion reverse a trade, a User may not change, withdraw, or cancel its authorization to make a transaction, except with respect to unfilled orders.</p></li>
                            <li><p><strong>Correction, Reversal, Cancellation by BlocPal.</strong> We may correct, reverse, or cancel any trade impacted by a BlocPal error in processing your purchase or otherwise. Your remedy in the event of an error will be limited. You may seek to cancel your order or reverse the transaction, although we cannot guarantee such cancellations or reversals will always be possible.</p></li>
                            <li><p><strong>Order Fulfillment.</strong> BlocPal provides its Users with a platform that allows their orders to be matched with the orders of other Users. Orders may be partially filled or may be filled by a number of orders, depending on the trading activity at the time an order is placed.</p></li>
                            <li><p><strong>Exchange Rate Volatility.</strong> The Assets that are available for purchase through the Services may be subject to high or low transaction volume, liquidity, and volatility at any time for potentially extended periods. You acknowledge that while we are using commercially reasonable methods to provide exchange rate information to you through our Services, the exchange rate information we provide may differ from prevailing exchange rates made available by third parties. Similarly, the actual market rate at the time of your trade may be different from the indicated prevailing rate depending on the velocity of trading in the Assets involved in your trade. You agree that we are not liable for price fluctuations or differences in actual versus indicated rates.</p></li>
                        </ol></li>
                    <li><p>DEPOSITS AND WITHDRAWALS</p>
                        <p>We email you receipt confirmation for all deposits and withdrawals.</p></li>
                    <li><p>ASSET DEPOSITS</p>
                        <ol type={1} start={84}>
                            <li><p><strong>Asset Deposits.</strong> Asset deposits will typically be credited to your Account after the requisite number of network confirmations have occurred on the blockchain for the deposited Asset. You agree and understand that in certain situations, Asset deposits may be delayed in connection with Downtime or congestion of or disruption to an Asset Network.</p></li>
                            <li><p><strong>No Receipt of Funds to Account from Third Party.</strong> You are not allowed to receive funds in your Account from a sender other than yourself. Any funds you receive from a sender other than yourself will be considered unauthorized. We reserve the right to investigate the source of any funds in your Account and determine, in our sole discretion, how to handle their disposition. Following our review of any funds in question and the circumstances by which you received them, we may determine that you are not the owner of such funds. If such a determination is made, we reserve the right to dispose of these funds in accordance with Applicable Laws and in our sole discretion, which may include, returning them to the destination of their origin or to a charity of our choosing.</p></li>
                        </ol></li>
                    <li><p>ASSET WITHDRAWALS</p>
                        <p>Asset withdrawals will typically be processed at the speed of an Asset Network. In certain situations, Asset withdrawals may be delayed in connection with hot wallet funding, Downtime, congestion or disruption of an Asset Network, or further review by our Compliance Department.</p></li>
                    <li><p>DOWNTIME AND MAINTENANCE</p>
                        <ol type={1} start={86}>
                            <li><p><strong>Downtime.</strong> You agree and understand that part of or all of the Site or Services may be periodically unavailable during scheduled maintenance or unscheduled downtime (collectively, “<strong>Downtime</strong>”).</p></li>
                            <li><p><strong>Maintenance.</strong> For information on our scheduled maintenance windows, please see www.BlocPal.com.</p></li>
                        </ol></li>
                    <li><p>FEES</p>
                        <p>Our current fee schedule is available on www.BlocPal.com (the “<strong>Fee Schedule</strong>”), and you hereby agree to pay all applicable <strong>fees</strong> related to your use of the Services as described in and in accordance with the Fee Schedule. We reserve the right to modify the Fee Schedule, including without limitation by increasing fees or introducing new fees or charges, at any time without notice.</p></li>
                    <li><p>COOKIES</p></li>
                </ol>
                <p>When you access the Site, we may make use of the standard practice of placing tiny data files called cookies, flash cookies, pixel tags, or other tracking tools (herein, “<strong>Cookies</strong>”) on your computer or other devices used to visit the Site and use the Services. We use Cookies to help us recognize you as a customer, collect information about your use of the Site and Services to better customize our services and content for you, and collect information about your computer or other access devices to: (i) ensure compliance with our CTF/AML Program, and (ii) ensure that your User Account security has not been compromised by detecting irregular or suspicious account activities. When you log on to the Site, you will be provided with the option to accept or reject cookies. Please note that if you reject cookies, you will not be able to use some or all of the Site. If you do not consent to the placing of Cookies on your device, please do not visit, access, or use the Site or the Services.</p>
                <ol type={1} start={37}>
                    <li><p>THIRD PARTY SITES AND CONTENT</p></li>
                </ol>
                <p>The Site may permit you to link to other websites or resources on the Internet, and other websites or resources may contain links to the Site. These other websites are not under BlocPal’s control, and you acknowledge that BlocPal is not responsible or liable for any third-party content, including but not limited to the accuracy, integrity, quality, usefulness, legality, appropriateness, safety or intellectual property rights of or relating to such third-party content or any other aspect of such websites or resources. The inclusion of any such link does not imply endorsement by BlocPal or any association of BlocPal with its operators. You further acknowledge and agree that BlocPal will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such third-party content, goods or services available on or through any such website or resource. Access and use of third-party sites, including the information, material, products and services on third party sites or available through third party sites, is solely at your own risk.</p>
                <ol type={1} start={38}>
                    <li><p>RECORDING AND RECORDKEEPING</p></li>
                </ol>
                <p>You agree and understand that for our mutual protection we may electronically record any telephone conversation we have with you. You also agree and understand that we maintain and retain records of all information, activity, and communications relating to your Account and use of the Site and Services.</p>
                <ol type={1} start={39}>
                    <li><p>INTELLECTUAL PROPERTY RIGHTS</p>
                        <ol type={1} start={88}>
                            <li><p><strong>Ownership by BlocPal et al.</strong> All material available on the Site and all material and services provided by or through BlocPal, its affiliates, subsidiaries, employees, agents, licensors or other commercial partners including, but not limited to, software, all informational text, software documentation, design of and “look and feel”, layout, photographs, graphics, audio, video, messages, interactive and instant messaging, design and functions, files, documents, images or other materials, whether publicly posted or privately transmitted as well as all derivative works thereof (collectively, the “<strong>Materials</strong>”), and all software, hardware and other technology used to perform the Services (and all intellectual property rights therein), are owned by us or our licensors or service providers, and are protected by copyright, trademark, trade secret and other intellectual property laws.</p></li>
                            <li><p><strong>Limited Licence.</strong> Subject to your compliance with this Agreement, during the term of this Agreement we grant to you a non-transferable, non-sublicensable, non-exclusive, revocable, limited-purpose right to access and use the Materials that we make available to you. You are not permitted to download, copy or otherwise store any Materials with the exception of publicly available trade data or your own account records.</p></li>
                            <li><p><strong>Feedback.</strong> You agree that any ideas, suggestions, concepts, processes or techniques which you provide to us related to the Services, the Site or BlocPal or its business (“<strong>Feedback</strong>”) are and will be our exclusive property without any compensation or other consideration payable to you by us, and you do so of your own free will and volition. BlocPal may or may not, in its sole discretion, use or incorporate the Feedback in whatever form or derivative BlocPal may decide into the Site, the Services, its software, documentation, business or other products or services, or any future versions or derivatives of the foregoing. You hereby assign all rights on a worldwide basis in perpetuity to BlocPal in any Feedback and, as applicable, waive any moral rights.</p></li>
                            <li><p><strong>Aggregated Data.</strong> BlocPal retains the right to sell, use, or share any Aggregated Data generated by anyone using the Services, including our users, for any purpose including enhancing and providing the Services and preparing and distributing investor reports.&nbsp;BlocPal does not sell personal information to any third parties; “Aggregated Data” means data that does not contain personal information, and which has been manipulated or combined to provide generalized, anonymous information.</p></li>
                        </ol></li>
                    <li><p>TAXES</p></li>
                </ol>
                <p>You take full responsibility for all taxes and fees of any nature associated with the Services, including any taxes related to your purchase or sale of the Assets. It is your responsibility to determine whether or not taxes apply to a transaction and to collect, report and remit the correct amounts to the appropriate authority.</p>
                <ol type={1} start={41}>
                    <li><p>EXPORT CONTROLS</p></li>
                </ol>
                <p>The Services and the trading of Assets may be subject to Canadian and other international export control and economic sanctions laws and regulations (collectively, “<strong>Export Control Laws</strong>”). By using the Services, you represent, warrant and covenant that your use of the Services and trading of the Assets complies with all applicable Export Control Laws. In the event we determine that we are required by any applicable Export Control Laws to prevent you from using the Site or Services, we may, in our sole discretion, suspect or terminate your User Account, and we are not responsible for any direct or indirect losses you may incur as a result of such actions.</p>
                <ol type={1} start={42}>
                    <li><p>NO ILLEGAL USE</p>
                        <ol type={1} start={92}>
                            <li><p><strong>Prohibition on Illegal Activity Uses.</strong> BlocPal strictly prohibits the use of the Site for any illegal activity, including but not limited to illegal gambling, money laundering, fraud, blackmail, extortion, ransoming data, or the financing of terrorism or other violent activities.</p></li>
                            <li><p><strong>Consequences of Illegal Activity Uses.</strong> You agree and understand that we have the right to immediately:</p>
                                <ol type="a">
                                    <li><p>suspend your Account and any Related Account;</p></li>
                                    <li><p>freeze or lock the funds and assets in all such accounts;</p></li>
                                    <li><p>suspend your access to BlocPal; and</p></li>
                                    <li><p>take any and all necessary and appropriate actions pursuant to this Agreement and/or Applicable Laws if we suspect, in our sole discretion, any such accounts to be used in connection with illegal activity, which is a direct violation of this Agreement.</p></li>
                                </ol></li>
                        </ol></li>
                    <li><p>GENERAL PROVISIONS</p></li>
                </ol>
                <p>You agree and understand to be legally bound by the terms and conditions set forth in this Agreement and that this Agreement governs your use of BlocPal and the Services. By clicking “I AGREE,” during the account opening process, you agree and understand to be legally bound by the terms and conditions of this Agreement, as it may be amended from time to time and agree and understand that you have received the disclosures set forth herein. If you do not agree to be legally bound by the terms and conditions of this User Agreement, do not click “I AGREE” and do not visit, access, or use the Site or the Services in any capacity or manner. Whether or not you click “I AGREE,” if you sign up for an account and use the Site or the Services in any capacity or manner, you agree, by virtue of any such action, to be legally bound by the terms and conditions of this Agreement (including any changes or amendments) and the BlocPal Policies in their entirety.</p>
                <ol type={1} start={44}>
                    <li><p>DISCLAIMER OF WARRANTIES</p>
                        <ol type={1} start={94}>
                            <li><p><strong>No Advice or Endorsement.</strong> For the avoidance of doubt, neither we nor any BlocPal Service Provider are giving investment advice, tax advice, legal advice, or other professional advice by allowing you to use BlocPal and the Services we or any BlocPal Service Provider provide, including, but not limited to, the ability to buy, sell, or store Assets or fiat currency. In addition, neither we nor any BlocPal Service Provider recommend, or endorse that you buy or sell Assets, or that you make any investment or enter into any trade. Before engaging in any trading or investment activity, you should always consult a qualified professional.</p></li>
                            <li><p><strong>Sole Responsibility, No Warranties.</strong> YOUR USE OF THE SITE OR SERVICES ARE AT YOUR SOLE RESPONSIBILITY AND RISK. THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. BlocPal EXPRESSLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND WITH RESPECT TO THE SITE OR SERVICES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, COMPLETENESS, PERFORMANCE, SYSTEM INTEGRATION, QUIET ENJOYMENT, TITLE AND NON-INFRINGEMENT.</p></li>
                            <li><p><strong>Disclaimer of Warranty.</strong> BlocPal DISCLAIMS ANY WARRANTY THAT THE SITE OR THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED OR THAT THE SITE OR THE SERVERS THAT MAKES THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOUR ACCESS AND USE OF THE SITE AND THE SERVICES MAY BE INTERRUPTED FROM TIME TO TIME FOR ANY OF SEVERAL REASONS, INCLUDING, WITHOUT LIMITATION, THE MALFUNCTION OF EQUIPMENT, PERIODIC UPDATING, MAINTENANCE OR REPAIR OF THE SITE OR SERVICES OR OTHER ACTIONS THAT BlocPal, IN ITS SOLE DISCRETION, MAY ELECT TO TAKE. BlocPal MAKES NO GUARANTEE OR WARRANTY REGARDING: (A) CURRENT OR FUTURE VALUE OR MARKET PRICE VOLATILITY OF ANY ASSETS; OR (B) THE COMPATIBILITY OF ANY SOFTWARE OR HARDWARE WITH THE SITE OR SERVICES.</p></li>
                            <li><p><strong>No Responsibility for Third-Party Provider.</strong> BlocPal IS NOT RESPONSIBLE FOR THE ACTS OR OMISSIONS OF, OR FOR THE FAILINGS OF, ANY THIRD-PARTY PROVIDER OF ANY SERVICE, NETWORK, SOFTWARE OR HARDWARE, INCLUDING BUT NOT LIMITED TO INTERNET SERVICE PROVIDERS, HOSTING SERVICES UTILIZED BY BlocPal AND TELECOMMUNICATIONS PROVIDERS.</p></li>
                            <li><p><strong>No Legal, Tax or Investment Advice.</strong> BlocPal DOES NOT PROVIDE, AND THE SITE AND SERVICES DO NOT CONSTITUTE, LEGAL, TAX OR INVESTMENT ADVICE. YOU ARE SOLELY RESPONSIBLE FOR OBTAINING ADVICE FROM QUALIFIED PROFESSIONALS IN YOUR JURISDICTION TO THE EXTENT YOU DEEM NECESSARY PRIOR TO USING THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT ALL ORDERS AND TRADES YOU MAKE USING THE SERVICES ARE UNSOLICITED AND WE DO NOT CONDUCT SUITABILITY REVIEWS OF ORDERS OR TRADES PLACED BY OUR USERS.</p></li>
                            <li><p><strong>No Implied Warranty.</strong> NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM BlocPal OR THROUGH OR FROM THE SITE OR SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.</p></li>
                            <li><p><strong>Other Jurisdictions.</strong> THE SITE AND SERVICES ARE OFFERED AND CONTROLLED BY BlocPal FROM ITS FACILITIES IN BRITISH COLUMBIA, CANADA. BlocPal makes no representations that THE SITE OR SERVICES ARE appropriate or available for use in other locations. Those who access or use THE SITE OR SERVICES from other jurisdictions do so at their own volition and are responsible for compliance with local law.</p></li>
                        </ol></li>
                    <li><p>DISCLAIMER OF LIABILITY</p>
                        <ol type={1} start={101}>
                            <li><p>EXCEPT TO THE EXTENT REQUIRED BY LAW, NEITHER WE NOR ANY BlocPal SERVICE PROVIDER SHALL BE LIABLE TO YOU, WHETHER IN CONTRACT OR TORT, FOR ANY PUNITIVE, SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL, OR SIMILAR DAMAGES (EVEN IF WE AND/OR ANY BlocPal SERVICE PROVIDER HAVE BEEN ADVISED OF THE POSSIBILITY THEREOF) IN CONNECTION WITH THIS AGREEMENT, YOUR USE OR ATTEMPTED USE OF THE SITE AND SERVICES, OR ANY OF THE INFORMATION, SERVICES OR TRANSACTIONS CONTEMPLATED BY THIS USER AGREEMENT.</p></li>
                            <li><p>IN NO EVENT SHALL WE OR ANY BlocPal SERVICE PROVIDER BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR INJURY RESULTING DIRECTLY OR INDIRECTLY FROM YOUR USE OF THE SITE AND SERVICES OR ANY SERVICES PROVIDED BY US OR ANY BlocPal SERVICE PROVIDER, INCLUDING, BUT NOT LIMITED TO, ANY LOSS CAUSED IN WHOLE OR IN PART BY ANY INACCURACIES OR INCOMPLETENESS, DELAYS, INTERRUPTIONS, ERRORS OR OMISSIONS, INCLUDING, BUT NOT LIMITED TO, THOSE ARISING FROM OUR NEGLIGENCE OR THE NEGLIGENCE OF ANY BlocPal SERVICE PROVIDER OR CONTINGENCIES BEYOND OUR OR ANY BlocPal SERVICE PROVIDER’S CONTROL IN PROCURING, COMPILING, INTERPRETING, COMPUTING, REPORTING, OR DELIVERING THE SITE AND SERVICES, THE SERVICES THEREON OR THE INFORMATION THEREIN. IN NO EVENT SHALL WE OR ANY BlocPal SERVICE PROVIDER BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON, OR IN CONNECTION WITH YOUR USE OF THE SITE AND SERVICES, THE SERVICES THEREON OR THE INFORMATION THEREIN.</p></li>
                            <li><p>THIS LIMITATION ON LIABILITY INCLUDES, BUT IS NOT LIMITED TO ANY DAMAGE OR INTERRUPTIONS CAUSED BY ANY COMPUTER VIRUSES, SPYWARE, SCAMWARE, TROJAN HORSES, WORMS, OR OTHER MALWARE THAT MAY AFFECT YOUR COMPUTER OR OTHER EQUIPMENT, OR ANY PHISHING, SPOOFING, DOMAIN TYPOSQUATTING, OR OTHER ATTACKS, FAILURE OF MECHANICAL OR ELECTRONIC EQUIPMENT OR COMMUNICATION LINES, TELEPHONE OR OTHER CONNECTIVITY PROBLEMS (E.G. YOU CANNOT ACCESS YOUR INTERNET SERVICE PROVIDER), UNAUTHORIZED ACCESS, THEFT, OPERATOR ERRORS, STRIKES OR OTHER LABOR PROBLEMS, OR ANY FORCE MAJEURE. NEITHER WE NOR ANY BlocPal SERVICE PROVIDER GUARANTEE CONTINUOUS, UNINTERRUPTED, OR SECURE ACCESS TO THE SITE AND SERVICES.</p></li>
                            <li><p>IF THIS DISCLAIMER OF LIABILITY SECTION IS DEEMED TO CONFLICT WITH ANY OTHER SECTION OF THIS AGREEMENT, THIS DISCLAIMER OF LIABILITY SECTION SUPERSEDES SUCH OTHER SECTION.</p></li>
                            <li><p>YOU AGREE THAT, UNDER NO LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO NEGLIGENCE, BREACH OF WARRANTY OR CONDITION, BREACH OF CONTRACT OR TORT, WILL BlocPal OR ITS OWNERS, OFFICERS, DIRECTORS, AFFILIATES, CONTRACTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY ACTING ON YOUR BEHALF FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES OR DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES OR THE COST OF ANY SUBSTITUTE EQUIPMENT, FACILITIES OR SERVICES (EVEN IF BlocPal HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING FROM OR RELATING TO THIS AGREEMENT OR YOUR USE OF OR YOUR INABILITY TO USE THE SITE OR SERVICES. BlocPal’S TOTAL AGGREGATE LIABILITY FROM ANY AND ALL CLAIMS UNDER THIS AGREEMENT IS LIMITED TO THE LESSER OF: (A) CDN $1,000.00; AND (B) THE TOTAL FEES YOU PAID TO BlocPal IN THE <strong>THIRTY (30) DAYS</strong> IMMEDIATELY PRECEDING THE OCCURRENCE OF LOSS OR DAMAGE. TO THE EXTENT ANY PROVINCE, STATE OR JURISDICTION DOES NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, BlocPal’S LIABILITY IN SUCH PROVINCE, STATE OR JURISDICTION WILL BE LIMITED TO THE FURTHEST EXTENT PERMITTED BY LAW. YOU FURTHER AGREE THAT THE FOREGOING LIMITATIONS WILL APPLY WITH RESPECT TO THIRD PARTY LIABILITY OF ANY KIND.</p></li>
                        </ol></li>
                    <li><p>WAIVER OF JURY TRIAL AND CLASS ACTION RIGHTS</p></li>
                </ol>
                <p>WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THE SITE, THE SERVICES AND/OR THIS AGREEMENT: (A) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND (B) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE. YOU ALSO AGREE NOT TO PARTICIPATE IN CLAIMS BROUGHT IN A PRIVATE ATTORNEY GENERAL OR REPRESENTATIVE CAPACITY, OR CONSOLIDATED CLAIMS INVOLVING ANOTHER PERSON’S ACCOUNT, IF WE ARE A PARTY TO THE PROCEEDING.</p>
                <ol type={1} start={47}>
                    <li><p>LIMITATION OF TIME</p></li>
                </ol>
                <p>You agree that you will not bring a claim under or related to this Agreement more than twelve (12) months from when your claim first arose.</p>
                <ol type={1} start={48}>
                    <li><p>REASONABLE CARE</p>
                        <ol type={1} start={106}>
                            <li><p><strong>Reasonable Care.</strong> We agree to take reasonable care and use commercially reasonable efforts in executing our responsibilities to you pursuant this Agreement, or such higher care where required by law or as specified by this Agreement.</p></li>
                            <li><p><strong>Failure or Delay, Negligence.</strong> You agree that we cannot be held responsible for any failure or delay to act by any BlocPal Service Provider, or any other participant that is within the time limits permitted by this Agreement or prescribed by law, or that is caused by your negligence.</p></li>
                            <li><p><strong>System Failure, Downtime.</strong> You further agree that neither we nor any BlocPal Service Provider can be held responsible for any erroneous order or trade or “System Failure” (defined as a failure of any computer hardware or software used by BlocPal, a BlocPal Service Provider, or any telecommunications lines or devices used by BlocPal or a BlocPal Service Provider), or Downtime, which prevents us from fulfilling our obligations under this Agreement, provided that we or the relevant BlocPal Service Provider (as applicable) used commercially reasonable efforts to prevent or limit such Erroneous Orders, Erroneous Trades, System Failures, or Downtime. You also agree that neither we nor any BlocPal Service Provider can be held responsible for any other circumstances beyond our or such BlocPal Service Provider’s (as the case may be) reasonable control.</p></li>
                            <li><p><strong>Reliance upon Rule or Regulation.</strong> In addition, you agree and understand that any act or omission made by us or any BlocPal Service Provider in reliance upon or in accordance with any provision of any rule or regulation of a provincial or federal agency having jurisdiction over such party, shall constitute reasonable care and be commercially reasonable.</p></li>
                            <li><p><strong>Factors Outside of BlocPal Control.</strong> We use commercially reasonable efforts to provide you with a reliable and secure platform, including an electronic exchange and custody service. From time to time, interruptions, errors or other deficiencies in service may occur due to a variety of factors, some of which are outside of our control. These factors can contribute to delays, errors in service, or system outages. You may experience difficulties in accessing your User Account, withdrawing your fiat currency or Assets, depositing your fiat currency or Assets, and/or placing and/or canceling Orders.</p></li>
                        </ol></li>
                    <li><p>FORCE MAJEURE</p></li>
                </ol>
                <p>You agree and understand that in no event shall we or any BlocPal Service Provider be liable for delays, failure in performance or interruption of service which result directly or indirectly from any cause or condition beyond our or any BlocPal Service Provider’s reasonable control, including but not limited to, any delay or failure due to any act of God, act of civil or military authorities, act of terrorists, civil disturbance, war, strike or other labor dispute, fire, interruption in telecommunications or Internet services or network provider services, failure of equipment and/or software, other catastrophe or any other occurrence which is beyond our reasonable control.</p>
                <ol type={1} start={50}>
                    <li><p>INDEMNITIES</p>
                        <ol type={1} start={111}>
                            <li><p><strong>Indemnification.</strong> In no event shall we or any BlocPal Service Provider be liable for any act, omission, error of judgment, or loss suffered by you in connection with this Agreement or your use or attempted use of BlocPal. You agree to indemnify and hold us and all BlocPal Service Providers harmless from or against any or all liabilities, obligations, losses, damages, penalties, actions, judgments, suits, costs, expenses (including reasonable attorneys’ fees), claims, disbursements or actions of any kind and injury (including death) arising out of or relating to your use of BlocPal or our and any BlocPal Service Provider’s performance or non-performance of duties to you.</p></li>
                            <li><p><strong>Waiver of Statute.</strong> If you are a resident of a jurisdiction subject to a statute which provides that a general release does not extend to claims which the creditor does not know or suspect to exist in his or her favour at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor, or other comparable or similar doctrine, then by using the Site and Services you hereby expressly waive any such statute or doctrine.</p></li>
                            <li><p><strong>Claims.</strong> You agree to indemnify, defend and hold harmless BlocPal, and its subsidiaries, affiliates, co-branders, all third-party advertisers, technology providers, service providers or other partners, and each of their respective officers, directors, agents, shareholders, employees and representatives (together, the “<strong>Indemnified Parties</strong>”), from and against any third party claim, demand, loss, damage, cost or liability (including reasonable attorneys’ fees) (collectively and individually, “<strong>Claims</strong>”) incurred by or made against the Indemnified Parties in connection with any Claims arising out of or relating to this Agreement, the Site or the Services, including but without limitation in relation to: (a) your use, non-use or misuse of or connection to the Site or the Services, including without limitation your Profile Information; (b) your violation or alleged violation of this Agreement; and (c) your violation of any rights, including intellectual property rights, of a third party and otherwise as set out herein. BlocPal reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify BlocPal and you agree to cooperate with BlocPal’s defense of these Claims. You agree not to settle any matter without the prior written consent of BlocPal. BlocPal will use reasonable efforts to notify you of any such Claims upon becoming aware of them.]</p></li>
                        </ol></li>
                    <li><p>LEGAL COSTS</p></li>
                </ol>
                <p>You agree to indemnify us and any BlocPal Service Provider for actual, reasonable legal costs and expenses directly related to your Account or any Related Account that are a result of any regulatory inquiry, legal action, litigation, dispute, or investigation whether such situations occur or are anticipated, that arise or relate to you or your use of the Site or the Services. As a result, we will be entitled to charge your Account for such costs without notice, including for legal and enforcement related costs that we incur. Any withholding will last for a period of time that is reasonably necessary to resolve any regulatory or legal issue at hand, and we may place any amounts garnered from you in a separate account and will pay you the remaining balance after any noted issue has been resolved. Furthermore, you agree that where such anticipated or actual actions relate to a specific asset in your Account, that asset may not be transferred out of said account until the matter is resolved. We will provide copies of all invoices on request.</p>
                <ol type={1} start={52}>
                    <li><p>SEPARATELY NEGOTIATED ARRANGEMENTS</p>
                        <ol type={1} start={114}>
                            <li><p><strong>Acknowledgment of Separately Negotiated Arrangements.</strong> From time to time, we may, subject to Applicable Laws and as determined by us in our sole discretion, enter into separately negotiated arrangements with certain BlocPal Users pursuant to a confidential side letter or similar agreement that supplements certain of the economic or other terms of this Agreement, in each case solely as applicable to that BlocPal User’s relationship with and use of the Site and the Services. Such arrangements may involve, among other matters: (i) different economic, funding, and fee terms; (ii) the ability to receive additional or customized market information not generally available to other BlocPal Users (subject to the terms of the BlocPal API Agreement); or (iii) preferred access to BlocPal customer service resources, or (iv) an agreement to permit representatives of such BlocPal User to serve on any customer representative bodies or advisory committees that we may form.</p></li>
                            <li><p><strong>No Effect On User Agreement.</strong> Any such separately negotiated arrangements will be entered into based on factors or considerations determined by us to be relevant in our sole discretion, and arrangements or terms offered to one BlocPal User generally are not available to all, or even any, other BlocPal Users. Examples of factors we may consider in deciding whether to negotiate customized terms via a confidential side letter or similar agreement include but are not limited to: (i) whether a BlocPal User plans to or is expected to conduct a significant or high volume of trading activity on our Exchange, and (ii) whether a BlocPal User is subject to specific and unique legal, tax, or regulatory obligations or requirements. You agree and understand that neither you nor any other BlocPal User shall have any right or legal recourse against us or any BlocPal User that receives additional or different rights or terms as a result of a separately negotiated arrangement. You further agree and understand that the validity or enforceability of the terms and conditions of this Agreement entered into by and between you and BlocPal shall not be affected by the existence of or the terms and conditions of any separately negotiated arrangement with any BlocPal User.</p></li>
                        </ol></li>
                    <li><p>ASSIGNMENT</p></li>
                </ol>
                <p>This Agreement, or your rights and obligations hereunder, may not be transferred or assigned by you under any circumstances. Any attempted transfer or assignment by you in violation hereof shall be null and void. For the purposes of this Agreement, an assignment includes any change of control. We may assign this Agreement without restriction. This Agreement shall be binding upon and enure to the benefit of the parties hereto, their respective successors, and BlocPal’s assigns.</p>
                <ol type={1} start={54}>
                    <li><p>RELATIONSHIP OF THE PARTIES</p></li>
                </ol>
                <p>You agree and understand that you and BlocPal are acting as independent contracting parties and nothing in this Agreement shall be deemed to constitute, create, imply, give effect to, or otherwise recognize a partnership, employment, joint venture, association or other business entity, fiduciary or other relationship of any kind, and the rights and obligations of the parties shall be limited to those expressly set forth herein. Except for the indemnity and exculpation provisions herein, nothing expressed in, mentioned in, or implied from this Agreement is intended or shall be construed to give any person other than the parties hereto any legal or equitable right, remedy, or claim under or in respect to this Agreement to enforce any of its terms which might otherwise be interpreted to confer such rights to such persons, and this Agreement and all representations, warranties, covenants, conditions and provisions hereof are intended to be and are for the exclusive benefit of you and us.</p>
                <ol type={1} start={55}>
                    <li><p>INJUNCTIVE RELIEF</p></li>
                </ol>
                <p>You agree and understand that your obligations and the obligations of each User set forth in this Agreement are necessary and reasonable in order to protect us and our business. You expressly agree that due to the unique nature of our business, monetary damages would be inadequate to compensate us for any breach by you of your covenants and agreements or any misrepresentation or inaccuracy of any of your representations or warranties set forth in this Agreement. Accordingly, you agree and understand that any such violation or threatened violation shall cause irreparable injury to the us and that, in addition to any other remedies that may be available, in law, in equity or otherwise, we shall be entitled to obtain injunctive relief against any threatened breach of this Agreement or your continuation of any such breach, without the necessity of proving actual damages.</p>
                <ol type={1} start={56}>
                    <li><p>BUSINESS CHANGES</p></li>
                </ol>
                <p>In the event that BlocPal’s assets are sold or we are acquired by or merged with a third party entity, or another change of control event occurs, we reserve the right, in any of these circumstances, to transfer or assign the information we have collected from you as part of such merger, acquisition, sale, or other change of control.</p>
                <ol type={1} start={57}>
                    <li><p>ENGLISH LANGUAGE CONTROLS</p></li>
                </ol>
                <p>Notwithstanding any other provision of this User Agreement, any translation of this User Agreement is provided for your convenience. The meanings of terms, conditions, and representations herein are subject to their definitions and interpretations in the English language.</p>
                <ol type={1} start={58}>
                    <li><p>NON-WAIVER OF RIGHTS</p></li>
                </ol>
                <p>This Agreement shall not be construed to waive rights that cannot be waived under Applicable Laws, including applicable state money transmission laws in the state where you are located. In addition, our failure to insist upon your strict compliance with any term or provision of this Agreement shall not be construed as a waiver for you to not comply with such term or provision.</p>
                <ol type={1} start={59}>
                    <li><p>SEVERABILITY</p></li>
                </ol>
                <p><a href="https://www.lawinsider.com/clause/severability">If any</a>&nbsp;provision or provisions of this Agreement shall be held to be invalid, illegal or unenforceable for any reason whatsoever: (a) the validity, legality and enforceability of the remaining provisions of this Agreement (including, without limitation, each portion of any Section, paragraph or sentence of this Agreement containing any such provision held to be invalid, illegal or unenforceable, that is not itself invalid, illegal or unenforceable) shall not in any way be affected or impaired thereby and shall remain enforceable to the fullest extent permitted by law; (b) such provision or provisions shall be deemed reformed to the extent necessary to conform to applicable law and to give the maximum effect to the intent of the parties hereto; and (c) to the fullest extent possible, the provisions of this Agreement (including, without limitation, each portion of any Section, paragraph or sentence of this Agreement containing any such provision held to be invalid, illegal or unenforceable, that is not itself invalid, illegal or unenforceable) shall be construed so as to give effect to the intent manifested thereby.</p>
                <ol type={1} start={60}>
                    <li><p>DISPUTE RESOLUTION</p>
                        <ol type={1} start={116}>
                            <li><p><strong>Governing Law.</strong> If there is any dispute between you and BlocPal about, or involving, this Agreement, the Site or the Services, you hereby agree that the dispute will be governed by and construed in accordance with the laws of the Province of British Columbia, Canada, and the federal laws of Canada applicable therein without regard to its conflict of law provisions.</p></li>
                            <li><p><strong>No Applicability of UN Convention.</strong> The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</p></li>
                            <li><p><strong>Arbitration.</strong> All disputes arising out of or in connection with this Agreement will be referred to and finally resolved by arbitration under the rules of the British Columbia International Commercial Arbitration Centre. The appointing authority will be the British Columbia International Commercial Arbitration Centre. The case will be adjudicated by a single arbitrator and will be administered by the British Columbia International Commercial Arbitration Centre in accordance with its applicable rules. The place of arbitration will be British Columbia, Canada. The language of the arbitration will be English. Notwithstanding the foregoing, BlocPal may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction and you agree that this Agreement is specifically enforceable by BlocPal through injunctive relief and other equitable remedies without proof of monetary damages.</p></li>
                            <li><p><strong>No Waiver of Rights.</strong> You agree that if BlocPal does not exercise or enforce any legal right or remedy which is contained in the Agreement (or which BlocPal has the benefit of under any applicable law), this will not be taken to be a formal waiver of BlocPal’s rights and that those rights or remedies will still be available to BlocPal.</p></li>
                        </ol></li>
                    <li><p>SURVIVAL</p>
                        <p>Section 2 (Eligibility), Section 13 (Communications), Section 16 (Account Closure and Cancellation by User), Section 17 (Account Suspension), Section 18 (Account Investigations), Section 19 (Account Termination), Section 20 (Right of Offset), Section 21 (Unclaimed Funds), Section 22 (Legal Process), Section 35 (Fees), Section 38 (Recording / Recordkeeping), Section 39 (Intellectual Property Rights), Section 40 (Taxes), Seciton 44 (Disclaimer of Warranties), Section 45 (Disclaimer of Liability), Section 46 (Waiver of Jury Trial and Class Action Rights), Section 47 (Limitation of Time), Section 50 (Indemnity), Section 51 (Legal Costs) and Section 60 (Dispute Resolution) shall survive any termination or expiration of these terms.</p></li>
                    <li><p>ENTIRE AGREEMENT</p></li>
                </ol>
                <p>This Agreement is the entire agreement between us related to the subject matter in this Agreement. This Agreement replaces and supersedes any other prior or contemporaneous agreement, representation or discussion, oral or written, and may not be changed except in writing signed by us, regardless of whether or not the parties act under an unsigned “electronic” agreement or rely on such an unsigned agreement.</p>
                <ol type={1} start={63}>
                    <li><p>CODE OF CONDUCT</p></li>
                </ol>
                <p>We work hard to make BlocPal safe, secure, and compliant. To that end, we expect that you treat our employees, including our Customer Support Team, as you would like to be treated yourself: with respect. Any use of inappropriate or abusive language towards any of our employees is strictly prohibited. Examples include, but are not limited to, verbal threats, harassment, aggressive comments, or behavior that is disrespectful. This type of behavior is a direct violation of our Code of Conduct and this Agreement. If you engage in this type of behavior, we will politely ask you to stop. If you continue to engage in this type of behavior, we will terminate your Account.</p>
                <ol type={1} start={64}>
                    <li><p>CONTACTING BlocPal</p>
                        <ol type={1} start={120}>
                            <li><p>You may contact BlocPal by email at info@BlocPal.com.</p></li>
                            <li><p>When contacting support please provide all relevant information, including your BlocPal Username and transaction IDs of any related deposits. Although we make no representations or provide no warranties about the speed of response, we will get back to you as soon as possible.</p></li>
                            <li><p>We cannot agree to obligations of confidentiality or nondisclosure with regard to any unsolicited information you submit to us, regardless of the method or medium chosen. By submitting unsolicited information or materials to us or any other provider in connection with the Site or the Services, you or anyone acting on your behalf, agree that any such information or materials will not be considered confidential or proprietary.</p></li>
                            <li><p>We do not provide any facility for sending or receiving private or confidential electronic communications. You should not use the Site to transmit any communication for which you intend only you and the intended recipient(s) to read. Notice is hereby given that all messages and other content entered using the Site can and may be read by us, regardless of whether we are the intended recipients of such messages. Nevertheless, messages and other content will be accessible only by BlocPal and its representatives or third-party providers that reasonably need such access.</p></li>
                        </ol></li>
                </ol>
            </Content>
        );
    }
}


export default class TermsScreen extends React.Component {
    static propTypes = {
        location: PropTypes.object,
    };
    componentDidMount() {
        document.title = 'Terms of Service (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.location.pathname + this.props.location.search + this.props.location.hash,
            page_search: this.props.location.search,
            page_hash: this.props.location.hash,
        });
    }

    render() {
        return (
            <StandardPage>
                <TermsText />
            </StandardPage>
        );
    }

}
