import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled.div`
    background-color: transparent;
    color: #021747;
    text-align: center;
    font-size: ${props => props.fontSize ? props.fontSize : '25px'};
    font-weight: 700;
    font-family: Inter, sans-serif;
    margin: 5px;
`;

export default class MessageBold extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        fontSize: PropTypes.string,
    };

    render() {
        return (
            <Text>
                {this.props.children}
            </Text>
        );
    }
}
