import React from 'react';
import PropTypes from 'prop-types';
import dateFns from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Component as RouteButton } from '../../../../components/RouteButton';
import { Component as Spinner } from '../../../../components/Spinner';
import { Container as SubmitDepositInfo } from '../../../../components/SubmitDepositInfo';

import { ColWrapper, RowWrapper } from '../../../../components/Layout';
import { Component as CurrencyStaticPair } from '../../../../components/CurrencyStaticPair';

import { mobile, smallMobile } from '../../../../styles';
import { displayFormat } from '../../../../utils';

import doneIcon from '../../../../images/ic-done-md.svg';
import loadIcon from '../../../../images/ic-refresh-md.svg';
import copyIcon from '../../../../images/ic-copy.svg';


const BlockWrapper = styled.div`
  padding: 0 48px;
  border-bottom: 1px solid rgba(172,181,188,0.25);

  ${mobile`
    padding: 0 40px;
  `};

  ${smallMobile`
    padding: 0 20px;
  `};
`;

const GreenCheckElement = styled.div`
    width: 24px;
    height: 24px;
`;

const StartTradeButton = styled(RouteButton).attrs({
    bsStyle: 'blue'
})`

    border-radius: 5px;
    text-align: center;
    display: block;

    a {
        border-radius: 5px;
        display: inline-flex;
        margin: 15px;
        padding: 10px 15px;
        width: 320px;
        box-sizing: border-box;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }
`;

const HeaderWrapper = styled(BlockWrapper)`
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;

  ${mobile`
    border: none;
  `};
`;

const TransactionStatus = styled.p`
  color: #4A4A4A;
  font-size: 24px;	font-weight: 600;
  line-height: 36px;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

const ValuesWrapper = styled(BlockWrapper)`
  padding-top: 5px;
  padding-bottom: 20px;
`;

const TransactionID = styled.p`
	color: #4A4A4A;
	font-size: 14px;
	line-height: 21px;
	margin: 0;
`;

const FreezValuesWrapper = styled(ValuesWrapper)`
  label {
	color: #9B9B9B;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.86px;
	line-height: 20px;
    padding-top: 5px;

	${mobile`
        font-size: 14px;
    `};
  }
`;

const CopyToClipboardSpan = styled.span`
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 0 5px;
`;

const FreezHeaderValuesWrapper = styled(ValuesWrapper)`
  padding-top: 20px;
  padding-bottom: 10px;

  label {
	color: #9B9B9B;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.86px;
	line-height: 20px;

	${mobile`
        font-size: 14px;
    `};
  }
`;

const DepRow = styled(RowWrapper)`
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 15px;

  ${mobile`
    flex-wrap: wrap;

    > div {
        width: 100%;
        flex: 1 0 50%;

        &:first-child {
            margin-bottom: 30px;
            flex: 0 1 100%;
        }
    }
  `};

  p {
      margin: 0;
      font-size: 14px;
  }

  span {
	color: #9B9B9B;
	font-size: 12px;
	line-height: 14px;
	width: 200px;
  }
`;

const Col = styled(ColWrapper)`
  width: 33%;
`;

const DateCol = styled(ColWrapper)`
  width: 66%;
`;

const Label = styled.label`
  font-size: 12px;
  text-transform: uppercase;
  color: #9B9B9B;
`;

const Hash = styled.p`
    margin: 0;
    width:95%;
    word-break: break-all;
	color: #4A4A4A;
	font-size: 13px;
	font-weight: 600;
	line-height: 15px;
`;

const TxId = styled.p`
    margin: 0;
    width:95%;
    word-break: break-all;
	color: #4A4A4A;
	font-size: 11px;
	font-weight: 600;
	line-height: 15px;
`;

const Status = styled.div`
    position: absolute;
    right: 15px;
    bottom: 15px;
    // bottom: 25px;
    text-align: right;
    font-size: 13px;
    color: #4A4A4A;
    max-width: 250px;
    padding-right: 15px;
    line-height: 15px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    img {
      position: absolute;
      right: 15px;
      top: 0px;
    }

    ${mobile`
        width: calc(100% - 80px);
        text-align: left;
        top: 43px;
        font-size: 16px;
        height: 70px;
        right: 0;
        left: 50px;
        align-items: flex-start;

        > div {
            align-self: flex-end;
        }

        span {
            display: none;
        }
    `};
`;

const MobileStatus = styled.div`
  display: none;
  color: #4A4A4A;
  font-size: 16px;
  margin-top: 30px;

  ${mobile`
    display: block;
  `}
`;

const DarkerText = styled.div`
  label {
    font-size: 14px;
    color: #000;
  }
`;

export default class TransactionCompleteStep extends React.Component {

    static propTypes = {
        status: PropTypes.string,
        transaction: PropTypes.object,
        currencyFromConfig: PropTypes.object,
        currencyToConfig: PropTypes.object,
        refundAddress: PropTypes.string,
        refundAmount: PropTypes.number,
        refundTxId: PropTypes.string,
        refundTs: PropTypes.number,
        proceedsAddress: PropTypes.string,
        withdrawalAddress: PropTypes.string,
        withdrawalAmount: PropTypes.number,
        withdrawalTxId: PropTypes.string,
        withdrawalTs: PropTypes.number,
        transactionId: PropTypes.string,
        rate: PropTypes.number,
        dayLimit: PropTypes.number,
        feeEstimate: PropTypes.number,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
    };

    state = {
        currentStep: 1,
        proceedsTxidCopied: false,
        refundTxidCopied: false,
        proceedsAddressCopied: false,
        refundAddressCopied: false,
        depositAddressCopied: false,
        proceedsTagCopied: false,
        refundTagCopied: false,
        depositTagCopied: false,
    };

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    onCopyProceedsTxid() {
        this.setState({
            proceedsTxidCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                proceedsTxidCopied: false,
            });
        }, 1500);
    }

    onCopyRefundTxid() {
        this.setState({
            refundTxidCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                refundTxidCopied: false,
            });
        }, 1500);
    }

    onCopyProceedsAddress() {
        this.setState({
            proceedsAddressCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                proceedsAddressCopied: false,
            });
        }, 1500);
    }

    onCopyRefundAddress() {
        this.setState({
            refundAddressCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                refundAddressCopied: false,
            });
        }, 1500);
    }

    onCopyDepositAddress() {
        this.setState({
            depositAddressCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                depositAddressCopied: false,
            });
        }, 1500);
    }

    onCopyProceedsTag() {
        this.setState({
            proceedsTagCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                proceedsTagCopied: false,
            });
        }, 1500);
    }

    onCopyRefundTag() {
        this.setState({
            refundTagCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                refundTagCopied: false,
            });
        }, 1500);
    }

    onCopyDepositTag() {
        this.setState({
            depositTagCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                depositTagCopied: false,
            });
        }, 1500);
    }

    render() {

        const {
            status,
            refundAddress,
            refundAmount,
            refundTxId,
            refundTs,
            proceedsAddress,
            withdrawalAddress,
            withdrawalAmount,
            withdrawalTxId,
            withdrawalTs,
            currencyToConfig,
            currencyFromConfig,
            rate,
            transactionId,
            transaction,
            dayLimit,
            feeEstimate,
            minValue,
            maxValue,
        } = this.props;

        // TODO ... before we integrate IOTA we need to add isAddressReusable to the coinConfig we get from the platform
        var isAddressReusable = true;

        return status === 'TRADE_INITIATED' || status === 'TRADE_CANCELED' || status === 'TRADE_DONE' || status === 'WITHDRAW_REQUESTED'  || status === 'WITHDRAW_FINISHED'  || status === 'WITHDRAW_FAILED' || status === 'REFUND_REQUESTED' || status === 'REFUND_FAILED' || status === 'REFUND_FINISHED' || status === 'QUICKTRADE_FINISHED' ? (
            <div>
                <HeaderWrapper>
                    <TransactionID>
                        <FormattedMessage
                            id="oct.trade_id"
                            defaultMessage="Trade ID: {id}"
                            values={{
                                id: transactionId
                            }}
                        />
                    </TransactionID>
                    <TransactionStatus>
                        {status === 'TRADE_DONE' ? (transaction.confCount >= transaction.targetConfCount ? 'Trade Complete - Withdrawal Pending' : 'Trade Complete - Deposit Still Confirming') : null}
                        {status === 'TRADE_INITIATED' ? 'Trade Initiated - Results Pending' : null}
                        {status === 'TRADE_CANCELED' ? 'Trade Cancelled - Refund Pending' : null}
                        {status === 'REFUND_REQUESTED' || status === 'REFUND_FAILED' ? 'Refund Pending' : null}
                        {status === 'WITHDRAW_REQUESTED' || status === 'WITHDRAW_FAILED' ? (transaction.confCount >= transaction.targetConfCount ? 'Trade Complete - Withdrawal Pending' : 'Trade Complete - Deposit Still Confirming') : null}
                        {status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'QUICKTRADE_FINISHED' ? 'QuickTrade Complete' : null}
                    </TransactionStatus>

                    { status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'QUICKTRADE_FINISHED' || transaction.confCount >= transaction.targetConfCount ? (
                        <React.Fragment>
                            <Status>
                                <GreenCheckElement>
                                    <img src={'/' + doneIcon} style={{width:'20px', height:'20px'}} alt=""/>
                                </GreenCheckElement>
                                <FormattedMessage
                                    id="oct.trade_details_sent"
                                    defaultMessage="A trade summary has been sent via email."
                                    tagName="span"
                                />
                            </Status>
                            <MobileStatus>
                                <FormattedMessage
                                    id="oct.trade_details_sent"
                                    defaultMessage="A trade summary has been sent via email."
                                    tagName="span"
                                />
                            </MobileStatus>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Status>
                                <Spinner />
                                <FormattedMessage
                                    id="oct.waiting_confirmation"
                                    defaultMessage="Withdrawal will process when deposit is confirmed. {a} of {b} confirmations received."
                                    values={{
                                        a : transaction.confCount,
                                        b : transaction.targetConfCount
                                    }}
                                    tagName="span"
                                />
                            </Status>
                            <MobileStatus>
                                <FormattedMessage
                                    id="oct.waiting_confirmation"
                                    defaultMessage="Withdrawal will process when deposit is confirmed. {a} of {b} confirmations received."
                                    values={{
                                        a : transaction.confCount,
                                        b : transaction.targetConfCount
                                    }}
                                    tagName="span"
                                />
                            </MobileStatus>
                        </React.Fragment>
                    )}
                </HeaderWrapper>

                <div>
                    <FreezHeaderValuesWrapper>
                        <CurrencyStaticPair
                            currencyFrom={currencyFromConfig.name}
                            currencyTo={currencyToConfig.name}
                            valueFrom={transaction.depositAmount - refundAmount}
                            valueTo={(transaction.targetAmount-feeEstimate) > 0 ? transaction.targetAmount-feeEstimate : 0 }
                            accurate={true}
                        />
                        <DepRow>
                            {transaction.tradeFiatAmount > 0 && (
                                <Col>
                                    <FormattedMessage
                                        id="oct.exchange_rate"
                                        defaultMessage="Final Exchange Rate"
                                        tagName="label"
                                    />
                                    <p>
                                        1 {currencyFromConfig.name} = {rate} {currencyToConfig.name}
                                    </p>
                                </Col>
                            )}
                            <Col>
                                <FormattedMessage
                                    id="oct.value_in_local_currency"
                                    defaultMessage="This trade / Trades today / Max daily"
                                    tagName="label"
                                />
                                <FormattedMessage
                                    id="oct.day_limit"
                                    defaultMessage="${spent} / ${spentToday} / ${limit} USD"
                                    tagName="p"
                                    values={{
                                        spent: displayFormat(transaction.tradeFiatAmount, 2),
                                        spentToday: displayFormat(transaction.dvSpend, 2),
                                        limit: dayLimit
                                    }}
                                />
                            </Col>
                        </DepRow>
                    </FreezHeaderValuesWrapper>
                    {withdrawalAmount > 0 ? (
                        <FreezValuesWrapper>
                            <DepRow>
                                <Col>
                                    <Label>
                                        <FormattedMessage
                                            id="oct.proceed"
                                            defaultMessage="Proceeds"
                                        />
                                    </Label>
                                    <p>
                                        {status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'TRADE_CANCELED' || status === 'QUICKTRADE_FINISHED' ? displayFormat(withdrawalAmount, 8) + ' ' + currencyToConfig.name : displayFormat((transaction.targetAmount-feeEstimate), 8) + ' ' + currencyToConfig.name }
                                    </p>
                                </Col>
                                <DateCol>
                                    <FormattedMessage
                                        id="oct.date"
                                        defaultMessage="Date"
                                        tagName="label"
                                    />
                                    {status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'TRADE_CANCELED' || status === 'QUICKTRADE_FINISHED' ? (
                                        <p>
                                            {dateFns.format(new Date(withdrawalTs), 'MMM DD, YYYY, hh:mm a')} &nbsp;
                                            ({formatToTimeZone(new Date(withdrawalTs), 'MMM DD, hh:mm [UTC]', {timeZone: 'UTC'})})
                                        </p>
                                    ) : (
                                        <p>
                                            Pending
                                        </p>
                                    )}
                                </DateCol>
                            </DepRow>
                            <FormattedMessage
                                id="oct.proceed_address"
                                defaultMessage="Proceeds Address"
                                tagName="label"
                            />
                            <CopyToClipboardSpan>
                                {this.state.proceedsAddressCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                    <CopyToClipboard onCopy={()=>this.onCopyProceedsAddress()} text={status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'TRADE_CANCELED' || status === 'QUICKTRADE_FINISHED' ? withdrawalAddress : proceedsAddress }>
                                        <span>
                                            <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                            &nbsp;Copy to clipboard
                                        </span>
                                    </CopyToClipboard>
                                )}
                            </CopyToClipboardSpan>
                            <Hash>
                                {status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'TRADE_CANCELED' || status === 'QUICKTRADE_FINISHED' ? withdrawalAddress : proceedsAddress }
                            </Hash>
                            {currencyToConfig.networks.find(networkConfig => networkConfig.name === transaction.targetAddr.network).addressTagName && transaction.targetAddr.tag ? (
                                <React.Fragment>
                                    <FormattedMessage
                                        id="oct.proceeds_tag_label"
                                        defaultMessage="Proceeds {name}"
                                        tagName="label"
                                        values={{
                                            label: currencyToConfig.label,
                                            name: currencyToConfig.networks.find(networkConfig => networkConfig.name === transaction.targetAddr.network).addressTagName.replace(' (Optional)', '')
                                        }}
                                    />
                                    <CopyToClipboardSpan>
                                        {this.state.proceedsTagCopied ? <span style={{color: 'red'}}> Copied! </span> : (
                                            <CopyToClipboard onCopy={()=>this.onCopyProceedsTag()} text={transaction.targetAddr.tag}>
                                                <span>
                                                    <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                    &nbsp;Copy to clipboard
                                                </span>
                                            </CopyToClipboard>
                                        )}
                                    </CopyToClipboardSpan>
                                    <Hash>
                                        {transaction.targetAddr.tag}
                                    </Hash>
                                </React.Fragment>
                            ) : null}
                            <FormattedMessage
                                id="oct.transaction_id"
                                defaultMessage="Transaction ID"
                                tagName="label"
                            />
                            { withdrawalTxId !== 'Pending' && (
                                <CopyToClipboardSpan>
                                    {this.state.proceedsTxidCopied ? <span style={{color: 'red'}}> Copied! </span> : (
                                        <CopyToClipboard onCopy={()=>this.onCopyProceedsTxid()} text={withdrawalTxId}>
                                            <span>
                                                <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                &nbsp;Copy to clipboard
                                            </span>
                                        </CopyToClipboard>
                                    )}
                                </CopyToClipboardSpan>
                            )}
                            <TxId>
                                {withdrawalTxId}
                            </TxId>
                        </FreezValuesWrapper>
                    ) : null}
                    {refundAmount > 0 ? (
                        <FreezValuesWrapper>
                            <DepRow>
                                <Col>
                                    <Label>
                                        <FormattedMessage
                                            id="oct.refund"
                                            defaultMessage="Refund"
                                        />
                                    </Label>
                                    <p>
                                        {status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'TRADE_CANCELED' || status === 'QUICKTRADE_FINISHED' ? displayFormat(refundAmount, 8) + ' ' + currencyFromConfig.name : displayFormat(transaction.refundAmount, 8) + ' ' + currencyFromConfig.name }
                                    </p>
                                </Col>
                                <DateCol>
                                    <FormattedMessage
                                        id="oct.date"
                                        defaultMessage="Date"
                                        tagName="label"
                                    />
                                    {status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'TRADE_CANCELED' || status === 'QUICKTRADE_FINISHED' ? (
                                        <p>
                                            {dateFns.format(new Date(refundTs), 'MMM DD, YYYY, hh:mm a')} &nbsp;
                                            ({formatToTimeZone(new Date(refundTs), 'MMM DD, hh:mm [UTC]', {timeZone: 'UTC'})})
                                        </p>
                                    ) : (
                                        <p>
                                            Pending
                                        </p>
                                    )}
                                </DateCol>
                            </DepRow>
                            <FormattedMessage
                                id="oct.refund_address"
                                defaultMessage="Refund Address"
                                tagName="label"
                            />
                            <CopyToClipboardSpan>
                                {this.state.refundAddressCopied ? <span style={{color: 'red'}}> Copied! </span> : (
                                    <CopyToClipboard onCopy={()=>this.onCopyRefundAddress()} text={refundAddress}>
                                        <span>
                                            <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                            &nbsp;Copy to clipboard
                                        </span>
                                    </CopyToClipboard>
                                )}
                            </CopyToClipboardSpan>
                            <Hash>
                                {refundAddress}
                            </Hash>
                            {currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.refundAddr.network).addressTagName && transaction.refundAddr.tag ? (
                                <React.Fragment>
                                    <FormattedMessage
                                        id="oct.refund_tag_label"
                                        defaultMessage="Refund {name}"
                                        tagName="label"
                                        values={{
                                            label: currencyFromConfig.label,
                                            name: currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.refundAddr.network).addressTagName.replace(' (Optional)', ''),
                                        }}
                                    />
                                    <CopyToClipboardSpan>
                                        {this.state.refundTagCopied ? <span style={{color: 'red'}}> Copied! </span> : (
                                            <CopyToClipboard onCopy={()=>this.onCopyRefundTag()} text={transaction.refundAddr.tag}>
                                                <span>
                                                    <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                    &nbsp;Copy to clipboard
                                                </span>
                                            </CopyToClipboard>
                                        )}
                                    </CopyToClipboardSpan>
                                    <Hash>
                                        {transaction.refundAddr.tag}
                                    </Hash>
                                </React.Fragment>
                            ) : null}
                            <FormattedMessage
                                id="oct.transaction_id"
                                defaultMessage="Transaction ID"
                                tagName="label"
                            />
                            { refundTxId !== 'Pending' && (
                                <CopyToClipboardSpan>
                                    {this.state.refundTxidCopied ? <span style={{color: 'red'}}> Copied! </span> : (
                                        <CopyToClipboard onCopy={()=>this.onCopyRefundTxid()} text={refundTxId}>
                                            <span>
                                                <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                &nbsp;Copy TxID to clipboard
                                            </span>
                                        </CopyToClipboard>
                                    )}
                                </CopyToClipboardSpan>
                            )}
                            <TxId>
                                {refundTxId}
                            </TxId>
                        </FreezValuesWrapper>
                    ) : null}

                    <FreezValuesWrapper>
                        <DepRow>
                            <Col>
                                <Label>
                                    <FormattedMessage
                                        id="oct.deposit_received"
                                        defaultMessage="Deposit Received"
                                    />
                                </Label>
                                <p>
                                    {transaction.depositAmount} {currencyFromConfig.name}
                                </p>
                            </Col>
                            <DateCol>
                                <FormattedMessage
                                    id="oct.date"
                                    defaultMessage="Date"
                                    tagName="label"
                                />
                                <p>
                                    {dateFns.format(new Date(transaction.startTs), 'MMM DD, YYYY, hh:mm a')} &nbsp;
                                    ({formatToTimeZone(new Date(transaction.startTs), 'MMM DD, hh:mm [UTC]', {timeZone: 'UTC'})})
                                </p>
                            </DateCol>
                        </DepRow>

                        <FormattedMessage
                            id="oct.deposit_address"
                            defaultMessage="Deposit Address"
                            tagName="label"
                        />
                        <CopyToClipboardSpan>
                            {this.state.depositAddressCopied ? <span style={{color: 'red'}}> Copied! </span> : (
                                <CopyToClipboard onCopy={()=>this.onCopyDepositAddress()} text={transaction.depositAddr.address}>
                                    <span>
                                        <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                        &nbsp;Copy to clipboard
                                    </span>
                                </CopyToClipboard>
                            )}
                        </CopyToClipboardSpan>
                        <Hash>
                            {transaction.depositAddr.address}
                        </Hash>
                        {currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.depositAddr.network).addressTagName && transaction.depositAddr.tag ? (
                            <React.Fragment>
                                <FormattedMessage
                                    id="oct.deposit_tag_label"
                                    defaultMessage="Deposit {name}"
                                    tagName="label"
                                    values={{
                                        label: currencyFromConfig.label,
                                        name: currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.depositAddr.network).addressTagName.replace(' (Optional)', ''),
                                    }}
                                />
                                <CopyToClipboardSpan>
                                    {this.state.depositTagCopied ? <span style={{color: 'red'}}> Copied! </span> : (
                                        <CopyToClipboard onCopy={()=>this.onCopyDepositTag()} text={transaction.depositAddr.tag}>
                                            <span>
                                                <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                &nbsp;Copy to clipboard
                                            </span>
                                        </CopyToClipboard>
                                    )}
                                </CopyToClipboardSpan>
                                <Hash>
                                    {transaction.depositAddr.tag}
                                </Hash>
                            </React.Fragment>
                        ) : null}
                    </FreezValuesWrapper>

                    <FreezValuesWrapper>
                        { isAddressReusable ? (
                            <React.Fragment>
                                <DarkerText>
                                    <FormattedMessage
                                        id="oct.reusable_deposit"
                                        defaultMessage="This deposit address is REUSABLE. Deposit again and a new trade will be started automatically."
                                        tagName="label"
                                    />
                                </DarkerText>
                                <SubmitDepositInfo
                                    addressObj={transaction.depositAddr}
                                    tagLabel={currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.depositAddr.network).addressTagName}
                                    minAmount={minValue}
                                    maxAmount={maxValue}
                                    coinConfig={currencyFromConfig}
                                />
                                <DarkerText>
                                    <FormattedMessage
                                        id="oct.new_id_emailed_when_deposit_detected"
                                        defaultMessage="A new QuickTrade ID will be emailed to you whenever a deposit to this address is detected."
                                        tagName="label"
                                    />
                                </DarkerText>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <br/>
                                <FormattedMessage
                                    id="oct.not_reusable_deposit"
                                    defaultMessage="{depositCurrency} addresses are NOT safe to reuse. Please do not deposit into this address again."
                                    tagName="label"
                                    values={{
                                        depositCurrency: transaction.depositAddr.asset
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </FreezValuesWrapper>
                </div>
                {status === 'REFUND_FINISHED' || status === 'WITHDRAW_FINISHED' || status === 'QUICKTRADE_FINISHED' && (
                    <StartTradeButton to="/quicktrade">
                        <img src={'/' + loadIcon} alt=""/>
                        <FormattedMessage
                            id="oct.new_trade"
                            defaultMessage="Start a new trade from the beginning"
                        />
                    </StartTradeButton>
                )}

            </div>
        ) : null;
    }

}
