import styled from 'styled-components';
import { mobile } from '../../styles';

export const RowWrapper = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    > :last-child {
      margin-right: 0;
    }
`;

export const FlexRowWithMobileWrap = styled(RowWrapper)`
    flex-flow: row nowrap;

    ${mobile`
        flex-flow: row wrap-reverse;
        justify-content: center;
    `};
`;

export const FlexRowWithWrap = styled(RowWrapper)`
    flex-flow: row wrap-reverse;

    ${mobile`
        justify-content: center;
    `};
`;

export const ColWrapper = styled.div`
    display: flex;
    flex-flow: column;
`;
