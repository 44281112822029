import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dateFns from 'date-fns';
import DropdownMenu from 'react-dd-menu';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

import copyIcon from '../../../../images/ic-copy.svg';
import { displayFormat } from '../../../../utils';
import { mobile } from '../../../../styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import { PortfolioTitle } from '../../../../commonStyles/GeneralStyles';


const ActivityWrapper = styled.div`
  background-color: #fff;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  width: 95%;
`;

const Table = styled.div`
  background-color: ${props => props.isFrozen ? '#02174708' : '#fff'};
  display: table;
  width: 100%;
  border-bottom: 1px solid #eee;

  // margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Header = styled.div`
  display: table-row;

  >div {
      padding: 5px 10px;
    }
`;

const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  width: 25%;
`;

const NarrowCell = styled.div`
  display: table-cell;
  white-space: nowrap;
  width: 1%;

  ${mobile`
    display: none;
    padding: 0;
  `};

`;

const QuadCell = styled(Cell)`
  overflow-x: visible;
  text-align: left;
  max-width: 40px;
  font-size: 14px;
`;

const QuadCellSmall = styled(Cell)`
  overflow-x: visible;
  text-align: left;
  max-width: 40px;
  font-size: 12px;
`;

const BreakWord = styled.div`
  width: 650px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  ${mobile`
    width: 320px;
  `};
`;

const LastCell = styled(Cell)`
  width: 34%;
  text-align: right;

  span {
    color: #ef8632;
    cursor: pointer;
  }

  ${mobile`
    display: none;
    padding: 0;
  `};
`;

const StatusCell = styled(Cell)`
  ${mobile`
    display: none;
    padding: 0;
  `};
`;


const TableHeader = styled(Header)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

    color: #ACB5BC;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.71px;
    line-height: 20px;

    >div {
      padding: 5px 10px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;
    }
`;

const TableRow = styled.div`
  display: table-row;
  cursor: pointer;

  &:nth-child(2n) {
    // background-color: ${props => props.isFrozen ? 'transparent' : '#02174708'};
    background-color: #02174708;
  }

  &:hover {
      background-color: #ef863214;
  }

  &:nth-child(2n):hover {
      background-color: #ef863214;
  }

  >div {
    padding: 5px 10px;
  }
`;

const SmallTableRow = styled.div`
  display: table-row;

  >div {
    padding: 5px 5px;

    ${mobile`
      padding-left: 20px;
    `};
  }
`;

const MobileOnlySmallTableRow = styled.div`
  display: none;

  >div {
    padding-left: 20px;
  }

  ${mobile`
    display: table-row;
  `};
`;

const DateCell = styled(LastCell)`
	color: #4A4A4A;
	font-size: 12px;
	line-height: 21px;
`;

const TypeCell = styled(Cell)`
    text-transform: capitalize;
`;

const Pagination = styled.div`
  padding: 10px 20px;
`;

const PagingPuttons = styled.div`
	height: 40px;
	width: 100px;
	border: 2px solid #ef8632;
	border-radius: 5px;
	display: inline-flex;
	align-items: stretch;

	span {
	  width: 50%;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  color: #ef8632;
	  cursor: pointer;

	  &:first-child {
	    border-right: 1px solid #ef8632;
	  }
	}
 `;

const PageInput = styled.input`
	height: 40px;
	width: 55px;
	border: 1px solid #CFCFCF;
	border-radius: 3px;
	background-color: #FFFFFF;
	margin-left: 30px;
	margin-right: 10px;
	text-align: center;
	font-size: 15px;
`;

const OperationTypeCell = styled(Cell)`
  display: block;
  width: 60px;
`;

/*
const TitleCell = styled(Cell)`
	color: #9B9B9B;
	font-size: 24px;
	line-height: 36px;
`;

const LongTitleCell = styled(TitleCell)`
  display: block;
  width: 250px;
`;
*/

const CopyToClipboardSpan = styled.span`
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 0 5px;
`;

const DownloadCsvSpan = styled.span`
    color: #ef8632;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 0 15px;
`;

const StyledMenu = styled(DropdownMenu)`
  display: inline-block;
  margin-left: 5px;
  position: relative;

  >span {
    cursor: pointer;
    color: #ef8632;
    text-transform: capitalize;
  }

  .dd-menu-items {
    position: absolute;
    width: 170px;
    background-color:#fff;
    box-shadow: 0 0 2px #ddd;

    ul {
      margin: 0;
      padding: 0;

      span {
        display: block;
        text-transform: capitalize;
        cursor: pointer;
        padding: 3px 15px;

        &:hover {
          background-color:#eee;
        }
      }
    }
  }
`;

const StatusDiv = styled.div`
  margin-bottom: 8px;
`;

const TYPES = [
    'All',
    'DEPOSIT',
    'WITHDRAWAL',
    'TRADE',
    // 'ORDER',
];


export default class WalletActivity extends React.Component {

    static propTypes = {
        fiatCurrency: PropTypes.string,
        usdToFiatRate: PropTypes.number,
        walletActivity: PropTypes.object,
        networkConfigs: PropTypes.object,
        reloadWalletActivity: PropTypes.bool,

        clearWalletActivity: PropTypes.func,
        getWalletActivity: PropTypes.func,

        coin: PropTypes.string,
        withdraw: PropTypes.bool,
        freezeType: PropTypes.string,

        history: PropTypes.object,
    };

    static defaultProps = {
        wallet: null
    };

    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false,
            operationType: props.freezeType ? props.freezeType : 'All',
            isFrozen: !!props.freezeType,
            rowDisplayed: '',
            selectedPage: 1,
            addressCopied: false,
            tagCopied: false,
            txCopied: false,
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.reloadWalletActivity === true && this.props.reloadWalletActivity !== prevProps.reloadWalletActivity) {
            this.load();
        }
        if (this.props.walletActivity && this.props.walletActivity !== prevProps.walletActivity) {
            this.setState({
                selectedPage: this.props.walletActivity.number+1,
            });
        }
    }

    shouldComponentUpdate(nextProps) {
        // don't want to redraw for every exchange config update since we're just using these for explorer links
        if (this.props.networkConfigs !== nextProps.networkConfigs) {
            return false;
        }
        return true;
    }

    load = () => {
        if (this.props.coin && this.props.coin !== '') {
            this.props.clearWalletActivity();
            if (this.props.freezeType) {
                this.props.getWalletActivity({
                    asset: this.props.coin,
                    types: this.state.operationType === 'All' ? ['DEPOSIT','WITHDRAWAL','TRADE'] : [this.state.operationType],
                    // types: this.props.freezeType.split(',')
                });
            } else {
                this.props.getWalletActivity({
                    asset: this.props.coin,
                    types: ['DEPOSIT','WITHDRAWAL','TRADE']
                });
            }
        }
    }

    onCopyAddress() {
        this.setState({
            addressCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                addressCopied: false,
            });
        }, 1500);
    }

    onCopyTag() {
        this.setState({
            tagCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                tagCopied: false,
            });
        }, 1500);
    }

    onCopyTx() {
        this.setState({
            txCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                txCopied: false,
            });
        }, 1500);
    }

    handleChange = event => {
        if (event.target.name === 'page') {
            // console.log(event.target.value);
            this.setState({
                selectedPage: event.target.value,
            });
        }
    };

    updatePage = (page) => {
        const { walletActivity } = this.props;

        if (page > (walletActivity.totalPages - 1)) {
            page = walletActivity.totalPages - 1;
        }
        if (page >= 0) {
            this.props.clearWalletActivity();
            this.props.getWalletActivity({
                asset: this.props.coin,
                types: this.state.operationType === 'All' ? ['DEPOSIT','WITHDRAWAL','TRADE'] : [this.state.operationType],
                page
            });
        }
    };

    toggleRowDisplayed = (key) => {
        const { rowDisplayed } = this.state;

        this.setState({
            rowDisplayed: rowDisplayed === key ? '' : key,
        });
    };

    toggleOperationsMenu = () => {
        this.setState(state => ({
            isMenuOpen: !state.isMenuOpen
        }));
    };

    selectType = (type) => {
        this.props.clearWalletActivity();
        this.setState({
            operationType: type,
            isMenuOpen: false,
        });
        this.props.getWalletActivity({
            asset: this.props.coin,
            types: type === 'All' ? ['DEPOSIT','WITHDRAWAL','TRADE'] : [type]
        });
    };

    closeMenu = () => {
        this.setState({ isMenuOpen: false });
    };


    render() {

        const { walletActivity, networkConfigs, freezeType, history, coin, fiatCurrency, usdToFiatRate } = this.props;
        const { operationType, isMenuOpen, rowDisplayed, selectedPage, addressCopied, tagCopied, txCopied } = this.state;

        const menuOptions = {
            isOpen: isMenuOpen,
            close: this.closeMenu,
            toggle: <span onClick={this.toggleOperationsMenu}>{operationType.replace(/_/g , ' ').toLocaleLowerCase()}</span>,
            align: 'left'
        };

        return (
            <ActivityWrapper isFrozen={freezeType}>
                <PortfolioTitle>
                    {freezeType === 'WITHDRAWAL' && (
                        <FormattedMessage
                            id="portfolio.withdrawalActivity"
                            defaultMessage="Withdrawal Activity"
                        />
                    )}
                    {freezeType === 'DEPOSIT' && (
                        <FormattedMessage
                            id="portfolio.depositActivity"
                            defaultMessage="Deposit Activity"
                        />
                    )}
                    {freezeType !== 'WITHDRAWAL' && freezeType !== 'DEPOSIT' && (
                        <FormattedMessage
                            id="portfolio.walletActivity"
                            defaultMessage="Wallet Activity"
                        />
                    )}
                    <DownloadCsvSpan onClick={() => { history.push('/account/profile/downloads'); }}>
                        &#11123; Download As CSV
                    </DownloadCsvSpan>
                </PortfolioTitle>
                <Table>
                    <Header>
                        <NarrowCell/>
                        <Cell/>
                        <OperationTypeCell>
                            {freezeType !== 'All' ? null : (
                                <React.Fragment>
                                    Operations:
                                    <StyledMenu {...menuOptions}>
                                        {TYPES.filter(type => type !== operationType).map((type, i) => (
                                            <span key={i} onClick={() => this.selectType(type)}>
                                                {type.replace(/_/g , ' ').toLocaleLowerCase()}
                                            </span>
                                        ))}
                                    </StyledMenu>
                                </React.Fragment>
                            )}
                        </OperationTypeCell>
                        <Cell/>
                        <StatusCell/>
                        <LastCell>
                            Time: <span>All</span>
                        </LastCell>
                    </Header>

                    <TableHeader>
                        <NarrowCell/>
                        <Cell>
                            Transaction
                        </Cell>
                        <Cell>
                            Operation
                        </Cell>
                        <Cell>
                            Amount
                        </Cell>
                        <StatusCell>
                            {freezeType !== 'WITHDRAWAL' && freezeType !== 'DEPOSIT' ? (
                                <FormattedMessage
                                    id="portfolio.combinedStatus"
                                    defaultMessage="Pair / Status"
                                />
                            ) : (
                                <FormattedMessage
                                    id="portfolio.status"
                                    defaultMessage="Status"
                                />
                            )}
                        </StatusCell>
                        <LastCell>Time</LastCell>
                    </TableHeader>
                    {walletActivity && walletActivity.content.map((item, i) => (
                        <React.Fragment key={'frag_' + i}>
                            <TableRow key={i} isFrozen={freezeType} onClick={() => {this.toggleRowDisplayed(item.type+item.id);}}>
                                <NarrowCell>
                                    {/* plus/minus ... rowDisplayed == item.type+item.id ? '\u229f' : '\u229E' */}
                                    {rowDisplayed === item.type+item.id ? '\u25BE' : '\u25B8'}
                                </NarrowCell>
                                <Cell>
                                    # {item.id ? item.id : ''}
                                </Cell>
                                <TypeCell>
                                    {item.type.replace('_', ' ').toLowerCase()}
                                </TypeCell>
                                <Cell>
                                    {(freezeType !== 'WITHDRAWAL' && freezeType !== 'DEPOSIT') ?
                                        item.qty ? <span>{item.sign}{displayFormat(item.qty, 8)} {item.asset}</span> : '' :
                                        item.qty ? <span>{displayFormat(item.qty, 8)} {item.asset}</span> : ''
                                    }
                                </Cell>
                                <StatusCell>
                                    {(item.type === 'DEPOSIT' || item.type === 'WITHDRAWAL') ?
                                        item.status ? item.status
                                            .replace('_', ' ')
                                            .toLowerCase()
                                            .replace('awaiting approval', 'processing')
                                            .replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : '' :
                                        item.status.replace('-', '/')
                                    }
                                </StatusCell>
                                <DateCell>
                                    {dateFns.format(new Date(item.ts.replace(' ', '')), 'YYYY/MM/DD  HH:mm')}
                                </DateCell>
                            </TableRow>
                            {rowDisplayed === item.type+item.id && item.address && (
                                <SmallTableRow key={'rowTwo_' + i} isFrozen={freezeType}>
                                    {(item.type === 'DEPOSIT' || item.type === 'WITHDRAWAL') ? (
                                        <React.Fragment>
                                            <NarrowCell/>
                                            <QuadCell>
                                                <StatusDiv>
                                                    <b>Status </b> {item.status ? item.status.replace('_', ' ').toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : ''}
                                                </StatusDiv>

                                                <b>Address</b>
                                                {addressCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                    <CopyToClipboardSpan  onClick={() => {this.onCopyAddress();}}>
                                                        <CopyToClipboard text={item.address.split(':', 1)[0]}>
                                                            <span>
                                                                <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                                &nbsp;Copy to clipboard
                                                            </span>
                                                        </CopyToClipboard>
                                                    </CopyToClipboardSpan>
                                                )}
                                                <br/>

                                                <BreakWord>
                                                    {item.address.split(':', 1)[0]}
                                                    {item.address && networkConfigs[item.nw] && networkConfigs[item.nw].explorerAddressUrl && networkConfigs[item.nw].explorerAddressUrl !== '' && (
                                                        <React.Fragment>
                                                            &nbsp;&nbsp;[<Link to={{ pathname: networkConfigs[item.nw].explorerAddressUrl + item.address.split(':', 1)[0] }} target="_blank">
                                                                { new URL(networkConfigs[item.nw].explorerAddressUrl).hostname }
                                                            </Link>]
                                                        </React.Fragment>
                                                    )}
                                                </BreakWord>

                                                {(item.address.split(':', 1)[1]) && (
                                                    <React.Fragment>
                                                        <b>Tag</b>
                                                        {tagCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                            <CopyToClipboardSpan  onClick={() => {this.onCopyTag();}}>
                                                                <CopyToClipboard text={item.address.split(':', 1)[1]}>
                                                                    <span>
                                                                        <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                                        &nbsp;Copy to clipboard
                                                                    </span>
                                                                </CopyToClipboard>
                                                            </CopyToClipboardSpan>
                                                        )}
                                                        <br/>

                                                        <BreakWord>
                                                            {item.address.split(':', 1)[1]}
                                                        </BreakWord>
                                                    </React.Fragment>
                                                )}

                                            </QuadCell>
                                            <Cell/>
                                            <Cell/>
                                            <StatusCell/>
                                            <DateCell/>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <NarrowCell/>
                                            <QuadCell>
                                                <b>Detail</b>
                                                <br/>
                                                <BreakWord>
                                                    {item.address}
                                                </BreakWord>
                                            </QuadCell>
                                            <Cell/>
                                            <Cell/>
                                            <StatusCell/>
                                            <DateCell/>
                                        </React.Fragment>
                                    )}
                                </SmallTableRow>
                            )}
                            {rowDisplayed === item.type+item.id && item.txid && (
                                <SmallTableRow key={'rowThree_' + i} isFrozen={freezeType}>
                                    {(item.type === 'DEPOSIT' || item.type === 'WITHDRAWAL') ? (
                                        <React.Fragment>
                                            <NarrowCell/>
                                            <QuadCellSmall>
                                                <b>Transaction Id (TxId)</b>
                                                {txCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                    <CopyToClipboardSpan  onClick={() => {this.onCopyTx();}}>
                                                        <CopyToClipboard text={item.txid}>
                                                            <span>
                                                                <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                                &nbsp;Copy to clipboard
                                                            </span>
                                                        </CopyToClipboard>
                                                    </CopyToClipboardSpan>
                                                )}
                                                <br/>
                                                <BreakWord>
                                                    {(item.txid) ? item.txid : ''}
                                                    {item.txid && !item.txid.startsWith('BPalDirect') && networkConfigs[item.nw] && networkConfigs[item.nw].explorerTxIdUrl && networkConfigs[item.nw].explorerTxIdUrl !== '' !== '' && (
                                                        <React.Fragment>
                                                            &nbsp;&nbsp;[<Link to={{ pathname: networkConfigs[item.nw].explorerTxIdUrl + item.txid }} target="_blank">
                                                                { new URL(networkConfigs[item.nw].explorerTxIdUrl).hostname }
                                                            </Link>]
                                                        </React.Fragment>
                                                    )}
                                                </BreakWord>
                                            </QuadCellSmall>
                                            <Cell/>
                                            <Cell/>
                                            <StatusCell/>
                                            <DateCell/>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <NarrowCell/>
                                            <QuadCellSmall>
                                                <b>Order Id</b>
                                                <br/>
                                                <BreakWord>
                                                    {(item.txid) ? item.txid : ''}
                                                </BreakWord>
                                            </QuadCellSmall>
                                            <Cell/>
                                            <Cell/>
                                            <StatusCell/>
                                            <DateCell/>
                                        </React.Fragment>
                                    )}
                                </SmallTableRow>
                            )}
                            {rowDisplayed === item.type+item.id && (item.fee > 0) && (
                                (item.type === 'WITHDRAWAL') ? (
                                    <React.Fragment>
                                        <SmallTableRow key={'rowFour_' + i} isFrozen={freezeType}>
                                            <NarrowCell/>
                                            <QuadCellSmall>
                                                <b>Amount Sent</b>
                                                <br/>
                                                {displayFormat((Number(item.qty)-Number(item.fee)), 8)} {coin}
                                            </QuadCellSmall>
                                            <Cell/>
                                            <Cell/>
                                            <StatusCell/>
                                            <DateCell/>
                                        </SmallTableRow>
                                        <SmallTableRow key={'rowFourB_' + i} isFrozen={freezeType}>
                                            <NarrowCell/>
                                            <QuadCellSmall>
                                                <b>Transaction Fee</b>
                                                <br/>
                                                {displayFormat(item.fee, 8)} {coin}
                                            </QuadCellSmall>
                                            <Cell/>
                                            <Cell/>
                                            <StatusCell/>
                                            <DateCell/>
                                        </SmallTableRow>
                                    </React.Fragment>
                                ) : (
                                    <SmallTableRow key={'rowFour_' + i} isFrozen={freezeType}>
                                        <NarrowCell/>
                                        <QuadCellSmall>
                                            <b>Transaction Fee</b>
                                            <br/>
                                            {displayFormat(item.fee, 8)} {coin}
                                        </QuadCellSmall>
                                        <Cell/>
                                        <Cell/>
                                        <StatusCell/>
                                        <DateCell/>
                                    </SmallTableRow>
                                )
                            )}
                            {rowDisplayed === item.type+item.id && (item.us > 0) && (
                                <SmallTableRow key={'rowFive_' + i} isFrozen={freezeType}>
                                    <NarrowCell/>
                                    <QuadCellSmall>
                                        <b>Transaction Fiat Value</b>
                                        <br/>
                                        {(fiatCurrency !== 'USD') ? getSymbolFromCurrency(fiatCurrency) + displayFormat(usdToFiatRate * item.us, 2) + ' ' + fiatCurrency + ' / ' : ''}
                                        ${displayFormat(item.us, 2)} USD
                                        {(fiatCurrency !== 'CAD') ? ' / ' + displayFormat(item.ca, 2) + 'CAD' : ''}
                                    </QuadCellSmall>
                                    <Cell/>
                                    <Cell/>
                                    <StatusCell/>
                                    <DateCell/>
                                </SmallTableRow>
                            )}
                            {rowDisplayed === item.type+item.id && (
                                <MobileOnlySmallTableRow key={'rowSix_' + i} isFrozen={freezeType}>
                                    <NarrowCell/>
                                    <QuadCellSmall>
                                        <b>Date & Time</b>
                                        <br/>
                                        {dateFns.format(new Date(item.ts.replace(' ', '')), 'YYYY/MM/DD  HH:mm')}
                                    </QuadCellSmall>
                                    <Cell/>
                                    <Cell/>
                                    <StatusCell/>
                                    <DateCell/>
                                </MobileOnlySmallTableRow>
                            )}
                        </React.Fragment>
                    ))}
                </Table>

                {walletActivity && walletActivity.totalPages > 1 ? (
                    <Pagination>
                        <PagingPuttons>
                            <span onClick={() => !walletActivity.first && this.updatePage(walletActivity.number - 1)}>&#60;</span>
                            <span onClick={() => !walletActivity.last && this.updatePage(walletActivity.number + 1)}>&#62;</span>
                        </PagingPuttons>
                        <PageInput
                            name="page"
                            value={selectedPage}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    this.updatePage(Number(selectedPage)-1);
                                }
                            }}
                            onChange={this.handleChange}
                        />
                        <span>
                            of {walletActivity.totalPages}
                        </span>
                    </Pagination>
                ) : null}

            </ActivityWrapper>

        );
    }
}
