import styled from 'styled-components';


const RadioGroup = styled.div`
  margin-left: 22px;

  label {
    margin-bottom: 20px;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    line-height: 26px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 2px solid ${props => props.theme.colors.primary8};
    border-radius: 100%;
    background: ${props => props.theme.colors.primary5};
    margin-top: 3px;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: ${props => props.theme.colors.primary2};
    position: absolute;
    top: 7px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  [type="radio"]:checked + label:before {
    border-color: ${props => props.theme.colors.primary2};
  }
`;

export default RadioGroup;
