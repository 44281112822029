import React from 'react';
import styled, { keyframes } from 'styled-components';
// import PropTypes from 'prop-types';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;


const SpinnerElement = styled.div`
    width: 24px;
    height: 24px;
`;

const Circle = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    background-color: #021747;
    border-radius: 100%;
    animation: ${spin} 1s infinite linear;
`;

const Gradient = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    top: -12px;
    left: 6px;
    background-image: linear-gradient(0deg, #021747 0%, #fff 100%);
    transform: rotate(-45deg);
`;

const Inner = styled.div`
    top: 50%;
    left: 50%;
    background-color: #fff;
    border-radius: 100%;
    position: absolute;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -50%);
`;


export default class Spinner extends React.Component {

    render() {

        return (
            <SpinnerElement>
                <Circle>
                    <Gradient/>
                    <Inner/>
                </Circle>
            </SpinnerElement>
        );

    }

}
