import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {DisplayFiat, displayFormat} from '../../../../utils';
import { mobile } from '../../../../styles';


const Container = styled.div`
  padding: 20px 40px 10px 40px;

  ${mobile`
    padding: 10px 10px 5px 10px;
  `};
`;

const WalletBalances = styled.div`
  display: flex;

  ${mobile`
    justify-content: space-between;
  `};
`;

const BalanceItem = styled.div`
  // width: 145px;
  margin: 25px;
  white-space: nowrap;

  label {
	color: #9B9B9B;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
  }

  p {
    margin: 0;
    font-weight: 600;

    ${mobile`
      font-weight: 500;
    `};
  }

  span {
	color: #9B9B9B;
	font-size: 14px;
	line-height: 21px;
  }

  ${mobile`
    margin: 5px;
  `};
`;

const AvailableBalance = styled(BalanceItem)`
  p {
	color: #4A4A4A;
	font-size: 24px;
	line-height: 36px;
    font-weight: 600;
    
    ${mobile`
      font-size: 22px;
      font-weight: 500;
      line-height: 34px;
    `};
  }
`;

const LockedBalance = styled(BalanceItem)`
  p {
	color: #ef8632;
	font-size: 16px;
	line-height: 28px;
    font-weight: 600;

    ${mobile`
      font-weight: 500;
    `};
  }
`;

const WalletActions = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 10px;
  }

  ${mobile`
    // flex-wrap: wrap;
    margin: 10px 0 10px 0;
    flex-direction: row;
    align-items: center;
  `};

`;

const WalletActionsDesktop = styled(WalletActions)`
  ${mobile`
    display: none;
  `};
`;

const WalletActionsMobile = styled(WalletActions)`
  display: none;
  ${mobile`
    display: flex;
  `};
`;

const LinkButton = styled(Link)`
    width: 150px;
    margin-right: 16px;
    padding: 16px 20px;
    border-radius: 5px;
    text-decoration: none;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 1.05px;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;

    ${mobile`
      margin: 5px 5px 5px 5px;
      padding: 5px;
      width: 30%;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      // width: 100%;
    `};
`;

const LinkButtonLarge = styled(LinkButton)`
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
`;

const ButtonOne = styled(LinkButton)`
  background: ${props => props.theme.colors.primary1};
  color: ${props => props.theme.colors.primary5};
`;

const ButtonOneLarge = styled(LinkButtonLarge)`
  background: ${props => props.theme.colors.primary1};
  color: ${props => props.theme.colors.primary5};
`;

const ButtonTwo = styled(LinkButton)`
  background: ${props => props.theme.colors.primary2};
  color: ${props => props.theme.colors.primary5};
`;

const ButtonSix = styled(LinkButton)`
  background: ${props => props.theme.colors.primary6};
  color: ${props => props.theme.colors.primary5};
`;

const OnClickButton = styled.div`
    width: 150px;
    margin-right: 16px;
    background: ${props => props.theme.colors.primary1};
    color: ${props => props.theme.colors.primary5};
    text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
    padding: 16px 20px;
    border-radius: 5px;
    text-decoration: none;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 1.05px;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;

    ${mobile`
      margin: 5px 5px 5px 5px;
      padding: 5px;
      // width: 100%;
      width: 30%;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
    `};
`;

const ButtonClickSix = styled(OnClickButton)`
  background: ${props => props.theme.colors.primary6};
  color: ${props => props.theme.colors.primary5};
`;

/*
const OnClickButtonLarge = styled(OnClickButton)`
  font-size: 24px;
  font-weight: bold;
  line-height: 20px;
`;

const ButtonClickSixLarge = styled(OnClickButtonLarge)`
  background: ${props => props.theme.colors.primary6};
  color: ${props => props.theme.colors.primary5};
`;

const InternalTransferButton = styled(Button)`
  width: 235px;
  border: 2px solid #ACB5BC;
  color: #ACB5BC;
  background: #fff;
`; */

export default class WalletInfo extends React.Component {

    static propTypes = {
        getPortfolioInfo: PropTypes.func,
        isAuthenticated: PropTypes.bool,
        fiatCurrency: PropTypes.string,
        wallet: PropTypes.object,
        coin: PropTypes.string,
        isFiatAsset: PropTypes.bool,
        history: PropTypes.object,
    };

    static defaultProps = {
        wallet: null
    };

    onClickLoadAddressBook = () => {
        const { isAuthenticated } = this.props;

        if (isAuthenticated) {
            this.props.getPortfolioInfo();
        }

        this.props.history.push(`/account/portfolio/${this.props.wallet.coin}/addressBook`);
    };


    render() {

        const { wallet, isFiatAsset, fiatCurrency } = this.props;

        return wallet && wallet.coin ? (
            <Container>
                <WalletBalances>
                    <AvailableBalance>
                        <FormattedMessage
                            id="wallet.available_balance"
                            defaultMessage="Available"
                            tagName="label"
                        />
                        <p>
                            {displayFormat(wallet.avail, 8)}
                        </p>
                    </AvailableBalance>

                    <LockedBalance>
                        <FormattedMessage
                            id="wallet.locked_balance"
                            defaultMessage="Locked"
                            tagName="label"
                        />
                        <p>
                            {displayFormat(wallet.lock, 8)}
                        </p>
                    </LockedBalance>

                    <BalanceItem>
                        <FormattedMessage
                            id="wallet.total_balance"
                            defaultMessage="Total"
                            tagName="label"
                        />
                        <p>
                            {displayFormat(wallet.total, 8)}
                        </p>
                        {fiatCurrency === 'USD' ? (
                            <span>
                                <DisplayFiat currency={fiatCurrency} value={Number(wallet.fiat)}/>
                            </span>
                        ) : (
                            <span>
                                <DisplayFiat currency={fiatCurrency} value={Number(wallet.fiat)}/>
                                <br/>
                                <DisplayFiat currency='USD' value={Number(wallet.fiatUSD)}/>
                            </span>
                        )}
                    </BalanceItem>
                </WalletBalances>
                <WalletActionsMobile>
                    {(isFiatAsset !== null) ? (
                        <React.Fragment>
                            <ButtonOneLarge to={`/account/portfolio/${wallet.coin}/deposit`}>
                                <FormattedMessage
                                    id="wallet.depositIcon"
                                    defaultMessage="&#8677;"
                                />
                            </ButtonOneLarge>
                            {(isFiatAsset) ? (
                                <ButtonClickSix onClick={() => {this.onClickLoadAddressBook();}}>
                                    <FormattedMessage
                                        id="wallet.accounts"
                                        defaultMessage="Accounts"
                                        // defaultMessage="&#128464;"
                                    />
                                </ButtonClickSix>
                            ) : (
                                <ButtonClickSix onClick={() => {this.onClickLoadAddressBook();}}>
                                    <FormattedMessage
                                        id="wallet.addresses"
                                        defaultMessage="Addresses"
                                        // defaultMessage="&#128464;"
                                    />
                                </ButtonClickSix>
                            )}
                            <ButtonOneLarge to={`/account/portfolio/${wallet.coin}/withdraw`}>
                                <FormattedMessage
                                    id="wallet.withdrawIcon"
                                    defaultMessage="&#8614;"
                                />
                            </ButtonOneLarge>
                        </React.Fragment>
                    ) : (
                        <FormattedMessage
                            id="wallet.asset_disabled"
                            defaultMessage="This asset is currently disabled."
                        />
                    )}
                </WalletActionsMobile>
                <WalletActionsMobile>
                    {(isFiatAsset !== null) && (
                        <React.Fragment>
                            <ButtonSix to={`/account/portfolio/${wallet.coin}/about`}>
                                <FormattedMessage
                                    id="wallet.about"
                                    defaultMessage="About"
                                />
                            </ButtonSix>
                            <ButtonTwo to={`/account/portfolio/${wallet.coin}/reserves`}>
                                <FormattedMessage
                                    id="wallet.proofs"
                                    defaultMessage="Proofs"
                                />
                            </ButtonTwo>
                            <ButtonSix to={`/account/portfolio/${wallet.coin}/news`}>
                                <FormattedMessage
                                    id="wallet.news"
                                    defaultMessage="News"
                                />
                            </ButtonSix>
                        </React.Fragment>
                    )}
                </WalletActionsMobile>
                <WalletActionsDesktop>
                    {(isFiatAsset !== null) ? (
                        <React.Fragment>
                            <ButtonOne to={`/account/portfolio/${wallet.coin}/deposit`}>
                                <FormattedMessage
                                    id="wallet.deposit"
                                    defaultMessage="Deposit"
                                />
                            </ButtonOne>
                            <ButtonOne to={`/account/portfolio/${wallet.coin}/withdraw`}>
                                <FormattedMessage
                                    id="wallet.withdraw"
                                    defaultMessage="Withdraw"
                                />
                            </ButtonOne>
                            <ButtonTwo to={`/account/portfolio/${wallet.coin}/reserves`}>
                                <FormattedMessage
                                    id="wallet.reserves"
                                    defaultMessage="Proof Of Reserves"
                                />
                            </ButtonTwo>
                        </React.Fragment>
                    ) : (
                        <FormattedMessage
                            id="wallet.asset_disabled"
                            defaultMessage="This asset is currently disabled."
                        />
                    )}
                </WalletActionsDesktop>
                <WalletActionsDesktop>
                    {(isFiatAsset !== null) && (
                        <React.Fragment>
                            <ButtonSix to={`/account/portfolio/${wallet.coin}/about`}>
                                <FormattedMessage
                                    id="wallet.about"
                                    defaultMessage="About"
                                />
                            </ButtonSix>
                            {(isFiatAsset) ? (
                                <ButtonClickSix onClick={() => {this.onClickLoadAddressBook();}}>
                                    <FormattedMessage
                                        id="wallet.accounts"
                                        defaultMessage="Accounts"
                                    />
                                </ButtonClickSix>
                            ) : (
                                <ButtonClickSix onClick={() => {this.onClickLoadAddressBook();}}>
                                    <FormattedMessage
                                        id="wallet.addressBook"
                                        defaultMessage="Address Book"
                                    />
                                </ButtonClickSix>
                            )}
                            <ButtonSix to={`/account/portfolio/${wallet.coin}/news`}>
                                <FormattedMessage
                                    id="wallet.news"
                                    defaultMessage="News"
                                />
                            </ButtonSix>
                        </React.Fragment>
                    )}
                </WalletActionsDesktop>
            </Container>
        ) : null;
    }
}
