import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import MyTradeHistory from './MyTradeHistory';
import { getTradeHistory } from '../../../../actions/market';
import {getBaseCoinConfig, getQuoteCoinConfig} from '../../../../selectors';


function mapStateToProps(state) {
    return {
        baseCoinConfigName: getBaseCoinConfig(state) && getBaseCoinConfig(state).name,
        quoteCoinConfigName: getQuoteCoinConfig(state) && getQuoteCoinConfig(state).name,
        tradeHistory: state.market.tradeHistory,
        tradeHistoryInitialized: state.market.tradeHistoryInitialized,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getTradeHistory
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyTradeHistory));
