import { handleActions } from 'redux-actions';
import { NotificationManager } from 'react-notifications';
import { calculateScaleUsingPrice } from '../../utils';


const defaultState = {
    activeOrderIds: [],
    activeOrderHashes: {},

    recentTrades: [],

    buyOrders: [],
    lastBuyInput: undefined,
    sellOrders: [],
    lastSellInput: undefined,
    orderBookQueue: {},
    orderBookFeedInitialized: false,

    tickers: [],
    tickerFeedInitialized: false,

    tradeHistory: {},
    tradeHistoryInitialized: false,

    buyPriceField: undefined,
    sellPriceField: undefined,

    candlesticksTv: [],
    lastCandlestick: {},
    candlestickInterval: '',
    candlestickFeedInitialized: false,

    volumeBarsTv: [],
    lastVolumeBar: {},

    lastCandlestickPrice: 0,
};

const getPair = (enginePairs, symbol) => {
    return enginePairs ?
        enginePairs.find(pair => pair.symbol === symbol).pair : symbol;
};

const parseEnginePair = (stringPair) => {
    const splitPair = stringPair.split(/[-]/g);
    return {
        base: splitPair[0],
        quote: splitPair[1],
        pair: splitPair[0] + '-' + splitPair[1],
        symbol: splitPair[0] + splitPair[1],
        status: splitPair[2].replace('_', '-'),
    };
};

const sortSells = (sellOrders) => {
    const sellOrdersSorted = [].concat(sellOrders).sort(function(a, b) {
        if (Number(a.price) < Number(b.price)) { return -1; }
        if (Number(a.price) > Number(b.price)) { return 1; }
        return 0;
    });
    return sellOrdersSorted;
};

const sortBuys = (buyOrders) => {
    const buyOrdersSorted = [].concat(buyOrders).sort(function(a, b) {
        if (Number(a.price) > Number(b.price)) { return -1; }
        if (Number(a.price) < Number(b.price)) { return 1; }
        return 0;
    });
    return buyOrdersSorted;
};

const sortChartDataTv = (chartEntries) => {
    const chartEntriesSorted = [].concat(chartEntries).sort(function(a, b) {
        if (a.time < b.time) { return -1; }
        if (a.time > b.time) { return 1; }
        return 0;
    });
    return chartEntriesSorted;
};

const sortRecentTradesDesc = (recentTrades) => {
    const recentTradesSorted = [].concat(recentTrades).sort(function(a, b) {
        if (a.aggTradeId > b.aggTradeId) { return -1; }
        if (a.aggTradeId < b.aggTradeId) { return 1; }
        return 0;
    });
    return recentTradesSorted;
};

const sortRecentTradesAsc = (recentTrades) => {
    const recentTradesSorted = [].concat(recentTrades).sort(function(a, b) {
        if (a.aggTradeId < b.aggTradeId) { return -1; }
        if (a.aggTradeId > b.aggTradeId) { return 1; }
        return 0;
    });
    return recentTradesSorted;
};



/* const sortWallets = (wallets) => {
    const walletsSorted = [].concat(wallets).sort(function(a, b) {
        if (a.coin < b.coin) { return -1; }
        if (a.coin > b.coin) { return 1; }
        return 0;
    });
    return walletsSorted;
}; */

export default handleActions(
    {
        ['EXCHANGE_CONFIG']: (state, action) => {
            const config = action.payload;
            if (config.enginePairs != null && !(JSON.stringify(state.activePairs) === JSON.stringify(config.enginePairs.map(parseEnginePair)))) {
                return {
                    ...state,
                    activePairs: config.enginePairs.map(parseEnginePair),
                };
            }
            return state;
        },
        ['CLEAR_AUTH_DATA']: (state) => {
            return {
                // ...defaultState,
                // activePairs: state.activePairs,
                // if activePairs gets reset it triggers an unwanted props reload on the market page...
                ...state,
                activeOrderIds: [],
                activeOrderHashes: {},

                recentTrades: [],

                buyOrders: [],
                lastBuyInput: undefined,
                sellOrders: [],
                lastSellInput: undefined,
                orderBookQueue: {},
                orderBookFeedInitialized: false,

                tickers: [],
                tickerFeedInitialized: false,

                tradeHistory: {},
                tradeHistoryInitialized: false,

                buyPriceField: undefined,
                sellPriceField: undefined,

                candlesticksTv: [],
                lastCandlestick: {},
                candlestickInterval: '',
                candlestickFeedInitialized: false,

                volumeBarsTv: [],
                lastVolumeBar: {},

                lastCandlestickPrice: 0,
            };
        },
        ['USER_ORDERS_INIT']: (state, action) => {
            let newActiveIds = [];
            let newActiveHashes = {};

            for (const o of action.payload.items) {
                const order = {
                    action: 'NEW',
                    orderId: o.orderId,
                    created: o.transactTime,
                    pair: getPair(state.activePairs, o.symbol),
                    status: o.status,
                    side: o.side,
                    type: o.type,
                    oQty: o.origQty,
                    oPrice: o.price,
                    fQty: o.executedQty,
                    sPrice: (o.type === 'TRAILING_STOP' || o.type === 'TRAILING_STOP_LIMIT') ? o.trailingStopPercent : o.stopPrice,
                    rQty: (o.origQty - o.executedQty)
                };

                newActiveIds.push(order.orderId);
                newActiveHashes[order.orderId] = order;
            }

            return {
                ...state,
                activeOrderIds: newActiveIds,
                activeOrderHashes: newActiveHashes
            };
        },
        ['STREAM_ORDER_UPDATE']: (state, action) => {
            const order = {
                action: action.x,
                orderId: action.i,
                created: action.O,
                pair: getPair(state.activePairs, action.s),
                status: action.X,
                side: action.S,
                type: action.o,
                oQty: action.q,
                oPrice: action.p,
                fQty: action.z,
                rQty: (action.q - action.z),
                sPrice: action.P,
                rReason: action.r,
                // TODO: FIX MISSING TRAILING STOP PERCENTAGE?
                // eQty: action.l,
                // ePrice: action.L,
                // tradeId: action.t,
                // maker: action.m,
                // ts: action.T,
            };

            let accountSettingsPrefix = sessionStorage.getItem('loginEmail');

            switch(order.action) {
            case 'NEW': {
                if (localStorage.getItem(accountSettingsPrefix + '_newOrderNotifications') !== 'disabled') {
                    NotificationManager.success(order.pair + ': ' + order.type.replaceAll('_', ' ') + ' ' + order.side + ' ' + (+order.oQty) + '@' + (+order.oPrice), 'Order Added');
                }
                return {
                    ...state,
                    activeOrderIds: [ ...state.activeOrderIds, order.orderId],
                    activeOrderHashes: { ...state.activeOrderHashes, [order.orderId]: order }
                };
            }
            case 'EXPIRED': {
                if (localStorage.getItem(accountSettingsPrefix + '_expiredOrderNotifications') !== 'disabled') {
                    NotificationManager.warning(order.pair + ((order.orderId && order.orderId > 0) ? ': ' + order.orderId : ''), 'Order Expired');
                }
                const prunedIds = state.activeOrderIds.filter(item => item !== order.orderId );
                const { [order.orderId]: deletedOrder, ...prunedHashes } = state.activeOrderHashes;
                return {
                    ...state,
                    lastDeletedOrder: deletedOrder,
                    activeOrderIds: prunedIds,
                    activeOrderHashes: prunedHashes,
                };
            }
            case 'REJECTED': {
                if (localStorage.getItem(accountSettingsPrefix + '_rejectedOrderNotifications') !== 'disabled') {
                    NotificationManager.warning(order.pair + ((order.rReason && order.rReason !== '') ? ': ' + order.rReason.replace('_', ' ') : ''), 'Order Rejected');
                }
                const prunedIds = state.activeOrderIds.filter(item => item !== order.orderId );
                const { [order.orderId]: deletedOrder, ...prunedHashes } = state.activeOrderHashes;
                return {
                    ...state,
                    lastDeletedOrder: deletedOrder,
                    activeOrderIds: prunedIds,
                    activeOrderHashes: prunedHashes,
                };
            }
            case 'CANCELED': {
                if (localStorage.getItem(accountSettingsPrefix + '_cancelledOrderNotifications') !== 'disabled') {
                    NotificationManager.warning(order.pair + ((order.orderId && order.orderId > 0) ? ': ' + order.orderId : ''), 'Order Cancelled');
                }
                const prunedIds = state.activeOrderIds.filter(item => item !== order.orderId );
                const { [order.orderId]: deletedOrder, ...prunedHashes } = state.activeOrderHashes;
                return {
                    ...state,
                    lastDeletedOrder: deletedOrder,
                    activeOrderIds: prunedIds,
                    activeOrderHashes: prunedHashes,
                };
            }
            case 'TRADE': {
                const trade = {
                    pair: getPair(state.activePairs, action.s),
                    orderId: action.i,
                    side: action.S,
                    quantity: action.l,
                    price: action.L,
                    tradeId: action.t,
                    maker: action.m,
                    ts: action.T,
                };
                if (trade.quantity > 0 && localStorage.getItem(accountSettingsPrefix + '_tradeNotifications') !== 'disabled') {
                    NotificationManager.success(trade.pair + ': ' + trade.side + ' ' + (+trade.quantity) + '@' + (+trade.price), 'Trade Executed', 10000);
                }

                const prunedIds = state.activeOrderIds.filter(item => item !== order.orderId );
                const { [order.orderId]: deletedOrder, ...prunedHashes } = state.activeOrderHashes;
                if (order.status === 'PENDING_ADD' || order.status === 'NEW' || order.status === 'PARTIALLY_FILLED') {
                    return {
                        ...state,
                        activeOrderIds: [ ...prunedIds, order.orderId],
                        activeOrderHashes: { ...prunedHashes, [order.orderId]: order }
                    };
                } else {
                    return {
                        ...state,
                        lastDeletedOrder: deletedOrder,
                        activeOrderIds: prunedIds,
                        activeOrderHashes: prunedHashes,
                    };
                }
            }
            default: {
                return state;
            }
            }
        },
        ['GET_USER_TRADES_HISTORY_SUCCESS']: (state, action) => {
            // NotificationManager.info('Loaded User Trades');
            return {
                ...state,
                tradeHistory: action.payload.trades,
                tradeHistoryInitialized: true,
            };
        },
        ['CLEAR_ORDER_BOOK_FEED']: (state) => {
            // NotificationManager.info('Cleared Order Book Container');
            return {
                ...state,
                buyOrders: [],
                sellOrders: [],
                orderBookQueue: {},
                orderBookFeedInitialized: false,
                tradeHistory: {},
                tradeHistoryInitialized: false,
                lastBuyInput: undefined,
                lastSellInput: undefined,
            };
        },
        ['START_ORDER_BOOK_FEED_SUCCESS']: (state, action) => {
            const buyOrders = [];
            const sellOrders = [];
            if (action.payload) { // comes in empty for inactive trade pairs
                const myOrders = state.activeOrderHashes;

                for (const o of action.payload.bids) {
                    const order = {
                        price: o[0],
                        qty: o[1],
                        orderIds: [],
                        pair: action.pair,
                    };

                    if (myOrders && Object.keys(myOrders).length > 0) {
                        for (let [myOrderId, myOrder] of Object.entries(myOrders)) {
                            if (myOrder.pair === action.pair && myOrder.side === 'BUY' && myOrder.oPrice == order.price) {
                                // console.log(`MATCH: ${myOrderId}: ${myOrder}`);
                                order.orderIds.push(myOrderId);
                            }
                        }
                    }

                    buyOrders.push(order);
                }

                for (const o of action.payload.asks) {
                    const order = {
                        price: o[0],
                        qty: o[1],
                        orderIds: [],
                        pair: action.pair,
                    };

                    if (myOrders && Object.keys(myOrders).length > 0) {
                        for (let [myOrderId, myOrder] of Object.entries(myOrders)) {
                            if (myOrder.pair === action.pair && myOrder.side === 'SELL' && myOrder.oPrice == order.price) {
                                // console.log(`MATCH: ${myOrderId}: ${myOrder}`);
                                order.orderIds.push(myOrderId);
                            }
                        }
                    }

                    sellOrders.push(order);
                }

                // process orderBookQueue... ??
            }

            return {
                ...state,
                buyOrders: sortBuys(buyOrders),
                sellOrders: sortSells(sellOrders),
                orderBookQueue: {},
                orderBookFeedInitialized: true,
            };
        },
        ['DIFF_BOOK_DEPTH_UPDATE']: (state, action) => {
            let deletedOrder;
            let buyOrders = [];
            let sellOrders = [];

            if (!state.orderBookFeedInitialized) {
                return {
                    ...state,
                    orderBookQueue: { ...state.orderBookQueue, action }
                };
            } else {
                const myOrders = state.activeOrderHashes;
                const pair = getPair(state.activePairs, action.s);

                for (const o of action.b) {
                    const order = {
                        price: o[0],
                        qty: o[1],
                        orderIds: [],
                        pair: pair,
                    };

                    if (myOrders && Object.keys(myOrders).length > 0) {
                        for (let [myOrderId, myOrder] of Object.entries(myOrders)) {
                            if (myOrder.pair === pair && myOrder.side === 'BUY' && myOrder.oPrice == order.price) {
                                // console.log(`MATCH: ${myOrderId}: ${myOrder}`);
                                order.orderIds.push(myOrderId);
                            }
                        }
                    }

                    buyOrders.push(order);
                }

                for (const o of action.a) {
                    const order = {
                        price: o[0],
                        qty: o[1],
                        orderIds: [],
                        pair: pair,
                    };

                    if (myOrders && Object.keys(myOrders).length > 0) {
                        for (let [myOrderId, myOrder] of Object.entries(myOrders)) {
                            if (myOrder.pair === pair && myOrder.side === 'SELL' && myOrder.oPrice == order.price) {
                                // console.log(`MATCH: ${myOrderId}: ${myOrder}`);
                                order.orderIds.push(myOrderId);
                            }
                        }
                    }

                    sellOrders.push(order);
                }
            }

            return {
                ...state,
                lastDeletedOrder: deletedOrder,
                buyOrders: sortBuys([ ...state.buyOrders.filter(function(state){
                    return buyOrders.filter(function(update){
                        return update.price == state.price;
                    }).length == 0;
                }), ...buyOrders.filter(o => o.qty > 0) ]),
                sellOrders: sortSells([ ...state.sellOrders.filter(function(state) {
                    return sellOrders.filter(function(update) {
                        return update.price == state.price;
                    }).length == 0;
                }), ...sellOrders.filter(o => o.qty > 0) ]),
            };
        },
        ['SET_PRICE_FIELD']: (state, action) => {
            return {
                ...state,
                buyPriceField: action.payload.price,
                sellPriceField: action.payload.price,
                lastBuyInput: 'CLICK',
                lastSellInput: 'CLICK',
            };
        },
        ['SET_LAST_PRICE_INPUT']: (state, action) => {
            if (action.payload.side == 'BUY') {
                return {
                    ...state,
                    lastBuyInput: action.payload.input,
                };
            } else if (action.payload.side == 'SELL') {
                return {
                    ...state,
                    lastSellInput: action.payload.input,
                };
            } else {
                return state;
            }
        },
        ['CLEAR_CANDLESTICK_FEED']: (state) => {
            return {
                ...state,
                candlesticksTv: [],
                volumeBarsTv: [],
                candlestickInterval: '',
                candlestickFeedInitialized: false,
                lastCandlestickPrice: 0,
                lastCandlestick: {},
                lastVolumeBar: {},
            };
        },
        ['START_CANDLESTICK_FEED_SUCCESS']: (state, action) => {
            var candlesticksTv = [];
            var volumeBarsTv = [];

            var lastCandlestickPrice = 0;
            var interval = '';

            var lastCandlestick = {};
            var lastVolumeBar = {};

            for (const c of action.payload) {
                // console.log(c);
                if (interval == '') {
                    interval = c.i;
                }
                lastCandlestickPrice = parseFloat(c.c);

                const candlestickTv = {
                    time: Math.floor(c.t / 1000),
                    open: parseFloat(c.o),
                    high: parseFloat(c.h),
                    low: parseFloat(c.l),
                    close: parseFloat(c.c),
                };
                const volumeBarTv = {
                    time: Math.floor(c.t / 1000),
                    value: parseFloat(c.v),
                    color: (parseFloat(c.o) > parseFloat(c.c)) ? 'rgba(242, 38, 38, 0.3)' : 'rgba(51, 215, 120, 0.3)',
                };

                lastCandlestick = candlestickTv;
                lastVolumeBar = volumeBarTv;

                candlesticksTv.push(candlestickTv);
                volumeBarsTv.push(volumeBarTv);
            }

            return {
                ...state,
                candlesticksTv: sortChartDataTv(candlesticksTv),
                volumeBarsTv: sortChartDataTv(volumeBarsTv),
                candlestickInterval: interval,
                candlestickFeedInitialized: true,
                lastCandlestickPrice: lastCandlestickPrice,
                lastCandlestick: lastCandlestick,
                lastVolumeBar: lastVolumeBar,
            };
        },
        ['CANDLESTICK_UPDATE']: (state, action) => {
            if (!state.candlestickFeedInitialized) {
                // do nothing
            } else {
                const interval = state.candlestickInterval;
                const c = action.k;

                if (c.i == interval) {
                    var lastCandlestickPrice = parseFloat(c.c);

                    const unixtime = Math.floor(c.t / 1000);

                    const candlestickTv = {
                        time: unixtime,
                        open: parseFloat(c.o),
                        high: parseFloat(c.h),
                        low: parseFloat(c.l),
                        close: parseFloat(c.c),
                    };

                    var volumeBarTv = {
                        time: unixtime,
                        value: parseFloat(c.v),
                        color: (parseFloat(c.o) > parseFloat(c.c)) ? 'rgba(242, 38, 38, 0.3)' : 'rgba(51, 215, 120, 0.3)',
                    };


                    if (state.candlesticksTv.find(cs => cs.time === unixtime)) {
                        // console.log('Existing Candlestick Found -- Updating');

                        const newCandlesticksTv = state.candlesticksTv.filter(cs => cs.time !== unixtime).concat(candlestickTv);
                        const newVolumeBarsTv = state.volumeBarsTv.filter(cs => cs.time !== unixtime).concat(volumeBarTv);

                        return {
                            ...state,
                            candlesticksTv: sortChartDataTv(newCandlesticksTv),
                            volumeBarsTv: sortChartDataTv(newVolumeBarsTv),
                            lastCandlestickPrice: lastCandlestickPrice,
                            lastCandlestick: candlestickTv,
                            lastVolumeBar: volumeBarTv,
                        };
                    } else {
                        // console.log('No Existing Candlestick Found -- Adding');

                        const newCandlesticksTv = state.candlesticksTv.concat(candlestickTv);
                        const newVolumeBarsTv = state.volumeBarsTv.concat(volumeBarTv);

                        return {
                            ...state,
                            candlesticksTv: sortChartDataTv(newCandlesticksTv),
                            volumeBarsTv: sortChartDataTv(newVolumeBarsTv),
                            lastCandlestickPrice: lastCandlestickPrice,
                            lastCandlestick: candlestickTv,
                            lastVolumeBar: volumeBarTv,
                        };
                    }
                }
            }

            return {
                ...state,
            };
        },
        ['START_TICKER_FEED_SUCCESS']: (state, action) => {
            var tickers = [];

            for (const t of action.payload) {
                // console.log(t);
                t.d = calculateScaleUsingPrice(t.w);
                tickers.push(t);
            }

            return {
                ...state,
                tickers: tickers,
                tickerFeedInitialized: true,
            };
        },
        ['TICKER_UPDATE']: (state, action) => {
            if (state.tickerFeedInitialized) {
                // console.log(action);

                var newTickers = [...state.tickers];
                for (const t of action.payload) {
                    // console.log(t);
                    t.d = calculateScaleUsingPrice(t.w);

                    if (newTickers.find(ticker => ticker.s === t.s)) {
                        newTickers = newTickers.filter(ticker => ticker.s !== t.s).concat(t);
                    } else {
                        newTickers = newTickers.concat(t);
                    }
                }

                return {
                    ...state,
                    tickers: newTickers,
                };
            }

            return {
                ...state,
            };
        },
        ['CLEAR_AGG_TRADE_FEED']: (state) => {
            // NotificationManager.info('Cleared Order Book Container');
            return {
                ...state,
                recentTrades: [],
            };
        },
        ['START_AGG_TRADE_FEED_SUCCESS']: (state, action) => {
            let prevPrice = 0;
            const recentTrades = sortRecentTradesAsc(action.payload);
            recentTrades.forEach((o) => {
                o.positiveMove = Number(o.price) >= Number(prevPrice);
                // console.log(o);
                prevPrice = o.price;
            });

            return {
                ...state,
                recentTrades: sortRecentTradesDesc(recentTrades).slice(0,14),
            };
        },
        ['AGG_TRADE_UPDATE']: (state, action) => {
            /*
            {
              "aggTradeId": 1172142,
              "price": "19355.680000000",
              "quantity": "0.000257178",
              "firstTradeId": 1172142,
              "lastTradeId": 1172143,
              "timestamp": 1606803966076,
              "buyerMaker": true,
              "bestMatch": true
            }

            {
              "e": "aggTrade",  // Event type
              "E": 123456789,   // Event time
              "s": "BNBBTC",    // Symbol
              "a": 12345,       // Aggregate trade ID
              "p": "0.001",     // Price
              "q": "100",       // Quantity
              "f": 100,         // First trade ID
              "l": 105,         // Last trade ID
              "T": 123456785,   // Trade time
              "m": true,        // Is the buyer the market maker?
              "M": true         // Ignore
            }
            */

            const lastTrade = state.recentTrades[0];
            var positiveMove = false;
            if (lastTrade && lastTrade.price) {
                if (Number(action.p) < Number(lastTrade.price)) {
                    positiveMove = false;
                } else {
                    positiveMove = true;
                }
            }

            const newTrade = {
                aggTradeId: action.a,
                price: action.p,
                quantity: action.q,
                timestamp: action.T,
                buyerMaker: action.m,
                positiveMove: positiveMove,
                // close: parseFloat(c.c),
            };

            const recentTrades = state.recentTrades.concat(newTrade);

            return {
                ...state,
                recentTrades: sortRecentTradesDesc(recentTrades).slice(0,14),
            };
        },
    },
    defaultState
);
