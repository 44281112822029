import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubmitDepositInfo from './SubmitDepositInfo';


// function mapStateToProps(state, props) {
function mapStateToProps() {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitDepositInfo);
