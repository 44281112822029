import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobile } from '../../styles';
import { Component as StandardPage } from '../../components/StandardPage';
import PropTypes from 'prop-types';

import logo from '../../images/BlocPal_Dark_On_White_600px.jpg';


const Title = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  margin-top: 1em;
  // margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  text-align: center;

  // width: 600px;

  display: block;
  flex-wrap: wrap;
  flex: 0 1 auto;
`;

const SquareLogoDiv = styled.div`
  text-align: center;
  display: block;

  margin-left: auto;
  margin-right: auto;

  margin-bottom: 30px;
`;

const SquareLogo = styled.img`
  ${mobile`
    width: 250px;
    // height: 250px;
  `}
`;

/*
export default function MaintenanceScreen() {
  return (
    <StandardPage>
      <SquareLogoDiv>
        <Title>
          <FormattedMessage
            id="navigation.maintenanceTitle"
            defaultMessage="Our Apologies, We Are Currently Under Maintenance"
          />
        </Title>
        <SquareLogo src={'/' + logo} />
      </SquareLogoDiv>
    </StandardPage>
  );
}
*/

export default class MaintenanceScreen extends React.Component {

    static propTypes = {
        location: PropTypes.object,
    };

    componentDidMount() {
        document.title = 'Under Maintenance (BlocPalX)';
        window.gtag('event', 'page_view', {
            page_path: this.props.location.pathname + this.props.location.search + this.props.location.hash,
            page_search: this.props.location.search,
            page_hash: this.props.location.hash,
        });
    }
    render() {
        return (
            <StandardPage>
                <SquareLogoDiv>
                    <Title>
                        <FormattedMessage
                            id="navigation.maintenanceTitle"
                            defaultMessage="Our apologies, we are currently under maintenance"
                        />
                    </Title>
                    <SquareLogo src={'/' + logo} />
                </SquareLogoDiv>
            </StandardPage>
        );
    }

}