import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { Component as CurrencyImage } from '../../../../components/CurrencyImage';


const SidebarLoader = props => (
    <ContentLoader
        height={1500}
        width={280}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="24" rx="0" ry="0" width="280" height="45" />
        <rect x="0" y="105" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="191" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="277" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="363" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="449" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="535" rx="0" ry="0" width="280" height="83" />
    </ContentLoader>
);

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  line-height: 19px;

  >span {
	color: #9B9B9B;
	font-size: 12px;
	letter-spacing: 0.43px;
	text-transform: uppercase;
  }

  p {
    color: #9B9B9B;
	font-size: 14px;
	letter-spacing: 0.43px;

	span {
	  color: #ef8632;
	  cursor: pointer;
	  user-select: none;
	}
  }
`;

const ListHeaderInactive = styled(ListHeader)`
  margin-bottom: 14px;
`;

const ListWrapper = styled.div`

`;

const Option = styled(NavLink)`
  width: 200px;
  display: flex;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: relative;
  padding: 15px 15px 15px 15px;
  // white-space: pre-wrap;
  // padding: 20px 20px 20px 20px;
  text-decoration: none;
  margin-bottom: -1px;

  color: #232323;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: rgba(239, 134, 50, 0.10);
  }

  &.active {
    background-color: rgba(239, 134, 50, 0.10);
    border-color: rgba(239, 134, 50, 0.15);

    // green background-color: rgba(51, 215, 120, 0.1);
    // green border-color: rgba(51, 215, 120, 0.2);
  }

  >span {
    // display: flex;
    // align-items: center;
    // height: 24px;
    // margin-top: 13px;
    img {
      margin-right: 10px;
    }
  }
`;

const OptionNonTrading = styled(Option)`

  background-color: rgba(239, 134, 50, 0.05);  
  border-color: rgba(239, 134, 50, 0.15);

  &:hover {    
    background-color: rgba(239, 134, 50, 0.05);
  }

  &.active {
    background-color: rgba(239, 134, 50, 0.08);
  }
`;

const BoldCoin = styled.span`
    font-weight: bold;
`;

const CurrencyIcon = styled(CurrencyImage)`
  // margin-top: 10px;
  width: 30px;
  height: 30px;
`;

export default class TradePairsList extends React.Component {

    static propTypes = {
        onChangeSortDirection: PropTypes.func,
        sortDirection: PropTypes.string,
        activePair: PropTypes.string,
        openOrders: PropTypes.bool,

        activeOrderIds: PropTypes.number,
        pairs: PropTypes.array,
        isAuthenticated: PropTypes.bool,
    };

    render() {

        const { activeOrderIds, openOrders, pairs, isAuthenticated, onChangeSortDirection, sortDirection, activePair } = this.props;

        const sortedTrading = (pairs && pairs.length > 0) && ((sortDirection === 'DESC') ?
            [...pairs].filter((pair) => pair.status === 'TRADING').sort((a, b) => (a.base > b.base) ? 1 : (a.base === b.base) ? ((a.quote > b.quote) ? 1 : -1) : -1 ) :
            [...pairs].filter((pair) => pair.status === 'TRADING').sort((a, b) => (a.base < b.base) ? 1 : (a.base === b.base) ? ((a.quote < b.quote) ? 1 : -1) : -1 ));

        const sortedNonTrading = (pairs && pairs.length > 0) && ((sortDirection === 'DESC') ?
            [...pairs].filter((pair) => pair.status !== 'TRADING').sort((a, b) => (a.base > b.base) ? 1 : (a.base === b.base) ? ((a.quote > b.quote) ? 1 : -1) : -1 ) :
            [...pairs].filter((pair) => pair.status !== 'TRADING').sort((a, b) => (a.base < b.base) ? 1 : (a.base === b.base) ? ((a.quote < b.quote) ? 1 : -1) : -1 ));

        return (pairs && pairs.length > 0) ? (
            <ListWrapper>
                {isAuthenticated && (
                    <React.Fragment>
                        <Option
                            key='openOrders'
                            to='/market/openOrders'
                            exact
                            isActive={() => openOrders}
                        >
                            <span>
                                <BoldCoin>
                                    {(activeOrderIds > 0) ? (
                                        <FormattedMessage
                                            id="trade.allOpenOrdersNumeric"
                                            defaultMessage="All Open Orders ({orderCount})"
                                            values={{
                                                orderCount: activeOrderIds,
                                            }}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="trade.allOpenOrders"
                                            defaultMessage="All Open Orders"
                                        />
                                    )}
                                </BoldCoin>
                            </span>
                        </Option>
                        <br/>
                    </React.Fragment>
                )}
                <ListHeader>
                    <FormattedMessage
                        id="trade.activePairs"
                        defaultMessage="Active Pairs"
                    />

                    <FormattedMessage
                        id="trade.sort"
                        defaultMessage="Sort: {actionLabel}"
                        tagName="p"
                        values={{
                            actionLabel: (
                                <FormattedMessage
                                    id="trade.sortalpha"
                                    defaultMessage="Alpha"
                                >
                                    {message => <span onClick={onChangeSortDirection}>{message} {sortDirection === 'ASC' ? '↑' : '↓'}</span>}
                                </FormattedMessage>
                            )
                        }}
                    />
                </ListHeader>
                {sortedTrading && sortedTrading.map((pair, index) => (
                    <Option
                        key={index}
                        to={`/market/${pair.pair}`}
                        exact
                        isActive={() => activePair === pair.pair}
                    >
                        <CurrencyIcon currency={pair.base} />
                        <BoldCoin>
                            {pair.base}
                        </BoldCoin>
                        <BoldCoin>/</BoldCoin>
                        <BoldCoin>
                            {pair.quote}
                        </BoldCoin>
                        <CurrencyIcon currency={pair.quote} />
                    </Option>
                ))}
                {sortedNonTrading && sortedNonTrading.length > 0 && (
                    <React.Fragment>
                        <br/><br/>
                        <ListHeaderInactive>
                            <FormattedMessage
                                id="trade.inactivePairs"
                                defaultMessage="Inactive Pairs"
                            />
                        </ListHeaderInactive>
                        {sortedNonTrading.map((pair, index) => (
                            <OptionNonTrading
                                key={index}
                                to={`/market/${pair.pair}`}
                                exact
                                isActive={() => activePair === pair.pair}
                            >
                                <CurrencyIcon currency={pair.base} />
                                <BoldCoin>
                                    {pair.base}
                                </BoldCoin>
                                <BoldCoin>/</BoldCoin>
                                <BoldCoin>
                                    {pair.quote}
                                </BoldCoin>
                                <CurrencyIcon currency={pair.quote} />
                            </OptionNonTrading>
                        ))}
                    </React.Fragment>
                )}
            </ListWrapper>
        ) : (
            <SidebarLoader/>
        );
    }

}
