import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as SmallButton } from '../../../../components/SmallButton';

import {DisplayFiat, displayFormat} from '../../../../utils';
import { mobile } from '../../../../styles';

import config from 'config';
const isDebugStreamButtonsEnabled = config.get('devOpts.streamButtons');


const Wrapper = styled.div`
  flex: 1 auto;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  position: relative;

  font-size: 14px;

  ${mobile`
    padding: 5px 0px 0px 0px;
  `};
`;

const Bold = styled.span`
    font-weight: bold;

    ${mobile`
      text-align: center;
    `};
`;

const Tiny = styled.div`
    margin-right: 25px;
    font-size: 12px;

    ${mobile`
      margin-right: 0;
      text-align: center;
    `};
`;

const Table = styled.div`
  background-color: #FAFAFA;
  display: table;

  width: 100%;

  margin-bottom: 15px;

  ${mobile`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex-wrap: wrap;
    // border-bottom: 0px;
  `};
`;

const TableRow = styled.div`
  display: table-row;

  ${mobile`
    display: flex;
    flex-direction: row;

    // flex-wrap: wrap;
    justify-content: space-between;
  `};
`;

const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  text-align: center;
  // width: 25%;
  vertical-align: middle;

  padding: 10px 5px;

  border-bottom: 1px solid #eee;

  ${mobile`
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  `};
`;

const CellNoFlex = styled(Cell)`
  ${mobile`
    display: none;
    padding: 0;
  `};
`;

const CellRight = styled(Cell)`
  text-align: right;

  ${mobile`
    display: none;
    padding: 0;
  `};
`;

const CellLeftNoPadding = styled(Cell)`
  text-align: left;
  padding-left: 0px;

  ${mobile`
    text-align: center;
  `};
`;

const GreenSpan = styled.span`
  color: #00B200;
`;

const RedSpan = styled.span`
  color: #F00;
`;



export default class TradeTicker extends React.Component {

    static propTypes = {
        pair: PropTypes.string,

        fiatCurrency: PropTypes.string,
        usdToFiatRate: PropTypes.number,
        ticker: PropTypes.object,
        tradePair: PropTypes.object,

        startTickerFeed: PropTypes.func,
        stopTickerFeed: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        const { pair } = this.props;
        this.props.startTickerFeed({
            pair: pair,
        });
    }

    componentWillUnmount() {
        this.props.stopTickerFeed();

        document.title = 'Market (BlocPalX)';
    }

    componentDidUpdate() {
        const { tradePair, ticker, fiatCurrency, usdToFiatRate } = this.props;

        if (tradePair && tradePair.pair) {
            if (ticker && ticker.data && ticker.data.c) {
                if (fiatCurrency === 'USD') {
                    document.title = tradePair.pair + ': ' + ((!ticker.quote.includes('USD')) ? '$' + displayFormat((Number(ticker.data.us) / Number(ticker.data.Q)), 2) + ' ' + fiatCurrency : '$' + displayFormat((Number(ticker.data.c)), 2) + ' ' + fiatCurrency) + ' (BlocPalX)';
                } else {
                    document.title = tradePair.pair + ': ' + ((!ticker.quote.includes('USD')) ? displayFormat(((Number(ticker.data.us) * usdToFiatRate) / Number(ticker.data.Q)), 2) + ' ' + fiatCurrency : displayFormat((Number(ticker.data.c) * usdToFiatRate), 2) + ' ' + fiatCurrency) + ' (BlocPalX)';
                }
            } else {
                document.title = tradePair.pair + ' (BlocPalX)';
            }
        } else {
            document.title = 'Market (BlocPalX)';
        }
    }

    stopTickerFeed = () => {
        this.props.stopTickerFeed();
    };


    render() {

        const { ticker, fiatCurrency, usdToFiatRate } = this.props;

        // console.log(ticker);

        return (
            <Wrapper>
                <Table>
                    <TableRow>
                        {ticker.data && Number(ticker.data.Q) > 0 ? (
                            <React.Fragment>
                                <CellRight>
                                    <Tiny>
                                        LAST QTY
                                    </Tiny>
                                    <Bold>
                                        {ticker.data && ticker.data.Q} @
                                    </Bold>
                                    {(fiatCurrency !== 'USD') && (
                                        <Tiny>
                                            {ticker.data && (
                                                <DisplayFiat currency={fiatCurrency} value={Number(ticker.data.us) * usdToFiatRate}/>
                                            )}
                                        </Tiny>
                                    )}
                                    <Tiny>
                                        {ticker.data && (
                                            <DisplayFiat currency='USD' value={Number(ticker.data.us)}/>
                                        )}
                                    </Tiny>
                                </CellRight>
                                <CellLeftNoPadding>
                                    <Tiny>
                                        LAST PRICE
                                    </Tiny>
                                    <Bold>
                                        {ticker.data && displayFormat(ticker.data.c, ticker.data.d)}
                                    </Bold>
                                    {(fiatCurrency !== 'USD') && (
                                        <Tiny>
                                            {ticker.data && (
                                                <DisplayFiat currency={fiatCurrency} value={(Number(ticker.data.us) * usdToFiatRate) / Number(ticker.data.Q)}/>
                                            )}
                                        </Tiny>
                                    )}
                                    <Tiny>
                                        {(ticker.data && !ticker.quote.includes('USD')) ? (
                                            <DisplayFiat currency='USD' value={Number(ticker.data.us) / Number(ticker.data.Q)}/>
                                        ) : (
                                            <DisplayFiat currency='USD' value={Number(ticker.data.c)}/>
                                        )}
                                    </Tiny>
                                </CellLeftNoPadding>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <CellRight>
                                    N/A
                                </CellRight>
                                <CellLeftNoPadding />
                            </React.Fragment>
                        )}

                        <Cell>
                            <div>
                                <GreenSpan>24H &#9650; </GreenSpan>
                                {ticker.data && displayFormat(ticker.data.h, ticker.data.d)}
                            </div>
                            <div>
                                {(ticker && ticker.data && ticker.data.P) && (
                                    Number(ticker.data.P) > 0 ? (
                                        <GreenSpan>&#9650; </GreenSpan>
                                    ) : (
                                        Number(ticker.data.P) < 0 && (
                                            <RedSpan>&#9660; </RedSpan>
                                        )
                                    )
                                )}
                                {ticker.data && ticker.data.P && ticker.data.P.replace('-', '')}%
                                ({ticker.data && displayFormat(ticker.data.v, 2)} vol)
                            </div>
                            <div>
                                <RedSpan>24H &#9660; </RedSpan>
                                {ticker.data && displayFormat(ticker.data.l, ticker.data.d)}
                            </div>
                        </Cell>

                        <CellNoFlex>
                            <div>
                                <GreenSpan>7D &#9650; </GreenSpan>
                                {ticker.data && displayFormat(ticker.data.h7, ticker.data.d)}
                            </div>
                            <div>
                                {(ticker && ticker.data && ticker.data.P7) && (
                                    Number(ticker.data.P7) > 0 ? (
                                        <GreenSpan>&#9650; </GreenSpan>
                                    ) : (
                                        Number(ticker.data.P7) < 0 && (
                                            <RedSpan>&#9660; </RedSpan>
                                        )
                                    )
                                )}
                                {ticker.data && ticker.data.P7 && ticker.data.P7.replace('-', '')}%
                                ({ticker.data && displayFormat(ticker.data.v7, 2)} vol)
                            </div>
                            <div>
                                <RedSpan>7D &#9660; </RedSpan>
                                {ticker.data && displayFormat(ticker.data.l7, ticker.data.d)}
                            </div>
                        </CellNoFlex>

                        <CellNoFlex>
                            <div>
                                <GreenSpan>30D &#9650; </GreenSpan>
                                {ticker.data && displayFormat(ticker.data.h30, ticker.data.d)}
                            </div>
                            <div>
                                {(ticker && ticker.data && ticker.data.P30) && (
                                    Number(ticker.data.P30) > 0 ? (
                                        <GreenSpan>&#9650; </GreenSpan>
                                    ) : (
                                        Number(ticker.data.P30) < 0 && (
                                            <RedSpan>&#9660; </RedSpan>
                                        )
                                    )
                                )}
                                {ticker.data && ticker.data.P30 && ticker.data.P30.replace('-', '')}%
                                ({ticker.data && displayFormat(ticker.data.v30, 2)} vol)
                            </div>
                            <div>
                                <RedSpan>30D &#9660; </RedSpan>
                                {ticker.data && displayFormat(ticker.data.l30, ticker.data.d)}
                            </div>
                        </CellNoFlex>
                    </TableRow>
                </Table>

                {(isDebugStreamButtonsEnabled) && (
                    <SmallButton bsStyle='red' onClick={() => {this.stopTickerFeed();}} title={'Stop Ticker Feed'}>
                        <FormattedMessage
                            id="trade.stopTickerFeed"
                            defaultMessage="Stop Ticker Feed"
                        />
                    </SmallButton>
                )}

            </Wrapper>
        );
    }

}
