import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PortfolioWithdrawals from './PortfolioWithdrawals';
import {
    getCurrentWallet,
    getCoinNetworks,
    selectWithdrawalAddresses,
    isWithdrawalAllowed
} from '../../../../selectors';
import {
    requestWithdraw,
    withdrawRequestSent,
    clearWithdrawalAddresses,
    getWithdrawalAddresses,
    clearWithdrawRequest,
    reloadWalletActivity
} from '../../../../actions/portfolio';


function mapStateToProps(state, { coin }) {
    return {
        coin: coin,
        fiatCurrency: (state.portfolio.fiatCurrency) ? state.portfolio.fiatCurrency : 'USD',
        usdToFiatRate: state.portfolio.usdToFiatRate,
        coinConfig: state.currency.coinsConfig ? state.currency.coinsConfig.find(config => config.name === coin) || undefined : undefined,
        addresses: selectWithdrawalAddresses(state, coin),
        networkConfigs: getCoinNetworks(state, coin),
        wallet: getCurrentWallet(state, coin),
        isWithdrawalAllowed: isWithdrawalAllowed(state, coin),

        userSecuredMethods: (state.user && state.user.securedMethods) ? state.user.securedMethods : ['NONE'],
        userSecuredActions: (state.user && state.user.securedActions) ? state.user.securedActions : [],
        userKycTier: (state.user && state.user.kycTier) ? state.user.kycTier : 'NONE',
        userLocked: state.user.locked,
        userJurisdictionLockWhitelisted: state.user.jurisdictionLockWhitelisted,
        userWithdrawalsDisabled: state.user.withdrawalsDisabled,

        withdrawalRequestState: state.portfolio.withdrawalRequestState,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearWithdrawRequest,
            requestWithdraw,
            withdrawRequestSent,
            clearWithdrawalAddresses,
            getWithdrawalAddresses,
            reloadWalletActivity,
        },
        dispatch
    );
}

export default withRouter(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioWithdrawals)));
