import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {millisToMinutesAndSeconds} from '../../utils';
import SmallButton from '../SmallButton/SmallButton';


const SessionExpireWrapper = styled.div`
    width: 100%;
    background-color: #EF8632;
    // border-radius: 5px;
    // padding: 20px 40px;
    // height: 30px;
    position: relative;
    overflow: hidden;
`;

const SessionExpireText = styled.div`
    background-color: #EF8632;
    color: #FFFFFF;
    // height: 100%;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 1.05px;
    // text-transform: uppercase;
    // position: absolute,
`;

export default class SessionExpireNotice extends React.Component {

    static propTypes = {
        expiresInMillis: PropTypes.number,
        isAuthenticated: PropTypes.bool,

        extendSession: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeExpireInMillis: 900000,
        };
    }

    componentDidMount() {
        this.setActiveExpireTime(this.props.expiresInMillis);
    }

    componentDidUpdate(prevProps) {
        if (this.props.expiresInMillis !== prevProps.expiresInMillis) {
            this.setActiveExpireTime(this.props.expiresInMillis);
        }
    }

    decrementOneSecond() {
        this.setActiveExpireTime(this.state.activeExpireInMillis - 1000);
    }

    setActiveExpireTime(activeExpireTime) {
        this.setState({ activeExpireInMillis: activeExpireTime, });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.decrementOneSecond();
        }, 1000);
    }

    onSubmitExtendSession = () => {
        this.props.extendSession();
        this.setActiveExpireTime(900000);
    };

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        const { isAuthenticated } = this.props;
        const { activeExpireInMillis } = this.state;

        // console.log('wsState', wsState, hideFirstState);

        return isAuthenticated && activeExpireInMillis < 61000 && activeExpireInMillis > 0 && (
            <React.Fragment>
                <SessionExpireWrapper>
                    <SessionExpireText>
                        <FormattedMessage
                            id="notice.sessionExpiration"
                            defaultMessage="This session expires in {expireTime}. Are you still there?  "
                            values={{
                                expireTime: millisToMinutesAndSeconds(activeExpireInMillis),
                            }}
                        />
                        <SmallButton onClick={this.onSubmitExtendSession}>
                            <FormattedMessage
                                id="notice.sessionExpirationYesButton"
                                defaultMessage="YES"
                            />
                        </SmallButton>
                    </SessionExpireText>
                </SessionExpireWrapper>
            </React.Fragment>
        );
    }
}
