import PropTypes from 'prop-types';
import styled from 'styled-components';

const validationColorMap = {
    error: 'red',
};

const InputGroup = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0 3px;
  margin-top: 1em;
  margin-bottom: 1em;

  > label {
	color: ${props => props.validationState ? validationColorMap[props.validationState] : '#333333'};
	font-size: 14px;
	line-height: 20px;
    margin-bottom: 0.2em;
  }

  > input {
    border-color: ${props => props.validationState ? validationColorMap[props.validationState] : undefined};
  }

  > .select-wrapper {
    border-color: ${props => props.validationState ? validationColorMap[props.validationState] : undefined};
  }
`;

InputGroup.propTypes = {
    validationState: PropTypes.oneOf(['error']),
};

InputGroup.defaultProps = {
    validationState: undefined,
};

export default InputGroup;
