import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DepthChart from './DepthChart';
import { getDepthChartBuyData, getDepthChartSellData } from '../../../../selectors';

function mapStateToProps(state) {
    return {
        depthChartBuyData: getDepthChartBuyData(state),
        depthChartSellData: getDepthChartSellData(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepthChart);
