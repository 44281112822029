import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionCompleteStep from './TransactionCompleteStep';
import {
    getStatus,
    getTransactionCurrencyFromConfig,
    getTransactionCurrencyToConfig,
    getRate,
    getDayLimit,
    getProceedAddress,
    getWithdrawalAddress,
    getWithdrawalAmount,
    getWithdrawalTxId,
    getWithdrawalTs,
    getRefundAddress,
    getRefundAmount,
    getRefundTxId,
    getRefundTs,
    getWithdrawalFeeEstimate,
    getDepositMax,
    getDepositMin,
} from '../../../../selectors';

function mapStateToProps(state) {
    return {
        status: getStatus(state),
        currencyFromConfig: getTransactionCurrencyFromConfig(state),
        currencyToConfig: getTransactionCurrencyToConfig(state),
        refundAddress: getRefundAddress(state),
        refundAmount: getRefundAmount(state),
        refundTxId: getRefundTxId(state),
        refundTs: getRefundTs(state),
        proceedsAddress: getProceedAddress(state),
        withdrawalAddress: getWithdrawalAddress(state),
        withdrawalAmount: getWithdrawalAmount(state),
        withdrawalTxId: getWithdrawalTxId(state),
        withdrawalTs: getWithdrawalTs(state),
        rate: getRate(state),
        dayLimit: getDayLimit(state),
        transaction: state.transactions.transactionState,
        transactionId: state.transactions.transactionId,
        feeEstimate: getWithdrawalFeeEstimate(state),
        maxValue: getDepositMax(state),
        minValue: getDepositMin(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionCompleteStep);
