import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import styled from 'styled-components';
import { distanceInWords } from 'date-fns';

import { mobile } from '../../../../styles';
import { sigDigits } from '../../../../utils';

import { Component as InfoIcon } from '../../../../components/InfoIcon';
import { ColWrapper, RowWrapper } from '../../../../components/Layout';
import { Component as CurrencyStaticPair } from '../../../../components/CurrencyStaticPair';
import { Component as Slider } from '../../../../components/Slider';
import { Container as SubmitDepositInfo } from '../../../../components/SubmitDepositInfo';
import { Component as Spinner } from '../../../../components/Spinner';
import { LoadingDots } from '../../../../components/LoadingDots';


const BlockWrapper = styled.div`
  padding: 0 48px;
  border-bottom: 1px solid rgba(172,181,188,0.25);

  ${mobile`
    padding: 0 24px;
  `}
`;

const HeaderWrapper = styled(BlockWrapper)`
  padding-top: 15px;
  padding-bottom: 15px;

  ${mobile`
    border: none;
  `}
`;

const TransactionStatus = styled.p`
  color: #4A4A4A;
  font-size: 24px;	font-weight: 600;
  line-height: 36px;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

const ValuesWrapper = styled(BlockWrapper)`
  padding-top: 25px;
  padding-bottom: 25px;

  ${mobile`
    display: flex;
    flex-direction: column;
    // flex-direction: column-reverse;
    // padding: 0 10px;
  `}
`;

const TransactionID = styled.p`
	color: #4A4A4A;
	font-size: 14px;
	line-height: 21px;
	margin: 0;
`;

const LabeledItem = styled.div`
  label {
    color: #9B9B9B;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  p {
    color: #4A4A4A;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin: 0;
    overflow-wrap: break-word;
    line-height: 16px;
  }

  span {
    color: #727072;
    font-size: 12px;
    line-height: 16px;
    margin-top: 20px;
  }
`;

const AddressWrapper = styled(BlockWrapper)`
  display: flex;
  border-top: 1px solid rgba(172,181,188,0.25);

  ${mobile`
    flex-direction: column;
  `};
`;

const AddressLabel = styled(LabeledItem)`
  padding-top: 25px;
  padding-bottom: 25px;
  width: 300px;
  margin-right: 25px;

  display: flex;
  flex-direction: column;

  ${mobile`
    padding-top: 10px;
    padding-bottom: 10px;
    // width: 80%;
    margin: 0;
  `}
`;

const Footer = styled.div`
  height: 50px;
  background-color: #FFF6DE;
  text-align: center;
  line-height: 50px;
  color: #A4A4A4;
  font-size: 14px;
  border-radius: 0px 0px 5px 5px;

  a {
    color: #ef8632;
    line-height: 21px;
    margin-left: 5px;
  }

  ${mobile`
      font-size: 12px;
  `}

`;

const Equivalent = styled.div`
  display: inline-block;
  margin-left: 30px;
  padding-bottom: 2px;

  ${mobile`
    display: none;
  `};

  label {
    color: #9B9B9B;
    font-size: 14px;
  }

  a {
    color: #ef8632;
    font-size: 12px;
    line-height: 19px;
  }
`;

const Rate = styled.div`
  label {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #4A4A4A;

    ${mobile`
        font-size: 16px;
    `}
  }

  p {
    margin: 0;
    font-weight: 500;
  }

  span {
      color: #A3A3A3;
      font-size: 12px;
      line-height: 21px;
      margin-left: 5px;
      display: block;

      ${mobile`
        font-size: 16px;
        margin: 0;
      `}
  }

   div {
    vertical-align: middle;
    margin-left: 7px;

    div {
      margin: 0;
    }
  }
`;

const Row = styled(RowWrapper)`
  // justify-content: flex-start;
  justify-content: space-around;
  margin-top: 15px;
`;

const LeftCol = styled(ColWrapper)`
    flex-direction: initial;
    align-items: center;

    ${mobile`
        display: flex;
        flex-direction: column;
        width: 95%;

        > span {
            display: none;
        }
    `}

    span {
      margin: 0 15px;
    }

    i {
      font-style: normal;
    }
`;

const RightCol = styled(ColWrapper)`
    flex-direction: initial;
    align-items: center;
    margin-left: 30px;

    ${mobile`
        display: none;
    `}
`;

const SliderColumn = styled(ColWrapper)`
  width: 70%;

  ${mobile`
    width: 95%;
    justify-content: space-around;
    margin-top: 0;
  `}
`;

const SliderRow = styled(Row)`
    justify-content: space-around;
    align-items: center;
    margin-bottom: 15px;
    margin-left: 10px;
//  align-items: flex-end;
`;


const RateInfoIcon = styled(InfoIcon)`
  img {
    margin-left: 7px;
  }
`;

const MobileRate = styled(BlockWrapper)`
  padding-top: 25px;
  padding-bottom: 25px;
  display: none;

  ${mobile`
    display: block;
  `}
`;

const InfoBlock = styled.div`
  padding: 10px 30px 0px 30px;
  border-bottom: 1px solid rgba(172,181,188,0.25);

  ${mobile`
    margin: 0;
    text-align: center;
    justify-content: center;
  `}

  p {
    font-size: 14px;
  }

  h3 {
    color: #bd1a1a;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
    margin-bottom: 15px;

    ${mobile`
        text-align: center;
    `}
  }
`;


export default class TransactionDepositingStep extends React.Component {

    static propTypes = {
        transaction: PropTypes.object,
        status: PropTypes.string,
        amount: PropTypes.number,
        currencyFromConfig: PropTypes.object,
        currencyToConfig: PropTypes.object,
        networkFromConfig: PropTypes.object,
        networkToConfig: PropTypes.object,
        proceedAddress: PropTypes.string,
        depositAddress: PropTypes.string,
        refundAddress: PropTypes.string,
        transactionId: PropTypes.string,
        rate: PropTypes.number,
        rateUpdated: PropTypes.number,
        dayValue: PropTypes.number,
        dayLimit: PropTypes.number,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
        partialDepthPair: PropTypes.string,
        partialDepth: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentStep: 1,
            amount: sigDigits((this.props.minValue + this.props.maxValue) / 4, 6),
            sliderValue: sigDigits((this.props.minValue + this.props.maxValue) / 4, 6),
            submitValue: sigDigits((this.props.minValue + this.props.maxValue) / 4, 6),
            calculatedRate: 1,
            calculatedMin: 0,
            calculatedMax: 0,
            calculatedProceeds: 0,
        };
    }

    componentDidMount() {
        const { minValue, maxValue } = this.props;
        const initialAmount = sigDigits((minValue + maxValue) / 4, 6);
        this.setState({
            sliderValue: initialAmount,
        });

        this.calculate();
    }

    componentDidUpdate(prevProps, prevState) {
        const { minValue, maxValue, rate, partialDepth } = this.props;
        const { amount } = this.state;

        if (amount !== prevState.amount || rate !== prevProps.rate || minValue !== prevProps.minValue || maxValue !== prevProps.maxValue || partialDepth !== prevProps.partialDepth) {
            this.calculate();
        }
    }

    calculate() {
        let submissionAmount;
        const {
            minValue,
            maxValue,
            transaction,
            partialDepth,
            partialDepthPair,
            rate,
            networkToConfig,
            networkFromConfig,
        } = this.props;
        const { amount } = this.state;

        let calculatedRate = rate;
        let calculatedMin = 0;
        let calculatedMax = 0;

        if (partialDepth != null && partialDepthPair === (transaction.refundAddr.asset + '-' + transaction.targetAddr.asset)) {
            let executedTrades = {};
            submissionAmount = Number(amount);
            // account for an extra deposit step with ETH funnel deposit addresses
            if (networkFromConfig && networkFromConfig.name && networkFromConfig.name === 'ETH') {
                submissionAmount = Number(amount) - Number(networkFromConfig.networkFee);
            }
            let tradeRemainingAmount = Number(submissionAmount);
            let tradeAmount;

            Object.keys(partialDepth).forEach(function(orderPrice) {
                const orderAmount = Number(partialDepth[orderPrice]);
                tradeAmount = tradeRemainingAmount;
                if (tradeAmount > 0) {
                    if (tradeAmount > orderAmount) {
                        tradeAmount = Number(orderAmount);
                    }
                    executedTrades[orderPrice] = tradeAmount;
                    tradeRemainingAmount = tradeRemainingAmount - tradeAmount;
                }
                calculatedMin = calculatedMin + orderAmount;
                calculatedMax = calculatedMax + orderAmount;
            });

            if (calculatedMin > minValue) calculatedMin = minValue;
            if (calculatedMax > maxValue) calculatedMax = maxValue;

            let proceeds = 0;
            let denom = 0;
            Object.keys(executedTrades).forEach(function(executedPrice) {
                const executedAmount = Number(executedTrades[executedPrice]);
                proceeds = proceeds + (Number(executedPrice) * executedAmount);
                denom = denom + Number(executedAmount);
            });
            if (denom > 0) {
                calculatedRate = sigDigits((proceeds / denom) * 0.994, 6);
            }
        }

        this.setState({
            calculatedMin: calculatedMin,
            calculatedMax: calculatedMax,
            calculatedRate: sigDigits(((submissionAmount * calculatedRate)-networkToConfig.networkFee) / Number(amount), 6),
            calculatedProceeds: sigDigits(((submissionAmount * calculatedRate)-networkToConfig.networkFee), 6),
        });
    }

    onChange = amount => {
        this.setState({
            amount: amount[0],
        });
    }

    onSubmitChange = amount => {
        this.setState({
            submitValue: amount[0],
        });
    }

    /*
    onInputChange = event => this.setState({
        amount: event.target.value,
        sliderValue: event.target.value
    });
    */


    render() {

        const {
            maxValue,
            status,
            transaction,
            transactionId,
            rateUpdated,
            currencyToConfig,
            currencyFromConfig,
            networkToConfig,
            networkFromConfig,
            proceedAddress,
            refundAddress,
            dayValue,
            dayLimit } = this.props;
        const { amount, sliderValue, calculatedMin, calculatedMax, calculatedRate, calculatedProceeds } = this.state;
        const { depositAddr } = transaction;

        return <div>
            <HeaderWrapper>
                <TransactionID>
                    <FormattedMessage
                        id="oct.trade_id"
                        defaultMessage="Trade ID: {id}"
                        values={{
                            id: transactionId
                        }}
                    />
                </TransactionID>
                <TransactionStatus>
                    {status === 'DEPOSIT_ADDR_NOT_ASSIGNED' ? (
                        <FormattedMessage
                            id="oct.generating_deposit_address"
                            defaultMessage="Generating Deposit Address"
                        />
                    ) : null}
                    {status === 'DEPOSIT_ADDR_ASSIGNED' ? (
                        <FormattedMessage
                            id="oct.awaiting_deposit"
                            defaultMessage="Awaiting Your Deposit"
                        />
                    ) : null}
                </TransactionStatus>
                {status === 'DEPOSIT_ADDR_NOT_ASSIGNED' ? (
                    <Spinner />
                ) : null}
            </HeaderWrapper>
            <ValuesWrapper>
                <Row>
                    <LeftCol>
                        <CurrencyStaticPair
                            currencyFrom={transaction.refundAddr.asset}
                            valueFrom={Number(amount)}
                            currencyTo={transaction.targetAddr.asset}
                            valueTo={calculatedProceeds}
                        />
                    </LeftCol>
                    <RightCol>
                        <Rate>
                            <FormattedMessage
                                id="oct.exchange_rate"
                                defaultMessage="Exchange Rate"
                                tagName="label"
                            />
                            {rateUpdated &&
                            <p>
                                1 {transaction.refundAddr.asset} ≈ {calculatedRate} {transaction.targetAddr.asset}
                                <span>
                                    <FormattedMessage
                                        id="oct.rate_updated"
                                        defaultMessage="Rate updated {time} ago"
                                        values={{
                                            time: distanceInWords(new Date(), new Date(rateUpdated))
                                        }}
                                    >{(txt) => txt}</FormattedMessage>
                                    <RateInfoIcon
                                        content={
                                            <FormattedMessage
                                                id="oct.rate_tooltip"
                                                defaultMessage="The exchange rate will adjust to the current rate as of the time the deposit is received. To reduce your risk, we usually execute the trade after one confirmation. Exchange rate displayed does not account for blockchain transaction fees."
                                            />
                                        }
                                    />
                                </span>
                            </p>}
                            {!rateUpdated && <LoadingDots />}
                        </Rate>
                    </RightCol>
                </Row>

                { maxValue > 0 ? (
                    <SliderRow>
                        <SliderColumn>
                            {sliderValue && calculatedMin &&
                            <Slider
                                value={sliderValue}
                                currency={transaction.refundAddr.asset}
                                onChange={this.onChange}
                                onUpdate={this.onSubmitChange}
                                usdRate={currencyFromConfig.coinPrice}
                                minValue={calculatedMin}
                                maxValue={calculatedMax}
                            />}
                        </SliderColumn>
                        <ColWrapper>
                            <Equivalent>
                                <FormattedMessage
                                    id="oct.equivalent"
                                    defaultMessage="Limit: ${limit} Remaining Today"
                                    values={{
                                        limit: dayValue
                                    }}
                                    tagName="label"
                                /><br/>
                                <FormattedHTMLMessage
                                    id="oct.canada_trade_regulation"
                                    defaultMessage="<a href=https://www.fintrac-canafe.gc.ca/guidance-directives/client-clientele/client/msb-eng target=_blank>Canadian Regulations</a>"
                                />
                            </Equivalent>
                        </ColWrapper>
                    </SliderRow>
                ) : (
                    <Equivalent>
                        <br/>
                        <FormattedMessage
                            id="oct.limit_reached_today"
                            defaultMessage="You've already reached your trade limit of ${limit} for today. Come back to this same URL tomorrow to execute this trade."
                            values={{
                                limit: dayLimit
                            }}
                            tagName="label"
                        /><br/>
                        <FormattedHTMLMessage
                            id="oct.canada_trade_regulation"
                            defaultMessage="<a href=https://www.fintrac-canafe.gc.ca/guidance-directives/client-clientele/client/msb-eng target=_blank>Canadian Regulations</a>"
                        />
                    </Equivalent>
                )}
            </ValuesWrapper>
            <MobileRate>
                <Rate>
                    <FormattedMessage
                        id="oct.exchange_rate"
                        defaultMessage="Exchange Rate"
                        tagName="label"
                    />
                    <p>
                        1 {transaction.refundAddr.asset} = {calculatedRate} {transaction.targetAddr.asset}
                    </p>
                    <span>
                        <FormattedMessage
                            id="oct.rate_updated"
                            defaultMessage="Rate updated {time} ago"
                            values={{
                                time: distanceInWords(new Date(), new Date(rateUpdated))
                            }}
                        />
                        <FormattedMessage
                            id="oct.rate_tooltip"
                            defaultMessage="The exchange rate will adjust to the current rate as of the time the deposit is received. To reduce your risk, we usually execute the trade after one confirmation. Exchange rate displayed does not account for blockchain transaction fees."
                        />
                    </span>
                </Rate>
            </MobileRate>

            {(networkFromConfig.networkFee * currencyFromConfig.coinPrice > 1) && (
                <InfoBlock>
                    <FormattedMessage
                        id="oct.high_source_fee_notice"
                        defaultMessage="Warning: The {currency} blockchain is currently congested, causing a high TxFee. (approx ${txFeeUsd} USD)"
                        tagName="h3"
                        values={{
                            currency: currencyFromConfig.label,
                            txFeeUsd: Number(networkFromConfig.networkFee * currencyFromConfig.coinPrice).toFixed(2),
                        }}
                    />
                    <FormattedMessage
                        id="oct.fees_out_of_our_control"
                        defaultMessage="Proceeds of trades automatically adjust to account for blockchain fees. Unfortunately blockchain fees are out of our control."
                        tagName="p"
                    />
                </InfoBlock>
            )}

            {((networkToConfig.networkFee * currencyToConfig.coinPrice) > 1) && (
                <InfoBlock>
                    <FormattedMessage
                        id="oct.high_dest_fee_notice"
                        defaultMessage="Warning: The {currency} blockchain is currently congested, causing a high TxFee. (approx ${txFeeUsd} USD)"
                        tagName="h3"
                        values={{
                            currency: currencyToConfig.label,
                            txFeeUsd: Number(networkToConfig.networkFee * currencyToConfig.coinPrice).toFixed(2),
                        }}
                    />
                    <FormattedMessage
                        id="oct.fees_out_of_our_control"
                        defaultMessage="Proceeds of trades automatically adjust to account for blockchain fees. Unfortunately blockchain fees are out of our control."
                        tagName="p"
                    />
                </InfoBlock>
            )}

            <SubmitDepositInfo
                addressObj={depositAddr}
                tagLabel={currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.depositAddr.network).addressTagName}
                minAmount={calculatedMin}
                maxAmount={calculatedMax}
                amount={Number(amount)}
                coinConfig={currencyFromConfig}
            />

            { depositAddr && depositAddr.address && (
                <AddressWrapper>
                    <AddressLabel>
                        <FormattedMessage
                            id="oct.refund_currency"
                            defaultMessage="Your {currency} Refund Address"
                            tagName="label"
                            values={{
                                currency: currencyFromConfig.label
                            }}
                        />
                        <p>
                            {refundAddress}
                        </p>
                        {currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.refundAddr.network).addressTagName && transaction.refundAddr.tag ? (
                            <React.Fragment>
                                <FormattedMessage
                                    id="oct.refund_tag_label"
                                    defaultMessage="Your {label} Refund {name}"
                                    tagName="label"
                                    values={{
                                        label: currencyFromConfig.label,
                                        name: currencyFromConfig.networks.find(networkConfig => networkConfig.name === transaction.refundAddr.network).addressTagName.replace(' (Optional)', ''),
                                    }}
                                />
                                <p>
                                    {transaction.refundAddr.tag}
                                </p>
                            </React.Fragment>
                        ) : null}
                    </AddressLabel>

                    <AddressLabel>
                        <FormattedMessage
                            id="oct.proceed_currency"
                            defaultMessage="Your {currency} Proceeds Address"
                            tagName="label"
                            values={{
                                currency: currencyToConfig.label
                            }}
                        />
                        <p>
                            {proceedAddress}
                        </p>
                        {currencyToConfig.networks.find(networkConfig => networkConfig.name === transaction.targetAddr.network).addressTagName && transaction.targetAddr.tag ? (
                            <React.Fragment>
                                <FormattedMessage
                                    id="oct.proceeds_tag_label"
                                    defaultMessage="Your {label} Proceeds {name}"
                                    tagName="label"
                                    values={{
                                        label: currencyToConfig.label,
                                        name: currencyToConfig.networks.find(networkConfig => networkConfig.name === transaction.targetAddr.network).addressTagName.replace(' (Optional)', ''),
                                    }}
                                />
                                <p>
                                    {transaction.targetAddr.tag}
                                </p>
                            </React.Fragment>
                        ) : null}
                    </AddressLabel>
                </AddressWrapper>
            ) }

            <Footer>
                <FormattedMessage
                    id="oct.terms_agree"
                    defaultMessage="By performing a deposit you agree to be bound by our {link}"
                    values={{
                        link: (<a href="/terms">Terms of Service</a>)
                    }}
                />
            </Footer>
        </div>;
    }

}
