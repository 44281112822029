import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Component as CurrencyImage } from '../../../../components/CurrencyImage';
import {
    Table,
    TableSortHeader,
    HeaderRowNoMobile,
    TableRow,
    HeaderCellLeft,
    HeaderCellLeftNoMobile,
    CellLeft,
    CellLeftNoMobile,
    LastCellNoMobile,
    SortButton,
    LastCellNoDesktop,
} from '../../../../commonStyles/TableStyles.js';

import {DisplayFiat} from '../../../../utils';
import { mobile } from '../../../../styles';


const Wrapper = styled.div`
  flex: 1 auto;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  position: relative;

  padding: 10px;

  width: 100%;
  
  ${mobile`
    padding: 5px 0px 0px 0px;
  `};
`;

const StyledPair = styled.div`
  // width: 100px;
  display: flex;
  // border: 2px solid #eee;
  // border-radius: 5px;
  // border-bottom: 1px solid #eee;
  // position: relative;
  padding: 10px;
  // padding: 15px 0px 15px 0px;
  // white-space: pre-wrap;
  // padding: 20px 20px 20px 20px;
  text-decoration: none;
  // margin: 10px 0px;
  vertical-align: middle;

  color: #232323;
  justify-content: space-between;

  &.active {
    background-color: #ef863214;
    border-color: #ef863214;
  }

  >span {
    // display: flex;
    // vertical-align: middle;
    // align-items: center;
    // height: 24px;
    margin-right: auto;
    img {
      margin-right: 10px;
    }
  }

  ${mobile`
    padding: 10px 5px 10px 5px;
    // width: 160px;
  `};
`;

const Bold = styled.span`
    font-weight: bold;

    ${mobile`
      text-align: center;
    `};
`;

const CurrencyIcon = styled(CurrencyImage)`
  margin: auto 15px auto 4px;

  width: 40px;
  height: 40px;

  ${mobile`
      width: 30px;
      height: 30px;
  `};
`;

const ClickSpan = styled.span`
  color: #ef8632;
  cursor: pointer;
`;

/*
import DropdownMenu from 'react-dd-menu';
const StyledMenu = styled(DropdownMenu)`
  display: inline-block;
  margin-left: 5px;
  position: relative;

  >span {
    cursor: pointer;
    color: #ef8632;
    text-transform: capitalize;
  }

  .dd-menu-items {
    position: absolute;
    width: 170px;
    background-color:#fff;
    box-shadow: 0 0 2px #ddd;

    ul {
      margin: 0;
      padding: 0;

      span {
        display: block;
        text-transform: capitalize;
        cursor: pointer;
        padding: 3px 15px;

        &:hover {
          background-color:#eee;
        }
      }
    }
  }
`;
*/

const BlueButton = styled(Link)`
    width: 150px;
    margin-right: 5px;
    // background: linear-gradient(90deg, rgba(2, 23, 71, 1) 0%, rgba(2, 23, 71, 0.5) 100%);
    background: rgba(2, 23, 71, 1);
    color: #FFFFFF;
    text-shadow: 0 1px 1px 0 rgba(0,169,13,0.5);
    padding: 12px 16px;
    border-radius: 5px;
    text-decoration: none;
    border: 0;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    letter-spacing: 1.05px;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
`;


export default class ProjectsList extends React.Component {

    static propTypes = {
        coinsConfig: PropTypes.array,
        fiatCurrency: PropTypes.string,
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            projects: null,
            usdToFiatRate: 1,  // TODO: figure out how to source this without logging in...
            sortDirection: 'DESC',
            sortColumn: 'VALUE',
        };
    }

    componentDidMount() {
        this.sort();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.sortDirection !== prevState.sortDirection || this.state.sortColumn !== prevState.sortColumn) {
            this.sort();
        }
    }

    sort() {
        const { coinsConfig } = this.props;
        const { sortDirection, sortColumn } = this.state;

        if (sortColumn === 'NAME') {
            this.setState({
                projects: [...coinsConfig].sort(
                    (prev, next) => sortDirection === 'ASC' ?
                        prev.name > next.name :
                        next.name > prev.name
                ),
            });
        } else {
            this.setState({
                projects: [...coinsConfig].sort(
                    (prev, next) => sortDirection === 'ASC' ?
                        Number(prev.coinPrice) - Number(next.coinPrice) :
                        Number(next.coinPrice) - Number(prev.coinPrice)
                ),
            });
        }
    }

    onChangeSortDirection = (column) => {
        this.setState({
            sortColumn: column,
            sortDirection: this.state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
        });
    };


    render() {
        const { history, fiatCurrency } = this.props;
        const { projects, sortDirection, sortColumn, usdToFiatRate } = this.state;

        return (
            <Wrapper>
                <Table>
                    <TableSortHeader>
                        <CellLeft>
                            <SortButton>
                                <FormattedMessage
                                    id="portfolio.sort"
                                    defaultMessage="Sort: {actionLabel}"
                                    values={{
                                        actionLabel: (
                                            <FormattedMessage
                                                id="portfolio.sortName"
                                                defaultMessage="Name"
                                            >
                                                {message => <ClickSpan onClick={() => { this.onChangeSortDirection('NAME'); }}>{message} {sortColumn === 'NAME' && (sortDirection === 'ASC' ? '↑' : '↓')}</ClickSpan>}
                                            </FormattedMessage>
                                        )
                                    }}
                                />
                            </SortButton>
                        </CellLeft>
                        <CellLeftNoMobile>
                            <SortButton>
                                <FormattedMessage
                                    id="portfolio.sort"
                                    defaultMessage="Sort: {actionLabel}"
                                    values={{
                                        actionLabel: (
                                            <FormattedMessage
                                                id="portfolio.sortValue"
                                                defaultMessage="Value"
                                            >
                                                {message => <ClickSpan onClick={() => { this.onChangeSortDirection('VALUE'); }}>{message} {sortColumn === 'VALUE' && (sortDirection === 'ASC' ? '↑' : '↓')}</ClickSpan>}
                                            </FormattedMessage>
                                        )
                                    }}
                                />
                            </SortButton>
                        </CellLeftNoMobile>
                        <LastCellNoDesktop>
                            <SortButton>
                                <FormattedMessage
                                    id="portfolio.sort"
                                    defaultMessage="Sort: {actionLabel}"
                                    values={{
                                        actionLabel: (
                                            <FormattedMessage
                                                id="portfolio.sortValue"
                                                defaultMessage="Value"
                                            >
                                                {message => <ClickSpan onClick={() => { this.onChangeSortDirection('VALUE'); }}>{message} {sortColumn === 'VALUE' && (sortDirection === 'ASC' ? '↑' : '↓')}</ClickSpan>}
                                            </FormattedMessage>
                                        )
                                    }}
                                />
                            </SortButton>
                        </LastCellNoDesktop>
                        <CellLeftNoMobile/>
                        <CellLeftNoMobile/>
                        <CellLeftNoMobile/>
                    </TableSortHeader>

                    <HeaderRowNoMobile>
                        <HeaderCellLeft>
                            <FormattedMessage
                                id="portfolio.activeAsset"
                                defaultMessage="Active Assets"
                            />
                        </HeaderCellLeft>
                        <HeaderCellLeft>
                            <FormattedMessage
                                id="portfolio.price"
                                defaultMessage="Price"
                            />
                        </HeaderCellLeft>
                        <HeaderCellLeftNoMobile>
                            <FormattedMessage
                                id="portfolio.maker"
                                defaultMessage="Maker Fee"
                            />
                        </HeaderCellLeftNoMobile>
                        <HeaderCellLeftNoMobile>
                            <FormattedMessage
                                id="portfolio.taker"
                                defaultMessage="Taker Fee"
                            />
                        </HeaderCellLeftNoMobile>
                        <HeaderCellLeftNoMobile/>
                    </HeaderRowNoMobile>

                    {projects && projects.map((coinConfig, i) => (
                        <React.Fragment key={'active-' + coinConfig.name}>
                            {coinConfig.trading && (
                                <TableRow key={i}>

                                    <CellLeft onClick={() => { history.push('/projects/' + coinConfig.name); }}>
                                        <StyledPair
                                            key={i}
                                            to={`/projects/${coinConfig.name}`}
                                            exact
                                        >
                                            <CurrencyIcon currency={coinConfig.name} />
                                            <span>
                                                <Bold>
                                                    {coinConfig.name}<br/>
                                                </Bold>
                                                {coinConfig.label}
                                            </span>
                                        </StyledPair>
                                    </CellLeft>

                                    <CellLeftNoMobile onClick={() => { history.push('/projects/' + coinConfig.name); }}>
                                        {fiatCurrency === 'USD' ? (
                                            <Bold>
                                                <DisplayFiat currency={fiatCurrency} value={coinConfig.coinPrice}/>
                                            </Bold>
                                        ) : (
                                            <Bold>
                                                <DisplayFiat currency={fiatCurrency} value={coinConfig.coinPrice * (usdToFiatRate !== 0 ? usdToFiatRate : 0)}/>
                                            </Bold>
                                        )}
                                    </CellLeftNoMobile>

                                    <LastCellNoDesktop onClick={() => { history.push('/projects/' + coinConfig.name); }}>
                                        {fiatCurrency === 'USD' ? (
                                            <Bold>
                                                <DisplayFiat currency={fiatCurrency} value={coinConfig.coinPrice}/>
                                            </Bold>
                                        ) : (
                                            <Bold>
                                                <DisplayFiat currency={fiatCurrency} value={coinConfig.coinPrice * (usdToFiatRate !== 0 ? usdToFiatRate : 0)}/>
                                            </Bold>
                                        )}
                                    </LastCellNoDesktop>

                                    <CellLeftNoMobile>
                                        {(Number(coinConfig.makerFee * 100) > 0) ? (coinConfig.makerFee * 100) + '%' : (
                                            <Bold>
                                                Free
                                            </Bold>
                                        )}
                                    </CellLeftNoMobile>
                                    <CellLeftNoMobile>
                                        {(coinConfig.takerFee) ? (coinConfig.takerFee * 100) + '%' : '- -'}
                                    </CellLeftNoMobile>

                                    <LastCellNoMobile>
                                        <BlueButton to={`/projects/${coinConfig.name}`}>
                                            <FormattedMessage
                                                id="projects.learnMore"
                                                defaultMessage="Learn More..."
                                            />
                                        </BlueButton>
                                    </LastCellNoMobile>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}

                </Table>
            </Wrapper>
        );
    }
}
