import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Component as Button } from '../../../../components/Button';
import { Container as TradeOrderPlacementForm } from '../../components/TradeOrderPlacementForm';
import { Container as TradeMyOrders } from '../../components/TradeMyOrders';
import { Container as MyTradeHistory } from '../../components/MyTradeHistory';
import { Container as TradeHistory } from '../../components/TradeHistory';

import { mobile } from '../../../../styles';


const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;

  margin-left: 5px;
  margin-top: 10px;

  position: relative;
  font-size: 14px;
  font-weight: bold;

  a {
    color: #000;
    background-color: #FAFAFA;
    border: 1px solid #CFCFCF;
    border-radius: 3px 7px 0px 0px;
    cursor: pointer;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: -1px;
  }

  a.selected, a:hover {
    color: #fff;
    background-color: #ef8632;
    border-bottom: 1px solid #FAFAFA;
    z-index: 2;
  }

  div.mobileOnly {
      display: none;
      ${mobile`
        display: block;
      `};
  }

  div.desktopOnly {
      display: block;
      ${mobile`
        display: none;
      `};
  }

  ${mobile`
    font-size: 12px;
  `};
`;

const OrderFormContainer = styled.div`
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 98%;

  background-color: ${props => props.bgColor || '#FFF'};

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;

  border: 1px solid #CFCFCF;  

  ${mobile`
    flex-wrap: wrap;
    min-width: 300px;
  `};
`;

const BuySellItem = styled.div`
  flex-grow: 1;
`;

const BuySellItemNoMobile = styled(BuySellItem)`
  ${mobile`
    display: none;
  `};
`;

const TradingDisabled = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 2px 0px 2px 0px;
  
  border: 2px solid #FF0000;
  color: #FF0000;
  background: #fff;
  border-radius: 5px;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-transform: capitalize;
  letter-spacing: 1.05px;
  
  width: 340px;

  ${mobile`
    width: 280px;
  `};
`;

const LoginButton = styled(Button)`
  border: 2px solid #ef8632;
  color: #ef8632;
  background: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;

  width: 340px;

  ${mobile`
    width: 280px;
  `};
`;

const LoginBlock = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const TabHeader = styled.div`
  display: flex;  
  flex-direction: column;  
  position: relative;
  cursor: pointer;
  margin-left: 2px;
`;

const TabHighlighter = styled.div`
  height: 3px;
  background-color: ${props => props.selected ? props.theme.colors.primary2 : '#FFFFFF' };
  position: absolute;
  bottom: 0;
  left: 0;  
  right: 0;
`;

const TabHeaderTitle = styled.div`
  text-align: center;
  // font-size: 10px;
  padding: 5px 10px 10px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  font-style: normal;
  color: ${props => props.selected ? props.theme.colors.primary2 : '#262626' };
  line-height: 10px;
  letter-spacing: 0em;    
`;

const LoginGroup = withRouter(({ history }) => (
    <LoginBlock>
        <LoginButton onClick={() => { history.push('/login'); }}>
            <FormattedMessage
                id="trade.signInToView"
                defaultMessage="Sign In To View"
            />
        </LoginButton>
    </LoginBlock>
));


const TABS = ['Buy', 'Sell', 'Trade', 'Open Orders', 'My Trades', 'All Trades'];


export default class TradeTabGroup extends React.Component {

    static propTypes = {
        pair: PropTypes.string,

        baseCoinConfigTakerFee: PropTypes.number,
        quoteCoinConfigTakerFee: PropTypes.number,
        isAuthenticated: PropTypes.bool,
        tradePairStatus: PropTypes.string,

        clearOrderBookFeed: PropTypes.func,
        startOrderBookFeed: PropTypes.func,
        stopOrderBookFeed: PropTypes.func,
    };

    static defaultProps = {
        pair: null
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 'Trade',
        };
    }


    componentDidMount() {
        const marketScreenSelectedTab = localStorage.getItem('marketScreenSelectedTab');
        if (marketScreenSelectedTab) {
            this.changeSelectedTab(marketScreenSelectedTab);
        }
    }

    changeSelectedTab = (tab) => {
        const { selectedTab } = this.state;
        if (tab !== selectedTab) {
            this.setState({
                selectedTab: tab,
            });
            localStorage.setItem('marketScreenSelectedTab', tab);
        }
    };

    renderTabHeaders = () => {        
        return (
            <Tabs>               
                {
                    TABS.map((value, index) => (
                        <TabHeader 
                            key={index}
                            onClick={() => this.changeSelectedTab(value)}
                            className={((value === this.state.selectedTab) ? 'selected' : '') + ((value==='Buy' || value==='Sell') ? ' mobileOnly' : '') + ((value==='Trade') ? ' desktopOnly' : '')}
                        >
                            <TabHeaderTitle selected={value === this.state.selectedTab}>{value}</TabHeaderTitle>
                            <TabHighlighter selected={value === this.state.selectedTab}/>
                        </TabHeader>  
                    )) 
                }
            </Tabs>
        );
    }


    render() {
        const { baseCoinConfigTakerFee, quoteCoinConfigTakerFee, pair, tradePairStatus, isAuthenticated } = this.props;
        const { selectedTab } = this.state;

        // console.log(buyOrders);
        // console.log(sellOrders);

        // console.log('CURRENT PAIR');
        // console.log(tradePair);

        // console.log(isAuthenticated);


        return (
            <React.Fragment>
                {this.renderTabHeaders()}
                <OrderFormContainer bgColor={(selectedTab === 'Buy' || selectedTab === 'Sell' || selectedTab === 'Trade') ? '#FAFAFA' : '#FFF'}>
                    {(selectedTab === 'Buy') && (
                        <BuySellItem>
                            <TradeOrderPlacementForm takerFee={baseCoinConfigTakerFee} side='BUY'/>
                        </BuySellItem>
                    )}
                    {(selectedTab === 'Sell') && (
                        <BuySellItem>
                            <TradeOrderPlacementForm takerFee={quoteCoinConfigTakerFee} side='SELL'/>
                        </BuySellItem>
                    )}
                    {(selectedTab === 'Trade') && (
                        (tradePairStatus && tradePairStatus === 'TRADING') ? (
                            <React.Fragment>
                                <BuySellItem>
                                    <TradeOrderPlacementForm takerFee={baseCoinConfigTakerFee} side='BUY'/>
                                </BuySellItem>
                                <BuySellItemNoMobile>
                                    <TradeOrderPlacementForm takerFee={quoteCoinConfigTakerFee} side='SELL'/>
                                </BuySellItemNoMobile>
                            </React.Fragment>
                        ) : (
                            <TradingDisabled>
                                <FormattedMessage
                                    id="trade.disabled"
                                    defaultMessage="Trade Pair Inactive"
                                    tagName="p"
                                />
                            </TradingDisabled>
                        )
                    )}
                    {(selectedTab === 'Open Orders') && (
                        isAuthenticated ? (
                            <TradeMyOrders/>
                        ) : (
                            <LoginGroup />
                        )
                    )}
                    {(selectedTab === 'My Trades') && (
                        isAuthenticated ? (
                            <MyTradeHistory pair={pair}/>
                        ) : (
                            <LoginGroup />
                        )
                    )}
                    {(selectedTab === 'All Trades') && (
                        <TradeHistory pair={pair}/>
                    )}

                </OrderFormContainer>
            </React.Fragment>
        );
    }
}
