import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {Component as Button} from '../../../../components/Button';
import {Component as Input} from '../../../../components/Input';
import {Container as InputTwoFa} from '../../../../components/InputTwoFa';

import {validateAnyAuthCode, validateGenericRegexp} from '../../../../utils';
import {mobile} from '../../../../styles';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import copyIcon from '../../../../images/ic-copy.svg';
import {PortfolioTitle} from '../../../../commonStyles/GeneralStyles';
import {StyledSelect} from '../../../../commonStyles/FormStyles';
import InputGroup from '../../../../components/InputGroup/InputGroup';
import {ItemIncomplete, ItemText, ItemTextTitle} from '../../../../commonStyles/AccountProfileStyles';


const VALIDATION_MESSAGES = {
    network: (
        <FormattedMessage
            id="error.network_required"
            defaultMessage="Network must be set"
        />
    ),
    label: (
        <FormattedMessage
            id="error.label_required"
            defaultMessage="Label must be set"
        />
    ),
    address: (
        <FormattedMessage
            id="error.address_required"
            defaultMessage="Address must be set"
        />
    ),
    tag: (
        <FormattedMessage
            id="error.tag_required"
            defaultMessage="Tag must be set"
        />
    ),
    code: (
        <FormattedMessage
            id="error.code_required"
            defaultMessage="2FA Code must be set"
        />
    ),
};


const Container = styled.div`
  padding: 20px;
`;

const AddressActions = styled.div`
  color: #ef8632;
  font-size: 12px;

  img {
    margin-right: 3px;
  }

  span {
    margin-right: 10px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
`;

const Address = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #eee;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  p {
    margin-top: 0;
	color: #4A4A4A;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.25px;
	line-height: 13px;

	span {
        border-radius: 3px;
        background-color: rgba(172,181,188,0.24);
        color: #9B9B9B;
        font-size: 12px;
        line-height: 20px;
        font-weight: 300;
        padding: 4px 7px;
        margin-left: 5px;
	}
  }

  >span {
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
  }
`;

const AuthRow = styled.div`
  margin-left: 0;
  margin-top: 10px;
`;

const InfoRow = styled.div`
  margin-left: 0;
  margin-top: 25px;
`;

const LeftInputTwoFa = styled.div`
  display: inline-block;
  width: 150px;
  margin-right: 5px;
  margin-top: 15px;
  vertical-align: top;
`;

const RightButtonTwoFa = styled(Button)`
  // display: block;
  // width: 47%;
  width: 150px;
  margin-left: 5px;
  margin-top: 32px;
  vertical-align: top;
`;

const WideButton = styled(Button)`
  display: block;
  width: 310px;
  margin-top: 35px;
`;

const WithdrawalAddress = styled(Input)`
  padding: 10px;
  border-radius: 3px;
  width: 400px;
  border: 1px solid #ddd;

  ${mobile`
    width: 350px;
  `}
`;

const WithdrawalAddressTag = styled(Input)`
  padding: 10px;
  border-radius: 3px;
  width: 200px;
  border: 1px solid #ddd;
`;


export default class PortfolioAddressBook extends React.Component {

    static propTypes = {
        coin: PropTypes.string,

        networkOptions: PropTypes.array,
        networkConfigs: PropTypes.object,
        addresses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            asset: PropTypes.string,
            network: PropTypes.string,
            address: PropTypes.string,
            tag: PropTypes.string,
            label: PropTypes.string,
            used: PropTypes.bool
        })),
        refreshWithdrawalAddresses: PropTypes.bool,
        user: PropTypes.object,

        setWithdrawAddress: PropTypes.func,
        deleteWithdrawAddress: PropTypes.func,
        clearWithdrawalAddresses: PropTypes.func,
        getWithdrawalAddresses: PropTypes.func,

        intl: PropTypes.object,
    };

    state = {
        errors: [],
        network: '',
        address: '',
        tag: '',
        label: '',
        code: '',
        addressCopied: -1,
        tagCopied: -1,
        displayNewAddressForm: false,
        displayEditAddressForm: false,
    };

    componentDidMount() {
        if (this.props.coin) {
            this.props.clearWithdrawalAddresses();
            this.props.getWithdrawalAddresses(this.props.coin);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.refreshWithdrawalAddresses !== prevProps.refreshWithdrawalAddresses && this.props.refreshWithdrawalAddresses === true) {
            this.props.clearWithdrawalAddresses();
            this.props.getWithdrawalAddresses(this.props.coin);

            this.setState({
                errors: [],
                network: '',
                address: '',
                tag: '',
                label: '',
                code: '',
            });
        }
    }

    shouldComponentUpdate(nextProps) {
        // don't want to redraw for every exchange config update
        if (this.props.networkConfigs !== nextProps.networkConfigs) {
            return false;
        }
        return true;
    }

    getError = field => {
        const error = this.state.errors.find(e => e.field === field);
        return error ? error.message : null;
    };

    onSelectNetwork = (key) => {
        this.setState({
            network: key.value,
        });
    };

    validateInput = () => {
        const { user, networkConfigs } = this.props;
        const { network } = this.state;

        const requiredFields = [
            'address',
            'network',
            'label',
            (user && !user.securedMethods.includes('NONE') && user.securedActions.includes('WITHDRAW')) ? 'code' : undefined,
            (networkConfigs && network && networkConfigs[network].addressTagRegex !== '') ? 'tag' : undefined,
        ];

        const validations = [
            {
                field: 'code',
                validate: code => {
                    return validateAnyAuthCode(code);
                },
                message: (
                    <FormattedMessage
                        id="error.invalid_code"
                        defaultMessage="Invalid code"
                    />
                )
            },
            {
                field: 'address',
                validate: address => {
                    return validateGenericRegexp(address, networkConfigs[network].addressRegex);
                },
                message: (
                    <FormattedMessage
                        id="error.invalid_address"
                        defaultMessage="Invalid {addressLabel}"
                        values={{
                            addressLabel: networkConfigs[network].addressName
                        }}
                    />
                )
            },
            (networkConfigs && network && networkConfigs[network].addressTagRegex !== '') ? {
                field: 'tag',
                validate: tag => {
                    return validateGenericRegexp(tag, networkConfigs[network].addressTagRegex);
                },
                message: (
                    <FormattedMessage
                        id="error.invalid_address_tag"
                        defaultMessage="Invalid {addressTagLabel}"
                        values={{
                            addressTagLabel: networkConfigs[network].addressTagName
                        }}
                    />
                )
            } : undefined,
        ];

        const missedFields = [];
        const invalidFields = [];

        requiredFields.filter(f => !!f).map(field => {
            if(!this.state[field]) {
                missedFields.push({
                    field,
                    message: VALIDATION_MESSAGES[field]
                });
            }
        });

        validations.filter(f => !!f).map(({ field, validate, message }) => {
            if(!validate(this.state[field])) {
                invalidFields.push({
                    field,
                    message
                });
            }
        });

        return invalidFields.concat(missedFields);
    };

    onCopyAddress(id) {
        this.setState({
            addressCopied: id,
        });
        this.timer = setTimeout(() => {
            this.setState({
                addressCopied: -1,
            });
        }, 1500);
    }

    onCopyTag(id) {
        this.setState({
            tagCopied: id,
        });
        this.timer = setTimeout(() => {
            this.setState({
                tagCopied: -1,
            });
        }, 1500);
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onEditAddress = ({ address, network, tag, label }) => {
        this.setState({
            address,
            network,
            tag: tag ? tag : '',
            label: label ? label : '',
            code: '',
            displayEditAddressForm: true,
            displayNewAddressForm: false,
        });
    }

    onCancelEditCreate = () => {
        this.setState({
            errors: [],
            network: '',
            address: '',
            tag: '',
            label: '',
            code: '',
            displayEditAddressForm: false,
            displayNewAddressForm: false,
        });
    }

    onCreateNew = () => {
        this.setState({
            errors: [],
            displayEditAddressForm: false,
            displayNewAddressForm: true,
        });
    }

    onConfirmDelete = ({ code, address, network, tag, label }) => {
        const { intl } = this.props;
        const confirmMessage = intl.formatMessage({id: 'portfolio.confirmDelete', defaultMessage: 'Are you sure you want to delete this address?'});
        confirm(confirmMessage) && this.onDelete({ code, address, network, tag, label });
    }

    onDelete = ({ code, address, network, tag, label }) => {
        const { coin } = this.props;

        this.setState({
            errors: []
        });

        this.props.deleteWithdrawAddress({
            code,
            address,
            network,
            tag,
            label,
            asset: coin
        });
    };

    onSubmit = () => {
        const { code, network, address, tag, label } = this.state;
        const { coin } = this.props;

        const errors = this.validateInput();

        if (errors.some(e => !!e)) {
            this.setState({
                errors
            });
        } else {
            this.setState({
                errors: [],
                displayEditAddressForm: false,
                displayNewAddressForm: false,
            });

            this.props.setWithdrawAddress({
                code,
                network,
                address,
                tag,
                label,
                asset: coin
            });
        }
    };


    render() {

        const { user, addresses, networkConfigs, networkOptions, intl } = this.props;
        const {
            displayNewAddressForm,
            displayEditAddressForm,
            network,
            address,
            tag,
            label,
            code,
            addressCopied,
            tagCopied
        } = this.state;

        const disabled = !address || !label || address === '' || label === '' || !validateAnyAuthCode(code);

        const addressLabel = networkConfigs[network] ? networkConfigs[network].addressName : 'Address';
        const tagLabel = networkConfigs[network] ? networkConfigs[network].addressTagName.replace(' (Optional)', '') : 'Tag';

        return (
            <Container>
                {(!displayNewAddressForm && !displayEditAddressForm) ? (
                    <React.Fragment>
                        <PortfolioTitle>
                            <FormattedMessage
                                id="portfolio.addressListTitle"
                                defaultMessage="Address List"
                            />
                        </PortfolioTitle>
                        {addresses && addresses.length > 0 ? addresses.map((address, i) => (
                            <Address key={i}>
                                <p><b>
                                    {(address.label && address.label !== '') ? address.label : 'No Label Set'} (on the {networkConfigs[address.network].displayName} network)
                                </b></p>
                                <p>
                                    {address.address} {(address.tag && address.tag !== '') ? ' : ' + address.tag : ''}
                                </p>
                                <AddressActions>
                                    <span onClick={() => this.onEditAddress({label: address.label, address: address.address, network: address.network, tag: address.tag})}>
                                        <FormattedMessage
                                            id="portfolio.editAddress"
                                            defaultMessage="Edit Label"
                                        />
                                    </span>
                                    <span onClick={() => this.onConfirmDelete({label: address.label, address: address.address, network: address.network, tag: address.tag})}>
                                        <FormattedMessage
                                            id="portfolio.deleteAddress"
                                            defaultMessage="Delete Address"
                                        />
                                    </span>
                                    <span onClick={() => {this.onCopyAddress(i);}}>
                                        {(addressCopied === i) ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                            <CopyToClipboard text={address.address}>
                                                <span>
                                                    <img src={'/' + copyIcon} alt=""/>
                                                    <FormattedMessage
                                                        id="general.copy_address_to_clipboard"
                                                        defaultMessage="Copy address to clipboard"
                                                    />
                                                </span>
                                            </CopyToClipboard>
                                        )}
                                    </span>
                                    {address.tag && address.tag !== '' && (
                                        <span onClick={() => {this.onCopyTag(i);}}>
                                            {(tagCopied === i) ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                <CopyToClipboard text={address.tag}>
                                                    <span>
                                                        <img src={'/' + copyIcon} alt=""/>
                                                        <FormattedMessage
                                                            id="general.copy_tag_to_clipboard"
                                                            defaultMessage="Copy tag to clipboard"
                                                        />
                                                    </span>
                                                </CopyToClipboard>
                                            )}
                                        </span>
                                    )}
                                </AddressActions>
                            </Address>
                        )) : (
                            <p><b>
                                <FormattedMessage
                                    id="portfolio.noAddresses"
                                    defaultMessage="(no addresses)"
                                />
                            </b></p>
                        )}
                        <AuthRow>
                            <WideButton
                                onClick={this.onCreateNew}
                            >
                                <FormattedMessage
                                    id="portfolio.createNew"
                                    defaultMessage="Create New"
                                />
                            </WideButton>
                        </AuthRow>
                        <InfoRow>
                            <FormattedMessage
                                id="deposit.addressBookIntro"
                                defaultMessage="We require setup of your withdrawal destinations into your address book prior to withdrawal. This adds a layer of security to your account and makes repeated withdrawals safer and easier."
                            />
                        </InfoRow>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {(displayEditAddressForm) ? (
                            <PortfolioTitle>
                                <FormattedMessage
                                    id="portfolio.editAddressTitle"
                                    defaultMessage="Edit Address"
                                />
                            </PortfolioTitle>
                        ) : (
                            <React.Fragment>
                                {network && network !== '' && (
                                    (networkConfigs[network] && networkConfigs[network].withdrawalWarning && networkConfigs[network].withdrawalWarning !== '') && (
                                        <ItemIncomplete>
                                            <ItemTextTitle>
                                                <FormattedMessage
                                                    id="profile.withdrawalWarningLabel"
                                                    defaultMessage="{network} Network Warning"
                                                    values={{
                                                        network: networkConfigs[network].displayName + ' (' + network + ')',
                                                    }}
                                                />
                                            </ItemTextTitle>
                                            <ItemText dangerouslySetInnerHTML={{__html: networkConfigs[network].withdrawalWarning }} />
                                        </ItemIncomplete>
                                    )
                                )}

                                <PortfolioTitle>
                                    <FormattedMessage
                                        id="portfolio.newAddressTitle"
                                        defaultMessage="Create New Entry"
                                    />
                                </PortfolioTitle>
                            </React.Fragment>
                        )}

                        <InputGroup>
                            <FormattedMessage
                                id="portfolio.AddressBookLabel"
                                defaultMessage="Label (for your own reference)"
                                tagName="label"
                            />
                            <WithdrawalAddress
                                placeholder={
                                    intl.formatMessage({
                                        id: 'portfolio.exampleAddressBookLabel',
                                        defaultMessage: 'my phone wallet address (example)'
                                    })
                                }
                                type="text"
                                name="label"
                                value={ label }
                                onChange={this.onChange}
                                error={this.getError('label')}
                            />
                        </InputGroup>

                        <InputGroup>
                            <FormattedMessage
                                id="portfolio.withdrawalAddressNetwork"
                                defaultMessage="Blockchain or Network"
                                tagName="label"
                            />
                            <StyledSelect
                                placeholder={
                                    intl.formatMessage({
                                        id: 'portfolio.selectWithdrawalNetwork',
                                        defaultMessage: 'Select Network'
                                    })
                                }
                                value={networkOptions.find(({value}) => value === network)}
                                onChange={this.onSelectNetwork}
                                options={networkOptions}
                                isDisabled={displayEditAddressForm}
                            />
                        </InputGroup>

                        {network && network !== '' && (
                            <React.Fragment>
                                <InputGroup>
                                    <FormattedMessage
                                        id="portfolio.withdrawAddressLabel"
                                        defaultMessage="{addressLabel}"
                                        tagName="label"
                                        values={{
                                            addressLabel: addressLabel,
                                        }}
                                    />
                                    <WithdrawalAddress
                                        placeholder={
                                            intl.formatMessage({
                                                id: 'portfolio.exampleAddress',
                                                defaultMessage: '0xAbC123aBc (example)'
                                            })
                                        }
                                        type="text"
                                        name="address"
                                        value={ address }
                                        onChange={this.onChange}
                                        error={this.getError('address')}
                                        disabled={displayEditAddressForm}
                                    />
                                </InputGroup>
                                { network && networkConfigs[network] && networkConfigs[network].addressTagName && networkConfigs[network].addressTagName !== '' && (
                                    <InputGroup>
                                        <FormattedMessage
                                            id="portfolio.withdrawAddressTagLabel"
                                            defaultMessage="{addressTagLabel} (Optional)"
                                            tagName="label"
                                            values={{
                                                addressTagLabel: tagLabel,
                                            }}
                                        />
                                        <WithdrawalAddressTag
                                            type="text"
                                            name="tag"
                                            value={ tag }
                                            onChange={this.onChange}
                                            error={this.getError('tag')}
                                            disabled={displayEditAddressForm}
                                        />
                                    </InputGroup>
                                )}

                                {user && !user.securedMethods.includes('NONE') && user.securedActions.includes('WITHDRAW') ? (
                                    <AuthRow>
                                        <LeftInputTwoFa>
                                            <InputTwoFa
                                                name="code"
                                                value={code}
                                                onChange={this.onChange}
                                                disabled={false}
                                                component='WITHDRAW'
                                            />
                                        </LeftInputTwoFa>
                                        <RightButtonTwoFa
                                            disabled={disabled}
                                            onClick={this.onSubmit}
                                        >
                                            <FormattedMessage
                                                id="portfolio.saveButtonText"
                                                defaultMessage="Save"
                                            />
                                        </RightButtonTwoFa>
                                    </AuthRow>
                                ) : (
                                    <AuthRow>
                                        <WideButton
                                            disabled={disabled}
                                            onClick={this.onSubmit}
                                        >
                                            <FormattedMessage
                                                id="portfolio.saveWithdrawAddressButtonText"
                                                defaultMessage="Save Address"
                                            />
                                        </WideButton>
                                    </AuthRow>
                                )}
                            </React.Fragment>
                        )}
                        <AuthRow>
                            <WideButton
                                bsStyle='red'
                                onClick={this.onCancelEditCreate}
                            >
                                <FormattedMessage
                                    id="portfolio.cancel"
                                    defaultMessage="Cancel"
                                />
                            </WideButton>
                        </AuthRow>
                    </React.Fragment>
                )}

            </Container>
        );
    }
}
