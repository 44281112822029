import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware  } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import logger, { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { apiMiddleware } from 'redux-api-middleware';
import { routerMiddleware } from 'connected-react-router';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { CookiesProvider } from 'react-cookie';

import { restMiddleware } from './middlewares/restMiddleware';
import { wsMiddleware } from './middlewares/wsMiddleware';
import { authMiddleware } from './middlewares/authMiddleware';

import { Container as App } from './screens/App';

import reducers from './reducers';
import { getBrowserLocale } from './utils';

// converting to Dart SASS broke this... not sure why... had to manually deploy static css in src/public/css
// import 'react-notifications/src/notifications.scss';

const dev = process.env.NODE_ENV !== 'production';
const devMiddleware = [createLogger(), reduxImmutableStateInvariant()];

const history = createBrowserHistory();

const store = createStore(
    reducers(history),
    applyMiddleware(
        logger,
        thunk,
        routerMiddleware(history),
        // TODO: Figure out how to only initiate a WS for routes that need it
        wsMiddleware(),
        restMiddleware,
        authMiddleware,
        apiMiddleware,
        ...(dev ? devMiddleware : [])
    )
);

const locale = getBrowserLocale();

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <IntlProvider locale={locale} >
                <App history={history}/>
            </IntlProvider>
        </Provider>
    </CookiesProvider>,
    document.getElementById('app')
);
