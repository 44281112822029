import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import TradeHistory from './TradeHistory';
import { clearAggTradeFeed, stopAggTradeFeed, startAggTradeFeed } from '../../../../actions/market';
import {getBaseCoinConfig, getQuoteCoinConfig} from '../../../../selectors';


function mapStateToProps(state) {
    return {
        baseCoinConfigName: getBaseCoinConfig(state) && getBaseCoinConfig(state).name,
        quoteCoinConfigName: getQuoteCoinConfig(state) && getQuoteCoinConfig(state).name,
        recentTrades: state.market.recentTrades,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearAggTradeFeed,
            stopAggTradeFeed,
            startAggTradeFeed,
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TradeHistory));
