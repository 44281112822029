import { connect } from 'react-redux';
import OfflineNotice from './OfflineNotice';
import {bindActionCreators} from 'redux';
import {loginLoading} from '../../actions/auth';

function mapStateToProps(state) {
    return {
        maintenanceMode: state.currency.maintenanceMode,
        wsState: (state.ws && state.ws.state) ? state.ws.state : ''
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            loginLoading,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfflineNotice);
