import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { NotificationContainer } from 'react-notifications';
import styled, { ThemeProvider } from 'styled-components';
import { injectGlobal } from 'styled-components';

import { Component as ScrollToTop } from '../../components/ScrollToTop';
import { Component as ErrorBoundary } from '../../components/ErrorBoundary';

import { Component as AboutScreen } from '../AboutScreen';
import { Component as BlogScreen } from '../BlogScreen';
import { Component as ConfirmationScreen } from '../ConfirmationScreen';
import { Container as DeviceConfirmScreen } from '../DeviceConfirmScreen';
import { Component as FaqScreen } from '../FaqScreen';
import { Container as ForgotPasswordScreen } from '../ForgotPasswordScreen';
import { Component as HowItWorksScreen } from '../HowItWorksScreen';
import { Container as IpChangedScreen } from '../IpChangedScreen';
import { Component as LandingPageScreen } from '../LandingPageScreen';
import { Component as ProofPageScreen } from '../ProofPageScreen';
import { Container as Login2FAScreen } from '../Login2FAScreen';
import { Container as LoginScreen } from '../LoginScreen';
import { Component as MaintenanceScreen } from '../MaintenanceScreen';
import { Container as MarketScreen } from '../MarketScreen';
import { Container as NewDeviceScreen } from '../NewDeviceScreen';
import { Component as NotFoundScreen } from '../NotFoundScreen';
import { Container as PortfolioScreen } from '../PortfolioScreen';
import { Component as PrivacyScreen } from '../PrivacyScreen';
import { Container as ProfileScreen } from '../ProfileScreen';
import { Container as ProjectsScreen } from '../ProjectsScreen';
import { Container as QuickTradeScreen } from '../QuickTradeScreen';
import { Container as RegistrationSetPasswordScreen } from '../RegistrationSetPasswordScreen';
import { Container as SessionExpireNotice } from '../../components/SessionExpireNotice';
import { Container as SignedOutScreen } from '../SignedOutScreen';
import { Container as SignUpScreen } from '../SignUpScreen';
import { Component as TermsScreen } from '../TermsScreen';
import { QuickTradeTransactionContainer as QuickTradeTransactionScreen } from '../QuickTradeScreen';
import { Container as VerifyEmailScreen } from '../VerifyEmailScreen';
import { Container as WalletStatusScreen } from '../WalletStatusScreen';
import { Container as WithdrawalConfirmedScreen } from '../WithdrawalConfirmedScreen';
import { Container as OfflineNotice } from '../../components/OfflineNotice';
import Skin from '../../Skin';
import {LoadingDots} from '../../components/LoadingDots';
import {mobile} from '../../styles';
import {FormattedMessage} from 'react-intl';




const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const SwitchContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;

  > * {
    flex: 1 100%;
    padding: 10px;
  }
`;

const LoadingText = styled(LoadingDots)`
    font-size: 40px;
    font-weight: 700;
    color: #021747;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    padding: 60px 110px;
    box-sizing: border-box;
    width: 100%;
    margin: 0;

    ${mobile`
      margin: 0px;
    `}
`;

//
// const ContentPane = styled.div`
//   background-color: #fff;
//   border-radius: 5px;
//   box-shadow: 0 0 26px -14px rgba(11,32,78,0.85);
//   overflow: hidden;
//
//   padding: 0;
//
//   display: flex;
//   flex-direction: column;
//
//   > * {
//     flex: 1;
//   }
// `;

injectGlobal`
  // * { padding: 0; margin: 0; }

  html {
    height: 100%;
    width: 100%;
  }
  
  body, input, select, button, textarea {
    font-family: Inter, sans-serif;
  }
  
  body {
    height: 100%;

    display: flex;
    flex-direction: column;
    margin: 0;
  }
  
  body > * {
    flex-shrink: 0;
  }
  
  #app {
    height: 100%;
    width: 100%;
    display: flex;
    // justify-content: space-around;
    margin: 0;
  }
`;

export default class App extends React.Component {

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        maintenanceMode: PropTypes.bool,
        loginLoading: PropTypes.bool,
        isConnected: PropTypes.bool,

        location: PropTypes.object,
        cookies: PropTypes.object,
        history: PropTypes.object,
    };

    componentDidMount() {
        //document.title = 'Welcome to BlocPalX';
    }

    render() {

        const { isAuthenticated, maintenanceMode, loginLoading, isConnected } = this.props;

        //<Route exact path="/" component={() => {
        //    return <Redirect to={'/market'} />;
        //}} />
        //
        // <Route exact path="/" component={(props) => {
        //     return <Grid><MarketScreen {...props} /></Grid>;
        //     return <Grid><QuickTradeScreen location={this.props.location} cookies={this.props.cookies} {...props} /></Grid>;
        //     if (props.location.search !== '') {
        //         return <Redirect to={`/quicktrade/${props.location.search}`} />;
        //     }
        //}} />

        // const token = sessionStorage.getItem('token');
        // console.log('token', token);

        // console.log('LOCK:',this.props.history);
        // console.log('isAuth',isAuthenticated);
        // console.log('loginLoading', loginLoading);
        // console.log('isConnected',isConnected);
        // sessionStorage.setItem('token', 'abc123');

        return (
            <ErrorBoundary>
                <ConnectedRouter history={this.props.history}>
                    {loginLoading ? (
                        <ThemeProvider theme={Skin}>
                            <Container>
                                <ScrollToTop location={this.props.location} />
                                <OfflineNotice />
                                {!isConnected && (
                                    <LoadingText>
                                        <FormattedMessage
                                            id="general.loading"
                                            defaultMessage="Loading"
                                        />
                                    </LoadingText>
                                )}
                            </Container>
                        </ThemeProvider>
                    ) :
                        <ThemeProvider theme={Skin}>
                            <Container>
                                <ScrollToTop location={this.props.location} />
                                <OfflineNotice />
                                <SessionExpireNotice />
                                <SwitchContainer>
                                    {maintenanceMode ? (
                                        <MaintenanceScreen location={this.props.history.location} />
                                    ) : (

                                        < Switch >

                                            <Route exact path="/" render={() => <LandingPageScreen location={this.props.history.location} />} />
                                            <Route exact path="/proofOfReserves" render={() => <ProofPageScreen location={this.props.history.location} />} />

                                            <Route exact path="/landing" component={() => {
                                                return <Redirect to={'/'} />;
                                            }} />

                                            <Route exact path="/quicktrade" component={(props) => {
                                                return <Grid><QuickTradeScreen location={this.props.location} cookies={this.props.cookies} {...props} /></Grid>;
                                            }} />

                                            <Route path="/quicktrade/:id" render={(props) => <Grid><QuickTradeTransactionScreen {...props} /></Grid>} />
                                            <Route path="/withdrawalConfirmed/:id" render={(props) => <WithdrawalConfirmedScreen {...props} />} />

                                            <Route path="/projects/:ticker?" render={(props) => <ProjectsScreen {...props} />} />
                                            <Route path="/projects" render={(props) => <ProjectsScreen {...props} />} />

                                            <Route path="/market/:pair?" render={(props) => <MarketScreen {...props} />} />

                                            <Route path="/confirm" render={() => <ConfirmationScreen />} />
                                            <Route path="/walletStatus" render={() => <WalletStatusScreen location={this.props.history.location} />} />
                                            <Route path="/howItWorks" render={() => <Grid><HowItWorksScreen /></Grid>} />
                                            <Route path="/aboutUs" render={() => <Grid><AboutScreen /></Grid>} />
                                            <Route path="/privacy" render={() => <PrivacyScreen location={this.props.history.location} />} />
                                            <Route path="/faq" render={() => <Grid><FaqScreen /></Grid>} />
                                            <Route path="/terms" render={() => <TermsScreen location={this.props.history.location} />} />
                                            <Route path="/blog" render={() => <Grid><BlogScreen /></Grid>} />
                                            <Route path="/maintenance" render={() => <MaintenanceScreen location={this.props.history.location} />} />

                                            {!isAuthenticated && (
                                                <Route path="/account" component={LoginScreen} />
                                            )}

                                            {isAuthenticated && (
                                                <Route path="/account/portfolio/:coin?" render={(props) => <PortfolioScreen {...props} />} />
                                            )}

                                            {isAuthenticated && (
                                                <Route path="/account/profile" render={(props) => <ProfileScreen {...props} />} />
                                            )}

                                            <Route path="/login" component={LoginScreen} />
                                            <Route path="/login-2fa" component={Login2FAScreen} />
                                            <Route path="/signedout" component={SignedOutScreen} />
                                            <Route path="/ipchanged" component={IpChangedScreen} />

                                            <Route path="/signup/:step?" render={(props) => <SignUpScreen {...props} />} />
                                            <Route path="/verify-email" component={VerifyEmailScreen} />
                                            <Route path="/register" component={RegistrationSetPasswordScreen} />

                                            <Route path="/forgot-password" component={ForgotPasswordScreen} />
                                            <Route path="/performReset" component={RegistrationSetPasswordScreen} />

                                            <Route path="/device-confirm" component={DeviceConfirmScreen} />
                                            <Route path="/new-device" component={NewDeviceScreen} />

                                            <Route component={NotFoundScreen} />
                                        </Switch>
                                    )}
                                </SwitchContainer>
                                <NotificationContainer />
                            </Container>
                        </ThemeProvider>
                    }
                </ConnectedRouter>
            </ErrorBoundary >
        );
    }
}
