import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as WithdrawalConfirmPanel } from '../../components/WithdrawalConfirmPanel';
import { LoadingDots } from '../../components/LoadingDots';
import GradientView from '../../components/GradientView/GradientView';
import {Component as AuthFooter} from '../../components/AuthFooter';

const WithdrawalScreenContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
`;

const LeftPane = styled.div`
  flex: 1 auto;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
`;

const LoadingText = styled(LoadingDots)`
    font-size: 30px;
    font-weight: 700;
    // height: 80vh;
    width: 100%;
    background-color: #021747;
    color: #eee;
    border-radius: 5px;
    text-shadow: 0px 2px 3px rgba(255,255,255,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ErrorText = styled.div`
    font-size: 30px;
    font-weight: 700;
    height: 80vh;
    // margin-top: 200px;
    // margin-bottom: 200px;
    // text-align: center;
    width: 100%;
    background-color: #fff;
    color: #0005;
    text-shadow: 0px 2px 3px rgba(255,255,255,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
`;


class TransactionLoadingMessage extends React.PureComponent {
    render() {
        return <LoadingText>
            <FormattedMessage
                id="general.loading_withdrawal"
                defaultMessage="Loading withdrawal"
            />
        </LoadingText>;
    }
}

TransactionLoadingMessage.propTypes = {
    id: PropTypes.string,
};

class SomethingWentWrongMessage extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <ErrorText>
                    { this.props.notifications[0].text ? (
                        <FormattedMessage
                            id="general.error"
                            defaultMessage="{error}"
                            values={{
                                error: this.props.notifications[0].text
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="general.loading_withdrawal_error"
                            defaultMessage="Failed to confirm withdrawal."
                        />
                    )}
                </ErrorText>
            </React.Fragment>
        );
    }
}

SomethingWentWrongMessage.propTypes = {
    id: PropTypes.string,
    notifications: PropTypes.array
};


export default class WithdrawalConfirmedScreen extends React.Component {

    static propTypes = {
        notifications: PropTypes.array,
        confirmAndProcessWithdrawal: PropTypes.func,
        id: PropTypes.string,
        confirmationUuid: PropTypes.string,
        confirmed: PropTypes.bool,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    };

    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };
    }

    componentDidMount() {
        const { match } = this.props;
        if (match && match.params && match.params.id) {
            this.props.confirmAndProcessWithdrawal(match.params.id);
        }

        document.title = 'Withdrawal Confirmation (BlocPalX)';
    }

    render() {

        const { notifications, confirmed, confirmationUuid } = this.props;

        let content;
        if ( confirmationUuid && confirmed ) {
            content = <WithdrawalConfirmPanel/>;
        } else if (notifications && notifications.length > 0) {
            content = <SomethingWentWrongMessage id={confirmationUuid} notifications={notifications} />;
        }

        return ((confirmationUuid && confirmed) || (notifications && notifications.length > 0)) ? (
            <GradientView>
                <WithdrawalScreenContainer>
                    <LeftPane>
                        { content }
                    </LeftPane>
                </WithdrawalScreenContainer>
                <AuthFooter />
            </GradientView>
        ) : (
            <TransactionLoadingMessage id={confirmationUuid} />
        );
    }
}
