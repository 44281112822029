import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Component as Logo } from '../../components/Logo';

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '0 0 0 0'};
    background: linear-gradient(0deg, #FFFFFF 0%, #ECECEC 100%);
    background-color: #fff;
`;

const BlocPalLogo = styled.div`
    margin-top: 60px;
`;

const GradientView = (props) => {
    return (
        <Container borderRadius={props.borderRadius}>
            <BlocPalLogo>
                <Logo />
            </BlocPalLogo>
            {props.children}
        </Container>
    );
};

GradientView.propTypes = {
    children: PropTypes.any,
    borderRadius: PropTypes.string,
};

export default GradientView;