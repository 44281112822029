import { connect } from 'react-redux';
import SessionExpireNotice from './SessionExpireNotice';
import {bindActionCreators} from 'redux';
import {extendSession} from '../../actions/auth';

function mapStateToProps(state) {
    return {
        expiresInMillis: state.auth.expiresInMillis,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            extendSession,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionExpireNotice);
