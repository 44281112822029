import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobile } from '../../styles';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

class MenuItem {
    constructor(title, path) {
        this.title = title;
        this.path = path;
    }
}

const Container = styled.div`
    color: #262626;

    display: flex;
    flex-grow: 1;
    flex-flow: row wrap;
    justify-content: flex-end;

    width: 100%;
    // height: 100%;
    //   padding: 0 170px;
    box-sizing: border-box;
    align-items: flex-end;
  
    > * {
        padding: 0.7em;
    }
    a {
        color: #262626;
    }

    a:hover {
        text-decoration: none;
        opacity: 0.5;
    }

    ${mobile`
        padding: 0 30px;
        margin-top: 20px;
        justify-content: center;
    `};
`;

const BottomRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 950px) {
       justify-content: space-between;
       flex-direction: column;
       
    }
  
`;

const FooterMenu = styled.ul`
    list-style: none;
    padding-left: 0;
    margin-left: 10px;
    -webkit-padding-start: 0;

    flex: auto;
    display: flex;
    flex-flow: row wrap;

    ${mobile`
        justify-content: space-between;
        flex-direction:column;
        align-items:center;
    `};

    > li {
        font-size: 12px;
        font-weight: 600;
        line-height: 3em;
            letter-spacing: 0.92px;
        margin-right: 2em;
        
        a {
            color: #262626;
        }

        ${mobile`
            margin: 0;
        `};
    }
`;

const Copyright = styled.div`
  padding-right: 0;
  opacity: 0.7;
  font-size: 14px;
  line-height: 20px;
  text-align: right;

  ${mobile`
    padding: 0;
    text-align: center;
  `}
`;


const menuItems = [
    /*
    new MenuItem(
        <FormattedMessage
            id="navigation.howItWorks"
            defaultMessage="How It Works"
        />,
        '/howItWorks'
    ),
    */
    new MenuItem(
        <FormattedMessage
            id="navigation.proofOfReserves"
            defaultMessage="Proof of reserves"
        />,
        '/proofOfReserves'
    ),
    new MenuItem(
        <FormattedMessage
            id="navigation.walletStatus"
            defaultMessage="Wallet status"
        />,
        '/walletStatus'
    ),
    new MenuItem(
        <FormattedMessage
            id="navigation.apidocs"
            defaultMessage="API"
        />,
        'https://blocpal-inc.github.io/exchange-api-docs/'
    ),
    new MenuItem(
        <FormattedMessage
            id="navigation.privacy"
            defaultMessage="Privacy"
        />,
        '/privacy'
    ),
    new MenuItem(
        <FormattedMessage
            id="navigation.terms"
            defaultMessage="Terms of service"
        />,
        '/terms'
    ),
    new MenuItem(
        <FormattedMessage
            id="navigation.discordLink"
            defaultMessage="Discord"
        />,
        'https://discord.gg/tvcTwPT9Bt'
    ),
    new MenuItem(
        <FormattedMessage
            id="navigation.twitterLink"
            defaultMessage="Twitter"
        />,
        'https://twitter.com/blocpalx'
    ),
];


const Footer = () => {

    const renderCopyRightView = () => {
        return (
            <Copyright>
                &copy;{'BlocPal International, 2017-2023. '}
                <FormattedMessage
                    id="general.rights_reserved"
                    defaultMessage="All rights reserved."
                />
            </Copyright>
        );
    };

    return (
        <Container>
            <BottomRow>
                <FooterMenu>
                    {menuItems.map((item, index) => (
                        <li key={index}>{
                            (item.path.includes('http', 0)) ?
                                <a href={item.path} target="_blank" rel="noreferrer">{item.title}</a> :
                                <Link to={item.path}>{item.title}</Link>
                        }</li>
                    ))}
                </FooterMenu>
                {renderCopyRightView()}
            </BottomRow>
        </Container>
    );

};

Footer.propTypes = {
    quickTradeId: PropTypes.string,
    currencyFrom: PropTypes.string,
    currencyTo: PropTypes.string,
    referralCode: PropTypes.string,
    showSupportLink: PropTypes.bool,
};

export default Footer;