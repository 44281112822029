import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetInformation from './AssetInformation';

function mapStateToProps(state, props) {
    return {
        coinConfig: state.currency.coinsConfig ? state.currency.coinsConfig.find(config => config.name === props.coin) || undefined : undefined,
        coinConfigExtended: state.currency.coinsConfigExtended ? state.currency.coinsConfigExtended.find(config => config.name === props.coin) || undefined : undefined,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetInformation);
