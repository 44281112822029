import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { mobile } from '../../../../styles';


const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  >span {
	color: #9B9B9B;
	font-size: 12px;
	letter-spacing: 0.43px;
	line-height: 19px;
	text-transform: uppercase;
  }

  p {
    color: #9B9B9B;
	font-size: 14px;
	letter-spacing: 0.43px;
	line-height: 19px;

	span {
	  color: #ef8632;
	  cursor: pointer;
	  user-select: none;
	}
  }
`;

const ListWrapper = styled.div`
  margin: 15px 0px 15px 0px;
`;

const Option = styled(NavLink)`
  width: 280px;
  display: flex;
  border-top: 1px solid #eee;
  // position: relative;
  padding: 15px 15px 15px 15px;
  // white-space: pre-wrap;
  // padding: 20px 20px 20px 20px;
  text-decoration: none;
  margin-bottom: 1px;

  color: #232323;
  justify-content: space-between;
  align-items: center;

  ${mobile`
    width: 90%;
  `}

  &.active {
    background-color: #ef863214;
    border-color: #ef863214;
  }

  &:hover {
    background-color: #ef863214;
  }
  
  >span {
    // display: flex;
    // align-items: center;
    // height: 24px;
    // margin-top: 13px;
    img {
      margin-right: 10px;
    }
  }
`;

const BoldOption = styled.span`
    font-weight: bold;
`;


export default class ProfileSidebar extends React.Component {

    static propTypes = {
        get2faAuths: PropTypes.func,
        getApiKeys: PropTypes.func,
        getUploadedFileList: PropTypes.func,
        getVrfyInfo: PropTypes.func,
        getProofOfReserves: PropTypes.func,
        location: PropTypes.string,
    };

    onClickLoadTwoFaAuthData = () => {
        this.props.get2faAuths();
    };

    onClickLoadApiKeyData = () => {
        this.props.getApiKeys();
    };

    onClickLoadVerificationData = () => {
        this.props.getUploadedFileList();
        this.props.getVrfyInfo();
    };

    onClickLoadProofOfReservesData = () => {
        this.props.getApiKeys();
    };

    render() {

        const { location } = this.props;

        // console.log('***location***');
        // console.log(location);

        return (
            <ListWrapper>
                <ListHeader>
                    <FormattedMessage
                        id="profile.accountOptions"
                        defaultMessage="Account Profile Options"
                    />
                </ListHeader>
                <Option
                    key='summary'
                    to='/account/profile/summary'
                    exact
                    isActive={() => !location || location === '/account/profile/summary'}
                >
                    <span>
                        <BoldOption>
                            <FormattedMessage
                                id="profile.summaryTitle"
                                defaultMessage="Account Summary"
                            />
                        </BoldOption>
                    </span>
                </Option>
                <Option
                    key='notifications'
                    to='/account/profile/notifications'
                    exact
                    isActive={() => !location || location === '/account/profile/notifications'}
                >
                    <span>
                        <BoldOption>
                            <FormattedMessage
                                id="profile.notificationsTitle"
                                defaultMessage="Notifications"
                            />
                        </BoldOption>
                    </span>
                </Option>
                <Option onClick={() => {this.onClickLoadVerificationData();}}
                    key='verification'
                    to='/account/profile/verification'
                    exact
                    isActive={() => location === '/account/profile/verification'}
                >
                    <span>
                        <BoldOption>
                            <FormattedMessage
                                id="profile.kycTitle"
                                defaultMessage="Identity Verification"
                            />
                        </BoldOption>
                    </span>
                </Option>
                <Option onClick={() => {this.onClickLoadTwoFaAuthData();}}
                    key='config2fa'
                    to='/account/profile/config2fa'
                    exact
                    isActive={() => location === '/account/profile/config2fa'}
                >
                    <span>
                        <BoldOption>
                            <FormattedMessage
                                id="profile.securityTitle"
                                defaultMessage="Security"
                            />
                        </BoldOption>
                    </span>
                </Option>
                <Option onClick={() => {this.onClickLoadProofOfReservesData();}}
                    key='proofOfReserves'
                    to='/account/profile/proofOfReserves'
                    exact
                    isActive={() => location === '/account/profile/proofOfReserves'}
                >
                    <span>
                        <BoldOption>
                            <FormattedMessage
                                id="profile.proofOfReservesTitle"
                                defaultMessage="Proof of Reserves"
                            />
                        </BoldOption>
                    </span>
                </Option>
                <Option
                    key='downloads'
                    to='/account/profile/downloads'
                    exact
                    isActive={() => location === '/account/profile/downloads'}
                >
                    <span>
                        <BoldOption>
                            <FormattedMessage
                                id="profile.reportsTitle"
                                defaultMessage="Statements & Reports"
                            />
                        </BoldOption>
                    </span>
                </Option>
                <Option onClick={() => {this.onClickLoadApiKeyData();}}
                    key='apikeys'
                    to='/account/profile/apikeys'
                    exact
                    isActive={() => location === '/account/profile/apikeys'}
                >
                    <span>
                        <BoldOption>
                            <FormattedMessage
                                id="profile.apiKeysTitle"
                                defaultMessage="API Keys"
                            />
                        </BoldOption>
                    </span>
                </Option>
            </ListWrapper>
        );
    }
}
