import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileApiKeyManagement from './ProfileApiKeyManagement';

import { clearApiKeys, getApiKeys, submitCreateApiKey, submitDisableApiKey, submitDeleteApiKey } from '../../../../actions/user';

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearApiKeys,
            getApiKeys,
            submitCreateApiKey,
            submitDisableApiKey,
            submitDeleteApiKey,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileApiKeyManagement);
