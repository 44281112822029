import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { default as Chart } from './kaktana-react-lightweight-charts';
import { sigDigits } from '../../../../utils';


const Wrapper = styled.div`
  background-color: #FFFFFF;
  padding-bottom: 20px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;
`;


export default class DepthChart extends React.Component {

    static propTypes = {
        depthChartBuyData: PropTypes.array,
        depthChartSellData: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {
            chartOptions: {
                alignLabels: true,
                timeScale: {
                    rightOffset: 0,
                    barSpacing: 10,
                    fixLeftEdge: true,
                    lockVisibleTimeRangeOnResize: true,
                    rightBarStaysOnScroll: true,
                    borderVisible: false,
                    borderColor: '#fff000',
                    visible: false,
                    timeVisible: false,
                    secondsVisible: false,
                    tickMarkFormatter: (time) => {
                        return sigDigits((time/100000000), 6);
                    },
                },
                crosshair: {
                    vertLine: {
                        visible: false,
                        labelVisible: false,
                    },
                    horzLine: {
                        visible: false,
                        labelVisible: false,
                    },
                },
                rightPriceScale: {
                    entireTextOnly: true,
                    scaleMargins: {
                        top: 0,
                        bottom: 0,
                    },
                    borderVisible: false,
                    visible: false,
                    borderColor: 'rgba(197, 203, 206, 1)',
                },
                leftPriceScale: {
                    entireTextOnly: true,
                    scaleMargins: {
                        top: 0,
                        bottom: 0,
                    },
                    borderVisible: false,
                    visible: false,
                    borderColor: 'rgba(197, 203, 206, 1)',
                },
                grid: {
                    vertLines: {
                        visible: false,
                    },
                    horzLines: {
                        visible: false,
                    },
                },
                handleScroll: false,
                handleScale: false,
            },
        };
    }

    render() {
        const { depthChartBuyData, depthChartSellData } = this.props;

        // console.log('***DEPTH CHART DATA***');
        // console.log(depthChartBuyData);
        // console.log(depthChartSellData);

        //TODO figure out priceScaleId so both charts can be combined...
        const series = [
            {
                options: {
                    // color: '#269ffb',
                    topColor: 'rgba(51, 215, 120, 0.2)',
                    bottomColor: '#FFFFFF',
                    lineColor: '#33D778',
                    lineWidth: 1,
                    priceScaleId: 'left',
                    scaleMargins: {
                        top: 0.8,
                        bottom: 0,
                    },
                    priceFormat: {
                        type: 'custom',
                        minMove: '0.00000001',
                        formatter: (value) => {
                            return parseFloat(value).toFixed(2);
                        }
                    },
                    crosshairMarkerVisible: false,
                    priceLineVisible: false,
                    lastValueVisible: false,
                },
                data: depthChartBuyData,
            },
            {
                options: {
                    // color: '#269ffb',
                    topColor: 'rgba(242, 38, 38, 0.2)',
                    bottomColor: '#FFFFFF',
                    lineColor: '#F22626',
                    lineWidth: 1,
                    priceScaleId: 'right',
                    scaleMargins: {
                        top: 0.8,
                        bottom: 0,
                    },
                    priceFormat: {
                        type: 'custom',
                        minMove: '0.00000001',
                        formatter: (value) => {
                            return parseFloat(value).toFixed(2);
                        }
                    },
                    crosshairMarkerVisible: false,
                    priceLineVisible: false,
                    lastValueVisible: false,
                },
                data: depthChartSellData,
            },
        ];

        return (
            <Wrapper>
                {((depthChartBuyData && depthChartBuyData.length > 0) || (depthChartSellData && depthChartSellData.length > 0)) && (
                    <Chart options={this.state.chartOptions} from={depthChartBuyData[1].time} to={depthChartSellData[38].time} areaSeries={series} autoWidth height={300} />
                )}
            </Wrapper>
        );
    }
}
