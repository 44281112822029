import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ProfileTwoFaConfiguration from './ProfileTwoFaConfiguration';

import { updatePassword, clear2faAuths, get2faAuths, requestNewGauth, requestAdd2fa, requestUpdate2faActions, requestDelete2fa, clearRequestSmsSend, requestSendSmsCode } from '../../../../actions/auth';
import { clearErrors } from '../../../../actions/errors';


function mapStateToProps(state) {
    return {
        auth: state.auth,
        securedActions: state.user.securedActions,
        securedMethods: state.user.securedMethods,
        isNewCredentialsError: state.errors.type === 'INVALID_NEW_2FA_CODE',
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePassword,
            clear2faAuths,
            get2faAuths,
            requestNewGauth, // WEBSOCKET
            requestAdd2fa, // WEBSOCKET
            requestUpdate2faActions, // WEBSOCKET
            requestDelete2fa, // WEBSOCKET
            clearRequestSmsSend, // INTERNAL
            requestSendSmsCode, // WEBSOCKET
            clearErrors,
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileTwoFaConfiguration));
