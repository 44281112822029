import styled from 'styled-components';

export const BackLink = styled.div`
  color: ${props => props.theme.colors.primary2};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  
  :before {
    content: '← ';
    color: #000;
  }
  
  :hover {
		cursor: pointer;
  }
`;

export const PortfolioTitle = styled.h1`
	color: ${props => props.theme.colors.primary6};
    margin: 10px 0;

	font-size: 24px;
	// line-height: 20px;
	font-weight: 400;
`;
