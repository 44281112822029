import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Login2FAScreen from './Login2FAScreen';
import { login2fa } from '../../actions/auth';

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        isCredentialsError: state.errors && state.errors.type && state.errors.type === ('CREDENTIALS_ERROR' || 'INVALID_2FA_CODE'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            login2fa,
        },
        dispatch
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Login2FAScreen));
