import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { Route } from 'react-router-dom';

import { Component as StandardPage } from '../../components/StandardPage';
import { Container as ProfileSidebar } from './components/ProfileSidebar';
import { Container as ProfileAccountSummary } from './components/ProfileAccountSummary';
import { Container as ProfileAccountVerification } from './components/ProfileAccountVerification';
import { Container as ProfileApiKeyManagement } from './components/ProfileApiKeyManagement';
import { Container as ProfileDownloadRecords } from './components/ProfileDownloadRecords';
import { Container as ProfileNotificationSettings } from './components/ProfileNotificationSettings';
import { Container as ProfilePoRConfiguration } from './components/ProfilePoRConfiguration';
import { Container as ProfileTwoFaConfiguration } from './components/ProfileTwoFaConfiguration';

import { mobile } from '../../styles';


const SidebarLoader = props => (
    <ContentLoader
        height={1500}
        width={280}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="16" y="0" rx="0" ry="0" width="75" height="21" />
        <rect x="16" y="24" rx="0" ry="0" width="230" height="45" />
        {/*<rect x="16" y="75" rx="0" ry="0" width="84" height="19" />*/}
        {/*<rect x="170" y="75" rx="0" ry="0" width="105" height="19" />*/}
        <rect x="0" y="105" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="191" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="277" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="363" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="449" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="535" rx="0" ry="0" width="280" height="83" />
        <rect x="0" y="621" rx="0" ry="0" width="280" height="83" />
    </ContentLoader>
);

const Sidebar = styled.div`
  border-right: 1px solid #eee;
  flex: 0 0 290px;
  padding: 15px;
  box-sizing: border-box;

  ${mobile`
      display: none;
      border: 0px;
  `};
`;

const SidebarMobile = styled(Sidebar)`
  display: none;
  ${mobile`
      display: block;
  `};
`;

const ProfileAccountSummaryNoMobile = styled.div`
  ${mobile`
      display: none;
  `};
`;

const Content = styled.div`
  flex: 1 1;
  // flex: 1 1 738px;
`;


export default class ProfileScreen extends React.Component {

    static propTypes = {
        userLoaded: PropTypes.bool,
        match: PropTypes.object,
    };

    componentDidMount() {
        document.title = 'Profile Settings (BlocPalX)';
    }

    render() {
        const { userLoaded, match } = this.props;

        return (
            <StandardPage row>
                <Sidebar>
                    {userLoaded ? (
                        <ProfileSidebar match={match}/>
                    ) : (
                        <SidebarLoader/>
                    )}
                </Sidebar>
                <Content>
                    <Route path={`${this.props.match.url}/`} exact component={() => (
                        <React.Fragment>
                            <SidebarMobile>
                                {userLoaded ? (
                                    <ProfileSidebar match={match}/>
                                ) : (
                                    <SidebarLoader/>
                                )}
                            </SidebarMobile>
                            <ProfileAccountSummaryNoMobile>
                                <ProfileAccountSummary/>
                            </ProfileAccountSummaryNoMobile>
                        </React.Fragment>
                    )}/>
                    <Route path={`${this.props.match.url}/summary`} exact component={() => (
                        <ProfileAccountSummary/>
                    )}/>
                    <Route path={`${this.props.match.url}/notifications`} exact component={() => (
                        <ProfileNotificationSettings/>
                    )}/>
                    <Route path={`${this.props.match.url}/downloads`} exact component={() => (
                        <ProfileDownloadRecords/>
                    )}/>
                    <Route path={`${this.props.match.url}/config2fa/:screen?/:label?`} exact component={(props) => (
                        <ProfileTwoFaConfiguration {...props}/>
                    )}/>
                    <Route path={`${this.props.match.url}/proofOfReserves`} exact component={() => (
                        <ProfilePoRConfiguration/>
                    )}/>
                    <Route path={`${this.props.match.url}/apikeys`} exact component={() => (
                        <ProfileApiKeyManagement/>
                    )}/>
                    <Route path={`${this.props.match.url}/verification/:screen?`} exact component={(props) => (
                        <ProfileAccountVerification {...props}/>
                    )}/>
                </Content>
            </StandardPage>
        );
    }

}
