import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import sha256 from 'crypto-js/sha256';
import { Link } from 'react-router-dom';
import BlocPalToolTip from '../../../../components/BlocPalToolTip/BlocPalToolTip';

import { CopyToClipboard } from 'react-copy-to-clipboard';

// import { mobile } from '../../../../styles';
import copyIcon from '../../../../images/ic-copy.svg';


const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
	color: ${props => props.theme.colors.primary6};
	font-size: 24px;
	line-height: 20px;
	font-weight: 400;
`;

const ItemLink = styled.div`
  // padding-left: 10px;
  // color: #bb0000;
  // text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  // white-space: nowrap;
  overflow: hidden;

  color: #ef8632;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;

  // color: #0000AA;
  // font-size: 12px;

  a {
      color: #ef8632;
  }

  a:visited {
      color: #ef8632;
  }

  a:hover {
    text-decoration: none;
    opacity: 0.5;
  }
`;

const CopyToClipboardSpan = styled.span`
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 0 5px;
`;

const DataContainer = styled.div`
    padding: 25px;
    margin-bottom: 20px;
`;


export default class WalletProofOfReserves extends React.Component {

    static propTypes = {
        coin: PropTypes.string,
        walletProof: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            downloadProofUrl: 'https://raw.githubusercontent.com/Blocpal-Inc/proof-of-reserves/main/audit_files/BlocPal_ProofOfReserves_PROOFID_merkletree.txt',
            downloadProofFilename: 'BlocPal_ProofOfReserves_PROOFID_merkletree.txt',
            proofSentenceCopied: false,
        };
    }

    componentDidMount() {
        //
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    onCopyProofSentence() {
        this.setState({
            proofSentenceCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                proofSentenceCopied: false,
            });
        }, 1500);
    }

    render() {
        const { walletProof } = this.props;
        const { downloadProofUrl, downloadProofFilename, proofSentenceCopied } = this.state;

        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="wallet.proof.walletProofTitle"
                        defaultMessage="Proof of Reserves Wallet Data"
                    />
                    <BlocPalToolTip tooltipId='portfolio.publicCode_tooltip'>
                        <FormattedMessage
                            id="portfolio.publicCode_tooltip"
                            defaultMessage="Used for our proof-of-reserves system"
                        />
                    </BlocPalToolTip>
                </Title>

                <FormattedMessage
                    id="wallet.proofOfReservesDetail"
                    defaultMessage="BlocPal audits balances monthly so you can participate in the public verification
                    of BlocPal's currency reserves using cryptographic SHA256 hashes and Merkle Tree proofs."
                    tagName="p"
                />

                {walletProof ? (
                    <DataContainer>
                        <ItemLink>
                            <a href="https://blocpal-inc.github.io/proof-of-reserves/" rel="noopener noreferrer" target="_blank" title="BlocPal Proof Of Reserves Merkle Tree Validator">
                                <FormattedMessage
                                    id="wallet.proofOfReservesValidator"
                                    defaultMessage="BlocPal Proof of Reserves Merkle Tree Validation Tool"
                                />
                            </a>
                        </ItemLink>
                        <br/>

                        <FormattedMessage
                            id="wallet.proof.walletProofId"
                            defaultMessage="Proof Id: {proofId}"
                            values={{
                                proofId: <b>{walletProof.pid}</b>,
                            }}
                        />
                        <br/>
                        <FormattedMessage
                            id="wallet.proof.walletProofDate"
                            defaultMessage="Proof Date: {proofDate}"
                            values={{
                                proofDate: <b>{new Date(walletProof.ts).toISOString().split('T')[0]}</b>,
                            }}
                        />
                        <br/>
                        <br/>

                        <FormattedMessage
                            id="wallet.proof.personalProofSentence"
                            defaultMessage="Personal Proof Sentence"
                            tagName="b"
                        />
                        <CopyToClipboardSpan>
                            {proofSentenceCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                <CopyToClipboard onCopy={()=>this.onCopyProofSentence()} text={walletProof.ps}>
                                    <span>
                                        <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                        &nbsp;Copy to clipboard
                                    </span>
                                </CopyToClipboard>
                            )}
                        </CopyToClipboardSpan>
                        <br/>
                        <br/>
                        {walletProof.ps}
                        <br/>
                        <br/>

                        <FormattedMessage
                            id="wallet.proof.walletProofHash"
                            defaultMessage="Proof Sentence Hash *"
                            tagName="b"
                        />
                        <br/>
                        <br/>
                        {sha256(walletProof.ps).toString()}
                        <br/>
                        <br/>

                        <FormattedMessage
                            id="wallet.proof.walletProofFileLink"
                            defaultMessage="Download Full Proof File"
                            tagName="b"
                        />
                        <br/>
                        <br/>
                        <b><a href={downloadProofUrl.replace('PROOFID', walletProof.pid)} rel="noopener noreferrer" target="_blank">
                            <FormattedMessage
                                id="wallet.proofFileLinkText"
                                defaultMessage="{fileName}"
                                values={{
                                    fileName: downloadProofFilename.replace('PROOFID', walletProof.pid),
                                }}
                            />
                        </a></b>
                        <br/>
                        <br/>
                        <br/>
                        <FormattedMessage
                            id="wallet.proofOfReservesCalcHash"
                            defaultMessage="* BlocPal uses SHA256 (hex format) for your Proof Sentence Hash."
                        />
                    </DataContainer>
                ) : (
                    <React.Fragment>
                        <FormattedMessage
                            id="wallet.proofOfReservesNoData"
                            defaultMessage="No proofs available."
                            tagName="h3"
                        />
                        <FormattedMessage
                            id="wallet.proofOfReservesNoDataDetail"
                            defaultMessage="To be included in our
                        Proof of Reserves Audits, you must have a balance in the asset at the time the audit is conducted."
                            tagName="p"
                        />
                    </React.Fragment>
                )}
                <FormattedMessage
                    id="wallet.proofOfReservesConfiguration"
                    defaultMessage="You can configure your Personal Proof Sentence for our next audit here: {profilePoRLink}"
                    tagName="p"
                    values={{
                        profilePoRLink: <Link to='/account/profile/proofOfReserves'>
                            <FormattedMessage
                                id="navigation.profile.proofOfReservesLinkText"
                                defaultMessage="Proof Of Reserves Configuration"
                            />
                        </Link>
                    }}
                />

                <br/>
                <ItemLink>
                    <a href="https://github.com/Blocpal-Inc/proof-of-reserves" rel="noopener noreferrer" target="_blank" title="BlocPal Proof Of Reserves on GitHub">
                        <FormattedMessage
                            id="wallet.proofOfReserves"
                            defaultMessage="Implementation details and Full BlocPal Proof of Reserves code on GitHub"
                        />
                    </a>
                </ItemLink>

            </Container>
        );
    }
}
