import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
// import dateFns from 'date-fns';
import DropdownMenu from 'react-dd-menu';

import { Component as SmallButton } from '../../../../components/SmallButton';

import { displayFormat } from '../../../../utils';
import { mobile } from '../../../../styles';
import {NavLink} from 'react-router-dom';


const Wrapper = styled.div`
  // background-color: #FFFFFF;

  flex: 1 auto;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  position: relative;

  padding: 10px 10px 30px 10px;

  ${mobile`
    padding: 0px;
  `};
`;

const Table = styled.div`
  //padding: 40px 40px 0;
  display: table;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-bottom: 1px solid #eee;

  ${mobile`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 0px;
  `};
`;

const Title = styled.div`
    margin-left: 15px;
    margin-top: 10px;
	color: #9B9B9B;
	font-size: 24px;
	line-height: 30px;
    white-space: nowrap;
`;

const Header = styled.div`
  display: table-row;

  >div {
      padding: 10px;
    }History

  ${mobile`
    display: none;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
  `};
`;

const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  font-size: 12px;
  padding: 10px;

  ${mobile`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  `};
`;

const CellFlexOnly = styled(Cell)`
  display: none;

  ${mobile`
    display: flex;
  `};
`;

const CellNoFlex = styled(Cell)`
  ${mobile`
    display: none;
  `};
`;

const LastCell = styled(Cell)`
  // width: 34%;
  text-align: center;

  span {
    // color: #ef8632;
    cursor: pointer;
  }
`;

const HeaderRow = styled(Header)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

    color: #ACB5BC;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.71px;
    line-height: 20px;

    >div {
      padding: 10px 20px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;
    }

    ${mobile`
      display: none;
    `};
`;

const TableRow = styled.div`
  display: table-row;

  &:nth-child(2n) {
    background-color: #02174708;
  }

  ${mobile`
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
  `};
`;

const PairLink = styled(NavLink)`
`;

/*
const DateCell = styled(LastCell)`
	color: #4A4A4A;
	font-size: 12px;
	line-height: 21px;
`;


const Pagination = styled.div`
  padding: 10px 20px;
`;

const PagingPuttons = styled.div`
	height: 56.39px;
	width: 105.2px;
	border: 2px solid #ef8632;
	border-radius: 5px;
	display: inline-flex;
	align-items: stretch;

	span {
	  width: 50%;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  color: #ef8632;
	  cursor: pointer;

	  &:first-child {
	    border-right: 1px solid #ef8632;
	  }
	}
 `;

 const PageInput = styled.input`
 	height: 49px;
 	width: 57.97px;
 	border: 1px solid #CFCFCF;
 	border-radius: 3px;
 	background-color: #FFFFFF;
 	margin-left: 30px;
 	margin-right: 10px;
 	text-align: center;
 	font-size: 15px;
 `;
 */

const TypeCell = styled(Cell)`
  text-transform: capitalize;

  ${mobile`
    display: none;
  `};
`;

const OrderTypeCell = styled(Cell)`
  display: block;
  // width: 105px;

  ${mobile`
    display: none;
  `};
`;

const TitleCell = styled(Cell)`
	color: #9B9B9B;
	font-size: 24px;
	line-height: 36px;

    ${mobile`
      display: none;
    `};
`;

const StyledMenu = styled(DropdownMenu)`
  display: inline-block;
  margin-left: 5px;
  position: relative;

  >span {
    cursor: pointer;
    color: #ef8632;
    text-transform: capitalize;
  }

  .dd-menu-items {
    position: absolute;
    width: 170px;
    background-color:#fff;
    box-shadow: 0 0 2px #ddd;

    ul {
      margin: 0;
      padding: 0;

      span {
        display: block;
        text-transform: capitalize;
        cursor: pointer;
        padding: 3px 15px;

        &:hover {
          background-color:#eee;
        }
      }
    }
  }
`;

const TableBody = styled.div`
  display: table-row-group;
`;


const CancelAllButton = styled(SmallButton)`
  // margin-left: 15px;
`;

const TYPES = [
    'All',
    'LIMIT',
    'LIMIT_MAKER',
    'STOP',
];

const sideBySideStyle = {
    display: 'flex',
    alignItems: 'center'
};

export default class TradeOrders extends React.Component {

    static propTypes = {
        baseCoinConfigName: PropTypes.string,
        quoteCoinConfigName: PropTypes.string,
        orders: PropTypes.object,

        submitCancelOrders: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            orderType: 'All',
        };
    }

    toggleOperationsMenu = () => {
        this.setState(state => ({
            isMenuOpen: !state.isMenuOpen
        }));
    };

    selectType = (type) => {
        this.setState({
            orderType: type,
            isMenuOpen: false,
        });
    };

    closeMenu = () => {
        this.setState({ isMenuOpen: false });
    };

    cancelOrder = (orderId) => {
        const orderIds = [orderId];
        this.props.submitCancelOrders(orderIds);
    }

    cancelAllOrders = () => {
        const { orders } = this.props;
        const orderIds = [];

        for (const orderId in orders) {
            orderIds.push(orderId);
        }

        this.props.submitCancelOrders(orderIds);
    }

    render() {
        const { orders, baseCoinConfigName, quoteCoinConfigName } = this.props;
        const { orderType, isMenuOpen } = this.state;

        const tradePairName = (baseCoinConfigName && quoteCoinConfigName) ? baseCoinConfigName + '-' + quoteCoinConfigName : undefined;

        const menuOptions = {
            isOpen: isMenuOpen,
            close: this.closeMenu,
            toggle: <span onClick={this.toggleOperationsMenu}>{orderType.replace(/_/g , ' ').toLocaleLowerCase()}</span>,
            align: 'left'
        };

        return (
            <Wrapper>
                {!(tradePairName) && (
                    <Title>
                        <FormattedMessage
                            id="trade.allOpenOrdersTitle"
                            defaultMessage="All Open Orders"
                        />
                    </Title>
                )}
                <Table>
                    <Header>
                        <TitleCell/>
                        {!(tradePairName) && (
                            <CellNoFlex/>
                        )}
                        <OrderTypeCell>
                            <React.Fragment>
                                Type:
                                <StyledMenu {...menuOptions}>
                                    {TYPES.filter(type => type !== orderType).map((type, i) => (
                                        <span key={i} onClick={() => this.selectType(type)}>
                                            {type.replace(/_/g , ' ').toLocaleLowerCase()}
                                        </span>
                                    ))}
                                </StyledMenu>
                            </React.Fragment>
                        </OrderTypeCell>
                        <CellNoFlex/>
                        <CellNoFlex/>
                        <CellNoFlex/>
                        <CellNoFlex/>
                        <LastCell>
                            {(orders && Object.keys(orders).length > 0) && Object.keys(orders)
                                .filter(orderId => (!tradePairName && (orderType === 'All' || orders[orderId].type === orderType || (orderType === 'STOP' && (orders[orderId].type.includes('STOP') || orders[orderId].type.includes('TAKE'))) )) || (tradePairName && orders[orderId].pair === tradePairName && (orderType === 'All' || orders[orderId].type === orderType  || (orderType === 'STOP' && (orders[orderId].type.includes('STOP') || orders[orderId].type.includes('TAKE'))) ))).length > 0 ? (
                                    <CancelAllButton bsStyle='red' onClick={() => {this.cancelAllOrders();}} title="Cancel All Orders">
                                        <FormattedMessage
                                            id="trade.cancelAllOrders"
                                            defaultMessage="Cancel All"
                                        />
                                    </CancelAllButton>
                                ) : ''}
                        </LastCell>
                    </Header>
                    <HeaderRow>
                        <Cell>
                            Order Id
                        </Cell>
                        {!(tradePairName) && (
                            <Cell>
                                Pair
                            </Cell>
                        )}
                        <Cell>
                            Type
                        </Cell>
                        <Cell>
                            Side
                        </Cell>
                        <Cell>
                            Remain
                        </Cell>
                        <Cell>
                            Price
                        </Cell>
                        <Cell>
                            Stop
                        </Cell>
                        <LastCell/>
                    </HeaderRow>
                    <TableBody>
                        {(orders && Object.keys(orders).length > 0) ?
                            Object.keys(orders)
                                .filter(orderId => (!tradePairName && (orderType === 'All' || orders[orderId].type === orderType || (orderType === 'STOP' && (orders[orderId].type.includes('STOP') || orders[orderId].type.includes('TAKE'))) )) || (tradePairName && orders[orderId].pair === tradePairName && (orderType === 'All' || orders[orderId].type === orderType  || (orderType === 'STOP' && (orders[orderId].type.includes('STOP') || orders[orderId].type.includes('TAKE'))) )))
                                .sort(function(a,b){return orders[b].oPrice-orders[a].oPrice;})
                                .map(orderId =>
                                    <TableRow key={orderId}>
                                        <CellNoFlex style={sideBySideStyle}>
                                            # {orderId}
                                        </CellNoFlex>
                                        {!(tradePairName) && (
                                            <Cell>
                                                <PairLink
                                                    key={'pairlink' + orderId}
                                                    to={`/market/${orders[orderId].pair}`}
                                                    exact
                                                >
                                                    {orders[orderId].pair.replace('-', '/')}
                                                </PairLink>
                                            </Cell>
                                        )}
                                        {(orders[orderId].side === 'BUY') ? (
                                            <TypeCell>
                                                {(['STOP_LOSS','TAKE_PROFIT','TRAILING_STOP'].some(el => orders[orderId].type.includes(el)) ? 'TRIGGER ENTRY' : orders[orderId].type.replaceAll('_', ' '))}
                                            </TypeCell>
                                        ) : (
                                            <TypeCell>
                                                {orders[orderId].type.replace('_LIMIT', '').replaceAll('_', ' ')}
                                            </TypeCell>
                                        )}
                                        <Cell>
                                            {orders[orderId].side}
                                        </Cell>
                                        <CellFlexOnly>
                                            {displayFormat(orders[orderId].rQty, 8)} @ {displayFormat(orders[orderId].oPrice, 8)}
                                        </CellFlexOnly>
                                        <CellNoFlex>
                                            {displayFormat(orders[orderId].rQty, 8)}
                                        </CellNoFlex>
                                        <CellNoFlex>
                                            {(Number(orders[orderId].oPrice) > 0) ? displayFormat(orders[orderId].oPrice, 8) : 'MARKET'}
                                        </CellNoFlex>
                                        <CellNoFlex>
                                            {(Number(orders[orderId].sPrice) > 0) && ((orders[orderId].type == 'TRAILING_STOP' || orders[orderId].type == 'TRAILING_STOP_LIMIT') ? ((orders[orderId].side == 'BUY') ? 'UP ' : 'DOWN ') + displayFormat(orders[orderId].sPrice, 2) + '%' : displayFormat(orders[orderId].sPrice, 8))}
                                        </CellNoFlex>
                                        <LastCell>
                                            <SmallButton bsStyle='red' onClick={() => {this.cancelOrder(orderId);}} title="Cancel Order">
                                                <FormattedMessage
                                                    id="trade.cancelOrder"
                                                    defaultMessage="Cancel"
                                                />
                                            </SmallButton>
                                        </LastCell>
                                    </TableRow>
                                ) : (
                                <TableRow key='0'>
                                    <Cell>
                                        <FormattedMessage
                                            id="trade.noOpenOrders"
                                            defaultMessage="No Open Orders"
                                        />
                                    </Cell>
                                    {!(tradePairName) && (
                                        <Cell/>
                                    )}
                                    <TypeCell/>
                                    <Cell/>
                                    <Cell/>
                                    <Cell/>
                                    <LastCell/>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </Wrapper>
        );
    }
}
