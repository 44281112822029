import React from 'react';

import styled from 'styled-components';

import { Component as ConfirmPanel } from '../../components/ConfirmPanel';
import { Component as StandardPage } from '../../components/StandardPage';

/* disable Latest Trades for now
import { FormattedMessage } from 'react-intl';
import { Container as LatestTransactions } from '../../components/LatestTransactions';
import { mobile } from '../../styles';
*/

const ConfirmationScreenContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
`;

const LeftPane = styled.div`
  flex: 1 auto;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
`;

/* disable Latest Trades for now
const SplitPane = styled.div`
  border-right: 1px solid #ACB5BC60;
  background-color: #fff;
  max-width: 1px;
  min-width: 1px;

  ${mobile`
    display: none;
  `}
`;

const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 410px;
  box-sizing: border-box;
  padding: 2em;
  position: relative;

  ${mobile`
    display: none;
  `}
`;

const TransactionsTitle = styled.div`
	color: #000000;
	font-size: 18px;
	font-weight: 300;
	line-height: 27px;
  margin-bottom: 0.5em;
`;

const TableWrapper = styled.div`
  position: absolute;
  width: calc(100% - 4em);
  left: 2em;
  top: 30px;
`;
*/

export default function ConfirmationScreen() {

    return (
        <StandardPage>
            <ConfirmationScreenContainer>
                <LeftPane>
                    <ConfirmPanel/>
                </LeftPane>
            </ConfirmationScreenContainer>
        </StandardPage>
    );

/* disable Latest Trades for now
    return (
        <StandardPage>
            <ConfirmationScreenContainer>
                <LeftPane>
                    <ConfirmPanel/>
                </LeftPane>

                <SplitPane />

                <RightPane>
                    <TableWrapper>
                        <TransactionsTitle>
                            <FormattedMessage
                                id="navigation.transactions.title"
                                defaultMessage="Latest Trades"
                            />
                        </TransactionsTitle>
                        <LatestTransactions/>
                    </TableWrapper>
                </RightPane>
            </ConfirmationScreenContainer>
        </StandardPage>
    );
*/

}
