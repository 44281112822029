import { createSelector } from 'reselect';
import {getCoinsConfig} from './currency';
import {getCurrentPath} from './router';


export const getActivePairs = state => state.market.activePairs;
const getTickers = state => state.market.tickers;
const getSellOrders = state => state.market.sellOrders;
const getBuyOrders = state => state.market.buyOrders;


export const getCurrentPrice = createSelector(
    getBuyOrders,
    getSellOrders,
    (buyOrders, sellOrders) => {
        const buyPrice = (buyOrders && buyOrders.length > 0) ? buyOrders[0].price : '0';
        const sellPrice = (sellOrders && sellOrders.length > 0) ? sellOrders[0].price : '0';
        return ((Number(buyPrice) + Number(sellPrice)) / 2);
    }
);

/* ...currently unused
export const getSortedPairs = createSelector(
    getActivePairs,
    (state, props) => props.sortDirection,
    (pairs, sortDirection) => {
        return (pairs && pairs.length > 0) ? pairs.sort(
            (prev, next) => sortDirection === 'ASC' ?
                prev.pair - next.pair :
                next.pair - prev.pair
        ) : {};
    }
);
*/

export const getTradePair = createSelector(
    getActivePairs,
    getCurrentPath,
    (pairs, currentPath) => {
        let pair = currentPath.split('/')[currentPath.split('/').length - 1];
        if (!pair.includes('-')) {
            pair = currentPath.split('/')[currentPath.split('/').length - 2];
        }
        return pairs ? pairs.find(w => w.pair === pair) || {} : {};
    }
);

export const getBaseCoinConfig = createSelector(
    getTradePair,
    getCoinsConfig,
    (tradePair, coinsConfig) => {
        return coinsConfig.find(coinConfig => coinConfig.name === tradePair.base);
    }
);

export const getQuoteCoinConfig = createSelector(
    getTradePair,
    getCoinsConfig,
    (tradePair, coinsConfig) => {
        return coinsConfig.find(coinConfig => coinConfig.name === tradePair.quote);
    }
);

export const getTradePairTicker = createSelector(
    getTickers,
    getActivePairs,
    getCurrentPath,
    (tickers, pairs, currentPath) => {
        let pair = currentPath.split('/')[currentPath.split('/').length - 1];
        if (!pair.includes('-')) {
            pair = currentPath.split('/')[currentPath.split('/').length - 2];
        }
        const tradePair = pairs ? pairs.find(w => w.pair === pair) || {} : {};

        return (tickers && tradePair.pair) ? {
            base: tradePair.base,
            quote: tradePair.quote,
            pair: tradePair.pair,
            status: tradePair.status,
            data: tickers.find(ticker => ticker.s === tradePair.symbol),
        } : {};
    }
);

export const getTradePairTickers = createSelector(
    getTickers,
    getActivePairs,
    (tickers, activePairs) => {
        return Array.from(new Set(activePairs))
            .map(tradePair => ({
                base: tradePair.base,
                quote: tradePair.quote,
                pair: tradePair.pair,
                status: tradePair.status,
                data: tickers.find(ticker => ticker.s === tradePair.symbol),
            }));
    }
);

const sortChartData = (chartEntries) => {
    const chartEntriesSorted = [].concat(chartEntries).sort(function(a, b) {
        if (a.time < b.time) { return -1; }
        if (a.time > b.time) { return 1; }
        return 0;
    });
    return chartEntriesSorted;
};

export const getDepthChartBuyData = createSelector(
    getSellOrders,
    getBuyOrders,
    (sellOrders, buyOrders) => {
        const topSellPrice = (sellOrders && sellOrders.length > 0) ? sellOrders[0].price : (buyOrders && buyOrders.length > 0) ? buyOrders[0].price : 0;
        const topBuyPrice = (buyOrders && buyOrders.length > 0) ? buyOrders[0].price : (sellOrders && sellOrders.length > 0) ? sellOrders[0].price : 0;
        const currentPrice = (Number(topSellPrice) + Number(topBuyPrice)) / 2;

        if (currentPrice == 0) return [];

        var depthBars = [];

        // calculate the buy side
        var buyOrdersIndex = 0;
        var totalBuyVol = 0;
        for (var i = 0; i < 40; i++) {
            const topPrice = currentPrice * (1 - (i * 0.0025));
            const bottomPrice = currentPrice * (1 - ((i+1) * 0.0025));

            while (buyOrders && buyOrders[buyOrdersIndex] && Number(buyOrders[buyOrdersIndex].price) > bottomPrice && Number(buyOrders[buyOrdersIndex].price) <= topPrice) {
                totalBuyVol = totalBuyVol + Number(buyOrders[buyOrdersIndex].qty);
                buyOrdersIndex++;
            }

            const bar = {
                time: Math.round(((topPrice+bottomPrice)/2) * 100000000),
                value: parseFloat(totalBuyVol),
                // color: '#00AA0040',
            };
            depthBars = depthBars.concat(bar);
        }

        return sortChartData(depthBars);
    }
);

export const getDepthChartSellData = createSelector(
    getSellOrders,
    getBuyOrders,
    (sellOrders, buyOrders) => {
        const topSellPrice = (sellOrders && sellOrders.length > 0) ? sellOrders[0].price : (buyOrders && buyOrders.length > 0) ? buyOrders[0].price : 0;
        const topBuyPrice = (buyOrders && buyOrders.length > 0) ? buyOrders[0].price : (sellOrders && sellOrders.length > 0) ? sellOrders[0].price : 0;
        const currentPrice = (Number(topSellPrice) + Number(topBuyPrice)) / 2;

        if (currentPrice == 0) return [];

        var depthBars = [];

        // calculate the sell side
        var sellOrdersIndex = 0;
        var totalSellVol = 0;
        for (var i = 0; i < 40; i++) {
            const bottomPrice = currentPrice * (1 + (i * 0.0025));
            const topPrice = currentPrice * (1 + ((i+1) * 0.0025));

            while (sellOrders && sellOrders[sellOrdersIndex] && Number(sellOrders[sellOrdersIndex].price) >= bottomPrice && Number(sellOrders[sellOrdersIndex].price) < topPrice) {
                totalSellVol = totalSellVol + Number(sellOrders[sellOrdersIndex].qty);
                sellOrdersIndex++;
            }

            const bar = {
                time: Math.round(((topPrice+bottomPrice)/2) * 100000000),
                value: parseFloat(totalSellVol),
                // color: '#AA000040',
            };
            depthBars = depthBars.concat(bar);
        }

        return depthBars;
    }
);

export const getOrderBookBuyEntries = createSelector(
    getBuyOrders,
    (buyOrders) => {
        let totalQty = 0;
        let orders = [];

        for (let i = 0; i < buyOrders.length; i++) {
            totalQty = totalQty + Number(buyOrders[i].qty);

            const order = {
                price: buyOrders[i].price,
                cumQty: parseFloat(totalQty).toFixed(8),
                orderIds: buyOrders[i].orderIds,
            };

            orders = orders.concat(order);

            if (i === 10) break;
        }
        return orders;
    }
);

export const getOrderBookSellEntries = createSelector(
    getSellOrders,
    (sellOrders) => {
        let totalQty = 0;
        let orders = [];

        for (let i = 0; i < sellOrders.length; i++) {
            totalQty = totalQty + Number(sellOrders[i].qty);

            const order = {
                price: sellOrders[i].price,
                cumQty: parseFloat(totalQty).toFixed(8),
                orderIds: sellOrders[i].orderIds,
            };

            orders = orders.concat(order);

            if (i === 10) break;
        }
        return orders;
    }
);
