import { handleActions } from 'redux-actions';
import { NotificationManager } from 'react-notifications';

const defaultState = {
    apiKeys: null,
    uploadedFiles: null,
    createdApiKey: null,
    securedMethods: ['NONE'],
    securedActions: ['NONE'],
    refreshKeyTable: false,
    refreshVerifyInfo: false,
    verificationUrlLoaded: false,
    refreshProofOfReservesInfo: false,
    referredBy: null,
};

export default handleActions(
    {
        ['CLEAR_AUTH_DATA']: () => {
            return defaultState;
        },
        ['SUCCESS_LOGIN']: (state = defaultState, action) => {
            return {
                ...state,
                ...action.payload,
                refreshVerifyInfo: false,
            };
        },
        ['VERIFICATION_URL_LOADED']: (state) => {
            return {
                ...state,
                verificationUrlLoaded: true,
            };
        },
        ['VERIFICATION_STATE']: (state = defaultState, action) => {
            return {
                ...state,
                ...action.payload,
                refreshVerifyInfo: false,
            };
        },
        ['GET_VRFY_INFO_SUCCESS']: (state = defaultState, action) => {
            return {
                ...state,
                ...action.payload,
                refreshVerifyInfo: false,
            };
        },
        ['UPDATE_VRFY_INFO_SUCCESS']: (state = defaultState, action) => {
            NotificationManager.success('Profile Info Updated');
            return {
                ...state,
                verificationData: action.payload.verificationData,
                refreshVerifyInfo: true,
            };
        },
        // WEBSOCKET
        ['USER_DATA']: (state = defaultState, action) => {
            return {
                ...state,
                ...action.payload,
                refreshVerifyInfo: false,
            };
        },
        ['GET_2FA_AUTHS_SUCCESS']: (state, action) => {
            return {
                ...state,
                securedMethods: action.payload.securedMethods,
                securedActions: action.payload.securedActions,
            };
        },
        ['GET_UPLOADED_FILE_LIST_SUCCESS']: (state, action) => {
            return {
                ...state,
                uploadedFiles: action.payload.uploadedFiles,
            };
        },
        ['CLEAR_API_KEYS']: (state) => {
            return {
                ...state,
                apiKeys: null,
            };
        },
        ['GET_API_KEYS_SUCCESS']: (state, action) => {
            return {
                ...state,
                apiKeys: action.payload.apiKeys,
                refreshKeyTable: false,
            };
        },
        ['CREATE_API_KEY_SUCCESS']: (state, action) => {
            NotificationManager.success('API Key Created');
            return {
                ...state,
                createdApiKey: action.payload,
                refreshKeyTable: true,
            };
        },
        ['DISABLE_API_KEY_SUCCESS']: (state) => {
            NotificationManager.success('API Key Disabled');
            return {
                ...state,
                refreshKeyTable: true,
            };
        },
        ['DELETE_API_KEY_SUCCESS']: (state) => {
            NotificationManager.success('API Key Deleted');
            return {
                ...state,
                refreshKeyTable: true,
            };
        },
        ['ADD_REFBY_SUCCESS']: (state, action) => {
            NotificationManager.success('Referral Code Registered');
            return {
                ...state,
                referredBy: action.payload.referredBy,
            };
        },
        ['GET_PROOF_RESERVES_SUCCESS']: (state, action) => {
            return {
                ...state,
                personalString: action.payload.personalString,
                includeBalances: action.payload.includeBalances,
                balancesString: action.payload.balancesString,
                walletProofs: action.payload.walletProofs,
                refreshProofOfReservesInfo: false,
            };
        },
        ['SET_PROOF_RESERVES_SUCCESS']: (state) => {
            NotificationManager.success('Options Saved');
            return {
                ...state,
                refreshProofOfReservesInfo: true,
            };
        },
    },
    defaultState
);
