import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


const OfflineWrapper = styled.div`
    width: 100%;
    background-color: #EF8632;
    // border-radius: 5px;
    // padding: 20px 40px;
    // height: 30px;
    position: relative;
    overflow: hidden;
`;

const ReconnectingWrapper = styled(OfflineWrapper)`
  background-color: #021647;
`;

const OfflineText = styled.div`
    background-color: #EF8632;
    color: #FFFFFF;
    // height: 100%;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 1.05px;
    // text-transform: uppercase;
    // position: absolute,
`;

const ReconnectingText = styled(OfflineText)`
  background-color: #021647;
  // color: #090809;
`;

export default class OfflineNotice extends React.Component {

    static propTypes = {
        maintenanceMode: PropTypes.bool,
        wsState: PropTypes.string,

        loginLoading: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            hideFirstState: true,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.wsState !== prevProps.wsState && prevState.hideFirstState === true) {
            this.setState({ hideFirstState: false, });
        }
    }

    render() {
        const { maintenanceMode, wsState } = this.props;
        const { hideFirstState } = this.state;

        // console.log('wsState', wsState, hideFirstState);

        return !maintenanceMode && (
            <React.Fragment>
                {!hideFirstState && wsState === 'RECONNECTING' && (
                    <ReconnectingWrapper>
                        <ReconnectingText>
                            <FormattedMessage
                                id="notice.reconnecting"
                                defaultMessage="Trying to connect..."
                            />
                        </ReconnectingText>
                    </ReconnectingWrapper>
                )}
                {!hideFirstState && wsState === 'NOT_CONNECTED' && (
                    <OfflineWrapper>
                        <OfflineText>
                            <FormattedMessage
                                id="notice.offline"
                                defaultMessage="DISCONNECTED"
                            />
                        </OfflineText>
                    </OfflineWrapper>
                )}
            </React.Fragment>
        );
    }
}
