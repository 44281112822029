import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobile } from '../../styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class MenuItem {
    constructor(title, path) {
        this.title = title;
        this.path = path;
    }
}

const Container = styled.div`
  background-color: ${props => props.type === 'BLUE' ? '' : '#021747'};
  color: #fff;
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
  justify-content: flex-end;
 
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  align-items: flex-end;

  > * {
    padding: 1em;
  }
  a {
    color: #FFFFFF;
  }

  a:hover {
    text-decoration: none;
    opacity: 0.7;
  }

  ${mobile`
    padding: 0 40px;
    justify-content: center;
  `};
`;

const BottomRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 950px) {
       flex-direction: column;
       
    }
`;

const FooterMenu = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-left: 10px;
  -webkit-padding-start: 0;

  flex: auto;
  display: flex;
  flex-flow: row wrap;

  ${mobile`
    justify-content: space-between;
    flex-direction:column;
    align-items:center;
  `};

  > li {
    font-size: 12px;
    font-weight: 600;
    line-height: 3em;
  	letter-spacing: 0.92px;
    margin-right: 2em;
     
    a {
      color: ${props => props.type === 'BLUE' ? '#111747' : '#FFFFFF'};
    }

    ${mobile`
        margin: 0;
    `};
  }
`;

const Copyright = styled.div`
//   padding-right: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  margin-left: 10px;
  color: ${props => props.type === 'BLUE' ? '#021747' : '#FFFFFF'};

  ${mobile`
    padding: 0;
    text-align: center;
  `};
`;

export default function AuthFooter(props) {

    const { type } = props || {};

    const menuItems = [
        new MenuItem(
            <FormattedMessage
                id="navigation.home"
                defaultMessage="Home"
            />,
            '/'
        ),
        new MenuItem(
            <FormattedMessage
                id="navigation.aboutUs"
                defaultMessage="About us"
            />,
            '/aboutUs'
        ),
        new MenuItem(
            <FormattedMessage
                id="navigation.privacy"
                defaultMessage="Privacy"
            />,
            '/privacy'
        ),
        new MenuItem(
            <FormattedMessage
                id="navigation.terms"
                defaultMessage="Terms of service"
            />,
            '/terms'
        ),
        new MenuItem(
            <FormattedMessage
                id="navigation.discordLink"
                defaultMessage="Discord"
            />,
            'https://discord.gg/tvcTwPT9Bt'
        ),
        new MenuItem(
            <FormattedMessage
                id="navigation.twitterLink"
                defaultMessage="Twitter"
            />,
            'https://twitter.com/blocpalx'
        ),
    ];

    return (
        <Container>
            <BottomRow>
                <FooterMenu type={type}>
                    {menuItems.map((item, index) => (
                        <li key={index}>{(item.path.includes('http', 0)) ?
                            <a href={item.path} target="_blank" rel="noreferrer">{item.title}</a> :
                            <Link to={item.path}>{item.title}</Link>}</li>
                    ))}
                </FooterMenu>
                <Copyright type={type}>
                    &copy;{'BlocPal International, 2017-2023. '}
                    <FormattedMessage
                        id="general.rights_reserved"
                        defaultMessage="All rights reserved."
                    />
                </Copyright>
            </BottomRow>
        </Container>
    );
}

AuthFooter.propTypes = {
    type: PropTypes.oneOf(['WHITE', 'BLUE'])
};

AuthFooter.defaultProps = {
    type: 'BLUE'
};
