import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as SmallButton } from '../../../../components/SmallButton';

import { Container as DepthChart } from '../DepthChart';

import { displayFormat, calculateScaleUsingPrice } from '../../../../utils';
import { mobile } from '../../../../styles';

import config from 'config';
const isDebugStreamButtonsEnabled = config.get('devOpts.streamButtons');


const Wrapper = styled.div`
  background-color: #FFFFFFBB;
  padding-bottom: 10px;

  display: flex;
  flex-flow: column;
  // flex-wrap: wrap;
  // justify-content: flex-start;
`;


const BuySellItem = styled.div`
  //margin-left: auto;
  //margin-right: auto;
  // width: 48%;
  flex-grow: 1;
`;

const OrderBookRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  //flex-flow: row;
  justify-content: space-around;

  background-color: #FFFFFFBB;
  // width: 100%;
  margin-top: -320px;
  min-height: 310px;
  z-index: 500;
`;

const OrderBookTable = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: table;
  width: 95%;
  border-bottom: 1px solid #eee;
`;

const Header = styled.div`
  display: table-row;

  >div {
      text-transform: capitalize;
      padding: 10px 20px;
  }History
`;

const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  font-size: 12px;

  // width: 22%;
`;

const CellRightJustified = styled(Cell)`
  text-align: right;
`;

const HeaderRow = styled(Header)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

  color: #555555;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0.71px;
  line-height: 20px;

    >div {
      // padding: 10px 20px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;
    }

    ${mobile`
      display: none;
    `}
`;

const HeaderRowMobile = styled(Header)`
  display: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

  color: #555555;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0.71px;
  line-height: 20px;

    >div {
      padding: 6px 8px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;
    }

  ${mobile`
    display: table-row;
  `}
`;

const TableRow = styled.div`
  display: table-row;

  // &:nth-child(2n) {
  //  background-color: #FBFBFBAA;
  //}

  >div {
    padding: 10px 20px;
  }

  ${mobile`
    >div {
      padding: 6px 8px;
    }
  `}
`;

const TableBody = styled.div`
  display: table-row-group;
`;

const CancelButtonRight = styled(SmallButton)`
  margin-left: 5px;
`;

const CancelButtonLeft = styled(SmallButton)`
  margin-right: 5px;
`;


export default class TradeOrders extends React.Component {

    static propTypes = {
        pair: PropTypes.string,

        buyOrders: PropTypes.array,
        sellOrders: PropTypes.array,
        currentPrice: PropTypes.number,
        tradePair: PropTypes.object,

        submitCancelOrders: PropTypes.func,
        setPriceField: PropTypes.func,
        clearOrderBookFeed: PropTypes.func,
        startOrderBookFeed: PropTypes.func,
        stopOrderBookFeed: PropTypes.func,
    };

    static defaultProps = {
        pair: null
    };

    constructor(props) {
        super(props);
        this.state = {
            decimals: calculateScaleUsingPrice(props.currentPrice),
            // isMenuOpen: false,
        };
    }

    componentDidMount() {
        if (this.props.pair && this.props.pair !== '') {
            this.props.startOrderBookFeed({
                pair: this.props.pair,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.pair !== prevProps.pair) {
            this.stopOrderBookFeed();
            if (this.props.pair && this.props.pair !== '') {
                this.props.startOrderBookFeed({
                    pair: this.props.pair,
                });
            }
        }

        if (prevState.decimals !== calculateScaleUsingPrice(this.props.currentPrice)) {
            this.setState({
                decimals: calculateScaleUsingPrice(this.props.currentPrice),
            });
        }
    }

    componentWillUnmount() {
        this.stopOrderBookFeed();
    }


    submitSetPriceField = (price) => {
        this.props.setPriceField(price);
    }

    cancelOrder = (orderIds) => {
        // const orderIds = [orderId];
        this.props.submitCancelOrders(orderIds);
    }

    stopOrderBookFeed = () => {
        this.props.stopOrderBookFeed();
        this.props.clearOrderBookFeed();
    };

    render() {
        const { buyOrders, sellOrders, tradePair } = this.props;
        const { decimals } = this.state;

        // console.log(buyOrders);
        // console.log(sellOrders);

        // console.log('CURRENT PAIR');
        // console.log(tradePair);

        return (
            <Wrapper>
                <DepthChart/>

                <OrderBookRow>
                    <BuySellItem>
                        <OrderBookTable>
                            <Header>
                                <Cell/>
                                <CellRightJustified>
                                    <FormattedMessage
                                        id="trade.bids"
                                        defaultMessage="Bids"
                                    />
                                </CellRightJustified>
                            </Header>
                            <HeaderRow>
                                <Cell>
                                    Depth ({tradePair.base})
                                </Cell>
                                <CellRightJustified>
                                    Price ({tradePair.quote})
                                </CellRightJustified>
                            </HeaderRow>
                            <HeaderRowMobile>
                                <Cell>
                                    Depth<br/>{tradePair.base}
                                </Cell>
                                <CellRightJustified>
                                    Price<br/>{tradePair.quote}
                                </CellRightJustified>
                            </HeaderRowMobile>
                            <TableBody>
                                {buyOrders && buyOrders.length > 0 ? buyOrders.slice(0, 15).map( (order, i) => (
                                    <TableRow key={i} onClick={() => {this.submitSetPriceField(order.price);}}>
                                        <Cell>
                                            {displayFormat(+order.cumQty, 8)}
                                        </Cell>
                                        <CellRightJustified>
                                            {order.orderIds.length > 0 && (
                                                <CancelButtonLeft bsStyle='red' onClick={() => {this.cancelOrder(order.orderIds);}} title={'Cancel ' + order.orderIds}>
                                                    <FormattedMessage
                                                        id="trade.cancelOrder"
                                                        defaultMessage="Cancel"
                                                    />
                                                </CancelButtonLeft>
                                            )}
                                            {displayFormat(+order.price, decimals)}
                                        </CellRightJustified>
                                    </TableRow>
                                )) : (
                                    <TableRow key='0'>
                                        <Cell>
                                            <FormattedMessage
                                                id="trade.noBids"
                                                defaultMessage="No Bids"
                                            />
                                        </Cell>
                                        <Cell/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </OrderBookTable>
                    </BuySellItem>
                    <BuySellItem>
                        <OrderBookTable>
                            <Header>
                                <Cell>
                                    <FormattedMessage
                                        id="trade.asks"
                                        defaultMessage="Asks"
                                    />
                                </Cell>
                                <Cell/>
                            </Header>
                            <HeaderRow>
                                <Cell>
                                    Price ({tradePair.quote})
                                </Cell>
                                <CellRightJustified>
                                    Depth ({tradePair.base})
                                </CellRightJustified>
                            </HeaderRow>
                            <HeaderRowMobile>
                                <Cell>
                                    Price<br/>{tradePair.quote}
                                </Cell>
                                <CellRightJustified>
                                    Depth<br/>{tradePair.base}
                                </CellRightJustified>
                            </HeaderRowMobile>
                            <TableBody>
                                {sellOrders && sellOrders.length > 0 ? sellOrders.slice(0, 15).map( (order, i) => (
                                    <TableRow key={i} onClick={() => {this.submitSetPriceField(order.price);}}>
                                        <Cell>
                                            {displayFormat(+order.price, decimals)}
                                            {order.orderIds.length > 0 && (
                                                <CancelButtonRight bsStyle='red' onClick={() => {this.cancelOrder(order.orderIds);}} title={'Cancel ' + order.orderIds}>
                                                    <FormattedMessage
                                                        id="trade.cancelOrder"
                                                        defaultMessage="Cancel"
                                                    />
                                                </CancelButtonRight>
                                            )}
                                        </Cell>
                                        <CellRightJustified>
                                            {displayFormat(+order.cumQty, 8)}
                                        </CellRightJustified>
                                    </TableRow>
                                )) : (
                                    <TableRow key='0'>
                                        <Cell>
                                            <FormattedMessage
                                                id="trade.noAsks"
                                                defaultMessage="No Asks"
                                            />
                                        </Cell>
                                        <Cell/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </OrderBookTable>
                    </BuySellItem>
                </OrderBookRow>

                {(isDebugStreamButtonsEnabled) && (
                    <SmallButton bsStyle='red' onClick={() => {this.stopOrderBookFeed();}} title={'Stop Order Book Feed'}>
                        <FormattedMessage
                            id="trade.stopOrderBookFeed"
                            defaultMessage="Stop Order Book Feed"
                        />
                    </SmallButton>
                )}

            </Wrapper>
        );
    }
}
