import styled from 'styled-components';
import {Component as Select} from '../components/Select';
import {mobile} from '../styles';

export const StyledSelect = styled(Select)`
  white-space: pre-wrap;
  width: 400px;

  ${mobile`
    width: 350px;
  `};
`;
