/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Component as RouteButton } from '../RouteButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


export class MenuItem {
    constructor(key, title, path) {
        this.key = key;
        this.title = title;
        this.path = path;
    }
}

const Menu = styled.ul`
  list-style: none;
  margin: 5px auto 0px 50px;
  padding-left: 0;
  // margin: 0;
  // -webkit-padding-start: 0;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  > li {
    margin-right: 0.5em;
  }
`;


const RouteMenu = (props) => {
    
    const {router} = props;
    // console.log('router: '+JSON.stringify(router.location.pathname));

    return (
        <Menu>
            {props.items.map(item => (
                <li key={item.key}>
                    <RouteButton 
                        to={item.path} 
                        title={item.title} 
                        bsStyle="menu"
                        borderColor="#021747"
                        color={router.location.pathname.startsWith(item.path) ? '#FFFFFF': '#021747'}
                        backgroundColor={router.location.pathname.startsWith(item.path) ? '#021747': '#FFFFFF'}
                    >
                        {item.title}
                    </RouteButton>

                </li>
            ))}
        </Menu>
    );
};

function mapStateToProps(state) {
    return {
        router: state.router,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RouteMenu);

RouteMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.instanceOf(MenuItem)),
    router: PropTypes.shape({}),
};

RouteMenu.defaultProps = {
    items: [],
};
