import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Component as Button } from '../../../../components/Button';
import { Component as QRCode } from '../../../../components/QRCode';

import { mobile } from '../../../../styles';
import { generateQRString } from '../../../../utils';

import copyIcon from '../../../../images/ic-copy.svg';
import qrIcon from '../../../../images/ic-qrcode-sm-orange.svg';
import {ItemIncomplete, ItemText, ItemTextTitle} from '../../../../commonStyles/AccountProfileStyles';
import {StyledSelect} from '../../../../commonStyles/FormStyles';
import InputGroup from '../../../../components/InputGroup/InputGroup';


const Container = styled.div`
  padding: 20px;
`;

const Address = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #eee;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  
  p {
    margin-top: 0;
	color: #4A4A4A;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.25px;
	line-height: 13px;

	span {
        border-radius: 3px;
        background-color: rgba(172,181,188,0.24);
        color: #9B9B9B;
        font-size: 12px;
        line-height: 20px;
        font-weight: 300;
        padding: 4px 7px;
        margin-left: 5px;
	}
  }

  >span {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 10px;
    display: block;
  }
`;

const AddressActions = styled.div`
  color: #ef8632;
  font-size: 12px;
  margin-top: 10px;

  img {
    margin-right: 3px;
  }

  span {
    margin-right: 10px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
`;

const AddressQR = styled(QRCode)`
  margin-top: 10px;
  margin-left: 25px;
  height: 200px;
  width: 200px;

  ${mobile`
    margin-left: auto;
    margin-right: auto;
  `}
`;

const RequestAddressButton = styled(Button)`
  margin-top: 20px;
  width: 400px;

  ${mobile`
    width: 350px;
  `}
`;

const DepositInfoBlock = styled.div`
  padding-top: 20px;
  margin-right: 40px;

  ${mobile`
    margin: 0;
    text-align: center;
    justify-content: center;
  `}

  h3 {
    color: #4A4A4A;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
    margin-bottom: 20px;

    ${mobile`
        text-align: center;
    `}
  }

  h4 {
    text-align: center;
    color: #D0021B;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 0 20px 0;

    ${mobile`
        text-align: center;
    `}
  }

  img {
    margin-right: 3px;
    margin-bottom: -1px;
  }
`;

const Bold = styled.span`
    font-weight: 600;
`;

const TagRequiredButton = styled(Button)`
  display: inline-block;
  text-align: center;
  width: 70%;

  ${mobile`
    width: 100%;
    display: flex;
    justify-content: center;

    a {
        width: 170px;
        text-align: center;
    }
  `}
`;

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

const VerifyAccountContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
  
  width: 95%;

  background-color: ${props => props.theme.colors.primary7};
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  justify-content: center;
  
  ${mobile`
    width: 350px;
  `}
`;

const VerifyAccountButton = styled(Button)`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  width: 300px;
  
  ${mobile`
    width: 250px;
  `}
`;

const RegionLockedContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  width: 500px;

  color: #ef8632;
  background-color: #FAFAFA;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #CFCFCF;
  border-radius: 5px;

  ${mobile`
    width: 350px;
  `}
`;


const VerifyAccount = withRouter(({ history }) => (
    <VerifyAccountContainer>
        <FormattedMessage
            id="portfoilo.kycIncomplete"
            defaultMessage="Account Verification Is Required To Enable Deposits"
        />
        <Break/>
        <VerifyAccountButton bsStyle='orange' onClick={() => { history.push('/account/profile/verification'); }}>
            <FormattedMessage
                id="profile.verification_required"
                defaultMessage="Account Verification"
            />
        </VerifyAccountButton>
    </VerifyAccountContainer>
));


/*
const SetWithdrawAddress = withRouter(({ history, coin }) => (
    <VerifyAccountContainer>
        <FormattedMessage
            id="portfoilo.no_perm_withdraw_set"
            defaultMessage="A Permanent Withdrawal Address Is Required To Enable Deposits"
        />
        <Break/>
        <VerifyAccountButton onClick={() => { history.push('/account/portfolio/' + coin + '/permwithdraw'); }}>
            <FormattedMessage
                id="profile.set_withdraw_address"
                defaultMessage="Set Withdrawal Address"
            />
        </VerifyAccountButton>
    </VerifyAccountContainer>
));
*/


export default class PortfolioDeposits extends React.Component {

    static propTypes = {
        coin: PropTypes.string,

        clearDepositAddresses: PropTypes.func,
        getDepositAddresses: PropTypes.func,
        requestDepositAddress: PropTypes.func,

        networkOptions: PropTypes.array,
        networkConfigs: PropTypes.object,
        addresses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            asset: PropTypes.string,
            address: PropTypes.string,
            tag: PropTypes.string,
            used: PropTypes.bool
        })),
        isDepositAllowed: PropTypes.bool,
        refreshDepositAddresses: PropTypes.bool,

        userKycTier: PropTypes.string,
        userJurisdictionLockWhitelisted: PropTypes.bool,

        intl: PropTypes.object,
    };

    state = {
        depositAddressLoading: false,
        viewDepositAddress: false,
        activeQR: null,
        addressCopied: '',
        addressTagCopied: '',
        network: '',
    };

    componentDidMount() {
        if (this.props.coin) {
            this.props.clearDepositAddresses();
            this.props.getDepositAddresses(this.props.coin);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.refreshDepositAddresses &&
            this.props.refreshDepositAddresses === true &&
            this.props.refreshDepositAddresses !== prevProps.refreshDepositAddresses) {

            this.setState({
                depositAddressLoading: false,
                network: '',
            });
        }
    }

    onCopyAddress(address) {
        this.setState({
            addressCopied: address,
        });
        this.timer = setTimeout(() => {
            this.setState({
                addressCopied: '',
            });
        }, 1500);
    }

    onCopyAddressTag(address) {
        this.setState({
            addressTagCopied: address,
        });
        this.timer = setTimeout(() => {
            this.setState({
                addressTagCopied: '',
            });
        }, 1500);
    }

    onSelectNetwork = (key) => {
        this.setState({
            network: key.value,
        });
    };

    onSubmitViewDepositAddress = () => this.setState({
        viewDepositAddress: true
    });

    toggleQR = (indexId) => {
        if (indexId === this.state.activeQR) {
            this.setState({
                activeQR: null
            });
        } else {
            this.setState({
                activeQR: indexId
            });
        }
    };

    requestDepositAddress = () => {
        const { network } = this.state;

        if (this.props.coin && network) {
            this.setState({
                depositAddressLoading: true
            });

            this.props.requestDepositAddress(this.props.coin, network);
        }
    }

    render() {
        const { coin,
            userKycTier,
            userJurisdictionLockWhitelisted,
            addresses,
            networkConfigs,
            networkOptions,
            isDepositAllowed,
            intl
        } = this.props;
        const { network,
            depositAddressLoading,
            viewDepositAddress,
            addressCopied,
            addressTagCopied
        } = this.state;

        console.log('networkConfigs', networkConfigs);

        return (
            <Container>
                {(userKycTier === 'NONE' || (!isDepositAllowed && !userJurisdictionLockWhitelisted)) ? (
                    (userKycTier === 'NONE') ? (
                        <VerifyAccount/>
                    ) : (
                        <RegionLockedContainer>
                            <FormattedMessage
                                id="portfoilo.regionDepositsNotAllowed"
                                defaultMessage="Deposits of {asset} are not yet enabled in your region"
                                values={{
                                    asset: coin,
                                }}
                            />
                        </RegionLockedContainer>
                    )
                ) : (
                    <React.Fragment>
                        {addresses && addresses.length > 0 ? addresses.map((address, i) => (
                            (!viewDepositAddress && address.network === 'XRP') ? (
                                <DepositInfoBlock key={i}>
                                    <FormattedMessage
                                        id="oct.tag_required_notice"
                                        defaultMessage="{tagName} is required for {network} network deposits"
                                        tagName="h3"
                                        values={{
                                            network: address.network,
                                            tagName: (networkConfigs[address.network] && networkConfigs[address.network].addressTagName) ? networkConfigs[address.network].addressTagName.replace(' (Optional)', '') : 'Tag',
                                        }}
                                    />
                                    <TagRequiredButton onClick={this.onSubmitViewDepositAddress}>
                                        <FormattedMessage
                                            id="oct.tag_required_button_ack"
                                            defaultMessage="I understand, show me the deposit addresses"
                                        />
                                    </TagRequiredButton>
                                </DepositInfoBlock>
                            ) : (
                                (networkConfigs[address.network]) && (
                                    <Address key={i}>
                                        {(networkConfigs[address.network].depositWarning !== '') && (
                                            <ItemIncomplete>
                                                <ItemTextTitle>
                                                    <FormattedMessage
                                                        id="profile.depositWarningLabel"
                                                        defaultMessage="Warning ({network} Network)"
                                                        values={{
                                                            network: address.network,
                                                        }}
                                                    />
                                                </ItemTextTitle>
                                                <ItemText dangerouslySetInnerHTML={{__html: networkConfigs[address.network].depositWarning }} />
                                            </ItemIncomplete>
                                        )}

                                        <Bold>
                                            <FormattedMessage
                                                id="profile.depositAddressLabel"
                                                defaultMessage="Deposit {addressLabel} ({network} Network)"
                                                values={{
                                                    network: address.network,
                                                    addressLabel: (networkConfigs[address.network].addressName) ? networkConfigs[address.network].addressName : 'Address',
                                                }}
                                            />
                                        </Bold>
                                        {address.address}
                                        {!address.used && (
                                            <FormattedMessage
                                                id="profile.deposit_address_not_used"
                                                defaultMessage="(not yet used)"
                                            />
                                        )}
                                        {address.tag !== '' && address.network === 'XRP' && (
                                            <React.Fragment>
                                                <Bold>
                                                    <FormattedMessage
                                                        id="profile.depositAddressTagLabel"
                                                        defaultMessage="Deposit {tagName} (Required!)"
                                                        values={{
                                                            tagName: (networkConfigs[address.network].addressTagName) ? networkConfigs[address.network].addressTagName.replace(' (Optional)', '') : 'Tag',
                                                        }}
                                                    />
                                                </Bold>
                                                {address.tag}
                                            </React.Fragment>
                                        )}
                                        <AddressActions>
                                            <span onClick={() => this.toggleQR(i)}>
                                                <img src={'/' + qrIcon} alt=""/>
                                                {this.state.activeQR === i ? (
                                                    <FormattedMessage
                                                        id="profile.hide_qr"
                                                        defaultMessage="Hide QR code"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        id="profile.show_qr"
                                                        defaultMessage="Show QR code"
                                                    />
                                                )}
                                            </span>
                                            <span>
                                                {(addressCopied === (address.address+address.tag)) ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                    <CopyToClipboard onCopy={()=>this.onCopyAddress(address.address+address.tag)} text={address.address}>
                                                        <span>
                                                            <img src={'/' + copyIcon} alt=""/>
                                                            <FormattedMessage
                                                                id="general.copy_address_to_clipboard"
                                                                defaultMessage="Copy address to clipboard"
                                                            />
                                                        </span>
                                                    </CopyToClipboard>
                                                )}
                                            </span>
                                            {address.tag !== '' && address.network === 'XRP' && (
                                                <span>
                                                    {(addressTagCopied === (address.address+address.tag)) ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                        <CopyToClipboard onCopy={()=>this.onCopyAddressTag(address.address+address.tag)} text={address.tag}>
                                                            <span>
                                                                <img src={'/' + copyIcon} alt=""/>
                                                                <FormattedMessage
                                                                    id="general.copy_tag_to_clipboard"
                                                                    defaultMessage="Copy tag to clipboard"
                                                                />
                                                            </span>
                                                        </CopyToClipboard>
                                                    )}
                                                </span>
                                            )}
                                        </AddressActions>
                                        {(this.state.activeQR === i) && (
                                            <React.Fragment>
                                                <AddressQR
                                                    value={generateQRString(address, networkConfigs[address.network], 0)}
                                                    size={200}
                                                />
                                            </React.Fragment>
                                        )}
                                    </Address>
                                )
                            )
                        )) : (
                            <React.Fragment>
                                <FormattedMessage
                                    id="portfolio.noAddressesYet"
                                    defaultMessage=" ... No Addresses Yet"
                                />
                                <Break/>
                            </React.Fragment>
                        )}

                        {(networkConfigs) && (
                            <React.Fragment>
                                <InputGroup>
                                    <FormattedMessage
                                        id="portfolio.depositAddressNetwork"
                                        defaultMessage="New Deposit Address"
                                        tagName="label"
                                    />
                                    <StyledSelect
                                        placeholder={
                                            intl.formatMessage({
                                                id: 'portfolio.selectDepositNetwork',
                                                defaultMessage: 'Select Network'
                                            })
                                        }
                                        value={networkOptions.find(({value}) => value === network)}
                                        onChange={this.onSelectNetwork}
                                        options={networkOptions}
                                        isDisabled={depositAddressLoading}
                                    />
                                </InputGroup>
                                {(network && network !== '') && (
                                    (networkConfigs[network].addressPool > 0) ? (
                                        (networkConfigs[network].depositAddressesAllowed === -1 || addresses.filter((address) => address.network === network).length < networkConfigs[network].depositAddressesAllowed) ? (
                                            <InputGroup>
                                                <RequestAddressButton bsStyle='blue' disabled={depositAddressLoading} onClick={() => this.requestDepositAddress()}>
                                                    <FormattedMessage
                                                        id="profile.request_new_address"
                                                        defaultMessage="Request New Address"
                                                    />
                                                </RequestAddressButton>
                                            </InputGroup>
                                        ) : (
                                            <FormattedMessage
                                                id="portfolio.depositAddressLimitReached"
                                                defaultMessage="You've reached your limit of {maxDepositAddresses} on this network."
                                                values={{
                                                    maxDepositAddresses: networkConfigs[network].depositAddressesAllowed,
                                                }}
                                            />
                                        )
                                    ) : (
                                        <FormattedMessage
                                            id="portfolio.depositAddressNotAvailable"
                                            defaultMessage="There are no deposit addresses available on this network. This is usually temporary. Please check back."
                                        />
                                    )
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Container>
        );
    }
}
