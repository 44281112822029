import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfileAccountSummary from './ProfileAccountSummary';
import {submitReferredBy} from '../../../../actions/user';
import {injectIntl} from 'react-intl';

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitReferredBy,
        },
        dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileAccountSummary));
