import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Component as Button } from '../../components/Button';
import { mobile } from '../../styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';


const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  
    ${mobile`
        flex-direction: column;
    `};
`;

const Box = styled.div`
    display: flex;
    background-color: #021747;
    padding: 1em;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    align-items: center;
    flex: 1;
`;

const Left = styled.div`
    flex: 1;  
    display: flex;
    align-items: center;
`;

const Right = styled.div`
    flex: 0 0 0 200px;
    justify-content: center;
    align-items: center;
`;

const Text = styled.div`
    background: #021747;
    color: #FFFFFF;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;  
    text-transform: capitalize;
    margin-right: 10px;
`;

const ActionButton = styled(Button)`
    margin-left: 10px;
    border-radius: 5px;
    text-transform: capitalize;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;  
    padding: 1em 3em;
`;

const Notice = (textElement = null, actionElement = null) => {
    return (
        <Box>
            <Left>
                {textElement}
            </Left>
            <Right>
                {actionElement}
            </Right>
        </Box>
    );
};

const Action = (onClick, textElement) => (
    <ActionButton
        bsStyle={'orange'}
        onClick={onClick}
    >
        {textElement}
    </ActionButton>
);

const VerifyAccount = withRouter(({ history }) => Notice(
    <Text>
        <FormattedMessage
            id="profile.verify_identity_to_unlock_deposit_and_trading"
            defaultMessage="Verify your identity to unlock deposits and trading"
        />
    </Text>,
    Action(
        () => { history.push('/account/profile/verification'); },
        <FormattedMessage
            id="profile.verify_identity"
            defaultMessage="Verify Identity"
        />
    )
));


const SetupTwoFactor = withRouter(({ history }) => Notice(
    <Text>
        <FormattedMessage
            id="profile.secure_account_using_2fa"
            defaultMessage="Secure your account to unlock deposits and trading"
        />
    </Text>,
    Action(
        () => { history.push('/account/profile/config2fa/newTwoFactor'); },
        <FormattedMessage
            id="profile.enable2FA"
            defaultMessage="Enable 2FA"
        />
    )
));

const LockedAccount = () => Notice(
    <Text>
        <FormattedMessage
            id="profile.user_locked"
            defaultMessage="This Account Is Currently Locked"
        />
    </Text>
);

const LockedWithdrawals = () => Notice(
    <Text>
        <FormattedMessage
            id="profile.withdrawal_locked"
            defaultMessage="Withdrawals On This Account Are Currently Locked"
        />
    </Text>
);

const AccountNotices = ({ isAuthenticated, withdrawalsDisabled, userLocked, kycTier, securedMethods }) => {
    return (
        <Container>
            {
                (isAuthenticated && userLocked) &&
                (<LockedAccount />)
            }
            {
                isAuthenticated && withdrawalsDisabled &&
                (<LockedWithdrawals />)
            }
            {
                isAuthenticated && kycTier === 'NONE' &&
                (<VerifyAccount />)
            }
            {
                (isAuthenticated && securedMethods && securedMethods.indexOf('NONE') !== -1) &&
                (<SetupTwoFactor />)
            }
        </Container>
    );
};

AccountNotices.propTypes = {
    isAuthenticated: PropTypes.bool,
    withdrawalsDisabled: PropTypes.bool,
    userLocked: PropTypes.bool,
    kycTier: PropTypes.string,
    securedMethods: PropTypes.array,
};

export default AccountNotices;

