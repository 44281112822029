import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { Component as CurrencyInput } from '../../../../components/CurrencyInput';
import { Component as Button } from '../../../../components/Button';
import { Component as Input } from '../../../../components/Input';
import { Container as InputTwoFa } from '../../../../components/InputTwoFa';
import { Component as InputGroup } from '../../../../components/InputGroup';
import { Component as Switcher} from '../../../../components/Switcher';
import BlocPalToolTip from '../../../../components/BlocPalToolTip/BlocPalToolTip';

import { displayFormat, validatePriceInput, validatePercentInput, calculateScaleUsingPrice } from '../../../../utils';
import { mobile } from '../../../../styles';


// NOTE:
// use of floatSide = 'left' or floatSide = 'right' used to "mirror" the interface.
// eg: floatSide={side === 'BUY' ? 'left' : 'right'}
// eg: <PriceInputGroup flexDirection={side === 'BUY' ? 'row-reverse' : 'row'}>
// design team decided not to utilize this feature, but significant work went into it
// so instead of pulling it out, for now, we just always set floatSide = 'left'

const VALIDATION_MESSAGES = {
    quantity: (
        <FormattedMessage
            id="error.quantity_required"
            defaultMessage="Quantity must be set"
        />
    ),
    price: (
        <FormattedMessage
            id="error.price_required"
            defaultMessage="Price must be set"
        />
    ),
    code: (
        <FormattedMessage
            id="error.code_required"
            defaultMessage="2FA Code must be set"
        />
    ),
};

const Tabs = styled.div`
  white-space: nowrap;
  position: relative;

  margin-left: 5px;
  margin-top: 0px;
  margin-right: auto;

  width: 340px;

  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;

  a {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    color: rgba(38, 38, 38, 0.5);
    background-color: #E6E6E6;
    // border: 1px solid #CFCFCF;
    border-radius: 2px;
    //border-radius: 3px 7px 0px 0px;
    cursor: pointer;
    padding: 8px 6px 8px 6px;
    margin: 2px;
    // margin-left: -1px;

    font-size: 10px;
    font-family: Inter, sans-serif;
    font-weight: 800;
    // letter-spacing: 0em;
    text-align: center;
    
    width: 58px;
  }

  a.selected, a:hover {
    color: #fff;
    // background-color: #ef8632;

    // border-bottom: 1px solid #FAFAFA;
    // z-index: 2;

    background-color: #8995AA;
  }

  ${mobile`
    width: 280px;
    font-size: 12px;
  `};
`;

const MaxButton = styled.div`
  // width: 49%;
  float: ${props => props.floatSide || 'left'};
  text-align: ${props => props.floatSide || 'left'};

  margin-top: 10px;
  margin-bottom: 5px;

  // display: flex;
  // flex-direction: column;
  // align-content: center;
  // justify-content: center;

  p {
    font-weight: 700;
    font-size: 12px;

    color: #fff;
    background-color: #c0c0c0;
    border: 1px solid #CFCFCF;
    border-radius: 5px;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
    margin: 1px;
    // margin-left: -1px;
  }

  p.selected, p:hover {
    color: #fff;
    background-color: #ef8632;
    // border-bottom: 1px solid #FAFAFA;
    // z-index: 2;
  }

`;

const Container = styled.div`
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  text-transform: capitalize;
  width: 340px;

  background-color: #FAFAFA;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  // border: 1px solid #CFCFCF;
  border-radius: 0 0 7px 3px;
  // border-radius: 5px;
`;

const FormHeader = styled.div`
  margin-top: 5px;
  width: 90%;
  float: ${props => props.floatSide || 'left'};
  text-align: ${props => props.floatSide || 'left'};
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
`;

const BalanceItem = styled.div`
  width: 49%;
  float: ${props => props.floatSide || 'left'};
  text-align: ${props => props.floatSide || 'left'};

  color: #262626;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 12px;

  label {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 4px;

    color: ${props => props.theme.colors.primary6};
    line-height: 24px;
  }

  p {
    padding-bottom: 4px;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
  }

  span {
    color: ${props => props.theme.colors.primary3};
    font-size: 14px;
    line-height: 21px;
  }
`;

const QuantityInputGroup = styled(InputGroup)`
  margin: 0 auto 5px auto;

  display: inline-block;
  width: 95%;
  // text-transform: uppercase;

  color: #000000;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;

  p {
    margin: 0px;
  }
`;

const LoginButton = styled(Button)`
  border: 2px solid #ef8632;
  color: #ef8632;
  background: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;

  width: 340px;

  ${mobile`
    width: 280px;
  `};
`;

const LoginBlock = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const QuantityInput = styled(CurrencyInput)`
  width: 100%;
  // height: 56px;
  justify-content: space-between;
  // text-transform: uppercase;

  input {
    height: 56px;
  }

  div {
    line-height: 56px;
  }
`;

const ProceedsGroup = styled.div`
  margin: 5px auto;

  width: 95%;
  // display: inline-block;
  // justify-content: space-between;
  // text-transform: uppercase;

  //span:nth-of-type(2n){
  //  float:right;
  //}

  span {
    color: ${props => props.theme.colors.primary6};
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
`;

const FormLine = styled.div`
  flex: 0 1 100%;
  // margin-top: 20px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  margin-bottom: 5px;

  label {
    color: ${props => props.theme.colors.primary6};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 5px;
  }
`;

const FormLineCentered = styled(FormLine)`
  text-align: center;
  // align-content: center;
  // justify-content: center;
`;


const TwoFAGroup = styled.div`
  margin: 5px 0;

  width: 100%;

  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: space-between;
  // text-transform: uppercase;

  span {
    color: ${props => props.theme.colors.primary6};
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
`;

const SubmitButton = styled(Button)`
  // margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;

  position: relative;
  bottom: -24px;
`;

const SubmitButtonWide = styled(Button)`
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
`;

const SubmitTwoFa = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

const PriceInputGroup = styled(InputGroup)`
  margin: 5px auto;

  // display: inline-block;
  width: 95%;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: space-around;

  label {
    color: ${props => props.theme.colors.primary6};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

const PriceInput = styled(Input)`
  // padding: 10px;
  border-radius: 3px;
  width: 140px;
  border: 1px solid #ddd;
  text-align: center;
  color: ${props => props.triggerPriceColor || 'black'};
`;

const OrderTypeSwitcher = styled.span`
  margin: auto;
  padding: 0px;
  position: relative;
`;

const ImmediateOrCancelSwitcher = styled.span`
  margin: auto;
  padding: 0px;
  position: relative;
`;

/*
const CodeInput = styled(Input)`
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ddd;
  width: 120px;
  // font-size: 16px;
  // line-height: 18px;
  letter-spacing: 5px;
  // height: 50px;

  ::placeholder {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
  }
`;
*/

const StopType = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  padding: 5px 10px 5px 10px;

  border: 2px solid #ef8632;
  border-radius: 7px;
  color: #ef8632;
  background: #fff;
  justify-content: center;
`;

const VerifyAccountButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;

  width: 330px;

  ${mobile`
    width: 270px;
  `};
`;

const VerifyAccountBlock = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const RegionLockedContainer = styled.div`
  margin: 10px auto;
  text-align: center;

  width: 350px;

  color: #ef8632;
  background-color: #FAFAFA;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #CFCFCF;
  border-radius: 5px;
`;

const VerifyAccount = withRouter(({ history }) => (
    <VerifyAccountBlock>
        <VerifyAccountButton bsStyle='orange' onClick={() => { history.push('/account/profile/verification'); }}>
            <FormattedMessage
                id="trade.verification_required"
                defaultMessage="Verify Account For Trading"
            />
        </VerifyAccountButton>
    </VerifyAccountBlock>
));

const LoginGroup = withRouter(({ history, side }) => (
    <LoginBlock>
        <LoginButton bsStyle='orange' onClick={() => { history.push('/login'); }}>
            <FormattedMessage
                id="trade.signInToTrade"
                defaultMessage="Sign In To {action}"
                values={{
                    action: side,
                }}
            />
        </LoginButton>
    </LoginBlock>
));

const TABS = ['MARKET', 'LIMIT MAKER', 'LIMIT', 'STOP', 'OCO'];


export default class TradeOrderPlacementForm extends React.Component {

    static propTypes = {
        takerFee: PropTypes.number,
        side: PropTypes.string,

        tradePair: PropTypes.object,
        userSecuredMethods: PropTypes.array,
        userSecuredActions: PropTypes.array,
        userKycTier: PropTypes.string,
        userLocked: PropTypes.bool,
        userJurisdictionLockWhitelisted: PropTypes.bool,
        userFeeExempt: PropTypes.bool,
        wallets: PropTypes.array,
        lastBuyInput: PropTypes.string,
        lastSellInput: PropTypes.string,
        buyOrders: PropTypes.array,
        sellOrders: PropTypes.array,
        buyPriceField: PropTypes.string,
        sellPriceField: PropTypes.string,
        currentPrice: PropTypes.number,
        isAuthenticated: PropTypes.bool,

        submitNewOrder: PropTypes.func,
        setLastPriceInput: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            price: '0.00',
            stopPrice: '0.00',
            trailingPercent: '0.00',
            quantity: '0.000',
            quantityBase: 0,
            selectedTab: 'MARKET',
            orderType: 'MARKET',
            timeInForce: 'IOC',
            settings: {
                marketOrderOn: false,
                limitMakerOn: false,
                immediateOrCancelOn: false,
                fillOrKillOn: false,
            },
            code: '',
        };
    }


    componentDidMount() {
        const { buyPriceField, sellOrders, sellPriceField, buyOrders, side, lastBuyInput, lastSellInput, currentPrice } = this.props;
        const { selectedTab, price } = this.state;

        const bestPriceCalcSide = (selectedTab === 'LIMIT MAKER') ? ((side === 'BUY') ? 'SELL' : 'BUY') : side;
        const bestPrice = (bestPriceCalcSide === 'BUY') ?
            (buyPriceField && lastBuyInput === 'CLICK') ? buyPriceField : (sellOrders && sellOrders.length > 0) ? sellOrders[0].price : '0' :
            (sellPriceField && lastSellInput === 'CLICK') ? sellPriceField : (buyOrders && buyOrders.length > 0) ? buyOrders[0].price : '0';

        const decimals = calculateScaleUsingPrice(currentPrice);
        const priceVal = Number(((side === 'BUY' && lastBuyInput !== 'FORM') || (side === 'SELL' && lastSellInput !== 'FORM')) ? (bestPrice && bestPrice > 0) ? bestPrice : '0' : price).toFixed(decimals);

        if (Number(priceVal) !== Number(price)) {
            this.setState({
                price: priceVal,
            });
        }

        const orderFormSelectedTab = localStorage.getItem('orderFormSelectedTab');
        if (orderFormSelectedTab) {
            this.changeSelectedTab(orderFormSelectedTab);
        }
    }

    componentDidUpdate() {
        const { buyPriceField, sellOrders, sellPriceField, buyOrders, side, lastBuyInput, lastSellInput, currentPrice } = this.props;
        const { selectedTab, price } = this.state;

        const bestPriceCalcSide = (selectedTab === 'LIMIT MAKER') ? ((side === 'BUY') ? 'SELL' : 'BUY') : side;
        const bestPrice = (bestPriceCalcSide === 'BUY') ?
            (buyPriceField && lastBuyInput === 'CLICK') ? buyPriceField : (sellOrders && sellOrders.length > 0) ? sellOrders[0].price : '0' :
            (sellPriceField && lastSellInput === 'CLICK') ? sellPriceField : (buyOrders && buyOrders.length > 0) ? buyOrders[0].price : '0';

        const decimals = calculateScaleUsingPrice(currentPrice);
        const priceVal = Number(((side === 'BUY' && lastBuyInput !== 'FORM') || (side === 'SELL' && lastSellInput !== 'FORM')) ? (bestPrice && bestPrice > 0) ? bestPrice : '0' : price).toFixed(decimals);

        if (Number(priceVal) !== Number(price)) {
            this.setState({
                price: priceVal,
            });
        }
    }

    changeSelectedTab = (tab) => {
        const { selectedTab } = this.state;
        if (tab !== selectedTab) {
            this.setDefaultOrderType(tab);

            this.setState({
                selectedTab: tab,
            });
            localStorage.setItem('orderFormSelectedTab', tab);
        }
    };

    setDefaultOrderType = (tab) => {
        const { side, currentPrice } = this.props;
        const { stopPrice, trailingPercent } = this.state;

        let orderType;
        let timeInForce;
        let marketOrder = false;

        if (tab === 'LIMIT') {
            orderType = 'LIMIT';
            timeInForce = 'GTC';
        } else if (tab === 'LIMIT MAKER') {
            orderType = 'LIMIT_MAKER';
            timeInForce = 'GTC';
        } else if (tab === 'STOP') {
            marketOrder = true;
            if (Number(trailingPercent) > 0) {
                if (marketOrder) {
                    orderType = 'TRAILING_STOP';
                    timeInForce = 'IOC';
                } else {
                    orderType = 'TRAILING_STOP_LIMIT';
                    timeInForce = 'GTC';
                }
            } else {
                if (side === 'BUY') {
                    if (Number(stopPrice) >= currentPrice) {
                        if (marketOrder) {
                            orderType = 'STOP_LOSS';
                            timeInForce = 'IOC';
                        } else {
                            orderType = 'STOP_LOSS_LIMIT';
                            timeInForce = 'GTC';
                        }
                    } else {
                        if (marketOrder) {
                            orderType = 'TAKE_PROFIT';
                            timeInForce = 'IOC';
                        } else {
                            orderType = 'TAKE_PROFIT_LIMIT';
                            timeInForce = 'GTC';
                        }
                    }
                } else {
                    if (Number(stopPrice) <= currentPrice) {
                        if (marketOrder) {
                            orderType = 'STOP_LOSS';
                            timeInForce = 'IOC';
                        } else {
                            orderType = 'STOP_LOSS_LIMIT';
                            timeInForce = 'GTC';
                        }
                    } else {
                        if (marketOrder) {
                            orderType = 'TAKE_PROFIT';
                            timeInForce = 'IOC';
                        } else {
                            orderType = 'TAKE_PROFIT_LIMIT';
                            timeInForce = 'GTC';
                        }
                    }
                }
            }
        } else if (tab === 'OCO') {
            orderType = 'MARKET';
            timeInForce = 'IOC';
        } else {
            orderType = 'MARKET';
            timeInForce = 'IOC';
        }

        this.setState({
            orderType: orderType,
            timeInForce: timeInForce,
            settings: {
                marketOrderOn: marketOrder,
                immediateOrCancelOn: false,
                fillOrKillOn: false,
            },
        });
    };

    updateStopOrder = (stopPrice, trailingPercent, marketOrderOn) => {
        const { side, currentPrice } = this.props;
        const { selectedTab, settings } = this.state;

        let orderType;
        let timeInForce;

        if (selectedTab === 'STOP') {
            if (Number(trailingPercent) > 0) {
                if (marketOrderOn) {
                    orderType = 'TRAILING_STOP';
                    timeInForce = 'IOC';
                } else {
                    orderType = 'TRAILING_STOP_LIMIT';
                    timeInForce = settings.immediateOrCancelOn ? 'IOC' : 'GTC';
                }
            } else {
                if (side === 'BUY') {
                    if (Number(stopPrice) >= currentPrice) {
                        if (marketOrderOn) {
                            orderType = 'STOP_LOSS';
                            timeInForce = 'IOC';
                        } else {
                            orderType = 'STOP_LOSS_LIMIT';
                            timeInForce = settings.immediateOrCancelOn ? 'IOC' : 'GTC';
                        }
                    } else {
                        if (marketOrderOn) {
                            orderType = 'TAKE_PROFIT';
                            timeInForce = 'IOC';
                        } else {
                            orderType = 'TAKE_PROFIT_LIMIT';
                            timeInForce = settings.immediateOrCancelOn ? 'IOC' : 'GTC';
                        }
                    }
                } else {
                    if (Number(stopPrice) <= currentPrice) {
                        if (marketOrderOn) {
                            orderType = 'STOP_LOSS';
                            timeInForce = 'IOC';
                        } else {
                            orderType = 'STOP_LOSS_LIMIT';
                            timeInForce = settings.immediateOrCancelOn ? 'IOC' : 'GTC';
                        }
                    } else {
                        if (marketOrderOn) {
                            orderType = 'TAKE_PROFIT';
                            timeInForce = 'IOC';
                        } else {
                            orderType = 'TAKE_PROFIT_LIMIT';
                            timeInForce = settings.immediateOrCancelOn ? 'IOC' : 'GTC';
                        }
                    }
                }
            }

            this.setState({
                orderType: orderType,
                timeInForce: timeInForce,
            });
        }
    }

    getError = field => {
        const error = this.state.errors.find(e => e.field === field);
        return error ? error.message : null;
    };

    setQuantityField = (quantity) => {
        this.setState({
            quantity: quantity
        });
    };

    validateInput = () => {
        const { userSecuredMethods, userSecuredActions } = this.props;
        const requiredFields = [
            'price',
            'quantity',
            'orderType',
            'timeInForce',
            !userSecuredMethods.includes('NONE') && userSecuredActions.includes('TRADE') ? 'code' : undefined,
        ];

        const validations = [
            {
                field: 'quantity',
                validate: quantity => {
                    return quantity && quantity.match(new RegExp(/^\d*(\.\d+)?$/));
                },
                message: (
                    <FormattedMessage
                        id="error.invalid_quantity"
                        defaultMessage="Quantity: Numbers and decimal only, no commas"
                    />
                )
            },
            {
                field: 'quantity',
                validate: quantity => {
                    return Number(quantity) > 0;
                },
                message: (
                    <FormattedMessage
                        id="error.insufficient_quantity"
                        defaultMessage="Quantity: must be greater than zero"
                    />
                )
            },
        ];

        const missedFields = [];
        const invalidFields = [];

        requiredFields.filter(f => !!f).map(field => {
            if(!this.state[field]) {
                missedFields.push({
                    field,
                    message: VALIDATION_MESSAGES[field]
                });
            }
        });

        validations.map(({ field, validate, message }) => {
            if(!validate(this.state[field])) {
                invalidFields.push({
                    field,
                    message
                });
            }
        });

        return invalidFields.concat(missedFields);
    };

    onInputClick = event => {
        if (Number(event.target.value) === 0) {
            this.setState({
                [event.target.name]: '',
            });
        }
    };

    handleChange = event => {
        const { side, lastBuyInput, lastSellInput, currentPrice } = this.props;
        const { settings } = this.state;

        if (event.target.name === 'price' && ((side === 'BUY' && lastBuyInput !== 'FORM') || (side === 'SELL' && lastSellInput !== 'FORM')))  {
            this.props.setLastPriceInput(side, 'FORM');
        }

        if (event.target.name === 'price' || event.target.name === 'stopPrice') {
            const decimals = calculateScaleUsingPrice(currentPrice);
            // console.log(decimals);
            // console.log(currentPrice);
            if (validatePriceInput(event.target.value, decimals)) {
                // console.log('REGEX PASS');
                this.setState({
                    [event.target.name]: event.target.value,
                    trailingPercent: '0.00',
                });
                if (event.target.name === 'stopPrice') {
                    this.updateStopOrder(event.target.value, '0', settings.marketOrderOn);
                }
            }
        } else if (event.target.name === 'quantity') {
            const decimals = 8;  // TODO: take into account minimum order lot size?

            if (validatePriceInput(event.target.value, decimals)) {
                // console.log('REGEX PASS');
                this.setState({
                    [event.target.name]: event.target.value,
                });
            }
        } else if (event.target.name === 'trailingPercent') {
            if (validatePercentInput(event.target.value, 2)) {
                const { stopPrice } = this.state;
                this.setState({
                    [event.target.name]: event.target.value
                });
                this.updateStopOrder(stopPrice, event.target.value, settings.marketOrderOn);
            }
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    };

    onInputBlur = () => {
        const { quantity, price, stopPrice, trailingPercent } = this.state;

        if (price === '') {
            this.setState({
                price: '0.00',
            });
        }

        if (stopPrice === '') {
            this.setState({
                stopPrice: '0.00',
            });
        }

        if (trailingPercent === '') {
            this.setState({
                trailingPercent: '0.00',
            });
        }

        if (quantity === '') {
            this.setState({
                quantity: '0.000',
            });
        }
    };

    onSubmit = () => {
        const { side, tradePair } = this.props;
        const { quantity, price, orderType, timeInForce, stopPrice, trailingPercent, code } = this.state;

        const errors = this.validateInput();

        if (errors.some(e => !!e)) {
            this.setState({
                errors
            });
        } else {
            this.setState({
                errors: []
            });

            this.props.setLastPriceInput(side, undefined);
            this.props.submitNewOrder({
                pair: tradePair.pair,
                quantity,
                price,
                side,
                orderType,
                timeInForce: (orderType === 'MARKET') ? 'IOC' : timeInForce,
                stopPrice,
                trailingPercent,
                code,
            });

            this.setState({
                code: ''
            });
        }
    };

    getTopBidPrice = () => {
        const { buyOrders, currentPrice } = this.props;
        const decimals = calculateScaleUsingPrice(currentPrice);
        return (buyOrders && buyOrders.length > 0) ? displayFormat(buyOrders[0].price, decimals) : '0';
    }

    getTopAskPrice = () => {
        const { sellOrders, currentPrice } = this.props;
        const decimals = calculateScaleUsingPrice(currentPrice);

        return (sellOrders && sellOrders.length > 0) ? displayFormat(sellOrders[0].price, decimals) : '0';
    }

    getMarketOrder = () => {
        const { settings } = this.state;
        if (settings.marketOrderOn !== undefined) {
            return settings.marketOrderOn;
        } else {
            return false;
        }
    };

    changeMarketOrder = () => {
        const { settings, stopPrice, trailingPercent } = this.state;
        this.updateStopOrder(stopPrice, trailingPercent, !settings.marketOrderOn);
        this.setState({
            settings: {
                ...settings,
                marketOrderOn: !settings.marketOrderOn,
                immediateOrCancelOn: false,
            }
        });
    };

    getImmediateOrCancel = () => {
        const { settings } = this.state;
        if (settings.immediateOrCancelOn !== undefined) {
            return settings.immediateOrCancelOn;
        } else {
            return false;
        }
    };

    changeImmediateOrCancel = () => {
        const { settings } = this.state;
        this.setState({
            timeInForce: (!settings.immediateOrCancelOn) ? 'IOC' : 'GTC',
            settings: {
                ...settings,
                marketOrderOn: false,
                fillOrKillOn: (settings.immediateOrCancelOn) ? false : settings.fillOrKillOn,
                immediateOrCancelOn: !settings.immediateOrCancelOn,
            }
        });
    };

    getFillOrKill = () => {
        const { settings } = this.state;
        if (settings.fillOrKillOn !== undefined) {
            return settings.fillOrKillOn;
        } else {
            return false;
        }
    };

    changeFillOrKill = () => {
        const { settings } = this.state;
        this.setState({
            timeInForce: (!settings.fillOrKillOn) ? 'FOK' : 'GTC',
            settings: {
                ...settings,
                immediateOrCancelOn: !settings.fillOrKillOn,
                fillOrKillOn: !settings.fillOrKillOn,
            }
        });
    };


    render() {
        const { userSecuredMethods, userSecuredActions, userKycTier, userJurisdictionLockWhitelisted, userLocked, userFeeExempt } = this.props;
        const { buyOrders, sellOrders, tradePair, wallets, side, takerFee, isAuthenticated } = this.props;
        const { quantity, price, stopPrice, trailingPercent, orderType, code, timeInForce, selectedTab, settings } = this.state;

        //
        // TODO: Move all this stuff to something called from componentDidUpdate
        //

        const bestPrice = (side === 'BUY') ?
            ((sellOrders && sellOrders.length > 0) ? sellOrders[0].price : '0') :
            ((buyOrders && buyOrders.length > 0) ? buyOrders[0].price : '0');

        // const { buyPriceField, sellPriceField, lastBuyInput, lastSellInput } = this.props;
        // const bestPrice = (side == 'BUY') ?
        //        (buyPriceField && lastBuyInput == 'CLICK') ? buyPriceField : (sellOrders && sellOrders.length > 0) ? sellOrders[0].price : '0' :
        //        (sellPriceField && lastSellInput == 'CLICK') ? sellPriceField : (buyOrders && buyOrders.length > 0) ? buyOrders[0].price : '0';

        const targetWallet = wallets ? wallets.find(wallet => (side === 'BUY' && wallet.coin === tradePair.base) || (side === 'SELL' && wallet.coin === tradePair.quote)) || undefined : undefined;
        const sourceWallet = wallets ? wallets.find(wallet => (side === 'BUY' && wallet.coin === tradePair.quote) || (side === 'SELL' && wallet.coin === tradePair.base)) || undefined : undefined;

        // const priceVal = ((side == 'BUY' && lastBuyInput != 'FORM') || (side == 'SELL' && lastSellInput != 'FORM')) ? (bestPrice && bestPrice > 0) ? (+bestPrice) : '0' : price;

        //const priceWarning = (priceVal > 0 && Math.abs(((priceVal-bestPrice)/priceVal)*100) > 20) ?
        //    'Price ' + ((Math.abs(((priceVal-bestPrice)/priceVal)*100) > 50) ? 'very ' : '') + ((((priceVal-bestPrice)/priceVal)*100) > 0 ? 'far above' : 'far below') + ' spot' : undefined;

        let priceWarning = (selectedTab === 'LIMIT MAKER' &&
            ((side === 'BUY' && Number(price) >= Number(bestPrice)) || (side === 'SELL' && Number(price) <= Number(bestPrice))))
            ? 'Order would execute as Taker' : undefined;
        priceWarning = (price > 0 && Math.abs(((price-bestPrice)/price)*100) > 20) ?
            'Price ' + ((Math.abs(((price-bestPrice)/price)*100) > 50) ? 'very ' : '') + ((((price-bestPrice)/price)*100) > 0 ? 'far above' : 'far below') + ' spot' : priceWarning;

        const submitDisabled = !quantity ||
            !(Number(quantity) > 0) ||
            !price ||
            !(orderType === 'MARKET' || settings.marketOrderOn || Number(price) > 0) ||
            (selectedTab === 'LIMIT MAKER' && ((side === 'BUY' && Number(price) >= Number(this.getTopAskPrice())) || ((side === 'SELL' && Number(price) <= Number(this.getTopBidPrice()))))) ||
            !(selectedTab !== 'STOP' || (selectedTab === 'STOP' && (Number(stopPrice) > 0 || Number(trailingPercent) > 0))) ||
            (priceWarning && priceWarning.includes('very')) ||
            (!userSecuredMethods.includes('NONE') && userSecuredActions.includes('TRADE') && (!code || code.length < 6));

        const formDisabled = !sourceWallet ||
            !sourceWallet.avail ||
            sourceWallet.avail <= 0 ||
            userKycTier === 'NONE';

        const stopPriceDisplay = (Number(trailingPercent) > 0) ? (side === 'BUY') ? this.getTopAskPrice() : this.getTopBidPrice() : stopPrice;

        // console.log(tradePair);
        // console.log(bestPrice);
        // console.log(price);
        // console.log(settings);

        // console.log('Stop Price: ' + stopPrice);
        // console.log('Trailing Percent: ' + trailingPercent);
        // console.log('Order Type: ' + orderType);
        // console.log('Time in Force: ' + timeInForce);
        // console.log('userJurisdictionWhitelisted', userJurisdictionLockWhitelisted);
        // console.log('userKycTier', userKycTier);

        return (!isAuthenticated || (targetWallet)) ? (
            <React.Fragment>
                {isAuthenticated && (userKycTier === 'NONE' || (!userJurisdictionLockWhitelisted && (!targetWallet || !targetWallet.rat))) ? (
                    (userKycTier === 'NONE') ? (
                        <VerifyAccount/>
                    ) : (
                        <RegionLockedContainer>
                            <FormattedMessage
                                id="portfoilo.regionTradeNotAllowed"
                                defaultMessage="Trading into {asset} is not yet enabled in your region"
                                values={{
                                    asset: (targetWallet) ? targetWallet.coin : '',
                                }}
                            />
                        </RegionLockedContainer>
                    )
                ) : (
                    <React.Fragment>
                        <Container>
                            <QuantityInputGroup>
                                <FormHeader floatSide={side === 'BUY' ? 'left' : 'left'}>
                                    <FormattedMessage
                                        id="trade.quantity_message"
                                        defaultMessage="{side} Order"
                                        tagName="p"
                                        values={{
                                            side: _.startCase(_.lowerCase(side)),
                                        }}
                                    />
                                </FormHeader>
                            </QuantityInputGroup>
                        </Container>
                        <Container>
                            <Tabs>
                                {TABS.map((value, index) => (
                                    <a
                                        key={index}
                                        onClick={() => {this.changeSelectedTab(value);}}
                                        className={((value === selectedTab) ? 'selected' : '')}
                                        title={value}
                                    >
                                        {(value === 'OCO') ? value : _.startCase(_.lowerCase(value))}
                                    </a>
                                ))}
                            </Tabs>
                        </Container>
                        <Container>
                            <React.Fragment>
                                {( userLocked || selectedTab === 'OCO') ? (
                                    <FormLineCentered>
                                        {(userLocked) ? (
                                            <FormattedMessage
                                                id="trade.userLocked"
                                                defaultMessage="This account is currently locked"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="trade.comingSoon"
                                                defaultMessage="OCO order type coming soon..."
                                            />
                                        )}
                                    </FormLineCentered>
                                ) : (
                                    <React.Fragment>
                                        {selectedTab === 'STOP' && (
                                            <React.Fragment>
                                                <PriceInputGroup flexDirection={side === 'BUY' ? 'row' : 'row'}>
                                                    <FormLineCentered>
                                                        {(side === 'BUY') ? (
                                                            <FormattedMessage
                                                                id="trade.triggerPrice"
                                                                defaultMessage="TRIGGER PRICE"
                                                                tagName="label"
                                                            />
                                                        ) : (
                                                            <FormattedMessage
                                                                id="trade.stopPrice"
                                                                defaultMessage="STOP PRICE"
                                                                tagName="label"
                                                            />
                                                        )}
                                                        <PriceInput
                                                            triggerPriceColor={(Number(trailingPercent) > 0) ? '#C0C0C0' : '#000000'}
                                                            type="text"
                                                            name="stopPrice"
                                                            value={stopPriceDisplay}
                                                            onChange={this.handleChange}
                                                            onClick={this.onInputClick}
                                                            onBlur={() => this.onInputBlur()}
                                                            error={this.getError('stopPrice')}
                                                            disabled={formDisabled}
                                                        />
                                                    </FormLineCentered>
                                                    <FormLineCentered>
                                                        <i>or</i>
                                                    </FormLineCentered>
                                                    <FormLineCentered>
                                                        <FormattedMessage
                                                            id="trade.trailingPercent"
                                                            defaultMessage="TRAILING %"
                                                            tagName="label"
                                                        />
                                                        <PriceInput
                                                            triggerPriceColor={(Number(trailingPercent) > 0) ? '#000000' : '#C0C0C0'}
                                                            type="text"
                                                            name="trailingPercent"
                                                            value={trailingPercent}
                                                            onChange={this.handleChange}
                                                            onClick={this.onInputClick}
                                                            onBlur={() => this.onInputBlur()}
                                                            error={this.getError('trailingPercent')}
                                                            disabled={formDisabled}
                                                        />
                                                    </FormLineCentered>
                                                </PriceInputGroup>
                                                {!formDisabled && (
                                                    <StopType>
                                                        {(side === 'BUY') ? (
                                                            <FormattedMessage
                                                                id="trade.stopOrderType"
                                                                defaultMessage="Stop Type: {stopType}"
                                                                values={{
                                                                    stopType: (orderType.includes('TRAILING') ? 'TRAILING TRIGGER' : 'TRIGGER ENTRY') + ' - ' + (orderType.includes('LIMIT') ? 'LIMIT' : 'MARKET'),
                                                                }}
                                                            />
                                                        ) : (
                                                            <FormattedMessage
                                                                id="trade.stopOrderType"
                                                                defaultMessage="Stop Type: {stopType}"
                                                                values={{
                                                                    stopType: orderType.includes('LIMIT') ? orderType.replaceAll('_', ' ').replaceAll('LIMIT', ' - LIMIT') : orderType.replaceAll('_', ' ') + ' - MARKET',
                                                                }}
                                                            />
                                                        )}
                                                    </StopType>
                                                )}
                                            </React.Fragment>
                                        )}

                                        <QuantityInputGroup>
                                            <BalanceItem floatSide={side === 'BUY' ? 'left' : 'left'}>
                                                <FormattedMessage
                                                    id="trade.available"
                                                    defaultMessage="Available"
                                                    tagName="label"
                                                />
                                                {(isAuthenticated && sourceWallet) ? (
                                                    (side === 'BUY') ? (
                                                        <p onClick={() => {this.setQuantityField(displayFormat(sourceWallet.avail/price, 8));}}>
                                                            {sourceWallet.avail.toFixed(8)} {sourceWallet.coin}
                                                        </p>
                                                    ) : (
                                                        <p onClick={() => {this.setQuantityField(sourceWallet.avail.toString());}}>
                                                            {sourceWallet.avail.toFixed(8)} {sourceWallet.coin}
                                                        </p>
                                                    )
                                                ) : (
                                                    <FormattedMessage
                                                        id="trade.signInToView"
                                                        defaultMessage="Sign In To View"
                                                        tagName="p"
                                                    />
                                                )}
                                            </BalanceItem>
                                            <MaxButton floatSide={side === 'BUY' ? 'right' : 'right'}>
                                                {(isAuthenticated && sourceWallet) && (
                                                    (side === 'BUY') ? (
                                                        <p onClick={() => {this.setQuantityField(displayFormat(sourceWallet.avail/price, 8));}}>
                                                            <FormattedMessage
                                                                id="trade.max"
                                                                defaultMessage="Max"
                                                            />
                                                        </p>
                                                    ) : (
                                                        <p onClick={() => {this.setQuantityField(sourceWallet.avail.toString());}}>
                                                            <FormattedMessage
                                                                id="trade.max"
                                                                defaultMessage="Max"
                                                            />
                                                        </p>
                                                    )
                                                )}
                                            </MaxButton>
                                            <QuantityInput
                                                iconAlign={side === 'BUY' ? 'right' : 'right'}
                                                textOverride='QTY'
                                                name="quantity"
                                                value={quantity}
                                                currency={tradePair.base}
                                                onChange={this.handleChange}
                                                onClick={this.onInputClick}
                                                onBlur={() => this.onInputBlur()}
                                                disabled={formDisabled}
                                                error={this.getError('quantity')}
                                            />
                                        </QuantityInputGroup>

                                        {orderType !== 'MARKET' && (
                                            <React.Fragment>
                                                {(!(selectedTab === 'STOP' && this.getMarketOrder())) && (
                                                    <PriceInputGroup flexDirection={side === 'BUY' ? 'row' : 'row'}>
                                                        <FormLineCentered>
                                                            <QuantityInput
                                                                iconAlign={side === 'BUY' ? 'right' : 'right'}
                                                                textOverride='PRICE'
                                                                name="price"
                                                                value={(settings.marketOrderOn) ? 'BEST POSSIBLE' : price}
                                                                currency={tradePair.quote}
                                                                onChange={this.handleChange}
                                                                onClick={this.onInputClick}
                                                                onBlur={() => this.onInputBlur()}
                                                                error={this.getError('price') || priceWarning}
                                                                disabled={(formDisabled || (settings.marketOrderOn)) ? true : false}
                                                            />
                                                        </FormLineCentered>
                                                    </PriceInputGroup>
                                                )}

                                                <PriceInputGroup flexDirection={side === 'BUY' ? 'row' : 'row'}>
                                                    {selectedTab === 'STOP' ? (
                                                        <FormLineCentered>
                                                            <FormattedMessage
                                                                id="trade.limitOrder"
                                                                defaultMessage="Set Limit"
                                                                tagName="label"
                                                            />
                                                            <OrderTypeSwitcher>
                                                                <Switcher
                                                                    disabled={formDisabled}
                                                                    checked={!this.getMarketOrder()}
                                                                    onChange={this.changeMarketOrder}
                                                                />
                                                            </OrderTypeSwitcher>
                                                        </FormLineCentered>
                                                    ) : (orderType !== 'LIMIT_MAKER') && (
                                                        <FormLineCentered>
                                                            <span>
                                                                <FormattedMessage
                                                                    id="trade.immediateOrCancel"
                                                                    defaultMessage="Instant or Cancel"
                                                                    tagName="label"
                                                                />
                                                                <BlocPalToolTip tooltipId='trade.IoC_tooltip'>
                                                                    <FormattedMessage
                                                                        id="trade.IoC_tooltip"
                                                                        defaultMessage="[I]mmediate or [C]ancel - When turned on your order will be immediately cancelled if it cannot be executed"
                                                                    />
                                                                </BlocPalToolTip>
                                                            </span>
                                                            <ImmediateOrCancelSwitcher>
                                                                <Switcher
                                                                    disabled={formDisabled}
                                                                    checked={this.getImmediateOrCancel()}
                                                                    onChange={this.changeImmediateOrCancel}
                                                                />
                                                            </ImmediateOrCancelSwitcher>
                                                        </FormLineCentered>
                                                    )}
                                                    {(orderType !== 'LIMIT_MAKER') && (
                                                        <FormLineCentered>
                                                            <span>
                                                                <FormattedMessage
                                                                    id="trade.fillOrKill"
                                                                    defaultMessage="Fill or Kill"
                                                                    tagName="label"
                                                                />
                                                                <BlocPalToolTip tooltipId='trade.FoK_tooltip'>
                                                                    <FormattedMessage
                                                                        id="trade.FoK_tooltip"
                                                                        defaultMessage="[F]ill or [K]ill - When turned on your order will be immediately cancelled if it cannot be executed IN FULL"
                                                                    />
                                                                </BlocPalToolTip>
                                                            </span>
                                                            <OrderTypeSwitcher>
                                                                <Switcher
                                                                    disabled={formDisabled}
                                                                    checked={this.getFillOrKill()}
                                                                    onChange={this.changeFillOrKill}
                                                                />
                                                            </OrderTypeSwitcher>
                                                        </FormLineCentered>
                                                    )}
                                                </PriceInputGroup>
                                            </React.Fragment>
                                        )}

                                        {!formDisabled && (
                                            <ProceedsGroup>
                                                <BalanceItem floatSide={side === 'BUY' ? 'left' : 'left'}>
                                                    <FormattedMessage
                                                        id="trade.proceeds"
                                                        defaultMessage="{coin} {action}"
                                                        tagName="label"
                                                        values={{
                                                            action: (side === 'BUY') ? 'Cost' : 'Proceeds',
                                                            coin: tradePair.quote,
                                                        }}
                                                    />
                                                    <p>
                                                        {displayFormat((price*quantity), 8)} {tradePair.quote}
                                                    </p>
                                                </BalanceItem>
                                                <BalanceItem floatSide={side === 'BUY' ? 'right' : 'right'}>
                                                    <FormattedMessage
                                                        id="trade.fee"
                                                        tagName="label"
                                                        defaultMessage="{coin} Fee"
                                                        values={{
                                                            coin: (side === 'BUY') ? tradePair.base : tradePair.quote,
                                                        }}
                                                    />
                                                    {userFeeExempt === true ? (
                                                        <FormattedMessage
                                                            id="trade.feeExempt"
                                                            tagName="p"
                                                            defaultMessage="FEE EXEMPT"
                                                        />
                                                    ) : (
                                                        <React.Fragment>
                                                            {timeInForce === 'GTC' && (
                                                                <FormattedMessage
                                                                    id="trade.makerFee"
                                                                    tagName="p"
                                                                    defaultMessage="MAKER: No Fee"
                                                                />
                                                            )}
                                                            {(orderType !== 'LIMIT_MAKER') && (
                                                                <FormattedMessage
                                                                    id="trade.takerFee"
                                                                    tagName="p"
                                                                    defaultMessage="TAKER: {feeAmount}"
                                                                    values={{
                                                                        feeAmount: displayFormat((side === 'BUY') ? (quantity * takerFee) : (price * quantity * takerFee), 8),
                                                                    }}
                                                                />
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </BalanceItem>
                                            </ProceedsGroup>
                                        )}


                                        {(!isAuthenticated) ? (
                                            <LoginGroup side={side}/>
                                        ) : (
                                            (userKycTier === 'NONE') ? (
                                                <VerifyAccount/>
                                            ) : (
                                                (userSecuredActions.includes('TRADE')) ? (
                                                    <TwoFAGroup flexDirection={side === 'BUY' ? 'row' : 'row'}>
                                                        <FormLineCentered>
                                                            <SubmitTwoFa>
                                                                <InputTwoFa
                                                                    name="code"
                                                                    value={code}
                                                                    onChange={this.handleChange}
                                                                    disabled={formDisabled}
                                                                    error={this.getError('code')}
                                                                    component='TRADE'
                                                                />
                                                            </SubmitTwoFa>
                                                        </FormLineCentered>
                                                        <FormLineCentered>
                                                            <SubmitButton
                                                                disabled={submitDisabled || formDisabled}
                                                                onClick={this.onSubmit}
                                                                bsStyle={(side === 'BUY') ? 'blue' : 'blue'}
                                                            >
                                                                {side}
                                                            </SubmitButton>
                                                        </FormLineCentered>
                                                    </TwoFAGroup>
                                                ) : (
                                                    <TwoFAGroup flexDirection={side === 'BUY' ? 'row' : 'row'}>
                                                        <FormLineCentered>
                                                            <SubmitButtonWide
                                                                disabled={submitDisabled || formDisabled}
                                                                onClick={this.onSubmit}
                                                                bsStyle={(side === 'BUY') ? 'blue' : 'blue'}
                                                            >
                                                                {side}
                                                            </SubmitButtonWide>
                                                        </FormLineCentered>
                                                    </TwoFAGroup>
                                                )
                                            )
                                        )}

                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </Container>
                    </React.Fragment>
                )}
            </React.Fragment>
        ) : null;
    }
}
