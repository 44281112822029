import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ucwords } from '../../../../utils';
import {Title, Label, Value, BoldOrangeValue} from '../../../../commonStyles/AccountProfileStyles';
import {Component as Input} from '../../../../components/Input';
import {Component as Button} from '../../../../components/Button';
import {AuthInputGroup} from '../../../../commonStyles/AuthStyles';

const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    // justify-content: center;
    align-items: center;
    width: 100%;
`;

const ReferralContainer = styled(FlexColumn)`
    align-items: flex-start;    
`;

const ContinueButton = styled(Button).attrs({
    bsStyle: 'orange'
})`
    text-align: center;
    display: block;
    width: 200px;
    margin-bottom: 30px;
    text-transform: capitalize;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
`;

const ItemIncomplete = styled.div`
    outline: 1px dashed #021747;
    outline-offset: 2px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    background-color: #EF8632;
    border-radius: 5px;
    padding: 15px;
    width: 90%;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
`;

const ItemText = styled.div`
    position: relative;
    margin: auto;
    text-align: center;
    font-size: 14px;
`;

const ItemTextTitle = styled.div`
    text-align: center;
    color: #FFFFFF;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
`;

const ItemTextNormal = styled.div`
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #262626;
`;

const InputGroupView = styled(AuthInputGroup)`
  width: 300px;
  margin-bottom: 15px;
`;

export default class ProfileAccountSummary extends React.Component {

    static propTypes = {
        submitReferredBy: PropTypes.func,
        user: PropTypes.object,
        intl: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            referredBy: null,
        };
    }

    renderInfo(infoElement, valueElement) {
        return (
            <React.Fragment>
                <Label>
                    {infoElement}
                </Label>
                <Value>
                    {valueElement}
                </Value>
            </React.Fragment>
        );
    }

    onSubmitReferredBy = () => {
        const { referredBy } = this.state;

        this.props.submitReferredBy(referredBy);
    }

    render() {

        const { user, intl } = this.props;
        const { referredBy } = this.state;

        let shareUrl = 'https://x.blocpal.com';
        if (user.referralCode) {
            shareUrl = shareUrl + '/?ref=' + user.referralCode;
        }

        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="profile.title_account_summary"
                        defaultMessage="Account Summary"
                    />
                </Title>
                <br />

                <Label>
                    Current Account Tier
                    {user.kycTier === 'NONE' && (
                        <Value>
                            None<br />
                        </Value>
                    )}
                    {user.kycTier === 'TIER1' && (
                        <BoldOrangeValue>
                            Tier 1<br />
                        </BoldOrangeValue>
                    )}
                    {user.kycTier === 'TIER2' && (
                        <BoldOrangeValue>
                            Tier 2<br />
                        </BoldOrangeValue>
                    )}
                    {user.kycTier === 'TIER3' && (
                        <BoldOrangeValue>
                            Tier 3<br />
                        </BoldOrangeValue>
                    )}
                    {user.kycTier === 'CORPORATE' && (
                        <BoldOrangeValue>
                            Corporate<br />
                        </BoldOrangeValue>
                    )}
                </Label>

                {this.renderInfo(
                    <FormattedMessage
                        id="profile.summary.fullNameTitle"
                        defaultMessage="Full Name"
                    />, (user.firstName && user.firstName !== '') ? user.firstName + ' ' + user.lastName : user.nickName
                )}
                {this.renderInfo(
                    <FormattedMessage
                        id="profile.summary.emailTitle"
                        defaultMessage="Email"
                    />, user.email
                )}
                {this.renderInfo(
                    <FormattedMessage
                        id="profile.summary.publicCodeTitle"
                        defaultMessage="Your Public Code (Used In Our Proof Of Reserves System)"
                    />, user.publicCode
                )}
                {this.renderInfo(
                    <FormattedMessage
                        id="profile.summary.referralCodeTitle"
                        defaultMessage="Your Referral Code (To Invite People)"
                    />, user.referralCode
                )}

                {this.renderInfo(
                    <FormattedMessage
                        id="profile.summary.referralUrlTitle"
                        defaultMessage="Your Referral URL (To Invite People)"
                    />, shareUrl
                )}

                {this.renderInfo(
                    <FormattedMessage
                        id="profile.2fa.2fa_types"
                        defaultMessage="2FA Types Enabled"
                    />, user.securedMethods.map((method, index) => (
                        <span key={index}>{ucwords(method.replace('GOOGLE', 'Google Authenticator'))}</span>
                    ))
                )}
                {this.renderInfo(
                    <FormattedMessage
                        id="profile.2fa.2fa_actions"
                        defaultMessage="Actions Requiring 2FA"
                    />, user.securedActions.map((action, index) => (
                        <span key={index}>{ucwords(action.replace('_', ' ')).replace('Api', 'API')}</span>
                    ))
                )}

                {user.feeExempt === true && user.feeExemptUntil && user.feeExemptUntil !== '' && this.renderInfo(
                    <FormattedMessage
                        id="profile.summary.feeExemptUntil"
                        defaultMessage="Fee Exempt Until"
                    />, new Date(user.feeExemptUntil).toISOString().split('T')[0]
                )}

                {user.feeExempt === true && !(user.feeExemptUntil && user.feeExemptUntil !== '') && this.renderInfo(
                    <FormattedMessage
                        id="profile.summary.feeExempt"
                        defaultMessage="Fee Exempt"
                    />, 'Yes'
                )}

                {user.referredBy && user.referredBy !== '' && this.renderInfo(
                    <FormattedMessage
                        id="profile.summary.referredByTitle"
                        defaultMessage="Who Referred You"
                    />, user.referredBy
                )}

                {0 === 1 && (!user.referredBy || user.referredBy === '') && (
                    <React.Fragment>
                        <ItemIncomplete>
                            <ItemText>
                                <ItemTextTitle>
                                    <FormattedMessage
                                        id="profile.referralCodeTitle"
                                        defaultMessage="Referral Code"
                                    />
                                </ItemTextTitle>
                                <ItemTextNormal>
                                    Please let us know who referred you by entering their Referral Code below so we can properly credit them.
                                </ItemTextNormal>
                            </ItemText>
                        </ItemIncomplete>
                        <ReferralContainer>
                            <InputGroupView>
                                <FormattedMessage
                                    id="profile.referralCodeTitle"
                                    defaultMessage="Referral Code"
                                    tagName="label"
                                />
                                <Input
                                    name="referredBy"
                                    placeholder={
                                        intl.formatMessage({
                                            id: 'profile.validation.referredByExample',
                                            defaultMessage: 'zxy321cba'
                                        })
                                    }
                                    type="text"
                                    value={(referredBy) ? referredBy : ''}
                                    onChange={this.onChange}
                                />
                            </InputGroupView>
                            <ContinueButton
                                onClick={this.onSubmitReferredBy}
                                disabled={!(referredBy && referredBy !== '')}>
                                <FormattedMessage
                                    id="profile.verification.submitReferredBy"
                                    defaultMessage="Submit Referral Code"
                                />
                            </ContinueButton>
                        </ReferralContainer>
                    </React.Fragment>
                )}
            </Container>
        );
    }
}
