import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TradeTicker from './TradeTicker';

import { startTickerFeed, stopTickerFeed } from '../../../../actions/market';
import {getTradePair, getTradePairTicker} from '../../../../selectors';

function mapStateToProps(state) {
    return {
        fiatCurrency: state.portfolio.fiatCurrency,
        usdToFiatRate: state.portfolio.usdToFiatRate,
        ticker: getTradePairTicker(state),
        tradePair: getTradePair(state),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            startTickerFeed,
            stopTickerFeed,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradeTicker);
