import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as GradientView } from '../../components/GradientView';
import { Component as Button } from '../../components/Button';
import { Component as AuthFooter } from '../../components/AuthFooter';
import { Component as MessageBold } from '../../components/MessageBold';
import { Component as Message, FontWeight } from '../../components/Message';
import {
    FormContainer,
    FormWrapper,
} from '../../commonStyles/AuthStyles';


const SigninButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 35px;
  border-radius: 10px;
  text-transform: capitalize;
`;

const Space1 = styled.div`
  width: 100%;
  height: 50px;
`;

const Space2 = styled.div`
  width: 100%;
  height: 25px;
`;

export default class SignedOutScreen extends React.Component {

    static propTypes = {
        clearAuthData: PropTypes.func,
        history: PropTypes.object
    };

    componentDidMount() {
        document.title = 'Signed Out (BlocPalX)';

        this.props.clearAuthData();
        window.gtag('event', 'page_view', {
            page_path: this.props.history.location.pathname + this.props.history.location.search + this.props.history.location.hash,
            page_search: this.props.history.location.search,
            page_hash: this.props.history.location.hash,
        });
        window.gtag('event', 'logout');
    }

    onSubmit = async event => {
        event.preventDefault();
        this.props.history.push('/login');
    };

    render() {

        return (
            <FormContainer>
                <GradientView borderRadius={'0 0 15px 15px'}>
                    <FormWrapper onSubmit={this.onSubmit}>
                        <Space1/>
                        <Message fontWeight={FontWeight.LIGHT}>
                            <FormattedMessage
                                id="auth.signout.signedOutMessage"
                                defaultMessage="Parting is such sweet sorrow..."
                            />
                        </Message>
                        <Space2/>
                        <MessageBold>
                            <FormattedMessage
                                id="auth.signout.signedOutTitle"
                                defaultMessage="Signed Out"
                            />
                        </MessageBold>
                        <Space2/>
                        <FormattedMessage
                            id="auth.signout.YouAreSignedOut"
                            defaultMessage="You've been signed out. Please come again!"
                        />
                        <Space2/>
                        <SigninButton
                            bsStyle={'orange'}
                        >
                            <FormattedMessage
                                id="auth.signin.cta"
                                defaultMessage="Sign In"
                            />
                        </SigninButton>
                    </FormWrapper>
                </GradientView>
                <AuthFooter type={'WHITE'} />
            </FormContainer>
        );
    }
}
