import styled from 'styled-components';
import { mobile } from '../styles';
import { Component as InputGroup } from '../components/InputGroup';
import { Link } from 'react-router-dom';

export const AuthInputGroup = styled(InputGroup)`
    width: 400px;
    margin-bottom: 32px;
    position: relative;

    ${mobile`
        width: 100%;
    `};

    a {
        position: absolute;
        top: 0;
        right: 0;
        color: #262626;
        font-size: 14px;
        line-height: 21px;
    }

    p {
        color: #021747;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-style: normal;
        margin-top: 40px;
    }
`;

export const LinkButton = styled(Link)`
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
`;

export const FormContainer = styled.div`
    height: 100%;
    background-color: #021747;
    display: flex;
    width: 100vw;
    flex-direction: column;
`;

export const FormWrapper = styled.form`
    padding: 30px 110px 60px 110px;

    ${mobile`
    padding: 20px 30px 60px 30px;

    label {
        white-space: normal;
    }`};

    label {
        color: #262626;
        font-size: 14px;
        white-space: nowrap;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;

        a {
          color: #EF8632;
        }
      }  
`;

export const LabelP = styled.p`
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
`;