import { createSelector } from 'reselect';

const getCountries = state => state.opendata.countries;
const getRegions = state => state.opendata.regions;

export const getSelectableCountries = createSelector(
    getCountries,
    (countries) => {
        const selectableCountries = Object.keys(countries ? countries : {})
            .map((key) => {
                return { value: key, label: (key === 'QQ') ? 'Select' : countries[key] };
            });

        return (selectableCountries && selectableCountries.length > 0) ? selectableCountries
            .filter(item => item && item.value !== 'QQ')
            .sort((prev, next) => {
                //_QQ comes first
                if (prev.value === 'QQ') return -1;
                if (next.value === 'QQ') return 1;
                return prev.label > next.label ? 1 : -1;
            }) : [];
    }
);


export const getSelectableRegions = createSelector(
    getRegions,
    (regions) => {
        const selectableRegions = Object.keys(regions ? regions : {})
            .map((key) => {
                return { value: key, label: (key.endsWith('_QQ')) ? 'Select' : regions[key] };
            });

        return (selectableRegions && selectableRegions.length > 0) ? selectableRegions
            .filter(item => item && !item.value.endsWith('_QQ'))
            .sort((prev, next) => {
                //_QQ comes first
                if (prev.value.endsWith('_QQ')) return -1;
                if (next.value.endsWith('_QQ')) return 1;
                return prev.label > next.label ? 1 : -1;
            }) : [];
    }
);
