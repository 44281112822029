import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Component as SmallButton } from '../../../../components/SmallButton';
import { default as PriceVolumeChart } from './PriceVolumeChart';
import { mobile } from '../../../../styles';

import config from 'config';
const isDebugStreamButtonsEnabled = config.get('devOpts.streamButtons');

const Wrapper = styled.div`
  background-color: #FFFFFF;
  // padding-top: 5px;
  //padding-left: 15px;
  //padding-right: 15px;
  padding-bottom: 20px;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;
`;

const ChartIntervalLinks = styled.div`
  // left: 10px;
  // z-index: 10;
  position: relative;
  font-size: 14px;
  font-weight: bold;

  ${mobile`
    margin-bottom: 15px;
  `};

  a {
    border-radius: 3px;
    color: #fff;
    background-color: rgba(2, 23, 71, 0.2);
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 2px;
  }

  a.selected, a:hover {
      background-color: rgba(239, 134, 50, 0.9);
  }

  a.nomobile {
    ${mobile`
      display: none;
    `};
  }
`;

const ResetLink = styled.div`
    float: right;
    margin-right: 10px;
    a {
        padding-left: 6px;
        padding-right: 6px;
    }

    ${mobile`
      margin-right: 10px;
    `};
`;


const INTERVALS = ['1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d', '3d', '1w', '1M'];

const MOBILE_INTERVALS = ['1m', '5m', '15m', '30m', '2h', '8h', '1d', '1w'];

export default class TradePriceChart extends React.Component {

    static propTypes = {
        clearCandlestickFeed: PropTypes.func,
        stopCandlestickFeed: PropTypes.func,
        startCandlestickFeed: PropTypes.func,
        pair: PropTypes.string,
        candlesticksTv: PropTypes.array,
        volumeBarsTv: PropTypes.array,
        lastCandlestickPrice: PropTypes.number,
        candlestickInterval: PropTypes.string,
        lastCandlestick: PropTypes.object,
        lastVolumeBar: PropTypes.object,
    };

    static defaultProps = {
        pair: null
    };

    constructor(props) {
        super(props);

        this.state = {
            reset: 0,
            lastCandlestickTime: 0,
            lastVolumeBarTime: 0,
            prevCandlestick: {},
            prevVolumeBar: {},
        };
    }

    componentDidMount() {
        // console.log('***TradePriceChart MOUNT');
        const { pair } = this.props;

        const interval = localStorage.getItem('chartInterval');
        if (interval) {
            this.props.clearCandlestickFeed();
            this.props.startCandlestickFeed({
                pair: pair,
                interval: interval,
            });
        } else {
            this.props.startCandlestickFeed({
                pair: pair,
                interval: '5m',
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.lastCandlestick && this.props.lastCandlestick.time > prevProps.lastCandlestick.time) {
            this.setState({
                lastCandlestickTime: this.props.lastCandlestick.time,
                prevCandlestick: this.props.lastCandlestick,
            });
        }
        if (this.props.lastVolumeBar && this.props.lastVolumeBar.time > prevProps.lastVolumeBar.time) {
            this.setState({
                lastVolumeBarTime: this.props.lastVolumeBar.time,
                prevVolumeBar: this.props.lastVolumeBar,
            });
        }
    }

    componentWillUnmount() {
        // console.log('***TradePriceChart UNMOUNT');
        this.stopCandlestickFeed();
    }

    stopCandlestickFeed = () => {
        this.props.stopCandlestickFeed();
        this.props.clearCandlestickFeed();
    };

    resetChartScale = (reset) => {
        this.setState({
            reset: reset,
        });
    }

    changeChartInterval = (interval) => {
        const { pair } = this.props;
        this.props.clearCandlestickFeed();
        this.setState({
            lastCandlestickTime: 0,
            lastVolumeBarTime: 0,
            prevCandlestick: {},
            prevVolumeBar: {},
        });
        this.props.startCandlestickFeed({
            pair: pair,
            interval: interval,
        });
        localStorage.setItem('chartInterval', interval);
    };

    render() {
        const { lastCandlestick, lastVolumeBar, candlesticksTv, volumeBarsTv, lastCandlestickPrice, candlestickInterval, pair } = this.props;
        const { reset, lastCandlestickTime, lastVolumeBarTime, prevCandlestick, prevVolumeBar } = this.state;

        const candlestick = (lastCandlestick && lastCandlestick.time >= lastCandlestickTime) ? lastCandlestick : prevCandlestick;
        const volumeBar = (lastVolumeBar && lastVolumeBar.time >= lastVolumeBarTime) ? lastVolumeBar : prevVolumeBar;

        var decimals;
        if (lastCandlestickPrice < 0.001) decimals = 8;
        else if (lastCandlestickPrice >= 0.001 && lastCandlestickPrice < 1) decimals = 6;
        else decimals = 2;

        return (
            <Wrapper>
                <ChartIntervalLinks>
                    {INTERVALS.map((value, index) => (
                        <a key={index} className={((!MOBILE_INTERVALS.includes(value)) ? 'nomobile ' : '') + ((value == candlestickInterval) ? 'selected' : '')} onClick={() => {this.changeChartInterval(value);}} title={value}>{value}</a>
                    ))}
                    <ResetLink>
                        <a onClick={() => {this.resetChartScale(new Date().getTime());}} title="Reset">Reset</a>
                    </ResetLink>
                </ChartIntervalLinks>

                {candlesticksTv && candlesticksTv.length > 0 &&
                    volumeBarsTv && volumeBarsTv.length > 0 &&
                    volumeBar && volumeBar.time > 0 &&
                    candlestick && candlestick.time > 0 && (
                    <PriceVolumeChart
                        legend={pair.replace('-', '/') + ': ' + candlestickInterval}
                        reset={reset}
                        interval={candlestickInterval}
                        decimals={decimals}
                        initCandles={candlesticksTv}
                        lastCandle={candlestick}
                        initVolumeBars={volumeBarsTv}
                        lastVolumeBar={volumeBar}
                    />
                )}

                {(isDebugStreamButtonsEnabled) && (
                    <SmallButton bsStyle='red' onClick={() => {this.stopCandlestickFeed();}} title={'Stop Candlestick Feed'}>
                        <FormattedMessage
                            id="trade.stopCandlestickFeed"
                            defaultMessage="Stop Candlestick Feed"
                        />
                    </SmallButton>
                )}
            </Wrapper>
        );
    }
}
