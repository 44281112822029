import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import dateFns from 'date-fns';

import { Component as SmallButton } from '../../../../components/SmallButton';
import { Component as Checkbox } from '../../../../components/Checkbox';
import { Component as Button } from '../../../../components/Button';
import { Container as InputTwoFa } from '../../../../components/InputTwoFa';
import { Title, ItemPending, GrayBubble } from '../../../../commonStyles/AccountProfileStyles';

import { validateAnyAuthCode } from '../../../../utils';
import { mobile } from '../../../../styles';

import refreshIcon from '../../../../images/ic-refresh-dark-md.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import copyIcon from '../../../../images/ic-copy.svg';


const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const Table = styled.div`
  padding-bottom: 20px;
  display: table;
  margin-top: 20px;
  // margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-bottom: 1px solid #eee;
`;

const Header = styled.div`
  display: table-row;

  >div {
      padding: 10px 20px;
    }History
`;

const FirstCell = styled.div`
  display: table-cell;
  white-space: nowrap;
  font-size: 12px;
`;

const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;
  font-size: 12px;
  // text-align: center;
`;

const CellNoMobile = styled(Cell)`
  ${mobile`
    display: none;
  `};
`;

const LastCell = styled(Cell)`
  // width: 34%;
  text-align: center;

  span {
    // color: #ef8632;
    cursor: pointer;
  }
`;

const StyledCheckbox = styled.div`
  margin: 20px;
`;

const CreateApiKeyText = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;

const WarningText = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
  color: #CC0000;
`;

const CreateApiKeyBoldText = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
`;

const Centered = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const HeaderRow = styled(Header)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;

    color: #ACB5BC;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.71px;
    line-height: 20px;

    >div {
      padding: 10px 20px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      text-transform: capitalize;

      ${mobile`
        padding: 3px;
      `};
    }
`;

const TableRow = styled.div`
  display: table-row;

  &:nth-child(2n) {
    background-color: #02174708;
  }

  >div {
    padding: 10px 20px;

    ${mobile`
      padding: 3px;
    `};
  }
`;

const TypeCell = styled(Cell)`
    text-transform: capitalize;
`;

const TitleCell = styled(Cell)`
	color: #9B9B9B;
	font-size: 24px;
	line-height: 36px;
`;

const TableBody = styled.div`
  display: table-row-group;
`;

const FormWrapper = styled.div`
  // background-color: #fff;
  // border-radius: 10px;
  // box-shadow: 0 0 26px -14px rgba(11,32,78,0.85);
  // padding: 30px 100px 50px 100px;
  // box-sizing: border-box;
  width: 300px;
  margin-top: 10px;
  // margin-left: auto;
  // margin-right: auto;

  ${mobile`
      margin-left: auto;
      margin-right: auto;
      // padding: 30px 30px 50px 30px;
    `};
`;

const FormTitle = styled.h1`
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const FormTitleCentered = styled(FormTitle)`
  text-align: center;
`;

const sideBySideStyle = {
    display: 'flex',
    alignItems: 'center'
};

const CreateButton = styled(Button)`
  margin: 20px 15px;
`;

const AuthRow = styled.div`
  margin-left: 0;
  margin-top: 35px;
`;

const LeftInputTwoFa = styled.div`
  display: inline-block;
  width: 150px;
  margin-right: 5px;
  margin-top: 15px;
  vertical-align: top;
`;

const RightButtonTwoFa = styled(Button)`
  // display: block;
  // width: 47%;
  width: 120px;
  margin-left: 5px;
  margin-top: 32px;
  vertical-align: top;
`;

const WideButton = styled(Button)`
  display: block;
  width: 280px;
  margin-top: 35px;
`;

const CopyToClipboardSpan = styled.span`
    color: #ef8632;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 0 5px;
`;

const ItemPendingWhiteBackground = styled(ItemPending)`
  background: ${props => props.theme.colors.primary5};
  width: 95%;
`;

export default class ProfileApiKeyManagement extends React.Component {

    static propTypes = {
        clearApiKeys: PropTypes.func,
        getApiKeys: PropTypes.func,
        submitCreateApiKey: PropTypes.func,
        submitDisableApiKey: PropTypes.func,
        submitDeleteApiKey: PropTypes.func,
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            createAllowTrading: false,
            createAllowWithdrawal: false,
            loading: false,
            view: '',
            code: '',
            keyCopied: false,
            secretCopied: false,
        };
    }

    componentDidMount() {
        const { user } = this.props;
        if (!user.apiKeys) {
            this.props.getApiKeys();
        }
    }

    // TODO: use this instead of wsMiddleware to update the table
    componentDidUpdate(prevProps) {
        if (this.props.user.refreshKeyTable !== prevProps.user.refreshKeyTable && this.props.user.refreshKeyTable === true) {
            this.setState({
                view: '',
                code: '',
            });
            this.submitRefreshKeys();
        }
    }

    submitRefreshKeys = () => {
        this.props.clearApiKeys();
        this.props.getApiKeys();
    }

    submitDisableKey = (id) => {
        this.props.submitDisableApiKey(id);
    }

    submitDeleteKey = (id) => {
        this.props.submitDeleteApiKey(id);
    }

    onSubmit = () => {
        const { code, createAllowTrading, createAllowWithdrawal } = this.state;

        this.setState({
            loading: true
        });

        this.props.submitCreateApiKey(createAllowTrading, createAllowWithdrawal, code);

        this.setState({
            loading: false
        });
    };

    onCreateNew = () => {
        this.setState({
            view: 'NEW',
        });
    }

    onCancelCreate = () => {
        this.setState({
            view: '',
            code: '',
        });
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            loading: false,
        });
    };

    onCopyKey() {
        this.setState({
            keyCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                keyCopied: false,
            });
        }, 1500);
    }

    onCopySecret() {
        this.setState({
            secretCopied: true,
        });
        this.timer = setTimeout(() => {
            this.setState({
                secretCopied: false,
            });
        }, 1500);
    }

    render() {

        const { user } = this.props;
        const { view, code, loading, createAllowTrading, createAllowWithdrawal, keyCopied, secretCopied } = this.state;

        const submitDisabled = loading || !validateAnyAuthCode(code);

        return (
            <Container>
                <Title>
                    <FormattedMessage
                        id="profile.apikeys.title"
                        defaultMessage="API Keys"
                    />
                </Title>

                {(user && !user.securedMethods.includes('NONE')) ? (
                    <React.Fragment>
                        {(view === '') && (
                            <p>
                                <FormattedMessage
                                    id="profile.apikeys.subtitle"
                                    defaultMessage="BlocPalX uses a Binance Compatible API"
                                />
                                <br/>
                                Docs: <a href="https://blocpal-inc.github.io/exchange-api-docs/" rel="noopener noreferrer" target="_blank">https://blocpal-inc.github.io/exchange-api-docs</a>
                            </p>
                        )}

                        {(user.createdApiKey && user.createdApiKey.apiKey) && (
                            <React.Fragment>
                                <ItemPendingWhiteBackground>
                                    <FormTitleCentered>
                                        <FormattedMessage
                                            id="profile.newApiKeyTitle"
                                            defaultMessage="Your new API Key"
                                        />
                                    </FormTitleCentered>

                                    <CreateApiKeyBoldText>
                                        <FormattedMessage
                                            id="profile.apiKeyText"
                                            defaultMessage="API Key"
                                        />
                                        <CopyToClipboardSpan>
                                            {keyCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                <CopyToClipboard onCopy={()=>this.onCopyKey()} text={user.createdApiKey.apiKey}>
                                                    <span>
                                                        <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                        &nbsp;Copy to clipboard
                                                    </span>
                                                </CopyToClipboard>
                                            )}
                                        </CopyToClipboardSpan>
                                    </CreateApiKeyBoldText>

                                    <Centered>
                                        {user.createdApiKey.apiKey}
                                    </Centered>

                                    <CreateApiKeyBoldText>
                                        <FormattedMessage
                                            id="profile.apiSecretText"
                                            defaultMessage="API Secret"
                                        />
                                        <CopyToClipboardSpan>
                                            {secretCopied ? <span style={{color: '#38B258'}}> Copied! </span> : (
                                                <CopyToClipboard onCopy={()=>this.onCopySecret()} text={user.createdApiKey.apiSecret}>
                                                    <span>
                                                        <img src={'/' + copyIcon} alt="Copy to clipboard"/>
                                                        &nbsp;Copy to clipboard
                                                    </span>
                                                </CopyToClipboard>
                                            )}
                                        </CopyToClipboardSpan>
                                    </CreateApiKeyBoldText>

                                    <Centered>
                                        {user.createdApiKey.apiSecret}
                                    </Centered>

                                </ItemPendingWhiteBackground>
                                <WarningText>
                                    <FormattedMessage
                                        id="profile.newApiKeyText"
                                        defaultMessage="You must copy and paste your new key now. The key and secret will not be displayed again"
                                    />
                                </WarningText>
                            </React.Fragment>
                        )}

                        { view === '' && (
                            <React.Fragment>
                                <GrayBubble>
                                    <FormattedHTMLMessage
                                        id="profile.api_keys_auto_disable"
                                        defaultMessage="For your protection: API keys will be disabled after <b>30 days</b> of inactivity"
                                    />
                                </GrayBubble>
                                <Table>
                                    <Header>
                                        <TitleCell>
                                            <FormattedMessage
                                                id="profile.title_apikey_list"
                                                defaultMessage="API keys"
                                            />
                                        </TitleCell>
                                        <Cell/>
                                        <Cell/>
                                        <CellNoMobile/>
                                        <CellNoMobile/>
                                        <LastCell>
                                            <a onClick={() => {this.submitRefreshKeys();}} title="Refresh"><img src={'/' + refreshIcon} alt="Refresh" title="Refresh" width="20" height="20"/></a>
                                        </LastCell>
                                    </Header>
                                    <HeaderRow>
                                        <FirstCell>
                                            API Key
                                        </FirstCell>
                                        <Cell>
                                            Trade
                                        </Cell>
                                        <Cell>
                                            Withdraw
                                        </Cell>
                                        <CellNoMobile>
                                            Last Used
                                        </CellNoMobile>
                                        <CellNoMobile>
                                            Status
                                        </CellNoMobile>
                                        <LastCell/>
                                    </HeaderRow>
                                    <TableBody>
                                        {(user.apiKeys && user.apiKeys.length > 0) ? user.apiKeys.map((key) =>
                                            <TableRow key={key.id}>
                                                <FirstCell style={sideBySideStyle}>
                                                    {(key.apiKey.length < 30) ? key.apiKey : key.apiKey.substr(0, 11) + ' ... ' + key.apiKey.substr(key.apiKey.length - 12) }
                                                </FirstCell>
                                                <Cell>
                                                    {(key.allowTrade) ? 'YES' : 'NO' }
                                                </Cell>
                                                <TypeCell>
                                                    {(key.allowWithdrawal) ? 'YES' : 'NO' }
                                                </TypeCell>
                                                <CellNoMobile>
                                                    {dateFns.format(new Date(key.lastUsed), 'MM/DD/YY hh:mm')}
                                                </CellNoMobile>
                                                <CellNoMobile>
                                                    {(key.enabled) ? 'ACTIVE' : 'DISABLED' }
                                                </CellNoMobile>
                                                <LastCell>
                                                    {(key.enabled) ? (
                                                        <SmallButton bsStyle='orange' onClick={() => {this.submitDisableKey(key.id);}} title="Disable">
                                                            <FormattedMessage
                                                                id="profile.disableApiKey"
                                                                defaultMessage="Disable"
                                                            />
                                                        </SmallButton>
                                                    ) : (
                                                        <SmallButton bsStyle='red' onClick={() => {this.submitDeleteKey(key.id);}} title="Delete">
                                                            <FormattedMessage
                                                                id="profile.deleteApiKey"
                                                                defaultMessage="Delete"
                                                            />
                                                        </SmallButton>
                                                    )}
                                                </LastCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow key='0'>
                                                <FirstCell>
                                                    <FormattedMessage
                                                        id="profile.noApiKeys"
                                                        defaultMessage="No API Keys"
                                                    />
                                                </FirstCell>
                                                <Cell/>
                                                <TypeCell/>
                                                <Cell/>
                                                <CellNoMobile/>
                                                <CellNoMobile/>
                                                <LastCell/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>

                                {(user.apiKeys && user.apiKeys.length > 0) && (
                                    <GrayBubble>
                                        <FormattedMessage
                                            id="profile.disableApiKeyText"
                                            defaultMessage="For your protection: Once disabled, API keys cannot be re-enabled"
                                        />
                                    </GrayBubble>
                                )}

                                <CreateButton onClick={() => {this.onCreateNew();}} title="Create New" bsStyle='blue'>
                                    <FormattedMessage
                                        id="profile.apiKeys.createNew"
                                        defaultMessage="Create New Key"
                                    />
                                </CreateButton>

                            </React.Fragment>
                        )}

                        { view === 'NEW' && (
                            <FormWrapper>
                                <FormTitle>
                                    <FormattedMessage
                                        id="profile.createApiKeyTitle"
                                        defaultMessage="Create a new API key"
                                    />
                                </FormTitle>

                                <StyledCheckbox>
                                    <Checkbox
                                        label={(
                                            <FormattedMessage
                                                id="profile.allowTrading"
                                                defaultMessage="Allow Trading"
                                            />
                                        )}
                                        checked={createAllowTrading}
                                        onChange={() => this.setState({ createAllowTrading: !createAllowTrading })}
                                    />
                                </StyledCheckbox>

                                <StyledCheckbox>
                                    <Checkbox
                                        label={(
                                            <FormattedMessage
                                                id="profile.allowWithdrawal"
                                                defaultMessage="Allow Withdrawal"
                                            />
                                        )}
                                        checked={createAllowWithdrawal}
                                        onChange={() => this.setState({ createAllowWithdrawal: !createAllowWithdrawal })}
                                    />
                                </StyledCheckbox>

                                <React.Fragment>
                                    <AuthRow>
                                        <FormattedMessage
                                            id="profile.2fa.existing2FA"
                                            defaultMessage="Verify With Your Existing 2FA Code"
                                        />
                                        <br/>
                                        <LeftInputTwoFa>
                                            <InputTwoFa
                                                name="code"
                                                value={code}
                                                onChange={this.onChange}
                                                disabled={loading}
                                                component='EDIT_ACCOUNT'
                                            />
                                        </LeftInputTwoFa>
                                        <RightButtonTwoFa disabled={submitDisabled} onClick={this.onSubmit}>
                                            <FormattedMessage
                                                id="profile.createKey"
                                                defaultMessage="Create"
                                            />
                                        </RightButtonTwoFa>
                                    </AuthRow>
                                    <WideButton bsStyle='red' onClick={this.onCancelCreate}>
                                        <FormattedMessage
                                            id="profile.cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </WideButton>
                                </React.Fragment>

                                <CreateApiKeyText>
                                    <FormattedMessage
                                        id="profile.createApiKeyText"
                                        defaultMessage="All API keys allow READ-ONLY user data"
                                    />
                                </CreateApiKeyText>
                            </FormWrapper>
                        )}
                    </React.Fragment>
                ) : (
                    <p>
                        <FormattedMessage
                            id="profile.apikeys.2faRequired"
                            defaultMessage="2FA Auth Configuration Is Required Before API Key Creation Is Allowed"
                        />
                    </p>
                )}

            </Container>
        );
    }
}
