
export function validateAnyAuthCode(code) {
    return validateGauthCode(code) || validateSmsCode(code) || validateYubikeyCode(code);
}

export function validateGauthCode(code) {
    return code && code.replace(/\s/g, '').length === 6 && code.replace(/\s/g, '').match(/^\d+/);
}

export function validateSmsCode(code) {
    return code && code.replace(/\s/g, '').length === 6 && code.replace(/\s/g, '').toUpperCase().match(/^[ACDEFGHJKLMNPQRTUVWXYZ234679]+/);
}

export function validateYubikeyCode(code) {
    return code && code.replace(/\s/g, '').length > 31 && code.replace(/\s/g, '').length < 65;
}

export function millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
        seconds === 60 ?
            (minutes+1) + ':00' :
            minutes + ':' + (seconds < 10 ? '0' : '') + seconds
    );
}